
/**
  * mutationObserver, to observe DOM-mutations.
  *
  * @param {Element} target DOM-Element to be observed (e.g. document.body)
  * @param {Function} callback MutationObservers' callback function. Function should accept at least an array of MutationRecord objects
  * @param {Object} config MutationObservers' observe config. See docs.
  *
  * @category shared
  * @subcategory helpers
  * @exports mutationObserver
  */
export default function mutationObserver(target, callback, config = { attributes: false, childList: true, subtree: true }) {
  const observer = new MutationObserver(callback);
  observer.observe(target, config);
}
