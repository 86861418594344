
import { DEMO_CUSTOMER_ID } from 'shared/utilities/constants';

// Utilities

export default function checkDemo(user) {
  if (!user) return false;
  const { customer } = user || {};
  return (
    customer
        && customer.id === DEMO_CUSTOMER_ID
        && user.email
        && !user.email.includes('@sosafe')
  );
}
