
import { ModuleData } from 'elearning/pages/player-page/types';
import { getMasterScoreByScormType } from 'elearning/pages/player-page/utils';
import { AuthProps } from 'types';
import { checkIsActivePresenter } from '../check';

interface ScormGetTypeProps {
  playerData : { isContinue: boolean },
  user: AuthProps['user'],
  moduleData: ModuleData
}

/**
 * @returns the initial configuration for our SCORM instance created in the player page,
 * this initial configuration is used by the SCORM "engine" to calculate where the user are resuming from or starting a brand new module
 */
export default function scormGetJSON({ playerData, user, moduleData }: ScormGetTypeProps) {
  let suspendData = moduleData.suspend_data || '';
  if (!playerData || !playerData.isContinue) {
    suspendData = '';
  }
  const isActivePresenter = checkIsActivePresenter(moduleData?.scorm_entry_point, { checkOnlyForDefaultModules: true });

  // check here what these values mean
  // https://scorm.com/scorm-explained/technical-scorm/run-time/run-time-reference

  const theJSON = {
    'cmi.core._children': 'student_id, student_name, lesson_location, credit, lesson_status, entry, score, total_time, lesson_mode, exit, session_time',
    'cmi.core.student_id': user.id,
    'cmi.core.student_name': `${user.firstname} ${user.lastname}`,
    'cmi.core.lesson_location': '',
    'cmi.core.credit': '',
    'cmi.core.lesson_status': 'incomplete',
    'cmi.core.score_children': 'cmi.core.score.raw, cmi.core.score.max, cmi.core.score.min',
    'cmi.core.score.raw': '0',
    'cmi.core.score.max': '100',
    'cmi.core.score.min': '0',
    'cmi.core.total_time': '',
    'cmi.core.lesson_mode': '',
    'cmi.core.entry': 'resume',
    'cmi.core.exit': 'suspend',
    'cmi.core.session_time': '',
    'cmi.student_data.mastery_score': getMasterScoreByScormType({ isCustomModule: moduleData.isCustom, isActivePresenter }),
    'cmi.launch_data': '',
    'cmi.entry': 'resume',
    'cmi.exit': 'suspend',
    'cmi.suspend_data': suspendData,
  };
  return theJSON;
}
