
// Packages
import React, { useContext } from "react";

// Utilities
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

// Components
import Logo from "shared/components/header/logo-header";
import LangSwitch from "authentication/components/lang-switch";
import GlobalSettingsContext from "shared/modules/global-settings/state/global-settings-context.provider";

/**
 * Component to display header on various Authentication screens
 *
 * @category authentication
 * @subcategory components
 * @exports AuthHeader
 * @component
 */
export default function AuthHeader() {
  const { i18n } = useTranslation("translations");
  const { globalSettings } = useContext(GlobalSettingsContext);

  return (
    <header className="d-flex justify-content-between mb-4">
      <Logo theme={globalSettings} className={i18n.language === "ar" ? "mr3" : "ml-3"} />
      <LangSwitch />
    </header>
  );
}
