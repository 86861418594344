
import { TOURS } from 'shared/utilities/tour-configs';

/**
 * returns tour type depending on component
 *
 * @param {string} component : one of [categories, modules, achievement]
 * @returns {string} Tour Type or null
 *
 * @category shared
 * @subcategory helpers
 * @exports tourGetTourTypeByComponent
 */
export default function tourGetTourTypeByComponent(component) {
  switch (component) {
    case 'categories':
      return TOURS.MainpageTour;
    case 'modules':
      return TOURS.ModulepageTour;
    case 'achievements':
      return TOURS.AchievementTour;

    default:
      return null;
  }
}
