
import React from 'react';
import PropTypes from 'prop-types';

import useSvgFromUrl from 'shared/hooks/use-svg-from-url';
import { componentToBase64 } from 'shared/svgs/helper/svg-helper';

import './svg-from-url-as-background-div.scss';

function SvgFromUrlAsBackgroundDiv(props) {
  const {
    url, className, fallback, modifier,
  } = props;

  const { svg } = useSvgFromUrl(url, 'module', true);

  let backgroundImage = `url(data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(modifier(svg))))})`;

  if ((!url || !svg) && fallback) {
    backgroundImage = `url(${componentToBase64(fallback)})`;
  }

  return (
        <div
            className={`svg-from-url-as-background ${className}`}
            style={{ backgroundImage }}
        />
  );
}

const areEqual = (prevProps, nextProps) => prevProps.url === nextProps.url;

export default React.memo(SvgFromUrlAsBackgroundDiv, areEqual);

SvgFromUrlAsBackgroundDiv.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
  fallback: PropTypes.elementType,
  modifier: PropTypes.func,
};

SvgFromUrlAsBackgroundDiv.defaultProps = {
  url: '',
  className: '',
  fallback: null,
  modifier: (svg) => svg,
};
