
// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

// Helpers
import imageErrorSrc from 'shared/svgs/image-error.svg';
import { ImageError } from 'shared/svgs';
import { DEMO_CUSTOMER_ID } from 'shared/utilities/constants';

// Scss
import 'scss/background-attributes.scss';
import 'scss/object-attributes.scss';

const mediaBreakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};
const mediaBreakpointKeys = Object.keys(mediaBreakpoints);

/*
 * returns the next higher breakpoint minus 1px, if found in the srcset.
 * returns an empty string if no higher breakpoint is defined.
 *
 * @prop {object} srcset - srcset-object, gets passed through from ResponsivePicture
 * @prop {number} index  - current index of mediaBreakpointKeys
 */
const getMaxWidthMediaQuery = (srcset, index) => {
  if (mediaBreakpointKeys[index + 1]) {
    if (srcset[mediaBreakpointKeys[index + 1]]) {
      return ` and (max-width: ${mediaBreakpoints[mediaBreakpointKeys[index + 1]] - 1}px)`;
    }
    return getMaxWidthMediaQuery(srcset, index + 1);
  }
  return '';
};

/**
 * Renders a responsive and ARIA-conform image.
 *
 * Uses the html5-picture element if supported, uses background-graphics as a fallback.
 * To properly behave, this component should always be wrapped in a relatively positioned element.
 * (The object-fit property won't work as expected otherwise)
 *
 * @category shared
 * @subcategory components
 * @exports ResponsivePicture
 * @component
 */
const ResponsivePicture = (props) => {
  const {
    src, alt, onClick, position, fit, defaultHeight, defaultWidth, grayscale, srcset, flipx, ariaHidden,
  } = props;
  let { className } = props;

  const optionalProps = {
    ...(ariaHidden ? { 'aria-hidden': 'true' } : {}),
    ...(onClick ? { onClick } : {}),
  };

  const [imgSuccess, setImgSuccess] = useState(true);
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { customer } = user ?? {};

  className += flipx ? ' flipx' : '';

  const fallbackImage = (
        <div
            data-original={src}
            className={`${className} image-error stroke-primary`}
            {...optionalProps}
        >
            <ImageError alt={alt} />
        </div>
  );

  if (!imgSuccess) return fallbackImage;

  let srcUrl;
  try {
    srcUrl = new URL(src);
  } catch (error) {
    setImgSuccess(false);
    return fallbackImage;
  }

  const ua = window?.navigator?.userAgent || '';
  const isIE = (ua.indexOf('MSIE') > 0 || !!ua.match(/Trident.*rv:11\./));
  const isSvg = srcUrl.pathname.match(/\.svg$/i);

  const operations = [];
  if (grayscale) operations.push('grayscale');

  if (operations.length) srcUrl.searchParams.append('operations', operations.join(','));

  if (isIE) {
    // IE doesn't support svg-image-urls inside the background-image attribute, so we ask the backend to provide a png instead
    srcUrl.pathname.replace(/\.svg$/i, '.png');

    if (defaultHeight) srcUrl.searchParams.append('height', defaultHeight);
    else if (defaultWidth) srcUrl.searchParams.append('width', defaultWidth);

    const ieSrc = srcUrl.toString();
    const ieStyle = {
      backgroundImage: `url("${ieSrc}"), url("${imageErrorSrc}")`,
      backgroundSize: `${fit}, auto`,
      backgroundPosition: `${position}, center`,
    };
    // translating the properties into their respective classes. See scss/backgroundAttributes.scss for available classnames.
    const ieClassName = 'background-repeat-no-repeat';

    return (
            <div
                className={`${ieClassName} ${className}`}
                style={ieStyle}
                role='img'
                alt={alt}
                {...optionalProps}
            />
    );
  }

  // non-IE (modern) browsers:
  // translating the properties into their respective classes. See scss/objectAttributes.scss for available classnames.
  const imgClassName = `object-position-${position.replace(' ', '-')} object-fit-${fit}`;

  if (isSvg) {
    return (
            <div
                className={className}
                {...optionalProps}
            >
                <img
                    className={`h-100 w-100 ${imgClassName}`}
                    src={srcUrl.toString()}
                    alt={alt}
                    onError={() => setImgSuccess(false)}
                />
            </div>
    );
  }

  // add login id for Demo Customers
  if (customer?.id === DEMO_CUSTOMER_ID && srcUrl.toString().includes('/v1/resource/')) {
    srcUrl.searchParams.append('login', user.id);
  }

  // a srcset with prop-dependent breakpoints will be added to non-svg pictures
  const srcSetJsx = [];
  Object.keys(mediaBreakpoints).forEach((currentBreakpoint, index) => {
    if (srcset[currentBreakpoint]) {
      srcUrl.searchParams.delete('height');
      srcUrl.searchParams.delete('width');
      const maxWidthMediaQuery = getMaxWidthMediaQuery(srcset, index);
      // set height xor width for the respective breakpoint. Prefer height.
      if (srcset[currentBreakpoint].height) {
        srcUrl.searchParams.append('height', srcset[currentBreakpoint].height);
        srcSetJsx.push(
                    <source
                        key={currentBreakpoint}
                        srcSet={srcUrl.toString()}
                        media={`(min-width: ${mediaBreakpoints[currentBreakpoint]}px)${maxWidthMediaQuery}`}
                    />,
        );
      } else if (srcset[currentBreakpoint].width) {
        srcUrl.searchParams.append('width', srcset[currentBreakpoint].width);
        srcSetJsx.push(
                    <source
                        key={currentBreakpoint}
                        srcSet={srcUrl.toString()}
                        media={`(min-width: ${mediaBreakpoints[currentBreakpoint]}px)${maxWidthMediaQuery}`}
                    />,
        );
      }
    }
  });

  // cleanup url and append height xor width, before returning the jsx-object
  srcUrl.searchParams.delete('height');
  srcUrl.searchParams.delete('width');
  if (defaultHeight) srcUrl.searchParams.append('height', defaultHeight);
  else if (defaultWidth) srcUrl.searchParams.append('width', defaultWidth);

  return (
        <picture {...(ariaHidden ? { 'aria-hidden': 'true' } : {})}>
            {srcSetJsx}
            <img
                className={`${className} ${imgClassName}`}
                src={srcUrl.toString()}
                alt={alt}
                onError={() => setImgSuccess(false)}
                {...(onClick ? { onClick } : {})}
            />
        </picture>
  );
};

ResponsivePicture.propTypes = {
  /** src url of the image */
  src: PropTypes.string.isRequired,
  /** alt text for ARIA */
  alt: PropTypes.string.isRequired,
  /** additional classes */
  className: PropTypes.string,
  /** onclick-function */
  onClick: PropTypes.func,
  /** height to render the picture in. Takes precidency over defaultWidth. */
  defaultHeight: PropTypes.number,
  /** width to render the picture in. Will be ignored, if defaultHeight is set. */
  defaultWidth: PropTypes.number,
  /** object/background position of the image. Defaults to "center center" */
  position: PropTypes.string,
  /** object/background fit. Defaults to "contain" */
  fit: PropTypes.string,
  /** grayscale the image? */
  grayscale: PropTypes.bool,
  /**
     * srcset-object with the image-breakpoints and a respective size.
     * <ul>
     * <li>srcset.##.height - image-height for the respective breakpoint and above. Takes precidency over srcset.##.width.</li>
     * <li>srcset.##.width  - image-width for the respective breakpoint and above. Will be ignored, if srcset.##.height is set.</li>
     * <ul>
     * replace ## with the CSS-Breakpoint-names below, e.g. 'srcset.md.width' (according to _themes.scss):
     * <li>xs: 0,</li>
     * <li>sm: 576px,</li>
     * <li>md: 768px,</li>
     * <li>lg: 992px,</li>
     * <li>xl: 1200px</li>
     * <li>xxl: 1600px</li>
     * </ul>
     * </ul>
     */
  srcset: PropTypes.shape({
    xs: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    sm: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    md: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    lg: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    xl: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    xxl: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }),
  /** flips icon on Y-axis */
  flipx: PropTypes.bool,
  /** sets aria-hidden, useful for purely decorative-images */
  ariaHidden: PropTypes.bool,
};

ResponsivePicture.defaultProps = {
  className: '',
  defaultHeight: null,
  defaultWidth: null,
  onClick: null,
  position: 'center center',
  fit: 'contain',
  grayscale: false,
  srcset: {},
  flipx: false,
  ariaHidden: false,
};

export default ResponsivePicture;
