
// Packages
import PropTypes from 'prop-types';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Hooks
import useTourHint from 'shared/hooks/use-tour-hint';

// Utilities
import { getComponentByUrl, tourGetTourTypeByComponent } from 'shared/helpers';
import usePlatformSettings from 'shared/hooks/use-platform-settings';
import useMetaLinks from 'shared/hooks/use-meta-links';

// Components
import SvgIcon from 'shared/svgs/helper/svg-icon';
import {
  FAQ, Support, Tour, Glossar,
} from 'shared/svgs';

// Scss
import './help-menu-button.scss';
import { Tooltip } from '@sosafe-platform-engineering/fe-lib-ui-react';
import { useTourWithAnalytics } from 'shared/hooks/use-tour/use-tour-with-analytics';
import { PanelToggle } from '../panel-toggle';

let timeout = null;

export default function HelpButtonMenu(props) {
  const { t, i18n } = useTranslation('translations');
  const dispatch = useDispatch();
  const { tourHint } = useTourHint();
  const { history } = props;
  const { support } = useMetaLinks();
  const { tourStart } = useTourWithAnalytics();

  const { settings: { show_faq /* show_glossary /* TODO Once the glossary page is not broken (404) anymore we should put this back in. */ } = {} } = usePlatformSettings();

  const componentsWithTour = ['achievements', 'modules', 'categories'];
  const url = window.location.pathname;
  const [component, setComponent] = useState(getComponentByUrl(url));
  const [showHelpOptions, setShowHelpOptions] = useState(false);

  useEffect(() => {
    timeout = setTimeout(() => {
      setComponent(getComponentByUrl(url));
    }, 300);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [url]);

  // Contains (readable) information about availability of menu items
  const availableMenuItems = {
    faq: show_faq ?? true,
    support,
    tour: (componentsWithTour.includes(component) && !props.minimizedView),
    glossary: false, // show_glossary ?? true, // TODO Once the glossary page is not broken (404) anymore we should put this back in.
  };

  const TooltipContent = useCallback(() => (
        <nav
            className='d-flex flex-column '
        >
            {availableMenuItems.faq && (
                <div
                    className='cursor-pointer d-flex flex-row help-menu-item px-3 py-1'
                    onClick={() => history.push('/faq')}
                    role='button'
                    aria-label={t('FAQ')}
                >
                    <SvgIcon
                        Icon={FAQ}
                        size='20'
                        className='my-auto stroke-icon-gray'
                    />
                    <span className={`my-auto ${i18n.language === 'ar' ? 'mr-2' : 'ml-2'}`}>{t('FAQ')}</span>
                </div>
            )}
            {availableMenuItems.support && (
                <div
                    className='cursor-pointer d-flex flex-row help-menu-item px-3 py-1'
                    onClick={() => {
                      window.open(support, '_blank');
                    }}
                    role='button'
                    aria-label={t('Support')}
                >
                    <SvgIcon
                        Icon={Support}
                        size='20'
                        className='my-auto stroke-icon-gray'
                    />
                    <span className={`my-auto ${i18n.language === 'ar' ? 'mr-2' : 'ml-2'}`}>{t('Support')}</span>
                </div>
            )}
            {availableMenuItems.tour && (
                <div
                    className='cursor-pointer d-flex flex-row help-menu-item px-3 py-1'
                    onClick={() => dispatch(tourStart({ id: tourGetTourTypeByComponent(getComponentByUrl()) }))}
                    role='button'
                    aria-label={t('See tour')}
                >
                    <SvgIcon
                        Icon={Tour}
                        size='20'
                        className='my-auto stroke-icon-gray'
                    />
                    <span className={`my-auto ${i18n.language === 'ar' ? 'mr-2' : 'ml-2'}`}>{t('See tour')}</span>
                </div>
            )}
            {availableMenuItems.glossary && (
                <div
                    className='cursor-pointer d-flex flex-row help-menu-item px-3 py-1'
                    onClick={() => {
                      window.open(i18n.language === 'de' ? 'https://sosafe.de/glossar/' : 'https://sosafe-awareness.com/cyber-security-glossary/', '_blank');
                    }}
                    role='button'
                    aria-label={t('Glossar')}
                >
                    <SvgIcon
                        Icon={Glossar}
                        size='20'
                        className='my-auto stroke-icon-gray'
                    />
                    <span className={`my-auto ${i18n.language === 'ar' ? 'mr-2' : 'ml-2'}`}>{t('Glossary')}</span>
                </div>
            )}
        </nav>
  ), [showHelpOptions, availableMenuItems]);

  // If all availableMenuItems are false, don't display the HelpMenuButton at all
  if (Object.values(availableMenuItems).every((itemValue) => !itemValue)) {
    return null;
  }

  return (
        <div className='position-relative'>
            <PanelToggle
                content={<TooltipContent/>}
                showContent={showHelpOptions}
                onMouseLeave={() => setShowHelpOptions(false) }
            >
                <div>
                    <Tooltip
                        content={t('Help')}
                    >
                        <div
                            onClick={() => setShowHelpOptions(true) }
                            className='my-auto position-relative'
                            role='button'
                            aria-label={t('Help')}
                        >
                            <SvgIcon
                                Icon={FAQ}
                                size='25'
                                className='my-auto mx-4 cursor-pointer stroke-icon-gray'
                            />
                        </div>
                    </Tooltip>
                    {tourHint}
                </div>

            </PanelToggle>
        </div>
  );
}

HelpButtonMenu.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  minimizedView: PropTypes.bool,
};

HelpButtonMenu.defaultProps = {
  minimizedView: false,
};