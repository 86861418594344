// Packages
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Services
import { checkDemo } from 'shared/helpers';

// Hooks
import useMetaLinks from 'shared/hooks/use-meta-links';

// Components
import OrientationChange from 'shared/components/orientation-change';
import { SoSafeLogoWhite } from 'shared/svgs';

// Scss
import './footer.scss';
import GlobalSettingsContext from 'shared/modules/global-settings/state/global-settings-context.provider';
import useUser from 'shared/hooks/use-user';
import { version } from '../../../../package.json';

export default function NewFooter(props) {
  const { t } = useTranslation('translations');
  const history = useHistory();
  const [isLandscape, setIsLandscape] = useState(0);

  const { user } = useUser({ enabled: false });
  const { globalSettings } = useContext(GlobalSettingsContext);

  const { accessibility, privacy, imprint } = useMetaLinks();

  // BUILD_DATE is set by webpack (DefinePlugin) during build
  const DATE = window.BUILD_DATE || '2021-01-01 - 00:00';

  const footerLogo = globalSettings?.footer_logo || null;

  const { className, hideOnLandscape, isFooterHidden } = props;

  const cssClasses = `app-footer direction-ltr noSelect ${className || ''}`;
  let content = (
    <footer className={cssClasses}>
      <OrientationChange
        updateParent={(ori) => setIsLandscape(ori.isLandscape)}
      />
      <Navbar className="px-3 py-0">
        <Nav className="flex-grow-1">
          {!isFooterHidden && (
            <Navbar.Brand
              href="/"
              onClick={() => history.push('/')}
              aria-label={t('footerLogo')}
              className="p-0 my-1 ml-1 mr-2 d-flex align-items-center"
            >
              {footerLogo ? (
                <img
                  alt={t('footerLogo')}
                  src={footerLogo}
                  className="pageFooter-logo"
                />
              ) : (
                <SoSafeLogoWhite className="pageFooter-logo" />
              )}
            </Navbar.Brand>
          )}
          <div
            id="version-number"
            className="text-muted version-number d-none d-md-flex mr-auto my-1 align-items-center"
          >
            <span className="u-textMedium">
              Version {version} ({DATE})
            </span>
          </div>
          {checkDemo(user) && (
            <div className="my-1 text-center">
              <span className="mx-auto u-textMedium">
                <strong>{t('Demo Mode Active')}</strong>
              </span>
              <br />
              <span className="mx-auto u-textMedium">
                {t(
                  'This e-learning access is for testing purposes only and is not approved for regular operation.'
                )}
              </span>
            </div>
          )}

          {!isFooterHidden && (
            <div className="d-flex flex-column flex-md-row ml-auto my-2 my-md-1 align-items-end align-items-md-center">
              <Nav.Item>
                <Nav.Link
                  className="p-0 p-md-2"
                  target="_blank"
                  href={accessibility}
                >
                  <span className="u-textMedium">{t('Accessibility')}</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="p-0 p-md-2" target="_blank" href={imprint}>
                  <span className="u-textMedium">{t('Imprint')}</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="p-0 p-md-2" target="_blank" href={privacy}>
                  <span className="u-textMedium">{t('Privacy Policy')}</span>
                </Nav.Link>
              </Nav.Item>
              {globalSettings?.copyright && (
                <Nav.Item>
                  <Nav.Link
                    className="p-0 p-md-2"
                    target="_blank"
                    href={accessibility}
                  >
                    <span className="u-textMedium">
                      © {new Date().getFullYear()} SoSafe SE
                    </span>
                  </Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item>
                <Nav.Link className="p-0 p-md-2" target="_blank" href={imprint}>
                  <span className="u-textMedium">{t('Imprint')}</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="p-0 p-md-2" target="_blank" href={privacy}>
                  <span className="u-textMedium">{t('Privacy Policy')}</span>
                </Nav.Link>
              </Nav.Item>
            </div>
          )}
        </Nav>
      </Navbar>
    </footer>
  );

  if (isLandscape && hideOnLandscape) {
    content = (
      <OrientationChange
        updateParent={(ori) => setIsLandscape(ori.isLandscape)}
      />
    );
  }
  return content;
}

NewFooter.propTypes = {
  className: PropTypes.string,
  hideOnLandscape: PropTypes.bool,
  isFooterHidden: PropTypes.bool,
  dark: PropTypes.bool,
};

NewFooter.defaultProps = {
  className: null,
  hideOnLandscape: false,
  dark: false,
  isFooterHidden: false,
};
