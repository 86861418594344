
// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

// Hooks
import usePlatformSettings from 'shared/hooks/use-platform-settings';

// Utilities
import { getModuleForModulePreview, getModuleKey, MODULE_OPENED_FROM } from 'elearning/helpers';

// Components
import { Play, Close } from 'shared/svgs';
import ResponsivePicture from 'shared/components/responsive-picture';
import SvgIcon from 'shared/svgs/helper/svg-icon';

// Scss
import './next-module-preview.scss';

/**
 * Component to display next module on playerpage 'inside' iframe. Does only show up, if the module was passed.
 * Use NextModulePopupPreview on gamification additionally.
 *
 * @export
 * @returns Jsx
 */
export default function NextModulePreview({ closeNextModulePreview }) {
  const { t } = useTranslation('translations');
  const history = useHistory();
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { categories } = useSelector((state) => state.modules, shallowEqual);
  const { settings } = usePlatformSettings();
  const { step_activation } = settings || {};
  const { moduleKey, categoryKey } = useParams();

  const newModule = getModuleForModulePreview(categories, user, step_activation, categoryKey, moduleKey);

  if (!newModule) return null;

  const {
    name,
    image,
    category_key,
  } = newModule;

  const historyState = {
    openedFrom: MODULE_OPENED_FROM.NEXT_MODULE_POP_UP,
  };

  return (
        <div className='NextModulePreview'>
            <div
                className='content shadow bg-black'
                onClick={() => history.push(`/elearning/${category_key}/${getModuleKey(newModule)}`, historyState)}
                role='button'
            >
                <div className='position-absolute bg-black w-100 h-100' />
                <ResponsivePicture
                    className='h-100 w-100'
                    src={image}
                    alt={name}
                    fit='cover'
                    srcset={{
                      xs: { width: 730 },
                      md: { height: 230 },
                      lg: { width: 570 },
                      xl: { height: 250 },
                    }}
                />
                <SvgIcon
                    className='next-play'
                    Icon={Play}
                    size='50%'
                />
                <h4 className='h3 next-title top text-center'>{t('Next module')}</h4>
                <span className='next-title bottom text-center h3'>{name}</span>
                <div
                    className='close cursor-pointer'
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      closeNextModulePreview();
                    }}
                    role='button'
                    aria-label={t('Close')}
                >
                    <SvgIcon
                        Icon={Close}
                        color='white'
                        size='28px'
                    />
                </div>
            </div>
        </div>
  );
}

NextModulePreview.propTypes = {
  closeNextModulePreview: PropTypes.func.isRequired,
};
