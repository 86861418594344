
import { MODULE_STATES } from 'shared/utilities/module-states';
import getStatusOfModule from 'elearning/helpers/get/get-status-of-module';
import ActionTypes from 'shared/utilities/action-types';

const INITIAL_STATE = { welcomeCompareData: null };

class WelcomeCardReducer {
  static reduce(state = INITIAL_STATE, action) {
    switch (action.type) {
      case ActionTypes.WELCOME_CARD_DATA_UPDATE:
        return { ...state, welcomeCompareData: action.payload };

      case ActionTypes.LEAVE_PLAYER_PAGE: {
        const module = action.payload;
        const statusOfModule = getStatusOfModule(module);
        if (module.slide === module.pages) return { ...state };
        if (module && module.progress >= 70 && !statusOfModule.includes(MODULE_STATES.PASSED)) {
          const data = {
            ...state.welcomeCompareData,
            currentMessage: 'ADHOC_MOD_ALMOST_DONE',
            addData: { module },
          };
          return { ...state, welcomeCompareData: data };
        }
        return { ...state };
      }
      case ActionTypes.ACCOUNT_UPDATE_SUCCESS:
        return { welcomeCompareData: { currentMessage: 'default' } };
      case ActionTypes.AUTH_LOGIN_FAILED:
      case ActionTypes.AUTH_LOGIN_REQUEST:
      case ActionTypes.AUTH_LOGIN_SUCCESS:
      case ActionTypes.AUTH_LOGOUT_FAILED:
      case ActionTypes.AUTH_LOGOUT_REQUEST:
      case ActionTypes.AUTH_LOGOUT_SUCCESS:
      case ActionTypes.CLEAR_STORE:
        return { welcomeCompareData: null };

      default:
        return state;
    }
  }
}

export default WelcomeCardReducer.reduce;
