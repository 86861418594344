import React from 'react';
import { RouterSwitch } from './router-switch';
import '../app.scss';
import NotificationSystemProvider, {
  NotificationRewardStateInterceptor,
  NotificationSystem,
} from 'shared/modules/notification-system';
import BackendHint from 'shared/components/backend-hint';
import { AuthContextProvider } from 'auth-context/auth-context';

/* Import of possible route controller */
import * as AuthRouteController from './routes/auth.route.controller';

/* Classic route controllers */
import * as ClassicRouteController from './routes/classic/classic.route.controller';
import * as PoliciesRouteController from './routes/classic/policies.route.controller';

/* QA Page route controllers */
import * as QaPageRouteController from './routes/personalized-learning/qa-page.route.controller';

/* Personalized learning route controllers */
import * as PersonalizedLearningRouteController from './routes/personalized-learning/personalized-learning.route.controller';
import * as SurveyRouteController from './routes/personalized-learning/survey.route.controller';
import * as HRSSurveyRouteController from './routes/personalized-learning/hrs-survey.route.controller';
import * as PlPoliciesRouteController from './routes/personalized-learning/pl-policies.route.controller';
import * as GuardRouteController from './routes/personalized-learning/guard.route.controller';
import * as CalculatingPathRouteController from './routes/personalized-learning/calculating-path.route.controller';

/*
 * Here are all the route controllers,
 * the order of the array counts as priority(0 index has the highest priority)
 * eg: if there are two paths allowed such as "PoliciesRouteController" and
 * "ClassicRouteController", survey router will be shown until the isPathAllowed return
 * false then the list of allowed path will contains only
 * "ClassicRouteController" therefore it will show it.
 */
const routeControllers = [
  AuthRouteController,
  CalculatingPathRouteController,
  GuardRouteController,
  PoliciesRouteController,
  PlPoliciesRouteController,
  QaPageRouteController,
  SurveyRouteController,
  HRSSurveyRouteController,
  PersonalizedLearningRouteController,
  ClassicRouteController,
];

interface Props {
  signIn: () => void;
  signOut: () => void;
  signUp: () => void;
}

export const RouterController = (authProps: Props) => (
  <div className="app">
    <div className="app-content w-100">
      <div className="app-page w-100">
        <AuthContextProvider contextProps={authProps}>
          <NotificationSystemProvider>
            <NotificationSystem />
            <NotificationRewardStateInterceptor />
            <BackendHint />
            <RouterSwitch routerConfigs={routeControllers} />
          </NotificationSystemProvider>
        </AuthContextProvider>
      </div>
    </div>
  </div>
);
