
import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomButton from 'shared/components/custom-button';
import { ALERT_TYPE } from 'shared/utilities/modal-alert';
import { checkDemo } from 'shared/helpers';

const MySwal = withReactContent(Swal);

export default function useDemoPopup() {
  const { t, i18n } = useTranslation('translations');
  const { user } = useSelector((state) => state.auth, shallowEqual);

  const show = () => {
    MySwal.fire({
      title: t('Demo Mode Active'),
      html: (
                <div className='d-flex flex-column'>
                    <span>
                        {t('This e-learning access is for testing purposes only and is not approved for regular operation.')}
                    </span>
                    <hr className='my-3' />
                    <CustomButton
                        label={t('Close')}
                        variant='outline-info'
                        onClick={() => MySwal.close()}
                        className='mx-auto'
                        aria-label={t('Close')}
                    />
                </div>
      ),
      buttonsStyling: false,
      showConfirmButton: false,
      showCloseButton: true,
      closeButtonAriaLabel: t('Close'),
      allowEnterKey: false,
      customClass: {
        popup: `popup-no-width ${i18n.language === 'ar' ? 'arab' : ''}`,
      },
      icon: ALERT_TYPE.INFO,
      focusConfirm: false,
    });
  };

  return {
    show,
    isDemo: checkDemo(user),
  };
}
