
import React, { useState } from 'react';
import SvgIcon from 'shared/svgs/helper/svg-icon';
import { ChevronRight } from 'shared/svgs';
import { useTranslation } from 'react-i18next';
import { copyString } from './copy-to-clipboard';
import { FaClipboard, FaClipboardCheck, FaClipboardList } from 'react-icons/fa';

import './error-collapsible.scss';

export default function ErrorCollapsible(error, response) {
  const [open, setOpen] = useState(false);
  const [copyState, setCopyState] = useState(0);
  const { t } = useTranslation();

  const message1 = JSON.stringify(error, null, 2);
  const message2 = response ? JSON.stringify(response, null, 2) : null;

  let message = `error: \n${message1}`;
  if (message2) message = `${message1}\nresponse: \n${message2}`;

  message = message.replace(/"sso": "(.)*"/g, '"sso": "sso_token_placeholder"');
  message = message.replace(/sso=([^&"?]*)/g, 'sso=sso_token_placeholder');
  message = message.replace(/"password": "(.)*"/g, '"password": "password_placeholder"');

  let copyLabel = null;
  switch (copyState) {
    case 0:
      copyLabel = (
                <span className='copy-text'>
                    <FaClipboardList
                        className='copy-icon'
                        size='15'
                    />
                    <span className='u-textMedium'>
                        {t('Copy error message for support')}
                    </span>
                </span>
      );
      break;
    case 1:
      copyLabel = (
                <span className='copy-text'>
                    <FaClipboardCheck
                        className='copy-icon'
                        size='15'
                    />
                    <span className='u-textMedium'>
                        {t('Copied')}
                    </span>
                </span>
      );
      break;
    case 2:
      copyLabel = (
                <span className='copy-text'>
                    <FaClipboard
                        className='copy-icon'
                        size='15'
                    />
                    <span className='u-textMedium'>
                        {t('Failed')}
                    </span>
                </span>
      );
      break;
    default:
      break;
  }

  return (
        <React.Fragment>
            <div
                className='error-copy'
                onClick={() => copyString(message, setCopyState)}
                role='link'
            >
                {copyLabel}
            </div>
            <div className={`error-collapsible ${open ? 'open' : ''}`}>
                <span
                    className='u-textMedium collapsible-toggle'
                    onClick={() => setOpen(!open)}
                    role='link'
                >
                    <SvgIcon
                        Icon={ChevronRight}
                        className='collapsible-chevron'
                        size='12'
                    />
                    <span className='collapsible-label'>{t('Show error message')}</span>
                </span>
                {open && (
                    <div className='collapsible-body'>
                        <pre>
                            {message}
                        </pre>
                    </div>
                )}
            </div>
        </React.Fragment>
  );
}
