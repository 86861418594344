
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AllModules() {
  const { t } = useTranslation('tourTranslations');

  return (
        <svg
            width='286'
            height='217'
            viewBox='0 0 286 217'
            direction='ltr'
        >
            <defs>
                <rect
                    id='prefix__alle_module_ueberblick-b'
                    width='270'
                    height='201'
                    x='0'
                    y='0'
                    rx='4'
                />
                <filter
                    id='prefix__alle_module_ueberblick-a'
                    width='109.6%'
                    height='112.9%'
                    x='-4.8%'
                    y='-5.5%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dy='2'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='4'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
                    />
                </filter>
                <path
                    id='prefix__alle_module_ueberblick-c'
                    d='M4 0h262a4 4 0 0 1 4 4v156H0V4a4 4 0 0 1 4-4z'
                />
                <path
                    id='prefix__alle_module_ueberblick-e'
                    d='M0 0h270v160H0z'
                />
                <path
                    id='prefix__alle_module_ueberblick-g'
                    d='M.2.1h184.65v90.468H.2z'
                />
                <path
                    id='prefix__alle_module_ueberblick-i'
                    d='M.125.1H76.5v90.468H.125z'
                />
                <path
                    id='prefix__alle_module_ueberblick-k'
                    d='M.125.025H50.05V31.54H.125z'
                />
                <path
                    id='prefix__alle_module_ueberblick-m'
                    d='M.2.2h80.65v45.365H.2z'
                />
                <path
                    id='prefix__alle_module_ueberblick-o'
                    d='M.225.1h36.25v19.777H.225z'
                />
                <path
                    id='prefix__alle_module_ueberblick-q'
                    d='M.225.225h36.25v27.14H.225z'
                />
                <path
                    id='prefix__alle_module_ueberblick-s'
                    d='M4.551 0l-4 1.757v3.09c0 1 .434 2.338 1.693 3.483C3.727 9.68 4.55 9.778 4.55 9.778s.825-.098 2.31-1.448c1.257-1.145 1.69-2.483 1.69-3.483v-3.09L4.551 0z'
                />
                <filter id='prefix__alle_module_ueberblick-t'>
                    <feColorMatrix
                        in='SourceGraphic'
                        values='0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0'
                    />
                </filter>
            </defs>
            <g
                fill='none'
                fillRule='evenodd'
            >
                <g transform='translate(8 6)'>
                    <use
                        fill='#000'
                        filter='url(#prefix__alle_module_ueberblick-a)'
                        xlinkHref='#prefix__alle_module_ueberblick-b'
                    />
                    <use
                        fill='#FFF'
                        xlinkHref='#prefix__alle_module_ueberblick-b'
                    />
                    <mask
                        id='prefix__alle_module_ueberblick-d'
                        fill='#fff'
                    >
                        <use xlinkHref='#prefix__alle_module_ueberblick-c'/>
                    </mask>
                    <use
                        fill='#FFF'
                        xlinkHref='#prefix__alle_module_ueberblick-c'
                    />
                    <g mask='url(#prefix__alle_module_ueberblick-d)'>
                        <mask
                            id='prefix__alle_module_ueberblick-f'
                            fill='#fff'
                        >
                            <use xlinkHref='#prefix__alle_module_ueberblick-e'/>
                        </mask>
                        <g mask='url(#prefix__alle_module_ueberblick-f)'>
                            <g transform='translate(-36 -4.75)'>
                                <path
                                    fill='#99CCD3'
                                    fillRule='nonzero'
                                    d='M.1 4.75h341.825v160H.1z'
                                />
                                <path
                                    fill='#D9EEFD'
                                    fillRule='nonzero'
                                    d='M49.2.1h184.65v90.468H49.2z'
                                />
                                <path
                                    fill='#F2F2F2'
                                    fillRule='nonzero'
                                    d='M105.925 34.95v-2.225h-17.8v2.225H84.05V87.6H110V34.95zm50.05-2.225V31.25h-.725v-1.5h-3.725v1.5h-.725v1.475h-.75V88.35h6.675V32.725z'
                                />
                                <path
                                    fill='#E6E6E6'
                                    fillRule='nonzero'
                                    d='M70.7 75h12.6v12.607H70.7z'
                                />
                                <path
                                    fill='#CCC'
                                    fillRule='nonzero'
                                    d='M94.425 54.225V52H83.3v2.225h-2.95v7.425h-5.2V87.6H97.4V54.225z'
                                />
                                <path
                                    fill='#E6E6E6'
                                    fillRule='nonzero'
                                    d='M185.65 51.275V48.3h-2.225v-3.7h-17.05v3.7h-2.225v2.975h-5.95V86.85h33.375V51.275z'
                                />
                                <path
                                    fill='#CCC'
                                    fillRule='nonzero'
                                    d='M155.25 70.55h22.25v17.797h-22.25z'
                                />
                                <path
                                    fill='#F2F2F2'
                                    fillRule='nonzero'
                                    d='M224.2 52v2.975H219V88.35h11.875V52z'
                                />
                                <path
                                    fill='#E6E6E6'
                                    fillRule='nonzero'
                                    d='M217.525 29.75v-1.475H215.3v-.75h-2.225V26.8h-4.45v.725H206.4V26.8h-2.225v-1.5h-2.225v1.5h-3.7v1.475h-.75v1.475h-1.475v57.1H219v-57.1z'
                                />
                                <g
                                    opacity='.4'
                                    transform='translate(49)'
                                >
                                    <mask
                                        id='prefix__alle_module_ueberblick-h'
                                        fill='#fff'
                                    >
                                        <use xlinkHref='#prefix__alle_module_ueberblick-g'/>
                                    </mask>
                                    <g
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        mask='url(#prefix__alle_module_ueberblick-h)'
                                    >
                                        <path d='M85.753-28.34l17.602 10.163-70.179 121.553-17.602-10.163zm19.117 12.649l4.59 2.65-70.18 121.553-4.59-2.65zm10.888 4.213l1.905 1.1-70.179 121.553-1.905-1.1zM37.25-52.417l11.561 6.675L-21.367 75.81l-11.562-6.675zm13.496 7.792l4.503 2.6-70.178 121.553-4.504-2.6zm112.774 11.4l26.696 15.412-80.501 139.432-26.696-15.412zm29.878 16.777l4.481 2.575L117.378 126.2l-4.48-2.575z'/>
                                    </g>
                                </g>
                                <path
                                    fill='#C7B299'
                                    fillRule='nonzero'
                                    d='M50.675.1h3.7v90.468h-3.7z'
                                />
                                <path
                                    fill='#EAD7C2'
                                    fillRule='nonzero'
                                    d='M233.825 86.875v3.7H49.18v-3.7z'
                                />
                                <path
                                    fill='#EAD7C2'
                                    fillRule='nonzero'
                                    d='M230.125.1h3.7v90.468h-3.7zM49.2.1h3.7v90.468h-3.7z'
                                />
                                <path
                                    fill='#D9EEFD'
                                    fillRule='nonzero'
                                    d='M240.9.1h78.6v90.468h-78.6z'
                                />
                                <path
                                    fill='#F2F2F2'
                                    fillRule='nonzero'
                                    d='M251.4 34.95v-2.225h23.15v2.225h5.325V87.6h-33.8V34.95z'
                                />
                                <path
                                    fill='#CCC'
                                    fillRule='nonzero'
                                    d='M297.25 87.6h-16.4V74.993h16.4z'
                                />
                                <path
                                    fill='#E6E6E6'
                                    fillRule='nonzero'
                                    d='M266.35 54.225V52h14.475v2.225h3.875v7.425h6.75V87.6H262.5V54.225z'
                                />
                                <g
                                    opacity='.4'
                                    transform='translate(242.25)'
                                >
                                    <mask
                                        id='prefix__alle_module_ueberblick-j'
                                        fill='#fff'
                                    >
                                        <use xlinkHref='#prefix__alle_module_ueberblick-i'/>
                                    </mask>
                                    <g
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        mask='url(#prefix__alle_module_ueberblick-j)'
                                    >
                                        <path d='M85.673-28.327l17.602 10.162-70.179 121.553-17.602-10.162zm19.117 12.623l4.59 2.65L39.2 108.5l-4.59-2.65zm10.887 4.238l1.905 1.1-70.178 121.553-1.906-1.1zM37.195-52.404l11.561 6.675-70.178 121.552-11.562-6.675zm13.47 7.792l4.504 2.6L-15.01 79.541l-4.503-2.6z'/>
                                    </g>
                                </g>
                                <path
                                    fill='#C7B299'
                                    fillRule='nonzero'
                                    d='M242.375.1h3.7v90.468h-3.7z'
                                />
                                <path
                                    fill='#EAD7C2'
                                    fillRule='nonzero'
                                    d='M319.5 86.85v3.7h-78.606v-3.7z'
                                />
                                <path
                                    fill='#EAD7C2'
                                    fillRule='nonzero'
                                    d='M240.9.1h3.7v90.468h-3.7z'
                                />
                                <path
                                    fill='#CCC'
                                    fillRule='nonzero'
                                    d='M249.375 103.425h75.8v79.338h-75.8z'
                                />
                                <path
                                    fill='#B3B3B3'
                                    fillRule='nonzero'
                                    d='M251.875 107.2h70.8v23.483h-70.8zm0 24.55h70.8v23.483h-70.8zm0 24.525h70.8v23.483h-70.8z'
                                />
                                <path
                                    fill='#E6E6E6'
                                    fillRule='nonzero'
                                    d='M281.8 112.075h10.95v5.815H281.8zm0 23.55h10.95v5.817H281.8zm0 24.3h10.95v5.815H281.8z'
                                />
                                <path
                                    fill='gray'
                                    fillRule='nonzero'
                                    d='M308.225 84.05h-41.9a.5.5 0 0 0-.5.5v18.575a.5.5 0 0 0 .5.5h41.9a.5.5 0 0 0 .5-.5V84.55a.5.5 0 0 0-.5-.5'
                                />
                                <path
                                    fill='#333'
                                    fillRule='nonzero'
                                    d='M273.2 89.05h28.15v11.295H273.2z'
                                />
                                <path
                                    fill='#1A1A1A'
                                    fillRule='nonzero'
                                    d='M274.15 98.65h25.95v1.772h-25.95z'
                                />
                                <path
                                    fill='#F0F3BD'
                                    fillRule='nonzero'
                                    d='M269.9 88.675h-.9a.175.175 0 0 1-.175-.175v-.475c0-.097.078-.175.175-.175h.9c.097 0 .175.078.175.175v.475a.175.175 0 0 1-.175.175'
                                />
                                <path
                                    fill='#00C3A0'
                                    fillRule='nonzero'
                                    d='M270.05 86.35h-2.175a.175.175 0 0 1-.175-.175v-.5c0-.097.078-.175.175-.175h2.175c.097 0 .175.078.175.175v.5a.175.175 0 0 1-.175.175'
                                />
                                <path
                                    fill='#666'
                                    fillRule='nonzero'
                                    d='M298.8 71.1h-23.05l-.4 12.95h23.85z'
                                />
                                <path
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    d='M277.575 69.275L277.1 84.05h20.35l-.475-14.775zM298.9 99.2h-23.225l-3.125 8.65H302z'
                                />
                                <path
                                    fill='#23394C'
                                    fillRule='nonzero'
                                    d='M276.725 100.725l-.2.65h21.325l-.3-.65zm-.3 1.775l-.2.675h21.925l-.3-.675zm-.6 1.8l-.2.65h22.825l-.3-.65z'
                                    opacity='.23'
                                />
                                <path
                                    fill='#CCC'
                                    fillRule='nonzero'
                                    d='M59.95 187.9h-3.6l-1.6-66.65h6.8z'
                                />
                                <path
                                    fill='#E6E6E6'
                                    fillRule='nonzero'
                                    d='M239.05 206.025h3.5l-12.225-85.25h-6.575zm-201.05 0h-3.5l12.225-85.25H53.3z'
                                />
                                <path
                                    fill='#CCC'
                                    fillRule='nonzero'
                                    d='M215.8 187.9h3.6l1.6-66.65h-6.8z'
                                />
                                <path
                                    fill='#DDBF9E'
                                    fillRule='nonzero'
                                    d='M39.725 104.15L13.15 118.575H263.9l-28.525-14.425z'
                                />
                                <path
                                    fill='#F1E5D8'
                                    fillRule='nonzero'
                                    d='M13.15 118.575H263.9v3.763H13.15z'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M100.975 113.075h81.1l-6.1-5.475h-68.9z'
                                />
                                <path
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    d='M104.375 112.075l3.875-3.475h66.55l3.85 3.475z'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M141.688 112.65v-4.625h-.338v4.625m-3.875.025l.55-4.65-.325-.025-.525 4.625m-3.875.075l1.075-4.65-.325-.075-1.075 4.65m-3.875.075l1.6-4.625-.3-.1-1.6 4.625m-3.9.125l2.15-4.65-.3-.125-2.15 4.65m-3.9.125l2.7-4.625-.275-.15-2.7 4.625m-3.925.175l3.225-4.65-.25-.175-3.25 4.65m-3.925.175l3.775-4.625-.25-.2-3.775 4.625m-3.95.225l4.3-4.65-.225-.225-4.325 4.65m37.175-4.525l.525 4.65.325-.05-.55-4.625m3.35.05l1.075 4.65.3-.075-1.075-4.65m3.35.1l1.625 4.625.3-.1-1.625-4.625m3.375.1l2.15 4.65.275-.125-2.15-4.65m3.375.15l2.7 4.625.275-.15-2.7-4.625m3.4.15l3.225 4.65.25-.175-3.225-4.65m3.4.2l3.775 4.625.25-.2-3.775-4.625m3.4.2l4.325 4.65.225-.225-4.3-4.65'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M105.325 110.513h72.75v-.338h-72.75'
                                />
                                <path
                                    fill='#333'
                                    fillRule='nonzero'
                                    d='M100.975 113.075h81.1v1h-81.1z'
                                />
                                <path
                                    stroke='#333'
                                    strokeWidth='1.688'
                                    d='M82.225 114.35c-.125-.675-.175-1.175.475-1.3l.6-.1h.075l.45 2.325h0l-.675.125c-.65.15-.8-.4-.925-1.05z'
                                />
                                <path
                                    fill='#B3B3B3'
                                    fillRule='nonzero'
                                    d='M96.25 113.275l-.55-2.925a.225.225 0 0 0-.275-.175l-2.6.5a.2.2 0 0 0-.175.25l.55 2.925a.25.25 0 0 0 .25.175l2.625-.5a.275.275 0 0 0 .175-.25'
                                />
                                <path
                                    fill='#F2F2F2'
                                    fillRule='nonzero'
                                    d='M94.225 111.575v-.1a.2.2 0 0 1 .15-.225l.575-.1a.15.15 0 0 1 .2.15v.1a.2.2 0 0 1-.15.225l-.575.1a.2.2 0 0 1-.225-.15m.25 1.175v-.075a.175.175 0 0 1 .15-.225l.55-.125a.2.2 0 0 1 .225.175v.075a.2.2 0 0 1-.15.225l-.575.1a.2.2 0 0 1-.225-.15'
                                />
                                <path
                                    className='fill-primary'
                                    fillRule='nonzero'
                                    d='M85.25 111.55l-.025-.05 7.75-1.475.825 4.45-7.7 1.475-.025-.025z'
                                />
                                <path
                                    fill='#333'
                                    fillRule='nonzero'
                                    d='M83.05 114.15c-.225-1.225-.3-2.175.925-2.4l1.125-.225h.125v.05l.825 4.375h.025l-1.275.25c-1.25.225-1.525-.8-1.775-2.05'
                                />
                                <path
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    d='M92.975 112.4l-.45.325c-.05.05-.075.025-.1-.025-.025-.05-.025-.125-.125-.1l-.9.175.175.225c.05.05.225.025.25-.05.025-.075.025-.05.05-.05s.325-.05.35.225v.05c-.025.05-.325.075-.35.05a.19.19 0 0 0-.1-.05.25.25 0 0 1-.375-.15.375.375 0 0 0-.5-.175h-.15c-.1.025-.175.025-.225.15-.05.125-.075.1-.15.125-.075.025-.4-.075-.375-.275a.275.275 0 0 1 .175-.275.325.325 0 0 1 .325.075.225.225 0 0 0 .35-.075 1.4 1.4 0 0 0 .1-.3.15.15 0 0 1 .15-.125h.225a.3.3 0 0 0 .175-.125.2.2 0 0 1 .3-.05.225.225 0 0 1-.25.375c-.125-.125-.275 0-.425 0l-.1.275c0 .05.025.025.05.025l1.25-.225c.1 0 .05-.075.05-.125s-.025-.1.075-.075l.475.15c.025 0 .05 0 .05.025'
                                />
                                <path
                                    fill='gray'
                                    fillRule='nonzero'
                                    d='M46.4 112.775h24.575v3.513H46.4z'
                                />
                                <path
                                    fill='#666'
                                    fillRule='nonzero'
                                    d='M71 112.775H46.4l4.7-1.75h24.575z'
                                />
                                <path
                                    stroke='gray'
                                    strokeWidth='1.688'
                                    d='M50.225 118.35a9.025 9.025 0 0 0 0 1.175c.025 3.7.05 5.575.525 6.525 1.675 3.45 9.225 3.95 9.875 2.5a.675.675 0 0 0-.025-.55c-.525-1.675-5.1-2.875-6.875-.875a3.1 3.1 0 0 0-.575 2.925c.65 2.125 3.625 3.05 4.25 2.425.175-.175.175-.575.05-.85-.55-1.1-3.575-1.15-5.475.175a5.06 5.06 0 0 0-1.175 1.175'
                                />
                                <rect
                                    width='2.925'
                                    height='1.002'
                                    x='48.75'
                                    y='114.125'
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    rx='.1'
                                />
                                <rect
                                    width='2.925'
                                    height='1.002'
                                    x='52.25'
                                    y='114.125'
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    rx='.1'
                                />
                                <rect
                                    width='1.175'
                                    height='1'
                                    x='68.875'
                                    y='114.425'
                                    fill='#F0F3BD'
                                    fillRule='nonzero'
                                    rx='.05'
                                />
                                <rect
                                    width='1.175'
                                    height='1'
                                    x='67.125'
                                    y='114.425'
                                    fill='#F0F3BD'
                                    fillRule='nonzero'
                                    rx='.05'
                                />
                                <rect
                                    width='2.625'
                                    height='1'
                                    x='48.9'
                                    y='114.225'
                                    fill='#E6E6E6'
                                    fillRule='nonzero'
                                    rx='.1'
                                />
                                <path
                                    fill='#333'
                                    fillRule='nonzero'
                                    d='M51.5 114.625l-.025 2.575-.55.975H49.45l-.125-.25-.425-.725.025-2.575z'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M50.575 118.55h-.85v-.375h.85zm.25-1.975h-1.25l.025-1.05h1.225z'
                                />
                                <path
                                    fill='#E6E6E6'
                                    fillRule='nonzero'
                                    d='M47.65 134.225l1.325 1.55-1.65 1.45h-.075l-1.275-1.5c-.025-.025-.025-.05 0-.05l1.675-1.45z'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M47.424 134.415l.754-.656 1.347 1.55-.755.656z'
                                />
                                <path
                                    fill='#CCC'
                                    fillRule='nonzero'
                                    d='M47.159 136.262l.756-.654.655.755-.756.655z'
                                />
                                <path
                                    fill='#999'
                                    fillRule='nonzero'
                                    d='M47.364 136.173l.756-.655.654.756-.755.655z'
                                />
                                <path
                                    fill='#CCC'
                                    fillRule='nonzero'
                                    d='M46.647 135.41l.754-.656.656.755-.754.656z'
                                />
                                <path
                                    fill='#999'
                                    fillRule='nonzero'
                                    d='M46.85 135.321l.755-.656.656.755-.754.656z'
                                />
                                <path
                                    fill='#333'
                                    fillRule='nonzero'
                                    d='M47.325 134.125L49.3 132.4l1.125-.225.975 1.125-.1.25-.275.825-2 1.725z'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M50.533 132.182l.755-.656.858.987-.755.656zm-2.005 1.798l1.076-.935.816.94-1.075.935z'
                                />
                                <path
                                    fill='#999'
                                    fillRule='nonzero'
                                    d='M71 116.3v-3.525l4.675-1.75v3.525z'
                                />
                                <path
                                    fill='#35B9AB'
                                    fillRule='nonzero'
                                    d='M55.95 62.625s-7.2 17.6-6.1 37.825l4.1-3.675s.675-24.55 2-34.15'
                                />
                                <path
                                    fill='#35B9AB'
                                    fillRule='nonzero'
                                    d='M45.4 62.25s-3.55 18.7 1.6 38.275l3.275-4.425s-4.25-24.175-4.875-33.85'
                                />
                                <path
                                    fill='#027E71'
                                    fillRule='nonzero'
                                    d='M45.4 62.25l-.075.35c-.175 8.725.125 17.6 1.35 26.25.4 2.975.95 5.975 1.925 8.825 0 .025.025.025.05.025a.05.05 0 0 0 .05-.05c-1.85-5.4-2.35-11.3-2.75-16.95-.375-5.075-.525-10.175-.525-15.275V62.85c0-.2-.025-.4-.025-.6m10.55.375l-.1.225c-1.525 8.925-2.95 17.875-3.9 26.875-.275 2.375-.525 4.825-.475 7.225 0 .05.025.05.05.05s.05 0 .05-.05c-.125-4.975.775-10.075 1.45-15a589.201 589.201 0 0 1 2.4-15.85c.125-.75.25-1.475.35-2.2.1-.725.125-.875.175-1.275'
                                />
                                <path
                                    fill='#ACB0B4'
                                    fillRule='nonzero'
                                    d='M54.5 107.45h-9.775l-1.5-14.025H56.5z'
                                />
                                <path
                                    fill='#333'
                                    fillRule='nonzero'
                                    d='M243.05 71.4h-51.425c-.58 0-1.05.47-1.05 1.05v34.075c0 .58.47 1.05 1.05 1.05h51.425a1.051 1.051 0 0 0 1.075-1.05V72.45a1.05 1.05 0 0 0-1.075-1.05'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M191.1 107.575l-8.025 7.8h67.85l-7.725-7.8z'
                                />
                                <path
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    d='M187.9 112.35l4.1-3.925h50.325l3.875 3.725z'
                                />
                                <path
                                    fill='#333'
                                    fillRule='nonzero'
                                    d='M183.075 115.375h67.85v1h-67.85z'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M203.625 113.9h-.025l1.225-2.95h-3.075l-1.35 2.525h-.025l1.325-2.5h-2.975l-1.475 2.35h-.025l1.45-2.325h-3.025l-1.625 2.225H194l1.6-2.2h-3.025l-1.85 2.225h-.05l1.85-2.2h-3.4V111h3.425l1.125-1.325h-3.9v-.175h3.925l1.325-1.575h.05l-1.3 1.55h2.875l1.15-1.575h.025l-1.125 1.55h2.875l.975-1.575h.05l-.975 1.55h2.85l.85-1.575h.025l-.825 1.55h2.9l.65-1.575h.05l-.65 1.55h2.875l.5-1.575h.025l-.475 1.55h2.875l.325-1.575h.05l-.325 1.575h2.875l.175-1.575h.05l-.175 1.725h2.875v-1.575h.05v1.575h2.875l-.15-1.575h.05l.15 1.575h2.875l-.325-1.575h.05l.325 1.575h2.875l-.475-1.55h.05l.475 1.575h2.875l-.65-1.55h.05l.65 1.575h2.9l-.825-1.55h.025l.85 1.575h2.875l-1-1.55h.05l1 1.575h2.875l-1.15-1.55h.025l1.175 1.575h2.875l-1.3-1.55h.025l1.325 1.575h4.2v.05h-4.175l1.125 1.325h3.2v.05h-3.15l2.075 2.5h-.025l-2.125-2.525h-3l1.825 2.5h-.05l-1.85-2.525h-3.025l1.475 2.325h-.05l-1.475-2.35h-3.025l1.225 2.325h-.05l-1.225-2.35h-3.025l1.05 2.5h-.025l-1.075-2.525H226.4l.85 2.675h-.05l-.85-2.7h-3.025l.575 2.7h-.05l-.575-2.7h-3.025l.275 2.525h-.05l-.275-2.525h-3.025v2.2h-.05v-2.2H214.1l-.225 2.2h-.05l.225-2.2h-3.025l-.35 1.7h-.05l.35-1.7h-3.025l-.7 2.225h-.025l.675-2.2h-3.025l-1.25 2.95zm35.05-3.025h3.025l-1.125-1.325H237.7l.975 1.325zm-3.075 0h3.025l-.975-1.325h-2.875l.825 1.325zm-3.075 0h3.025l-.825-1.325h-2.9l.7 1.325zm-3.05 0h3l-.7-1.325H228.9l.575 1.325zm-3.075 0h3.025l-.575-1.325h-2.875l.425 1.325zm-3.075 0h3.025l-.425-1.325h-2.875l.275 1.325zm-3.075 0h3.025L223 109.55h-2.9l.15 1.325zm-3.075 0h3.025l-.125-1.325h-2.9v1.325zm-3.075 0h3.025v-1.325h-2.875l-.15 1.325zm-3.075 0h3.025l.15-1.325h-2.875l-.3 1.325zm-3.05 0H211l.275-1.325h-2.9l-.4 1.325zm-3.075 0h3.025l.4-1.325h-2.875l-.55 1.325zm-3.125 0h3.075l.55-1.325h-2.9l-.725 1.325zm-3.025 0h2.975l.725-1.325h-2.85l-.85 1.325zm-3.075 0h3.025l.85-1.325h-2.9l-.975 1.325zm-3.075 0h3.025l.975-1.325h-2.875l-1.125 1.325z'
                                />
                                <path
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    d='M210.725 112.625l-.625 2.25h14.025l-.5-2.25z'
                                />
                                <path
                                    fill='#E9EDF0'
                                    fillRule='nonzero'
                                    d='M192.375 73.775H242.3v31.515h-49.925z'
                                />
                                <g transform='translate(192.25 73.75)'>
                                    <mask
                                        id='prefix__alle_module_ueberblick-l'
                                        fill='#fff'
                                    >
                                        <use xlinkHref='#prefix__alle_module_ueberblick-k'/>
                                    </mask>
                                    <g
                                        fillRule='nonzero'
                                        mask='url(#prefix__alle_module_ueberblick-l)'
                                    >
                                        <g opacity='.6'>
                                            <path
                                                className='fill-primary'
                                                d='M52 6.425c-9.225 12.1-14.825 13.8-18.25 12.975-3.95-.95-4.2-5.05-9.625-6.825-5.825-1.925-8.825 1.725-15.6.675-2.8-.425-6.9-1.75-11.55-6.25.017 8.25.058 16.517.125 24.8h55.45c-.167-8.45-.35-16.908-.55-25.375z'
                                            />
                                            <path
                                                className='fill-primary'
                                                d='M51.575 11.3c-10.6 9.725-16.25 10.55-19.5 9.2-2-.825-3.3-2.575-6.825-3.325-3.525-.75-6.55.225-9.2.825-3.55.8-8.875 1.025-16.3-1.8v15.525H51.3c.1-6.8.192-13.608.275-20.425z'
                                            />
                                            <path
                                                fill='#FFF'
                                                d='M51.575 11.3c-10.6 9.725-16.25 10.55-19.5 9.2-2-.825-3.3-2.575-6.825-3.325-3.525-.75-6.55.225-9.2.825-3.55.8-8.875 1.025-16.3-1.8v15.525H51.3c.1-6.8.192-13.608.275-20.425z'
                                                opacity='.3'
                                            />
                                            <path
                                                className='fill-primary'
                                                d='M50.325 12.975A31.425 31.425 0 0 1 37.1 22.6c-2.575.95-10 3.75-17.575.975-2.025-.75-2.15-1.2-4.025-1.95-5.725-2.3-11.875-1.175-17.15.825v9.275h51.9l.075-18.75z'
                                            />
                                            <path
                                                fill='#000'
                                                d='M50.325 12.975A31.425 31.425 0 0 1 37.1 22.6c-2.575.95-10 3.75-17.575.975-2.025-.75-2.15-1.2-4.025-1.95-5.725-2.3-11.875-1.175-17.15.825v9.275h51.9l.075-18.75z'
                                                opacity='.1'
                                            />
                                        </g>
                                        <path
                                            fill='#FFF'
                                            d='M21.035-27.963l7.08 4.088-42.963 74.413-7.08-4.088zm8.251 4.743l2.75 1.588-42.963 74.413-2.75-1.588z'
                                            opacity='.4'
                                        />
                                    </g>
                                </g>
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M180.2 95.85H96.85A1.85 1.85 0 0 1 95 94v-6.925h87.05V94a1.85 1.85 0 0 1-1.85 1.85'
                                />
                                <path
                                    fill='#333'
                                    fillRule='nonzero'
                                    d='M182.05 87.075H95V37.9a1.676 1.676 0 0 1 1.675-1.7h83.65c.953 0 1.725.772 1.725 1.725v49.15zm-29.375 16.375H147.6v-7.6h-17.825v7.6H124.4a2.575 2.575 0 0 0-2.575 2.55h33.4a2.55 2.55 0 0 0-2.55-2.55'
                                />
                                <path
                                    fill='#F1F1F1'
                                    fillRule='nonzero'
                                    d='M140.275 91.45a1.8 1.8 0 1 1-1.775-1.8 1.775 1.775 0 0 1 1.775 1.8'
                                />
                                <path
                                    fill='#E9EDF0'
                                    fillRule='nonzero'
                                    d='M98.2 38.95h80.65v45.365H98.2z'
                                />
                                <g transform='translate(98 38.75)'>
                                    <mask
                                        id='prefix__alle_module_ueberblick-n'
                                        fill='#fff'
                                    >
                                        <use xlinkHref='#prefix__alle_module_ueberblick-m'/>
                                    </mask>
                                    <g
                                        fillRule='nonzero'
                                        mask='url(#prefix__alle_module_ueberblick-n)'
                                    >
                                        <g opacity='.6'>
                                            <path
                                                className='fill-primary'
                                                d='M84 5.025C69.075 24.575 60.05 27.3 54.5 25.975c-6.4-1.55-6.775-8.15-15.525-11.025-9.425-3.125-14.275 2.8-25.2 1.125C9.275 15.375 2.6 13.2-4.9 5.925L-4.675 46H84.9L84 5.025z'
                                            />
                                            <path
                                                className='fill-primary'
                                                d='M83.325 12.925c-17.15 15.675-26.3 17-31.525 14.85-3.225-1.35-5.325-4.175-11.025-5.4-6.1-1.325-10.575.4-14.85 1.35C20.175 25 11.575 25.375-.4 20.8v25.075h83.275l.45-32.95z'
                                            />
                                            <path
                                                fill='#FFF'
                                                d='M83.325 12.925c-17.15 15.675-26.3 17-31.525 14.85-3.225-1.35-5.325-4.175-11.025-5.4-6.1-1.325-10.575.4-14.85 1.35C20.175 25 11.575 25.375-.4 20.8v25.075h83.275l.45-32.95z'
                                                opacity='.3'
                                            />
                                            <path
                                                className='fill-primary'
                                                d='M81.3 15.625c-3.325 4.05-10.25 11.325-21.4 15.525-4.125 1.55-16.15 6.05-28.35 1.575-3.275-1.2-3.475-1.925-6.525-3.15-9.25-3.75-19.15-1.925-27.675 1.35v14.95h83.825l.125-30.25z'
                                            />
                                            <path
                                                fill='#000'
                                                d='M81.3 15.625c-3.325 4.05-10.25 11.325-21.4 15.525-4.125 1.55-16.15 6.05-28.35 1.575-3.275-1.2-3.475-1.925-6.525-3.15-9.25-3.75-19.15-1.925-27.675 1.35v14.95h83.825l.125-30.25z'
                                                opacity='.1'
                                            />
                                        </g>
                                        <path
                                            fill='#FFF'
                                            d='M33.97-50.532l11.431 6.6L-23.986 76.25l-11.432-6.6zm13.354 7.702l4.438 2.562-69.387 120.183-4.438-2.562z'
                                            opacity='.4'
                                        />
                                    </g>
                                </g>
                                <rect
                                    width='42.45'
                                    height='29.385'
                                    x='64.375'
                                    y='77.1'
                                    fill='#033347'
                                    fillRule='nonzero'
                                    rx='1.4'
                                />
                                <rect
                                    width='42.025'
                                    height='28.975'
                                    x='64.575'
                                    y='77.3'
                                    fill='#044D6B'
                                    fillRule='nonzero'
                                    rx='1.275'
                                />
                                <path
                                    fill='#E9EDF0'
                                    fillRule='nonzero'
                                    d='M67.475 78.225h36.25v27.14h-36.25z'
                                />
                                <circle
                                    cx='65.975'
                                    cy='91.8'
                                    r='1'
                                    fill='#CDE0E8'
                                    fillRule='nonzero'
                                />
                                <circle
                                    cx='105.2'
                                    cy='91.8'
                                    r='1'
                                    fill='#033347'
                                    fillRule='nonzero'
                                />
                                <g
                                    opacity='.6'
                                    transform='translate(67.25 85.5)'
                                >
                                    <mask
                                        id='prefix__alle_module_ueberblick-p'
                                        fill='#fff'
                                    >
                                        <use xlinkHref='#prefix__alle_module_ueberblick-o'/>
                                    </mask>
                                    <g
                                        fillRule='nonzero'
                                        mask='url(#prefix__alle_module_ueberblick-p)'
                                    >
                                        <path
                                            className='fill-primary'
                                            d='M38.925 1.9c-7.1 9.275-11.375 10.575-14.025 9.95-2.65-.625-3.2-3.875-7.375-5.25S10.75 7.95 5.55 7.15C3.425 6.8.25 5.775-3.3 2.325l.1 19.025h42.55c-.15-6.467-.292-12.95-.425-19.45z'
                                        />
                                        <path
                                            className='fill-primary'
                                            d='M38.6 5.65c-8.15 7.45-12.5 8.075-14.975 7.05-1.525-.625-2.525-1.975-5.225-2.575-2.7-.6-5.05.2-7.075.65-2.725.625-6.8.8-12.5-1.375v11.9h39.55L38.6 5.65z'
                                        />
                                        <path
                                            fill='#FFF'
                                            d='M38.6 5.65c-8.15 7.45-12.5 8.075-14.975 7.05-1.525-.625-2.525-1.975-5.225-2.575-2.7-.6-5.05.2-7.075.65-2.725.625-6.8.8-12.5-1.375v11.9h39.55L38.6 5.65z'
                                            opacity='.3'
                                        />
                                        <path
                                            className='fill-primary'
                                            d='M37.625 6.925a23.825 23.825 0 0 1-10.15 7.375c-1.975.75-7.675 2.9-13.475.75-1.55-.575-1.65-.9-3.1-1.5-4.375-1.775-9.1-.9-13.15.65v7.1h39.825l.05-14.375z'
                                        />
                                        <path
                                            fill='#000'
                                            d='M37.625 6.925a23.825 23.825 0 0 1-10.15 7.375c-1.975.75-7.675 2.9-13.475.75-1.55-.575-1.65-.9-3.1-1.5-4.375-1.775-9.1-.9-13.15.65v7.1h39.825l.05-14.375z'
                                            opacity='.1'
                                        />
                                    </g>
                                </g>
                                <g transform='translate(67.25 78)'>
                                    <mask
                                        id='prefix__alle_module_ueberblick-r'
                                        fill='#fff'
                                    >
                                        <use xlinkHref='#prefix__alle_module_ueberblick-q'/>
                                    </mask>
                                    <g
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        mask='url(#prefix__alle_module_ueberblick-r)'
                                        opacity='.4'
                                    >
                                        <path d='M15.739-15.517l4.156 2.4L-5.328 30.57l-4.157-2.4zm4.848 2.775l1.624.937-25.224 43.688-1.624-.937z'/>
                                    </g>
                                </g>
                                <path
                                    fill='#044D6B'
                                    fillRule='nonzero'
                                    d='M83.225 87.15v19.7c.005.109-.003.218-.025.325a1.6 1.6 0 0 0-.05.175.1.1 0 0 1-.025.075c-.05.146-.126.281-.225.4l-.15.175a1.15 1.15 0 0 1-.175.175l-.125.075-.15.075-.225.1h-.15l-.325.05h-8.1l-.35-.05h-.1l-.25-.1-.125-.05c0-.025-.025-.025-.05-.05l-.1-.05-.275-.275-.1-.125-.125-.225a.85.85 0 0 1-.1-.3 1.025 1.025 0 0 1-.05-.4v-19.7A1.625 1.625 0 0 1 73.5 85.5h8.1a1.625 1.625 0 0 1 1.625 1.65z'
                                />
                                <path
                                    fill='#044D6B'
                                    fillRule='nonzero'
                                    d='M83.225 92.125V89.8c.09 0 .175.034.239.095a.321.321 0 0 1 .099.23V91.8a.319.319 0 0 1-.1.23.344.344 0 0 1-.238.095zm-11.187-1.15h-.052a.28.28 0 0 1-.286-.275v-.6a.28.28 0 0 1 .286-.275h.052V91v-.025zm0 1.4h-.052a.28.28 0 0 1-.286-.275v-.6a.28.28 0 0 1 .286-.275h.052V92.4v-.025z'
                                />
                                <path
                                    fill='#D9EEFD'
                                    fillRule='nonzero'
                                    d='M82.875 87.15v19.7c0 .1-.025.175-.025.25l-.05.125v.075a2.075 2.075 0 0 1-.175.325l-.125.125-.15.125-.075.05h-.025l-.075.05h-.025l-.15.075h-8.85l-.2-.075-.075-.05c-.025 0-.05 0-.05-.025l-.075-.05a.6.6 0 0 1-.225-.2l-.075-.1a.625.625 0 0 1-.1-.175l-.075-.2a1.475 1.475 0 0 1-.05-.325v-19.7a1.275 1.275 0 0 1 1.275-1.3h8.1c.524.007.99.335 1.175.825.06.152.094.312.1.475z'
                                />
                                <rect
                                    width='2.275'
                                    height='2.265'
                                    x='72.9'
                                    y='92.325'
                                    fill='#B2D9DB'
                                    fillRule='nonzero'
                                    rx='.625'
                                />
                                <rect
                                    width='2.275'
                                    height='2.265'
                                    x='76.425'
                                    y='92.325'
                                    fill='#F0F3BD'
                                    fillRule='nonzero'
                                    rx='.625'
                                />
                                <rect
                                    width='2.275'
                                    height='2.265'
                                    x='79.975'
                                    y='92.325'
                                    fill='#9BC2D2'
                                    fillRule='nonzero'
                                    rx='.625'
                                />
                                <rect
                                    width='2.275'
                                    height='2.265'
                                    x='72.9'
                                    y='95.625'
                                    className='fill-primary'
                                    fillRule='nonzero'
                                    rx='.625'
                                />
                                <rect
                                    width='2.275'
                                    height='2.265'
                                    x='76.425'
                                    y='95.625'
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    rx='.625'
                                />
                                <rect
                                    width='2.275'
                                    height='2.265'
                                    x='76.425'
                                    y='95.625'
                                    className='fill-primary'
                                    fillRule='nonzero'
                                    rx='.625'
                                />
                                <rect
                                    width='2.275'
                                    height='2.265'
                                    x='79.975'
                                    y='95.625'
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    rx='.625'
                                />
                                <rect
                                    width='2.275'
                                    height='2.265'
                                    x='76.425'
                                    y='95.625'
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    opacity='.5'
                                    rx='.625'
                                />
                                <rect
                                    width='2.275'
                                    height='2.265'
                                    x='72.9'
                                    y='105.175'
                                    fill='#018090'
                                    fillRule='nonzero'
                                    rx='.625'
                                />
                                <rect
                                    width='2.275'
                                    height='2.265'
                                    x='76.425'
                                    y='105.175'
                                    fill='#99CCD3'
                                    fillRule='nonzero'
                                    rx='.625'
                                />
                                <rect
                                    width='2.275'
                                    height='2.265'
                                    x='76.425'
                                    y='105.175'
                                    fill='#99CCD3'
                                    fillRule='nonzero'
                                    opacity='.3'
                                    rx='.625'
                                />
                                <rect
                                    width='2.275'
                                    height='2.265'
                                    x='79.975'
                                    y='105.175'
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    rx='.625'
                                />
                                <rect
                                    width='2.275'
                                    height='2.265'
                                    x='72.9'
                                    y='98.925'
                                    fill='#69A3BB'
                                    fillRule='nonzero'
                                    rx='.625'
                                />
                                <rect
                                    width='2.275'
                                    height='2.265'
                                    x='76.425'
                                    y='98.925'
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    rx='.625'
                                />
                                <circle
                                    cx='75.175'
                                    cy='104.175'
                                    r='1'
                                    fill='#FFF'
                                    fillRule='nonzero'
                                />
                                <circle
                                    cx='76.35'
                                    cy='104.175'
                                    r='1'
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    opacity='.6'
                                />
                                <circle
                                    cx='77.55'
                                    cy='104.175'
                                    r='1'
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    opacity='.6'
                                />
                                <circle
                                    cx='78.725'
                                    cy='104.175'
                                    r='1'
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    opacity='.6'
                                />
                                <circle
                                    cx='79.925'
                                    cy='104.175'
                                    r='1'
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    opacity='.6'
                                />
                                <path
                                    fill='#494949'
                                    fillRule='nonzero'
                                    d='M81.725 86.625h-.575c-.025 0-.025-.025-.05-.05v-.325h.75c0 .025.025.05.025.075v.15a.1.1 0 0 1-.025.075.05.05 0 0 1-.05.05l-.075.025zm-.5-.4h-.1v.31c.014 0 .025.012.025.028h.675v-.31c0-.015-.011-.028-.025-.028h-.575zm.705.079v.304a.169.169 0 0 0 .102-.169c0-.067-.034-.1-.101-.135'
                                />
                                <path
                                    fill='#494949'
                                    fillRule='nonzero'
                                    d='M81.225 86.284h.55v.236c0 .034 0 .034-.025.034h-.55c-.014 0-.025-.015-.025-.034v-.236h.05zm-.405.28a.203.203 0 0 0-.202 0l.1.067.102-.067zm-.14-.093l.13.067.052-.067c-.097-.109-.24-.109-.337 0v.067h.026l.13-.067zm-.005-.124a.24.24 0 0 1 .2.101h.025v-.067c-.062-.1-.153-.159-.25-.159s-.188.058-.25.159l.05.067c.06-.078.143-.115.225-.101zm-.465-.122h.068c.033 0 .033.025.033.05v.275c0 .025 0 .05-.034.05h-.067a.07.07 0 0 1-.034-.05v-.275a.07.07 0 0 1 .034-.05zm-.141.075h.067v.281a.08.08 0 0 1-.033.056h-.068c-.034 0-.034-.028-.034-.056v-.225c.034-.028.034-.056.068-.056zm-.159.08h.034l.067.067v.169c0 .037-.03.067-.067.067h-.034c-.034 0-.034-.034-.034-.067v-.17c0-.033 0-.067.034-.067zm-.141.092h.067v.101c0 .034 0 .068-.034.068h-.033a.068.068 0 0 1-.068-.068v-.101h.068zm-6.344-.272a.175.175 0 0 0-.2.2.2.2 0 0 0 .2.225c.124 0 .225-.1.225-.225a.2.2 0 0 0-.225-.2zm0 .4a.2.2 0 0 1-.175-.2c0-.097.078-.175.175-.175a.2.2 0 0 1 .2.175.225.225 0 0 1-.2.2z'
                                />
                                <path
                                    fill='#494949'
                                    fillRule='nonzero'
                                    d='M73.485 86.279h-.034v.169l.101.135h.034l-.101-.101z'
                                />
                                <circle
                                    cx='74.725'
                                    cy='89.6'
                                    r='1.725'
                                    fill='#FFF'
                                    fillRule='nonzero'
                                />
                                <path
                                    stroke='#9BC2D2'
                                    strokeLinecap='round'
                                    strokeWidth='1.688'
                                    d='M74.725 89.6l.725.75m-.681-1.975V89.6'
                                />
                                <path
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    d='M80.3 85.85l-8.075 12.375V92.15l4.1-6.3zm2.475.825l-10.55 16.15V100l9.25-14.15h.125c.524.007.99.335 1.175.825zm.1 7.175l-9.325 14.275h1.85l7.475-11.45z'
                                    opacity='.4'
                                    style={{ mixBlendMode: 'screen' }}
                                />
                                <path
                                    fill='#044D6B'
                                    fillRule='nonzero'
                                    d='M78.75 86.35h-2.4a.725.725 0 0 1-.725-.725h3.85c0 .4-.325.725-.725.725z'
                                />
                            </g>
                        </g>
                    </g>
                </g>
                <g transform='translate(23 176)'>
                    <rect
                        width='134'
                        height='20'
                        fill='#F6AF7E'
                        rx='10'
                    />
                    <text
                        fill='#FFF'
                        fontFamily='Roboto-Bold, Roboto'
                        fontSize='8'
                        fontWeight='bold'
                        transform='translate(15 5)'
                    >
                        <tspan
                            x='82.449'
                            y='8'
                        >{t('SvgText:AllModules1')}</tspan>
                    </text>
                    <text
                        fill='#FFF'
                        fontFamily='Roboto-Bold, Roboto'
                        fontSize='8'
                        fontWeight='bold'
                        transform='translate(15 5)'
                    >
                        <tspan
                            x='15'
                            y='8'
                        >{t('SvgText:AllModules2')}</tspan>
                    </text>
                    <path d='M87.95 5l-4.501 1.976v3.477c0 1.125.488 2.63 1.904 3.918C87.021 15.891 87.949 16 87.949 16s.927-.109 2.597-1.629c1.415-1.288 1.903-2.793 1.903-3.918V6.976L87.949 5z'/>
                    <use
                        fill='#FFF'
                        fillRule='nonzero'
                        stroke='#FFF'
                        strokeWidth='.5'
                        transform='translate(85 6)'
                        xlinkHref='#prefix__alle_module_ueberblick-s'
                    />
                    <path
                        stroke='#FFF'
                        strokeWidth='.5'
                        d='M77.5 5v10.981'
                    />
                    <g
                        filter='url(#prefix__alle_module_ueberblick-t)'
                        transform='translate(15 5)'
                    >
                        <g
                            fill='#000'
                            fillRule='nonzero'
                        >
                            <path d='M8.53 6H6.741a.47.47 0 1 0 0 .94h.652L5.138 9.198a.47.47 0 1 0 .665.665l2.256-2.256v.652a.47.47 0 1 0 .941 0V6.47A.471.471 0 0 0 8.53 6z'/>
                            <path d='M9.61 2H7.304a.399.399 0 0 0-.39.406v1.99H5a.399.399 0 0 0-.39.406v1.99H2.694a.399.399 0 0 0-.39.406v1.99H.39a.399.399 0 0 0-.39.406c0 .224.175.406.39.406h2.305a.383.383 0 0 0 .277-.119.414.414 0 0 0 .114-.287v-1.99H5a.383.383 0 0 0 .276-.119.414.414 0 0 0 .115-.287v-1.99h1.914a.383.383 0 0 0 .276-.119.414.414 0 0 0 .114-.287v-1.99H9.61a.399.399 0 0 0 .391-.406A.399.399 0 0 0 9.61 2z'/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
  );
}
