import React, { ReactElement, useRef } from 'react';
import {
  Anchor,
  Button,
  Flex,
  Popover,
  Text,
} from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import { Trans, useTranslation } from 'react-i18next';
import { IconBrandGoogle } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react/icons';
import { useDisclosure } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react/hooks';
import useGoogleIdentityServices from 'authentication/hooks/use-google-identity-services';
import { parseCodeLanguageToISO6391 } from 'shared/utilities/languages';

const GoogleSsoButton: React.FC = (): ReactElement => {
  const { t, i18n } = useTranslation('translations');
  const { init: loadGoogleWidget } = useGoogleIdentityServices();
  const [
    isGoogleWidgetLoadStarted,
    { toggle: toggleIsGoogleWidgetLoadStarted },
  ] = useDisclosure(false);
  const [isPrivacyPolicyVisible, { open, close }] = useDisclosure(false);

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const closePrivacyPolicyWithDelay = () => {
    timeoutRef.current = setTimeout(() => {
      close();
    }, 250);
  };

  const openPrivacyPolicy = () => {
    clearTimeout(timeoutRef.current);
    open();
  };

  const loadGoogleWidgetHandler = () => {
    if (!isGoogleWidgetLoadStarted) {
      loadGoogleWidget();
      toggleIsGoogleWidgetLoadStarted();
    }
  };

  return (
    <>
      {isGoogleWidgetLoadStarted ? (
        <Flex justify="center" w="100%" id="g_id_signin">
          <Button size="sm" variant="secondary" loading fullWidth>
            {t('Enable Google Login')}
          </Button>
        </Flex>
      ) : (
        <Flex w="100%">
          <Popover
            middlewares={{
              flip: { fallbackPlacements: ['right', 'bottom'] },
              shift: true,
            }}
            position="right"
            shadow="sm"
            width={305}
            opened={isPrivacyPolicyVisible}
          >
            <Popover.Target>
              <Button
                fullWidth
                size="sm"
                justify="space-between"
                variant="secondary"
                leftSection={<IconBrandGoogle size={14} />}
                rightSection={<span />}
                onMouseLeave={closePrivacyPolicyWithDelay}
                onMouseOver={openPrivacyPolicy}
                onFocus={openPrivacyPolicy}
                onClick={loadGoogleWidgetHandler}
              >
                {t('Enable Google Login')}
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <Text
                size="xs"
                onMouseLeave={closePrivacyPolicyWithDelay}
                onMouseOver={openPrivacyPolicy}
                onFocus={openPrivacyPolicy}
              >
                <Trans
                  i18nKey={'translations:Google privacy note'}
                  components={{
                    1: (
                      <Anchor
                        variant="link"
                        target="_blank"
                        rel="noreferrer"
                        href={`https://policies.google.com/privacy?hl=${parseCodeLanguageToISO6391(
                          i18n?.language ?? 'en'
                        )}`}
                      >
                        Google&apos;s privacy policy
                      </Anchor>
                    ),
                  }}
                >
                  By clicking on the Google SSO login you enable data processing
                  by the provider. For more information about the specific data
                  processing please refer to{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://policies.google.com/privacy?hl=${parseCodeLanguageToISO6391(
                      i18n?.language ?? 'en'
                    )}`}
                  >
                    Google&apos;s privacy policy
                  </a>
                  .
                </Trans>
              </Text>
            </Popover.Dropdown>
          </Popover>
        </Flex>
      )}
    </>
  );
};

export default GoogleSsoButton;
