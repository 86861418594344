
// Packages
import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Utilities
import { store } from 'state/store/store';
import { checkIsIE } from 'shared/helpers';

// Components
import { Shine } from 'shared/svgs';
import CustomButton from 'shared/components/custom-button';
import SvgFromUrlAsBackgroundDiv from 'shared/components/svg-from-url-as-background-div';
import ResponsivePicture from 'shared/components/responsive-picture';
import PlaceholderReplacement from './placeholder-replacement';

import { ProductAnalytics } from 'shared/modules/product-analytics';

// Scss
import './badge-modal.scss';
import { TRACKED_EVENTS } from 'elearning/helpers';
import { QueryContextProvider, SoSafeAxiosProvider } from 'shared/modules/sosafe-connect';

const MySwal = withReactContent(Swal);

/**
 * Renders a Reward-Badge without Particles
 *
 * @category shared
 * @subcategory components
 * @exports BadgeModal
 * @component
 */
export function BadgeModal({ badge, history }) {
  const { t } = useTranslation('translations');

  return (
        <div className='badge-modal d-flex flex-column'>
            <div className='position-relative d-flex'>
                <div className='badgemodal-badge my-4 mx-auto'>
                    <div className={`badgemodal-badge-shine ${badge.received ? '' : 'd-none'} ${checkIsIE() ? 'is-ie' : ''}`}>
                        <SvgFromUrlAsBackgroundDiv
                            url=''
                            fallback={Shine}
                            className='w-100 h-100'
                        />
                    </div>
                    <ResponsivePicture
                        className='badgemodal-badge-image'
                        src={badge.image}
                        alt={t('Badge received image')}
                        fit='contain'
                        grayscale={!badge.received}
                        defaultHeight={240}
                    />
                </div>
            </div>
            <h2 className='mb-2 h1 text-secondary z-index-1'>{badge.name}</h2>
            <span>
                {badge.received ? (
                    <PlaceholderReplacement
                        stringInput={badge.description_won}
                        history={history}
                    />
                ) : (
                    <PlaceholderReplacement
                        stringInput={badge.description}
                        history={history}
                    />
                )}
            </span>
            <CustomButton
                className='my-3'
                label={badge.received ? t('Great, let\'s go!') : t('Close')}
                onClick={() => MySwal.close()}
            />
        </div>
  );
}

export function openBadgeModal(badge, history, i18n, fromView) {
  let arab = '';
  if (i18n && i18n.language === 'ar') {
    arab = 'arab';
  }

  ProductAnalytics.getInstance().trackEvent(TRACKED_EVENTS.BADGE_OPENED, {
    view: fromView,
    badgeTitle: badge.name,
    badgeId: badge.id,
    achieved: !!badge.received,
  });

  MySwal.fire({
    html: (
            <Provider store={store}>
                <QueryContextProvider>
                    <SoSafeAxiosProvider>
                        <BadgeModal
                            history={history}
                            badge={badge}
                        />
                    </SoSafeAxiosProvider>
                </QueryContextProvider>
            </Provider>
    ),
    buttonsStyling: false,
    showConfirmButton: false,
    customClass: {
      popup: 'badge-modal-popup overflow-hidden',
      closeButton: `badge-modal-close ${arab}`,
    },
    showCloseButton: true,
    closeButtonAriaLabel: i18n.t('Close'),
    focusConfirm: false,
    stopKeydownPropagation: false,
  });
}

BadgeModal.propTypes = {
  badge: PropTypes.shape({
    received: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    description_won: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape().isRequired,
};
