
// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// Hooks
import useCertificate from 'shared/hooks/use-certificate';
import usePlatformSettings from 'shared/hooks/use-platform-settings';

// Components
import AchievementCardHeader from 'gamification/components/achievement-card-header';
import Progressbar from 'shared/components/progress-bar';
import AnimatedNumber from 'shared/components/animated-number';
import CustomButton from 'shared/components/custom-button';
import SafeScoreIndicator from 'elearning/components/safe-score-indicator';
import ResponsivePicture from 'shared/components/responsive-picture';

// Utils
import getCertificateModal from 'shared/components/cert-modal';

// Scss
import 'gamification/components/level-card.scss';
import { calculateProgress } from 'shared/components/progress-bar/progressbar.utils';

export default function LevelCard(props) {
  const { t, i18n } = useTranslation('translations');
  const { progress, className } = props;

  const {
    loading: loadingCertificate,
    enabled: certificateEnabled,
    mandatory: mandatoryModuleCount,
  } = useCertificate();

  const { settings: platformSettings } = usePlatformSettings();
  const {
    level: showLevel,
    certificate: showCertificate,
  } = platformSettings || {};

  if (!progress) {
    return (
            <div>
                No Data
            </div>
    );
  }

  const {
    completed,
    total,
    xp: userXp,
    level,
  } = progress;

  const {
    image: levelImage,
    name: levelName,
    xp: minXp,
    xp_max: nextXp,
  } = level || {};

  const { percentageXp, currentLevelXp, maxLevelXp } = calculateProgress({ minXp, maxXp: nextXp, currentXp: userXp });

  const RL = i18n.language === 'ar' ? 'l' : 'r';

  let certificateSection = null;
  if (showCertificate) {
    certificateSection = (
            <CustomButton
                disabled={!certificateEnabled || loadingCertificate}
                label={loadingCertificate ? t('Loading ...') : t('Print certificate')}
                className={`m${RL}-auto py-1 mt-1 ${!certificateEnabled || loadingCertificate ? 'cursor-default' : ''}`}
                onClick={() => getCertificateModal(i18n)}
            />
    );
    if (!certificateEnabled) {
      const certificateTooltip = () => {
        if (mandatoryModuleCount > 0) {
          return {
            description: t('Complete all mandatory modules first!'),
            position: 'bottom',
          };
        }
        return {
          description: t('Complete at least one module!'),
          position: 'bottom',
        };
      };
      certificateSection = (
                <CustomButton
                    disabled={!certificateEnabled || loadingCertificate}
                    label={loadingCertificate ? t('Loading ...') : t('Print certificate')}
                    className={`m${RL}-auto py-1 mt-1 ${!certificateEnabled || loadingCertificate ? 'cursor-default' : ''}`}
                    onClick={() => getCertificateModal()}
                    tooltip={certificateTooltip()}
                />
      );
    }
  }

  return (
        <Card className={`shadow ${className}`}>
            <Card.Body>
                <AchievementCardHeader
                    title={showLevel ? t('Level') : t('Overview of results')}
                    info={t('achievement level tooltip')}
                />
                <div className='d-flex flex-row px-0 px-sm-3'>
                    {showLevel && (
                        <div className='level-shield'>
                            <ResponsivePicture
                                className='h-100 w-100'
                                src={levelImage}
                                alt={t('level badge')}
                                fit='contain'
                                srcset={{
                                  xs: { height: 110 },
                                  md: { height: 130 },
                                }}
                                defaultHeight={130}
                            />
                        </div>
                    )}

                    {!showLevel && (
                        <div className='level-shield flex-shrink-0'>
                            <SafeScoreIndicator
                                percent={total ? Math.ceil(((completed || 0) / total) * 100) : 0}
                            />
                        </div>
                    )}

                    <div className='d-flex flex-column ml-3 ml-sm-4 flex-grow-1 align-items-start w-100'>
                        {showLevel && (
                            <React.Fragment>
                                <h5 className='mb-2 h4'>{levelName}</h5>
                                <Progressbar
                                    progress={percentageXp}
                                    animate
                                    className='w-100 mb-1 delay'
                                    delay={0.25}
                                    maxValue={maxLevelXp}
                                    aria-label={t('You have completed x of y modules.', { x: completed, y: total })}
                                />
                                <span className='mb-1 border-bottom level-border-bottom pb-1 w-100'>
                                    <AnimatedNumber
                                        className=''
                                        start={0}
                                        end={currentLevelXp}
                                        duration={1}
                                        delay={0.25}
                                    />
                                    <span> / {maxLevelXp} {t('Experience points')}</span>
                                </span>
                            </React.Fragment>
                        )}
                        <span className={`w-100 d-none d-sm-block ${showLevel ? 'u-textMedium' : ''} ${!showLevel && showCertificate ? 'border-bottom level-border-bottom pb-2 mb-2' : ''}`}>
                            {t('You have completed x of y modules.', { x: completed, y: total })}
                        </span>
                        {certificateSection}
                    </div>
                </div>
                <span className='w-100 d-block d-sm-none mt-1 u-textMedium'>
                    {t('You have completed x of y modules.', { x: completed, y: total })}
                </span>
            </Card.Body>
        </Card>
  );
}

LevelCard.propTypes = {
  progress: PropTypes.shape({
    completed: PropTypes.number,
    total: PropTypes.number,
    xp: PropTypes.number,
    level: PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.string,
      name: PropTypes.string,
      xp: PropTypes.number,
    }),
  }),
  className: PropTypes.string,
};

LevelCard.defaultProps = {
  progress: null,
  className: '',
};
