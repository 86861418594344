
// Packages
import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Hooks
import getModuleForQuickStartCard from 'elearning/helpers/get/get-module-for-quick-start-card';
import usePlatformSettings from 'shared/hooks/use-platform-settings';

// Utilities
import { dispatchSetPlayerData } from 'elearning/services';
import { useTranslation } from 'react-i18next';
import { getModuleKey } from 'elearning/helpers';
import { useSoSafeAnalytics } from 'shared/hooks/use-sosafe-analytics';
import { ANALYTICS_TYPE } from 'shared/modules/sosafe-connect';
import { UseCertificateModal } from 'flamingo-e-learning-platform/hooks/use-certificate-modal';
import getCertificateModal from './cert-modal';

// Services

const MySwal = withReactContent(Swal);

/**
 * Receives a string and replaces defined placeholders
 *
 * Current options:
 * $RPL LINKTO /elearning/91 link_text$
 * $RPL CERTIFICATE link_text$
 * $RPL QUICKSTART link_text$
 * $RPL MODULELINK 23 link_text$ - this uses the module group id since the id is not the same in all langs
 *
 * @category shared
 * @subcategory components
 * @exports PlaceholderReplacement
 * @component
 */
export default function PlaceholderReplacement(props) {
  const { stringInput, history } = props;
  const { categories } = useSelector((state) => state.modules, shallowEqual);
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const dispatch = useDispatch();
  const { settings } = usePlatformSettings();
  const { step_activation } = settings || {};
  const { i18n } = useTranslation('translations');
  const { sendSoSafeAnalytics } = useSoSafeAnalytics();
  const { showCertificateModal } = UseCertificateModal();

  const stringElements = stringInput.split(/(\$RPL.*?\$)/);
  if (!stringElements || !stringElements.length) return <span />;

  const jsx = (
    <span>
      {stringElements.map((element, idx) => {
        const individualWords = element.split(' ');
        if (!individualWords || !individualWords.length) return <span />;
        if (individualWords[0] !== '$RPL') return <span key={idx.toString()}>{element}</span>;

        switch (individualWords[1]) {
          case 'LINKTO': {
            const parts = element.split(' ');
            const link = parts[2];
            const text = parts.splice(3).join(' ').replace(/\$/g, '');
            return (
              <a
                key={idx.toString()}
                className='cursor-pointer text-secondary'
                onClick={() => {
                  history.push(link);
                  if (MySwal.isVisible()) MySwal.close();
                }}
                role='button'
              >
                {text}
              </a>
            );
          }
          case 'CERTIFICATE': {
            const parts = element.split(' ');
            const text = parts.splice(2).join(' ').replace(/\$/g, '');
            return (
              <a
                key={idx.toString()}
                className='cursor-pointer text-secondary'
                onClick={() => showCertificateModal()}
                role='button'
              >
                {text}
              </a>
            );
          }
          case 'QUICKSTART': {
            const nextModule = getModuleForQuickStartCard(categories, user, step_activation);
            const parts = element.split(' ');
            const text = parts.splice(2).join(' ').replace(/\$/g, '');
            return (
              <a
                key={idx.toString()}
                className='cursor-pointer text-secondary'
                onClick={() => {
                  if (MySwal.isVisible()) MySwal.close();
                  sendSoSafeAnalytics({ type: ANALYTICS_TYPE.QUICKSTART_USED });
                  dispatch(dispatchSetPlayerData({ isContinue: true }));
                  history.push(`elearning/${nextModule.category_key}/${getModuleKey(nextModule)}`);
                }}
                role='button'
              >
                {text}
              </a>
            );
          }
          case 'MODULELINK': {
            const parts = element.split(' ');
            const groupId = parts[2];
            const text = parts.splice(3).join(' ').replace(/\$/g, '');

            let categoryKey = null;
            let moduleKey = null;

            categories.forEach((category) => {
              category.modules.forEach((module) => {
                if (parseInt(module.group, 10) === parseInt(groupId, 10)) {
                  categoryKey = module.category_key;
                  moduleKey = getModuleKey(module);
                }
              });
            });
            if (!categoryKey || !moduleKey) return <span>{text}</span>;
            return (
              <a
                key={idx.toString()}
                className='cursor-pointer text-secondary'
                onClick={() => {
                  if (MySwal.isVisible()) MySwal.close();
                  dispatch(dispatchSetPlayerData({ isContinue: true }));
                  history.push(`elearning/${categoryKey}/${moduleKey}`);
                }}
                role='button'
              >
                {text}
              </a>
            );
          }
          default:
            return null;
        }
      })}
    </span>
  );
  return jsx;
}

PlaceholderReplacement.propTypes = {
  /** tring that contains placeholders */
  stringInput: PropTypes.string.isRequired,
  /** Needed in order to redirect */
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
