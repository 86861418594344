
import { TOURS } from 'shared/utilities/tour-configs';
import { store } from 'state/store/store';

/**
 * Checks if the user has aleady seen the tour with type tourType
 *
 * @param {string} tourType : one of [TOURS.MainpageTour, TOURS.ModulepageTour, TOURS.AchievementTour]
 * @returns {boolean} if the user has seen the tour
 *
 * @category shared
 * @subcategory helpers
 * @exports tourSeen
 */
export default function tourSeen(tourType) {
  const {
    user,
  } = store.getState().auth;

  const { game } = user || {};
  const { welcome } = game || {};

  switch (tourType) {
    case TOURS.MainpageTour:
      return welcome.categories;
    case TOURS.ModulepageTour:
      return welcome.modules;
    case TOURS.AchievementTour:
      return welcome.achievements;
    default:
      return false;
  }
}
