
// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Components
import CustomButton from 'shared/components/custom-button';
import ModuleCard from 'elearning/components/module-card';
import SvgIcon from 'shared/svgs/helper/svg-icon';
import { ChevronUp, ChevronDown } from 'shared/svgs';

// Services
import { dispatchSetPlayerData } from 'elearning/services';
import TourStepWrapper from 'shared/components/onboarding/tour-step-wrapper';
import { TOURS } from 'shared/utilities/tour-configs';
import { checkModuleUnlocked } from 'elearning/helpers/check';
import { MODULE_OPENED_FROM } from 'elearning/helpers';

export default function CollapsibleTopicItem(props) {
  const { t } = useTranslation('translations');
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth?.user, shallowEqual);

  const {
    topic,
    first,
    last,
  } = props;

  const isCollapsible = topic.modules[0].version >= 4;

  const historyState = {
    openedFrom: MODULE_OPENED_FROM.MODULE_CARD,
  };

  const handleButton = (action, moduleKey, categoryKey) => {
    historyState.action = action;
    const isContinue = action === 'CONTINUE';
    dispatch(dispatchSetPlayerData({ isContinue }));
    history.push(`/elearning/${categoryKey}/${moduleKey}`, historyState);
  };

  const name = topic.topic_name || `${t('Completed modules')}:`;

  let totalModules = 0;
  if (topic.modules && topic.modules.length) {
    topic.modules.forEach((module) => {
      if (checkModuleUnlocked(user, module)) {
        totalModules += 1;
      }
    });
  }

  return (
        <div
            className='w-100'
            key={name}
        >
            {isCollapsible && (
                <TourStepWrapper
                    id='module_tour_progress'
                    tour={TOURS.ModulepageTour}
                    disabled={!first}
                    className='d-flex mb-4'
                >
                    <div className='my-auto'>
                        <h4 className='h3 d-inline mb-3'>{name}</h4>
                    </div>
                    <span className='mx-1' />
                    <div className='my-auto'>
                        <span className='d-inline text-primary h3 mb-2'>
                            {`${topic.completed}/${totalModules}`}
                        </span>
                    </div>
                    <CustomButton
                        label={(
                            <span className='ml-2 d-flex'>
                                <SvgIcon
                                    Icon={collapsed ? ChevronDown : ChevronUp}
                                    size='16px'
                                    className='my-auto color-dark'
                                />
                            </span>
                        )}
                        className='ml-auto p-1 mb-0 mt-0'
                        variant='link-dark'
                        onClick={() => setCollapsed(!collapsed)}
                        ariaLabel={t(collapsed ? 'Expand' : 'Collapse')}
                    />
                </TourStepWrapper>
            )}

            <Collapse in={!collapsed}>
                <div className='row'>
                    {topic.modules.map((module, idx) => (
                        <ModuleCard
                            isFirstCard={first && idx === 0}
                            key={module.id}
                            handleButton={(action, moduleKey) => handleButton(action, moduleKey, module.category_key)}
                            module={module}
                        />
                    ))}
                </div>
            </Collapse>
            {!last && (
                <hr className='mt-0 mx-4' />
            )}
        </div>
  );
}

CollapsibleTopicItem.propTypes = {
  topic: PropTypes.shape({
    topic_name: PropTypes.string,
    modules: PropTypes.arrayOf(PropTypes.any),
  }).isRequired,
  last: PropTypes.bool.isRequired,
  showTourOnfirstChild: PropTypes.bool,
};
