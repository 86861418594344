
import { SESSION_STORAGE_KEY_SSO } from 'shared/utilities/constants';

export function getSSORedirect() {
  return sessionStorage.getItem(SESSION_STORAGE_KEY_SSO);
}

export function removeSSORedirect() {
  sessionStorage.removeItem(SESSION_STORAGE_KEY_SSO);
}
