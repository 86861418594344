
// Packages
import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import { checkIsIE } from 'shared/helpers';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import { Shine } from 'shared/svgs';
import SvgFromUrlAsBackgroundDiv from 'shared/components/svg-from-url-as-background-div';
import Particles from 'shared/svgs/particles';
import ResponsivePicture from 'shared/components/responsive-picture';

// Scss
import './reward-shield-image.scss';

/**
 * Renders the Reward-Badge with optional Particles
 *
 * @category shared
 * @subcategory components
 * @exports RewardShieldImage
 * @component
 */
export default function RewardShieldImage(props) {
  const { t } = useTranslation('translations');
  const { image, withShine, isBadge } = props;
  return (
        <React.Fragment>
            <div className='rewardmodal-badge my-4 mx-auto'>
                {(withShine || isBadge) && (
                    <div className={`rewardmodal-badge-shine ${checkIsIE() ? 'is-ie' : ''}`}>
                        <SvgFromUrlAsBackgroundDiv
                            url=''
                            fallback={Shine}
                            className='w-100 h-100'
                        />
                    </div>
                )}
                {isBadge && (
                    <div className='rewardmodal-badge-particles'>
                        <Particles />
                    </div>
                )}
                <ResponsivePicture
                    className='rewardmodal-badge-image'
                    src={image}
                    alt=""
                    fit='contain'
                    defaultHeight={240}
                />
            </div>

        </React.Fragment>
  );
}

RewardShieldImage.propTypes = {
  image: PropTypes.string,
  withShine: PropTypes.bool,
  isBadge: PropTypes.bool,
};

RewardShieldImage.defaultProps = {
  image: null,
  withShine: false,
  isBadge: false,
};
