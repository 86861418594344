import { useCallback, useEffect } from 'react';
import {
  FEATURE_TYPE,
  PARTNER_BLACK_LIST,
  SURVEY_TYPE,
} from 'survey-hub/constants';
import { usePostSurveyAnswers } from 'survey-hub/hooks/use-post-survey-answer';
import useUser from 'shared/hooks/use-user';
import FLAGS from 'flamingo-e-learning-platform/training/components/shared/check-for-flags';
import { useSurveyActive } from 'survey-hub/hooks/use-survey-active';
import { useSurveyResult } from 'survey-hub/hooks/use-survey-result';
import { queryClient, queryKey } from 'shared/modules/sosafe-connect';

interface Props {
  isPersonalizedLearning: boolean;
}

/**
 * This hook checks if the mandant of the user is not on the blacklist PARTNER_BLACK_LIST.
 * This means, the mandant is a Partner! For partners we dont want to show the culture survey.
 * The hook sends the result of the survey as empty object, in order to start the calculation of the learning path.
 * Rest is done within the <CultureSurveyPartner /> component.
 */
export default function useSkipForPartner({ isPersonalizedLearning }: Props): {
  isLoading: boolean;
  skipSurvey: boolean;
} {
  const { user } = useUser({});
  const isPartner = !PARTNER_BLACK_LIST.includes(user?.mandant_id || 1);

  const hideContextSurvey =
    user?.campaign.hideContextSurvey || user?.campaign.hide_context_survey;

  /* Check if user is PL and Survey Hub is enabled */
  const { data: activeSurveyResult, isLoading: isLoadingActiveSurvey } =
    useSurveyActive({
      isSurveyEnabled: isPersonalizedLearning,
      surveyTypeId: SURVEY_TYPE.CULTURE_AND_CONTEXT,
    });

  const surveyId = activeSurveyResult?.data.id;
  const isReadyToSkip = !isLoadingActiveSurvey && !!surveyId;

  const postSurveyResult = usePostSurveyAnswers({
    onSucess: () => {
      queryClient.invalidateQueries([
        queryKey.SURVEY_HUB_RESULT,
        SURVEY_TYPE.CULTURE_AND_CONTEXT,
        FEATURE_TYPE.PERSONALIZED_LEARNING,
      ]);
      queryClient.invalidateQueries([queryKey.ELEARNING]);
    },
    onError: (e) => {
      // if this post fail is either because of bad request a 503 or 500
      // when the error is 500+ the user is automatically redirected to the 503
      // my suggestion is to dispatch a custom sentry error so it's visible
      // quickly to us once something goes wrong (if status code is 500 <)
    },
  });

  /* Check if user has already answered survey and if result is still loading */
  const { answeredSurveys, isFetchingSurveysAnswers } = useSurveyResult({
    userId: user?.id,
    surveyTypeId: SURVEY_TYPE.CULTURE_AND_CONTEXT,
    surveyFeatureId: FEATURE_TYPE.PERSONALIZED_LEARNING,
    enabled: isReadyToSkip,
  });

  const skipSurveyForMsp = useCallback(() => {
    if (!(hideContextSurvey || isPartner)) {
      return;
    }

    if (answeredSurveys?.length > 0) {
      return;
    }

    if (!surveyId) {
      return;
    }

    postSurveyResult.mutate({
      userId: user?.id || 0,
      surveyId,
      answers: {
        // For now we are sending all the answers as true (hardcoded)
        // this would be well addressed if we use survey core to answer the
        // survey in a smarter way
        'isIT_Admin': 'false',
        'isLeadership': 'false',
        'business-trip': 'false',
        'work-from-home': 'false',
        'has-mobile-phone': 'false',
        'isSoftwareDeveloper': 'false',
      },
    });
  }, [hideContextSurvey, isPartner, answeredSurveys, surveyId]);

  useEffect(() => {
    if (FLAGS.isPartnerFeatureActivated || hideContextSurvey) {
      skipSurveyForMsp();
    }
  }, [hideContextSurvey, isPartner, answeredSurveys, surveyId]);

  return {
    isLoading:
      postSurveyResult.isLoading ||
      isFetchingSurveysAnswers ||
      isLoadingActiveSurvey,
    skipSurvey: Boolean(isPartner || hideContextSurvey),
  };
}
