
import getTopics from "./get-topics";
import getAllModulesFromCategories from "./get-all-modules-from-categories";
import getCategoryByCategoryKey from "./get-category-by-category-key";
import getModuleKey from "./get-module-key";
import { checkModuleLocked } from "../check";
import getModuleForQuickStartCard from "./get-module-for-quick-start-card";

/**
 * Returns the next suggested module - different from the Quickstart Card logic in a sense that it will basically just return the
 * next available module within the current category. If the category has been completed already the logic will use the quickstart
 * logic to find the next module and return that.
 *
 * @param {*} categories all available categories
 * @param {*} user user object
 * @param {*} step_activation whether step activation is activated or not
 * @param {*} categoryKey the key of the current category
 * @param {*} moduleKey the id of the current module
 * @returns {*} module object
 *
 * @category elearning
 * @subcategory helpers
 * @exports getModuleForModulePreview
 */

export default function getModuleForModulePreview(
  categories,
  user,
  step_activation,
  categoryKey,
  moduleKey
) {
  let nextModule = null;

  if (!user?.game || !user?.game.progress || user?.game.progress.total === 1) return null;

  const currentCategory = getCategoryByCategoryKey(categories, categoryKey, user.language);
  if (currentCategory) {
    const categorizedModules = getTopics(
      getAllModulesFromCategories([currentCategory]),
      step_activation,
      user
    );

    categorizedModules.forEach((cat) => {
      if (!nextModule) {
        nextModule = cat.modules.find((module) => {
          if (checkModuleLocked(user, module)) return false;
          if (!module.active) return false;
          if (module.statusOfModule.includes("PASSED")) return false;
          if (getModuleKey(module) === moduleKey) return false; // skip if current module

          return true;
        });
      }
    });
  }

  if (!nextModule) {
    nextModule = getModuleForQuickStartCard(categories, user, step_activation, true);
  }

  return nextModule;
}
