// Packages
import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';

// Services
import { closeWelcome } from 'shared/services/onboarding.service';

// Components
import CustomButton from 'shared/components/custom-button';
import SvgIcon from 'shared/svgs/helper/svg-icon';
import { ChevronButtonLeft, ChevronButtonRight, Close } from 'shared/svgs';

// Scss
import './welcome-modal.scss';

export default function WelcomeModal() {
  const dispatch = useDispatch();
  const { welcome } = useSelector((state) => state.welcome, shallowEqual);
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { t, i18n } = useTranslation('translations');

  const { firstname, lastname, customer } = user || {};
  const nameTitle = firstname || lastname ? ` ${firstname || ''} ${lastname || ''}` : null;
  const customerToken = customer ? customer.token : 'undefined';

  const [show, setShow] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);

  const imageUrls = {
    step1: `${window.elearningRuntimeConfig.RESOURCE_URL}resource/${customerToken}/img/assets/welcome.png?height=355`,
    step2: `${window.elearningRuntimeConfig.RESOURCE_URL}resource/${customerToken}/img/assets/certificate.png?height=355`,
  };

  const arab = `${i18n.language === 'ar' ? 'arab' : ''}`;

  useEffect(() => {
    if (welcome) {
      setTimeout(() => {
        setShow(true);
      }, 100);
    } else {
      setShow(false);
    }
  }, [welcome]);

  if (welcome) {
    return (
            <div className='w-100 h-100 position-fixed welcome-overlay'>
                <div
                    className={`card shadow welcome-tooltip d-flex flex-column position-relative ${show ? 'show' : 'hide'} ${arab}`}
                    role='dialog'
                >
                    <div
                        className='welcome-close cursor-pointer'
                        onClick={() => dispatch(closeWelcome())}
                        role='button'
                        label={t('Close')}
                        aria-label={t('Close')}
                    >
                        <SvgIcon
                            Icon={Close}
                            className='mb-1 stroke-icon-gray'
                            size='24px'
                        />
                    </div>
                    <h3 className='mb-2 h2 mx-auto text-center'>{nameTitle ? `${t('Welcome')} ${nameTitle}!` : `${t('Welcome')}!`}</h3>

                    <div className='d-flex flex-row justify-content-between mb-4 mt-4 mt-sm-5 position-relative'>
                        {!!pageNumber && (
                            <div
                                className={`btn-container ${i18n.language === 'ar' ? 'right' : 'left'}`}
                                onClick={() => setPageNumber(pageNumber - 1)}
                                role='button'
                                aria-label={t('Back')}
                            >
                                <SvgIcon
                                    Icon={i18n.language === 'ar' ? ChevronButtonRight : ChevronButtonLeft}
                                    className='cursor-pointer color-secondary'
                                    size='40px'
                                />
                            </div>
                        )}

                        <Carousel
                            className='welcome-carousel'
                            activeIndex={pageNumber}
                            controls={false}
                            indicators={false}
                            touch
                            slide={!i18n.language === 'ar'}
                        >
                            <Carousel.Item>
                                <div
                                    className='background-img-welcome mb-3'
                                    style={{ backgroundImage: `url("${imageUrls.step1}")` }}
                                />
                                <div className='text-min-height'>
                                    <p className='text-center mb-0'>
                                        <Trans i18nKey='WelcomeModal text1'>
                                            On this platform you can learn about all topics of <strong title={t('IT security')} />
                                            in a playful and sustainable way. Accompany Jan and Clara in their everyday work and get fit together in dealing with
                                            <strong title={t('cyber security risks and attacks.')} />
                                        </Trans>
                                    </p>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div
                                    className='background-img-welcome mb-3'
                                    style={{ backgroundImage: `url("${imageUrls.step2}")` }}
                                />
                                <div className='text-min-height'>
                                    <p className='text-center mb-0'>{t('WelcomeModal text2')}</p>
                                </div>
                            </Carousel.Item>
                        </Carousel>

                        {(pageNumber < 1) && (
                            <div
                                className={`btn-container ${i18n.language === 'ar' ? 'left' : 'right'}`}
                                onClick={() => setPageNumber(pageNumber + 1)}
                                role='button'
                                aria-label={t('Next')}
                            >
                                <SvgIcon
                                    Icon={i18n.language === 'ar' ? ChevronButtonLeft : ChevronButtonRight}
                                    className='cursor-pointer color-secondary'
                                    size='40px'
                                />
                            </div>
                        )}
                    </div>

                    <div className='mb-3 d-flex justify-content-center'>
                        <div
                            className={`dot ${pageNumber === 0 ? 'dot-selected' : ''}`}
                            onClick={() => setPageNumber(0)}
                        />
                        <span className='mx-1' />
                        <div
                            className={`dot ${pageNumber === 1 ? 'dot-selected' : ''}`}
                            onClick={() => setPageNumber(1)}
                        />
                    </div>

                    {(pageNumber === 1) && (
                        <CustomButton
                            className={`continue-btn py-1 ${i18n.language === 'ar' ? 'left' : 'right'}`}
                            label={t('Let\'s start!')}
                            onClick={() => dispatch(closeWelcome())}
                        />
                    )}

                </div>
            </div>
    );
  }
  return null;
}
