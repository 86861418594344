
import cloneDeep from 'lodash/cloneDeep';
import { store } from 'state/store/store';
import {
  TOURS,
  MainpageTour,
  ModulepageTour,
  AchievementTour,
  MainpageTourMobile,
  ModulepageTourMobile,
  AchievementTourMobile,
  STEP_TYPES,
} from 'shared/utilities/tour-configs';

export default function tourGetConfig(id) {
  const {
    user,
  } = store.getState().auth;

  let config = null;
  switch (id) {
    case TOURS.MainpageTour:
      config = cloneDeep(MainpageTour);
      break;
    case TOURS.AchievementTour:
      config = cloneDeep(AchievementTour);
      break;
    case TOURS.ModulepageTour:
      config = cloneDeep(ModulepageTour);
      break;
    case TOURS.MainpageTourMobile:
      config = cloneDeep(MainpageTourMobile);
      break;
    case TOURS.AchievementTourMobile:
      config = cloneDeep(AchievementTourMobile);
      break;
    case TOURS.ModulepageTourMobile:
      config = cloneDeep(ModulepageTourMobile);
      break;
    default:
      return {};
  }

  const { steps } = config;
  const filteredSteps = steps
    .filter((step) => {
      const { condition } = step;
      if (!condition) return true;
      const { every } = condition;

      return every.every((key) => {
        if (key === 'info_widgets') {
          return !!user?.customer?.info_widgets?.length;
        }
        return !!user?.campaign?.[key];
      });
    })
    .filter((step) => {
      const { id: stepId, type } = step;
      if (!stepId) return true; // mobile
      if (STEP_TYPES.STEP === type) {
        const elem = document.getElementById(stepId);
        return !!elem;
      }
      return true;
    })
    .map((step) => {
      const { title, description, image } = step;

      let tempImage = null;
      if (config.id === TOURS.MainpageTourMobile || config.id === TOURS.AchievementTourMobile) {
        if (typeof image !== 'function') {
          Object.keys(image).forEach((key) => {
            if (user?.campaign && user?.campaign[key]) {
              tempImage = image[key];
            }
          });
          if (!tempImage) {
            tempImage = image.default;
          }
        }
      }
      let tempTitle = null;
      if (typeof title !== 'string') {
        Object.keys(title).forEach((key) => {
          if (user?.campaign && user?.campaign[key]) {
            tempTitle = title[key];
          }
        });
        if (!tempTitle) {
          tempTitle = title.default;
        }
      }
      let tempDescription = null;
      if (typeof description !== 'string') {
        Object.keys(description).forEach((key) => {
          if (user?.campaign && user?.campaign[key]) {
            tempDescription = description[key];
          }
        });
        if (!tempDescription) {
          tempDescription = description.default;
        }
      }
      return {
        ...step,
        image: tempImage || image,
        title: tempTitle || title,
        description: tempDescription || description,
      };
    });

  config.steps = filteredSteps;
  return config;
}
