
// Packages
import clsx from "clsx";
import { Tooltip } from "@sosafe-platform-engineering/fe-lib-ui-react";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useState } from "react";
import { Collapse } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import PulseLoader from "react-spinners/PulseLoader";

// Hooks
import { useFeedback } from "shared/hooks/use-feedback";
import useGetComponent from "shared/hooks/use-get-component";
import usePlatformSettings from "shared/hooks/use-platform-settings";
import { useTourWithAnalytics } from "shared/hooks/use-tour/use-tour-with-analytics";
import useTourHint from "shared/hooks/use-tour-hint";

// Utilities
import { ACHIEVEMENT_PAGE_OPENED_FROM } from "elearning/helpers";
import { getComponentByUrl, getDisplayName, tourGetTourTypeByComponent } from "shared/helpers";
import useMetaLinks from "shared/hooks/use-meta-links";
import { TOURS } from "shared/utilities/tour-configs";

// Components
import HelpButtonMenu from "shared/components/header/help-button-menu";
import Logo from "shared/components/header/logo-header";
import TourStepWrapper from "shared/components/onboarding/tour-step-wrapper";
import OrientationChange from "shared/components/orientation-change";
import ShakeContainer from "shared/components/shake-container";
import GlobalSettingsContext from "shared/modules/global-settings/state/global-settings-context.provider";
import {
  ChevronDown,
  ChevronUp,
  Close,
  FAQ,
  Feedback,
  Hamburger,
  Learning,
  Logout,
  Profile,
  Support,
  Tour,
  Training,
} from "shared/svgs";
import SvgIcon from "shared/svgs/helper/svg-icon";
import Notification from "./notification";

// Scss
import "./header.scss";

export default function Header(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const component = useGetComponent();
  const { tourStart } = useTourWithAnalytics();
  const { tourHint, isTourHintHidden } = useTourHint(true);
  const componentsWithTour = ["achievements", "modules", "categories"];

  const { t, i18n } = useTranslation("translations");
  const arab = i18n.language === "ar" ? "arab" : "";

  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { categories, loading: loadingCategories } = useSelector(
    (state) => state.modules,
    shallowEqual
  );
  const { globalSettings } = useContext(GlobalSettingsContext);

  const { support } = useMetaLinks();

  const location = useLocation();
  const path = location.pathname;
  const pathArr = path.split("/");
  const isScorm = pathArr.length >= 4 && pathArr.includes("elearning");

  const [isLandscape, setIsLandscape] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isTrainingOpen, setIsTrainingOpen] = useState(false);

  const showHeader = !isScorm || !isMobile || !isLandscape;

  const { settings: platformSettings } = usePlatformSettings();

  const displayName = getDisplayName(user);

  const icon = (i) => (
    <SvgIcon Icon={i} size="24px" className="mx-2 my-auto color-icon-gray stroke-icon-gray" />
  );

  const chevron = (state) => {
    const chev = state ? ChevronUp : ChevronDown;
    return (
      <SvgIcon Icon={chev} size="16px" className="mx-3 my-auto color-icon-gray stroke-icon-gray" />
    );
  };

  const { showFeedback } = useFeedback();

  const onClick = (type, additionalInfo) => {
    if (type === "training-submenu") {
      setIsTrainingOpen(!isTrainingOpen);
      return;
    }

    setIsTrainingOpen(false);
    setIsMenuOpen(false);
    switch (type) {
      case "training":
        history.push("/");
        break;
      case "achievements":
        history.push({
          pathname: "/achievements",
          state: {
            openedFrom: ACHIEVEMENT_PAGE_OPENED_FROM.START_PAGE.HEADER_MENU,
          },
        });
        break;
      case "downloads":
        history.push("/downloads");
        break;
      case "account":
        history.push("/account");
        break;
      case "feedback":
        // setFeedbackSeen(Moment().format('MM.DD.YYYY'));
        showFeedback(user.apikey);
        break;
      case "faq":
        history.push("/faq");
        break;
      case "support":
        window.open(support, "_blank");
        break;
      case "logout":
        props.handleSignOutClick();
        break;
      case "category":
        history.push(`/elearning/${additionalInfo}`);
        break;
      default:
        break;
    }
  };

  let mobileSubmenu = null;
  if (loadingCategories) {
    mobileSubmenu = (
      <div className="my-auto py-3">
        <PulseLoader sizeUnit="rem" size={4} loading className="mb-1" />
      </div>
    );
  } else if (categories && categories.length) {
    mobileSubmenu = (
      <React.Fragment>
        {categories.map(({ name, category_name: categoryKey, id }) => {
          const active = path.includes(`elearning/${categoryKey}`);
          return (
            <div
              role="button"
              key={id}
              className="header-submenu-item py-3"
              onClick={() => onClick("category", categoryKey)}
            >
              <span className="my-auto">{active ? <strong>{name}</strong> : name}</span>
            </div>
          );
        })}
      </React.Fragment>
    );
  } else {
    mobileSubmenu = (
      <div className="my-auto py-3">
        <span>{t("No modules available")}</span>
      </div>
    );
  }

  const headerMenuItemClassName = (key) =>
    `d-flex flex-row py-3 px-2 header-menu-item ${path.includes(key) ? "active" : ""}`;

  const HeaderButton = useCallback(
    ({ type, exactMath }) => {
      const capitalizeType = type.charAt(0).toUpperCase() + type.slice(1);
      return (
        <span
          className="h3 header-link mx-2"
          onClick={() => onClick(type)}
          role="button"
          aria-label={t(capitalizeType)}
        >
          {path.includes(type) || path === exactMath ? (
            <strong>{t(capitalizeType)}</strong>
          ) : (
            t(capitalizeType)
          )}
        </span>
      );
    },
    [path]
  );

  return (
    <TourStepWrapper id="main_tour_navigation" tour={TOURS.MainpageTour} margin={0}>
      <header className={`App-header shadow ${showHeader ? "" : "d-none"}`}>
        <nav className="header-inner d-flex flex-row align-items-center">
          <OrientationChange updateParent={(ori) => setIsLandscape(ori.isLandscape)} />
          {user && showHeader && (
            <React.Fragment>
              <Logo
                theme={globalSettings}
                className={clsx(i18n.language === "ar" ? "mr-md-2 ml-md-4" : "ml-md-2 mr-md-4")}
              />

              {/* Mobile */}
              <div className="d-flex flex-row my-auto ml-auto d-md-none">
                {platformSettings?.notifications && isMobile && !props.minimizedView && (
                  <Notification />
                )}
                <div
                  className={`${isTourHintHidden ? "" : "pulse"} ml-4`}
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  role="button"
                >
                  <SvgIcon
                    Icon={isMenuOpen ? Close : Hamburger}
                    size="30"
                    className="my-auto cursor-pointer stroke-icon-gray"
                  />
                </div>
                {tourHint}
              </div>

              {/* Desktop */}
              <React.Fragment>
                <div className="d-none flex-row d-md-flex px-2">
                  {(platformSettings?.achievements || platformSettings?.material_area) &&
                    !props.minimizedView && (
                      <TourStepWrapper id="main_tour_training" tour={TOURS.MainpageTour} margin={0}>
                        <HeaderButton type="training" exactMath="/" />
                      </TourStepWrapper>
                    )}
                  {(platformSettings?.achievements || platformSettings?.material_area) &&
                    !props.minimizedView && (
                      <TourStepWrapper
                        id="main_tour_achievements"
                        tour={TOURS.MainpageTour}
                        margin={0}
                      >
                        <HeaderButton type="achievements" />
                      </TourStepWrapper>
                    )}
                  {platformSettings?.material_area && !props.minimizedView && (
                    <TourStepWrapper id="main_tour_downloads" tour={TOURS.MainpageTour} margin={0}>
                      <HeaderButton type="downloads" />
                    </TourStepWrapper>
                  )}
                </div>

                <div className="d-none flex-row my-auto ml-auto d-md-flex align-items-center">
                  {platformSettings?.notifications && !isMobile && !props.minimizedView && (
                    <React.Fragment>
                      <Notification />
                      <div className="header-divider border-right" />
                    </React.Fragment>
                  )}
                  <Tooltip content={t("Account")}>
                    <div
                      className="mx-4 my-auto d-flex flex-row cursor-pointer"
                      onClick={() => onClick("account")}
                      role="button"
                      aria-label={displayName || t("Account")}
                      data-testid="btn-account"
                    >
                      <SvgIcon
                        Icon={Profile}
                        size="25"
                        className="my-auto cursor-pointer stroke-icon-gray"
                      />
                      {!!displayName && (
                        <span className="ml-2 h3 d-none d-lg-block header-link active">
                          {displayName}
                        </span>
                      )}
                    </div>
                  </Tooltip>

                  <div className="header-divider border-right" />

                  {platformSettings?.feedback && !props.minimizedView && (
                    <TourStepWrapper id="main_tour_feedback" tour={TOURS.MainpageTour} margin={0}>
                      <div className="d-flex align-items-center">
                        <Tooltip content={t("Feedback")}>
                          <div
                            className="my-auto mx-4"
                            onClick={() => onClick("feedback")}
                            role="button"
                            aria-label={t("Feedback")}
                          >
                            <ShakeContainer className="cursor-pointer" disabled>
                              <SvgIcon Icon={Feedback} className="stroke-icon-gray" size="25" />
                            </ShakeContainer>
                          </div>
                        </Tooltip>
                        <div className="header-divider border-right" />
                      </div>
                    </TourStepWrapper>
                  )}

                  <HelpButtonMenu history={history} minimizedView={props.minimizedView} />

                  <div className="header-divider border-right" />
                  <div className="d-flex align-items-center">
                    <Tooltip content={t("Logout")}>
                      <div
                        data-testid="btn-logout"
                        className="mx-4 my-auto d-flex flex-row cursor-pointer"
                        onClick={() => onClick("logout")}
                        role="button"
                        aria-label={t("Logout")}
                      >
                        <SvgIcon Icon={Logout} size="25" className="stroke-icon-gray" />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </React.Fragment>
            </React.Fragment>
          )}
        </nav>

        {/* Mobile Menu */}
        <nav
          className={`header-menu d-md-none cursor-pointer shadow ${arab} ${
            isMenuOpen ? "menu-open" : "menu-closed"
          }`}
        >
          <div className="d-flex flex-column header-menu-item py-0">
            <div
              className={`${headerMenuItemClassName("elearning")} ${
                path === "/" ? "active" : ""
              } justify-content-between`}
              onClick={() => onClick("training-submenu")}
              role="button"
            >
              <span onClick={() => onClick("training")} role="button" aria-label={t("Training")}>
                {icon(Training)}
                <span className="my-auto">{t("Training")}</span>
              </span>
              <span>{chevron(isTrainingOpen)}</span>
            </div>
            <Collapse className="header-submenu" in={isTrainingOpen}>
              <div className="header-submenu-inner">{mobileSubmenu}</div>
            </Collapse>
          </div>

          {platformSettings?.achievements && !props.minimizedView && (
            <div
              className={headerMenuItemClassName("achievements")}
              onClick={() => onClick("achievements")}
              role="button"
              aria-label={t("Achievements")}
            >
              {icon(Learning)}
              <span className="my-auto">{t("Achievements")}</span>
            </div>
          )}

          {(true || platformSettings?.material_area) && !props.minimizedView && (
            <div
              className={headerMenuItemClassName("downloads")}
              onClick={() => onClick("downloads")}
              role="button"
              aria-label={t("downloads")}
            >
              {icon(Learning)}
              <span className="my-auto">{t("downloads")}</span>
            </div>
          )}

          <div
            className={headerMenuItemClassName("account")}
            onClick={() => onClick("account")}
            role="button"
            aria-label={t("Account")}
          >
            {icon(Profile)}
            <span className="my-auto">{displayName || t("Account")}</span>
          </div>

          {platformSettings?.feedback && !props.minimizedView && (
            <div
              className={headerMenuItemClassName("feedback")}
              onClick={() => onClick("feedback")}
              role="button"
              aria-label={t("Feedback")}
            >
              {icon(Feedback)}
              <span className="my-auto">{t("Feedback")}</span>
            </div>
          )}

          <div
            className={headerMenuItemClassName("faq")}
            onClick={() => onClick("faq")}
            role="button"
            aria-label={t("FAQ")}
          >
            {icon(FAQ)}
            <span className="my-auto">{t("FAQ")}</span>
          </div>
          {support && (
            <div
              className={headerMenuItemClassName("support")}
              onClick={() => onClick("support")}
              role="button"
              aria-label={t("Support")}
            >
              {icon(Support)}
              <span className="my-auto">{t("Support")}</span>
            </div>
          )}

          {componentsWithTour.includes(component) && !props.minimizedView && (
            <div
              className={`${headerMenuItemClassName("tour")}`}
              onClick={() => {
                setIsMenuOpen(false);
                dispatch(tourStart({ id: tourGetTourTypeByComponent(getComponentByUrl()) }));
              }}
              role="button"
              aria-label={t("See tour")}
            >
              {icon(Tour)}
              <span className="my-auto">{t("See tour")}</span>
            </div>
          )}

          <div
            className={`${headerMenuItemClassName("logout")}`}
            onClick={() => onClick("logout")}
            role="button"
            aria-label={t("Logout")}
          >
            {icon(Logout)}
            <span className="my-auto">{t("Logout")}</span>
          </div>
        </nav>
      </header>
    </TourStepWrapper>
  );
}

Header.propTypes = {
  handleSignOutClick: PropTypes.func.isRequired,
  minimizedView: PropTypes.bool,
};

Header.defaultProps = {
  minimizedView: false,
};
