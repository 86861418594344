
/**
 * escapes regular expressions
 *
 * @param {string} str input string
 * @returns escaped input string
 *
 * @category shared
 * @subcategory helpers
 * @exports escapeRegExp
 */
export default function escapeRegExp(str) {
  return str.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
}
