
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { checkIsIE } from 'shared/helpers';

// Keep ref and val outside of component to prevent component to rerender on number change
const ref = React.createRef();
let val = 0;

/**
 * Animated number which counts from given start to given end. Default start is 0.
 *
 * @category shared
 * @subcategory components
 * @exports AnimatedNumber
 * @component
 */
export default function AnimatedNumber(props) {
  const {
    className,
    start,
    end,
    duration, // seconds
    delay,
  } = props;

  useEffect(() => {
    // Dont animate on IE
    if (checkIsIE()) {
      return () => { };
    }
    if (start >= end) {
      return () => { };
    }

    val = start;

    const ms = duration * 1000;
    const stepLength = 20;
    const stepsAmount = ms / stepLength;

    const diff = end - start;
    const incPerStep = diff / stepsAmount;

    let intervall = null;
    const delayTimer = setTimeout(() => {
      intervall = setInterval(() => {
        val += incPerStep;
        if (ref && ref.current) {
          if (val >= end) {
            val = end;
            ref.current.textContent = Math.floor(val);
            clearInterval(intervall);
          } else {
            ref.current.textContent = Math.floor(val);
          }
        }
        if (val >= end && intervall) {
          clearInterval(intervall);
        }
      }, stepLength);
    }, delay * 1000);

    return () => {
      if (intervall) {
        clearInterval(intervall);
      }
      if (delayTimer) {
        clearTimeout(delayTimer);
      }
    };
  }, [end]);
  return (
        <span
            ref={ref}
            className={className}
            data-testid='animatedNumber'
        >
            { start }
        </span>
  );
}

AnimatedNumber.propTypes = {
  className: PropTypes.string,
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  delay: PropTypes.number,
};

AnimatedNumber.defaultProps = {
  className: '',
  delay: 0,
};
