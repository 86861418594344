import ISO6391 from 'iso-639-1';

type LanguageProps = { key: string; value: string };

export const langNamesFromCode: Record<string, string> = {
  ar: 'Arab',
  bg: 'Bulgarian',
  cs: 'Czech',
  da: 'Danish',
  de: 'German',
  ch: 'German (CH)',
  el: 'Greek',
  en: 'English',
  es: 'Spanish',
  et: 'Estonian',
  fi: 'Finnish',
  fr: 'French',
  hu: 'Hungarian',
  hr: 'Croatian',
  it: 'Italian',
  jp: 'Japanese',
  ja: 'Japanese',
  nl: 'Dutch',
  no: 'Norwegian',
  pl: 'Polish',
  pt: 'Portuguese',
  ro: 'Romanian',
  ru: 'Russian',
  se: 'Swedish',
  sv: 'Swedish',
  sk: 'Slovak',
  sl: 'Slovene',
  sr: 'Serbian',
  tr: 'Turkish',
  vi: 'Vietnamese',
  zh: 'Chinese',
  uk: 'Ukrainian',
  hi: 'Hindi',
  th: 'Thai',
  ko: 'Korean',
};

export const nonLatinLanguages: Set<string> = new Set([
  'ar', // Arabic
  'zh', // Chinese
  'ja', // Japanese
  'ru', // Russian
  'hi', // Hindi
  'ko', // Korean
  'th', // Thai
]);

export const LANGUAGE_MANAGER: Array<LanguageProps> = [
  { key: 'de', value: 'Deutsch' },
  { key: 'en', value: 'English' },
  { key: 'ar', value: 'عربي' },
  { key: 'bg', value: 'български' },
  { key: 'ch', value: 'Deutsch (CH)' },
  { key: 'cs', value: 'čeština' },
  { key: 'da', value: 'Dansk' },
  { key: 'el', value: 'Ελληνικά' },
  { key: 'es', value: 'Español' },
  { key: 'et', value: 'eesti keel' },
  { key: 'fi', value: 'Suomi' },
  { key: 'fr', value: 'Français' },
  { key: 'hu', value: 'Magyar' },
  { key: 'hr', value: 'Hrvatski' },
  { key: 'it', value: 'Italiano' },
  // { key: 'ja', value: '日本語' },
  { key: 'jp', value: '日本語' }, // ToDo: remove, once backend uses the proper ISO-639-1 code ("JA")
  { key: 'nl', value: 'Nederlands' },
  { key: 'no', value: 'Norsk' },
  { key: 'pl', value: 'Polski' },
  { key: 'pt', value: 'Português' },
  { key: 'ro', value: 'Română' },
  { key: 'ru', value: 'Pусский' },
  { key: 'se', value: 'Svenska' }, // ToDo: remove, once backend uses the proper ISO-639-1 code ("SV")
  { key: 'sk', value: 'Slovenský' },
  { key: 'sl', value: 'Slovenščina' },
  { key: 'sr', value: 'Srpski' },
  // { key: 'sv', value: 'Svenska' },
  { key: 'tr', value: 'Türkçe' },
  { key: 'vi', value: 'tiếng Việt' },
  { key: 'zh', value: '中文' },
  { key: 'ko', value: '한국어' },
  { key: 'th', value: 'ไทย' },
  { key: 'hi', value: 'हिन्दी' },
  { key: 'uk', value: 'український' },
];

/**
 * sosafe has not implemented code language following ISO6392 and therefore,
 * we are correcting few code languages here, until we have fixed in the database and backend.
 * (until there we use this parser)
 * */
const SOSAFE_LANG_CODE_TO_ISO6392: Record<string, string> = {
  jp: 'ja',
  ch: 'de-ch',
  se: 'sv',
  nb: 'no',
};

// Sosafe does NOT use the ISO6392 this is a map to be used from iso6392 to sosafe language code
export const SOSAFE_LANGUAGE_CODE_PARSER: Map<string, string> = new Map();
SOSAFE_LANGUAGE_CODE_PARSER.set('ja', 'jp');
SOSAFE_LANGUAGE_CODE_PARSER.set('sv', 'se');
SOSAFE_LANGUAGE_CODE_PARSER.set('nb', 'no');
SOSAFE_LANGUAGE_CODE_PARSER.set('de-ch', 'ch');

export const parseCodeLanguageToISO6391 = (code: string): string => {
  const fixedCode = SOSAFE_LANG_CODE_TO_ISO6392[code];
  return fixedCode ?? code;
};

export const LANGUAGE_MANAGER_ISO: Array<LanguageProps> = LANGUAGE_MANAGER.map(
  ({ key, value }) => ({
    key: parseCodeLanguageToISO6391(key),
    value,
  })
);

type LanguageCodeMapping = {
  sosafeCodeLanguage: string;
  ISO6391: string;
};

export const parseCodeLanguageArrayToISO6391 = (
  codeArray: string[]
): Array<LanguageCodeMapping> =>
  codeArray.map((code) => ({
    sosafeCodeLanguage: code,
    ISO6391: parseCodeLanguageToISO6391(code),
  }));

type LanguageNameParts = {
  languageName: string;
  derivativeLanguage?: string;
};

export const getNameAndDerivativeNameFromCode = (
  code: string
): LanguageNameParts => {
  const parts = code.split('-');
  return {
    languageName: parts?.[0] ?? code,
    derivativeLanguage: parts?.[1],
  };
};

export const getCodeByLanguageName = (
  languageName: string
): string | undefined => {
  const code = ISO6391.getCode(languageName);
  return code || undefined;
};

export const getLanguageISO6391Name = (code: string): string => {
  const isDerivativeLanguage = code.includes('-');

  if (isDerivativeLanguage) {
    const { languageName, derivativeLanguage } =
      getNameAndDerivativeNameFromCode(code);
    return `${ISO6391.getNativeName(languageName)} (${derivativeLanguage?.toUpperCase() ?? ''})`;
  }

  // Override Serbian language name to use Latin script
  if (code === 'sr') {
    return LANGUAGE_MANAGER.find((lang) => lang.key === 'sr')?.value ?? '';
  }

  return ISO6391.getNativeName(code);
};

export const parseCodeLanguageToLanguageType = (
  codeArray: string[]
): Array<LanguageProps> =>
  codeArray.map((code) => {
    const parsedCodeLanguage = parseCodeLanguageToISO6391(code);
    return {
      key: parsedCodeLanguage,
      value: getLanguageISO6391Name(parsedCodeLanguage),
    };
  });

// Languages with high priority
const SOSAFE_PRIO_LANGUAGES: string[] = ['en', 'de', 'fr'];

type SeparateByPrioProps = {
  prioLanguage: Array<LanguageProps>;
  restLanguage: Array<LanguageProps>;
};

const separateLanguageByPriority = (
  codeArray: Array<LanguageProps>
): SeparateByPrioProps =>
  codeArray.reduce<SeparateByPrioProps>(
    (acc, curLanguage) => {
      const isPrio = SOSAFE_PRIO_LANGUAGES.includes(curLanguage.key);
      if (isPrio) {
        return { ...acc, prioLanguage: [...acc.prioLanguage, curLanguage] };
      }
      return { ...acc, restLanguage: [...acc.restLanguage, curLanguage] };
    },
    { prioLanguage: [], restLanguage: [] }
  );

const sortLanguagesAlphabetically = (
  languages: Array<LanguageProps>
): Array<LanguageProps> =>
  languages.sort((a, b) => {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  });

const sortPrioLanguages = (
  languages: Array<LanguageProps>
): Array<LanguageProps> =>
  languages.sort((a) => {
    if (a.key === 'en') {
      return -1;
    }
    if (a.key === 'de') {
      return 0;
    }
    return 1;
  });

/*
  Sort language code first by language prio and then alphabetically
  eg: Prio languages ['en', 'de', 'fr']
*/
export const sortLanguages = (
  languages: Array<LanguageProps>
): Array<LanguageProps> => {
  const { prioLanguage, restLanguage } = separateLanguageByPriority(languages);
  const sortedPrioLanguages = sortPrioLanguages(prioLanguage);
  const sortedRestLanguages = sortLanguagesAlphabetically(restLanguage);
  return [...sortedPrioLanguages, ...sortedRestLanguages];
};

export const isLangSupported = (lang: string): LanguageProps | undefined =>
  LANGUAGE_MANAGER.find((langData) => langData.key === lang);

export const DEFAULT_LANGUAGE = 'en';

export function getLanguageName(code: string): string | undefined {
  return langNamesFromCode[code];
}
