import React, { useEffect } from 'react';
import DownloadsPage from 'downloads/downloads.page';
import { PlayerPage } from 'elearning/pages/player-page';
import AchievementsPage from 'flamingo-e-learning-platform/achievements/achievements.page';
import FlamingoTrainingPage from 'flamingo-e-learning-platform/training/training.page';
import FaqPage from 'support/pages/faq-page';
import ContentPage from 'flamingo-e-learning-platform/content/content.page';
import { useSoSafeConnect } from 'shared/modules/sosafe-connect';
import useUser from 'shared/hooks/use-user';
import AccountPage from 'flamingo-e-learning-platform/account/account';
import { AwarenesssAssessmentPage } from 'flamingo-e-learning-platform/awareness-assessment/awareness-assessment.page';
import ErrorPage from 'shared/pages/error/error.page';
import { useLocation } from 'react-router-dom';
import showHeader from 'shared/helpers/personalizedLearning/show-header';
import { ModalAlertPage } from 'flamingo-e-learning-platform/utilities/modal-alert/modal-alert.page';
import { isUserAllowedOnPL } from 'shared/hooks/use-is-user-allowed-pl';
import useAuthentication from 'authentication/hooks/use-authentication';
import { useCategoriesRequest } from 'elearning/hooks/use-categories-request';
import LessonPlayerPage from 'flamingo-e-learning-platform/pages/lesson-player-page';
import FlamingoHeader from '../../../flamingo-e-learning-platform/header/header';

const isNewIframeEnabledV7 =
  window.elearningRuntimeConfig.ENABLE_NEW_IFRAME_FOR_V7 === 'true';
const isNewIframeEnabledV6 =
  window.elearningRuntimeConfig.ENABLE_NEW_IFRAME_FOR_V6 === 'true';

export const name = 'PersonalizedLearningRouteController';

export const routes = [
  {
    path: '/',
    render: () => <FlamingoTrainingPage />,
  },
  {
    path: '/achievements',
    render: () => <AchievementsPage />,
  },
  {
    path: '/faq',
    render: () => <FaqPage />,
  },
  {
    path: '/elearning/:categoryKey/:moduleKey',
    render: () => <PlayerPage />,
  },
  {
    path: '/account',
    render: () => <AccountPage />,
  },
  {
    path: '/awareness-assessment',
    render: () => <AwarenesssAssessmentPage />,
  },
  {
    path: '/downloads',
    render: () => <DownloadsPage />,
  },
  {
    path: '/503',
    render: () => <ErrorPage errorCode={503} />,
  },
  ...(isNewIframeEnabledV7 || isNewIframeEnabledV6
    ? [
        {
          path: '/elearning/:categoryKey/:moduleKey/:v',
          render: () => <LessonPlayerPage />,
        },
      ]
    : []),
];

if (window.elearningRuntimeConfig.DEPLOY_ENVIRONMENT !== 'production') {
  routes.push({
    path: '/playground/modal-alert',
    render: () => <ModalAlertPage />,
  });
}

export const defaultRoute = '/';

export const Wrapper = ({ children }: { children: JSX.Element }) => {
  const { signOut } = useAuthentication();
  const { pathname } = useLocation();
  const enableHeader = showHeader(pathname);

  useEffect(() => {
    import('../../../theme/flamingo');
  }, []);

  if (
    (pathname.includes('/v7') && isNewIframeEnabledV7) ||
    (pathname.includes('/v6') && isNewIframeEnabledV6)
  ) {
    return (
      <React.Fragment>
        <>{children}</>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {enableHeader && (
        <FlamingoHeader handleSignOutClick={signOut} minimizedView={false} />
      )}
      <ContentPage>{children}</ContentPage>
    </React.Fragment>
  );
};

export const UseIsPathAllowed = () => {
  const { apiKey } = useSoSafeConnect();
  const { user, loading } = useUser({});
  const { isAllowed, isLoading: isLoadingUserAllowed } = isUserAllowedOnPL();
  const isPersonalizedLearning = user?.campaign.isPersonalizedLearning ?? false;
  const loadingAuthorization = apiKey ? loading : false;
  const { data: result } = useCategoriesRequest();
  const hasModules = result?.data ? result.data.result?.length > 0 : false;

  return {
    allowed: !!apiKey && isPersonalizedLearning && isAllowed && hasModules,
    loading: loadingAuthorization && isLoadingUserAllowed,
  };
};
