
import React, { useContext } from 'react';
import useGlobalSettings, { defaultGlobalSettings } from '../hooks/use-global-settings.hooks';

const GlobalSettingsContext = React.createContext({
  globalSettings: defaultGlobalSettings,
  setLocalToken: (token: string) => {
    console.warn('Global settings context not initialized');
  },
  removeLocalToken: () => {
    console.warn('Global settings context not initialized');
  },
  loading: false,
});

GlobalSettingsContext.displayName = 'global-settings-context';
export default GlobalSettingsContext;

export function GlobalSettingsContextProvider(props: { children: React.ReactNode }) {
  const globalSettingsContextValue = useGlobalSettings();

  return (
        <GlobalSettingsContext.Provider value={globalSettingsContextValue}>
            <div className='h-100 w-100'>
                {props.children}
            </div>
        </GlobalSettingsContext.Provider>
  );
}

export const UseGlobalSettingsContext = () => {
  const context = useContext(GlobalSettingsContext);
  if (!context) throw 'UseGlobalSettingsContext need to be wrapped into <GlobalSettingsContextProvider />';
  return context;
};
