
// Packages
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Star from './star';

/**
 * enter component description here - props and return values are NOT documented here
 *
 * @category shared
 * @subcategory components
 * @exports Rating
 * @component
 */
export default function Rating(props) {
  const { t } = useTranslation('translations');
  const { className, rate, setRate } = props;

  const calcStarsArray = (amount) => {
    const active = [];
    for (let i = 0; i <= amount; i++) {
      active.push(i);
    }
    return active;
  };

  const [starsActive, setStarsActive] = useState(calcStarsArray(rate - 1));

  const onHoverStar = (id) => {
    setStarsActive(calcStarsArray(id));
  };

  const onLeave = () => {
    if (rate) {
      setStarsActive(calcStarsArray(rate - 1));
    } else {
      setStarsActive([]);
    }
  };

  const onClick = (id) => {
    setRate(id + 1);
    setStarsActive(calcStarsArray(id));
  };

  return (
        <div
            className={`d-flex flex-row px-2 py-2 ${className}`}
            onMouseLeave={() => onLeave(false)}
            role='listbox'
        >
            <div className='align-self-center'>
                <small>
                    {t('Bad')}
                </small>
            </div>
            <span className='mx-1' />
            <Star
                key='star_0'
                onHover={() => onHoverStar(0)}
                active={starsActive.includes(0)}
                onClick={() => onClick(0)}
                selected={rate === 0}
            />
            <Star
                key='star_1'
                onHover={() => onHoverStar(1)}
                active={starsActive.includes(1)}
                onClick={() => onClick(1)}
                selected={rate === 1}
            />
            <Star
                key='star_2'
                onHover={() => onHoverStar(2)}
                active={starsActive.includes(2)}
                onClick={() => onClick(2)}
                selected={rate === 2}
            />
            <Star
                key='star_3'
                onHover={() => onHoverStar(3)}
                active={starsActive.includes(3)}
                onClick={() => onClick(3)}
                selected={rate === 3}
            />
            <Star
                key='star_4'
                onHover={() => onHoverStar(4)}
                active={starsActive.includes(4)}
                onClick={() => onClick(4)}
                selected={rate === 4}
            />
            <span className='mx-1' />
            <div className='align-self-center'>
                <small>
                    {t('Good')}
                </small>
            </div>
        </div>
  );
}

Rating.propTypes = {
  className: PropTypes.string,
  rate: PropTypes.number.isRequired,
  setRate: PropTypes.func.isRequired,
};

Rating.defaultProps = {
  className: '',
};
