
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Quickstart() {
  const { t, i18n } = useTranslation('tourTranslations');

  return (
        <svg
            width='313'
            height='137'
            viewBox='0 0 313 137'
            direction='ltr'
        >
            <defs>
                <rect
                    id='prefix__einstieg-leicht-gemacht-a'
                    width='299'
                    height='122.472'
                    x='0'
                    y='0'
                    rx='3.379'
                />
                <filter
                    id='prefix__einstieg-leicht-gemacht-b'
                    width='107.7%'
                    height='118.8%'
                    x='-3.8%'
                    y='-7.8%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dy='2'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='3.5'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
                    />
                </filter>
                <path
                    id='prefix__einstieg-leicht-gemacht-d'
                    d='M3.379 0H125.85v122.472H3.38A3.379 3.379 0 0 1 0 119.093V3.38A3.379 3.379 0 0 1 3.379 0z'
                />
                <path
                    id='prefix__einstieg-leicht-gemacht-f'
                    d='M0 0h299v139.957H0z'
                />
                <path
                    id='prefix__einstieg-leicht-gemacht-h'
                    d='M.064.048h102.312v50.129H.064z'
                />
                <path
                    id='prefix__einstieg-leicht-gemacht-j'
                    d='M28.803 62.122L.032 55.347V7.475L28.803.08z'
                />
                <path
                    id='prefix__einstieg-leicht-gemacht-l'
                    d='M.143.032h44.596v24.334H.143z'
                />
                <path
                    id='prefix__einstieg-leicht-gemacht-n'
                    d='M.127.127h76.722v90.972H.127z'
                />
                <path
                    id='prefix__einstieg-leicht-gemacht-p'
                    d='M3.844 0L.465 1.484v2.61c0 .845.367 1.975 1.43 2.942 1.253 1.14 1.949 1.223 1.949 1.223s.696-.082 1.95-1.223c1.062-.967 1.428-2.097 1.428-2.942v-2.61L3.844 0z'
                />
                <filter id='prefix__einstieg-leicht-gemacht-q'>
                    <feColorMatrix
                        in='SourceGraphic'
                        values='0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0'
                    />
                </filter>
            </defs>
            <g
                fill='none'
                fillRule='evenodd'
            >
                <g transform='translate(7 5)'>
                    <mask
                        id='prefix__einstieg-leicht-gemacht-c'
                        fill='#fff'
                    >
                        <use xlinkHref='#prefix__einstieg-leicht-gemacht-a'/>
                    </mask>
                    <use
                        fill='#000'
                        filter='url(#prefix__einstieg-leicht-gemacht-b)'
                        xlinkHref='#prefix__einstieg-leicht-gemacht-a'
                    />
                    <use
                        fill='#FFF'
                        xlinkHref='#prefix__einstieg-leicht-gemacht-a'
                    />
                    <g mask='url(#prefix__einstieg-leicht-gemacht-c)'>
                        <mask
                            id='prefix__einstieg-leicht-gemacht-e'
                            fill='#fff'
                        >
                            <use xlinkHref='#prefix__einstieg-leicht-gemacht-d'/>
                        </mask>
                        <use
                            fill='#FFF'
                            xlinkHref='#prefix__einstieg-leicht-gemacht-d'
                        />
                        <g mask='url(#prefix__einstieg-leicht-gemacht-e)'>
                            <g transform='translate(-65 -16)'>
                                <mask
                                    id='prefix__einstieg-leicht-gemacht-g'
                                    fill='#fff'
                                >
                                    <use xlinkHref='#prefix__einstieg-leicht-gemacht-f'/>
                                </mask>
                                <g mask='url(#prefix__einstieg-leicht-gemacht-g)'>
                                    <g transform='translate(-517.843 -1.59)'>
                                        <path
                                            fill='#99CCD3'
                                            fillRule='nonzero'
                                            d='M517.843 1.59h299v99.243h-299z'
                                        />
                                        <path
                                            fill='#E5CDB3'
                                            fillRule='nonzero'
                                            d='M1264.579 174.183L.127 179.909l250.604-35.546 53.676-8.938 51.657-8.62 38.377-6.41 1.718-.286 1.925-.318 27.037-4.517h.016l3.387-.557 25.717-4.294 3.802-.636.54-.079 20.215-3.372 8.349-1.4 2.974-.493 5.996-1.002 16.382-2.735h328.534l11.849 2.179 7.443 1.351 1.065.207.525.096 27.642 5.057.525.112 2.465.445 26.305 4.819.191.032.064.016 26.035 4.771.096.016 1.654.302 34.957 6.409 43.769 8.016 43.736 8.032z'
                                        />
                                        <path
                                            fill='#C7B299'
                                            fillRule='nonzero'
                                            d='M919.043 115.004l-493.922.27 29.12-4.851 435.316-.827z'
                                            opacity='.2'
                                        />
                                        <path
                                            fill='#C7B299'
                                            fillRule='nonzero'
                                            d='M920.316 101.167l-493.923.111 29.121-1.972 435.315-.334z'
                                        />
                                        <path
                                            fill='#C7B299'
                                            fillRule='nonzero'
                                            d='M981.849 126.518l-625.785.287 40.095-6.696h550.733zm115.847 18.052l-846.965-.207 53.676-8.938 721.211-.891z'
                                            opacity='.2'
                                        />
                                        <path
                                            fill='#998675'
                                            fillRule='nonzero'
                                            d='M304.28 135.266l503.036-.446-504.245-.922zm640.958-15.459l-550.797.588 1.718-.286 1.925-.318h547.058zm-.637 6.998l-550.796.588 1.718-.286 1.924-.318h547.059zm-25.494-11.785l-493.97.254 3.387-.557 490.328.255.191.032zm-26.56-4.867l-434.504-.366.54-.079h431.499zm-30.632-5.614l-374.768.397 2.974-.493H861.39z'
                                            opacity='.36'
                                        />
                                        <path
                                            fill='#D9EEFD'
                                            fillRule='nonzero'
                                            d='M560.052 7.523h102.312v50.129H560.052z'
                                        />
                                        <path
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                            d='M591.495 26.83v-1.24h-9.86v1.24h-2.259v29.169h14.378V26.83zm27.737-1.24v-.811h-.413v-.827h-2.052v.827h-.413v.811h-.414v30.822h3.706V25.59z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M571.981 49.017h6.982v6.985h-6.982z'
                                        />
                                        <path
                                            fill='#CCC'
                                            fillRule='nonzero'
                                            d='M585.118 37.518v-1.24h-6.155v1.24h-1.639v4.103h-2.878v14.378h12.326V37.518z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M635.661 35.864v-1.638h-1.224v-2.052h-9.463v2.052h-1.225v1.638h-3.292v19.721h18.496V35.864z'
                                        />
                                        <path
                                            fill='#CCC'
                                            fillRule='nonzero'
                                            d='M618.819 46.552h12.326v9.862h-12.326z'
                                        />
                                        <path
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                            d='M657.037 36.278v1.638h-2.879v18.496h6.568V36.278z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M653.331 23.952v-.827h-1.225v-.414h-1.24v-.397h-2.465v.397h-1.225v-.397h-1.241v-.827h-1.224v.827h-2.068v.811h-.397v.827h-.827v31.633h12.739V23.952z'
                                        />
                                        <g
                                            opacity='.4'
                                            transform='translate(559.989 7.475)'
                                        >
                                            <mask
                                                id='prefix__einstieg-leicht-gemacht-i'
                                                fill='#fff'
                                            >
                                                <use xlinkHref='#prefix__einstieg-leicht-gemacht-h'/>
                                            </mask>
                                            <g
                                                fill='#FFF'
                                                fillRule='nonzero'
                                                mask='url(#prefix__einstieg-leicht-gemacht-i)'
                                            >
                                                <path d='M47.478-15.707l9.751 5.63-38.886 67.354-9.752-5.63zm10.585 6.999l2.547 1.471-38.886 67.354-2.548-1.471zm6.047 2.345l1.06.613-38.886 67.353-1.061-.612zM20.602-29.05l6.404 3.698L-11.88 42l-6.405-3.697zm7.482 4.305l2.494 1.44L-8.31 44.048l-2.493-1.44zm62.476 6.326l14.793 8.54-44.606 77.26-14.793-8.54zm16.2 9.355l2.465 1.424L64.62 69.62l-2.465-1.424z'/>
                                            </g>
                                        </g>
                                        <path
                                            fill='#EAD7C2'
                                            fillRule='nonzero'
                                            d='M662.38 55.586v2.052H560.068v-2.052z'
                                        />
                                        <path
                                            fill='#EAD7C2'
                                            fillRule='nonzero'
                                            d='M660.313 7.523h2.052v50.129h-2.052z'
                                        />
                                        <path
                                            fill='#D9EEFD'
                                            fillRule='nonzero'
                                            d='M666.277 7.523h43.562v50.129h-43.562z'
                                        />
                                        <path
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                            d='M672.098 26.83v-1.24h12.835v1.24h2.942v29.169h-18.719V26.83z'
                                        />
                                        <path
                                            fill='#CCC'
                                            fillRule='nonzero'
                                            d='M697.497 55.999H688.4v-6.985h9.097z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M680.384 37.518v-1.24h8.032v1.24h2.131v4.103h3.753v14.378h-16.047V37.518z'
                                        />
                                        <path
                                            fill='#D9EEFD'
                                            fillRule='nonzero'
                                            d='M736.542 63.394l-28.771-6.775V8.747l28.771-7.395z'
                                        />
                                        <g
                                            opacity='.4'
                                            transform='translate(707.74 1.272)'
                                        >
                                            <mask
                                                id='prefix__einstieg-leicht-gemacht-k'
                                                fill='#fff'
                                            >
                                                <use xlinkHref='#prefix__einstieg-leicht-gemacht-j'/>
                                            </mask>
                                            <g
                                                fill='#FFF'
                                                fillRule='nonzero'
                                                mask='url(#prefix__einstieg-leicht-gemacht-k)'
                                            >
                                                <path d='M49.306-3.55l9.751 5.63-38.886 67.353-9.752-5.63zM23.44-22.847l6.405 3.697-38.887 67.354-6.404-3.698zm7.468 4.305l2.493 1.44L-5.485 50.25l-2.493-1.44z'/>
                                            </g>
                                        </g>
                                        <path
                                            fill='#C7B299'
                                            fillRule='nonzero'
                                            d='M707.771 56.826v-2.052l30.823 6.982v2.052z'
                                        />
                                        <path
                                            fill='#C7B299'
                                            fillRule='nonzero'
                                            d='M708.598 7.523h2.052v50.129h-2.052z'
                                        />
                                        <path
                                            fill='#EAD7C2'
                                            fillRule='nonzero'
                                            d='M707.771 57.637v-2.052l30.823 6.982v2.068z'
                                        />
                                        <path
                                            fill='#EAD7C2'
                                            fillRule='nonzero'
                                            d='M738.594 64.635l-2.052-.827V.938l2.052-.827zM707.771 7.523h2.052v50.129h-2.052z'
                                        />
                                        <path
                                            fill='#C7B299'
                                            fillRule='nonzero'
                                            d='M667.104 7.523h2.052v50.129h-2.052z'
                                        />
                                        <path
                                            fill='#EAD7C2'
                                            fillRule='nonzero'
                                            d='M709.823 55.601v2.052h-43.555v-2.052z'
                                        />
                                        <path
                                            fill='#EAD7C2'
                                            fillRule='nonzero'
                                            d='M707.771 7.523h2.052v50.129h-2.052zm-41.494 0h2.052v50.129h-2.052z'
                                        />
                                        <path
                                            fill='#CCC'
                                            fillRule='nonzero'
                                            d='M734.522 68.356h39.49v41.324h-39.49z'
                                        />
                                        <path
                                            fill='#B3B3B3'
                                            fillRule='nonzero'
                                            d='M735.826 70.329h36.882v12.23h-36.882zm0 12.771h36.882v12.232h-36.882zm0 12.787h36.882v12.232h-36.882z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M751.412 72.857h5.694v3.03h-5.694zm0 12.278h5.694v3.03h-5.694zm0 12.644h5.694v3.03h-5.694z'
                                        />
                                        <path
                                            fill='gray'
                                            fillRule='nonzero'
                                            d='M765.186 58.273h-21.837a.254.254 0 0 0-.254.255v9.67a.254.254 0 0 0 .254.27h21.837a.254.254 0 0 0 .254-.27v-9.67a.254.254 0 0 0-.254-.255'
                                        />
                                        <path
                                            fill='#333'
                                            fillRule='nonzero'
                                            d='M746.943 60.881h14.664v5.883h-14.664z'
                                        />
                                        <path
                                            fill='#1A1A1A'
                                            fillRule='nonzero'
                                            d='M747.436 65.875h13.519v1h-13.519z'
                                        />
                                        <path
                                            fill='#F0F3BD'
                                            fillRule='nonzero'
                                            d='M745.21 60.675h-.461a.095.095 0 0 1-.096-.096v-.254a.095.095 0 0 1 .096-.08h.46c.064 0 .096.032.096.08v.254a.08.08 0 0 1-.095.096'
                                        />
                                        <path
                                            fill='#00C3A0'
                                            fillRule='nonzero'
                                            d='M745.29 59.466h-1.13a.095.095 0 0 1-.095-.095v-.255a.095.095 0 0 1 .095-.095h1.13c.052 0 .095.042.095.095v.255a.095.095 0 0 1-.096.095'
                                        />
                                        <path
                                            fill='#666'
                                            fillRule='nonzero'
                                            d='M760.271 51.514h-12.008l-.206 6.759h12.421z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M749.218 50.576l-.255 7.697h10.608l-.254-7.697zm11.101 15.586h-12.103l-1.623 4.501h15.348z'
                                        />
                                        <path
                                            fill='#23394C'
                                            fillRule='nonzero'
                                            d='M748.772 66.957l-.11.334h11.116l-.159-.334zm-.159.923l-.11.333h11.418l-.143-.334zm-.302.937l-.11.334h11.88l-.16-.334z'
                                            opacity='.23'
                                        />
                                        <path
                                            fill='#05668E'
                                            fillRule='nonzero'
                                            d='M786.577 39.697h12.58a2.942 2.942 0 0 1 2.926 2.926v22.553a2.942 2.942 0 0 1-2.926 2.926h-.302c.019.126.03.254.032.382 0 1.098-.89 1.988-1.988 1.988h-8.064a1.988 1.988 0 0 1-1.988-1.988 1.48 1.48 0 0 1 .032-.382h-.302a2.942 2.942 0 0 1-2.927-2.926V42.623a2.942 2.942 0 0 1 2.927-2.926'
                                            opacity='.1'
                                        />
                                        <path
                                            fill='#05668E'
                                            fillRule='nonzero'
                                            d='M783.81 64.03h18.114a1.225 1.225 0 1 1 0 2.45H783.81a1.225 1.225 0 0 1 0-2.45m0-19.387h18.114a1.225 1.225 0 0 1 0 2.45H783.81a1.225 1.225 0 0 1 0-2.45m0 5.312h18.114a1.225 1.225 0 1 1 0 2.45H783.81a1.225 1.225 0 0 1 0-2.45'
                                            opacity='.2'
                                        />
                                        <path
                                            fill='#B3B3B3'
                                            fillRule='nonzero'
                                            d='M780.406 69.279h24.938v40.405h-24.938z'
                                        />
                                        <path
                                            fill='#CCC'
                                            fillRule='nonzero'
                                            d='M802.672 74.098v2.083a1.67 1.67 0 0 0-1.67-1.67h-16.286a1.686 1.686 0 0 0-1.67 1.67v-2.083a1.686 1.686 0 0 1 1.67-1.67h16.286a1.686 1.686 0 0 1 1.67 1.67'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M802.672 76.181v12.66a1.67 1.67 0 0 1-1.67 1.67h-16.286a1.686 1.686 0 0 1-1.67-1.67v-12.66a1.686 1.686 0 0 1 1.67-1.67h16.286c.922 0 1.67.748 1.67 1.67'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M787.642 78.042h2.179v6.86h-2.179z'
                                        />
                                        <path
                                            fill='#018090'
                                            fillRule='nonzero'
                                            d='M787.642 78.981h2.179v1.148h-2.179z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M795.913 78.042h2.179v6.86h-2.179z'
                                        />
                                        <path
                                            fill='#00C3A0'
                                            fillRule='nonzero'
                                            d='M795.913 78.981h2.179v1.148h-2.179z'
                                        />
                                        <g opacity='.5'>
                                            <path
                                                fill='#FFF'
                                                fillRule='nonzero'
                                                d='M795.913 39.697h3.197V69.27h-3.197zm-3.96 0h1.877V69.27h-1.877z'
                                                opacity='.62'
                                            />
                                        </g>
                                        <path d='M620.25 19.324h34.274v62.765H620.25z'/>
                                        <path
                                            fill='#01544B'
                                            fillRule='nonzero'
                                            d='M692.17 82.925l.03 2.067.033 2.068c0 .7.016 1.384.016 2.083 0 .7-.032 1.368-.08 2.068h-.016c-.048-.7-.063-1.384-.08-2.068-.015-.684 0-1.383.017-2.083l.031-2.068.032-2.067h.016zm2.21-12.851l.048 10.56v5.297c-.016 1.75-.032 3.515-.08 5.28h-.016c-.064-1.765-.064-3.53-.08-5.28v-5.296l.064-10.56h.064zm2.051 21.106l-.063-8.128a172.375 172.375 0 0 1-.016-4.056c.016-1.351.032-2.703.08-4.055h.015c.048 1.352.064 2.704.08 4.055.016 1.352-.016 2.704-.016 4.056l-.064 8.127h-.016z'
                                        />
                                        <path
                                            fill='#35B9AB'
                                            fillRule='nonzero'
                                            d='M692.153 83.116s-.127-14.585-16.333-16.223c0 0 .238 15.889 16.333 16.223m4.278-8.112s11.85-.11 13.17-13.39c0 0-12.9.19-13.17 13.39m-13.836-16.429s.095 10.736 11.88 11.929c0 0-.159-11.69-11.88-11.929'
                                        />
                                        <path
                                            fill='#027E71'
                                            fillRule='nonzero'
                                            d='M676.742 67.355l1.972 1.924 1.956 1.94 3.881 3.913 3.833 3.96 1.892 2.004c.637.668 1.257 1.336 1.877 2.02l-1.972-1.925-1.956-1.94-3.865-3.913-3.833-3.96-1.908-2.004c-.62-.668-1.257-1.336-1.877-2.02m6.028-8.779c1.033.939 2.035 1.909 3.037 2.879l2.99 2.942c.986.986 1.973 1.972 2.943 2.974.97 1.002 1.924 2.02 2.878 3.038a171.575 171.575 0 0 1-3.037-2.879 259.388 259.388 0 0 1-2.974-2.942 250.04 250.04 0 0 1-2.943-2.974c-.97-1.002-1.94-2.02-2.894-3.038m13.661 16.62l1.623-1.717 1.622-1.718c1.081-1.145 2.195-2.274 3.276-3.42l3.308-3.371 1.67-1.686 1.67-1.67-1.606 1.734-1.638 1.717-3.277 3.404-3.308 3.387-1.654 1.67-1.686 1.67'
                                        />
                                        <path
                                            fill='#818487'
                                            fillRule='nonzero'
                                            d='M682.642 90.32h23.666l-5.614 19.515h-12.421z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M666.913 120.507h1.94l-6.775-47.236h-3.642z'
                                        />
                                        <path
                                            fill='#CCC'
                                            fillRule='nonzero'
                                            d='M654.031 110.471h2.004l.874-36.93h-3.753z'
                                        />
                                        <path
                                            fill='#DDBF9E'
                                            fillRule='nonzero'
                                            d='M556.474 64.062l-14.727 7.984h138.939l-15.809-7.984z'
                                        />
                                        <path
                                            fill='#F1E5D8'
                                            fillRule='nonzero'
                                            d='M541.747 72.062h138.94v2.083h-138.94z'
                                        />
                                        <path
                                            fill='#4D4D4D'
                                            fillRule='nonzero'
                                            d='M634.31 59.402h-46.187a1.018 1.018 0 0 1-1.018-1.033v-3.85h48.222v3.85c0 .564-.453 1.025-1.018 1.033'
                                        />
                                        <path
                                            fill='#333'
                                            fillRule='nonzero'
                                            d='M635.327 54.6h-48.222V27.37a.939.939 0 0 1 .939-.954h46.345a.938.938 0 0 1 .938.954V54.6zm-16.254 9.065h-2.815v-4.263h-9.892v4.263h-2.99c-.79 0-1.432.64-1.432 1.431h18.545a1.416 1.416 0 0 0-1.416-1.431'
                                        />
                                        <path
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                            d='M612.187 57.017a1.002 1.002 0 1 1-2.004.031 1.002 1.002 0 0 1 2.004-.031'
                                        />
                                        <path
                                            fill='#CCE6E9'
                                            fillRule='nonzero'
                                            d='M588.919 28.341h44.596v24.334h-44.596z'
                                        />
                                        <g
                                            opacity='.6'
                                            transform='translate(588.776 28.31)'
                                        >
                                            <mask
                                                id='prefix__einstieg-leicht-gemacht-m'
                                                fill='#fff'
                                            >
                                                <use xlinkHref='#prefix__einstieg-leicht-gemacht-l'/>
                                            </mask>
                                            <g
                                                fillRule='nonzero'
                                                mask='url(#prefix__einstieg-leicht-gemacht-m)'
                                            >
                                                <path
                                                    className='fill-primary'
                                                    d='M46.52 5.137c-8.254 10.815-13.264 12.326-16.334 11.594-3.53-.859-3.753-4.517-8.588-6.107-5.233-1.734-7.904 1.559-13.964.62-2.497-.397-6.187-1.59-10.354-5.614.053 7.39.096 14.786.128 22.187h49.605c-.16-7.55-.323-15.11-.493-22.68z'
                                                />
                                                <path
                                                    className='fill-primary'
                                                    d='M46.138 9.495c-9.495 8.7-14.552 9.431-17.447 8.222-1.797-.747-2.958-2.306-6.107-2.99-3.149-.684-5.869.223-8.222.748-3.181.716-7.953.922-14.585-1.622v13.9H45.9l.238-18.258z'
                                                />
                                                <path
                                                    fill='#FFF'
                                                    d='M46.138 9.495c-9.495 8.7-14.552 9.431-17.447 8.222-1.797-.747-2.958-2.306-6.107-2.99-3.149-.684-5.869.223-8.222.748-3.181.716-7.953.922-14.585-1.622v13.9H45.9l.238-18.258z'
                                                    opacity='.3'
                                                />
                                                <path
                                                    className='fill-primary'
                                                    d='M45.025 10.99c-1.845 2.258-5.662 6.282-11.849 8.604-2.29.859-8.938 3.356-15.697.875-1.813-.668-1.925-1.066-3.61-1.75-5.122-2.067-10.625-1.065-15.332.748v8.286H44.96l.064-16.763z'
                                                />
                                                <path
                                                    fill='#000'
                                                    d='M45.025 10.99c-1.845 2.258-5.662 6.282-11.849 8.604-2.29.859-8.938 3.356-15.697.875-1.813-.668-1.925-1.066-3.61-1.75-5.122-2.067-10.625-1.065-15.332.748v8.286H44.96l.064-16.763z'
                                                    opacity='.1'
                                                />
                                            </g>
                                        </g>
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M604.442 28.358L588.919 44.15v-8.81l6.712-6.982zm5.121 0l-20.644 21.31v-3.943l17.097-17.367z'
                                            opacity='.45'
                                        />
                                        <path
                                            fill='#4D4D4D'
                                            fillRule='nonzero'
                                            d='M588.744 69.009h44.945l-3.387-3.038h-38.171z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M590.636 68.452l2.147-1.924h36.866l2.148 1.924z'
                                        />
                                        <path
                                            fill='#4D4D4D'
                                            fillRule='nonzero'
                                            d='M611.296 68.77v-2.561h-.175v2.561m-2.147.016l.302-2.577-.175-.015-.302 2.576m-2.147.032l.604-2.577-.175-.031-.604 2.56m-2.147.048l.891-2.561-.16-.063-.89 2.576m-2.163.064l1.193-2.577-.159-.079-1.193 2.576m-2.179.08l1.495-2.561-.143-.095-1.495 2.576m-2.179.096l1.797-2.577-.143-.095-1.797 2.56m-2.179.112l2.083-2.577-.127-.111-2.099 2.576m-2.195.112l2.402-2.561-.128-.127-2.401 2.576m20.596-2.513l.302 2.577.175-.016-.302-2.576m1.845.031l.604 2.577.159-.048-.588-2.56m1.86.047l.891 2.561.159-.048-.891-2.576m1.861.063l1.193 2.577.159-.08-1.193-2.576m1.877.095l1.495 2.561.143-.08-1.495-2.576m1.877.095l1.797 2.577.143-.112-1.797-2.56m1.893.095l2.083 2.577.143-.112-2.099-2.576m1.892.127l2.402 2.561.127-.112-2.401-2.576'
                                        />
                                        <path
                                            fill='#4D4D4D'
                                            fillRule='nonzero'
                                            d='M591.161 67.577h40.302v-.175h-40.302'
                                        />
                                        <path
                                            fill='#333'
                                            fillRule='nonzero'
                                            d='M588.744 69.009h44.93v1h-44.93z'
                                        />
                                        <path
                                            fill='#1A1A1A'
                                            d='M594.453 122.145a1.924 1.924 0 1 0 0-3.85 1.924 1.924 0 0 0 0 3.85zm19.992 0a1.924 1.924 0 1 0 0-3.85 1.924 1.924 0 0 0 0 3.85z'
                                        />
                                        <path
                                            fill='#B3B3B3'
                                            fillRule='nonzero'
                                            d='M593.833 102.153h3.404v13.173h-3.404z'
                                        />
                                        <path
                                            fill='#333'
                                            d='M594.008 113.684h3.054a.764.764 0 0 1 .763.779v1.384l15.539.906a2.131 2.131 0 0 1 2.003 2.131h-39.665a2.131 2.131 0 0 1 2.004-2.13l15.539-.907v-1.384a.78.78 0 0 1 .763-.78z'
                                        />
                                        <path
                                            fill='#999'
                                            d='M606.556 91.974a3.706 3.706 0 0 1-3.705 3.706h-3.213v6.282a.859.859 0 0 1-.875.859h-.477v3.435a.78.78 0 0 1-.763.764h-3.976a.78.78 0 0 1-.764-.764v-3.435h-.477a.859.859 0 0 1-.874-.859V95.68h-3.197a3.706 3.706 0 0 1-3.706-3.706h22.027z'
                                        />
                                        <path
                                            fill='#1A1A1A'
                                            fillRule='nonzero'
                                            d='M575.161 120.22a1.924 1.924 0 0 0 3.85 0h-3.85z'
                                        />
                                        <path
                                            fill='#000'
                                            fillRule='nonzero'
                                            d='M577.086 118.296a1.924 1.924 0 0 0-1.925 1.924h3.85a1.924 1.924 0 0 0-1.925-1.924zm17.367 0a1.924 1.924 0 0 0-1.924 1.924h3.849a1.94 1.94 0 0 0-1.925-1.924zm19.992 0a1.924 1.924 0 0 0-1.924 1.924h3.848a1.924 1.924 0 0 0-1.924-1.924z'
                                        />
                                        <path
                                            fill='gray'
                                            fillRule='nonzero'
                                            d='M591.447 95.68h1.352v7.141h-.54a.811.811 0 0 1-.812-.811v-6.33z'
                                        />
                                        <path
                                            fill='gray'
                                            fillRule='nonzero'
                                            d='M592.8 102.821h1.351v4.183h-.54a.811.811 0 0 1-.812-.811v-3.372z'
                                        />
                                        <path
                                            fill='#333'
                                            fillRule='nonzero'
                                            d='M566.032 55.729a2.592 2.592 0 0 1 2.45-3.468h13.502s8.748 0 13.599 19.324c0 0 3.005 16.795 15.49 16.222h5.424a2.274 2.274 0 0 1 2.274 2.275 2.275 2.275 0 0 1-2.274 2.29H584.02a12.66 12.66 0 0 1-12.58-11.213l-1.066-9.256a21.328 21.328 0 0 0-.43-2.45c-.588-2.528-2.035-8.428-3.912-13.724z'
                                        />
                                        <path
                                            fill='#1A1A1A'
                                            fillRule='nonzero'
                                            d='M566.032 55.729l12.342-.319a11.562 11.562 0 0 1 10.64 6.235c.986 1.892 1.956 4.342 2.815 7.49 0 0-15.618-11.864-22.52-2.29l-3.277-11.116zm7.444 30.981s7.332 4.294 24.285-.78c0 0 2.8 3.818 8.525 4.215h6.49a5.121 5.121 0 0 1 3.657 1.527l.604.62-33.017.064s-6.87.143-10.544-5.646z'
                                            opacity='.5'
                                        />
                                        <path
                                            fill='#F79470'
                                            fillRule='nonzero'
                                            d='M681.163 95.394s5.694-5.948 6.632-5.55c.939.397.27 2.687.27 2.687l-6.09 3.928-.812-1.065z'
                                        />
                                        <path
                                            fill='#F79470'
                                            fillRule='nonzero'
                                            d='M672.543 95.171l6.219.35s5.47-2.243 7.427-2.433c2.29-.223 2.879.827 1.654 2.83-.588.955-2.48 2.736-3.499 3.674-.43.382.143 1.257-.43 1.241l-.953.048c-1.48.047-2.497-1.304-3.881-1.845l-8.954.27s-1.209-2.163 1.034-4.835'
                                        />
                                        <path
                                            fill='#333'
                                            fillRule='nonzero'
                                            d='M680.575 96.555c-.636 0-.78-.7-.859-1.13-.08-.46-.223-1.208.589-1.367l.795-.143.032.222.365 1.957.255.254-.986.175-.191.032zm-.461-1.209c.127.684.254.843.588.78l.382-.064-.318-1.686-.382.08c-.334.063-.398.238-.27.89z'
                                        />
                                        <path
                                            fill='#B2B5B8'
                                            fillRule='nonzero'
                                            d='M692.217 94.44l-.477-2.561a.19.19 0 0 0-.223-.143l-2.29.43a.19.19 0 0 0-.16.222l.478 2.56c.03.102.134.164.238.144l2.29-.43a.175.175 0 0 0 .144-.223'
                                        />
                                        <path
                                            fill='#DEDEE0'
                                            fillRule='nonzero'
                                            d='M690.467 92.96v-.08a.175.175 0 0 1 .128-.206l.509-.095a.175.175 0 0 1 .19.143v.08a.159.159 0 0 1-.143.19l-.493.096a.159.159 0 0 1-.19-.128m.19 1.034v-.08a.159.159 0 0 1 .127-.19l.51-.096a.159.159 0 0 1 .19.128v.08a.19.19 0 0 1-.127.206l-.509.08a.143.143 0 0 1-.19-.128'
                                        />
                                        <path
                                            className='fill-primary'
                                            fillRule='nonzero'
                                            d='M682.579 92.944l-.016-.047 6.791-1.304.732 3.912-6.76 1.288-.031-.032z'
                                        />
                                        <path
                                            fill='#333'
                                            fillRule='nonzero'
                                            d='M680.654 95.219c-.206-1.082-.27-1.909.812-2.115l.97-.191h.127v.047l.716 3.817.031.032-1.129.223c-1.081.207-1.336-.716-1.542-1.797'
                                        />
                                        <path
                                            fill='#DEDEE0'
                                            fillRule='nonzero'
                                            d='M689.354 93.692l-.398.27c-.047.048-.063.032-.063-.031 0-.064-.048-.08-.112-.064l-.81.143c.063.064.095.143.158.19a.143.143 0 0 0 .223-.047c0-.032.016-.032.048-.032s.27-.047.286.191c.016.239.016.032 0 .048s-.27.08-.286.048c-.016-.032-.064-.048-.096-.048a.239.239 0 0 1-.334-.127.318.318 0 0 0-.429-.16l-.127.032c-.096.016-.175.016-.191.128a.175.175 0 0 1-.143.111c-.207.032-.35-.08-.334-.254a.223.223 0 0 1 .159-.223.239.239 0 0 1 .286.064.19.19 0 0 0 .302-.08l.096-.27a.111.111 0 0 1 .127-.096l.19-.032a.239.239 0 0 0 .16-.095.19.19 0 0 1 .27-.048.207.207 0 0 1-.074.36.19.19 0 0 1-.148-.026c-.128-.127-.255-.016-.366-.016-.047.08-.084.166-.112.255.016.032.048.016.064.016l1.081-.191c.096-.016.048-.064.048-.111 0-.048-.016-.08.064-.064l.413.111c.016.016.048.016.048.048'
                                        />
                                        <path
                                            fill='#F79470'
                                            fillRule='nonzero'
                                            d='M689.418 95.87s-.557-1.128-1.893-2.4c-.811-.78-1.4-1.353-2.274-.939a1.384 1.384 0 0 0-.175.398.525.525 0 0 0 .048.54c.302.414.302 1.002 2.703 3.642 0 0-1.288-2.29-2.751-3.514-.477-.414-1.145-.796-1.559-.812a.588.588 0 0 0-.54.414c-.048.095-.064.08-.032.223.238 1.177 2.306 3.594 2.306 3.594s-3.5-5.376-3.595-2.306c-.016.477.478.747.652 1.16.907 2.275 3.674 3.77 3.674 3.77s-2.513-3.053-4.087-3.976c-.636-.382-1.177.19-1.066.748.366 1.956 3.086 4.421 3.086 4.421s3.149.095 3.753-1.066c.605-1.16 2.847-2.274 1.75-3.896'
                                        />
                                        <path
                                            fill='#4A263D'
                                            fillRule='nonzero'
                                            d='M685.124 93.597l2.194 2.385s-1.606-1.129-2.194-2.385m-1.877.668l2.863 3.371s-2.322-2.1-2.863-3.371m-1.082 1.479l2.545 2.56s-1.988-1.288-2.545-2.56m-1.288.986l2.37 2.497s-1.432-1.066-2.243-2.275l-.127-.222'
                                        />
                                        <path
                                            fill='#4A263D'
                                            fillRule='nonzero'
                                            d='M681.45 97.477a3.117 3.117 0 0 1-.525-.668c-.302-.461-.239-1.876 1.24-1.065l.143.127a2.131 2.131 0 0 0-.81-.255c-.366-.016-.637.255-.59.525.08.541.494 1.209.494 1.209l.048.127zm4.103-3.292c-.19-.207-.36-.43-.509-.668-.286-.461.207-2.068 1.956-.588l-.668-.35s-1.081-.462-1.208.35c-.096.54.381 1.129.381 1.129l.048.127z'
                                        />
                                        <path
                                            fill='#4A263D'
                                            fillRule='nonzero'
                                            d='M683.517 94.662a4.835 4.835 0 0 1-.477-.668c-.286-.461-.207-2.401 2.21-.27l-1.033-.62a1.543 1.543 0 0 0-.573-.175c-.334-.032-.683.095-.636.381.102.431.257.848.461 1.24l.048.112z'
                                        />
                                        <path
                                            className='fill-primary'
                                            fillRule='nonzero'
                                            d='M637.06 69.31s14.315 6.315 26.545 20.088c7.236 2.624 11.308 2.004 10.894 2.56-.413.557-.668 12.088-.668 12.088l-12.119-4.454s-22.202-14.345-25.446-19.625c-3.245-5.28.795-10.656.795-10.656z'
                                        />
                                        <path
                                            fill='#044D6B'
                                            fillRule='nonzero'
                                            d='M621.777 222.946l-2.863.207s-12.024-83.386-10.767-98.623c8.604-3.864 14.346 7.507 14.346 7.507l-.541 46.218-.175 44.69zm7.745-.302l2.895.493s9.033-88.523 7.268-103.696c-8.715-3.483-13.789 11.387-13.789 11.387l1.909 46.155 1.717 45.66z'
                                        />
                                        <path
                                            fill='#044D6B'
                                            fillRule='nonzero'
                                            d='M633.307 105.636l6.378 13.805s.175 12.803-17.192 12.596c-17.368-.207-14.887-8.318-14.696-10.147.19-1.829 5.264-15.252 5.264-15.252s16.493-4.056 20.246-1.002z'
                                        />
                                        <path
                                            fill='#000'
                                            fillRule='nonzero'
                                            d='M637.06 69.31s14.315 6.315 26.545 20.088c7.236 2.624 11.308 2.004 10.894 2.56-.413.557-.668 12.088-.668 12.088l-12.119-4.454s-22.202-14.345-25.446-19.625c-3.245-5.28.795-10.656.795-10.656z'
                                            opacity='.1'
                                        />
                                        <path
                                            fill='#F0F3BD'
                                            fillRule='nonzero'
                                            d='M635.757 111.06c-4.597 2.417-5.55 6.886-23.141.97-3.26-16.732-2.8-39.38-2.8-39.38s10.768-8.858 29.551-1.574c-.668 20.66-3.61 39.983-3.61 39.983'
                                        />
                                        <path
                                            className='fill-primary'
                                            fillRule='nonzero'
                                            d='M618.373 125.278s-11.164-.859-13.232.175c2.497-6.823 6.028-14.25 6.028-14.25l-3.897-23.38 1.018-7.856c.795-2.402.048-4.66 2.068-6.219 2.672-2.052 4.262-4.994 4.628-4.994.62 0 1.797 29.12 1.797 29.12l1.59 27.404zm24.588-.986c-.62 0-7.856.604-7.856.604l-1.543-12.914s-2.465-40.508-1.209-42.783c6.807.637 7.014 1.877 7.014 1.877l-.223 9.495s2.943 4.215 2.863 7.873c-.08 3.658-3.101 6.186-3.101 6.186l-3.15 16.43 7.205 13.232z'
                                        />
                                        <path
                                            fill='#000'
                                            fillRule='nonzero'
                                            d='M637.745 94.519c-1.543.35-3.086.62-4.66.843a78.447 78.447 0 0 1-4.692.54 47.172 47.172 0 0 1-4.74.144 19.546 19.546 0 0 1-2.37-.175 10.322 10.322 0 0 1-2.305-.525c.762.182 1.54.294 2.322.334.779.048 1.574.048 2.354.048.779 0 1.574-.032 2.353-.08l2.354-.159c1.559-.127 3.133-.286 4.692-.445l4.692-.525z'
                                            opacity='.1'
                                        />
                                        <path
                                            fill='#701212'
                                            fillRule='nonzero'
                                            d='M638.445 63.315s-6.012-10.863 2.147-19.276c-15.014-3.674-24.382-4.135-24.382-4.135s4.39 17.208-2.544 21.025c4.453 4.708 12.66 10.115 24.779 2.386'
                                        />
                                        <path
                                            fill='#F79470'
                                            fillRule='nonzero'
                                            d='M635.963 69.9l-10.449 12.739-9.065-14.648s5.471-1.018 5.694-4.008c.222-2.99-.668-11.085-.668-11.085l7.984 2.115s-.16 5.28.047 9.05c.207 3.769 3.865 5.868 6.457 5.836'
                                        />
                                        <path
                                            fill='#000'
                                            fillRule='nonzero'
                                            d='M621.49 54.79s1.289-.906 8.557.493c-.382 1.288-.716 3.785-.572 6.235-2.593 1.16-4.756 1.876-7.253 1.097-.175-3.547-.731-7.825-.731-7.825'
                                            opacity='.1'
                                        />
                                        <path
                                            fill='#F79470'
                                            fillRule='nonzero'
                                            d='M636.202 45.534c.207 4.914-2.592 13.534-9.924 16.08-9.13-3.897-9.002-16.335-9.002-16.335s.811-9.208 2.592-10.306c1.782-1.097 15.046-.97 15.22-.954.176.016 1.114 11.515 1.114 11.515'
                                        />
                                        <path
                                            fill='#F79470'
                                            fillRule='nonzero'
                                            d='M618.39 51.53c-2.784-1.177-4.295-2.64-3.372-4.326.922-1.686 2.528.143 2.528.143l.843 4.183z'
                                        />
                                        <path
                                            fill='#851F1A'
                                            fillRule='nonzero'
                                            d='M636.711 45.407c-4.072-1.925-3.738-7.22-3.213-9.209-2.64 4.85-11.26 6.091-15.888 4.278-2.672 1.21-.318 3.992-.08 5.265.239 1.272-.19 6.234-3.96 7.316.477-3.802-.89-7.157-2.688-8.35-1.797-1.193.334-8.207 1.957-8.827 1.622-.62 3.403-1.734 4.23-4.135.827-2.402 7.841-6.632 12.421-3.722 6.696-.636 10.338.62 10.306 6.632 4.485 2.593 4.215 5.042 3.674 7.714-2.64 1.559-3.976 5.885-3.976 5.885l-3.578-.144.795-2.703z'
                                        />
                                        <path
                                            fill='#4A263D'
                                            fillRule='nonzero'
                                            d='M623.224 53.772a2.497 2.497 0 0 1 1.002-.334 2.78 2.78 0 0 1 1.082.064c.35.095 1.876.413 1.86.78-.365.063-.556.095-1.972-.335a5.98 5.98 0 0 0-.954-.159h-1.018m-1.399-7.904a1.104 1.104 0 0 0 2.194.238 1.113 1.113 0 0 0-.986-1.224 1.097 1.097 0 0 0-1.208.986m9.431.493a1.113 1.113 0 1 0 2.214.236 1.113 1.113 0 0 0-2.214-.236'
                                        />
                                        <path
                                            stroke='#59081C'
                                            strokeWidth='.657'
                                            d='M628.822 46.377c-.206 1.924 1.034 3.626 2.752 3.817 1.718.19 3.292-1.225 3.499-3.133.207-1.909-1.018-3.626-2.736-3.817-1.717-.191-3.308 1.224-3.515 3.133zm-9.924-1.082c-.207 1.925 1.018 3.626 2.752 3.817 1.733.191 3.292-1.224 3.499-3.133.206-1.908-1.018-3.626-2.752-3.817-1.733-.19-3.292 1.225-3.499 3.133zm9.924 1.082c-.668-1.527-2.337-2.084-3.673-.398'
                                        />
                                        <path
                                            fill='#4A263D'
                                            fillRule='nonzero'
                                            d='M634.119 47.06a9.034 9.034 0 0 0-.732-.795 2.417 2.417 0 0 0-.811-.493 1.845 1.845 0 0 0-.938-.08c-.354.063-.704.142-1.05.24.26-.29.58-.517.938-.668a1.89 1.89 0 0 1 1.225-.016c.393.134.732.39.97.731.222.321.359.693.398 1.082m-9.749-1.671a7.332 7.332 0 0 0-1.05-.222 2.386 2.386 0 0 0-.955.032 2.1 2.1 0 0 0-.81.477c-.26.25-.505.516-.732.795.045-.386.182-.757.397-1.081a1.75 1.75 0 0 1 .986-.716 2.004 2.004 0 0 1 1.225.064c.36.14.682.363.938.652'
                                        />
                                        <path
                                            fill='#F79470'
                                            fillRule='nonzero'
                                            d='M634.834 52.389c3.054-.716 4.549-2.45 4.406-4.04-.223-2.513-3.324-.239-3.324-.239l-1.082 4.279z'
                                        />
                                        <path
                                            fill='#4A263D'
                                            fillRule='nonzero'
                                            d='M627.216 45.9c-.05.332-.045.67.016 1.002.062.308.164.608.302.89.149.288.319.565.51.827.193.255.4.5.62.732l.143.159-.096.175a4.52 4.52 0 0 1-.509.588 2.83 2.83 0 0 1-.604.446 2.37 2.37 0 0 1-.716.238 1.686 1.686 0 0 1-.747-.032 2.27 2.27 0 0 0 .684-.19c.205-.082.393-.201.556-.35a2.56 2.56 0 0 0 .477-.446c.143-.168.27-.35.382-.54l.048.334a4.199 4.199 0 0 1-.684-.812 3.53 3.53 0 0 1-.493-.938 2.783 2.783 0 0 1-.175-1.065c.012-.358.11-.707.286-1.018'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M626.04 82.066c2.194-8.78 8.81-15.888 10.782-14.839 1.972 1.05 4.422 7.952 4.422 7.952s-7.634-3.212-15.205 6.887'
                                        />
                                        <path
                                            fill='#F79470'
                                            fillRule='nonzero'
                                            d='M613.125 106.225s8.127.922 9.876.222c1.75-.7 7.014-1.224 8.16-1.59 1.144-.366 2.64-2.18 3.276-2.259.636-.08.35 3.229-2.959 4.501 0 0-.254.477.637.573.89.095 3.753 1.479 5.137.397 1.383-1.081 2.226-.763 2.274-.46.048.301.398 1.892-.127 2.321-.525.43-.795 1.05-1.845 1.972-1.05.923-.764 1.32-3.038 1.114-2.274-.207-5.44-.573-6.377-1.289-.303-.222-3.356-2.497-5.392-2.163-4.199.716-8.27 1.225-8.938 1.495'
                                        />
                                        <path
                                            fill='#4A263D'
                                            fillRule='nonzero'
                                            d='M639.43 109.135c-.524.095-1.065.175-1.606.239-.54.063-1.065.11-1.606.159a18.71 18.71 0 0 1-1.622.063c-.542.011-1.084-.01-1.623-.063l1.607-.064 1.622-.111 1.606-.112 1.623-.111m-1.002 1.622a13.98 13.98 0 0 1-1.384.239c-.477.063-.938.095-1.4.127-.46.032-.938.048-1.415.048a10.37 10.37 0 0 1-1.4-.112c.477 0 .939 0 1.4-.031l1.4-.064 2.799-.207zm-1.559 1.638c-.465.056-.932.088-1.4.096-.477.032-.938.016-1.415.016l-1.4-.096a13.98 13.98 0 0 1-1.383-.238l1.4.095 1.399.064 2.799.063z'
                                        />
                                        <path
                                            className='fill-primary'
                                            fillRule='nonzero'
                                            d='M616.226 104.84l1.05 7.953s-14.934-2.163-15.666-4.294c-.493-1.416.684-15.92 3.229-25.081 1.717-6.219 4.485-9.94 4.835-10.704.954-2.1 5.837-3.387 5.82 2.831-.015 6.219-5.773 27.053-5.773 27.053l6.505 2.243z'
                                        />
                                        <path
                                            fill='#000'
                                            fillRule='nonzero'
                                            d='M616.226 104.84l1.05 7.953s-14.934-2.163-15.666-4.294c-.493-1.416.684-15.92 3.229-25.081 1.717-6.219 4.485-9.94 4.835-10.704.954-2.1 5.837-3.387 5.82 2.831-.015 6.219-5.773 27.053-5.773 27.053l6.505 2.243z'
                                            opacity='.1'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M624.592 81.86c.461.953-5.757-12.517-8.223-14.076-4.66.922-7.045 7.634-7.045 7.634s11.165-2.147 15.268 6.441'
                                        />
                                        <path
                                            fill='#ABABAE'
                                            d='M652.79 55.792l-.747-.08.254-.795c.016-.159.445-.127.43.048l.063.827z'
                                        />
                                        <path
                                            fill='#59595B'
                                            d='M652.95 56.38l-1.13-.11.08-.94c0-.03.031-.063.063-.095a.08.08 0 0 1 .096-.031l.858.08c.037.002.071.02.096.047a.302.302 0 0 1 .032.111l-.096.939z'
                                        />
                                        <path
                                            fill='#383839'
                                            d='M650.977 64.794l.891-9.145 1.145.111-.891 9.129z'
                                        />
                                        <path
                                            fill='#404041'
                                            d='M651.534 64.842l.859-9.145.62.063-.891 9.129z'
                                        />
                                        <path
                                            fill='#ABABAE'
                                            d='M652.997 55.808l.016-.143-1.257-.111-.016-.016-.079.031-.032.08-.238 2.433.127.016.111-.016.064-.572-.112-.159.159-1.559.032-.063.08-.032z'
                                        />
                                        <path
                                            fill='#E4E6E7'
                                            fillRule='nonzero'
                                            d='M646.936 56.59l.964-.268 2.707 9.76-.964.267z'
                                        />
                                        <path
                                            fill='#CFD0D2'
                                            fillRule='nonzero'
                                            d='M647.246 56.507l.964-.267 2.706 9.76-.963.266z'
                                        />
                                        <path
                                            fill='#43525A'
                                            d='M647.78 56.874h.064l.048.175h-.048l-.239.063-.46.127.588 2.132h-.048c-.032-.016-.032 0-.175-.414-.143-.413-.604-1.718-.636-1.893-.175-1.033-.255-1.86-.477-3.021-.016-.08.35-.191.381-.112l1.002 2.943z'
                                        />
                                        <path
                                            fill='#666'
                                            d='M649.911 55.061l-.174 8.079-.796-.016.191-8.079.398-.159z'
                                        />
                                        <path
                                            fill='#4D4D4D'
                                            d='M649.911 55.061l-.174 8.079-.398-.016.191-8.318z'
                                        />
                                        <path
                                            fill='#E5CDB3'
                                            d='M649.132 55.045l.43-1.925.35 1.925c-.032.08-.08.19-.144.19-.063 0-.063-.286-.238-.302-.175-.016-.127.302-.255.302-.127 0-.143-.19-.143-.19z'
                                        />
                                        <path
                                            fill='#4D4D4D'
                                            d='M649.434 53.709l.128-.589.111.589z'
                                        />
                                        <path
                                            fill='#44535B'
                                            d='M651.406 55.617l-1.431 7.952-.779-.127 1.431-7.968.414-.079z'
                                        />
                                        <path
                                            fill='#37454E'
                                            d='M651.406 55.617l-1.431 7.952-.382-.063 1.464-8.191z'
                                        />
                                        <path
                                            fill='#E5CDB3'
                                            d='M650.627 55.474l.716-1.829.063 1.972c-.047.064-.11.175-.174.16-.064-.017-.016-.303-.191-.335-.175-.032-.175.27-.302.255-.128-.016-.112-.223-.112-.223z'
                                        />
                                        <path
                                            fill='#414141'
                                            d='M651.136 54.202l.223-.557v.604z'
                                        />
                                        <path
                                            fill='#ACB0B4'
                                            d='M647.017 58.655h5.757l-.047 7.968-5.678.016z'
                                        />
                                        <path
                                            fill='#818487'
                                            fillRule='nonzero'
                                            d='M647.669 59.291h1v6.238h-1zm1.018 0h1v6.238h-1zm1.018 0h1v6.238h-1zm1.018 0h1v6.238h-1zm1.017 0h1v6.238h-1z'
                                        />
                                        <path
                                            className='fill-primary'
                                            fillRule='nonzero'
                                            d='M660.6 67.323h.444a2.004 2.004 0 0 0 0-4.008h-.445v.716h.445a1.288 1.288 0 1 1 0 2.576h-.445v.716zm-2.705-5.599c1.607 0 2.895.16 2.91.334 0 .191-1.303.35-2.91.35-1.606 0-2.91-.159-2.91-.35 0-.175 1.304-.334 2.91-.334z'
                                        />
                                        <path
                                            className='fill-primary'
                                            fillRule='nonzero'
                                            d='M655.017 62.122c.207.16 1.415.286 2.878.286h.287c1.479-.016 2.624-.175 2.624-.35v6.664c0 .191-1.304.334-2.91.334-1.607 0-2.911-.143-2.911-.334v-6.664c0 .016.016.032.032.064z'
                                        />
                                        <path
                                            fill='#000'
                                            fillRule='nonzero'
                                            d='M657.895 61.724c1.607 0 2.895.16 2.91.334 0 .191-1.303.35-2.91.35-1.606 0-2.91-.159-2.91-.35 0-.175 1.304-.334 2.91-.334z'
                                            opacity='.1'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M714.674 88.094l1.797-9.59.35-2.227 1.797.62 12.15 1.734-2.321 8.938c-1.13.323-2.285.557-3.452.7-2.322.222-4.77.334-7.109.159l-3.212-.334z'
                                        />
                                        <path
                                            fill='#23394C'
                                            fillRule='nonzero'
                                            d='M727.445 84.483a.19.19 0 0 0-.127-.27l-9.813-1.638a.175.175 0 0 0-.207.143.175.175 0 0 0 .159.207l9.797 1.654c.076.006.15-.03.19-.096z'
                                            opacity='.43'
                                        />
                                        <path
                                            fill='#23394C'
                                            fillRule='nonzero'
                                            d='M724.868 85.151a.175.175 0 0 0-.127-.254l-7.586-1.272a.175.175 0 0 0-.207.159.175.175 0 0 0 .143.206l7.586 1.257a.159.159 0 0 0 .191-.096zm1.797 1.177a.19.19 0 0 0-.127-.27l-9.51-1.59a.19.19 0 0 0-.223.143.206.206 0 0 0 .159.222l9.51 1.59c.077.007.15-.03.191-.095zm2.481-5.788a.175.175 0 0 0-.143-.224l-2.847-.477a.175.175 0 0 0-.206.143.19.19 0 0 0 .143.223l2.847.477a.175.175 0 0 0 .206-.143zm-9.303-.128a.19.19 0 0 0-.144-.239l-1.685-.334a.207.207 0 0 0-.223.16.175.175 0 0 0 .159.206l1.686.334a.207.207 0 0 0 .207-.127zm-.239.779a.19.19 0 0 0-.143-.222l-1.686-.334a.19.19 0 0 0-.223.143.175.175 0 0 0 .143.206l1.686.334a.19.19 0 0 0 .223-.127zm9.352.143a.19.19 0 0 0-.144-.238l-2.846-.477a.175.175 0 0 0-.207.159.175.175 0 0 0 .143.206l2.847.478a.19.19 0 0 0 .207-.128z'
                                            opacity='.23'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M719.795 85.692l-.525-1.097-1.097-.875-.668.477 1.717 1.797z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M717.505 84.197l.668-.477-1.893.08-1.384.477-.318 1.892 1.877-1.972.652.318.032-.238.191.063z'
                                        />
                                        <path
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                            d='M718.697 86.058l.446.938-1.654 1.893-.716-.748z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M716.773 88.141l-1.193.08v-.827l1.241-1.988 1.876.652z'
                                        />
                                        <path
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                            d='M716.821 85.406l-.366-1.209-1.177 1.829.302 1.368z'
                                        />
                                        <path
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                            d='M716.455 84.197l.652.318.095-.7.303.382 1.129.048.286 1.145.875.302-1.018.541-.08-.175-1.876-.652z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M719.795 85.692l.143 1.177-1.097.493.302-.366-.366-.763z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M718.841 87.362l.302-.366-.446-.938-1.876-.652-.366-1.209.652.318.032-.238.89.334.175.35-.35.493.716-.112.334.557.414.064.35.636-.398.572z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M716.455 84.197l-1.177 1.829.302 1.368v.827l-.286-.127-.716-1.925z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M716.455 84.197l-1.877 1.972.096-.636z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M715.294 88.094l2.195.795.938.334-2.306.175z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M717.489 88.889l-1.909-.668 1.193-.08zm0 0l.938.334 1.177-2.211-.763.35z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M717.489 88.889l1.352-1.527.763-.35-.127.668-.573.652-.811.78z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M714.578 86.169l.509 2.736.827.159z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M714.578 86.169l-.541 1.098 1.05 1.638zm6.139 5.265l1.702-.7 1.304-1.257-.811-.604-2.72 2.02z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M722.912 88.873l.811.604-.302-1.908-.891-1.352-3.037-.064 3.308 1.638-.462.716h.414l-.095.207z'
                                        />
                                        <path
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                            d='M720.033 90.368l-1.447.573-3.149-1.432 1.113-.827z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M716.55 88.682l-.206-1.225 1.304-.111 3.26 1.002-.875 2.02z'
                                        />
                                        <path
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                            d='M720.908 88.348l1.893-.557-3.022-.938-2.131.493zm1.893-.557l-.462.716 1.13.016-.557.35v1.161l-1.781.461-.414.939-.938-.971.254-.095.875-2.02z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M720.717 91.434l-1.861.302-.858-1.066.588.271 1.193-.478z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M717.998 90.67l.588.271 1.447-.573.875-2.02 1.893-.557-.462.716h.414l-.461.97-.557.223-.811-.286.239.715-.843.414-.064.445-.97.43-.954-.318z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M722.801 87.791l-3.022-.938-2.131.493-1.304.111.159-.302 2.99-1.002z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M722.801 87.791l-3.308-1.638 1.033.016z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M716.503 87.155l-1.066 2.354-.445 1.018-.493-2.338z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M715.437 89.509l.907-2.052.206 1.225zm0 0l-.445 1.018 3.61.891-.604-.748z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M715.437 89.509l2.561 1.161.604.748-1.066-.032-1.081-.493-1.304-.732z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M719.493 86.153l-4.295.891-.174.875z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M719.493 86.153l-1.782-.397-2.513 1.288zm9.94 3.595l1.113-.557.843-1.034-.541-.525-1.781 1.654z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M730.848 87.632l.541.525-.223-1.622-.62-1.145-2.02-.095 2.227 1.431-.286.588.27.016-.064.159z'
                                        />
                                        <path
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                            d='M728.972 88.841l-.955.461-2.131-1.256.732-.668z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M726.618 87.378l-.159-1.034.874-.079 2.179.89-.54 1.686z'
                                        />
                                        <path
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                            d='M729.512 87.155l1.241-.429-2.02-.843-1.4.382zm1.241-.429l-.286.588.747.032-.366.286.016.971-1.177.365-.254.78-.636-.827.175-.08.54-1.686z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M729.433 89.748l-1.225.222-.604-.906.413.238.78-.381z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M727.604 89.064l.413.238.955-.461.54-1.686 1.241-.429-.286.588.27.016-.286.795-.366.175-.541-.254.159.604-.557.35-.031.366-.636.35-.637-.286z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M730.753 86.726l-2.02-.843-1.4.382-.874.079.111-.254 1.956-.795z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M730.753 86.726l-2.227-1.431.7.031z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M726.57 86.09l-.684 1.956-.27.843-.366-1.972z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M725.886 88.046l.573-1.702.159 1.034zm0 0l-.27.843 2.417.811-.429-.636z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M725.886 88.046l1.718 1.018.429.636-.716-.048-.731-.429-.875-.62z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M728.526 85.295l-2.831.683-.111.732z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M728.526 85.295l-1.177-.366-1.654 1.049zm-12.66 1.638l-.54-1.098-1.098-.89-.652.477 1.718 1.813z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M713.576 85.422l.652-.477-1.876.095-1.4.477-.318 1.893 1.877-1.988.652.334.047-.255.191.064z'
                                        />
                                        <path
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                            d='M714.769 87.298l.445.939-1.654 1.892-.715-.747z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M712.845 89.382l-1.209.064.016-.827 1.24-1.973 1.877.652z'
                                        />
                                        <path
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                            d='M712.892 86.646l-.381-1.224-1.161 1.845.302 1.352z'
                                        />
                                        <path
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                            d='M712.511 85.422l.652.334.111-.7.302.366 1.114.063.302 1.13.874.318-1.017.524-.08-.159-1.877-.652z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M715.866 86.933l.144 1.177-1.114.477.318-.35-.365-.78z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M714.896 88.587l.318-.35-.445-.939-1.877-.652-.381-1.224.652.334.047-.255.891.334.175.366-.35.493.716-.111.334.541.413.063.35.636-.397.573z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M712.511 85.422l-1.161 1.845.302 1.352-.016.827-.27-.112-.732-1.924zm0 0l-1.877 1.988.111-.652z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M711.366 89.334l2.194.795.939.319-2.306.19z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M713.56 90.129l-1.924-.683 1.209-.064zm0 0l.939.319 1.177-2.195-.78.334z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M713.56 90.129l1.336-1.542.78-.334-.128.668-.572.636-.811.779z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M710.634 87.41l.509 2.719.827.159z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M710.634 87.41l-.525 1.081 1.034 1.638zm15.856.397l-.54-1.097-1.098-.875-.652.477 1.718 1.798z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M724.2 86.312l.652-.477-1.876.08-1.4.477-.318 1.893 1.877-1.973.652.318.047-.254.191.08z'
                                        />
                                        <path
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                            d='M725.393 88.173l.445.939-1.654 1.892-.715-.747z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M723.469 90.257l-1.193.079v-.843l1.24-1.972 1.877.652z'
                                        />
                                        <path
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                            d='M723.516 87.521l-.381-1.209-1.161 1.829.302 1.352zm-.381-1.209l.652.318.111-.699.302.381 1.114.048.302 1.145.874.302-1.017.541-.08-.175-1.877-.652z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M726.49 87.807l.144 1.177-1.114.477.318-.349-.365-.764z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M725.52 89.461l.318-.349-.445-.939-1.877-.652-.381-1.209.652.318.047-.254.891.334.175.366-.35.493.716-.112.334.557.413.064.35.62-.397.573z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M723.135 86.312l-1.161 1.829.302 1.352v.843l-.286-.127-.732-1.924zm0 0l-1.877 1.973.111-.637z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M721.99 90.209l2.194.795.939.334-2.306.175z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M724.184 91.004l-1.908-.668 1.193-.079zm0 0l.939.334 1.177-2.211-.78.334z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M724.184 91.004l1.336-1.543.78-.334-.128.668-.572.653-.811.763z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M721.258 88.285l.525 2.735.811.159z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M721.258 88.285l-.525 1.081 1.05 1.654z'
                                        />
                                        <path
                                            fill='#ACB0B4'
                                            fillRule='nonzero'
                                            d='M728.542 109.835l2.672-19.419.127-.78h-20.962l.128.78 2.672 19.419z'
                                        />
                                        <path
                                            fill='#818487'
                                            fillRule='nonzero'
                                            d='M718.443 107.21a.604.604 0 0 1-.573.653.588.588 0 0 1-.588-.62l-.398-15.014a.604.604 0 0 1 .573-.652.588.588 0 0 1 .588.62l.398 15.014zm-3.086-.063a.636.636 0 0 1-.54.7.62.62 0 0 1-.62-.589l-1.098-14.982a.62.62 0 0 1 .525-.683.62.62 0 0 1 .636.588l1.097 14.966zm5.519-19.022h-11.467v1.511h22.902v-1.511zm2.402 19.085a.62.62 0 0 0 .572.653.588.588 0 0 0 .589-.62l.397-15.014a.604.604 0 0 0-.572-.652.588.588 0 0 0-.589.62l-.397 15.014zm3.085-.063c-.038.34.202.65.541.7a.604.604 0 0 0 .62-.589l1.098-14.982a.62.62 0 0 0-.525-.683.62.62 0 0 0-.636.588l-1.098 14.966zm-5.471-15.587h-.063a.62.62 0 0 0-.589.653v15.013a.62.62 0 0 0 .589.637h.063a.62.62 0 0 0 .589-.637V92.213a.62.62 0 0 0-.589-.652z'
                                        />
                                        <g transform='translate(718.872 42.146)'>
                                            <mask
                                                id='prefix__einstieg-leicht-gemacht-o'
                                                fill='#fff'
                                            >
                                                <use xlinkHref='#prefix__einstieg-leicht-gemacht-n'/>
                                            </mask>
                                            <g
                                                fillRule='nonzero'
                                                mask='url(#prefix__einstieg-leicht-gemacht-o)'
                                            >
                                                <path
                                                    fill='#044D6B'
                                                    d='M20.246 124.88c-.254.366 31.65 4.93 37.47-.254.223-5.265.398-14.632.7-24.954.239-7.793.541-16.127 1.002-23.666.716-11.578.827-22.774 2.545-25.7-3.038-6.076-28.04-13.424-44.373-1.782-.636 12.787 1.511 23.045 1.511 23.045.636 1.718 1.24 12.962 1.575 24.7.381 13.39.365 27.434-.43 28.611'
                                                />
                                                <path
                                                    fill='#F79470'
                                                    d='M48.476 44.961s-4.453-1.145-3.8-13.677c-4.55-3.324-9.305-.668-9.305-.668s2.672 10.067-5.217 13.296c-.095 6.838 7.698 6.457 7.698 6.457s6.632.763 10.624-5.408'
                                                />
                                                <path
                                                    fill='#000'
                                                    d='M44.675 31.76c-4.549-3.323-9.304-.667-9.304-.667s1.352 4.564-.557 8.525a46.377 46.377 0 0 0 9.861-2.227 50.75 50.75 0 0 1 0-5.63'
                                                    opacity='.1'
                                                />
                                                <path
                                                    fill='#044D6B'
                                                    d='M17.208 48.651S9.813 57.59 7.602 88.666c-.684 9.654-.859 21.455-.175 35.785 2.943-1.972 7.316-1.098 10.29.922.185.13.36.274.525.43.175-5.28.97-19.928 1.988-33.845.939-12.723 2.052-24.826 3.054-28.611 2.147-8.112-6.076-14.696-6.076-14.696m43.705 0s7.396 8.938 9.59 40.015c.684 9.654.875 21.455.176 35.785-2.943-1.972-7.316-1.098-10.275.922-.185.13-.36.274-.524.43-.191-5.28-.97-19.928-1.989-33.845-.938-12.723-2.051-24.826-3.069-28.611-2.147-8.112 6.091-14.696 6.091-14.696'
                                                />
                                                <path
                                                    fill='#CCC'
                                                    d='M43.164 53.216l-3.165 5.694-5.566.127-1.781-7.094-1.002-2.147 13.025.637z'
                                                />
                                                <path
                                                    className='fill-primary'
                                                    d='M35.18 47.267h5.71l1.384 3.802-3.165 3.165s-3.293-.51-3.674-.51c-.382 0-2.275-3.164-2.275-3.164l2.02-3.293z'
                                                />
                                                <path
                                                    className='fill-primary'
                                                    d='M39.109 54.234l.89 4.676-3.42 6.075-2.146-5.948 1.256-5.821z'
                                                />
                                                <path
                                                    fill='#FFF'
                                                    d='M35.18 47.267l-2.529 4.676-3.07-8.604 2.005-1.018s.684 3.165 3.594 4.946m5.71-.508s.493 5.693 2.274 6.457c1.638-2.275 6.585-9.432 6.585-9.432l-2.784-1.145s-3.292 4.12-6.075 4.12'
                                                />
                                                <path
                                                    fill='#F79470'
                                                    d='M46.759 21.375s1.844-4.882.429-9.129C45.772 8 33.86 8.572 31.92 10.8c-1.94 2.227-.859 20.93-.016 23.172.843 2.243 8.747 5.122 11.785 1.718 2.688-2.354 4.596-7.045 3.07-14.314'
                                                />
                                                <path
                                                    fill='#4A263D'
                                                    d='M45.168 22.648s.97-6.982.302-9.702c-6.695 1.48-16.19-1.002-17.113-2.783-.922-1.781-1.781-4.103-.556-6.998 2.051 1.622 15.077 3.992 19.53 3.626 3.96-.43 2.481 5.71 2.481 5.71s3.467 3.371 2.815 6.25c-2.322 2.37-3.833 7.745-3.833 7.745l-3.626-3.848zm-9.177-3.833a.19.19 0 0 0-.08.08.684.684 0 0 1-.063.11l-.095.223a8.78 8.78 0 0 0-.175.461c-.048.144-.207.62-.302.939a25.25 25.25 0 0 0-.493 1.892c-.144.637-.287 1.257-.398 1.893-.111.636-.239 1.288-.334 1.924l-.032.223.207.048.747.175.764.159.763.159.764.127-.716-.302-.732-.27-.731-.255-.732-.254.175.286c.127-.636.27-1.272.398-1.909.127-.636.254-1.272.366-1.908.11-.636.238-1.272.334-1.909l.159-.954.08-.477.047-.239a.382.382 0 0 1 .032-.127c.016-.032.016-.08.047-.095m.35 11.865c.459.105.93.147 1.4.127a6.87 6.87 0 0 0 1.4-.175c.46-.118.904-.295 1.32-.525.42-.23.78-.558 1.05-.954a7.714 7.714 0 0 1-1.241.54c-.417.151-.842.279-1.273.382-.43.111-.859.19-1.304.286-.445.096-.89.191-1.352.318'
                                                />
                                                <path
                                                    fill='#4A263D'
                                                    d='M38.806 19.546l.048-.031.064-.048.127-.096.27-.159c.19-.09.387-.165.589-.222a3.42 3.42 0 0 1 1.272-.143l.334.047.16.032.158.048c.109.022.215.054.318.095l.318.127.303.16.302.159.572.397-.509-.477-.286-.207a2.99 2.99 0 0 0-.286-.19l-.318-.191-.318-.144-.16-.063-.174-.064a2.242 2.242 0 0 0-.35-.095 3.77 3.77 0 0 0-1.463-.048 4.071 4.071 0 0 0-.732.175l-.35.143-.175.096-.08.047-.095.048.461.604zm-4.485-1.002l-.238-.127-.223-.08a2.592 2.592 0 0 0-.477-.11 2.847 2.847 0 0 0-1.781.254c-.143.063-.255.159-.382.222-.123.082-.24.172-.35.27l.382-.19a3.76 3.76 0 0 1 .413-.111c.264-.074.539-.1.811-.08.263.017.52.076.764.175l.35.175.159.111.127.096.445-.605z'
                                                />
                                                <path
                                                    fill='#3D1C3B'
                                                    d='M41.717 21.566a.89.89 0 0 1-.795.954.89.89 0 0 1-.939-.81.859.859 0 0 1 .796-.939.875.875 0 0 1 .938.795m-7.65-.254c.032.477-.286.906-.732.938a.859.859 0 0 1-.89-.811c-.032-.477.286-.907.731-.938a.859.859 0 0 1 .89.81'
                                                />
                                                <path
                                                    fill='#3D1C3B'
                                                    d='M31.856 20.866l.287-.08.286-.095c.183-.067.377-.105.572-.11.193-.017.386 0 .573.047.19.056.371.136.54.238a1.177 1.177 0 0 0-.492-.381 1.415 1.415 0 0 0-.637-.096 2.004 2.004 0 0 0-.604.16 1.51 1.51 0 0 0-.27.158l-.255.16m7.602.54c.186-.168.41-.288.653-.35.236-.074.484-.1.731-.08.251.023.5.06.748.112l.381.095.382.112a2.72 2.72 0 0 0-.366-.207l-.381-.175-.382-.111-.398-.048a1.622 1.622 0 0 0-.78.127 1.18 1.18 0 0 0-.588.525'
                                                />
                                                <path
                                                    fill='#F79470'
                                                    d='M46.425 28.294s5.137-.827 3.355-7.603c-2.051-2.449-4.15 1.845-4.15 1.845l.795 5.758z'
                                                />
                                                <path
                                                    fill='#08243B'
                                                    d='M34.305 126.518c.016-.047.016-.111.032-.159v-.509l.032-.684.048-1.351.063-2.704.096-5.423c.063-3.605.116-7.216.159-10.831l.175-10.831c.063-3.616.132-7.226.207-10.83.095-3.611.19-7.221.381-10.832.048-.89.096-1.797.16-2.687.047-.462.079-.907.127-1.352a8.55 8.55 0 0 1 .206-1.304c.013-.1.04-.196.08-.287l.175-.286.318-.604.636-1.193 1.272-2.386c1.718-3.18 3.436-6.346 5.265-9.463.922-1.558 1.86-3.101 2.847-4.596a42.448 42.448 0 0 1 1.574-2.195c.27-.35.557-.7.859-1.034.302-.334.302-.318.461-.461a3.18 3.18 0 0 1 .255-.223l.254-.19h-.159c.496.288.955.635 1.368 1.033.197.209.378.432.54.668.17.234.323.479.462.732.256.52.443 1.07.556 1.638a6.25 6.25 0 0 1 .112 1.718l.063-.096-7.427 5.233-.048.032v.08l.907 4.93.032-.096a60.805 60.805 0 0 0-2.72 1.75c-.89.604-1.75 1.272-2.576 1.956a22.25 22.25 0 0 0-2.338 2.242c-.365.4-.695.832-.986 1.288-.297.45-.532.936-.7 1.448.167-.51.408-.992.716-1.432a9.797 9.797 0 0 1 1.001-1.272 19.355 19.355 0 0 1 2.354-2.21 30.17 30.17 0 0 1 2.593-1.91 49.141 49.141 0 0 1 2.735-1.717l.048-.032v-.047l-.859-4.947-.048.112 7.491-5.17.048-.031v-.064a6.76 6.76 0 0 0-.111-1.797 6.425 6.425 0 0 0-1.034-2.497 6.091 6.091 0 0 0-.573-.7 6.918 6.918 0 0 0-1.431-1.113l-.095-.048-.08.048-.286.223c-.092.065-.177.14-.255.222-.176.153-.34.317-.493.493-.309.33-.6.675-.874 1.034a51.492 51.492 0 0 0-1.59 2.21 92.478 92.478 0 0 0-2.864 4.613c-1.845 3.117-3.578 6.282-5.296 9.463l-1.288 2.386-.636 1.193-.318.588-.175.302a2.516 2.516 0 0 0-.127.366c-.087.452-.151.909-.191 1.368-.048.445-.096.89-.128 1.352a154.01 154.01 0 0 0-.159 2.703c-.175 3.61-.27 7.221-.35 10.831a822.27 822.27 0 0 0-.174 10.847l-.096 10.83-.048 5.424v5.408l-.031 5.423-.032 2.704-.032 1.351v1.193c-.016.048-.016.112-.032.16'
                                                />
                                                <path
                                                    fill='#08243B'
                                                    d='M34.433 67.895a27.53 27.53 0 0 0-1.48-3.276 31.856 31.856 0 0 0-1.844-3.101l-.525-.748-.557-.716-.27-.35-.302-.35-.287-.35-.318-.333-.62-.652-.334-.302-.334-.303-.334-.318-.35-.27-.715-.557.047.143c.133-.497.298-.986.493-1.463.186-.472.399-.934.636-1.383.255-.462.525-.891.812-1.32.286-.422.605-.821.954-1.193l.143-.143-.175-.112-5.296-3.69.048.255c.35-.46.733-.897 1.145-1.304a12.74 12.74 0 0 1 1.352-1.098 8.43 8.43 0 0 1 1.526-.843l.398-.159.43-.127.206-.063.207-.048.43-.08-.176-.063c.253.402.466.828.637 1.272.19.461.35.922.524 1.384.334.922.637 1.845.939 2.783a331.89 331.89 0 0 1 1.75 5.646c.572 1.893 1.097 3.77 1.637 5.662l1.623 5.678c-.462-1.909-.97-3.817-1.464-5.726-.493-1.908-1.033-3.801-1.558-5.694a177.298 177.298 0 0 0-1.67-5.662 97.72 97.72 0 0 0-.939-2.815 21.796 21.796 0 0 0-.524-1.383 7.236 7.236 0 0 0-.668-1.368l-.064-.08h-.095l-.462.08-.222.048-.223.063-.43.128-.429.175a9.558 9.558 0 0 0-1.59.858c-.494.347-.962.73-1.4 1.145-.44.422-.844.88-1.209 1.368l-.095.16.143.095 5.328 3.658v-.255c-.36.388-.69.802-.986 1.24a17.31 17.31 0 0 0-.827 1.353c-.244.462-.457.94-.636 1.431-.195.493-.36.998-.493 1.51v.096l.08.048.715.54c.111.096.239.176.35.287l.334.286.334.302.334.303.636.636a25.208 25.208 0 0 1 2.258 2.767c.696.98 1.333 2 1.909 3.054.286.525.573 1.05.827 1.59.254.541.509 1.082.748 1.638m-17.32-17.892c1.702 8.111 2.863 19.165 3.8 31.331m39.682-31.522c-.064.016-.095.064-.143.112l-.127.143a3.7 3.7 0 0 0-.223.318c-.143.222-.27.445-.398.668-.127.222-.493.89-.715 1.352a44.67 44.67 0 0 0-1.225 2.83A41.892 41.892 0 0 0 56 61.105a35.975 35.975 0 0 0-.859 6.107 25.606 25.606 0 0 0 .366 6.14 31.522 31.522 0 0 1-.08-6.124c.096-1.018.207-2.02.366-3.037.16-1.018.35-2.004.573-2.99a57.876 57.876 0 0 1 1.701-5.885 62.02 62.02 0 0 1 1.114-2.847c.206-.461.413-.938.652-1.384.238-.445.222-.46.35-.683l.19-.334.112-.144c.048-.047.095-.11.143-.127'
                                                />
                                            </g>
                                        </g>
                                        <path
                                            fill='#DDBF9E'
                                            fillRule='nonzero'
                                            d='M674.786 124.705l-24.62 13.36h232.138l-26.401-13.36z'
                                        />
                                        <path
                                            fill='#F1E5D8'
                                            fillRule='nonzero'
                                            d='M650.166 138.065h232.139v3.481H650.166z'
                                        />
                                        <path
                                            fill='#333'
                                            fillRule='nonzero'
                                            d='M774.6 94.885h-56.173c-.641 0-1.161.52-1.161 1.16l.78 37.2a1.161 1.161 0 0 0 1.16 1.146h54.616a1.16 1.16 0 0 0 1.16-1.145l.78-37.2c0-.641-.52-1.161-1.161-1.161'
                                        />
                                        <circle
                                            cx='746.514'
                                            cy='114.638'
                                            r='3.499'
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M806.743 130.765c0 1.288-4.978 2.338-11.133 2.338-6.155 0-11.133-1.05-11.133-2.338s4.978-2.322 11.133-2.322c6.155 0 11.133 1.034 11.133 2.322z'
                                        />
                                        <path
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                            d='M807.03 130.558c0 1.082-5.042 1.956-11.276 1.956-6.235 0-11.277-.874-11.277-1.956 0-1.081 5.058-1.956 11.277-1.956 6.218 0 11.276.875 11.276 1.956z'
                                        />
                                        <path
                                            fill='#F0F0F0'
                                            fillRule='nonzero'
                                            d='M802.56 125.39c-.747.922-.986 1.876-.683 2.13a9.35 9.35 0 0 0 1.304-2.035.62.62 0 0 0-.62-.096z'
                                            style={{ MixBlendMode: 'multiply' }}
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M807.03 130.192c0 1.082-5.042 1.956-11.276 1.956-6.235 0-11.277-.874-11.277-1.956 0-1.081 5.058-1.956 11.277-1.956 6.218 0 11.276.875 11.276 1.956z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M787.07 120.061c-.35 10.783 7.109 11.42 8.684 11.42 1.844 0 8.73-1.034 8.683-11.531-.652.111-1.813.19-2.417.286-4.899.859-10.163.414-14.95-.175z'
                                        />
                                        <path
                                            fill='#F2F2F2'
                                            fillRule='nonzero'
                                            d='M802.863 120.125c-.064 1.16-1.495 7.348-3.801 8.986-4.58 3.228-9.511-.35-9.511-.35 2.21 2.497 5.264 2.72 6.203 2.72 1.844 0 8.73-1.034 8.683-11.531a26.26 26.26 0 0 1-1.574.175z'
                                            opacity='.6'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M804.437 119.934c0 .907-3.896 1.654-8.683 1.654-4.788 0-8.684-.747-8.684-1.654 0-.907 3.896-1.67 8.684-1.67 4.787 0 8.683.748 8.683 1.67z'
                                        />
                                        <path
                                            fill='#F0F0F0'
                                            fillRule='nonzero'
                                            d='M795.547 118.678c-3.133.047-6.616.508-8.207 1.542.525.811 3.992 1.368 8.414 1.368 4.087 0 7.522-.54 8.429-1.272a.302.302 0 0 0 .063-.191c-.031-.923-3.88-1.511-8.7-1.447z'
                                        />
                                        <path
                                            fill='#42210B'
                                            fillRule='nonzero'
                                            d='M787.563 120.332c.81.715 4.167 1.256 8.19 1.256 4.024 0 7.38-.54 8.207-1.256-.827-.732-4.183-1.273-8.206-1.273-4.024 0-7.38.541-8.191 1.273z'
                                        />
                                        <path
                                            fill='#301506'
                                            fillRule='nonzero'
                                            d='M803.96 120.332c-.62-.541-2.608-.97-5.248-1.161 4.85.556-4.374 2.687-11.086 1.208.907.7 4.2 1.209 8.128 1.209s7.38-.54 8.206-1.256z'
                                        />
                                        <path
                                            fill='#603813'
                                            fillRule='nonzero'
                                            d='M793.559 120.22c0 .207-1.018.366-2.259.366-1.24 0-2.258-.159-2.258-.366 0-.206 1.002-.381 2.258-.381 1.257 0 2.259.159 2.259.381zm1.113-.604c0 .08-.382.143-.859.143s-.843-.064-.843-.143c0-.08.382-.143.843-.143.461 0 .859.063.859.143z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M795.754 121.763c-4.231 0-8.716-.62-8.716-1.765s4.485-1.75 8.716-1.75c4.23 0 8.7.605 8.7 1.75s-4.486 1.765-8.7 1.765zm0-3.213c-5.424 0-8.414.86-8.414 1.448s2.99 1.463 8.414 1.463c5.423 0 8.397-.859 8.397-1.463 0-.605-2.99-1.448-8.397-1.448z'
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M795.483 111.505l-.095.08-.112.079a1.13 1.13 0 0 0-.11.143 2.179 2.179 0 0 0-.24.398c-.07.17-.128.346-.174.524 0 .096-.016.191-.016.27a.302.302 0 0 0 .016.128v.08-.032l.031.111v.08c.032.015.032.031.048.063l.127.096.191.11.366.16c.183.074.358.165.525.27h.08l.047.032.095.064.032.032h.032l.064.063.127.127c.169.159.313.341.43.541.13.225.217.474.254.732.026.12.036.243.032.366l-.048.365a1.972 1.972 0 0 1-.207.589 3.26 3.26 0 0 1-.588.843c-.194.2-.401.385-.62.556-.315.253-.644.487-.987.7l-.381.239a.668.668 0 0 1 .016-.112c.024-.118.056-.235.095-.35.138-.374.297-.74.477-1.097l.334-.668a4.25 4.25 0 0 0 .239-.668.875.875 0 0 0 0-.461c-.016-.032-.032-.048-.032-.08l-.048-.08a.843.843 0 0 0-.159-.19l-.048-.048h-.063l-.143-.127-.303-.19a4.12 4.12 0 0 1-.46-.287 2.21 2.21 0 0 1-.494-.461 1.527 1.527 0 0 1-.318-.668 1.161 1.161 0 0 1-.048-.35v-.048c0-.016.016-.016.016-.032v-.159h.016v-.111l.064-.286.047-.128c.072-.145.157-.284.255-.413a2.083 2.083 0 0 1 1.113-.78l.207-.031h.286l.032.016zm1.607-1.511a.064.064 0 0 0-.016.048v.095a1.288 1.288 0 0 0 .11.493c.017.016.017.032.017.064 0 .031.016.047.032.047h.031l.112.096a.239.239 0 0 1 .08.095l.047.064h.016v.032h.016v.031l.047.128a.954.954 0 0 1 .048.413c-.003.12-.024.237-.063.35-.062.182-.16.35-.287.493l-.27.27-.127.096a.127.127 0 0 0-.016-.048c0-.032-.016-.063-.016-.111a1.368 1.368 0 0 1-.032-.382v-.461a.716.716 0 0 0-.016-.19c0-.05-.01-.1-.032-.144h-.016v-.064h-.015c-.016-.016 0 .032 0 .032h.015v-.032a.827.827 0 0 1-.206-.35 1.225 1.225 0 0 1-.032-.238c0-.032.016-.048.016-.08 0-.022.005-.044.016-.063.01-.102.042-.2.095-.287a.875.875 0 0 1 .286-.334.207.207 0 0 1 .128-.047c.016-.016.031 0 .031-.016z'
                                            opacity='.2'
                                        />
                                        <path
                                            fill='#F0F0F0'
                                            fillRule='nonzero'
                                            d='M803.737 121.238c-.683.843-.063 1.988.493 1.686.095-.584.153-1.174.175-1.765-.159-.064-.397-.255-.668.08z'
                                            opacity='.5'
                                            style={{ MixBlendMode: 'multiply' }}
                                        />
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M803.213 127.982a4.04 4.04 0 0 1-.955-.255c-.254-.111.175-1.209.462-1.081.204.082.418.14.636.175a2.338 2.338 0 0 0 2.576-2.02 2.306 2.306 0 0 0-2.02-2.56h-.159c-.318-.16.016-1.194.303-1.162 1.892.302 3.26 1.972 3.037 3.88a3.499 3.499 0 0 1-3.88 3.023z'
                                        />
                                        <path
                                            fill='#F0F0F0'
                                            fillRule='nonzero'
                                            d='M803.356 127.33c2.29.54 4.612-4.438.652-5.074a2.306 2.306 0 0 1-.652 4.565 2.895 2.895 0 0 1-.636-.175c-.287-.128-.716.97-.462 1.081a4 4 0 0 0 .955.255h.46c-.476-.08-.953-.557-.317-.653z'
                                            opacity='.5'
                                        />
                                        <path
                                            fill='#333'
                                            fillRule='nonzero'
                                            d='M702.332 133.453l-3.07.715-1.256.859-25.479-2.099 2.688-2.275z'
                                        />
                                        <path
                                            fill='#4D4D4D'
                                            fillRule='nonzero'
                                            d='M700.089 116.705l.478.016 1.765 16.732-6.25-.509z'
                                        />
                                        <path
                                            fill='#999'
                                            fillRule='nonzero'
                                            d='M672.83 133.66l24.587 2.02a.509.509 0 0 0 .557-.446l2.577-18.306a.43.43 0 0 0-.398-.493l-23.395-1.924a.509.509 0 0 0-.54.397l-3.77 18.21a.43.43 0 0 0 .382.541z'
                                        />
                                        <path
                                            fill='#127891'
                                            fillRule='nonzero'
                                            d='M697.56 116.737a.461.461 0 0 0 .43.493.445.445 0 0 0 .493-.413.461.461 0 0 0-.413-.493.477.477 0 0 0-.51.413z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M698.356 114.16c.604.048 1.034.716.97 1.528-.025.231-.09.457-.19.668l-.828-.064a.907.907 0 0 0 .302-.652c.032-.445-.159-.764-.302-.764s-.397.255-.43.7c-.031.446.207.716.35.89a.318.318 0 0 1 .032.446.414.414 0 0 1-.381.064c-.493-.16-.78-.732-.716-1.463.064-.732.588-1.4 1.193-1.352z'
                                        />
                                        <path
                                            fill='#333'
                                            fillRule='nonzero'
                                            d='M692.805 116.34a.493.493 0 0 0 .414.509.461.461 0 0 0 .493-.43.445.445 0 0 0-.414-.493.461.461 0 0 0-.493.414z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M693.6 113.763c.605.048 1.018.732.955 1.543a1.718 1.718 0 0 1-.175.652l-.827-.064a.859.859 0 0 0 .286-.652c.048-.445-.143-.747-.302-.763-.16-.016-.382.254-.43.7.012.33.142.647.366.89a.302.302 0 0 1 .016.446.398.398 0 0 1-.382.063c-.493-.143-.763-.731-.7-1.463.064-.732.59-1.4 1.193-1.352z'
                                        />
                                        <path
                                            fill='#333'
                                            fillRule='nonzero'
                                            d='M688.05 115.958a.445.445 0 0 0 .413.493.461.461 0 0 0 .493-.43.477.477 0 0 0-.413-.492.461.461 0 0 0-.493.429z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M688.83 113.366c.604.063 1.033.731.97 1.542a2.964 2.964 0 0 1-.176.668l-.827-.08a.922.922 0 0 0 .287-.651c.031-.446-.16-.748-.303-.764-.143-.016-.397.255-.429.7-.032.445.207.732.35.89h.016c.126.12.133.318.016.446a.382.382 0 0 1-.382.064c-.493-.144-.78-.732-.716-1.448.064-.715.589-1.415 1.193-1.367z'
                                        />
                                        <path
                                            fill='#333'
                                            fillRule='nonzero'
                                            d='M683.279 115.56a.461.461 0 0 0 .429.493c.247.01.46-.169.493-.413a.461.461 0 0 0-.43-.493.445.445 0 0 0-.492.413z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M684.074 112.984c.604.048 1.034.716.954 1.527-.012.232-.072.46-.175.668l-.827-.064a.97.97 0 0 0 .302-.668c.032-.445-.159-.747-.318-.763-.159-.016-.381.254-.43.715.016.326.145.636.367.875a.302.302 0 0 1 .031.445.445.445 0 0 1-.397.08c-.477-.16-.764-.748-.7-1.463.064-.716.588-1.4 1.193-1.352z'
                                        />
                                        <path
                                            fill='#333'
                                            fillRule='nonzero'
                                            d='M678.523 115.163a.445.445 0 0 0 .414.493.461.461 0 1 0 .08-.907.445.445 0 0 0-.494.414z'
                                        />
                                        <path
                                            fill='#E6E6E6'
                                            fillRule='nonzero'
                                            d='M679.318 112.586c.605.048 1.018.716.955 1.527-.013.232-.072.46-.175.668l-.827-.064a.89.89 0 0 0 .286-.652c.048-.46-.159-.763-.302-.763s-.398.254-.43.7c-.031.445.207.715.366.89a.286.286 0 0 1 .016.43.414.414 0 0 1-.382.08c-.493-.16-.763-.732-.715-1.464.047-.732.604-1.4 1.208-1.352z'
                                        />
                                        <path
                                            fill='#35B9AB'
                                            fillRule='nonzero'
                                            d='M695.923 102.407s5.47 13.376 4.628 28.724l-3.118-2.784s-.493-18.624-1.51-25.94'
                                        />
                                        <path
                                            fill='#35B9AB'
                                            fillRule='nonzero'
                                            d='M703.938 102.137s2.688 14.187-1.208 29.057l-2.497-3.356s3.244-18.353 3.705-25.7'
                                        />
                                        <path
                                            fill='#027E71'
                                            fillRule='nonzero'
                                            d='M703.938 102.137c.027.083.043.168.048.255.127 6.632-.095 13.375-1.018 19.944-.318 2.258-.716 4.532-1.463 6.695a.032.032 0 0 1-.032.032c-.032 0-.048-.016-.032-.048 1.384-4.103 1.782-8.572 2.084-12.882.286-3.849.397-7.73.397-11.594 0-.652 0-1.289-.016-1.94.016-.16.032-.319.032-.462m-8.015.27l.079.191c1.161 6.76 2.243 13.567 2.974 20.39.191 1.829.398 3.673.35 5.502 0 .032-.016.048-.032.048a.048.048 0 0 1-.048-.048c.096-3.785-.572-7.65-1.097-11.387a386.664 386.664 0 0 0-1.829-12.055 51.272 51.272 0 0 0-.27-1.654c-.096-.541-.08-.668-.127-.987'
                                        />
                                        <path
                                            fill='#ACB0B4'
                                            fillRule='nonzero'
                                            d='M697.036 136.459h7.411l1.145-10.656h-10.083z'
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g transform={`${i18n.language === 'ru' ? 'translate(259.119 95.376)' : 'translate(240.119 95.376)'}`}>
                    <rect
                        width={i18n.language === 'ru' ? '40' : '59.124'}
                        height='23.65'
                        className='fill-secondary'
                        rx='3.379'
                    />
                    <text
                        fill='#FFF'
                        fontSize='8.446'
                    >
                        <tspan
                            x='7.602'
                            y='15.602'
                        >{t('SvgText:Quickstart1')}</tspan>
                    </text>
                </g>
                <g
                    fill='#AEAEAE'
                    fillRule='nonzero'
                >
                    <path d='M149.743 108.89a5.074 5.074 0 0 0-5.068 5.068 5.074 5.074 0 0 0 5.068 5.067 5.074 5.074 0 0 0 5.068-5.067 5.074 5.074 0 0 0-5.068-5.068zm0 9.502a4.44 4.44 0 0 1-4.434-4.434 4.44 4.44 0 0 1 4.434-4.435 4.44 4.44 0 0 1 4.434 4.435 4.44 4.44 0 0 1-4.434 4.434z'/>
                    <path d='M150 110.733h-.718v3.446l2.257 2.082.507-.468-2.047-1.888z'/>
                </g>
                <text
                    fill='#B6B7B6'
                    fontFamily='Roboto-Bold, Roboto'
                    fontSize='8.446'
                    fontWeight='bold'
                    transform='translate(7 5)'
                >
                    <tspan
                        x='152.034'
                        y='111.89'
                    >{t('SvgText:Quickstart2')}</tspan>
                </text>
                <text
                    fill='#5D5D5D'
                    fontFamily='Roboto-Bold, Roboto'
                    fontSize='8.446'
                    fontWeight='bold'
                    transform='translate(7 5)'
                >
                    <tspan
                        x='138'
                        y='99'
                    >{t('SvgText:Quickstart3')}</tspan>
                </text>
                <text
                    fill='#494949'
                    fontFamily='Roboto-Bold, Roboto'
                    fontSize='13.514'
                    fontWeight='bold'
                    transform='translate(7 5)'
                >
                    <tspan
                        x='138.52'
                        y='48.475'
                    >{t('SvgText:Quickstart4')}</tspan>
                </text>
                <path
                    fill='#05668E'
                    fillRule='nonzero'
                    d='M290.797 13.446a8.444 8.444 0 0 0-8.447 8.447 8.444 8.444 0 0 0 8.447 8.446 8.444 8.444 0 0 0 8.446-8.446 8.444 8.444 0 0 0-8.446-8.447zm.844 12.67h-1.689v-5.068h1.69v5.068zm0-6.757h-1.689v-1.69h1.69v1.69z'
                />
                <g transform='translate(144.675 13.446)'>
                    <rect
                        width='119.938'
                        height='16.893'
                        fill='#F6AF7E'
                        rx='8.446'
                    />
                    <text
                        fill='#FFF'
                        fontFamily='Roboto-Bold, Roboto'
                        fontSize='8.446'
                        fontWeight='bold'
                    >
                        <tspan
                            x='88.686'
                            y='11.379'
                        >{t('SvgText:Quickstart5')}</tspan>
                    </text>
                    <text
                        fill='#FFF'
                        fontFamily='Roboto-Bold, Roboto'
                        fontSize='8.446'
                        fontWeight='bold'
                    >
                        <tspan
                            x='21.116'
                            y='11.379'
                        >{t('SvgText:Quickstart6')}</tspan>
                    </text>
                    <path d='M78.973 3.379l-3.8 1.669v2.936c0 .95.412 2.222 1.608 3.31 1.409 1.283 2.192 1.375 2.192 1.375s.784-.092 2.194-1.375c1.195-1.088 1.607-2.36 1.607-3.31V5.048l-3.8-1.67z'/>
                    <use
                        fill='#FFF'
                        fillRule='nonzero'
                        stroke='#FFF'
                        strokeWidth='.5'
                        transform='translate(76.482 4.223)'
                        xlinkHref='#prefix__einstieg-leicht-gemacht-p'
                    />
                    <path
                        stroke='#FFF'
                        strokeWidth='.5'
                        d='M70.527 3.379v9.274'
                    />
                    <g filter='url(#prefix__einstieg-leicht-gemacht-q)'>
                        <g
                            fill='#FFF'
                            fillRule='nonzero'
                        >
                            <path d='M15.65 8.446h-1.509a.397.397 0 1 0 0 .795h.55l-1.905 1.906a.397.397 0 1 0 .562.562l1.905-1.906v.55a.397.397 0 1 0 .795 0v-1.51a.398.398 0 0 0-.398-.397z'/>
                            <path d='M16.563 5.068h-1.947a.337.337 0 0 0-.33.343v1.68H12.67a.337.337 0 0 0-.33.343v1.681h-1.617a.337.337 0 0 0-.33.343v1.68H8.776a.337.337 0 0 0-.33.344c0 .19.148.343.33.343h1.947a.324.324 0 0 0 .233-.1.35.35 0 0 0 .097-.243v-1.68h1.617a.324.324 0 0 0 .233-.101.35.35 0 0 0 .096-.243v-1.68h1.617a.324.324 0 0 0 .233-.101.35.35 0 0 0 .097-.243v-1.68h1.617c.182 0 .33-.154.33-.343a.337.337 0 0 0-.33-.343z'/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
  );
}
