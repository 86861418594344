
export const URL_MAIL_SUP = "mailto:support@sosafe.de";
export const SUPPORT_SITE = "https://support.sosafe.de/";

export function URL_SUPPORT_PAGE(lang) {
  return `${SUPPORT_SITE}${lang === "de" ? "de" : "en"}/support/home`;
}

export function URL_ACCOUNT_API() {
  return `${window.elearningRuntimeConfig.API_URL}auth/data`;
}
export function URL_ACTIVATE_API() {
  return `${window.elearningRuntimeConfig.API_URL}auth/activate`;
}
export function URL_AUTH_API() {
  return `${window.elearningRuntimeConfig.API_URL}auth`;
}
export function URL_DOWNLOADS_API() {
  return `${window.elearningRuntimeConfig.API_URL}elearning/materials`;
}
export function URL_ELEARNING_API() {
  return `${window.elearningRuntimeConfig.API_URL}elearning`;
}
export function URL_FEEDBACK_API() {
  return `${window.elearningRuntimeConfig.API_URL}feedback`;
}
export function URL_MODULEFEEDBACK_API() {
  return `${window.elearningRuntimeConfig.API_URL}elearning/feedback`;
}
export function URL_HINT_API() {
  return `${window.elearningRuntimeConfig.API_URL}elearning/hints`;
}
export function URL_NOTIFICATION_API() {
  return `${window.elearningRuntimeConfig.API_URL}elearning/notifications`;
}
export function URL_OPTIN_API() {
  return `${window.elearningRuntimeConfig.API_URL}auth/optin`;
}
export function URL_PASSWORD_API() {
  return `${window.elearningRuntimeConfig.API_URL}auth/password`;
}
export function URL_SURVEY_API() {
  return `${window.elearningRuntimeConfig.API_URL}survey`;
}
export function URL_SURVEY_HUB_API() {
  return `${window.elearningRuntimeConfig.SURVEY_HUB_API_URL}`;
}
export function URL_GAME_API() {
  return `${window.elearningRuntimeConfig.API_URL}game`;
}
export function URL_BRANDING_API() {
  return `${window.elearningRuntimeConfig.API_URL}customer/custom`;
}
export function URL_ANALYTICS_API() {
  return `${window.elearningRuntimeConfig.API_URL}tmstats/report`;
}
export function URL_RESOURCE_API() {
  return `${window.elearningRuntimeConfig.RESOURCE_URL}resource`;
}
export function URL_PL_USERS_API() {
  return `${window.elearningRuntimeConfig.API_URL}personalized-learning/users`;
}
