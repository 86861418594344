
// Packages
import React, { useState } from "react";
import PropTypes from "prop-types";

// Hooks
import { useTranslation } from "react-i18next";

// Components
import CustomButton from "shared/components/custom-button";
import OutsideClickAlerter from "shared/components/outside-click-alerter";
import SvgIcon from "shared/svgs/helper/svg-icon";
import { Close, ChevronDown } from "shared/svgs";

// Scss
import "./custom-dropdown.scss";

/**
 * Generates a custom dropdown
 *
 * @category shared
 * @subcategory components
 * @exports CustomDropdown
 * @component
 */
export default function CustomDropdown({
  className,
  handleClick,
  dropdownOptions,
  currentSelection,
  label,
  mobileText,
  styling,
}) {
  const [show, setShow] = useState(false);
  const { t } = useTranslation("translations");

  return (
    <div className={`position-relative ${className}`}>
      <OutsideClickAlerter onClickOutside={() => setShow(false)}>
        <div className="d-flex flex-row text-secondary desktop-btn">
          {label && label !== "" && <span className="align-self-center mr-1">{label}:</span>}
          <CustomButton
            className="align-self-center"
            onClick={() => setShow(!show)}
            variant={styling || "link"}
            label={
              <div className="d-flex justify-content-between">
                {currentSelection.value}
                <SvgIcon
                  Icon={ChevronDown}
                  size="12px"
                  className="ml-1 align-self-center stroke-secondary"
                />
              </div>
            }
            ariaLabel={
              `${mobileText}: ${currentSelection.value}` ||
              `${t("Please select an option")}: ${currentSelection.value}`
            }
            ariaHaspopup="listbox"
            ariaExpanded={show}
          />
        </div>
        {/* Desktop */}
        {show && (
          <div className="d-none d-md-flex select-menu-container shadow" role="listbox">
            {dropdownOptions.map((option) => (
              <div
                key={option.key}
                onClick={(e) => {
                  setShow(false);
                  handleClick(e, option.key);
                }}
                className="select-menu-item"
                role="option"
                aria-selected={currentSelection.key === option.key}
              >
                {option.value}
              </div>
            ))}
          </div>
        )}
        {/* Mobile */}
        {show && (
          <div
            className={`d-md-none d-flex select-mobile-menu-container shadow-top ${
              show ? "open" : ""
            }`}
            role="listbox"
          >
            <div className="d-flex flex-row justify-content-between">
              <h5 className="h4 my-auto">{mobileText || t("Please select an option")}</h5>
              <CustomButton
                label={<SvgIcon Icon={Close} size="24px" className="stroke-dark" />}
                variant="link"
                className="px-0"
                onClick={() => setShow(false)}
              />
            </div>
            <div className="select-scroll-container">
              {dropdownOptions.map((option) => (
                <div
                  key={option.key}
                  onClick={(e) => {
                    setShow(false);
                    handleClick(e, option.key);
                  }}
                  className="select-menu-item border-bottom"
                  role="option"
                  aria-selected={currentSelection.key === option.key}
                >
                  {option.value}
                </div>
              ))}
            </div>
          </div>
        )}
      </OutsideClickAlerter>
      <div className={`d-md-none dark-screen-overlay ${show ? " open" : ""}`} />
    </div>
  );
}

CustomDropdown.propTypes = {
  /** className string */
  className: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  dropdownOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentSelection: PropTypes.shape().isRequired,
  label: PropTypes.string,
  mobileText: PropTypes.string,
  styling: PropTypes.string,
};

CustomDropdown.defaultProps = {
  className: null,
  label: null,
  mobileText: null,
  styling: null,
};
