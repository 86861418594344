
import React from 'react';
import PropTypes from 'prop-types';

import './svg-icon.scss';

/**
 * renders an SVG-Icon object
 *
 * @category shared
 * @subcategory svgs
 * @exports SvgIcon
 * @component
 */
function SvgIcon({
  Icon, size, color, className, onClick, divergentHeight, setStroke, flipx, role, testid
}) {
  let classes = ['svg-icon', flipx ? 'flipx' : ''];
  classes = className ? classes.concat(className.split(' ')) : classes;

  let svgSize;
  switch (size) {
    case 'small':
      svgSize = '16px';
      break;
    case 'medium':
      svgSize = '32px';
      break;
    case 'large':
      svgSize = '64px';
      break;
    default:
      svgSize = size;
  }
  if (!svgSize) svgSize = '16px';

  let styles = {};
  if (color) {
    styles = {
      color,
    };
    if (setStroke) {
      styles.stroke = color;
    }
  }

  return (
    <Icon
      height={divergentHeight || svgSize}
      width={svgSize}
      className={classes.join(' ')}
      style={styles}
      onClick={onClick}
      preserveAspectRatio='xMidYMid meet'
      role={role}
      data-testid={testid}
    />
  );
}

export default SvgIcon;

SvgIcon.propTypes = {
  /** A valid SVG shape, e.g. from shared/svgs */
  Icon: PropTypes.shape().isRequired,
  /** named size - one of 'small','medium','large' */
  size: PropTypes.string,
  /** icon-color; stroke color as well, if setStroke is true */
  color: PropTypes.string,
  /** className */
  className: PropTypes.string,
  /** onClick */
  onClick: PropTypes.func,
  /** custom height, if icon should not be square. this should be used together with the size attribute */
  divergentHeight: PropTypes.string,
  /** switch stroke-coloring on or off (default=on) */
  setStroke: PropTypes.bool,
  /** flips icon on Y-axis */
  flipx: PropTypes.bool,
  /** aria-role */
  role: PropTypes.string,
  /** data-testid */
  testid: PropTypes.string,
};

SvgIcon.defaultProps = {
  size: null,
  color: null,
  className: null,
  onClick: undefined,
  divergentHeight: null,
  setStroke: true,
  flipx: false,
  role: null,
  testid: 'svg-icon',
};
