
// Packages
import { Tooltip } from '@sosafe-platform-engineering/fe-lib-ui-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkIsIE } from 'shared/helpers';

// Scss
import './progress-bar.scss';

/**
 * Renders a progress bar for the given level
 *
 * @category shared
 * @subcategory components
 * @exports Progressbar
 * @component
 */
function Progressbar(props) {
  const {
    className, progress, label, labelPosition, animate, startProgress, delay, tooltip, maxValue, currentLevelXp, labelledBy
  } = props;
  const { i18n, t } = useTranslation('translations');

  const [currVal, setCurrVal] = useState(startProgress || 0);

  useEffect(() => {
    // Dont animate on IE
    if (checkIsIE()) {
      setCurrVal(progress);
      return () => { };
    }

    const delayTimer = setTimeout(() => {
      setCurrVal(progress);
    }, delay * 1000);
    return () => {
      if (delayTimer) {
        clearTimeout(delayTimer);
      }
    };
  }, [progress]);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`progress-bar ${i18n.language === 'ar' ? 'rotate180' : ''} ${animate && !checkIsIE() ? 'animate' : ''} ${className}`}
      role="progressbar"
      aria-labelledby={labelledBy}
      aria-valuenow={progress}
      aria-valuemin={0}
      aria-valuemax={maxValue}
      aria-valuetext={`${progress}% - ${currentLevelXp} ${t("out of")} ${maxValue} ${t("Experience points")}`}
    >
      <div
        className='progress-bar-indicator'
        style={{
          transform: `scaleX(${currVal / 100})`,
        }}
      />
      {tooltip && (
        <Tooltip
          content={`${progress} %`}
          position='top'
        >
          <div
            style={{
              width: 0,
              height: 0,
              position: 'absolute',
              top: 0,
              left: `${Math.max(5, Math.min(currVal, 95)) || 0}%`,
            }}
          />
        </Tooltip>
      )}
      {label && (
        <div
          className={`progress-label ${labelPosition}`}
          style={{
            transform: `translate(${currVal || 0}%, 0)`,
          }}
        >
          <small className='text-muted text-right px-1'>{label}</small>
        </div>
      )}
    </div>
  );
}

Progressbar.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.number,
  animate: PropTypes.bool,
  label: PropTypes.string,
  labelPosition: PropTypes.string,
  startProgress: PropTypes.number,
  currentLevelXp: PropTypes.number,
  delay: PropTypes.number,
  tooltip: PropTypes.bool,
  maxValue: PropTypes.number,
  labelledBy: PropTypes.string,
};

Progressbar.defaultProps = {
  className: '',
  progress: 0,
  animate: false,
  label: null,
  labelPosition: '',
  startProgress: 0,
  delay: null,
  tooltip: false,
  maxValue: 100
};

export default React.memo(Progressbar, (prevProps, nextProps) => prevProps.progress === nextProps.progress);
