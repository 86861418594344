// Packages
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  MutableRefObject,
} from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  Button,
  Text,
  Title,
  Card,
  Tooltip,
  ActionIcon,
} from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import { useMutation } from '@tanstack/react-query';
import Helmet from 'shared/components/helmet';

// Services
import { dispatchDeletePlayerData } from 'elearning/services';
import { ProductAnalytics } from 'shared/modules/product-analytics';

// Hooks
import { useClearLoginData } from 'authentication/hooks/use-clear-login-data';
import useDemoPopup from 'elearning/hooks/use-demo-popup';
import { useIsPersonalizedLearning } from 'flamingo-e-learning-platform/hooks/use-is-personalized-learning';
import usePlatformSettings from 'shared/hooks/use-platform-settings';
import { NotificationType } from 'shared/modules/notification-system/types';
import { useNotificationSystemContext } from 'shared/modules/notification-system';
import {
  queryClient,
  queryKey,
  useSoSafeConnect,
  UpdateElearning,
} from 'shared/modules/sosafe-connect';
import { MODULE_STATES } from 'shared/utilities/module-states';
import { ReferenceAction } from 'shared/modules/sosafe-connect/handle-reference';
import { useCategoriesRequest } from 'elearning/hooks/use-categories-request';
import useUser from 'shared/hooks/use-user';
import { useModalContext } from 'modal-context/modal-context';
import { UseReferenceHandler } from 'shared/utilities/use-reference-handler';
import {
  checkIsActivePresenter,
  checkShowModuleFeedback,
  getTopics,
  MODULE_OPENED_FROM,
  MODULE_START_TYPE,
  scormGetJSON,
  scormCallbackAction,
  scormStart,
  TRACKED_EVENTS,
} from 'elearning/helpers';
import ActionTypes from 'shared/utilities/action-types';
import Loading from 'shared/components/loading';
import { IconX } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react/icons';
import getModuleByKeyFromCategories from './helpers/get-module-by-category-key';
import { CMIElement } from './utils/scorm-types';
import UseShowDecisionAlert from './hooks/use-decision-alert';
import {
  createNewProgressFromModuleFinished,
  getLessonStatusBy,
} from './utils/scorm-services';
import getCategoryByCategoryKey from './helpers/get-category-by-key';
import useFullscreen from './hooks/use-fullscreen';
import { MutationResponse, ApiError, HistoryState, ALERT_TYPE } from './types';
import './lesson-player-page.scss';

function getInitialModuleData(topics, topicsDefault, moduleIdInt) {
  const moduleData =
    topics
      ?.flatMap((topic) => topic.modules)
      ?.filter((module) => module.id === moduleIdInt)[0] ||
    topicsDefault
      ?.flatMap((topic) => topic.modules)
      ?.filter((module) => module.id === moduleIdInt)[0] ||
    {};
  return moduleData;
}

const MySwal = withReactContent(Swal);

let timer;
let demoDelayTimer;

export default function LessonPlayerPage(): JSX.Element {
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const { t } = useTranslation(['translations', 'helmetTranslations']);
  const { categoryKey, moduleKey } = useParams<{
    categoryKey: string;
    moduleKey: string;
  }>();
  const { settings } = usePlatformSettings();
  const { step_activation } = settings || {};
  const history = useHistory();
  const match = useRouteMatch();
  const clearLoginData = useClearLoginData();
  const { setNotifications, clearNotificationQueue } =
    useNotificationSystemContext();
  const { refetch: refetchCategories } = useCategoriesRequest(false);
  const { user, loading: loadingUser } = useUser({
    enabled: false,
    id: 'playerPage',
  });
  const { show } = UseShowDecisionAlert();
  const { close } = useModalContext();
  const checkReference = UseReferenceHandler();

  const { categories, player_data, loadingPlayerPage } = useSelector(
    (state: { modules: any }) => state.modules,
    shallowEqual
  );

  const { language } = user || {};

  const dispatch = useDispatch();
  const isPersonalizedLearning = useIsPersonalizedLearning();
  const [isLoading, setIsLoading] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(
    getCategoryByCategoryKey(categories, categoryKey, language || 'en')
  );
  const [topics, setTopics] = useState(
    currentCategory
      ? getTopics(currentCategory.modules, step_activation, user)
      : []
  );

  const currentDefaultCategory = getCategoryByCategoryKey(
    categories,
    categoryKey,
    'en'
  );
  const topicsDefault = currentDefaultCategory
    ? getTopics(currentDefaultCategory.modules, step_activation, user)
    : [];
  const [activeModuleData, setActiveModuleData] = useState(
    getInitialModuleData(
      topics,
      topicsDefault,
      getModuleByKeyFromCategories(
        categories,
        moduleKey,
        categoryKey,
        language || 'en'
      )?.id
    )
  );

  const lastLessonStatus = useRef('incomplete');
  const { endPoints } = useSoSafeConnect();
  const updateElearningMutation = useMutation<
    MutationResponse,
    ApiError,
    UpdateElearning
  >((data: UpdateElearning) => endPoints.post.updateElearning(data), {
    onSuccess: (response, data) => {
      const newLevel = response.data?.result.achievements?.level;
      const nextLevel = response.data?.result.achievements?.nextLevel;
      const newXp = response.data?.result.achievements?.xp ?? 0;
      if ((newLevel && nextLevel) || !!newXp) {
        queryClient.setQueryData([queryKey.AUTH], ({ data: authResponse }) => {
          const oldProgress = authResponse.result?.game.progress;

          const newProgress = createNewProgressFromModuleFinished({
            progress: oldProgress,
            newLevel: newLevel || oldProgress.level,
            nextLevel: nextLevel || oldProgress.nextLevel,
            newXp,
          });

          const newAuthResponse = {
            ...authResponse,
            result: {
              ...authResponse.result,
              game: {
                ...authResponse.result?.game,
                progress: {
                  ...oldProgress,
                  ...newProgress,
                },
              },
            },
          };
          return { data: { ...newAuthResponse }, status: 200 };
        });
        queryClient.invalidateQueries([queryKey.AUTH]);
      }

      const { result } = checkReference(response.data);

      const newActiveModuleData = {
        ...activeModuleData,
        progress: result.progress,
        slide: result.slide,
        point: result.point,
        score: result.score,
        suspend_data: result.suspend_data,
        status: result.status,
        lesson_status: getLessonStatusBy({
          lessonStatus: data.lesson_status,
        }),
      };

      dispatch({
        type: ActionTypes.MODULE_UPDATE_PROGRESS,
        payload: { module: newActiveModuleData },
      });
      setActiveModuleData(newActiveModuleData);
      setIsLoading(false);
    },
    onError: (error: ApiError) => {
      if (
        error?.response?.data?.reference &&
        ReferenceAction.shouldLogout(error.response.data.reference)
      ) {
        checkReference(error.response.data, dispatch);
        clearLoginData(true);
      }
      setIsLoading(false);
    },
  });

  useEffect(() => {
    const nextCategory = getCategoryByCategoryKey(
      categories,
      categoryKey,
      user?.language
    );
    if (!isEqual(currentCategory, nextCategory)) {
      setCurrentCategory(nextCategory);
      setTopics(
        nextCategory
          ? getTopics(nextCategory.modules, step_activation, user)
          : []
      );
    }
  }, [categories?.length, categoryKey]);

  useEffect(() => {
    const historyState = (history.location.state as HistoryState) || {};
    const isRestartAction =
      (history.location.state as HistoryState)?.action === 'RESTART';

    let startType = MODULE_START_TYPE.STARTED;
    if (
      isRestartAction ||
      activeModuleData?.statusOfModule?.includes?.(MODULE_STATES.PASSED)
    ) {
      startType = MODULE_START_TYPE.RESTARTED;
    } else if (
      activeModuleData?.statusOfModule?.includes?.(MODULE_STATES.PAUSED)
    ) {
      startType = MODULE_START_TYPE.CONTINUED;
    }

    if (activeModuleData?.uuid) {
      ProductAnalytics.getInstance().trackEvent(TRACKED_EVENTS.MODULE_OPENED, {
        startType,
        openedFrom: historyState.openedFrom || MODULE_OPENED_FROM.DIRECT_ACCESS,
        moduleUuid: activeModuleData.uuid,
        moduleName: activeModuleData.name,
        moduleGroup: activeModuleData.group,
        moduleLanguage: activeModuleData.language,
        moduleVersion: activeModuleData.version,
      });
    }
  }, [activeModuleData?.uuid]);

  useEffect(() => {
    const moduleIdInt = getModuleByKeyFromCategories(
      categories,
      moduleKey,
      categoryKey,
      language || 'en'
    )?.id;

    if (moduleIdInt !== activeModuleData?.id) {
      setActiveModuleData(
        getInitialModuleData(topics, topicsDefault, moduleIdInt)
      );
    } else if (!activeModuleData?.id) {
      refetchCategories();
    }
  }, [moduleKey, topics]);

  const wrapperRef = useRef(null);
  const { isFullscreen, isFullScreenAvailable, toggleFullscreen, setElement } =
    useFullscreen();

  const sendUpdateModuleProgressRequest = useCallback(
    debounce((data) => updateElearningMutation.mutate(data), 4000, {
      maxWait: 10000,
    }),
    [activeModuleData?.uuid]
  );

  const handleModuleCompletion = useCallback(
    (lessonStatus) => {
      if (lastLessonStatus.current !== lessonStatus) {
        lastLessonStatus.current = lessonStatus;
        setIsLoading(true);

        // This function will be called by scormGetRequestsFromSCO when the lesson_status changes from incomplete to passed/failed
        ProductAnalytics.getInstance().trackEvent(
          TRACKED_EVENTS.MODULE_COMPLETION_STATUS,
          {
            passed: lessonStatus === 'passed',
            moduleUuid: activeModuleData.uuid,
            moduleName: activeModuleData.name,
            moduleGroup: activeModuleData.group,
            moduleLanguage: activeModuleData.language,
            deadline: activeModuleData.finished_by,
            hasDeadline: !!activeModuleData.finished_by,
            mandatory: activeModuleData.mandatory,
            moduleVersion: activeModuleData.version,
          }
        );

        // invalidate elearning query to ensure fetching latest category information
        queryClient.invalidateQueries([queryKey.ELEARNING]);

        // If the module is completed then we can flush the debounce function
        // So we don't need to wait the 4sec debounce call
        sendUpdateModuleProgressRequest.flush();
      }
    },
    [activeModuleData?.uuid]
  );

  const redirectBack = useCallback(async () => {
    if (match.url === history.location.pathname) {
      if (!(history.location.state as HistoryState)?.openedFrom) {
        return history.push('/');
      }

      const { openedFrom } = history.location.state as HistoryState;

      switch (openedFrom) {
        case MODULE_OPENED_FROM.CATEGORY_CARD:
        case MODULE_OPENED_FROM.DIRECT_ACCESS:
        case MODULE_OPENED_FROM.QUICKSTART_CARD:
          history.push('/');
          break;
        case MODULE_OPENED_FROM.NEXT_MODULE_POP_UP:
        case MODULE_OPENED_FROM.MODULE_CARD:
          history.push(`/elearning/${categoryKey}`);
          break;
        case MODULE_OPENED_FROM.ACHIEVEMENT_PROGRESS_CARD:
          history.push('/achievements');
          break;
        default:
          history.push('/');
      }
    }
    queryClient.invalidateQueries([queryKey.ELEARNING]);
    await sendUpdateModuleProgressRequest.flush();
  }, [history.location.state, match.url]);

  useBeforeunload(async () => {
    await sendUpdateModuleProgressRequest.flush();
  });

  useEffect(() => {
    setElement(wrapperRef?.current);
  }, [wrapperRef]);

  const redirectHome = (): void => {
    close();
    redirectBack();
  };

  const showNoModuleAlert = () => {
    if (!MySwal.isVisible()) {
      show({
        title: <Title size="h2">{t('translations:Loading failed')}</Title>,
        content: (
          <Text>{t('translations:This module is not yet available.')}</Text>
        ),
        footer: (
          <>
            <Button
              variant="primary"
              aria-label={t('translations:Back to the home page')}
              onClick={() => redirectHome()}
            >
              {t('translations:Back to the home page')}
            </Button>
          </>
        ),
        onClose: redirectBack,
        type: ALERT_TYPE.ERROR,
      });
    }
  };

  useEffect(() => {
    const { group, scorm_entry_point, lesson_status } = activeModuleData;
    const moduleCompleted = lesson_status === 'passed';
    const moduleData = { moduleCompleted, group, scorm_entry_point };

    const showModuleFeedback = checkShowModuleFeedback({
      moduleData,
      moduleFeedback: (user?.game?.moduleFeedback || '') as string,
      moduleFeedbackGroups: user?.game?.moduleFeedbackGroups || [],
    });

    if (moduleCompleted) {
      if (showModuleFeedback && !isPersonalizedLearning) {
        const feedbackModal = {
          type: NotificationType.FEEDBACK,
          moduleGroup: group,
          displayOrder: 0,
        };
        setNotifications(feedbackModal);
      }
    }
  }, [
    activeModuleData.group,
    activeModuleData.scorm_entry_point,
    activeModuleData.lesson_status,
  ]);

  const { show: showDemoPopup, isDemo } = useDemoPopup();

  const doStart = async () => {
    if (isEmpty(activeModuleData)) {
      return;
    }

    if (!user) {
      return;
    }

    const theJSON = scormGetJSON({
      playerData: player_data,
      user,
      moduleData: activeModuleData,
    });
    try {
      window.API = new window.SCORMLMS(theJSON);
    } catch (error) {
      console.error('window.SCORMLMS is not a constructor');
    }
    if (window.API) {
      (window.API as any).setCallBack((type: any, elem: CMIElement) =>
        scormCallbackAction({
          type,
          cmiElement: elem,
          moduleData: activeModuleData,
          sendUpdateModuleProgressRequest,
          finishLoading: () => setIsLoading(false),
          handleModuleCompletion,
          redirectBack,
          isFullScreenAvailable,
        })
      );
    }
    setIsLoading(true);
    try {
      if (iFrameRef.current) {
        await scormStart(
          user.apikey,
          activeModuleData.id,
          iFrameRef as MutableRefObject<HTMLIFrameElement>
        );
      }
    } catch (e) {
      show({
        title: <Title size="h2">{t('translations:Loading failed')}</Title>,
        content: <></>,
        footer: (
          <Button
            variant="primary"
            aria-label={t('translations:Reload page')}
            onClick={() => window.location.reload()}
          >
            {t('translations:Reload page')}
          </Button>
        ),
        type: ALERT_TYPE.ERROR,
      });
    }

    dispatch(dispatchDeletePlayerData());

    demoDelayTimer = null;
    if (isDemo) {
      demoDelayTimer = setTimeout(() => {
        showDemoPopup();
      }, 2000);
    }
  };

  useEffect(() => {
    lastLessonStatus.current = 'incomplete';
    const fullScreenEvent = (event) => {
      const lmsUrl = window.elearningRuntimeConfig.LMS_URL;
      if (typeof lmsUrl === 'string') {
        if (
          lmsUrl.includes(event.origin) &&
          event.data.type === 'TOGGLE_FULLSCREEN'
        ) {
          if (wrapperRef?.current) {
            toggleFullscreen(wrapperRef.current);
          }
        }
      }
    };

    if (iFrameRef.current) {
      if (activeModuleData.id && !loadingUser) {
        if (!activeModuleData.active) {
          showNoModuleAlert();
        } else {
          doStart();
          window.addEventListener('message', fullScreenEvent);
        }
      }
    }

    return () => {
      window.removeEventListener('message', fullScreenEvent);
    };
  }, [activeModuleData.id, loadingUser]);

  useEffect(
    () => () => {
      clearTimeout(timer);
      clearTimeout(demoDelayTimer);
      // this dispatch is used, to fill the WelcomeCard with up to date information
      dispatch({
        type: ActionTypes.LEAVE_PLAYER_PAGE,
        payload: activeModuleData,
      });
      clearNotificationQueue();
    },
    []
  );

  const onQuit = () => {
    sendUpdateModuleProgressRequest.flush();
    setTimeout(() => {
      redirectBack();
    }, 100);
  };

  const isActivePresenter = checkIsActivePresenter(
    activeModuleData?.scorm_entry_point
  );
  const iframeWrapperClassNames = ['iframe-wrapper', 'shadow', 'w-100'];
  const isModuleVersion6 = activeModuleData.version === 6;

  const content = (
    <div
      className={iframeWrapperClassNames.join(' ')}
      id="wrapper"
      ref={wrapperRef}
    >
      <iframe
        id="el_frame"
        data-testid="elearning-module-iframe"
        className="player-iframe"
        ref={iFrameRef}
        title={t('translations:Open')}
      />
      {isLoading && (
        <div className="scorm-loader">
          <Card>
            <Loading />
            {/*
            The new loading text is only assigned to the
            aria-label because it may breaks the UI otherwise
            */}
            <p aria-label={t('translations:lesson_content_loading')}>
              {t('translations:Loading ...')}
            </p>
          </Card>
        </div>
      )}
      <div className={isModuleVersion6 ? 'close-button-v6' : 'close-button'}>
        <Tooltip label={t('translations:Close')}>
          <ActionIcon
            variant="transparent"
            aria-label={t('translations:Close')}
            color="black"
            bg="white"
            size="sm"
            p={8}
            radius="xl"
            onClick={() => onQuit()}
            className="close-button-icon"
          >
            <IconX height={16} width={16} />
          </ActionIcon>
        </Tooltip>
      </div>
    </div>
  );

  const divClassesArray = ['content-wrapper-v7', 'm-auto'];
  divClassesArray.push(isActivePresenter ? 'active-presenter' : 'storyline');
  if (isFullscreen) {
    divClassesArray.push('content-wrapper-fullscreen');
  }
  const divClasses = divClassesArray.join(' ');

  return (
    <React.Fragment>
      <Helmet
        title={`${t('helmetTranslations:Module')} ${activeModuleData.name || ''}`}
      />
      <div className={divClasses}>
        {loadingPlayerPage ? (
          <React.Fragment>
            <Loading className="mx-auto" />
          </React.Fragment>
        ) : (
          <React.Fragment>{content}</React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
