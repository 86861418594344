
// Packages
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Title } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
// Hooks
import { useTranslation } from 'react-i18next';
import { useModalContext } from 'modal-context/modal-context';

// Components
import RewardShieldImage from 'shared/components/reward-shield-image';
import { ModalBody } from 'flamingo-e-learning-platform/utilities/modal-alert/modal-body';
import PlaceholderReplacement from './placeholder-replacement';

// Scss
import './reward-modal.scss';
import { ALERT_TYPE } from 'shared/utilities/modal-alert';

export default function RewardNewBadgeModal(props) {
  const { t } = useTranslation('translations');
  const { badges } = props;
  const { close } = useModalContext();

  const history = useHistory();

  const [currBadgeIdx, setCurrBadgeIdx] = useState(0);

  const onNext = () => {
    if (currBadgeIdx >= badges?.length - 1) {
      props.onNext();
    } else {
      setCurrBadgeIdx(currBadgeIdx + 1);
    }
  };

  const onNextClick = () => {
    close();
    onNext();
  }

  const currentBadge = badges?.find((_, idx) => idx === currBadgeIdx);

  if (!currentBadge) return null;

  const modalProps = {
    title:  <Title className="mb-2" size="h2">{t('New badge received')}</Title>,
    content:<>
              <div className='position-relative d-flex badge-shield'>
                  <RewardShieldImage
                      image={currentBadge.image}
                      isBadge
                  />
              </div>
              <h4 className='mb-2 h1 text-secondary z-index-1'>{currentBadge.name}</h4>
              <span className='mb-3'>
                  <PlaceholderReplacement
                      stringInput={currentBadge.description_won}
                      history={history}
                  />
              </span>
            </>,
    footer: <Button variant='secondary' onClick = {() => onNextClick()}>{t('Great, let\'s go!')}</Button>,
    type: ALERT_TYPE.NONE
  }

  return (
        <ModalBody {...modalProps} />
  );
}

RewardNewBadgeModal.propTypes = {

};
