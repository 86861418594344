
// Packages
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ScrollArea } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";

// Components
import {
  ActivityBadgeSection,
  ActivityLevelSection,
  ActivityModuleSection,
  ActivityRegisterSection,
} from "gamification/components/activity-sections";
import { Point } from "shared/svgs";
import SvgIcon from "shared/svgs/helper/svg-icon";
import { getDaysFromActivities } from "gamification/helpers";
import { useGetActivities } from "shared/hooks/use-get-activities";
import {
  Card,
  Title,
  Text,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import Loading from "shared/components/loading";
import style from "./activities.module.css";

function ActivityDay(props: { children: any; date: any }) {
  const { children, date } = props;
  return (
    <div className={style.activityDay}>
      <SvgIcon
        Icon={Point}
        size="16px"
        className={style.activitiesDayDot}
        setStroke={false}
      />
      <Text span className="mt-1 mb-3">
        {date}
      </Text>
      {children}
    </div>
  );
}


interface ActivityCardProps {
  act: any;
  isLast: boolean;
}
const ActivityCard = ({
  act,
  isLast,
}: ActivityCardProps) =>(
    <div>
      {act.module && <ActivityModuleSection activity={act} isLast={isLast} />}
      {act.badge && <ActivityBadgeSection activity={act} isLast={isLast} />}
      {act.level && <ActivityLevelSection activity={act} isLast={isLast} />}
      {act.type === 17 && (
        <ActivityRegisterSection activity={act} isLast={isLast} />
      )}
    </div>
  );

ActivityCard.displayName = "ActivityCard";


export default function ActivitiesCard() {
  const { t } = useTranslation("flamingo");
  const { data: activitiesResponse } = useGetActivities();
  const activities = useMemo(() => activitiesResponse?.data.result || [], [activitiesResponse]);

  const days = useMemo(() => getDaysFromActivities(activities).sort((a, b) => {
    if (a.day === b.day) return 0;
    return new Date(a.isoTimestamp) > new Date(b.isoTimestamp) ? -1 : 1;
  }), [activities]);

  return (
    <Card withBorder radius={"lg"} className={style.activityCard}>
      <div className={style.activitiesBody}>
        <div className={style.title}>
          <Title order={2} size="h4">
            {t("activities")}
          </Title>
        </div>
        {days.length === 0 ? (
          <Loading className={style.loading} />
        ) : (
          <ScrollArea className={style.activityList} type="always" offsetScrollbars>
            <div className={style.activityListBorder}>
              {days.map((day, dayIdx) => (
                <ActivityDay date={day.day} key={dayIdx.toString()}>
                  {day.activities.map((act, idx) => {
                    const isLast = idx === day.activities.length - 1;
                    return (
                      <ActivityCard
                        key={idx}
                        act={act}
                        isLast={isLast}
                      />
                    );
                  })}
                </ActivityDay>
              ))}
            </div>
          </ScrollArea>
        )}
      </div>
    </Card>
  );
}
