import { useEffect, useState } from 'react';
import { getIdByReference } from 'shared/utilities/reference-handling';

interface UseReferenceProps {
  defaultReference: string | null;
}

interface UseReferenceReturn {
  showLoginNotActivated: boolean;
  showLoginNotExist: boolean;
  showActivateSuccess: boolean;
  showAccountNotExist: boolean;
  resetShowAccountNotExist: () => void;
}

export function useSinginReference({
  defaultReference,
}: UseReferenceProps): UseReferenceReturn {
  const [reference, setReference] = useState(defaultReference);
  const [status, setStatus] = useState({
    showLoginNotActivated: false,
    showLoginNotExist: false,
    showActivateSuccess: false,
    showAccountNotExist: false,
  });

  useEffect(() => {
    if (defaultReference !== reference) {
      const id = getIdByReference(defaultReference);
      const newStatus = {
        showLoginNotActivated: id === 'LOGIN_NOT_ACTIVATED',
        showLoginNotExist: id === 'LOGIN_ACTIVATE_NOT_EXIST',
        showActivateSuccess: id === 'LOGIN_ACTIVATE_SUCCESS',
        showAccountNotExist: id === 'LOGIN_ACCOUNT_NOT_EXIST',
      };

      setStatus(newStatus);
      setReference(reference);
    }
  }, [defaultReference, reference]);

  return {
    ...status,
    resetShowAccountNotExist: () =>
      setStatus((prev) => ({ ...prev, showAccountNotExist: false })),
  };
}

export default useSinginReference;
