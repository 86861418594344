
// Packages
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import isEmail from 'validator/lib/isEmail';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Services
import { dispatchClearAuthRef } from 'authentication/services';

// Hooks
import { useHistory } from 'react-router';

// Utilities
import Validators from 'shared/utilities/validators';
import { getIdByReference } from 'shared/utilities/reference-handling';
import { validateAccessCode } from 'authentication/helpers';
import { showToast, ALERT_TYPE } from 'shared/utilities/modal-alert';

// Components
import PasswordControl from 'shared/components/password-control';
import { useActivateAccount } from 'authentication/hooks/use-activate-account';
import { useModalContext } from 'modal-context/modal-context.tsx';
import { Button, Text, Title } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { UseShowDecisionAlert } from '../../flamingo-e-learning-platform/utilities/modal-alert/use-show-decision-alert';
import './signin-form.scss';

const useEffectOnlyOnce = (func) => useEffect(func, []);
let passwordInput = null;

function formIsValid(password, email) {
  const validations = [Validators.required(password).valid];
  return validations.every((item) => item) && (isEmail(email) || validateAccessCode(email));
}
export default function SignInForm(props) {
  const { t, i18n } = useTranslation([
    'translations',
    'messageTranslations',
    'placeholderTranslations',
  ]);
  const history = useHistory();
  const { mutate: activateAccount } = useActivateAccount();
  const { close } = useModalContext();
  const { show } = UseShowDecisionAlert();

  const translationClass = i18n.language === 'ar' ? 'arab' : '';

  const { reference, activateMail, loading, login_data } = useSelector(
    (state) => state.auth,
    shallowEqual
  );

  const dispatch = useDispatch();

  const sessionExpired = sessionStorage.getItem('sessionExpired');
  let inputMail = sessionExpired ?? '';
  if (props.data && props.data.mail) {
    inputMail = props.data.mail;
  }

  const [input, setInput] = useState(inputMail);
  const [password, setPassword] = useState('');

  useEffectOnlyOnce(() => {
    if (login_data && login_data.code) {
      setInput(login_data.code);
    }
  });

  const {
    setShowActivateSuccess,
    showLoginNotActivated,
    showLoginNotExist,
    showAccountNotExist,
    resetShowAccountNotExist,
  } = useReference(reference);

  useEffect(() => {
    if (showLoginNotActivated) {
      show({
        title:  <Title size="h2">{t('Error')}</Title>,
        content: <Text>
                    {t('messageTranslations:Account not activated! Please open the link sent to your email address to activate your account.')}.
                 </Text>,
        footer: <>
                  <Button variant='primary' aria-label={t('Send again')} onClick = {() => activateAccount({ email: input })}>{t('Send again')}</Button>
                  <Button variant='secondary' aria-label={t('Close')} onClick = {() => close()}>{t('Close')}</Button>
                </>,
        type: ALERT_TYPE.ERROR,
      })
    }
  }, [showLoginNotActivated, history, t, dispatch, input]);

  useEffect(() => {
    const closeRedirect = () => {
      close();
      history.push('/');
    }
    if (showLoginNotExist) {
      show({
        title:  <Title size="h2">{t('Error')}</Title>,
        content: <Text>
                    {t('messageTranslations:Account not found, link expired or already activated! Try to login.')}.
                 </Text>,
        footer: <>
                  <Button variant='secondary' aria-label={t('Close')} onClick = {() => closeRedirect()}>{t('Close')}</Button>
                </>,
        type: ALERT_TYPE.ERROR,
      })
    }
  }, [showLoginNotExist, history, t]);

  useEffect(() => {
    const handleAccountNotExist = (redirect) => {
      close();
      resetShowAccountNotExist();
      dispatch(dispatchClearAuthRef());

      if (redirect) {
        history.push(redirect);
      }
    }
    if (showAccountNotExist) {
      show({
        title:  <Title size="h2">{t('Error')}</Title>,
        content: <Text>
                    {t('Unfortunately this did not work out. Are you already registered?')}.
                 </Text>,
        footer: <>
                  <Button variant='secondary' aria-label={t('Close')} onClick = {() => handleAccountNotExist()}>{t('Close')}</Button>
                  <Button variant='primary' aria-label={t('Go to registration')} onClick = {() => handleAccountNotExist('/register')}>{t('Go to registration')}</Button>
                </>,
        type: ALERT_TYPE.ERROR,
      })
    }
  }, [showAccountNotExist]);

  useEffect(() => {
    if (setShowActivateSuccess) {
      if (activateMail && activateMail !== '') {
        setInput(activateMail);
        setTimeout(() => {
          // eslint-disable-next-line react/no-find-dom-node
          const node = ReactDOM.findDOMNode(passwordInput);
          if (node) {
            node.focus();
          }
        }, 100);
      }
    }
  }, [reference, activateMail, setShowActivateSuccess]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (formIsValid(password, input)) {
      let data = { password, code: input };
      let type = 'CODE';
      if (isEmail(input)) {
        data = { password, email: input };
        type = 'EMAIL';
      }
      props.doSignIn(type, data);
    } else {
      showToast(ALERT_TYPE.ERROR, `${t('An error has occured')} (Form invalid)`);
    }
  };

  return (
    <Form onSubmit={(e) => onSubmit(e)} className="u-flex u-flexCol" id='signin-form'>
      <Form.Group>
        <Form.Label htmlFor="userEmail">{t('Business email address or access code')}</Form.Label>
        <Form.Control
          id="userEmail"
          placeholder={t('placeholderTranslations:Email or code')}
          autoComplete="email"
          value={input}
          onChange={(e) => setInput(e.target.value?.trim())}
        />
      </Form.Group>
      <PasswordControl
        hideIndicator
        name="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        setRef={(e) => (passwordInput = e)}
        autoComplete="current-password"
      />
      <Button
        type="submit"
        disabled={!formIsValid(password, input)}
        data-testid="login-button"
        aria-label={t('Login')}
      >
        {loading ? t('Logging in...') : t('Login')}
      </Button>
    </Form>
  );
}

SignInForm.propTypes = {
  doSignIn: PropTypes.func.isRequired,
  data: PropTypes.shape({
    mail: PropTypes.string,
  }),
};

SignInForm.defaultProps = {
  data: null,
};

function useReference(propsReference) {
  const [reference, setReference] = useState(propsReference);
  const [showLoginNotActivated, setShowLoginNotActivated] = useState(false);
  const [showLoginNotExist, setShowLoginNotExist] = useState(false);
  const [showActivateSuccess, setShowActivateSuccess] = useState(false);
  const [showAccountNotExist, setShowAccountNotExist] = useState(false);

  useEffect(() => {
    if (propsReference !== reference) {
      switch (getIdByReference(propsReference)) {
        case 'LOGIN_NOT_ACTIVATED':
          setShowActivateSuccess(false);
          setShowLoginNotActivated(true);
          setShowLoginNotExist(false);
          setShowAccountNotExist(false);
          break;
        case 'LOGIN_ACTIVATE_NOT_EXIST':
          setShowActivateSuccess(false);
          setShowLoginNotActivated(false);
          setShowLoginNotExist(true);
          setShowAccountNotExist(false);
          break;
        case 'LOGIN_ACTIVATE_SUCCESS':
          setShowActivateSuccess(true);
          setShowLoginNotActivated(false);
          setShowLoginNotExist(false);
          setShowAccountNotExist(false);
          break;
        case 'LOGIN_ACCOUNT_NOT_EXIST':
          setShowActivateSuccess(false);
          setShowLoginNotActivated(false);
          setShowLoginNotExist(false);
          setShowAccountNotExist(true);
          break;
        default:
          setShowActivateSuccess(false);
          setShowLoginNotActivated(false);
          setShowLoginNotExist(false);
          setShowAccountNotExist(false);
          break;
      }
      setReference(reference);
    }
  }, [propsReference, reference]);

  return {
    showLoginNotActivated,
    showLoginNotExist,
    showActivateSuccess,
    showAccountNotExist,
    resetShowAccountNotExist: () => setShowAccountNotExist(false),
  };
}
