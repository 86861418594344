
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

export default function OrientationChange(props) {
  const [isEventListenerAdded, setisEventListenerAdded] = useState(false);
  const handleOrientationChange = () => {
    if (!isEventListenerAdded) {
      setisEventListenerAdded(true);
    }
    const orientation = window.innerWidth > window.innerHeight ? 90 : 0;

    props.updateParent({
      isPortrait: orientation === 0,
      isLandscape: orientation === 90,
    });
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && isMobile) {
      if (!isEventListenerAdded) {
        handleOrientationChange();
        window.addEventListener('load', () => handleOrientationChange(), false);
      } else {
        window.removeEventListener('load', () => handleOrientationChange(), false);
      }

      window.addEventListener('resize', () => handleOrientationChange(), false);
    }
    return () => {
      window.removeEventListener('resize', () => handleOrientationChange(), false);
    };
  });

  return (
        <React.Fragment />
  );
}

OrientationChange.propTypes = {
  updateParent: PropTypes.func.isRequired,
};
