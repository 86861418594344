
// Packages
import { shallowEqual, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';

// Utilities
import { UseReferenceHandler } from 'shared/utilities/use-reference-handler';
import { queryKey, useSoSafeConnect } from 'shared/modules/sosafe-connect';
import { useQuery } from '@tanstack/react-query';
import { useCategoriesRequest } from 'elearning/hooks/use-categories-request';

/**
 * Hook to handle notifications. Starts 5minutes interval to fetch new notifications.
 * Provides call to mark notifications as read.
 * Reloads categories if a notification object requests a reload.
 *
 * @param {*} parameterName parameter description
 * @returns {*} return value description
 *
 * @category shared
 * @subcategory hooks
 * @exports useNotifications
 */
export default function useNotifications() {
  const { moduleTimeStamp } = useSelector((state) => state.modules, shallowEqual);
  const { endPoints } = useSoSafeConnect();
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const { refetch: refetchCategories } = useCategoriesRequest(false);
  const checkReference = UseReferenceHandler();

  const { data: notificationResult } = useQuery([queryKey.NOTIFICATIONS], endPoints.fetch.getNotifications, {
    refetchInterval: 5 * 60000,
    refetchIntervalInBackground: true,
    staleTime: 5 * 60000,
    onSuccess: (response) => {
      if (response.data && response.data.result) {
        const { result } = response.data;
        const unread = result.filter((noti) => !noti.read);
        const notisWithReload = unread.filter((noti) => noti.reload);
        if (notisWithReload && notisWithReload.length) {
          const reloadCategories = !!notisWithReload.find((noti) => {
            const notiCreated = new Date(noti.created).getUTCMilliseconds();
            if (moduleTimeStamp < notiCreated) return true;
            return false;
          });
          if (reloadCategories) {
            refetchCategories();
          }
        }
        setNotifications(result);
      }
    },
  });

  const sendReadNotification = useCallback((id) => {
    endPoints.put.updateNotification({ id })
      .then((response) => {
        if (response.data && response.data.result) {
          const result = notifications.map((noti) => (noti.id === response.data.result.id ? response.data.result : noti));
          setNotifications(result);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          checkReference(error.response.data);
        } else {
          checkReference({ title: error.name, status: error.statusCode, reference: '8CCA1873-7195-4EB7-8BF4-32CCF473B353' });
        }
      });
  }, [endPoints]);

  return {
    notifications: notificationResult?.data.result ?? [],
    sendReadNotification,
    loading,
  };
}
