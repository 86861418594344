
// Packages
import React from 'react';
import PropTypes from 'prop-types';

export default function SafeScoreIndicator(props) {
  const { percent } = props;
  return (
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 533 595'
            xmlns='http://www.w3.org/2000/svg'
        >
            <defs>
                <path
                    id='scoreIndicator-shield'
                    d='M 266.5 44 L 458 129.5 L 458 281 C 458 330 436.5 395.5 376 451.5 C 312 511 273.5 521.5 266.5 523 C 259.5 521.5 221 511 157 451.5 C 96.5 395.5 75 330 75 281 L 75 281 L 75 129.5 L 266.5 44 Z'
                    strokeWidth='20'
                    strokeLinecap='round'
                    strokeMiterlimit='2'
                />
                <filter id='scoreIndicator-darken1'>
                    <feColorMatrix
                        type='matrix'
                        values='.8 0 0 0 0 0 .8 0 0 0 0 0 .8 0 0 0 0 0 1 0 '
                    />
                </filter>
                <filter id='scoreIndicator-darken2'>
                    <feColorMatrix
                        type='matrix'
                        values='.6 0 0 0 0 0 .6 0 0 0 0 0 .6 0 0 0 0 0 1 0 '
                    />
                </filter>
                <filter id='scoreIndicator-darken3'>
                    <feColorMatrix
                        type='matrix'
                        values='.5 0 0 0 0 0 .5 0 0 0 0 0 .5 0 0 0 0 0 1 0 '
                    />
                </filter>
                <filter
                    x='-7'
                    y='-7'
                    width='30'
                    height='30'
                    filterUnits='objectBoundingBox'
                    id='scoreIndicator-shadow'
                >
                    <feOffset
                        dx='3'
                        dy='3'
                        in='SourceAlpha'
                        result='scoreIndicator-shadowOffset'
                    />
                    <feGaussianBlur
                        stdDeviation='7'
                        in='scoreIndicator-shadowOffset'
                        result='scoreIndicator-shadowBlur'
                    />
                    <feColorMatrix
                        values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.85 0'
                        type='matrix'
                        in='scoreIndicator-shadowBlur'
                        result='scoreIndicator-shadowColorMatrix'
                    />
                    <feComponentTransfer result='opacityBlurOffset'>
                        <feFuncA
                            type='table'
                            tableValues='0 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12 .12'
                        />
                    </feComponentTransfer>
                    <feBlend
                        in='SourceGraphic'
                        in2='scoreIndicator-shadowColorMatrix'
                        mode='normal'
                    />
                </filter>
                <clipPath id='scoreIndicator-clip0'>
                    <rect
                        x='266.5'
                        width='266.5'
                        height='595'
                    />
                </clipPath>
            </defs>
            <g>
                <use
                    href='#scoreIndicator-shield'
                    className='fill-primary stroke-none'
                />
                <use
                    href='#scoreIndicator-shield'
                    className='fill-primary stroke-none'
                    clipPath='url(#scoreIndicator-clip0)'
                    filter='url(#scoreIndicator-darken1)'
                />
            </g>
            <rect
                className='scoreIndicator-mask fill-white'
                opacity='.85'
                x='75'
                y='44'
                width='383'
                height={479 - percent * 4.79}
            />
            <g>
                <use
                    href='#scoreIndicator-shield'
                    className='stroke-primary fill-none'
                    filter='url(#scoreIndicator-darken2)'
                />
                <use
                    href='#scoreIndicator-shield'
                    className='stroke-primary fill-none'
                    clipPath='url(#scoreIndicator-clip0)'
                    filter='url(#scoreIndicator-darken3)'
                />
            </g>
            <text
                id='indicator-txt'
                key='indicator-txt'
                fontFamily='-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
                className='stroke-button-text fill-button-text'
                filter='url(#scoreIndicator-shadow)'
                textAnchor='middle'
                fontSize='80'
                x='266.5'
                y='295'
            >
                {percent} %
            </text>
        </svg>
  );
}

SafeScoreIndicator.propTypes = {
  percent: PropTypes.number,
};

SafeScoreIndicator.defaultProps = {
  percent: null,
};
