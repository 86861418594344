/*
How to add a new Language?

Add folder with specific id.
Copy index.js from de into the new folder.
Add JSON files from translation package.

i18n.js ->
    add import
    add language to i18n.init({ resources: { } })

translationsTests.js ->
    add import
    add test (copy en case and replace en with new lang).

remove comment from // testLangFiles(); in i18n.js
npm start
check console and report errors
readd comment in front of testLangFiles();

Profit!
*/

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Ar from './ar';
import Bg from './bg';
import Ch from './ch';
import Cs from './cs';
import Da from './da';
import De from './de';
import El from './el';
import En from './en';
import Es from './es';
import Et from './et';
import Fi from './fi';
import Fr from './fr';
import Hu from './hu';
import Hr from './hr';
import It from './it';
import Ja from './ja';
import Nl from './nl';
import No from './no';
import Pl from './pl';
import Pt from './pt';
import Ro from './ro';
import Ru from './ru';
import Sk from './sk';
import Sl from './sl';
import Sr from './sr';
import Sv from './sv';
import Tr from './tr';
import Vi from './vi';
import Zh from './zh';
import Ko from './ko';
import Th from './th';
import Hi from './hi';
import Uk from './uk';

import debugTranslationsDe from './de/debug.translations.json';
import debugTranslationsEn from './en/debug.translations.json';

// import { testLangFiles } from './translationsTests';

const parseSoSafeLang = function (key: string) {
  if (key && key.length) {
    const langSplit = key.split('-').shift();
    return langSplit;
  }
  return 'en';
};

export const getDefaultLanguage = () => {
  const storageLanguage = localStorage.getItem('language') || undefined;
  return storageLanguage ?? parseSoSafeLang(window.navigator.language);
};

// testLangFiles();

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        ...En,
        debugTranslations: debugTranslationsEn,
      },
      de: {
        ...De,
        debugTranslations: debugTranslationsDe,
      },
      ar: Ar,
      bg: Bg,
      ch: Ch,
      cs: Cs,
      da: Da,
      el: El,
      es: Es,
      et: Et,
      fi: Fi,
      fr: Fr,
      hu: Hu,
      hr: Hr,
      it: It,
      ja: Ja,
      jp: Ja, // Since this key should be removed from BE, once codes are synced, take correct translated file.
      nl: Nl,
      no: No,
      nb: No,
      pl: Pl,
      pt: Pt,
      ro: Ro,
      ru: Ru,
      se: Sv, // Since this key should be removed from BE, once codes are synced, take correct translated file.
      sk: Sk,
      sl: Sl,
      sr: Sr,
      sv: Sv,
      tr: Tr,
      vi: Vi,
      zh: Zh,
      ko: Ko,
      th: Th,
      hi: Hi,
      uk: Uk,
    },
    debug: false,
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    lng: getDefaultLanguage(),
    fallbackLng: 'en', // use en if detected lng is not available
    ns: [
      'faqTranslations',
      'helmetTranslations',
      'languagesTranslations',
      'messageTranslations',
      'optInTranslations',
      'placeholderTranslations',
      'tourTranslations',
      'translations',
      'welcomeCardTranslations',
      'debugTranslations',
      'moduleFeedbackTranslations',
    ],
    defaultNS: 'translations',
    // keySeparator: false, // we do not use keys in form messages.welcome
    // nsSeparator: false,
    keySeparator: ':',
  });

export default i18n;
