import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
/**
* Hook to handle namespaces and translations
*
* @param {{ [langKey: string]: { [translationKey: string]: string }}} translations translations jsons
* @param {string} namespace i18n namespace
* @returns {*} return value description
*
* @category src\lang
* @subcategory src\lang
* @exports useInitTranslations
*/
export default function useInitTranslations(translations: Record<string, any>, namespace: string): { translationsReady: boolean } {
  const { i18n } = useTranslation();
  const [translationsReady, setTranslationsReady] = useState(i18n.hasResourceBundle(i18n.language, namespace));
  useEffect(() => {
    if (!i18n.hasResourceBundle(i18n.language, namespace)) {
      // All all ressources to get rid of strange bug...
      Object.keys(translations).forEach((langKey) => {
        i18n.addResourceBundle(langKey.toLowerCase(), namespace, translations[langKey], true, true);
      });
      i18n.loadNamespaces([namespace]).then(() => {
        setTranslationsReady(true);
      });
    }
  }, [i18n, translations, i18n.language]);
  return {
    translationsReady,
  };
}
