
interface TourMobileCheckProps {
  id: string,
  width?: number
}

/**
 * Converts tour id into corresponding mobile/desktop id depending on window innerwidth
 *
 * @param {string} id String
 * @param {string} width String
 * @returns {string} newTour String
 *
 * @category shared
 * @subcategory helpers
 * @exports tourMobileCheck
 */
export default function tourMobileCheck({ id, width }: TourMobileCheckProps) {
  let compWidth = width;
  if (!compWidth) {
    compWidth = window.innerWidth;
  }
  const mdBreakpoint = 768;
  let newTour = id;
  if (compWidth < mdBreakpoint) {
    if (!id?.includes('Mobile')) {
      newTour = `${id}Mobile`;
    }
  } else if (id?.includes('Mobile')) {
    newTour = id?.replace('Mobile', '');
  }
  return newTour;
}
