
import getStatusOfModule from 'elearning/helpers/get/get-status-of-module';
import { checkModuleUnlocked } from '../check';

/**
 *
 * @typedef {Object} getTopicsObject
 * @property {string} topic_name - topic_name
 * @property {string} topic_id - topic_id
 * @property {*} master - master
 * @property {*} modules - modules
 * @property {*} doneModules - doneModules
 * @property {boolean} active - active
 */

/**
 * Split modules into their categories
 *
 * @param {Object} modules modules
 * @param {boolean} step_activation
 * @returns {getTopicsObject} topic object
 *
 * @category elearning
 * @subcategory helpers
 * @exports getTopics
 */
export default function getTopics(modules, step_activation, user) {
  const topicsIds = ['master'];
  const unfilteredTopicIds = modules.map((module) => module.topic_id);
  unfilteredTopicIds.forEach((topicId) => {
    if (!topicsIds.includes(topicId)) topicsIds.push(topicId);
  });

  let topics = topicsIds.map((id) => {
    let matchingModules = [];
    if (id === 'master') {
      matchingModules = modules
        .filter((module) => module.master)
        .map((module) => ({ ...module, active: true, statusOfModule: getStatusOfModule(module) }))
        .sort((a, b) => a.stage - b.stage);
    } else {
      matchingModules = modules
        .filter((module) => module.topic_id === id && !module.master)
        .map((module) => ({ ...module, active: true, statusOfModule: getStatusOfModule(module) }))
        .sort((a, b) => a.stage - b.stage);
    }

    if (!matchingModules || !matchingModules.length) return null;

    return {
      topic_name: matchingModules[0].topic_name,
      topic_id: id,
      modules: matchingModules,
      completed: matchingModules.filter((mod) => mod.status === 3).length,
      total: matchingModules.length,
      active: true,
    };
  }).filter((obj) => !!obj);

  // Check active state
  /* Depends on
    *   unlocked
    *   step_activation -> master && stage
    */
  const masterTopic = topics.find((topic) => topic.topic_id === 'master');
  const masterDone = masterTopic ? masterTopic.modules.every((module) => module.status === 3) : true;

  if (step_activation) {
    // active depending on prev modules
    if (!masterDone) {
      topics = topics.map(
        (topic) => ({
          ...topic,
          active: topic.topic_id === 'master', // false on normal topics
          modules: topic.modules.map((module) => ({ ...module, active: topic.topic_id === 'master' })), // false on normal topics
        }),
      );
    } else {
      topics = topics.map((topic) => {
        const { modules: topicModules } = topic;
        const firstStageDone = !topicModules.filter((mod) => mod.stage === 1 && mod.status !== 3).length;
        const secondStageDone = !topicModules.filter((mod) => mod.stage === 2 && mod.status !== 3).length;

        const isActive = (stage) => {
          switch (stage) {
            case 1:
              return true;
            case 2:
              return firstStageDone;
            case 3:
              return firstStageDone && secondStageDone;
            default:
              return true;
          }
        };
        return {
          ...topic,
          modules: topicModules.map((module) => ({
            ...module,
            active: isActive(module.stage),
          })),
        };
      });
    }
  }

  // Overrule active with unlocked
  topics = topics.map(
    (topic) => ({
      ...topic,
      modules: topic.modules.map((module) => (
        {
          ...module,
          active: checkModuleUnlocked(user, module) && module.active,
        }
      )),
    }),
  );

  return topics;
}
