interface SpiderConfig {
  readonly SCALES: number;
  readonly AXIS_LENGTH: number;
  readonly INNER_CIRCLE_MULTIPLIER: number;
  readonly MIN_DISTANCE_MULTIPLIER: number;
}

interface CircleConfig {
  readonly WIDTH_MULTIPLIER: number;
  readonly MARGIN_MULTIPLIER: number;
}

interface LabelConfig {
  readonly WIDTH_MULTIPLIER: number;
  readonly HEIGHT_MULTIPLIER: number;
}

interface CakeConfig {
  readonly MAX_RADIUS_MULTIPLIER: number;
  readonly CIRCLE: CircleConfig;
  readonly LABEL: LabelConfig;
}

interface ChartConfig {
  readonly SIZE: number;
  readonly MIDDLE: number;
  readonly SPIDER: SpiderConfig;
  readonly CAKE: CakeConfig;
}

export const CHART_CONFIG: ChartConfig = {
  SIZE: 100,
  MIDDLE: 50,
  SPIDER: {
    SCALES: 10,
    AXIS_LENGTH: 35,
    INNER_CIRCLE_MULTIPLIER: 0.5,
    MIN_DISTANCE_MULTIPLIER: 1.6,
  },
  CAKE: {
    MAX_RADIUS_MULTIPLIER: 0.32,
    CIRCLE: {
      WIDTH_MULTIPLIER: 0.067,
      MARGIN_MULTIPLIER: 0.2,
    },
    LABEL: {
      WIDTH_MULTIPLIER: 0.17,
      HEIGHT_MULTIPLIER: 0.07,
    },
  },
} as const;
