
import getCategoryByCategoryKey from "./get-category-by-category-key";
import getObjectByKeyValue from "./get-object-by-key-value";
import getModuleKey from "./get-module-key";

/**
 * Get module from categories by moduleKey and categoryKey
 *
 * @param {Array} categories
 * @param {String} moduleKey
 * @param {String} categoryKey
 * @returns module
 *
 * @category elearning
 * @subcategory helpers
 * @exports getModuleByKeyFromCategories

 */
export default function getModuleByKeyFromCategories(
  categories,
  moduleKey,
  categoryKey,
  userLanguage
) {
  const categoryByKey = getCategoryByCategoryKey(categories, categoryKey, userLanguage);
  // Delete this fallback with the refactoring of modules to be shown based on category id, not category NAME
  const defaultCategoryKey = getCategoryByCategoryKey(categories, categoryKey, "en");
  const moduleByKey =
    categoryByKey?.modules?.find((currentModule) => getModuleKey(currentModule) === moduleKey) ||
    defaultCategoryKey?.modules?.find((currentModule) => getModuleKey(currentModule) === moduleKey);

  return moduleByKey || getObjectByKeyValue(categoryByKey?.modules, "id", moduleKey);
}
