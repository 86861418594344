
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { MdCheck } from 'react-icons/md';

import './custom-form-group.scss';

export default function CustomFormGroup(props) {
  const {
    id, label, optional, placeholder, value, valid, disabled, handleOnChange, isTrimOnBlur, ...restProps
  } = props;
  const { t } = useTranslation('translations');

  const onChange = (e) => {
    const targetValue = e.target.value ? e.target.value.trimLeft() : '';
    e.target.value = targetValue;
    const result = {};
    result[id] = targetValue;
    handleOnChange(result);
  };

  const onBlur = (e) => {
    if (!isTrimOnBlur) {
      return;
    }
    const targetValue = e.target.value ? e.target.value.trim() : '';
    e.target.value = targetValue;
    const result = {};
    result[id] = targetValue;
    handleOnChange(result);
  };

  return (
        <Form.Group>
            <Form.Label
                htmlFor={id}
                className={`${props.disabled ? 'disabled' : ''}`}
            >{label} {optional ? <span className='fs-footnote'>({t('Optional')})</span> : null}</Form.Label>
            <InputGroup>
                <Form.Control
                    onBlur={(e) => onBlur(e)}
                    type='text'
                    id={id}
                    className={`custom-form-control ${valid ? 'valid' : ''}`}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => onChange(e)}
                    disabled={disabled}
                    {...restProps}
                />
                <InputGroup.Append className={`validation-append ${valid ? 'valid' : ''} ${props.disabled ? 'disabled' : ''}`}>
                    <InputGroup.Text><MdCheck /></InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
        </Form.Group>
  );
}

CustomFormGroup.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  valid: PropTypes.bool,
  disabled: PropTypes.bool,
  optional: PropTypes.bool,
  handleOnChange: PropTypes.func.isRequired,
  isTrimOnBlur: PropTypes.bool,

};

CustomFormGroup.defaultProps = {
  label: '',
  placeholder: '',
  value: '',
  valid: false,
  disabled: false,
  optional: false,
  isTrimOnBlur: false,
};
