import ActionTypes from 'shared/utilities/action-types';

const INITIAL_STATE = {};

class WelcomeReducer {
  static reduce(state = INITIAL_STATE, action) {
    switch (action.type) {
      case ActionTypes.WELCOME_CHANGED:
        return {
          ...state,
          welcome: action.welcome,
          firstLogin: false,
        };
      case 'SET_FIRST_LOGIN':
        return { ...state, firstLogin: action.payload };
      case ActionTypes.AUTH_LOGIN_FAILED:
      case ActionTypes.AUTH_LOGIN_REQUEST:
      case ActionTypes.AUTH_LOGIN_SUCCESS:
      case ActionTypes.AUTH_LOGOUT_FAILED:
      case ActionTypes.AUTH_LOGOUT_REQUEST:
      case ActionTypes.AUTH_LOGOUT_SUCCESS:
      case ActionTypes.CLEAR_STORE:
        return INITIAL_STATE;

      default:
        return state;
    }
  }
}

export default WelcomeReducer.reduce;
