
export const FAQ_ITEMS = [
  { key: 'faq_1' },
  { key: 'faq_2' },
  { key: 'faq_3' },
  { key: 'faq_4' },
  { key: 'faq_5' },
  { key: 'faq_6' },
  { key: 'faq_7' },
  { key: 'faq_8' },
  { key: 'faq_9' },
  { key: 'faq_10' },
  { key: 'faq_11' },
  { key: 'faq_12' },
];
