
import React from 'react';

export default function LevelDisabled() {
  return (
        <svg
            width='136'
            height='199'
            viewBox='0 0 136 199'
        >
            <defs>
                <path
                    id='prefix__level_tour_mobile-b'
                    d='M57.632 0L0 25.691v45.195c0 14.628 6.25 34.196 24.384 50.943C45.75 141.583 57.63 143 57.63 143s11.876-1.417 33.259-21.17c18.116-16.748 24.369-36.316 24.369-50.944V25.691L57.632 0z'
                />
                <filter
                    id='prefix__level_tour_mobile-a'
                    width='127.8%'
                    height='122.4%'
                    x='-12.1%'
                    y='-9.8%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dx='2'
                        dy='2'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='5'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'
                    />
                </filter>
                <path
                    id='prefix__level_tour_mobile-c'
                    d='M0 0h115.258v79H0z'
                />
                <path
                    id='prefix__level_tour_mobile-e'
                    d='M57.632 0L0 25.691v45.195c0 14.628 6.25 34.196 24.384 50.943C45.75 141.583 57.63 143 57.63 143s11.876-1.417 33.259-21.17c18.116-16.748 24.369-36.316 24.369-50.944V25.691L57.632 0z'
                />
                <text
                    id='prefix__level_tour_mobile-h'
                    fill='#000'
                    fontSize='26'
                    fontWeight='normal'
                    letterSpacing='-1'
                >
                    <tspan
                        x='32'
                        y='89'
                    >58 %</tspan>
                </text>
                <filter
                    id='prefix__level_tour_mobile-g'
                    width='115.7%'
                    height='133.3%'
                    x='-7.8%'
                    y='-8.3%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dy='2'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='1'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
                    />
                </filter>
            </defs>
            <g
                fill='none'
                fillRule='evenodd'
            >
                <text
                    fill='#4A4A4A'
                    fontSize='20'
                    fontWeight='bold'
                    transform='translate(8 8)'
                >
                    <tspan
                        x='26'
                        y='186'
                    >12 / 21</tspan>
                </text>
                <g transform='translate(8 8)'>
                    <g fillRule='nonzero'>
                        <use
                            fill='#000'
                            filter='url(#prefix__level_tour_mobile-a)'
                            href='#prefix__level_tour_mobile-b'
                        />
                        <use
                            fill='#FFF'
                            href='#prefix__level_tour_mobile-b'
                        />
                    </g>
                    <g transform='translate(0 64.5)'>
                        <mask
                            id='prefix__level_tour_mobile-d'
                            fill='#fff'
                        >
                            <use href='#prefix__level_tour_mobile-c' />
                        </mask>
                        <path
                            className='fill-primary'
                            fillRule='nonzero'
                            d='M57.632-64L0-38.309V6.886C0 21.514 6.25 41.082 24.384 57.83 45.75 77.583 57.63 79 57.63 79s11.876-1.417 33.259-21.17c18.116-16.748 24.369-36.316 24.369-50.944v-45.195L57.632-64z'
                            mask='url(#prefix__level_tour_mobile-d)'
                        />
                        <path
                            fill='#000'
                            fillRule='nonzero'
                            d='M57.632-64.5l-.002 143s11.876-1.417 33.259-21.17c18.116-16.748 24.369-36.316 24.369-50.944v-45.195L57.632-64.5z'
                            mask='url(#prefix__level_tour_mobile-d)'
                            opacity='.1'
                        />
                    </g>
                    <mask
                        id='prefix__level_tour_mobile-f'
                        fill='#fff'
                    >
                        <use href='#prefix__level_tour_mobile-e' />
                    </mask>
                    <path
                        className='stroke-primary'
                        strokeWidth='5.72'
                        d='M57.631 3.131l54.767 24.417v43.338c0 14.043-6.081 32.787-23.45 48.842-18.328 16.933-29.3 19.936-31.319 20.368-2.018-.433-12.991-3.436-31.304-20.368C8.938 103.672 2.86 84.928 2.86 70.886h0V27.548L57.631 3.13z'
                        mask='url(#prefix__level_tour_mobile-f)'
                    />
                    <path
                        stroke='#000'
                        strokeOpacity='.2'
                        strokeWidth='5.72'
                        d='M57.631 3.131l54.767 24.417v43.338c0 14.043-6.081 32.787-23.45 48.842-18.328 16.933-29.3 19.936-31.319 20.368-2.018-.433-12.991-3.436-31.304-20.368C8.938 103.672 2.86 84.928 2.86 70.886h0V27.548L57.631 3.13z'
                        mask='url(#prefix__level_tour_mobile-f)'
                    />
                    <g
                        fill='#000'
                        mask='url(#prefix__level_tour_mobile-f)'
                    >
                        <use
                            filter='url(#prefix__level_tour_mobile-g)'
                            href='#prefix__level_tour_mobile-h'
                        />
                        <use href='#prefix__level_tour_mobile-h' />
                    </g>
                </g>
            </g>
        </svg>

  );
}
