import { PersonalizedModule } from "../../types/module.type";

/**
 * Checks if all modules are optional (no mandatory modules exist)
 * @param modules Array of PersonalizedModule objects
 * @returns boolean - true if all modules are optional, false if any mandatory modules exist
 */
export const hasOnlyOptionalModules = (modules: PersonalizedModule[]): boolean => {
  if (!modules.length) return false;
  
  return modules.every((module) => !module.mandatory);
}; 