
// Packages
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import AppPageContent from 'shared/components/app-page-content';

// Scss
import 'app.scss';
import Loading from 'shared/components/loading';

export default function UpdatingPlayerPage() {
  const { t } = useTranslation(['translations', 'messageTranslations']);

  const classNames = 'container base m-auto d-flex flex-column justify-content-center';
  return (
    <div className="app-content h-100">
      <div className="app-page">
        <AppPageContent center>
          <div className={classNames}>
            <div className="row justify-content-center">
              <div className="col-12 col-sm-9 col-md-7 col-lg-5 col-xl-4">
                <div className="card shadow">
                  <div className="d-flex py-4 flex-column">
                    <p className="mx-auto text-center">{t('Loading')}</p>
                    <div className="mx-auto fs-color-primary text-center">
                      <Loading />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AppPageContent>
      </div>
    </div>
  );
}
