import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Button } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { IconArrowLeft } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react/icons";

export default function PageContentHeader(props) {
  const { t } = useTranslation("translations");
  const history = useHistory();

  const { title, backAction, className, subtitleClassName, subtitle } = props;

  const onClick = backAction || (() => history.push("/"));

  return (
    <React.Fragment>
      <div className={`row text-left ${className || ""}`}>
        <div className="col-12 col-md-2 mb-3 mb-md-0 d-flex align-self-start">
          <Button
            variant="naked"
            aria-label={t("Back")}
            leftSection={<IconArrowLeft size={16} />}
            onClick={onClick}
          >
            {t("Back")}
          </Button>
        </div>
        <div className="col-12 col-md-8 text-center">
          <h2 className="mb-2 h1">{title}</h2>
        </div>
        <div className="col-12 col-md-2" />
      </div>
      {subtitle && (
        <div className={`row mt-3 mb-4 ${subtitleClassName || ""}`}>
          <div className="col-12 col-md-1" />
          <div className="col-12 col-md-10 text-center">
            <span className="font-weight-normal h3 mb-2">{subtitle}</span>
          </div>
          <div className="col-12 col-md-1" />
        </div>
      )}
    </React.Fragment>
  );
}

PageContentHeader.propTypes = {
  title: PropTypes.string,
  backAction: PropTypes.func,
  className: PropTypes.string,
  subtitleClassName: PropTypes.string,
  subtitle: PropTypes.string,
};

PageContentHeader.defaultProps = {
  title: null,
  backAction: null,
  className: null,
  subtitleClassName: null,
  subtitle: null,
};
