
// Packages
import PropTypes from 'prop-types';
import PulseLoader from 'react-spinners/PulseLoader';
import React, { useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react"

// Utilities
import ActionTypes from 'shared/utilities/action-types';
import useMetaLinks from 'shared/hooks/use-meta-links';

// Components
import AuthImage from 'authentication/components/auth-image';
import Helmet from 'shared/components/helmet';
import AuthHeader from 'authentication/components/auth-header';
import SignUpForm from 'authentication/components/signup-form';

// Scss
import 'scss/base.scss';

const STATES = {
  REGISTER_FORM: 'REGISTER_FORM',
  REGISTER_SSO_FORM: 'REGISTER_SSO_FORM',
  FETCH_DATA_FORM: 'FETCH_DATA_FORM',
  SKIP_SSO_FORM: 'SKIP_SSO_FORM',
};

export default function RegisterConfPage() {
  const { t, i18n } = useTranslation(['translations', 'helmetTranslations']);
  const dispatch = useDispatch();

  const { register_data } = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    dispatch((disp) => disp({ type: ActionTypes.REMOVE_REDIRECT_REGISTER }));
  }, []);

  return (
    <React.Fragment>
      <Helmet title={t('helmetTranslations:Registration')} />
      <AuthHeader />

      <div className="m-auto pb-3 auth-card-width">
        <Card shadow="sm" className="auth-card">
          <div className="d-flex flex-row">
            <AuthImage flipx={i18n.language === 'ar'} />
            <div className="base-card-right flex-grow-1">
              <CardBody register_data={register_data} />
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
}

function CardBody(props) {
  const { t } = useTranslation('translations');
  const { register_data } = props;

  let state = '';
  if (register_data) {
    if (register_data.sso && register_data.skip_optin) {
      state = STATES.SKIP_SSO_FORM;
    } else {
      state = STATES.REGISTER_FORM;
    }
  }

  let register_type = null;
  if (register_data && register_data.sso) {
    register_type = 'SSO';
  }

  const { support } = useMetaLinks();

  if (state === STATES.REGISTER_FORM) {
    return (
      <React.Fragment>
        <h2 className="mb-2 h1 text-center register-login-title-weight">{t('Register')}</h2>
        <hr />
        <SignUpForm register_type={register_type} />
        <p className="text-center">
          {t('Already registered?')}
          <Link className="text-nowrap ml-2" to="/">
            {t('Go to login')}.
          </Link>
        </p>
        {support && (
          <p className="text-center">
            <a href={support} target="_blank" rel="noopener noreferrer">
              {t('Go to support')}
            </a>
          </p>
        )}
      </React.Fragment>
    );
  }

  if (state === STATES.SKIP_SSO_FORM) {
    return (
      <React.Fragment>
        <div className="text-center">
          <p>{t('You will be redirected.')}</p>
          <PulseLoader sizeUnit="rem" size={4} loading />
        </div>
      </React.Fragment>
    );
  }

  return <Redirect to="/" />;
}

CardBody.propTypes = {
  register_data: PropTypes.shape(),
};

CardBody.defaultProps = {
  register_data: null,
};
