
/**
 * Check if hints are missing or wrong lang
 * @returns {Boolean}
 */
export default function validateDidYouKnowHints(didyouknowHints, userLang) {
  if (!didyouknowHints || !didyouknowHints.length) return false;
  if (didyouknowHints[0].language !== userLang) return false;
  return true;
}
