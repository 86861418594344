
import cloneDeep from 'lodash/cloneDeep';

import ActionTypes from 'shared/utilities/action-types';

const INITIAL_STATE = {};

class ModulesReducer {
  static reduce(state = INITIAL_STATE, action) {
    let reference;
    let result;
    const payload = action.payload;
    if (payload) {
      reference = payload.reference;
      result = payload.result;
    }
    switch (action.type) {
      case ActionTypes.SET_PLAYER_DATA:
        return {
          ...state,
          player_data: action.payload,
          reference: null,
        };

      case ActionTypes.DELETE_PLAYER_DATA:
        return {
          ...state,
          player_data: null,
        };

      case ActionTypes.CATEGORIES_FETCH_REQUEST:
        return {
          ...state,
          loading: true,
          loadingPlayerPage: true,
          reference: null,
          slideType: null,
        };

      case ActionTypes.CATEGORIES_FETCH_SUCCESS: {
        // Set Timestamp
        const stamp = new Date().getUTCMilliseconds();
        let lang = null;
        if (result && result.length) {
          lang = result[0].language;
        }

        return {
          ...state,
          categories: result,
          lang,
          loading: false,
          loadingPlayerPage: false,
          reference,
          slideType: null,
          timeStamp: stamp,
        };
      }

      case ActionTypes.CATEGORIES_FETCH_FAILED:
        return {
          ...state,
          loading: false,
          loadingPlayerPage: false,
          reference,
          slideType: null,
        };

      case ActionTypes.MODULE_UPDATE_PROGRESS: {
        const { module } = action.payload;
        const categories = cloneDeep(state.categories);
        const resCategories = categories.map((cat) => {
          if (cat.id !== module.category_id) return cat;
          const { modules } = cat;
          const resModules = modules.map((mod) => {
            if (mod.id !== module.id) return mod;
            return {
              ...mod,
              progress: module.progress,
              slide: module.slide,
              point: module.point,
              score: module.score,
              suspend_data: module.suspend_data,
              status: module.status,
            };
          });
          return {
            ...cat,
            modules: resModules,
          };
        });
        return {
          ...state,
          categories: resCategories,
        };
      }

      case ActionTypes.LEAVE_PLAYER_PAGE:
        return { ...state };

      case ActionTypes.AUTH_LOGOUT_FAILED:
      case ActionTypes.AUTH_LOGOUT_SUCCESS:
      case ActionTypes.CLEAR_STORE:
        return {};
      default:
        return state;
    }
  }
}

export default ModulesReducer.reduce;
