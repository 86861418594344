import { useEffect, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UseFormReturnType } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react/form';
import { useForm } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react/form';
import { RootState } from 'state/reducers';
import Validators from 'shared/utilities/validators';
import { isEmail, validateAccessCode } from 'authentication/helpers';

export interface LoginFormValues {
  login: string;
  password: string;
}

interface UseLoginFormReturn {
  form: UseFormReturnType<LoginFormValues>;
  loading: boolean;
  passwordInputRef: React.RefObject<HTMLInputElement>;
}

interface UseLoginFormProps {
  defaultEmail?: string | null;
}

export const useLoginForm = ({
  defaultEmail,
}: UseLoginFormProps): UseLoginFormReturn => {
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const { reference, activateMail, loading, login_data } = useSelector(
    (state: RootState) => state.auth,
    shallowEqual
  );

  const form = useForm<LoginFormValues>({
    name: 'signin-form',
    initialValues: {
      login: defaultEmail || '',
      password: '',
    },
    validate: {
      // TO-DO: Implement error messages if disabling singin button
      // NULL=VALID, TRUE=ERROR
      login: (value) => {
        if (!isEmail(value) && !validateAccessCode(value)) {
          return true;
        }
        return null;
      },
      password: (value) => {
        const validation = Validators.required(value);
        if (!validation.valid) {
          return true;
        }
        return null;
      },
    },
  });

  useEffect(() => {
    if (login_data?.code) {
      form.setFieldValue('login', login_data.code);
    }
  }, []);

  useEffect(() => {
    if (activateMail) {
      form.setFieldValue('login', activateMail);
      setTimeout(() => {
        passwordInputRef.current?.focus();
      }, 100);
    }
  }, [reference, activateMail]);

  return {
    form,
    loading,
    passwordInputRef,
  };
};
