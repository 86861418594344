
// Packages
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Card } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Utilities
import useMetaLinks from 'shared/hooks/use-meta-links';
import { UseReferenceHandler } from 'shared/utilities/use-reference-handler';
import { getSubdomain } from 'authentication/helpers';

// Components
import AuthImage from 'authentication/components/auth-image';
import CustomFormGroup from 'shared/components/custom-form-group';
import Helmet from 'shared/components/helmet';
import AuthHeader from 'authentication/components/auth-header';
import Validators from 'shared/utilities/validators';

// Scss
import 'scss/base.scss';
import { useSoSafeConnect } from 'shared/modules/sosafe-connect';
import { Button } from '@sosafe-platform-engineering/fe-lib-ui-react';

export default function RequestPasswordPage(props) {
  const { t, i18n } = useTranslation(['translations', 'helmetTranslations', 'placeholderTranslations']);
  const [loading, setLoading] = useState(false);
  const { support } = useMetaLinks();
  const { endPoints } = useSoSafeConnect();
  const checkReference = UseReferenceHandler();

  let inputMail = '';
  if (props.location && props.location.state && props.location.state.mail) {
    inputMail = props.location.state.mail;
  }

  const [email, setEmail] = useState(inputMail);
  const history = useHistory();

  let timeout = null;

  useEffect(() => () => clearTimeout(timeout), []);

  const requestPasswordReset = (e) => {
    e.preventDefault();
    setLoading(true);

    const subdomain = getSubdomain();
    endPoints.post.requestPasswordReset({ email, subdomain }).then((response) => {
      checkReference(response.data);
      setLoading(false);
      timeout = setTimeout(() => {
        history.push('/');
      }, 500);
    }).catch((error) => {
      if (error.response) {
        checkReference(error.response.data);
        setLoading(false);
      } else {
        checkReference({ title: error.name, status: error.statusCode, reference: '40700745-2789-4F88-8412-1885DDC724E4' });
        setLoading(false);
      }
    });
  };

  return (
    <React.Fragment>
      <Helmet title={t('helmetTranslations:Restore password')} />
      <AuthHeader />
      <main className='m-auto auth-card-width'>
        <Card shadow="sm" className='overflow-hidden'>
          <div className='d-flex flex-row'>
            <AuthImage
              flipx={(i18n.language === 'ar')}
            />
            <div className='base-card-right py-3 px-3 flex-grow-1'>
              <h2 className='mb-2 h1 text-center'>{t('Forgot password?')}</h2>
              <hr />
              <Form onSubmit={(e) => requestPasswordReset(e)} className='u-flex u-flexCol'>
                <CustomFormGroup
                  id='input'
                  label={t('Email')}
                  isTrimOnBlur
                  placeholder={t('placeholderTranslations:Email')}
                  value={email}
                  handleOnChange={(value) => setEmail(value.input)}
                  valid={formIsValid(email)}
                  autoComplete="email"
                />
                <Button
                  type='submit'
                  disabled={!formIsValid(email)}
                  className='u-flexGrow1 u-marginBottom16'
                >
                  {loading ? t('Sending email...') : t('Recover')}
                </Button>
              </Form>

              <p className='text-center'>
                {t('Remembered your password?')}
                <Link
                  className='text-nowrap ml-2'
                  to='/'
                >
                  {t('Go to login')}.
                </Link>
              </p>

              {support && (
                <React.Fragment>
                  <hr />
                  <p className='text-center mb-1'>
                    <a
                      href={support}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {t('Go to support')}
                    </a>
                  </p>
                </React.Fragment>
              )}
            </div>
          </div>
        </Card>
      </main>
    </React.Fragment>
  );
}

function formIsValid(email) {
  const validations = [
    Validators.required(email).valid,
    Validators.email(email).valid,
  ];
  return validations.every((item) => item);
}

RequestPasswordPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      mail: PropTypes.string,
    }),
  }).isRequired,
};
