import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

/*
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, onClickOutside, disabled) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target) && !disabled) {
        onClickOutside();
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, disabled]);
}

/**
 * Component that alerts if clicked outside of it
 *
 * @category shared
 * @subcategory components
 * @exports OutsideClickAlerter
 * @component
 */
export default function OutsideClickAlerter(props) {
  const {
    children, className, disabled, onClick,
  } = props;
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.onClickOutside, disabled);

  return (
        <div
            className={className || ''}
            ref={wrapperRef}
            onClick={onClick}
        >
            {children}
        </div>
  );
}

OutsideClickAlerter.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClickOutside: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

OutsideClickAlerter.defaultProps = {
  className: '',
  onClick: () => {},
  disabled: false,
};
