
// Packages
import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon, ChevronUpIcon, Modal } from '@sosafe-platform-engineering/fe-lib-ui-react';

// Hooks
import usePlatformSettings from 'shared/hooks/use-platform-settings';
import useCheats from 'test/hooks/use-cheats';
import useUser from 'shared/hooks/use-user';

// Components
import ActionTypes from 'shared/utilities/action-types';
import CustomButton from 'shared/components/custom-button';
import Divider from 'elearning/components/divider';
import SvgIcon from 'shared/svgs/helper/svg-icon';
import { Close } from 'shared/svgs';
import { ModuleStateChanger } from 'shared/components/module-state-changer.component';

// Scss
import './debug-settings.scss';
import { useGetBadge } from 'shared/hooks/use-get-badge';

export default function DebugSettings(props) {
  const { className } = props;
  const [open, setOpen] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const { refreshUser } = useUser({ enabled: false }); // we use this when the modal closes
  useGetBadge(false);

  const dispatch = useDispatch();
  const { t } = useTranslation('debugTranslations');

  const { settings } = usePlatformSettings();
  const { user } = useSelector((state) => state.auth, shallowEqual);
  let maxAdditionalXp = 0;
  if (user?.game && user?.game?.progress?.level) {
    maxAdditionalXp = user.game.progress.level.xp_max - (user.game.progress.xp + 50);
  }

  const { activities } = useSelector((state) => state?.game, shallowEqual);
  const { categories } = useSelector((state) => state.modules, shallowEqual);
  const { badges } = useSelector((state) => state?.game, shallowEqual);

  const [cheats, setCheats] = useState({
    xp: false, badges: true, level: true, isMultiBadge: false,
  });

  const { cheat, isDebug, closeDebug } = useCheats();

  const isDemoAccount = user?.game?.demo || false;
  const isSalesAccount = user?.game?.sales || false;
  const isReducedView = isDemoAccount || isSalesAccount;

  if (!isDebug) return null;

  const addActivity = (type) => {
    const newActivities = [
      ...(activities || []),
      getNewActivity(type, categories, badges, activities ? activities.length : 0),
    ];
    dispatch({ type: ActionTypes.GET_ACTIVITIES_SUCCESS, payload: { result: newActivities } });
  };

  const applyCheats = () => {
    dispatch({ type: ActionTypes.REWARD_SUCCESS, newRewards: getNewRewards(cheats, badges, user) });
  };

  const triggerAnimations = () => {
    // this is kinda hacky and should not be used - delete after testing
    const charts = document.querySelectorAll('.cakechart, .spiderchart, .progresschart');
    charts.forEach((elem) => {
      const currentClasses = [...elem.classList];
      currentClasses.forEach((cclass) => {
        elem.classList.remove(cclass);
      });
      // triggering reflow
      setTimeout(() => {
        currentClasses.forEach((cclass) => {
          elem.classList.add(cclass);
        });
      }, 10);
    });
  };

  const debugSettings = { ...settings };
  if (isReducedView) delete debugSettings.simulation_opt_in;

  if (isReducedView) {
    return (
            <div className={className}>
                <Card className='shadow hover-shadow p-3'>
                    <div className='d-flex flex-row'>
                        <CustomButton
                            variant='link-dark'
                            label={<h5 className='h4 cursor-pointer'>{t('Features')}</h5>}
                            onClick={() => setOpen(!open)}
                            className='p-0'
                        />
                        <CustomButton
                            variant='link-dark'
                            label={open ? <ChevronUpIcon /> : <ChevronDownIcon />}
                            onClick={() => setOpen(!open)}
                            className='ml-auto mr-0 p-0'
                        />
                    </div>
                    {open && (
                        <div className='d-flex flex-column card pt-2'>
                            {isDemoAccount && !isSalesAccount ? (
                                <Form.Check
                                    type='switch'
                                    id='999'
                                    label={t('Toggle gamification features')}
                                    onChange={() => {
                                      cheat({
                                        achievements: !debugSettings.achievements,
                                        badges: !debugSettings.badges,
                                        colleagues: !debugSettings.colleagues,
                                        hints: !debugSettings.hints,
                                        level: !debugSettings.level,
                                      });
                                    }}
                                    checked={debugSettings.level}
                                />
                            ) : (
                              Object.keys(debugSettings).map((key) => (
                                    <div
                                        key={key}
                                        className='d-flex flex-row'
                                    >
                                        <Form.Check
                                            type='switch'
                                            id={`key${key}`}
                                            label={t(key)}
                                            onChange={() => cheat({ [key]: !debugSettings[key] })}
                                            checked={debugSettings[key]}
                                        />
                                    </div>
                              ))
                            )}
                        </div>
                    )}
                </Card>
            </div>
    );
  }

  return (
        <div className={className}>
            <Card className='debug-settings shadow'>
                <div className='d-flex flex-row'>
                    <CustomButton
                        variant='link'
                        label={<span className='cursor-pointer'>🧙‍♂️</span>}
                        onClick={() => setOpen(!open)}
                    />
                    <CustomButton
                        variant='link-dark'
                        label={
                            <span className='d-flex'>
                                <SvgIcon
                                    color='#C6433C'
                                    Icon={Close}
                                    className='my-auto'
                                />
                            </span>
                        }
                        onClick={() => closeDebug()}
                        className='ml-auto'
                    />
                </div>
                {open && (
                    <div className='d-flex flex-column card p-2'>
                        Settings
                        {Object.keys(debugSettings).map((key) => (
                            <div
                                key={key}
                                className='d-flex flex-row'
                            >
                                <Form.Check
                                    type='switch'
                                    id={`key${key}`}
                                    label={t(key)}
                                    onChange={() => cheat({ [key]: !debugSettings[key] })}
                                    checked={debugSettings[key]}
                                />
                            </div>
                        ))}
                        <Divider className='my-1' />
                        Activities
                        <div className='d-flex flex-column'>
                            <CustomButton
                                className='py-0 mb-1'
                                label={<span className='u-textMedium'>Add Module</span>}
                                onClick={() => addActivity('module')}
                            />
                            <CustomButton
                                className='py-0 mb-1'
                                label={<span className='u-textMedium'>Add Badge</span>}
                                onClick={() => addActivity('badge')}
                            />
                            <CustomButton
                                className='py-0 mb-1'
                                label={<span className='u-textMedium'>Add Level</span>}
                                onClick={() => addActivity('level')}
                            />
                        </div>
                        <Divider className='my-1' />
                        Cheats
                        <div className='d-flex flex-row'>
                            <Form.Check
                                type='switch'
                                id='keyBadge'
                                label='new Badge'
                                onChange={() => setCheats({
                                  ...cheats,
                                  badges: !cheats.badges,
                                  isMultiBadge: !cheats.badges ? false : cheats.isMultiBadge,
                                })
                                }
                                checked={cheats.badges || false}
                            />
                        </div>
                        <div className='d-flex flex-row'>
                            <Form.Check
                                type='switch'
                                id='keyisMultiBadge'
                                label='2 new Badges'
                                onChange={() => setCheats({
                                  ...cheats,
                                  isMultiBadge: !cheats.isMultiBadge,
                                  badges: !cheats.isMultiBadge ? false : cheats.badges,
                                })
                                }
                                checked={cheats.isMultiBadge || false}
                            />
                        </div>
                        <div className='d-flex flex-row'>
                            <Form.Check
                                type='switch'
                                id='keyXp'
                                label={`+${maxAdditionalXp} xp`}
                                onChange={() => setCheats({ ...cheats, xp: !cheats.xp, level: !cheats.xp ? false : cheats.level })
                                }
                                checked={cheats.xp || false}
                            />
                        </div>
                        <div className='d-flex flex-row'>
                            <Form.Check
                                type='switch'
                                id='keyLevel'
                                label='new level'
                                onChange={() => setCheats({ ...cheats, level: !cheats.level, xp: !cheats.level ? false : cheats.xp })
                                }
                                checked={cheats.level || false}
                            />
                        </div>
                        <CustomButton
                            disabled={!cheats.badges && !cheats.level && !cheats.xp && !cheats.isMultiBadge}
                            className='py-0 mb-1'
                            label={<span className='u-textMedium'>Confirm</span>}
                            onClick={applyCheats}
                        />
                        <CustomButton
                            ariaLabel='show module cheats'
                            className='py-0 mb-1'
                            variant='outline-secondary'
                            label={<span className='u-textMedium'>Module cheats</span>}
                            onClick={() => setModalVisible(true)}
                        />
                        <Modal
                            isOpen={modalVisible}
                            onClose={() => {
                              refreshUser();
                              setModalVisible(false);
                            }}
                            confirmButton={{ ariaLabel: 'this_button_api_whack' }}
                        >
                            <Modal.Header>Modify user state</Modal.Header>
                            <Modal.Body>
                                <ModuleStateChanger />
                            </Modal.Body>
                        </Modal>
                        <Divider className='my-1' />
                        <CustomButton
                            className='py-0 mb-1'
                            label={<span className='u-textMedium'>Reset Chartanimations</span>}
                            onClick={triggerAnimations}
                        />
                    </div>
                )}
            </Card>
        </div>
  );
}

function getNewActivity(type, categories, badges, length) {
  const module = categories[0].modules[Math.floor(categories[0].modules.length * Math.random())];
  const badge = badges[Math.floor(badges.length * Math.random())];

  const act = {
    badge: null,
    badge_id: null,
    component: null,
    created: `2020-10-0${(Math.ceil(length / 3) % 10) + 1}T15:11:33.525Z`,
    hint_id: null,
    level: null,
    level_id: null,
    module: null,
    module_id: 432,
    notification_id: null,
  };

  switch (type) {
    case 'module':
      act.module = {
        ...module,
      };
      break;
    case 'badge':
      act.badge = {
        ...badge,
      };
      break;
    case 'level':
      act.level = {
        name: 'Level 9000',
        description: 'Du bist ja ein richtiger Cyber Experte!',
      };
      break;
    default:
      break;
  }

  return act;
}

function getNewRewards(cheats, badges, user) {
  const {
    xp: isXp, level: isLevel, badges: isBadges, isMultiBadge,
  } = cheats;
  const { game } = user;
  const { progress } = game;

  const maxAdditionalXp = progress.level.xp_max - (progress.xp + 50);

  let additionalXp = null;
  if (isXp) {
    additionalXp = maxAdditionalXp;
  } else if (isLevel) {
    additionalXp = maxAdditionalXp + 150;
  }

  let newBadges = isBadges
    ? [
      {
        name: 'Cheater!',
        description_won: 'Eigentlich unverdient. Hier trotzdem ein neues Badge.',
        image: badges[Math.floor(Math.random() * badges.length)].image,
      },
    ]
    : null;
  if (isMultiBadge) {
    newBadges = [
      {
        name: 'Cheater!',
        description_won: 'Eigentlich unverdient. Hier trotzdem ein neues Badge.',
        image: badges[Math.floor(Math.random() * badges.length)].image,
      },
      {
        name: 'Bester Button Klicker',
        description_won: "Für das Klicken auf 'Confirm' hast du dir ein Abzeichen verdient",
        image: badges[Math.floor(Math.random() * badges.length)].image,
      },
    ];
  }

  return {
    xp: additionalXp,
    level: isLevel
      ? {
        name: 'Level 9000!',
        xp: progress.level.xp_max,
        xp_max: progress.level.xp_max * 2,
      }
      : null,
    badges: newBadges,
  };
}
