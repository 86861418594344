import React, { useState } from 'react';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import PropTypes from 'prop-types';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import Validators from 'shared/utilities/validators';

export default function PasswordControl(props) {
  const {
    secondPassword,
    value,
    firstPassword,
    name,
    formLabel,
    showHint,
    className,
    placeholder,
    setRef,
    hideIndicator,
    newPassword,
    ...restProps
  } = props;
  const { t } = useTranslation('translations');
  const [showPassword, setShowPassword] = useState(false);

  const validationResult = Validators.password(value);
  let passwordsEqual;
  if (secondPassword && secondPassword !== '') {
    passwordsEqual = Validators.matches(value, secondPassword);
  }

  const updateValue = (pw) => {
    props.onChange({ target: { value: pw } });
  };

  const getInfoForRule = (rule) => {
    const info = {
      min: t('... at least 12 characters'),
      max: t('... 512 characters maximum'),
      uppercase: t('... an uppercase letter'),
      lowercase: t('... a lowercase letter'),
      symbols: t('... a symbol'),
      digits: t('... a digit'),
      equal: t("Your passwords don't match."),
    };
    return info[rule];
  };

  const showValidators =
    value &&
    validationResult &&
    validationResult.data &&
    validationResult.data.length;
  const showEqual = passwordsEqual && !passwordsEqual.valid;

  const isValid =
    value &&
    value !== '' &&
    !showValidators &&
    !showEqual &&
    (firstPassword === value || secondPassword === value);

  function passwordRefFunction(instance) {
    if (setRef) {
      props.setRef(instance);
    }
  }

  return (
    <React.Fragment>
      <Form.Group className="d-flex flex-column">
        <Form.Label className="mb-0" htmlFor={name}>
          {formLabel || t('Password')}
        </Form.Label>
        {showHint && (
          <span className="u-textMedium text-muted mb-2">
            {t(
              'Please do not use any previously used passwords! Always use different passwords.'
            )}
          </span>
        )}
        <InputGroup>
          <Form.Control
            id={name}
            className={`${className}${isValid ? ' valid' : ''}`}
            placeholder={placeholder}
            type={showPassword ? 'text' : 'password'}
            autoComplete={newPassword ? 'new-password' : ''}
            value={value}
            onChange={(e) => updateValue(e.target.value)}
            ref={passwordRefFunction}
            {...restProps}
          />
          <InputGroup.Append
            onClick={() => setShowPassword(!showPassword)}
            className="cursor-pointer"
            role="button"
            aria-label={
              showPassword
                ? t('areaPressedPasswordHide')
                : t('areaPressedPasswordShow')
            }
          >
            <InputGroup.Text>
              {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
      </Form.Group>
      {!hideIndicator && (showValidators || showEqual) ? (
        <div className="alert alert-info" role="alert">
          {showValidators
            ? [
                <strong key="v_title">{t('Your password needs...')}</strong>,
                validationResult.data.map((rule, i) => (
                  <React.Fragment key={i.toString()}>
                    <br />
                    <span key={i.toString()}>{getInfoForRule(rule)}</span>
                  </React.Fragment>
                )),
              ]
            : null}
          {showValidators && showEqual ? <br /> : null}
          {showEqual && <strong>{t("Your passwords don't match.")}</strong>}
        </div>
      ) : null}
    </React.Fragment>
  );
}

PasswordControl.propTypes = {
  secondPassword: PropTypes.string,
  value: PropTypes.string,
  firstPassword: PropTypes.string,
  name: PropTypes.string,
  formLabel: PropTypes.string,
  showHint: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  hideIndicator: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  setRef: PropTypes.func,
  newPassword: PropTypes.bool,
  autoComplete: PropTypes.string,
};

PasswordControl.defaultProps = {
  secondPassword: '',
  value: '',
  firstPassword: '',
  name: '',
  formLabel: null,
  showHint: false,
  className: '',
  placeholder: '',
  hideIndicator: false,
  setRef: null,
  newPassword: false,
  autoComplete: undefined,
};
