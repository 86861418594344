
import ActionTypes from 'shared/utilities/action-types';

/**
 * Dispatch function to clear register data of auth reducer
 *
 * @returns {function} Functio to dispatch
 *
 * @category authentication
 * @subcategory services
 * @exports dispatchRemoveRegisterData
 */
export default function dispatchRemoveRegisterData() {
  return (dispatch) => {
    dispatch({ type: ActionTypes.AUTH_REMOVE_REG_DATA });
  };
}
