import React, { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Button,
  Input,
  Stack,
  Text,
  PasswordInput,
} from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import { RootState } from 'state/reducers';
import { isEmail } from 'authentication/helpers';
import { AuthFunction } from 'authentication/types/auth-funtion.type';
import { useSinginReference } from './use-singin-reference.hook';
import { useSignInAlertsHook } from './use-singin-alerts.hook';
import { useLoginForm, LoginFormValues } from './use-singin-form.hook';

interface SignInFormProps {
  doSignIn: AuthFunction;
  memoizedEmail?: string;
}

const SignInForm: React.FC<SignInFormProps> = ({ doSignIn, memoizedEmail }) => {
  const { t } = useTranslation([
    'translations',
    'messageTranslations',
    'placeholderTranslations',
  ]);

  const defaultReference = useSelector(
    (state: RootState) => state.auth.reference,
    shallowEqual
  );

  const {
    showLoginNotActivated,
    showLoginNotExist,
    showAccountNotExist,
    resetShowAccountNotExist,
  } = useSinginReference({ defaultReference });

  const sessionExpired = useMemo(
    () => sessionStorage.getItem('sessionExpired'),
    []
  );

  const { loading, passwordInputRef, form } = useLoginForm({
    defaultEmail: sessionExpired ? null : memoizedEmail,
  });

  useSignInAlertsHook({
    input: form.values.login,
    showLoginNotActivated,
    showLoginNotExist,
    showAccountNotExist,
    resetShowAccountNotExist,
  });

  const onFormSubmit = form.onSubmit(({ login, password }: LoginFormValues) => {
    if (isEmail(login)) {
      doSignIn('EMAIL', {
        password,
        email: login,
        code: '',
        sso: '',
      });
    } else {
      doSignIn('CODE', {
        password,
        email: '',
        code: login,
        sso: '',
      });
    }
  });

  return (
    <form onSubmit={onFormSubmit} data-testid="login-form">
      <Stack gap="lg">
        <Stack gap="sm">
          <Input.Wrapper
            label={t('translations:Business email address or access code')}
          >
            <Input
              id="userEmail"
              autoComplete="email"
              {...form.getInputProps('login')}
              key={form.key('login')}
            />
          </Input.Wrapper>
          <Stack gap="xs">
            <PasswordInput
              label={t('translations:Password')}
              type="password"
              {...form.getInputProps('password')}
              key={form.key('password')}
              ref={passwordInputRef}
              autoComplete="current-password"
            />
            <Text ta="right" size="xs">
              <Link to="/password">
                {t('translations:Forgot your password?')}
              </Link>
            </Text>
          </Stack>
        </Stack>
        <Button
          type="submit"
          size="sm"
          disabled={!form.isValid()}
          data-testid="login-button"
          aria-label={t('translations:Login')}
          loading={loading}
        >
          {loading ? t('translations:Logging in...') : t('translations:Login')}
        </Button>
      </Stack>
    </form>
  );
};

export default SignInForm;
