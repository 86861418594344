import React, { useContext } from 'react';
import Logo from 'shared/components/header/logo-header';
import LangSwitch from 'authentication/components/lang-switch';
import GlobalSettingsContext from 'shared/modules/global-settings/state/global-settings-context.provider';
import {
  Flex,
  Stack,
  Divider,
} from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';

const AuthHeader: React.FC = () => {
  const { globalSettings } = useContext(GlobalSettingsContext);

  return (
    <header style={{ width: '100' }}>
      <Stack gap="0">
        <Flex p="lg" bg="white">
          <Logo theme={globalSettings} />
        </Flex>
        <Divider />
        <Flex p="md" align="center" justify="end">
          <LangSwitch />
        </Flex>
      </Stack>
    </header>
  );
};

export default AuthHeader;
