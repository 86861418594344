
// Packages
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Collapse } from "react-bootstrap";

// Utilities
import { FAQ_ITEMS } from "support/utilities/constants";
import useMetaLinks from "shared/hooks/use-meta-links";

// Components
import PageContentHeader from "shared/components/page-content-header";
import Helmet from "shared/components/helmet";
import SvgIcon from "shared/svgs/helper/svg-icon";
import { ChevronDown, ChevronLeft, ChevronRight } from "shared/svgs";

// Scss
import "./faq-page.scss";

export default function FaqPage() {
  const { t, i18n } = useTranslation(["translations", "faqTranslations", "helmetTranslations"]);
  const { privacy } = useMetaLinks();
  const classNames = "FaqPage px-3";

  const [activeKey, setActiveKey] = useState(null);

  const onClickItem = (key) => {
    if (activeKey === key) {
      setActiveKey(null);
    } else {
      setActiveKey(key);
    }
  };

  const chevIcon = i18n.language === "ar" ? ChevronLeft : ChevronRight;

  return (
    <React.Fragment>
      <Helmet title={t("helmetTranslations:FAQ")} />
      <div className={classNames}>
        <PageContentHeader
          title={t("faqTranslations:title")}
          subtitle={t("faqTranslations:subtitle")}
        />
        <div className="row mb-4">
          <div className="col-12 col-md-8 mx-auto">
            <Card className="shadow">
              <Card.Body className="p-0">
                <div className="d-flex flex-column">
                  {FAQ_ITEMS.map(({ key }, idx) => (
                    <React.Fragment key={key}>
                      <div
                        className={`d-flex flex-column cursor-pointer accordion-item ${
                          idx === 0 ? "border-top-0" : ""
                        } `}
                        onClick={() => onClickItem(key)}
                        role="button"
                        aria-expanded={activeKey === key}
                        aria-controls={`accordion-collapse-${key}`}
                      >
                        <div className="d-flex flex-row">
                          <span className="ml-1 accordion-item-octicon d-flex">
                            <SvgIcon
                              className="mx-auto align-self-center color-primary"
                              size="12"
                              Icon={activeKey === key ? ChevronDown : chevIcon}
                              color="black"
                            />
                          </span>
                          <span id={`accordion-label-${key}`} className="ml-2">
                            {t(`faqTranslations:items_${idx}_title`)}
                          </span>
                        </div>
                      </div>
                      <Collapse
                        id={`accordion-collapse-${key}`}
                        aria-labelledby={`accordion-label-${key}`}
                        className="item-desc mt-2"
                        in={activeKey === key}
                      >
                        <div
                          className="px-4 mx-2 u-textMedium"
                          dangerouslySetInnerHTML={{
                            __html: t(`faqTranslations:items_${idx}_description`, {
                              link: privacy,
                            }),
                          }}
                        />
                      </Collapse>
                    </React.Fragment>
                  ))}
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
