
import React from 'react';

export default function Badges() {
  return (
        <svg
            width='305'
            height='101'
            viewBox='0 0 305 101'
            direction='ltr'
        >
            <defs>
                <path
                    id='prefix__abzeichen-a'
                    d='M32.63 0L0 14.54v25.576c0 8.279 3.539 19.353 13.806 28.83C25.903 80.126 32.63 80.927 32.63 80.927s6.724-.802 18.83-11.98c10.258-9.478 13.798-20.552 13.798-28.83V14.538L32.63 0z'
                />
                <path
                    id='prefix__abzeichen-c'
                    d='M32.63 0L0 14.54v25.576c0 8.279 3.539 19.353 13.806 28.83C25.903 80.126 32.63 80.927 32.63 80.927s6.724-.802 18.83-11.98c10.258-9.478 13.798-20.552 13.798-28.83V14.538L32.63 0z'
                />
                <path
                    id='prefix__abzeichen-e'
                    d='M32.63 0L0 14.54v25.576c0 8.279 3.539 19.353 13.806 28.83C25.903 80.126 32.63 80.927 32.63 80.927s6.724-.802 18.83-11.98c10.258-9.478 13.798-20.552 13.798-28.83V14.538L32.63 0z'
                />
                <filter
                    id='prefix__abzeichen-g'
                    width='140.9%'
                    height='133.3%'
                    x='-20.5%'
                    y='-16.7%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dx='1'
                        dy='1'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='3'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        result='shadowMatrixOuter1'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0'
                    />
                    <feMerge>
                        <feMergeNode in='shadowMatrixOuter1' />
                        <feMergeNode in='SourceGraphic' />
                    </feMerge>
                </filter>
                <path
                    id='prefix__abzeichen-h'
                    d='M32.644 0L0 14.552v25.6c0 8.286 3.54 19.37 13.812 28.856C25.914 80.198 32.643 81 32.643 81s6.728-.802 18.84-11.992c10.261-9.486 13.803-20.57 13.803-28.856v-25.6L32.644 0z'
                />
                <filter
                    id='prefix__abzeichen-j'
                    width='140.9%'
                    height='133.3%'
                    x='-20.5%'
                    y='-16.7%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dx='1'
                        dy='1'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='3'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        result='shadowMatrixOuter1'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0'
                    />
                    <feMerge>
                        <feMergeNode in='shadowMatrixOuter1' />
                        <feMergeNode in='SourceGraphic' />
                    </feMerge>
                </filter>
                <path
                    id='prefix__abzeichen-k'
                    d='M32.644 0L0 14.552v25.6c0 8.286 3.54 19.37 13.812 28.856C25.914 80.198 32.643 81 32.643 81s6.728-.802 18.84-11.992c10.261-9.486 13.803-20.57 13.803-28.856v-25.6L32.644 0z'
                />
            </defs>
            <g
                fill='none'
                fillRule='evenodd'
            >
                <path
                    fill='#494949'
                    fillRule='nonzero'
                    d='M33.63 9L1 23.54v25.576c0 8.279 3.539 19.353 13.806 28.83C26.903 89.126 33.63 89.927 33.63 89.927s6.724-.802 18.83-11.98c10.258-9.478 13.798-20.552 13.798-28.83V23.538L33.63 9z'
                />
                <g transform='translate(1 9)'>
                    <mask
                        id='prefix__abzeichen-b'
                        fill='#fff'
                    >
                        <use href='#prefix__abzeichen-a' />
                    </mask>
                    <g
                        fillRule='nonzero'
                        mask='url(#prefix__abzeichen-b)'
                    >
                        <path
                            fill='#FE9100'
                            d='M45.585 25.96c-1.168-1.276-2.984-2.998-3.757.89-.262 1.322-.876 2.341-1.402 3.162-.567-2.871-2.273-5.843-3.88-7.846-.602-.75-2.736-3.73-3.146-8.715a.896.896 0 0 0-1.497-.591c-5.28 4.795-8.178 11.39-8.286 18.815 0 0-2.198-1.854-3.393-5.303-.322-.929-1.566-1.106-2.106-.285-.104.158-.2.316-.287.47-4.074 7.206-6.033 15.957-4.222 24.1 3.03 13.634 23.067 17.446 33.675 8.876 10.378-8.384 7.08-23.99-1.7-33.574z'
                        />
                        <path
                            fill='#FCD703'
                            d='M42.376 34.133c-.862-.943-2.202-2.216-2.772.658-.194.976-.647 1.73-1.035 2.336-.419-2.121-1.678-4.317-2.864-5.798-.444-.554-2.02-2.756-2.322-6.44a.661.661 0 0 0-1.105-.437c-3.897 3.544-6.036 8.418-6.116 13.904 0 0-1.622-1.37-2.504-3.919-.237-.686-1.156-.817-1.555-.21a6.111 6.111 0 0 0-.211.346c-3.007 5.326-4.454 11.793-3.116 17.81C21.01 62.46 35.8 65.277 43.63 58.943c7.66-6.196 5.225-17.728-1.255-24.81z'
                        />
                        <path
                            fill='#E58B77'
                            d='M23.473 18.302a4.805 4.805 0 0 0 0-7.458 4.805 4.805 0 0 0 0 7.458z'
                        />
                        <path
                            fill='#FCD703'
                            d='M54.448 34.649a4.805 4.805 0 0 0 0-7.457c-1.083.88-2.364 5.534 0 7.457z'
                        />
                        <path
                            fill='#EFCC02'
                            d='M11.303 32.49a3.463 3.463 0 0 0 0-5.375c-.78.635-1.703 3.988 0 5.375z'
                        />
                        <path
                            fill='#FF7B49'
                            d='M40.13 20.82a3.463 3.463 0 0 0 1.482-5.166 3.463 3.463 0 0 0-1.482 5.167z'
                        />
                    </g>
                </g>
                <g transform='translate(1 9)'>
                    <mask
                        id='prefix__abzeichen-d'
                        fill='#fff'
                    >
                        <use href='#prefix__abzeichen-c' />
                    </mask>
                    <g mask='url(#prefix__abzeichen-d)'>
                        <path
                            fill='#F2937E'
                            fillRule='nonzero'
                            d='M41.911 50.554c0-2.991-1.488-5.426-5.24-7.937-2.359-1.579-4.52-4.194-5.305-6.872a.64.64 0 0 0-1.105-.23c-3.556 4.253.326 10.21-1.201 11.86-.932 1.006-2.345-.688-3.24-3.297-.192-.562-.988-.567-1.185-.007-.812 2.297-1.277 4.638-1.277 6.483 0 5.512 4.153 9.98 9.276 9.98 5.124 0 9.277-4.468 9.277-9.98z'
                        />
                    </g>
                </g>
                <g transform='translate(1 9)'>
                    <mask
                        id='prefix__abzeichen-f'
                        fill='#fff'
                    >
                        <use href='#prefix__abzeichen-e' />
                    </mask>
                    <g mask='url(#prefix__abzeichen-f)'>
                        <g transform='translate(16.786 53.574)'>
                            <path
                                fill='#A28577'
                                fillRule='nonzero'
                                d='M4.908 12.075a4.135 4.135 0 0 1-4.79-3.36 4.141 4.141 0 0 1 3.354-4.798L18.621.26a3.891 3.891 0 0 1 4.746 3.106 4.41 4.41 0 0 1-3.31 5.052L4.908 12.075z'
                            />
                            <path
                                fill='#7B6357'
                                d='M10.915 3.82l9.151-2.112a.486.486 0 0 1 .219.946l-9.152 2.112a.486.486 0 0 1-.218-.946zM4.923 8.837l9.152-2.111a.486.486 0 0 1 .219.946L5.142 9.784a.486.486 0 0 1-.219-.947z'
                            />
                            <path
                                fill='#896F62'
                                d='M2.592 6.007l4.26-.983a.486.486 0 0 1 .219.946l-4.26.983a.486.486 0 0 1-.22-.946z'
                            />
                            <path
                                fill='#DBB19C'
                                fillRule='nonzero'
                                d='M27.78 12.14c2.305 0 4.175-1.885 4.175-4.21 0-2.323-1.87-4.207-4.176-4.207H13.657a4.21 4.21 0 1 0 0 8.416h14.122z'
                            />
                            <rect
                                width='10.364'
                                height='1'
                                x='12.037'
                                y='5.503'
                                fill='#A68575'
                                rx='.5'
                            />
                            <rect
                                width='6.963'
                                height='1'
                                x='21.267'
                                y='9.064'
                                fill='#A68575'
                                rx='.5'
                            />
                            <rect
                                width='3.401'
                                height='1'
                                x='25.316'
                                y='5.503'
                                fill='#C49E8C'
                                rx='.5'
                            />
                            <rect
                                width='5.344'
                                height='1'
                                x='13.657'
                                y='9.064'
                                fill='#C49E8C'
                                rx='.5'
                            />
                        </g>
                    </g>
                </g>
                <path
                    className='stroke-primary'
                    strokeWidth='3.474'
                    d='M33.63 10.772L64.64 24.59v24.526c0 7.947-3.443 18.555-13.277 27.641-10.378 9.583-16.59 11.282-17.733 11.526-1.143-.244-7.356-1.944-17.724-11.526C6.06 67.67 2.619 57.063 2.619 49.117h0V24.59L33.63 10.772z'
                />
                <path
                    fill='#000'
                    fillOpacity='.05'
                    fillRule='nonzero'
                    d='M33.63 9v80.927s6.724-.802 18.83-11.98c10.258-9.478 13.798-20.552 13.798-28.83V23.538L33.63 9z'
                />
                <path
                    fill='#FCD703'
                    fillRule='nonzero'
                    d='M45.855 30.041a.972.972 0 0 1 0-1.942.972.972 0 0 1 0 1.942z'
                />
                <path
                    fill='#F2937E'
                    fillRule='nonzero'
                    d='M8.935 47.521a.972.972 0 0 1 0-1.942.972.972 0 0 1 0 1.942z'
                />
                <path
                    fill='#FCD703'
                    fillRule='nonzero'
                    d='M29.5 19.682a.972.972 0 0 1 0-1.942.972.972 0 0 1 0 1.942z'
                />
                <path
                    fill='#FE9100'
                    fillRule='nonzero'
                    d='M58 51.406a.972.972 0 0 1 0-1.942.972.972 0 0 1 0 1.942z'
                />
                <path
                    fill='#F7BA69'
                    fillRule='nonzero'
                    d='M109.63 9L77 23.54v25.576c0 8.279 3.539 19.353 13.806 28.83 12.097 11.18 18.824 11.981 18.824 11.981s6.724-.802 18.83-11.98c10.258-9.478 13.798-20.552 13.798-28.83V23.538L109.63 9z'
                />
                <g fillRule='nonzero'>
                    <g transform='rotate(45 27.836 143.648)'>
                        <rect
                            width='18.091'
                            height='18.128'
                            fill='#FFF'
                            rx='2.084'
                        />
                        <g
                            fill='#494949'
                            transform='translate(2.915 3.075)'
                        >
                            <ellipse
                                cx='2.024'
                                cy='2.023'
                                rx='2.024'
                                ry='2.023'
                            />
                            <ellipse
                                cx='10.121'
                                cy='2.023'
                                rx='2.024'
                                ry='2.023'
                            />
                            <ellipse
                                cx='2.024'
                                cy='10.116'
                                rx='2.024'
                                ry='2.023'
                            />
                            <ellipse
                                cx='10.121'
                                cy='10.116'
                                rx='2.024'
                                ry='2.023'
                            />
                        </g>
                    </g>
                    <g transform='rotate(45 17.774 167.94)'>
                        <rect
                            width='18.091'
                            height='18.128'
                            fill='#FFF'
                            rx='2.084'
                        />
                        <g
                            fill='#494949'
                            transform='translate(3.032 3.075)'
                        >
                            <ellipse
                                cx='2.024'
                                cy='2.023'
                                rx='2.024'
                                ry='2.023'
                            />
                            <ellipse
                                cx='10.121'
                                cy='2.023'
                                rx='2.024'
                                ry='2.023'
                            />
                            <ellipse
                                cx='2.024'
                                cy='10.116'
                                rx='2.024'
                                ry='2.023'
                            />
                            <ellipse
                                cx='10.121'
                                cy='10.116'
                                rx='2.024'
                                ry='2.023'
                            />
                        </g>
                    </g>
                    <g transform='rotate(45 3.61 133.613)'>
                        <rect
                            width='18.091'
                            height='18.128'
                            fill='#FFF'
                            rx='2.084'
                        />
                        <g
                            fill='#494949'
                            transform='translate(2.915 3.075)'
                        >
                            <ellipse
                                cx='2.024'
                                cy='2.023'
                                rx='2.024'
                                ry='2.023'
                            />
                            <ellipse
                                cx='10.121'
                                cy='2.023'
                                rx='2.024'
                                ry='2.023'
                            />
                            <ellipse
                                cx='2.024'
                                cy='10.116'
                                rx='2.024'
                                ry='2.023'
                            />
                            <ellipse
                                cx='10.121'
                                cy='10.116'
                                rx='2.024'
                                ry='2.023'
                            />
                        </g>
                    </g>
                    <g transform='rotate(45 -6.452 157.905)'>
                        <rect
                            width='18.091'
                            height='18.128'
                            fill='#FFF'
                            rx='2.084'
                        />
                        <g
                            fill='#494949'
                            transform='translate(3.032 3.075)'
                        >
                            <ellipse
                                cx='2.024'
                                cy='2.023'
                                rx='2.024'
                                ry='2.023'
                            />
                            <ellipse
                                cx='10.121'
                                cy='2.023'
                                rx='2.024'
                                ry='2.023'
                            />
                            <ellipse
                                cx='2.024'
                                cy='10.116'
                                rx='2.024'
                                ry='2.023'
                            />
                            <ellipse
                                cx='10.121'
                                cy='10.116'
                                rx='2.024'
                                ry='2.023'
                            />
                        </g>
                    </g>
                </g>
                <path
                    stroke='#FFF'
                    strokeLinecap='round'
                    strokeWidth='.7'
                    d='M123.96 24.214v1.541m0 3.083v1.542m1.196-1.826l1.048 1.047m-3.441-1.067l-1.046 1.046m3.439-3.417l1.048-1.046m-3.441 1.026l-1.046-1.047m3.939 2.356h1.542m-4.935-.001h-1.542M97.566 68.886v1.541m0 3.083v1.542m1.195-1.826l1.048 1.047m-3.44-1.067l-1.047 1.046m3.44-3.418l1.047-1.045m-3.44 1.026l-1.047-1.047m3.94 2.355h1.542m-4.935 0h-1.542m34.896-41.92v.963m0 1.927v.964m.776-1.113l.598.598m-2.15-.611l-.598.598m2.149-2.136l.599-.598m-2.15.585l-.598-.598m2.434 1.444h.964m-3.084 0h-.964m-24.371 42.649v.963m0 1.927v.964m.776-1.114l.598.599m-2.15-.611l-.598.598m2.15-2.136l.598-.598m-2.149.585l-.599-.598m2.434 1.444h.964m-3.084 0h-.964M86.878 33.764v1.156m0 2.312v1.156m.898-1.369l.784.785m-2.58-.801l-.784.785m2.579-2.562l.785-.785m-2.58.769l-.785-.785m2.955 1.766h1.157m-3.701.001h-1.157m40.321 30.266v1.156m0 2.312v1.156m.897-1.368l.785.784m-2.58-.8l-.784.785m2.579-2.563l.785-.785m-2.58.769l-.785-.785m2.954 1.767h1.157m-3.7 0h-1.157'
                />
                <path
                    fill='#FFF'
                    fillRule='nonzero'
                    d='M95.047 28.307l2.186.725a.948.948 0 0 1 .598 1.224.992.992 0 0 1-1.243.614l-2.186-.725a.952.952 0 0 1-.598-1.224.992.992 0 0 1 1.243-.614zm8.453-4.975l-.476-2.008a.94.94 0 0 1 .705-1.146.971.971 0 0 1 1.018.426 1 1 0 0 1 .133.312l.476 2.008a.94.94 0 0 1-.705 1.147.979.979 0 0 1-1.151-.74zm-4.571-.287l1.811 1.946a.966.966 0 0 1-.073 1.365.982.982 0 0 1-1.379-.038l-1.81-1.946a.96.96 0 0 1 .072-1.365.982.982 0 0 1 1.379.038z'
                />
                <path
                    className='stroke-primary'
                    strokeWidth='3.474'
                    d='M109.63 10.772l31.01 13.818v24.526c0 7.947-3.443 18.555-13.277 27.641-10.378 9.583-16.59 11.282-17.733 11.526-1.143-.244-7.356-1.944-17.724-11.526-9.844-9.087-13.286-19.694-13.286-27.64h0V24.59l31.011-13.818z'
                />
                <path
                    fill='#000'
                    fillOpacity='.05'
                    fillRule='nonzero'
                    d='M109.63 9v80.927s6.724-.802 18.83-11.98c10.258-9.478 13.798-20.552 13.798-28.83V23.538L109.63 9z'
                />
                <g
                    filter='url(#prefix__abzeichen-g)'
                    transform='translate(229 10)'
                    style={{ mixBlendMode: 'luminosity' }}
                >
                    <mask
                        id='prefix__abzeichen-i'
                        fill='#fff'
                    >
                        <use href='#prefix__abzeichen-h' />
                    </mask>
                    <use
                        fill='#F0F3BD'
                        fillRule='nonzero'
                        href='#prefix__abzeichen-h'
                    />
                    <g mask='url(#prefix__abzeichen-i)'>
                        <path
                            fill='#5F5F5F'
                            d='M20.25 53.136h24.786c5.458 0 9.882 4.424 9.882 9.882V81h-44.55V63.018c0-5.458 4.424-9.882 9.882-9.882z'
                        />
                        <path
                            fill='#989898'
                            d='M25.434 52.65l7.457 12.15 7.457-13.932z'
                        />
                        <path
                            fill='#828282'
                            d='M18.792 43.74l-4.119 3.894a1.296 1.296 0 0 0 .211 2.045l4.474 2.754c.572.352.78 1.082.48 1.683l-2.007 4.019a1.296 1.296 0 0 0 .734 1.803L32.562 64.8l-9.517-23.166-4.253 2.106zm27.783 0l4.12 3.894a1.296 1.296 0 0 1-.212 2.045l-4.474 2.754a1.296 1.296 0 0 0-.48 1.683l2.009 4.02a1.296 1.296 0 0 1-.732 1.804L32.89 64.8l9.432-23.166 4.252 2.106z'
                        />
                        <path
                            fill='#164355'
                            d='M30.678 58.806c-.27 0-1.208 5.883-2.814 17.649l5.259 3.735 4.461-3.735-2.389-17.649h-4.517z'
                        />
                        <path
                            fill='#0B3241'
                            d='M28.188 53.136h9.396v1.782a4.698 4.698 0 1 1-9.396 0v-1.782z'
                        />
                        <path
                            fill='#E3A68C'
                            d='M32.643 56.214c8.992 0 16.281-12.974 16.281-21.384s-7.289-19.764-16.28-19.764c-8.992 0-16.282 11.354-16.282 19.764s7.29 21.384 16.281 21.384z'
                        />
                        <path
                            fill='#E3A68C'
                            d='M48.924 40.014c1.879 0 3.402-3.218 3.402-5.304 0-2.086-1.523-4.902-3.402-4.902-1.879 0-3.402 2.816-3.402 4.902 0 2.086 1.523 5.304 3.402 5.304zm-32.562 0c1.879 0 3.402-3.218 3.402-5.304 0-2.086-1.523-4.902-3.402-4.902-1.879 0-3.402 2.816-3.402 4.902 0 2.086 1.523 5.304 3.402 5.304z'
                        />
                        <g fillRule='nonzero'>
                            <path
                                fill='#807A76'
                                d='M30.452 55.242a.97.97 0 0 1-.968-.973v-1.918c0-2.336 1.891-4.237 4.216-4.237a.97.97 0 0 1 .968.973.97.97 0 0 1-.968.973 2.288 2.288 0 0 0-2.28 2.29v1.919a.97.97 0 0 1-.968.973z'
                            />
                            <path
                                fill='#BA7052'
                                d='M27.376 63.057l-.07-1.478a.96.96 0 0 0-.965-.909h-4.153a.958.958 0 0 0-.953 1.107l.396 2.43c.33 2.023 2.095 3.509 4.167 3.509 2.044 0 3.795-1.448 4.155-3.437l1.771-9.778c.19-1.049-.626-2.013-1.704-2.013a1.73 1.73 0 0 0-1.65 1.19l-2.258 6.992'
                            />
                            <path
                                fill='#A35D45'
                                d='M30.07 52.488a1.65 1.65 0 0 0-.537.09 1.716 1.716 0 0 1 1.116 1.923l-1.72 9.778c-.318 1.806-1.749 3.166-3.495 3.4.176.024.356.037.538.037 1.984 0 3.684-1.448 4.033-3.437l1.72-9.778c.185-1.049-.607-2.013-1.654-2.013z'
                            />
                        </g>
                        <path
                            fill='#8E563F'
                            fillRule='nonzero'
                            d='M37.137 45.3c-.808-.557-1.572-1.074-2.39-1.074-1.038 0-1.748.55-2.185.914-.437-.364-1.157-.914-2.184-.914-.797 0-1.55.517-2.358 1.063-1.562 1.06-3.234 4.283 1.266 4.283 1.387 0 2.545-.321 3.276-.91.732.589 1.89.91 3.276.91 6.45 0 2.828-3.212 1.3-4.272z'
                        />
                        <path
                            fill='#8E563F'
                            d='M16.524 28.53v6.138l3.24-3.894zm33.21-.018v6.138l-3.24-3.893z'
                        />
                        <path
                            fill='#603A21'
                            d='M34.455 12.312a1.782 1.782 0 0 0-3.564 0h.891m17.876 16.2c-4.136 3.726-5.571 8.51-17.066 8.51-11.494 0-12.224-4.46-15.982-8.51h8.262'
                        />
                        <path
                            fill='#B2794F'
                            d='M49.633 28.512c0-9.126-7.398-16.524-16.524-16.524-9.126 0-16.524 7.398-16.524 16.524h8.262'
                        />
                        <path
                            fill='#704528'
                            d='M33.048 11.988c-9.126 0-16.524 7.398-16.524 16.524l6.147.018c.273-3.728 1.07-6.69 2.39-8.885 1.635-2.718 4.297-5.27 7.987-7.657zm.162 0c9.126 0 16.524 7.398 16.524 16.524l-6.147.018c-.273-3.728-1.07-6.69-2.39-8.885-1.635-2.718-4.297-5.27-7.987-7.657z'
                        />
                    </g>
                    <path
                        className='stroke-primary'
                        strokeWidth='3.24'
                        d='M32.644 1.774l31.022 13.83v24.548c0 7.955-3.445 18.572-13.283 27.666-10.382 9.591-16.597 11.292-17.74 11.537-1.143-.245-7.359-1.946-17.732-11.537C5.063 58.723 1.62 48.106 1.62 40.152h0V15.604l31.024-13.83z'
                        mask='url(#prefix__abzeichen-i)'
                    />
                    <path
                        fill='#000'
                        fillOpacity='.05'
                        fillRule='nonzero'
                        d='M32.644 0v81s6.727-.802 18.839-11.992c10.261-9.486 13.803-20.57 13.803-28.856v-25.6L32.644 0z'
                        mask='url(#prefix__abzeichen-i)'
                    />
                </g>
                <g
                    filter='url(#prefix__abzeichen-j)'
                    transform='translate(153 10)'
                    style={{ mixBlendMode: 'luminosity' }}
                >
                    <mask
                        id='prefix__abzeichen-l'
                        fill='#fff'
                    >
                        <use href='#prefix__abzeichen-k' />
                    </mask>
                    <use
                        fill='#8DCFEA'
                        fillRule='nonzero'
                        href='#prefix__abzeichen-k'
                    />
                    <g mask='url(#prefix__abzeichen-l)'>
                        <path
                            fill='#EAB8A2'
                            d='M19.906 21.903s-.27-.613-.962-.507c-.692.107-1.97 3.203-3.514 6.885 0 0-.532 1.254-.772 3.015-.24 1.761-1.863 8.219-1.863 9.606 0 1.388-.106 9.233-.32 10.354-.213 1.12-1.543 16.383-1.862 22.36-.102 1.895-1.113 8.493-2.448 16.618H27.88c-.049-6.943-.066-11.98-.02-12.295.16-1.067 3.142-4.323 4.206-12.648 0 0 2.236-3.575 2.343-5.07.106-1.494-14.503-13.235-14.503-13.235l-.599-4.402a17.76 17.76 0 0 1-.073-4.173l.672-6.688v-9.82z'
                        />
                        <path
                            fill='#AA7D69'
                            d='M25.013 62.092l6.886 3.194s1.188-1.938 1.862-3.564h-8.546l-.202.37m-2.268 4.814l2.592-2.332a16.607 16.607 0 0 0-.823-1.394c-.469.762-.931 1.513-1.07 1.73 0 0 .152 1.23-.699 1.996'
                        />
                        <path
                            fill='#EAB8A2'
                            d='M44.291 40.5s4.979.344 5.579.425c.599.08 1.12 1.3.808 2.149-.313.85-1.278 2.574-5.84 2.44l-.547-5.014zm3.889 17.687l-2.07 6.944s-.47.887-1.518.574c-1.047-.313-1.937-2.193-1.728-3.968.21-1.775 2.76-5.847 2.76-5.847l2.555 2.297z'
                        />
                        <path
                            fill='#EAB8A2'
                            d='M43.805 54.562s1.952 4.92 3.641 5.781c1.689.861 2.902.189 3.087-.484.184-.672.026-1.451-.37-2.554-.396-1.103-2.19-5.002-2.19-5.002s-.382-.78-1.226-1.183-2.124-1.062-2.124-1.062l-.818 4.504z'
                        />
                        <path
                            fill='#457081'
                            d='M46.073 16.329v46.398a3.956 3.956 0 0 1-.171 1.146l-.06.181a3.819 3.819 0 0 1-.891 1.4 4.014 4.014 0 0 1-.721.57h-.001a3.671 3.671 0 0 1-.324.178h-.001a3.715 3.715 0 0 1-.52.207h-.002a3.8 3.8 0 0 1-1.14.173H23.335c-.28 0-.552-.03-.815-.088l-.13-.03a3.876 3.876 0 0 1-1.102-.477 3.887 3.887 0 0 1-1.404-1.588 3.679 3.679 0 0 1-.257-.707v-.002a3.864 3.864 0 0 1-.122-.963V16.33c0-2.127 1.716-3.855 3.831-3.855h18.908c2.116 0 3.83 1.728 3.83 3.855'
                        />
                        <path
                            fill='#457081'
                            d='M45.911 27.864v-5.346c.358 0 .648.338.648.754v3.837c0 .417-.29.755-.648.755M19.42 25.272h.085v-2.916h-.085c-.31 0-.563.32-.563.714v1.488c0 .394.252.714.563.714m0 3.564h.085V25.92h-.085c-.31 0-.563.32-.563.715v1.486c0 .395.252.715.563.715'
                        />
                        <path
                            fill='#FFF'
                            d='M45.263 16.125v46.32a3.068 3.068 0 0 1-.131.887l-.056.164a2.9 2.9 0 0 1-.407.746 2.892 2.892 0 0 1-.285.328 2.967 2.967 0 0 1-.535.426l-.032.02c-.066.041-.133.08-.204.116l-.049.022a2.642 2.642 0 0 1-.363.147l-.042.013c-.1.03-.192.054-.283.075a3.2 3.2 0 0 1-.61.059H23.311a2.965 2.965 0 0 1-1.59-.457l-.018-.012a2.411 2.411 0 0 1-.184-.126 3.157 3.157 0 0 1-.69-.726 2.998 2.998 0 0 1-.217-.38 2.906 2.906 0 0 1-.19-.505l-.012-.045a3.048 3.048 0 0 1-.095-.752v-46.32a3.004 3.004 0 0 1 2.997-3.003h18.956a3 3 0 0 1 2.995 3.003'
                        />
                        <path
                            fill='#C4C4C4'
                            d='M23.34 29.484a1.405 1.405 0 0 1-1.405-1.405v-2.374c0-.776.63-1.405 1.405-1.405h2.374c.776 0 1.405.629 1.405 1.405v2.374c0 .776-.629 1.405-1.405 1.405H23.34z'
                        />
                        <path
                            fill='#FCD703'
                            d='M33.814 29.484H31.44a1.405 1.405 0 0 1-1.405-1.405v-2.374c0-.776.63-1.405 1.405-1.405h2.374c.776 0 1.405.629 1.405 1.405v2.374c0 .776-.629 1.405-1.405 1.405'
                        />
                        <path
                            fill='#9BC2D2'
                            d='M41.914 29.484H39.54a1.405 1.405 0 0 1-1.405-1.405v-2.374c0-.776.63-1.405 1.405-1.405h2.374c.776 0 1.405.629 1.405 1.405v2.374c0 .776-.629 1.405-1.405 1.405'
                        />
                        <path
                            fill='#33CFAE'
                            d='M25.714 37.422H23.34a1.405 1.405 0 0 1-1.405-1.405v-2.374c0-.776.63-1.405 1.405-1.405h2.374c.776 0 1.405.629 1.405 1.405v2.374c0 .776-.629 1.405-1.405 1.405'
                        />
                        <path
                            className='fill-primary'
                            d='M33.814 37.422H31.44a1.405 1.405 0 0 1-1.405-1.405v-2.374c0-.776.63-1.405 1.405-1.405h2.374c.776 0 1.405.629 1.405 1.405v2.374c0 .776-.629 1.405-1.405 1.405'
                        />
                        <path
                            fill='#E58B77'
                            d='M41.914 37.422H39.54a1.405 1.405 0 0 1-1.405-1.405v-2.374c0-.776.63-1.405 1.405-1.405h2.374c.776 0 1.405.629 1.405 1.405v2.374c0 .776-.629 1.405-1.405 1.405'
                        />
                        <path
                            fill='#33CFAE'
                            d='M25.714 61.074H23.34c-.776 0-1.405-.63-1.405-1.405v-2.374c0-.776.63-1.405 1.405-1.405h2.374c.776 0 1.405.63 1.405 1.405v2.374c0 .776-.629 1.405-1.405 1.405'
                        />
                        <path
                            className='fill-primary'
                            d='M33.814 61.074H31.44c-.776 0-1.405-.63-1.405-1.405v-2.374c0-.776.63-1.405 1.405-1.405h2.374c.776 0 1.405.63 1.405 1.405v2.374c0 .776-.629 1.405-1.405 1.405'
                        />
                        <path
                            fill='#FCD703'
                            d='M41.914 61.074H39.54c-.776 0-1.405-.63-1.405-1.405v-2.374c0-.776.63-1.405 1.405-1.405h2.374c.776 0 1.405.63 1.405 1.405v2.374c0 .776-.629 1.405-1.405 1.405'
                        />
                        <path
                            fill='#5CB4D9'
                            d='M25.714 44.874H23.34a1.405 1.405 0 0 1-1.405-1.405v-2.374c0-.776.63-1.405 1.405-1.405h2.374c.776 0 1.405.629 1.405 1.405v2.374c0 .776-.629 1.405-1.405 1.405'
                        />
                        <path
                            fill='#F18900'
                            d='M33.814 44.874H31.44a1.405 1.405 0 0 1-1.405-1.405v-2.374c0-.776.63-1.405 1.405-1.405h2.374c.776 0 1.405.629 1.405 1.405v2.374c0 .776-.629 1.405-1.405 1.405'
                        />
                        <path
                            fill='#457081'
                            d='M35.411 14.418h-5.537c-.931 0-1.686-.798-1.686-1.782h8.91c0 .984-.755 1.782-1.687 1.782'
                        />
                        <path
                            fill='#EAB8A2'
                            d='M16.143 60.795l7.934-8.888s1.492-1.624 3.142-.453c1.651 1.171 3.595 3.14 3.595 3.14s1.518.692 1.997.932c.48.239 1.704.878 2.077 1.942s.08 2.05-.386 2.302c0 0-.546 1.36-2.73 1.49-2.183.13-4.526-.532-4.526-.532s-.346.226-.719.186c0 0-1.824 3.273-2.117 3.778 0 0 .24 2.13-1.624 2.608-1.864.479-11.875-.798-6.643-6.505'
                        />
                    </g>
                    <g mask='url(#prefix__abzeichen-l)'>
                        <path
                            stroke='#FFF'
                            strokeWidth='.972'
                            d='M8.829 27.054a2.027 2.027 0 0 1-2.025-2.025c0-1.117.908-2.025 2.025-2.025s2.025.908 2.025 2.025a2.027 2.027 0 0 1-2.025 2.025zM55.323 42.12a2.027 2.027 0 0 1-2.025-2.025c0-1.117.908-2.025 2.025-2.025s2.025.908 2.025 2.025a2.027 2.027 0 0 1-2.025 2.025z'
                        />
                        <path
                            fill='#FFF'
                            fillRule='nonzero'
                            d='M50.868 20.169a.973.973 0 0 1 0-1.944.973.973 0 0 1 0 1.944zm7.128 25.029a.973.973 0 0 1 0-1.944.973.973 0 0 1 0 1.944zM9.882 48.6a.973.973 0 0 1 0-1.944.973.973 0 0 1 0 1.944z'
                        />
                        <path
                            stroke='#FFF'
                            strokeLinecap='round'
                            strokeWidth='.972'
                            d='M54.961 26.568l.076 4.05m-2.063-2.057l4.05.064M7.819 38.88l.076 4.05m-2.063-2.057l4.05.064m4.564-22.551l-2.81 2.918m-.004-2.914l2.818 2.91'
                        />
                    </g>
                    <path
                        className='stroke-primary'
                        strokeWidth='3.24'
                        d='M32.644 1.774l31.022 13.83v24.548c0 7.955-3.445 18.572-13.283 27.666-10.382 9.591-16.597 11.292-17.74 11.537-1.143-.245-7.359-1.946-17.732-11.537C5.063 58.723 1.62 48.106 1.62 40.152h0V15.604l31.024-13.83z'
                        mask='url(#prefix__abzeichen-l)'
                    />
                    <path
                        fill='#000'
                        fillOpacity='.05'
                        fillRule='nonzero'
                        d='M32.644 0v81s6.727-.802 18.839-11.992c10.261-9.486 13.803-20.57 13.803-28.856v-25.6L32.644 0z'
                        mask='url(#prefix__abzeichen-l)'
                    />
                </g>
            </g>
        </svg>
  );
}
