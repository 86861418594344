import ActionTypes from 'shared/utilities/action-types';
import { store } from './store/store';

export default function scanResponseForReward(response) {
  const { dispatch } = store;
  const { user } = store.getState().auth;

  const { data } = response || {};
  const { result } = data;
  const { achievements } = result || {};

  if (achievements) {
    // TODO: Should be handled by backend
    const { campaign } = user || {};
    const {
      badges,
      level,
    } = campaign || {};

    if (!badges) delete achievements.badges;
    if (!level) {
      delete achievements.level;
      delete achievements.xp;
    }

    dispatch({ type: ActionTypes.REWARD_SUCCESS, newRewards: achievements });
  }
  return response;
}
