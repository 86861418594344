
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Categories() {
  const { t, i18n } = useTranslation('tourTranslations');
  const isPt = i18n.language === 'pt';

  return (
        <svg
            width='313'
            height='219'
            viewBox='0 0 313 219'
            direction='ltr'
        >
            <defs>
                <rect
                    id='prefix__kategorien-b'
                    width='299'
                    height='205'
                    x='0'
                    y='0'
                    rx='3.379'
                />
                <filter
                    id='prefix__kategorien-a'
                    width='107.7%'
                    height='111.2%'
                    x='-3.8%'
                    y='-4.6%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dy='2'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='3.5'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
                    />
                </filter>
                <path
                    id='prefix__kategorien-c'
                    d='M3.379 0H295.62A3.379 3.379 0 0 1 299 3.379v148.655H0V3.379A3.379 3.379 0 0 1 3.379 0z'
                />
                <path
                    id='prefix__kategorien-e'
                    d='M0 0h325v152.128H0z'
                />
                <path
                    id='prefix__kategorien-g'
                    d='M0 0h172.181v84.362H0z'
                />
                <path
                    id='prefix__kategorien-i'
                    d='M0 0h71.223v84.362H0z'
                />
                <path
                    id='prefix__kategorien-k'
                    d='M.086.138h75.199v42.304H.086z'
                />
            </defs>
            <g
                fill='none'
                fillRule='evenodd'
            >
                <g transform='translate(7 5)'>
                    <use
                        fill='#000'
                        filter='url(#prefix__kategorien-a)'
                        xlinkHref='#prefix__kategorien-b'
                    />
                    <use
                        fill='#FFF'
                        xlinkHref='#prefix__kategorien-b'
                    />
                    <mask
                        id='prefix__kategorien-d'
                        fill='#fff'
                    >
                        <use xlinkHref='#prefix__kategorien-c'/>
                    </mask>
                    <use
                        fill='#FFF'
                        xlinkHref='#prefix__kategorien-c'
                    />
                    <g mask='url(#prefix__kategorien-d)'>
                        <g transform='translate(-13)'>
                            <mask
                                id='prefix__kategorien-f'
                                fill='#fff'
                            >
                                <use xlinkHref='#prefix__kategorien-e'/>
                            </mask>
                            <g mask='url(#prefix__kategorien-f)'>
                                <path
                                    fill='#99CCD3'
                                    fillRule='nonzero'
                                    d='M0 0h325v152.128H0z'
                                />
                                <path
                                    fill='#D9EEFD'
                                    fillRule='nonzero'
                                    d='M76.41 6.915h172.181v84.362H76.41z'
                                />
                                <path
                                    fill='#F2F2F2'
                                    fillRule='nonzero'
                                    d='M129.309 39.415V37.34h-16.596v2.075h-3.803v49.096h24.202V39.415zm46.675-2.075v-1.383h-.691v-1.383h-3.458v1.383h-.691v1.383h-.692v51.862h6.224V37.34z'
                                />
                                <path
                                    fill='#E6E6E6'
                                    fillRule='nonzero'
                                    d='M96.463 76.755h11.755V88.51H96.463z'
                                />
                                <path
                                    fill='#CCC'
                                    fillRule='nonzero'
                                    d='M118.59 57.394v-2.075h-10.372v2.075h-2.766v6.915h-4.84v24.202h20.744V57.394z'
                                />
                                <path
                                    fill='#E6E6E6'
                                    fillRule='nonzero'
                                    d='M203.644 54.628v-2.766h-2.075v-3.458h-15.904v3.458h-2.075v2.766h-5.531v33.191h31.117V54.628z'
                                />
                                <path
                                    fill='#CCC'
                                    fillRule='nonzero'
                                    d='M175.293 72.606h20.745v16.596h-20.745z'
                                />
                                <path
                                    fill='#F2F2F2'
                                    fillRule='nonzero'
                                    d='M239.601 55.319v2.766h-4.84v31.117h11.063V55.319z'
                                />
                                <path
                                    fill='#E6E6E6'
                                    fillRule='nonzero'
                                    d='M233.378 34.574v-1.383h-2.075V32.5h-2.074v-.691h-4.149v.691h-2.075v-.691h-2.074v-1.383h-2.075v1.383h-3.457v1.382h-.692v1.383h-1.383v53.245h21.437V34.574z'
                                />
                                <g
                                    opacity='.4'
                                    transform='translate(76.41 6.915)'
                                >
                                    <mask
                                        id='prefix__kategorien-h'
                                        fill='#fff'
                                    >
                                        <use xlinkHref='#prefix__kategorien-g'/>
                                    </mask>
                                    <g
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        mask='url(#prefix__kategorien-h)'
                                    >
                                        <path d='M79.78-26.51l16.423 9.482L30.76 96.32l-16.423-9.482zm17.816 11.78l4.282 2.473L36.436 101.09l-4.282-2.472zm10.17 3.95l1.781 1.028-65.441 113.348-1.782-1.028zM34.55-48.947l10.78 6.224-65.442 113.348-10.78-6.224zm12.563 7.252l4.192 2.42-65.442 113.348-4.191-2.42zm105.17 10.619L177.181-16.7l-75.067 130.02-24.898-14.375zm27.267 15.74l4.162 2.403-75.067 130.02-4.162-2.403z'/>
                                    </g>
                                </g>
                                <path
                                    fill='#C7B299'
                                    fillRule='nonzero'
                                    d='M77.793 6.915h3.457v84.362h-3.457z'
                                />
                                <path
                                    fill='#EAD7C2'
                                    fillRule='nonzero'
                                    d='M248.59 6.915v3.457H76.41V6.915zm0 80.904v3.457H76.41v-3.457z'
                                />
                                <path
                                    fill='#EAD7C2'
                                    fillRule='nonzero'
                                    d='M245.133 6.915h3.457v84.362h-3.457zm-168.723 0h3.457v84.362H76.41z'
                                />
                                <path
                                    fill='#D9EEFD'
                                    fillRule='nonzero'
                                    d='M255.16 6.915h73.298v84.362H255.16z'
                                />
                                <path
                                    fill='#F2F2F2'
                                    fillRule='nonzero'
                                    d='M264.944 39.415V37.34h21.609v2.075h4.962v49.096H260V39.415z'
                                />
                                <path
                                    fill='#CCC'
                                    fillRule='nonzero'
                                    d='M307.712 88.511h-15.299V76.756h15.3z'
                                />
                                <path
                                    fill='#E6E6E6'
                                    fillRule='nonzero'
                                    d='M278.912 57.394v-2.075h13.502v2.075h3.595v6.915h6.31v24.202h-27.02V57.394z'
                                />
                                <g
                                    opacity='.4'
                                    transform='translate(256.543 6.915)'
                                >
                                    <mask
                                        id='prefix__kategorien-j'
                                        fill='#fff'
                                    >
                                        <use xlinkHref='#prefix__kategorien-i'/>
                                    </mask>
                                    <g
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        mask='url(#prefix__kategorien-j)'
                                    >
                                        <path d='M79.78-26.51l16.423 9.482L30.76 96.32l-16.423-9.482zm17.816 11.78l4.282 2.473L36.436 101.09l-4.282-2.472zm10.17 3.95l1.781 1.028-65.441 113.348-1.782-1.028zM34.55-48.947l10.78 6.224-65.442 113.348-10.78-6.224zm12.562 7.252l4.192 2.42-65.442 113.348-4.191-2.42z'/>
                                    </g>
                                </g>
                                <path
                                    fill='#C7B299'
                                    fillRule='nonzero'
                                    d='M256.543 6.915H260v84.362h-3.457z'
                                />
                                <path
                                    fill='#EAD7C2'
                                    fillRule='nonzero'
                                    d='M328.457 6.915v3.457H255.16V6.915zm0 80.905v3.456H255.16V87.82z'
                                />
                                <path
                                    fill='#EAD7C2'
                                    fillRule='nonzero'
                                    d='M255.16 6.915h3.457v84.362h-3.457z'
                                />
                                <path
                                    fill='#CCC'
                                    fillRule='nonzero'
                                    d='M89.237 180.185h-3.371l-1.47-62.165h6.327z'
                                />
                                <path
                                    fill='#E6E6E6'
                                    fillRule='nonzero'
                                    d='M256.231 197.074h3.268l-11.393-79.504h-6.137zm-187.462 0h-3.268l11.393-79.504h6.137z'
                                />
                                <path
                                    fill='#CCC'
                                    fillRule='nonzero'
                                    d='M234.553 180.185h3.371l1.47-62.165h-6.31z'
                                />
                                <path
                                    fill='#DDBF9E'
                                    fillRule='nonzero'
                                    d='M70.376 102.081l-24.79 13.432h233.828l-26.605-13.432z'
                                />
                                <path
                                    fill='#F1E5D8'
                                    fillRule='nonzero'
                                    d='M45.586 115.531h233.827v3.508H45.586z'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M124.693 110.396h75.614l-5.687-5.099h-64.257z'
                                />
                                <path
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    d='M127.874 109.463l3.595-3.233h62.062l3.595 3.233z'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M162.656 110.016v-4.322h-.312v4.322m-3.613.017l.502-4.339-.294-.017-.501 4.322m-3.614.052l1.003-4.34-.276-.051-1.02 4.321m-3.613.07l1.504-4.322-.277-.087-1.521 4.322m-3.631.104l2.006-4.322-.26-.121-2.005 4.322m-3.648.138l2.507-4.322-.259-.155-2.507 4.322m-3.665.155l3.008-4.322-.242-.155-3.008 4.322m-3.682.172l3.527-4.321-.225-.191-3.527 4.322m-3.682.19l4.011-4.321-.208-.191-4.028 4.322m34.661-4.218l.502 4.339.294-.034-.502-4.322m3.112.034l1.003 4.34.293-.07-1.02-4.321m3.129.069l1.504 4.322.294-.087-1.521-4.322m3.146.104l2.006 4.322.259-.121-2.005-4.322m3.146.138l2.507 4.322.259-.155-2.507-4.322m3.164.155l3.008 4.322.242-.155-3.008-4.322m3.163.173l3.527 4.321.225-.19-3.527-4.322m3.198.191l4.011 4.321.225-.19-4.028-4.322'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M128.738 107.993h67.835v-.294h-67.835'
                                />
                                <path
                                    fill='#333'
                                    fillRule='nonzero'
                                    d='M124.693 110.396h75.614v1h-75.614z'
                                />
                                <path
                                    fill='#B3B3B3'
                                    fillRule='nonzero'
                                    d='M5.1 101.148h35.491v77.96H5.1z'
                                />
                                <path
                                    fill='#999'
                                    fillRule='nonzero'
                                    d='M7.572 104.864h30.564v23.073H7.572zm0 24.116h30.564v23.075H7.572z'
                                />
                                <path
                                    fill='#E6E6E6'
                                    fillRule='nonzero'
                                    d='M17.287 109.653H28.04v5.715H17.287zm0 23.148H28.04v5.715H17.287z'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M7.71 67.783h9.301v33.857H7.71z'
                                />
                                <path
                                    className='fill-primary'
                                    fillRule='nonzero'
                                    d='M9.698 69.564h5.324v17.612H9.698z'
                                />
                                <path
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    d='M12.36 98.97a1.383 1.383 0 1 0 0-2.767 1.383 1.383 0 0 0 0 2.766'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M12.36 98.624a1.037 1.037 0 1 0 0-2.075 1.037 1.037 0 0 0 0 2.075m16.32-30.841h9.301v33.857H28.68z'
                                />
                                <path
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    d='M33.33 98.97a1.383 1.383 0 1 0 0-2.767 1.383 1.383 0 0 0 0 2.766'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M33.33 98.624a1.037 1.037 0 1 0 0-2.075 1.037 1.037 0 0 0 0 2.075'
                                />
                                <path
                                    fill='#F0F3BD'
                                    fillRule='nonzero'
                                    d='M30.668 69.564h5.324v17.612h-5.324z'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M18.255 67.783h9.301v33.857h-9.301z'
                                />
                                <path
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    d='M22.906 98.97a1.383 1.383 0 1 0 0-2.767 1.383 1.383 0 0 0 0 2.766'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M22.906 98.624a1.037 1.037 0 1 0 0-2.075 1.037 1.037 0 0 0 0 2.075'
                                />
                                <path
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    d='M20.243 69.564h5.324v17.612h-5.324z'
                                />
                                <path
                                    fill='#C7B299'
                                    fillRule='nonzero'
                                    d='M51.464 33.174a16.959 16.959 0 0 1-.622 4.53 17.806 17.806 0 0 1-.882 2.385c-3.528 7.718-12.307 11.536-20.358 8.855-8.052-2.682-12.788-11-10.984-19.293 1.803-8.292 9.567-13.892 18.005-12.987 8.438.905 14.837 8.024 14.841 16.51z'
                                />
                                <circle
                                    cx='34.842'
                                    cy='33.181'
                                    r='15.057'
                                    fill='#998675'
                                    fillRule='nonzero'
                                    transform='rotate(-45 34.842 33.181)'
                                />
                                <circle
                                    cx='34.851'
                                    cy='33.174'
                                    r='14.435'
                                    fill='#FFF'
                                    fillRule='nonzero'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M35.82 33.174a.968.968 0 1 1-1.937.035.968.968 0 0 1 1.936-.035z'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M35.024 33.624a.45.45 0 0 1-.45-.45V22.923a.45.45 0 0 1 .45-.432c.239 0 .432.193.432.432v10.251a.45.45 0 0 1-.432.45z'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M35.024 33.624h-7.918a.45.45 0 0 1-.432-.45c0-.238.194-.432.432-.432h7.918c.239 0 .432.194.432.432a.45.45 0 0 1-.432.45zm0-12.62a.156.156 0 0 1-.173-.173v-1.14c0-.096.077-.174.173-.174a.19.19 0 0 1 .173.173v1.141a.173.173 0 0 1-.173.173zm-6.206 1.573a.156.156 0 0 1-.138-.086l-.571-.986a.173.173 0 1 1 .294-.173l.57.986a.173.173 0 0 1-.069.242l-.086.017zm-4.564 4.46h-.104l-.968-.57a.173.173 0 0 1-.069-.242.173.173 0 0 1 .242-.052l.985.553a.19.19 0 0 1 .052.242.173.173 0 0 1-.138.07zm-1.746 6.154h-1.124a.173.173 0 0 1 0-.345h1.124a.173.173 0 1 1 0 .345zm.588 6.759a.121.121 0 0 1-.139-.086.173.173 0 0 1 .052-.242l.986-.553a.173.173 0 0 1 .242.052.173.173 0 0 1-.07.242l-.985.57-.086.018zm4.874 4.997h-.086a.173.173 0 0 1-.052-.242l.554-.986a.19.19 0 0 1 .242-.069.173.173 0 0 1 .069.242l-.57.986a.19.19 0 0 1-.156.069zm6.725 1.884a.173.173 0 0 1-.172-.173v-1.123a.19.19 0 0 1 .172-.19.173.173 0 0 1 .173.19v1.123a.156.156 0 0 1-.173.173zm6.76-1.711a.156.156 0 0 1-.139-.087l-.57-.985a.19.19 0 0 1 .052-.242.173.173 0 0 1 .242.069l.57.968a.173.173 0 0 1-.069.242l-.086.035zm4.995-4.875l-.086-.035-.985-.57a.173.173 0 0 1-.052-.242.173.173 0 0 1 .225-.052l.985.57a.156.156 0 0 1 .07.225.173.173 0 0 1-.156.104zm1.885-6.725h-1.124a.173.173 0 0 1-.172-.173.156.156 0 0 1 .172-.173h1.124a.173.173 0 0 1 .19.173.19.19 0 0 1-.19.173zm-2.697-6.206a.156.156 0 0 1-.155-.087.156.156 0 0 1 .069-.224l.968-.57a.19.19 0 0 1 .242.051.19.19 0 0 1-.052.242l-.985.57-.087.018zm-4.46-4.564l-.086-.035a.173.173 0 0 1-.07-.242l.571-.968a.173.173 0 0 1 .242-.069.19.19 0 0 1 .052.242l-.57.986a.156.156 0 0 1-.139.086z'
                                />
                                <path
                                    fill='#F7946F'
                                    fillRule='nonzero'
                                    d='M235.936 133.578l-12.274 17.582 1.556 4.926s2.11 5.238-2.23 9.854c-1.003-3.112 1.262-5.117.64-7.088-3.147 1.85-5.895 7.918-4.097 12.914-.9.017-2.11-1.072-2.11-1.072a8.004 8.004 0 0 1-4.027-4.27c-1.4-3.561-1.66-3.388-.778-7.744.743-3.7 9.75-11.185 8.09-9.336l9.249-24.565 5.981 8.8z'
                                />
                                <path
                                    className='fill-primary'
                                    fillRule='nonzero'
                                    d='M237.872 78.138s-6.62 17.91-6.62 41.11c-4.616 8.54-8.955 10.58-8.108 10.752.847.173 10.752 11.859 10.752 11.859l7.676-14.331s1.365-26.709 2.213-34.454c2.385-21.85-5.913-14.936-5.913-14.936z'
                                />
                                <path
                                    fill='#000'
                                    fillRule='nonzero'
                                    d='M237.872 78.138s-6.62 17.91-6.62 41.11c-4.616 8.54-8.955 10.58-8.108 10.752.847.173 10.752 11.859 10.752 11.859l7.676-14.331s1.365-26.709 2.213-34.454c2.385-21.85-5.913-14.936-5.913-14.936z'
                                    opacity='.1'
                                />
                                <path
                                    fill='#701212'
                                    fillRule='nonzero'
                                    d='M276.993 66.47s-5.307-15.265 5.826-26.364c-20.088-5.255-35.162-5.532-35.162-5.532s5.515 23.183-3.838 28.179c5.86 6.379 16.751 13.83 33.174 3.716z'
                                />
                                <path
                                    fill='#044D6B'
                                    fillRule='nonzero'
                                    d='M251.01 291.307l-3.94.657s-12.344-120.803-9.924-141.496c11.894-4.754 18.826 15.524 18.826 15.524l-2.61 62.977-2.351 62.338z'
                                />
                                <path
                                    fill='#044D6B'
                                    fillRule='nonzero'
                                    d='M245.842 131.608l-8.696 18.86s-.259 17.46 23.46 17.184c23.717-.277 20.312-11.358 20.07-13.865-.242-2.506-7.192-20.796-7.192-20.796s-22.508-5.532-27.642-1.383z'
                                />
                                <path
                                    fill='#F0F3BD'
                                    fillRule='nonzero'
                                    d='M243.525 131.608s13.588 7.208 31.065 1.383c4.426-22.456 3.873-52.83 3.873-52.83s-14.418-11.928-39.64-2.213c.83 27.729 4.702 53.66 4.702 53.66z'
                                />
                                <path
                                    fill='#F7946F'
                                    fillRule='nonzero'
                                    d='M246.17 74.197l13.726 18.445 14.418-18.722s-7.347-1.66-7.209-5.687c.138-4.028 2.49-14.694 2.49-14.694l-10.96 1.676s-.554 7.07-1.384 12.05c-.83 4.978-7.623 7.347-11.08 6.932z'
                                />
                                <path
                                    fill='#000'
                                    fillRule='nonzero'
                                    d='M269.715 56.132s-2.022-1.47-11.91-.64c.328 1.763.293 5.774-.26 9.007 6.846.38 7.607-.761 10.269-2.282 1.14-4.944 1.901-6.085 1.901-6.085z'
                                    opacity='.1'
                                />
                                <path
                                    fill='#F7946F'
                                    fillRule='nonzero'
                                    d='M252.013 39.052c-2.299 6.189-2.282 18.342 6.034 24.617 13.259-1.228 18.238-17.15 18.238-17.15s2.766-12.083.933-14.244c-1.832-2.16-18.808-7.433-19.05-7.485-.242-.052-6.155 14.262-6.155 14.262z'
                                />
                                <path
                                    fill='#F7946F'
                                    fillRule='nonzero'
                                    d='M272.274 54.04c4.045-.346 6.586-1.59 6.102-4.132-.484-2.541-3.284-.847-3.284-.847l-2.818 4.979z'
                                />
                                <path
                                    fill='#841E1A'
                                    fillRule='nonzero'
                                    d='M251.408 38.672c5.999-.796 7.762-7.676 7.9-10.442 1.366 7.295 11.86 12.412 18.532 12.032 2.905 2.628-1.262 5.22-2.074 6.742-.813 1.521-2.334 8.039 2.04 10.96.95-5.03 4.08-8.765 6.863-9.542 2.783-.778 2.956-10.615 1.158-12.084-1.798-1.47-3.647-3.613-3.7-7.002-.051-3.388-7.277-11.72-14.33-9.888-8.281-3.578-13.45-3.457-15.887 4.218-6.812 1.452-7.469 4.702-7.883 8.315 2.731 3.112 2.662 9.163 2.662 9.163l4.616 1.296.103-3.768z'
                                />
                                <path
                                    fill='#4A263E'
                                    fillRule='nonzero'
                                    d='M258.824 54.195c.588.104 1.159.19 1.712.242.54.058 1.084.064 1.625.018a4.477 4.477 0 0 0 1.504-.38l.346-.19.31-.243.295-.294.276-.328c-.017.138-.052.277-.086.432l-.19.415-.294.38-.173.173-.173.156c-.52.365-1.13.58-1.763.622a5.22 5.22 0 0 1-1.798-.19 4.633 4.633 0 0 1-1.59-.813z'
                                />
                                <ellipse
                                    cx='256.943'
                                    cy='42.32'
                                    stroke='#59081D'
                                    strokeWidth='.911'
                                    rx='4.668'
                                    ry='4.218'
                                    transform='rotate(-78.3 256.943 42.32)'
                                />
                                <ellipse
                                    cx='270.058'
                                    cy='45.01'
                                    stroke='#59081D'
                                    strokeWidth='.911'
                                    rx='4.668'
                                    ry='4.218'
                                    transform='rotate(-78.3 270.058 45.01)'
                                />
                                <path
                                    stroke='#59081D'
                                    strokeWidth='.911'
                                    d='M261.072 43.166c1.487-1.677 3.855-1.694 4.858 1.003'
                                />
                                <path
                                    fill='#4A263E'
                                    fillRule='nonzero'
                                    d='M254.676 41.61c.381.206.774.39 1.175.554a6.88 6.88 0 0 0 1.158.345c.406.064.817.093 1.228.087l1.296-.035c-.378.248-.8.418-1.244.502a3.388 3.388 0 0 1-1.383.034 3.769 3.769 0 0 1-1.28-.519 3.371 3.371 0 0 1-.95-.968zm12.481 2.732c.423.098.839.225 1.245.38a9.94 9.94 0 0 0 1.158.363c.392.088.791.14 1.193.156.415.034.83.017 1.279.052-.393.215-.82.361-1.262.432a3.561 3.561 0 0 1-1.348-.052 4.512 4.512 0 0 1-1.228-.519 6.707 6.707 0 0 1-1.037-.812z'
                                />
                                <path
                                    fill='#F7946F'
                                    fillRule='nonzero'
                                    d='M250.924 48.352c-3.596-2.178-4.788-4.996-3.959-6.966 1.332-3.112 4.34 1.054 4.34 1.054l-.38 5.912z'
                                />
                                <path
                                    fill='#4A263E'
                                    fillRule='nonzero'
                                    d='M263.32 43.218c.048.227.071.46.068.692a3.51 3.51 0 0 1-.103.726 4.132 4.132 0 0 1-.675 1.279c-.29.376-.626.713-1.002 1.003-.382.295-.793.55-1.228.76l.19-.415c.035.121.07.277.121.415l.156.415c.114.277.253.543.415.795.167.249.364.475.588.675.23.211.493.386.778.518a2.3 2.3 0 0 1-.951-.26 2.852 2.852 0 0 1-.813-.604 3.907 3.907 0 0 1-.605-.83 5.836 5.836 0 0 1-.224-.467 3.388 3.388 0 0 1-.173-.501l-.07-.26.277-.138a8.92 8.92 0 0 0 1.09-.691c.351-.247.681-.525.985-.83.296-.305.552-.648.76-1.02a4.17 4.17 0 0 0 .415-1.262z'
                                />
                                <path
                                    className='fill-primary'
                                    fillRule='nonzero'
                                    d='M280.745 135.532l6.223 13.83c-6.777-2.075-18.67.691-18.67.691l1.798-11.755'
                                />
                                <path
                                    fill='#F7946F'
                                    fillRule='nonzero'
                                    d='M298.032 122.394l-12.706 11.945-4.927-1.21s-5.273-1.746-9.525 2.818c3.129.778 4.944-1.573 6.915-1.09-1.625 3.199-7.4 6.293-12.395 4.841.034.882 1.175 1.988 1.175 1.988a7.866 7.866 0 0 0 4.443 3.682c3.578 1.159 3.423 1.418 7.64.277 3.58-.95 10.356-10.269 8.645-8.523l16.267-9.196-5.532-5.532z'
                                />
                                <path
                                    fill='#4A263E'
                                    fillRule='nonzero'
                                    d='M267.9 141.98c1.435-.104 2.87-.207 4.288-.432a18.376 18.376 0 0 0 4.166-1.141c-.63.364-1.296.66-1.988.882-.696.218-1.407.385-2.127.5-1.435.22-2.89.283-4.339.191zm.415 1.573c.65.187 1.315.314 1.988.38.673.058 1.35.064 2.023.018a25.402 25.402 0 0 0 2.005-.242l2.023-.363a27.98 27.98 0 0 1-1.971.553c-.673.141-1.354.24-2.04.294a9.076 9.076 0 0 1-2.057-.07 6.604 6.604 0 0 1-1.97-.57zm2.126 1.573l1.816.121c.603.058 1.209.081 1.815.07a12.43 12.43 0 0 0 1.798-.19l1.78-.398c-.569.232-1.152.428-1.746.587a8.073 8.073 0 0 1-1.815.26 16.336 16.336 0 0 1-1.85-.121c-.605-.087-1.21-.19-1.798-.329z'
                                />
                                <path
                                    className='fill-primary'
                                    fillRule='nonzero'
                                    d='M280.364 78.19s-8.16 11.271-.31 20.693c10.372 12.447 17.857 17.616 19.36 19.362-7.156 5.808-9.87 5.186-8.988 5.532 3.457 1.383 11.755 9.68 11.755 9.68l7.675-13.518a4.72 4.72 0 0 0 .57-5.17c-3.664-7-17.926-20.726-21.262-27.451-3.752-7.572-8.8-9.128-8.8-9.128zm-45.258 68.406a59.07 59.07 0 0 1 15.213 2.074l.692-18.445s-1.107-51.724-2.766-54.784c-9.145.848-9.422 2.507-9.422 2.507l.277 12.758s-3.976 5.636-3.89 10.528c.087 4.892 4.166 8.315 4.166 8.315l4.15 22.059-8.42 14.988z'
                                />
                                <path
                                    fill='#4A263E'
                                    fillRule='nonzero'
                                    d='M242.886 109.359l6.292.743 3.146.312 3.164.293a93 93 0 0 0 3.146.208c1.055.052 2.11.104 3.164.12 1.054.018 2.092 0 3.146-.068a16.06 16.06 0 0 0 3.13-.45 13.76 13.76 0 0 1-3.113.71c-1.06.126-2.114.195-3.163.207-2.126.051-4.253-.052-6.362-.208s-4.218-.432-6.31-.726a76.143 76.143 0 0 1-6.24-1.141z'
                                    opacity='.1'
                                />
                                <path
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    d='M258.91 91.536c.623 1.262-7.761-16.786-11.08-18.86-6.24 1.244-9.422 10.25-9.422 10.25s14.971-2.903 20.503 8.61z'
                                />
                                <path
                                    className='fill-primary'
                                    fillRule='nonzero'
                                    d='M280.485 135.273c-1.244-2.853-2.143-4.737-2.143-4.737l1.798-16.25s3.924-2.818 3.06-8.955c-.104-.812-.243-8.972-.243-8.972l2.559-13.035s-5.895-6.154-6.656-6.154c-.76 0-7.416 36.857-7.416 36.857l-1.573 25.879 10.614-4.633z'
                                />
                                <path
                                    fill='#FFF'
                                    fillRule='nonzero'
                                    d='M260.864 91.813c2.905-11.79 11.773-21.35 14.418-19.967 2.645 1.383 5.964 10.666 5.964 10.666s-10.269-4.287-20.382 9.3z'
                                />
                                <path
                                    fill='#4D4D4D'
                                    fillRule='nonzero'
                                    d='M201.362 94.336h-77.724a1.711 1.711 0 0 1-1.728-1.711v-6.483h81.18v6.483a1.712 1.712 0 0 1-1.728 1.711'
                                />
                                <path
                                    fill='#333'
                                    fillRule='nonzero'
                                    d='M203.09 86.142h-81.18V40.297c0-.87.704-1.574 1.573-1.574H201.5c.878 0 1.59.712 1.59 1.59v45.83zm-27.4 15.282h-4.72v-7.088h-16.63v7.088h-5.013a2.403 2.403 0 0 0-2.403 2.386h31.17a2.386 2.386 0 0 0-2.404-2.386'
                                />
                                <path
                                    fill='#F1F1F1'
                                    fillRule='nonzero'
                                    d='M164.142 90.222a1.677 1.677 0 1 1-1.677-1.66 1.66 1.66 0 0 1 1.677 1.66'
                                />
                                <path
                                    fill='#E9EDF0'
                                    fillRule='nonzero'
                                    d='M124.9 41.282h75.199v42.304H124.9z'
                                />
                                <g transform='translate(124.814 41.144)'>
                                    <mask
                                        id='prefix__kategorien-l'
                                        fill='#fff'
                                    >
                                        <use xlinkHref='#prefix__kategorien-k'/>
                                    </mask>
                                    <g
                                        fillRule='nonzero'
                                        mask='url(#prefix__kategorien-l)'
                                    >
                                        <g opacity='.6'>
                                            <path
                                                className='fill-primary'
                                                d='M78.225 4.65C64.309 22.854 55.907 25.395 50.72 24.168c-5.964-1.435-6.31-7.607-14.47-10.286-8.799-2.905-13.31 2.627-23.51 1.054-4.2-.656-10.406-2.68-17.408-9.456l.208 37.358h83.515l-.83-38.188z'
                                            />
                                            <path
                                                className='fill-primary'
                                                d='M77.585 11.997c-15.973 14.625-24.496 15.87-29.37 13.847-3.026-1.262-4.98-3.89-10.287-5.047-5.687-1.228-9.87.38-13.847 1.262-5.359 1.21-13.38 1.555-24.565-2.715v23.39H77.17l.415-30.737z'
                                            />
                                            <path
                                                fill='#FFF'
                                                d='M77.585 11.997c-15.973 14.625-24.496 15.87-29.37 13.847-3.026-1.262-4.98-3.89-10.287-5.047-5.687-1.228-9.87.38-13.847 1.262-5.359 1.21-13.38 1.555-24.565-2.715v23.39H77.17l.415-30.737z'
                                                opacity='.3'
                                            />
                                            <path
                                                className='fill-primary'
                                                d='M75.7 14.504c-3.094 3.803-9.542 10.58-19.931 14.487-3.855 1.452-15.058 5.653-26.45 1.47-3.042-1.125-3.233-1.799-6.085-2.94-8.609-3.474-17.858-1.78-25.81 1.262v13.951h78.173l.104-28.23z'
                                            />
                                            <path
                                                fill='#000'
                                                d='M75.7 14.504c-3.094 3.803-9.542 10.58-19.931 14.487-3.855 1.452-15.058 5.653-26.45 1.47-3.042-1.125-3.233-1.799-6.085-2.94-8.609-3.474-17.858-1.78-25.81 1.262v13.951h78.173l.104-28.23z'
                                                opacity='.1'
                                            />
                                        </g>
                                        <path
                                            fill='#FFF'
                                            d='M31.584-47.177l10.66 6.155-64.704 112.07-10.66-6.155zm12.441 7.183l4.148 2.395-64.704 112.07-4.147-2.395z'
                                            opacity='.4'
                                        />
                                    </g>
                                </g>
                                <path
                                    fill='#999'
                                    d='M157.227 139.473a5.722 5.722 0 0 1-5.722 5.722h-4.961v9.664c0 .745-.604 1.348-1.349 1.348h-.743v5.29a1.193 1.193 0 0 1-1.175 1.176h-6.12c-.65 0-1.176-.526-1.176-1.176v-5.29h-.76a1.331 1.331 0 0 1-1.331-1.348v-9.664h-4.927a5.722 5.722 0 0 1-5.722-5.722h33.986z'
                                />
                                <path
                                    fill='gray'
                                    fillRule='nonzero'
                                    d='M133.907 145.195h2.092v11.012h-.847a1.245 1.245 0 0 1-1.245-1.244v-9.768z'
                                />
                                <path
                                    fill='#333'
                                    fillRule='nonzero'
                                    d='M94.7 83.55a4.01 4.01 0 0 1 3.785-5.36h20.831s13.485 0 20.953 29.82c0 0 4.65 25.897 23.925 25.033h8.367a3.51 3.51 0 0 1 3.51 3.509 3.51 3.51 0 0 1-3.51 3.526h-50.116c-9.925-.004-18.272-7.444-19.413-17.304l-1.642-14.28a35.059 35.059 0 0 0-.657-3.768c-.917-3.907-3.13-13-6.034-21.177z'
                                />
                                <path
                                    fill='#1A1A1A'
                                    fillRule='nonzero'
                                    d='M94.7 83.55l19.033-.502c6.863-.173 13.276 3.526 16.423 9.629 1.504 2.939 3.025 6.707 4.339 11.565 0 0-24.099-18.324-34.73-3.544L94.699 83.55zm11.478 47.798s11.323 6.621 37.479-1.21c0 0 4.304 5.895 13.138 6.518h10.01a7.935 7.935 0 0 1 5.635 2.368l.934.95-50.929.104s-10.597.208-16.267-8.73z'
                                    opacity='.5'
                                />
                                <path
                                    className='fill-primary'
                                    fillRule='nonzero'
                                    d='M162.5 35.923l-20.606 9.18v16.18c0 5.238 2.23 12.24 8.712 18.221 7.641 7.07 11.894 7.59 11.894 7.59s4.253-.52 11.894-7.59c6.482-5.981 8.712-12.983 8.712-18.22V45.101l-20.606-9.18z'
                                />
                                <path
                                    fill='#000'
                                    fillRule='nonzero'
                                    d='M162.5 37.496l19.172 8.557v15.23c0 2.87-.813 10.286-8.264 17.166-3.7 3.423-6.517 5.204-8.246 6.086a13.19 13.19 0 0 1-2.662 1.089 13.19 13.19 0 0 1-2.662-1.09c-1.712-.881-4.547-2.662-8.246-6.085-7.451-6.88-8.264-14.296-8.264-17.166v-15.23l19.172-8.557m0-1.573l-20.606 9.18v16.18c0 5.238 2.23 12.24 8.712 18.221 7.641 7.07 11.894 7.59 11.894 7.59s4.253-.52 11.894-7.59c6.482-5.981 8.712-12.983 8.712-18.22V45.101l-20.606-9.18z'
                                    opacity='.1'
                                />
                                <path
                                    fill='#000'
                                    fillRule='nonzero'
                                    d='M162.5 87.093s4.253-.519 11.894-7.589c6.482-5.981 8.712-12.983 8.712-18.22V45.101l-20.606-9.18v51.171z'
                                    opacity='.1'
                                />
                                <path
                                    fill='#35B9AB'
                                    fillRule='nonzero'
                                    d='M87.456 66.66S81.475 81.25 82.408 98l3.389-3.042s.553-20.33 1.66-28.3'
                                />
                                <path
                                    fill='#35B9AB'
                                    fillRule='nonzero'
                                    d='M78.709 66.366s-2.94 15.49 1.33 31.687l2.715-3.647s-3.527-20.019-4.045-28.04'
                                />
                                <path
                                    fill='#027E71'
                                    fillRule='nonzero'
                                    d='M78.709 66.366l-.052.276c-.138 7.244.104 14.59 1.106 21.765.346 2.455.796 4.927 1.59 7.295l.053.035c.017 0 .051-.035.034-.052-1.521-4.478-1.936-9.353-2.282-14.055-.31-4.2-.432-8.419-.432-12.637 0-.708 0-1.417.017-2.126a5.241 5.241 0 0 1-.034-.501m8.747.294l-.086.207c-1.262 7.382-2.438 14.798-3.233 22.249-.225 1.988-.45 3.993-.398 5.998 0 .035.018.035.052.035.035 0 .035 0 .035-.035-.087-4.131.64-8.35 1.193-12.43.61-4.39 1.279-8.77 2.005-13.137.086-.606.19-1.21.294-1.816.104-.605.086-.726.138-1.071'
                                />
                                <path
                                    fill='#ACB0B4'
                                    fillRule='nonzero'
                                    d='M86.246 103.81h-8.09l-1.245-11.634h10.995z'
                                />
                                <path
                                    fill='#ABABAE'
                                    d='M221.691 85.347l-1.296-.12.432-1.35c.035-.259.778-.224.743.07l.121 1.4z'
                                />
                                <path
                                    fill='#59595B'
                                    d='M221.934 86.35l-1.92-.19.156-1.574a.207.207 0 0 1 .087-.172.225.225 0 0 1 .173-.07l1.452.139a.26.26 0 0 1 .155.103.19.19 0 0 1 .052.19l-.155 1.574z'
                                />
                                <path
                                    fill='#383839'
                                    d='M218.597 100.664l1.521-15.559 1.919.19-1.504 15.541z'
                                />
                                <path
                                    fill='#404041'
                                    d='M219.548 100.75L221 85.191l1.037.104-1.504 15.541z'
                                />
                                <path
                                    fill='#ABABAE'
                                    d='M222.037 85.382l.018-.225-2.127-.208-.034-.017-.139.052-.052.121-.397 4.149.207.017.19-.017.104-.968-.173-.277.26-2.645.051-.121.121-.052z'
                                />
                                <path
                                    fill='#E4E6E7'
                                    fillRule='nonzero'
                                    d='M211.714 86.703l1.083-.3 4.603 16.599-1.083.3z'
                                />
                                <path
                                    fill='#CFD0D2'
                                    fillRule='nonzero'
                                    d='M212.263 86.557l.964-.267 4.603 16.598-.963.268z'
                                />
                                <path
                                    fill='#43525A'
                                    d='M213.152 87.197l.103-.035.087.294-.087.035-.397.103-.796.225 1.02 3.63h-.103c-.035-.017-.035 0-.277-.691-.242-.692-1.02-2.922-1.089-3.233-.311-1.763-.432-3.146-.812-5.134-.035-.138.587-.329.64-.19l1.71 4.996z'
                                />
                                <path
                                    fill='#666'
                                    d='M216.782 84.102l-.294 13.761-1.348-.035.294-13.743.691-.259z'
                                />
                                <path
                                    fill='#4D4D4D'
                                    d='M216.782 84.102l-.294 13.761-.674-.017.311-14.158z'
                                />
                                <path
                                    fill='#E5CDB3'
                                    d='M215.434 84.085l.743-3.267.605 3.284c-.07.104-.138.312-.242.312-.104 0-.121-.502-.415-.502s-.225.502-.415.502c-.19 0-.276-.329-.276-.329z'
                                />
                                <path
                                    fill='#4D4D4D'
                                    d='M215.952 81.803l.225-.985.19 1.002z'
                                />
                                <path
                                    fill='#44535B'
                                    d='M219.306 85.07l-2.42 13.519-1.331-.225 2.437-13.536.709-.138z'
                                />
                                <path
                                    fill='#37454E'
                                    d='M219.306 85.07l-2.42 13.519-.657-.104 2.489-13.933z'
                                />
                                <path
                                    fill='#E5CDB3'
                                    d='M217.992 84.828l1.227-3.111.087 3.336c-.07.104-.173.294-.294.277-.121-.017-.035-.519-.311-.57-.277-.053-.311.483-.502.449-.19-.035-.207-.38-.207-.38z'
                                />
                                <path
                                    fill='#414141'
                                    d='M218.856 82.668l.363-.951.035 1.02z'
                                />
                                <path
                                    fill='#ACB0B4'
                                    d='M211.855 90.222h9.802l-.104 13.536-9.646.052z'
                                />
                                <path
                                    fill='#818487'
                                    fillRule='nonzero'
                                    d='M212.979 91.311h1v10.609h-1zm1.728 0h1v10.609h-1zm1.712 0h1v10.609h-1zm1.729 0h1v10.609h-1zm1.728 0h1v10.609h-1z'
                                />
                            </g>
                        </g>
                    </g>
                </g>
                <tspan
                    x='10.136'
                    y={`${isPt ? '174.169' : '184.169'}`}
                >
                    {t('SvgText:Categories1')}
                </tspan>
                {isPt && (
                    <tspan
                        x='10.136'
                        y='195.169'
                    >
                        {t('SvgText:Categories1a')}
                    </tspan>
                )}
            </g>
        </svg>
  );
}
