import getObjectByKeyValue from 'elearning/helpers/get/get-object-by-key-value';
import getModuleKey from 'elearning/helpers/get/get-module-key';
import { PersonalizedModule } from 'flamingo-e-learning-platform/training/types/module.type';
import getCategoryByCategoryKey from './get-category-by-key';
import { CategoryType } from '../types';

/**
 * Get module from categories by moduleKey and categoryKey
 *
 * @param {Array} categories
 * @param {String} moduleKey
 * @param {String} categoryKey
 * @returns module
 *
 * @category elearning
 * @subcategory helpers
 * @exports getModuleByKeyFromCategories

 */

export default function getModuleByKeyFromCategories(
  categories: CategoryType[],
  moduleKey: string,
  categoryKey: string,
  userLanguage: string
): PersonalizedModule | undefined {
  const categoryByKey = getCategoryByCategoryKey(
    categories,
    categoryKey,
    userLanguage
  );
  // Delete this fallback with the refactoring of modules to be shown based on category id, not category NAME
  const defaultCategoryKey = getCategoryByCategoryKey(
    categories,
    categoryKey,
    'en'
  );
  const moduleByKey =
    categoryByKey?.modules?.find(
      (currentModule) => getModuleKey(currentModule) === moduleKey
    ) ||
    defaultCategoryKey?.modules?.find(
      (currentModule) => getModuleKey(currentModule) === moduleKey
    );

  return (
    moduleByKey ||
    getObjectByKeyValue(categoryByKey?.modules ?? [], 'id', moduleKey)
  );
}
