
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Level() {
  const { t } = useTranslation('tourTranslations');

  return (
        <svg
            width='299'
            height='194'
            viewBox='0 0 299 194'
            direction='ltr'
        >
            <defs>
                <linearGradient
                    id='levellernfortschritt-c'
                    x1='-10.466%'
                    x2='52.682%'
                    y1='88.568%'
                    y2='47.399%'
                >
                    <stop
                        offset='0%'
                        stopColor='#06668D'
                    />
                    <stop
                        offset='5.11%'
                        stopColor='#086F90'
                    />
                    <stop
                        offset='18.24%'
                        stopColor='#148494'
                    />
                    <stop
                        offset='32.03%'
                        stopColor='#219495'
                    />
                    <stop
                        offset='46.45%'
                        stopColor='#2CA194'
                    />
                    <stop
                        offset='61.78%'
                        stopColor='#34A993'
                    />
                    <stop
                        offset='78.65%'
                        stopColor='#39AF93'
                    />
                    <stop
                        offset='100%'
                        stopColor='#3AB092'
                    />
                </linearGradient>
                <path
                    id='levellernfortschritt-b'
                    d='M43.3917433,0 L0,19.6509309 C0,19.6509309 0,45.569965 0,54.2199992 C0,65.4086024 4.70554362,80.3759319 18.3592714,93.1852838 C34.4461096,108.295011 43.3904681,109.378531 43.3904681,109.378531 C43.3904681,109.378531 52.3322762,108.295011 68.4318665,93.1852838 C82.071567,80.3759319 86.779661,65.4086024 86.779661,54.2199992 C86.779661,45.5712595 86.779661,19.6509309 86.779661,19.6509309 L43.3917433,0 Z'
                />
                <filter
                    id='levellernfortschritt-a'
                    width='149.6%'
                    height='139.3%'
                    x='-20.2%'
                    y='-16%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dx='4'
                        dy='4'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='6.5'
                    />
                    <feComposite
                        in='shadowBlurOuter1'
                        in2='SourceAlpha'
                        operator='out'
                        result='shadowBlurOuter1'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0'
                    />
                </filter>
                <path
                    id='levellernfortschritt-d'
                    d='M43.560873,0 L0.108082272,19.6167553 C0.108082272,19.6167553 0.108082272,45.4907129 0.108082272,54.1257036 C0.108082272,65.2948483 4.82024608,80.2361477 18.4931832,93.0232224 C34.6026538,108.106672 43.559596,109.188308 43.559596,109.188308 C43.559596,109.188308 52.5139843,108.106672 68.636225,93.0232224 C82.295115,80.2361477 87.0098328,65.2948483 87.0098328,54.1257036 C87.0098328,45.4920051 87.0098328,19.6167553 87.0098328,19.6167553 L43.560873,0 Z'
                />
            </defs>
            <g
                fill='none'
                fillRule='evenodd'
                transform='translate(0 9)'
            >
                <text
                    fill='#393939'
                    fontSize='12.655'
                >
                    <tspan
                        x='.847'
                        y='181.91'
                    >{t('SvgText:Level1')}</tspan>
                </text>
                <g transform='translate(.847 148.215)'>
                    <rect
                        width='254.915'
                        height='14.463'
                        x='42.938'
                        fill='#C7D2DA'
                        opacity='.4'
                        rx='3.616'
                    />
                    <rect
                        width='150.056'
                        height='14.463'
                        className='fill-primary'
                        rx='3.616'
                    />
                </g>
                <text
                    fill='#393939'
                    fontSize='16.271'
                    fontWeight='bold'
                >
                    <tspan
                        x='.847'
                        y='137'
                    >{t('SvgText:Level2')}</tspan>
                </text>
                <g transform='translate(107.062 .87)'>
                    <g fillRule='nonzero'>
                        <use
                            fill='#000'
                            filter='url(#levellernfortschritt-a)'
                            href='#levellernfortschritt-b'
                        />
                        <use
                            fill='url(#levellernfortschritt-c)'
                            fillOpacity='.3'
                            href='#levellernfortschritt-b'
                        />
                    </g>
                    <mask
                        id='levellernfortschritt-e'
                        fill='#fff'
                    >
                        <use href='#levellernfortschritt-d' />
                    </mask>
                    <use
                        fill='#E4E3E3'
                        fillRule='nonzero'
                        href='#levellernfortschritt-d'
                    />
                    <rect
                        width='87.118'
                        height='109.379'
                        y='.619'
                        className='fill-primary'
                        mask='url(#levellernfortschritt-e)'
                    />
                </g>
            </g>
        </svg>
  );
}
