
// Packages
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import isEmail from "validator/lib/isEmail";
import { Redirect, useHistory } from "react-router";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

// Services
import { dispatchRemoveRegisterData } from "authentication/services";

// Hooks
import useForm from "shared/hooks/use-form";

// Utilities
import { getIdByReference } from "shared/utilities/reference-handling";
import { ALERT_TYPE } from "shared/utilities/modal-alert";
import { getDataFromSSOProfile } from "authentication/helpers/get";

// Components
import CustomFormGroup from "shared/components/custom-form-group";
import PasswordControl from "shared/components/password-control";
import Validators from "shared/utilities/validators";
import OptInLabel from "authentication/components/optin-label";
import CopyToClipboard from "shared/components/copy-to-clipboard";
import { UseLoginRequest } from "authentication/hooks/use-login-request";
import { useRegistrationRequest } from "authentication/hooks/use-registration-request";
import { useActivateAccount } from "authentication/hooks/use-activate-account";
import {
  getLanguageName,
  getNameAndDerivativeNameFromCode,
  nonLatinLanguages,
  parseCodeLanguageToLanguageType,
  sortLanguages,
  SOSAFE_LANGUAGE_CODE_PARSER,
} from "shared/utilities/languages";
import { useModalContext } from "modal-context/modal-context.tsx";
import { Button, Text, Title } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { UseShowDecisionAlert } from "../../flamingo-e-learning-platform/utilities/modal-alert/use-show-decision-alert";

export default function SignUpForm(props) {
  const { t, i18n } = useTranslation([
    "translations",
    "messageTranslations",
    "languagesTranslations",
    "placeholderTranslations",
  ]);
  const { login } = UseLoginRequest();
  const history = useHistory();
  const { mutate: activateAccount } = useActivateAccount();
  const { close } = useModalContext();
  const { show } = UseShowDecisionAlert();

  const { register_type } = props;
  const [redirect, setRedirect] = useState("");
  const { mutate: registerUser } = useRegistrationRequest();

  const { loading, register_data, login_data, reference } = useSelector(
    (state) => state.auth,
    shallowEqual
  );
  const translationClass = i18n.language === "ar" ? "arab" : "";

  const codeLanguages = useMemo(() => {
    const languagesCode = register_data?.languages.languages ?? [];
    const languages = parseCodeLanguageToLanguageType(languagesCode);
    return sortLanguages(languages);
  }, [register_data, register_data?.languages.languages]);

  let registerType = register_type;
  if (!registerType) {
    if (register_data) {
      if (register_data.key) {
        registerType = "KEY";
      } else if (register_data.code) {
        registerType = "CODE";
      } else {
        registerType = "EMAIL";
      }
    }
  }

  const dispatch = useDispatch();
  useEffect(
    () => () => {
      dispatch(dispatchRemoveRegisterData());
    },
    []
  );

  const [
    { firstname, lastname, email, password1, password2, language, optIn, gender, key },
    handleChange,
  ] = useForm({
    firstname: "",
    lastname: "",
    email: "",
    password1: "",
    password2: "",
    language: "en",
    optIn: false,
    gender: "",
    key: "",

    ...getData(register_data, registerType, i18n),
  });

  const { sso } = register_data;
  const { method } = register_data;

  const password = login_data ? login_data.password : null;

  const { legal } = register_data || {};
  const { terms } = legal || {};

  useEffect(() => {
    const loginDirectly = (data) => {
      close();
      login(data);
    };
    const closeRedirect = () => {
      close();
      setRedirect("/");
    };
    switch (getIdByReference(reference)) {
      case "REGISTRATION_SUCCESS_CODE": {
        const data = {
          password,
          code: register_data.code,
        };
        show({
          title: <Title size="h2">{t("Thank you!")}</Title>,
          content: (
            <>
              <Trans i18nKey="messageTranslations:Your account has been created. Please remember the following password">
                <Title size="h3" c="#1971C2">
                  Your account has been created.
                </Title>
                <span style={{ fontWeight: 700 }}>Attention:</span>
                <Text>
                  Please remember the following password. Without entering this password you will
                  lose access to your account and your learning status.
                </Text>
              </Trans>
              <div className="d-flex mt-3" style={{ width: "100%" }}>
                <span className="mx-4" />
                <Text className="m-auto h1" data-testid="registration-code-password" c="#1971C2">
                  {password}
                </Text>
                <CopyToClipboard ariaLabel={t("Copy Password")} content={password} />
              </div>
            </>
          ),
          footer: (
            <>
              <Button variant="secondary" aria-label={t("Close")} onClick={() => closeRedirect()}>
                {t("Close")}
              </Button>
              <Button
                variant="primary"
                aria-label={t("Login directly")}
                onClick={() => loginDirectly(data)}
              >
                {t("Login directly")}
              </Button>
            </>
          ),
          type: ALERT_TYPE.SUCCESS,
        });
        break;
      }
      case "REGISTRATION_SUCCESS_KEY": {
        const data = {
          password: password2,
          email,
        };
        show({
          title: <Title size="h2">{t("Thank you!")}</Title>,
          content: <Text>{t("messageTranslations:You have been registered for E-Learning")}.</Text>,
          footer: (
            <>
              <Button
                variant="primary"
                aria-label={t("Login directly")}
                onClick={() => loginDirectly(data)}
              >
                {t("Login directly")}
              </Button>
              <Button variant="secondary" aria-label={t("Close")} onClick={() => closeRedirect()}>
                {t("Close")}
              </Button>
            </>
          ),
          type: ALERT_TYPE.SUCCESS,
        });
        break;
      }
      case "REGISTRATION_LOGIN_EXISTS_NOT_ACTIVE_1":
      case "REGISTRATION_LOGIN_EXISTS_NOT_ACTIVE_2":
        show({
          title: <Title size="h2">{t("Error")}</Title>,
          content: (
            <Text>
              {t(
                "The account already exists, but has not yet been activated! Please open the link sent to your email address to activate your account."
              )}
            </Text>
          ),
          footer: (
            <>
              <Button
                variant="primary"
                aria-label={t("Send again")}
                onClick={() => activateAccount({ email })}
              >
                {t("Send again")}
              </Button>
              <Button variant="secondary" aria-label={t("Close")} onClick={() => close()}>
                {t("Close")}
              </Button>
            </>
          ),
          type: ALERT_TYPE.ERROR,
        });
        break;
      default:
        break;
    }
  }, [reference, email, password]);

  if (!register_data && !login_data) {
    history.push("/");
    return null;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const { languageName, derivativeLanguage } = getNameAndDerivativeNameFromCode(language);
    const languageCode = derivativeLanguage ?? languageName;
    const sosafeLanguageCode = SOSAFE_LANGUAGE_CODE_PARSER.get(languageCode);

    let registerUserPayload = {
      firstname,
      lastname,
      optIn,
      language: sosafeLanguageCode ?? languageCode,
    };
    if (gender !== null) {
      registerUserPayload.gender = parseInt(gender, 10);
    }
    if (gender === "-1") registerUserPayload.gender = null;

    if (registerType === "CODE") {
      registerUserPayload = {
        ...registerUserPayload,
        code: register_data.code,
      };
    } else if (registerType === "SSO") {
      registerUserPayload = {
        ...registerUserPayload,
        email,
        sso,
        method,
      };
    } else if (registerType === "EMAIL") {
      registerUserPayload = {
        ...registerUserPayload,
        email,
        password: password2,
      };
    } else {
      registerUserPayload = {
        ...registerUserPayload,
        email,
        key,
        password: password2,
      };
    }

    registerUser(registerUserPayload);
  };

  const isValid = formIsValid(
    {
      firstname,
      lastname,
      email,
      password1,
      password2,
      language,
      optIn,
      gender,
      key,
    },
    registerType,
    terms
  );

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  const optionalJsx = registerType === "CODE" ? `(${t("Optional")})` : "";

  return (
    <Form onSubmit={(e) => onSubmit(e)} className="u-flex u-flexCol">
      <Form.Group>
        <Form.Label htmlFor="gender">{`${t("Salutation")} ${optionalJsx}`}</Form.Label>
        <Form.Control
          as="select"
          defaultValue={gender === null ? "" : gender}
          className="custom-select"
          id="gender"
          name="gender"
          onChange={(e) => handleChange({ gender: e.target.value })}
          autoComplete="honorific-prefix"
        >
          <option value="">{t("------")}</option>
          <option value={0}>{t("Mr.")}</option>
          <option value={1}>{t("Mrs./Ms.")}</option>
          <option value={2}>{t("Divers")}</option>
        </Form.Control>
      </Form.Group>
      <CustomFormGroup
        id="firstname"
        label={`${t("Firstname")} ${optionalJsx}`}
        isTrimOnBlur
        placeholder={t("placeholderTranslations:Firstname")}
        value={firstname}
        handleOnChange={(value) => handleChange(value)}
        valid={!!firstname && firstname !== ""}
        autoComplete="given-name"
      />
      <CustomFormGroup
        id="lastname"
        label={`${t("Lastname")} ${optionalJsx}`}
        isTrimOnBlur
        placeholder={t("placeholderTranslations:Lastname")}
        value={lastname}
        handleOnChange={(value) => handleChange(value)}
        valid={!!lastname && lastname !== ""}
        autoComplete="family-name"
      />
      <Form.Group>
        <Form.Label htmlFor="language">{t("Language")}</Form.Label>
        <Form.Control
          as="select"
          defaultValue={language}
          className="custom-select"
          id="language"
          name="language"
          onChange={(e) => handleChange({ language: e.target.value })}
          autoComplete="language"
        >
          {codeLanguages.length ? (
            codeLanguages.map(({ key: codeKey, value }) => (
              <option
                key={codeKey}
                value={codeKey}
                lang={codeKey}
                aria-label={nonLatinLanguages.has(codeKey) ? getLanguageName(codeKey) : undefined}
              >
                {value}
              </option>
            ))
          ) : (
            <option key={i18n.language} value={i18n.language}>
              {t(`languagesTranslations:${i18n.language}`)}
            </option>
          )}
        </Form.Control>
      </Form.Group>
      {(registerType === "EMAIL" || registerType === "KEY") && (
        <React.Fragment>
          <CustomFormGroup
            key="email"
            id="email"
            label={t("Email")}
            isTrimOnBlur
            placeholder={t("placeholderTranslations:Email")}
            value={email}
            handleOnChange={(value) => handleChange(value)}
            valid={!!email && email !== "" && isEmail(email)}
            disabled
            autoComplete="email"
          />
          <PasswordControl
            key="password1"
            name="password1"
            value={password1}
            onChange={(e) => handleChange({ password1: e.target.value })}
            secondPassword={password2}
            showHint
            newPassword
          />
          <PasswordControl
            key="password2"
            hideIndicator
            name="password2"
            formLabel={t("Repeat Password")}
            value={password2}
            onChange={(e) => handleChange({ password2: e.target.value })}
            firstPassword={password1}
            newPassword
          />
        </React.Fragment>
      )}
      {terms === 0 ? (
        <Form.Group>
          <Form.Check
            type="checkbox"
            id="optIn"
            label={
              <OptInLabel
                registerData={register_data}
                registerType={registerType}
                termsVersion={terms}
              />
            }
            checked={optIn}
            onChange={() => handleChange({ optIn: !optIn })}
          />
        </Form.Group>
      ) : (
        <OptInLabel registerData={register_data} registerType={registerType} termsVersion={terms} />
      )}
      <Button type="submit" disabled={!isValid} data-testid="register-button">
        {loading ? t("Signing up...") : t("Register")}
      </Button>
    </Form>
  );
}

SignUpForm.propTypes = {
  register_type: PropTypes.string,
  profile: PropTypes.shape({
    preferredLanguage: PropTypes.string,
    givenName: PropTypes.string,
    surname: PropTypes.string,
  }),
};

SignUpForm.defaultProps = {
  profile: null,
  register_type: "",
};

function formIsValid(data, registerType, terms) {
  let validations = [];
  if (registerType === "CODE") {
    validations = [];
  } else if (registerType === "SSO") {
    validations = [
      Validators.required(data.firstname).valid,
      Validators.required(data.lastname).valid,
    ];
  } else if (registerType === "EMAIL") {
    validations = [
      Validators.required(data.firstname).valid,
      Validators.required(data.lastname).valid,
      Validators.email(data.email).valid,
      Validators.required(data.password1).valid,
      Validators.required(data.password2).valid,
      Validators.password(data.password1).valid,
      Validators.matches(data.password1, data.password2).valid,
    ];
  } else {
    validations = [
      Validators.required(data.firstname).valid,
      Validators.required(data.lastname).valid,
      Validators.required(data.password1).valid,
      Validators.required(data.password2).valid,
      Validators.password(data.password1).valid,
      Validators.matches(data.password1, data.password2).valid,
    ];
  }
  if (terms === 0) {
    validations.push(Validators.required(data.optIn).valid);
  }
  return validations.every((item) => item);
}

function getInitialLang(register_data, i18nLanguage) {
  const languagesCode = register_data?.languages.languages ?? [];
  const languages = parseCodeLanguageToLanguageType(languagesCode);
  if (!languages?.length) return i18nLanguage;
  if (languages?.find(({ key }) => key === i18nLanguage)) {
    return i18nLanguage;
  }
  if (languages?.includes("en")) return "en";
  return languages[0].key;
}

function getData(register_data, registerType, i18n) {
  let data = {};
  if (register_data) {
    if (registerType === "CODE") {
      data = {
        language: getInitialLang(register_data, i18n.language),
        organization: -1,
        register_type: registerType,
        code: register_data.code,
      };
    } else if (registerType === "EMAIL") {
      data = {
        firstname: register_data.firstname || "",
        lastname: register_data.lastname || "",
        email: register_data.address || "",
        language: register_data.language || getInitialLang(register_data, i18n.language),
        gender: register_data.gender ?? null,
        register_type: registerType,
      };
    } else if (registerType === "KEY") {
      data = {
        firstname: register_data.firstname || "",
        lastname: register_data.lastname || "",
        email: register_data.address || "",
        key: register_data.key || "",
        language: register_data.language || getInitialLang(register_data, i18n.language),
        gender: register_data.gender ?? null,
        register_type: registerType,
      };
    } else if (registerType === "SSO") {
      data = getDataFromSSOProfile(register_data, i18n);
      data.register_type = registerType;
    }
  }
  return data;
}
