
/**
 * Evaluates whether more modules will be release in the future
 *
 * @param {*} parameterName user user object
 * @returns {*} boolean whether to show info an the next releaseDate
 *
 * @category elearning
 * @subcategory helpers
 * @exports getNextModuleRelease
 */

export default function getNextModuleRelease(user) {
  const { nextModuleRelease } = user?.game?.progress || {};
  const infoNotAllModulesAvailable = !!nextModuleRelease;
  let releaseDate;
  if (infoNotAllModulesAvailable) releaseDate = nextModuleRelease.unlocked;
  return { infoNotAllModulesAvailable, releaseDate };
}
