
import { useState, useEffect, useContext } from 'react';
import GlobalSettingsContext from 'shared/modules/global-settings/state/global-settings-context.provider';

export default function useSvgFromUrl(url, type, isBranding) {
  const [svg, setSvg] = useState(null);
  const { globalSettings } = useContext(GlobalSettingsContext);

  useEffect(() => {
    if (url) {
      fetch(`${url}`, { cache: 'force-cache' })
        .then((response) => response.text())
        .then((res) => {
          let resSvg = removeXlink(res);
          if (isBranding && globalSettings && Object.keys(globalSettings).length) {
            resSvg = setBranding(resSvg, globalSettings);
            setSvg(resSvg);
          } else {
            setSvg(resSvg);
          }
        });
    }
  }, [globalSettings]);

  return {
    svg,
  };
}

function removeXlink(svg) {
  return svg.replace(/xlink:/g, '');
}

function setBranding(svg, theme) {
  let result = svg;

  const colorReplacements = {
    '#00C39A': theme.primaryColor || '#00c39a',
    '#05668E': theme.secondaryColor || '#05668E',
  };

  result = result.replace(/#([0-9a-z]){6}/gi, (match) => colorReplacements[match.toUpperCase()] || match);

  return result;
}
