// Packages
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  Button,
  Select,
  Center,
  Stack,
  Group,
  Grid,
  TextInput
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import {IconCheck, IconArrowLeft } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react/icons"

// Hooks
import useUser from "shared/hooks/use-user";
import { useAccount } from "account/hooks/use-account";
import { useSoSafeAnalytics } from "shared/hooks/use-sosafe-analytics";

// Utilities
import {
  getNameAndDerivativeNameFromCode,
  parseCodeLanguageToLanguageType,
  sortLanguages,
  getLanguageName,
  nonLatinLanguages
} from "shared/utilities/languages";

// Components
import Helmet from "shared/components/helmet";

// Types
import { ANALYTICS_TYPE } from "shared/modules/sosafe-connect";
import PersonalInformationSVG from "../svgs/personal_information.svg";

// Scss
import style from "./account.module.css";
import { useAccountState } from "./use-account-state";
import { ChangePassword } from "./change-password";

export default function AccountPage() {
  const { t, i18n } = useTranslation([
    "translations",
    "flamingo",
    "helmetTranslations",
    "languagesTranslations",
  ]);

  const validator = (key: string, value: string) => {
    if (key === "email") {
      if (
        !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
          value
        )
      ) {
        return t("flamingo:email_error_invalid");
      }
    }

    return null;
  }

  const { state, error, updateField, validate, validateAllFields, isValid } = useAccountState({ validator });

  const { user, loading } = useUser({});
  const showPasswordSection = user && !!user?.email && !user.use_sso;
  const requireFields = !!user?.email;
  const disableNameFields = user && !user?.elearning_code;

  const GENDER = [
    { value: "", label: t("flamingo:not_specified") },
    { value: "0", label: t("flamingo:male") },
    { value: "1", label: t("flamingo:female") },
    { value: "2", label: t("flamingo:diverse") },
  ];

  const { sendSoSafeAnalytics } = useSoSafeAnalytics();

  useEffect(() => {
    sendSoSafeAnalytics({ type: ANALYTICS_TYPE.PAGE_VIEWED });
  }, []);

  useEffect(() => {
    if (!loading && i18n.language !== user?.language) {
      i18n.changeLanguage(user?.language);
      window.document.documentElement.lang = user?.language || "en";
    }
  }, [i18n, user, loading]);

  const customerLanguage = useMemo(() => {
    if (loading) return [];
    const languagesCode = user?.customer?.languages.languages ?? [];
    const languages = parseCodeLanguageToLanguageType(languagesCode);
    return sortLanguages(languages);
  }, [loading, user?.customer?.languages.languages]);

  const fallbackOptions = [
    { value: "en", label: t("languagesTranslations:en") },
  ];

  const availableLanguages = !customerLanguage.length
    ? fallbackOptions
    : customerLanguage;

  const { save } = useAccount();

  const onSave = () => {

    const isAllFieldsValid = validateAllFields();
    if (!isAllFieldsValid) return;

    save({ ...state, gender: state.gender === "" ? undefined : parseInt(state.gender, 10) });
  };

  const onBackClick = () => window.history.go(-1);

  return (
    <React.Fragment>
      <Center mb="auto">
        <Helmet title={t("helmetTranslations:Account management")} />
        <Stack maw="730px">
          <Button
            className={style.back}
            variant="naked"
            aria-label={t("flamingo:back")}
            leftSection={<IconArrowLeft size={16} />}
            onClick={onBackClick}
          >
            {t("flamingo:back")}
          </Button>
          <h5 className={style.title}>{t("flamingo:personal_info")}</h5>
          <Card withBorder radius="lg" mt="lg" p="xl">
              <Stack>
              <Grid>
                <Grid.Col span={{ base: 12, xs: 6 }}>
                  <Select
                    className={style.formDropdown}
                    label={t("flamingo:salutation")}
                    placeholder={t("flamingo:salutation")}
                    data={GENDER}
                    value={state.gender}
                    error={error.gender}
                    onChange={(value) => {
                      updateField("gender", value || "");
                    }}
                    onBlur={() => { validate("gender"); }}
                    autoComplete="honorific-prefix"
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ base: 12, xs: 6 }}>
                  <TextInput
                    label={t("flamingo:firstname")}
                    placeholder={t("flamingo:firstname")}
                    required={requireFields}
                    value={state.firstname}
                    error={error.firstname}
                    disabled={disableNameFields}
                    onChange={(e) => updateField("firstname", e.currentTarget.value)}
                    onBlur={() => { validate("firstname"); }}
                    autoComplete="given-name"
                  />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, xs: 6 }}>
                  <TextInput
                    label={t("flamingo:lastname")}
                    placeholder={t("flamingo:lastname")}
                    required={requireFields}
                    value={state.lastname}
                    error={error.lastname}
                    disabled={disableNameFields}
                    onChange={(e) => updateField("lastname", e.currentTarget.value)}
                    onBlur={() => { validate("lastname"); }}
                    autoComplete="family-name"
                  />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, xs: 6 }}>
                    <TextInput
                      label={t("flamingo:email_label")}
                      placeholder={t("flamingo:email_label")}
                      disabled
                      type="email"
                      value={state.email}
                      error={error.email}
                      onBlur={() => { validate("email"); }}
                      autoComplete="email"
                    />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, xs: 6 }}>
                    <Select
                      className={style.formDropdown}
                      label={t("flamingo:language_label")}
                      placeholder={t("flamingo:language_label")}
                      data={availableLanguages.map((avLang) => ({
                        value: avLang.key,
                        label: avLang.value,
                      }))}
                      value={state.language}
                      error={error.language}
                      autoComplete="language"
                      renderOption={(item) => (
                        <div
                          lang={item.option.value}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 'calc(0.5rem * var(--mantine-scale))' // Ensure --mantine-scale is defined in your CSS
                          }}
                          aria-label={nonLatinLanguages.has(item.option.value) ? getLanguageName(item.option.value) : undefined}
                        >
                          {item.checked ? (
                            <IconCheck
                              style={{
                                width: '1.2em',
                                minWidth: '1.5em',
                                height: '1em'
                              }}
                            />
                          ) : null}
                          <span lang={item.option.value}>{item.option.label}</span>
                        </div>
                      )}
                      onChange={(value) => {
                        const { languageName, derivativeLanguage } =
                          getNameAndDerivativeNameFromCode(value || "en");
                        const newLanguageValue = derivativeLanguage
                          ? `${languageName}-${derivativeLanguage}`
                          : languageName;

                        updateField("language", newLanguageValue);
                      }}
                    />
                  </Grid.Col>
                </Grid>
              </Stack>
              {user?.game?.canDownloadCertificate && (
                <span className="certificate-warning-text">
                  {t("translations:user_profile_certificate_warning")}
                </span>
              )}
              <Group justify="flex-end" mt="sm">
              <Button
                type="submit"
                aria-label={t("flamingo:save")}
                className={style.save}
                onClick={onSave}
                disabled={!isValid}
              >
                {t("flamingo:save")}
              </Button>
              </Group>
          </Card>
          {showPasswordSection && (
            <ChangePassword />
          )}
          {/* <PersonalInformationSVG className={style.svg} /> */}
        </Stack>
      </Center>
    </React.Fragment>
  );
}
