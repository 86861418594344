
import { Checkmark, Attention } from 'shared/svgs';
import { MODULE_STATES } from 'shared/utilities/module-states';

/**
 * @typedef {Object} getCardRibbonConfigObject
 * @property {boolean} cardRibbonDisplay - cardRibbonDisplay
 * @property {string} cardRibbonClassName - cardRibbonClassName
 * @property {string} cardRibbonIcon - cardRibbonIcon
 * @property {string} cardRibbonText - cardRibbonText
 */

/**
 * Returns CardRibbon configuration, based on the passed in module.
 * Further clarification inside the respective functional component.
 *
 * @param {?} module the module
 * @returns {getCardRibbonConfigObject} cardRibbon config
 *
 * @category elearning
 * @subcategory helpers
 * @exports getCardRibbonConfig
 */
export default function getCardRibbonConfig(module) {
  let cardRibbonDisplay = false;
  let cardRibbonClassName = '';
  let cardRibbonIcon = null;
  let cardRibbonText = '';

  if (!module) return {
    cardRibbonDisplay, cardRibbonClassName, cardRibbonIcon, cardRibbonText,
  };

  const { statusOfModule } = module;

  switch (true) {
    case statusOfModule.includes(MODULE_STATES.PASSED):
      cardRibbonDisplay = true;
      cardRibbonClassName = 'passed';
      cardRibbonIcon = Checkmark;
      cardRibbonText = 'Completed';

      break;
    case statusOfModule.includes(MODULE_STATES.EXPIRED):
      cardRibbonDisplay = true;
      cardRibbonClassName = 'overdue';
      cardRibbonIcon = Attention;
      cardRibbonText = 'Module overdue!';

      break;
    case statusOfModule.includes(MODULE_STATES.EXPIRE_SOON):
      cardRibbonDisplay = true;
      cardRibbonClassName = 'soon-overdue';
      cardRibbonIcon = Checkmark;
      cardRibbonText = 'Module will expire soon!';

      break;
    default:
      cardRibbonClassName = 'info';
      break;
  }

  return {
    cardRibbonDisplay, cardRibbonClassName, cardRibbonIcon, cardRibbonText,
  };
}
