
/**
 * use error messages from JSON schema if any. Used by form
 *
 * @param {*} errors
 * @returns error
 *
 * @category shared
 * @subcategory helpers
 * @exports surveyTransformErrors
 */
export const surveyTransformErrors = (errors, t) => errors.map((error) => {
  const messages = {
    required: t('This field is required'),
    minItems: t('Please choose at least one option'),
  };
    // use error messages from JSON schema if any
  if (messages && messages[error.name]) {
    return {
      ...error,
      message: messages[error.name],
    };
  }
  return error;
});
