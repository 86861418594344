
/**
 * Returns a primitive number in fixed-point notation.
 *
 * @param {any} number The Number to floatify
 * @param {number} [fractionDigits=4] Number of digits after the decimal point.
 * @returns {number} primitive number with given amount of fraction digits
 *
 * @category shared
 * @subcategory helpers
 * @exports toFixedFloat
 */
export default function toFixedFloat(number, fractionDigits = 4) {
  const fractionMod = 10 ** fractionDigits;
  return Math.round(parseFloat(number) * fractionMod) / fractionMod;
}
