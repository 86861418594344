
// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';

// Components
import CustomButton from 'shared/components/custom-button';
import InfoBubble from 'shared/components/info-bubble';
import SvgIcon from 'shared/svgs/helper/svg-icon';
import Pill from 'elearning/components/pill-component';
import CardRibbon from 'elearning/components/card-ribbon';
import ResponsivePicture from 'shared/components/responsive-picture';
import Progressbar from 'shared/components/progress-bar';
import TourStepWrapper from 'shared/components/onboarding/tour-step-wrapper';

// Utilities
import { Time, Play, Lock } from 'shared/svgs';
import { MODULE_STATES } from 'shared/utilities/module-states';
import { TOURS } from 'shared/utilities/tour-configs';
import { getModuleKey } from 'elearning/helpers';
import { checkModuleLocked, checkModuleLockedButPreview } from 'elearning/helpers/check';

// Scss
import './module-card.scss';
import { Tooltip } from '@sosafe-platform-engineering/fe-lib-ui-react';
import { dateToDDMMYYYY } from 'shared/helpers';

export default function ModuleCard(props) {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { module, isFirstCard, className } = props;

  const { campaign } = user || {};
  const { elearning_version } = campaign || {};

  const { t, i18n } = useTranslation('translations', 'helmet');
  const pillLongLangs = ['ar', 'es', 'cs', 'fr', 'it', 'nl', 'pl', 'pt', 'ru', 'se'];
  const pillClass = pillLongLangs.includes(i18n.language) ? 'two-lines' : null;

  const LR = i18n.language === 'ar' ? 'r' : 'l';
  const RL = i18n.language === 'ar' ? 'l' : 'r';

  if (!module) return null;

  const {
    name, description, image, mandatory, finished_by, unlocked, duration, active, statusOfModule, progress,
  } = module;

  let textHighlight = '';
  if (mandatory) {
    switch (true) {
      case statusOfModule.includes(MODULE_STATES.EXPIRED):
        textHighlight = 'text-danger';
        break;
      case statusOfModule.includes(MODULE_STATES.EXPIRE_SOON):
        textHighlight = 'text-warning';
        break;
      default:
        textHighlight = 'text-secondary';
        break;
    }
  }

  const isMaster = module.master;
  const isOldModule = elearning_version && parseInt(elearning_version, 10) < 4;
  const notYetUnlocked = checkModuleLocked(user, { unlocked });
  const moduleAlreadyPassed = statusOfModule.includes(MODULE_STATES.PASSED);

  const onClick = (type) => {
    if (active) {
      props.handleButton(type, getModuleKey(module));
    }
  };

  let leftButtonLabel = t('Start');
  let showRightButton = false;
  let leftAction = (e) => {
    e.stopPropagation();
    if (leftButtonLabel === t('Start')) {
      onClick('START');
    } else {
      onClick('RESTART');
    }
  };
  if (notYetUnlocked || checkModuleLockedButPreview(user, { unlocked })) {
    leftButtonLabel = <span className='u-textMedium'>{t('Available from {{x}}', { x: dateToDDMMYYYY(unlocked) })}</span>;
  } else if (!active) {
    leftButtonLabel = <span className='u-textMedium'>{t('Not available yet')}</span>;
  } else if (statusOfModule.includes(MODULE_STATES.PAUSED) || (statusOfModule.includes(MODULE_STATES.PASSED) && progress > 0 && progress < 100)) {
    showRightButton = true;
    leftButtonLabel = t('Continue');
    leftAction = (e) => {
      e.stopPropagation();
      onClick('CONTINUE');
    };
  } else if (statusOfModule.includes(MODULE_STATES.FAILED)) {
    leftButtonLabel = t('Restart');
  } else if (statusOfModule.includes(MODULE_STATES.PASSED)) {
    leftButtonLabel = t('Restart');
  }

  const buttonConfig = (
        <div className={`d-flex ${isOldModule ? 'large-size-btn' : ''}`}>
            <CustomButton
                className={`w-100 ${showRightButton ? 'mr-1' : ''}`}
                variant='secondary'
                label={leftButtonLabel}
                disabled={!active}
                onClick={leftAction}
                tooltip={active || notYetUnlocked ? null : {
                  description: t('Complete the previous stage first'),
                  position: 'top',
                }}
            />
            {showRightButton && (
                <React.Fragment>
                    <span className='mx-1' />
                    <CustomButton
                        className='w-100'
                        variant='secondary'
                        label={t('Restart')}
                        disabled={!active}
                        onClick={(e) => {
                          e.stopPropagation();
                          onClick('RESTART');
                        }}
                        tooltip={active || notYetUnlocked ? null : {
                          description: t('Complete the previous stage first'),
                          position: 'top',
                        }}
                    />
                </React.Fragment>
            )}
        </div>
  );

  if (isMaster) {
    return (
            <MasterCard
                module={module}
                image={image}
                active={active}
                name={name}
                description={description}
                duration={duration}
                buttonConfig={buttonConfig}
                onClick={onClick}
                textHighlight={textHighlight}
                notYetUnlocked={notYetUnlocked}
                finished_by={finished_by}
                mandatory={mandatory}
                moduleAlreadyPassed={moduleAlreadyPassed}
                langOptions={{
                  pillClass, LR, RL, t,
                }}
            />
    );
  }

  let layoutClass = 'col-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2 mb-4';

  let ReturnCard = (
        <NarrowModuleCard
            module={module}
            className={className}
            buttonConfig={buttonConfig}
            onClick={onClick}
            notYetUnlocked={notYetUnlocked}
            textHighlight={textHighlight}
            moduleAlreadyPassed={moduleAlreadyPassed}
            langOptions={{
              pillClass, LR, RL, t,
            }}
        />
  );

  if (isOldModule) {
    layoutClass = 'col-12 col-xxl-6 mb-4';
    ReturnCard = (
            <DatedModuleCard
                module={module}
                className={className}
                buttonConfig={buttonConfig}
                onClick={onClick}
                notYetUnlocked={notYetUnlocked}
                textHighlight={textHighlight}
                moduleAlreadyPassed={moduleAlreadyPassed}
                langOptions={{
                  pillClass, LR, RL, t,
                }}
            />
    );
  }

  if (isFirstCard) {
    ReturnCard = (
            <TourStepWrapper
                marginX={4}
                id='module_tour_modules'
                tour={TOURS.ModulepageTour}
                className={layoutClass}
            >
                { React.cloneElement(ReturnCard, { layoutClass: 'h-100' }) }
            </TourStepWrapper>
    );
  } else {
    ReturnCard = React.cloneElement(
      ReturnCard,
      { layoutClass },
    );
  }

  return ReturnCard;
}

ModuleCard.propTypes = {
  module: PropTypes.shape({}).isRequired,
};

function MasterCard(props) {
  const {
    image,
    active,
    name,
    description,
    duration,
    buttonConfig,
    module,
    notYetUnlocked,
    finished_by,
    textHighlight,
    mandatory,
    moduleAlreadyPassed,
    langOptions,
  } = props;
  const {
    pillClass, LR, RL, t,
  } = langOptions;
  const { unlocked, video } = module;

  return (
        <Card
            className={`shadow hover-shadow overflow-hidden ${active ? 'cursor-pointer' : ''}`}
            onClick={() => props.onClick('CONTINUE')}
        >
            <div className='row'>
                <div className={`col-12 col-md-6 col-lg-7 col-xl-8 p${LR}-md-3 order-1`}>
                    <div className={`h-100 py-3 px-3 p${LR}-md-0 d-flex flex-column justify-content-between`}>
                        <div className='row h-100 m-0 align-items-center'>
                            <div className='col-12 px-0 mt-2 order-1 d-flex flex-column'>
                                <h2 className='h1 mb-2 order-1'>{name}</h2>
                                <p className='h4 my-auto text-muted order-0'>{t('Entry video')}</p>
                                <div
                                    className='u-textMedium ul-no-padding-left order-2'
                                    dangerouslySetInnerHTML={{ __html: description }}
                                />
                            </div>
                            <div className='d-flex flex-row col-12 px-0 justify-content-between mb-2 order-0'>
                                <div className='col-6 col-md-8 px-0 order-0'>
                                    <div className='d-flex justify-content-start'>
                                        <Pill
                                            className={pillClass}
                                            module={module}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex flex-column col-4 px-0 order-1 order-md-0 mt-0 justify-content-center'>
                                    <div className='text-muted d-flex flex-row align-self-end'>
                                        <SvgIcon
                                            Icon={Time}
                                            size='15px'
                                            className='align-self-center stroke-icon-gray'
                                        />
                                        <small className={`m${LR}-1`}>{t('approx. x minutes', { count: duration })}</small>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 px-0 order-2 d-flex flex-row justify-content-between mt-1'>
                                <div className='px-0 order-0 d-flex flex-column justify-content-center mt-1'>
                                    {finished_by && !moduleAlreadyPassed && (
                                        <div className='d-flex flex-column flex-md-row'>
                                            <small className={`font-weight-bold my-auto mr-1 ${textHighlight}`}>
                                                {`${t('Pass until')}: ${dateToDDMMYYYY(finished_by)}`}
                                            </small>
                                        </div>
                                    )}
                                    <small className={`font-weight-bold ${textHighlight}`}>
                                        {mandatory ? t('Mandatory module') : t('Optional module')}
                                    </small>
                                </div>
                                <div className='px-0 mt-1 order-2 order-md-0 d-flex flex-column flex-sm-row justify-content-end align-items-sm-end align-self-end'>
                                    {buttonConfig}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-6 col-lg-5 col-xl-4 d-flex m${RL}-md-0 p${RL}-md-0 card-header-down-sm-height order-0`}>
                    <div className='flex-grow-1 d-flex position-relative'>
                        <ResponsivePicture
                            className='h-100 w-100'
                            src={image}
                            alt={t('Module image')}
                            grayscale={notYetUnlocked}
                            fit='cover'
                            defaultWidth={730}
                            srcset={{
                              xs: { width: 730 },
                              md: { width: 630 },
                            }}
                        />
                        {notYetUnlocked ? (
                            <div className='locked-container'>
                                <Tooltip content={t('This module is available {{x}}', { x: dateToDDMMYYYY(unlocked) })}>
                                    <div className='cursor-help'>
                                        <SvgIcon
                                            Icon={Lock}
                                            size='large'
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        ) : video && (
                            <div className='play-btn position-absolute text-center'>
                                <SvgIcon
                                    Icon={Play}
                                    size='17vw'
                                    divergentHeight='112px'
                                />
                            </div>
                        )}
                        <CardRibbon module={module} />
                    </div>
                </div>
            </div>
        </Card>
  );
}

function NarrowModuleCard(props) {
  const {
    module,
    moduleAlreadyPassed,
    buttonConfig,
    onClick,
    textHighlight,
    className,
    notYetUnlocked,
    langOptions,
    layoutClass,
  } = props;

  const { pillClass, LR, t } = langOptions;

  if (!module) return null;
  const {
    name, description, image, mandatory, finished_by, duration, active, unlocked, progress, video,
  } = module;

  const showProgressBar = progress > 0 && progress < 100;

  return (
        <div className={`${layoutClass || ''}`}>
            <Card
                className={`shadow hover-shadow h-100 ${active ? 'cursor-pointer' : ''} overflow-hidden ${className || ''}`}
                onClick={() => onClick('CONTINUE')}
            >
                <div className='d-flex flex-column h-100'>
                    <div className='p-3 d-flex flex-column justify-content-between h-100 order-1'>
                        <div className='row'>
                            <div className='col-12 mt-2 order-1'>
                                <h2 className='u-paragraph u-textBold'>{name}</h2>
                            </div>
                            <div className='col-12 d-flex justify-content-between align-items-center order-0'>
                                <Pill
                                    className={pillClass}
                                    module={module}
                                />
                                <div className='col-2 justify-content-end d-flex p-0'>
                                    <InfoBubble
                                        size='small'
                                        description={description}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row align-items-end mt-3'>
                            <div className='col-7 d-flex flex-column justify-content-between mt-auto'>
                                {finished_by && !moduleAlreadyPassed && (
                                    <small className={`d-block font-weight-bold ${textHighlight}`}>
                                        {`${t('Pass until')}: ${dateToDDMMYYYY(finished_by)}`}
                                    </small>
                                )}
                                <small className={`font-weight-bold ${textHighlight}`}>
                                    {mandatory ? t('Mandatory module') : t('Optional module')}
                                </small>
                                <div className='text-muted d-flex flex-row mt-1'>
                                    <SvgIcon
                                        Icon={Time}
                                        size='15px'
                                        className='my-auto stroke-icon-gray'
                                    />
                                    <small className={`my-auto m${LR}-1`}>{t('approx. x minutes', { count: duration })}</small>
                                </div>
                            </div>
                            <div className='col-5'>
                                {showProgressBar && (
                                    <Progressbar
                                        className='progress-bar-sm'
                                        tooltip
                                        animate
                                        progress={progress}
                                    />
                                )}
                            </div>
                            <div className='col-12 mt-3'>
                                {buttonConfig}
                            </div>
                        </div>
                    </div>
                    <div className='svg-height d-flex position-relative order-0'>
                        <ResponsivePicture
                            className='flex-grow-1 h-100 w-100 position-absolute'
                            grayscale={!active || notYetUnlocked}
                            src={image}
                            alt={t('Module image')}
                            fit='cover'
                            defaultWidth={730}
                            srcset={{
                              xs: { width: 730 },
                              md: { height: 350 },
                            }}
                        />
                        {notYetUnlocked ? (
                            <div className='locked-container'>
                                <Tooltip content={t('This module is available {{x}}', { x: dateToDDMMYYYY(unlocked) })}>
                                    <div className='cursor-help'>
                                        <SvgIcon
                                            Icon={Lock}
                                            size='large'
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        ) : video && (
                            <div className='play-btn position-absolute text-center'>
                                <SvgIcon
                                    Icon={Play}
                                    size='17vw'
                                    divergentHeight='112px'
                                />
                            </div>
                        )}
                        <CardRibbon module={module} />
                    </div>
                </div>
            </Card>
        </div>
  );
}

function DatedModuleCard(props) {
  const {
    module,
    moduleAlreadyPassed,
    buttonConfig,
    onClick,
    textHighlight,
    className,
    notYetUnlocked,
    langOptions,
    layoutClass,
  } = props;
  const {
    pillClass, LR, RL, t,
  } = langOptions;
  if (!module) return null;
  const {
    name, description, image, mandatory, finished_by, duration, active, unlocked, progress, video,
  } = module;

  const showProgressBar = progress > 0 && progress < 100;

  return (
        <div className={`${layoutClass || ''}`}>
            <Card
                className={`shadow overflow-hidden hover-shadow ${active ? 'cursor-pointer' : ''} ${className || ''}`}
                onClick={() => onClick('CONTINUE')}
            >
                <div className='row dated-module-card'>
                    <div className={`col-12 col-md-6 col-lg-8 p${LR}-md-3 order-1`}>
                        <div className={`h-100 py-3 px-3 p${LR}-md-0 d-flex flex-column`}>
                            <div className='row h-100 m-0'>
                                <div className='col-12 px-0 mb-2 order-1'>
                                    <h2 className='h1 mb-2'>{name}</h2>
                                    <div
                                        className='u-textMedium ul-no-padding-left'
                                        dangerouslySetInnerHTML={{ __html: description }}
                                    />
                                </div>
                                <div className='d-flex flex-row col-12 px-0 mb-2 justify-content-between order-0'>
                                    <div className='col-6 col-md-8 px-0 order-0'>
                                        <div className='d-flex justify-content-start'>
                                            <Pill
                                                className={pillClass}
                                                module={module}
                                            />
                                        </div>
                                    </div>
                                    <div className={`d-flex flex-column ${showProgressBar ? 'justify-content-start' : 'justify-content-center'} col-4 col-lg-3 px-0 order-1 order-md-0`}>
                                        {showProgressBar && (
                                            <Progressbar
                                                className='progress-bar-sm mb-2'
                                                tooltip
                                                animate
                                                progress={module.progress}
                                            />
                                        )}
                                        <div className='text-muted d-flex flex-row align-self-end'>
                                            <SvgIcon
                                                Icon={Time}
                                                size='15px'
                                                className='align-self-center stroke-icon-gray'
                                            />
                                            <small className={`m${LR}-1`}>{t('approx. x minutes', { count: duration })}</small>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 px-0 d-flex flex-row justify-content-between mt-auto order-2'>
                                    <div className='px-0 order-0 d-flex justify-content-center flex-column mt-1'>
                                        {finished_by && !moduleAlreadyPassed && (
                                            <div className='d-flex flex-column flex-md-row'>
                                                <small className={`font-weight-bold my-auto mr-1 ${textHighlight}`}>
                                                    {`${t('Pass until')}: ${dateToDDMMYYYY(finished_by)}`}
                                                </small>
                                            </div>
                                        )}
                                        <small className={`font-weight-bold ${textHighlight}`}>
                                            {mandatory ? t('Mandatory module') : t('Optional module')}
                                        </small>
                                    </div>
                                    <div className='px-0 mt-1 order-2 order-md-0 d-flex flex-column flex-sm-row justify-content-end align-items-sm-end align-self-end'>
                                        {buttonConfig}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-md-6 col-lg-4 d-flex m${RL}-md-0 p${RL}-md-0 card-header-down-sm-height order-0`}>
                        <div className='w-100 position-relative'>
                            <ResponsivePicture
                                className='h-100 w-100 position-absolute'
                                src={image}
                                grayscale={notYetUnlocked}
                                alt={t('Module image')}
                                fit='cover'
                                defaultWidth={730}
                                srcset={{
                                  xs: { width: 730 },
                                  md: { width: 630 },
                                }}
                            />
                            {notYetUnlocked ? (
                                <div className='locked-container'>
                                    <Tooltip
                                        content={t('This module is available {{x}}', { x: dateToDDMMYYYY(unlocked) })}
                                        position='bottom'
                                    >
                                        <div className='cursor-help'>
                                            <SvgIcon
                                                Icon={Lock}
                                                size='large'
                                            />
                                        </div>
                                    </Tooltip>
                                </div>
                            ) : video && (
                                <div className='play-btn position-absolute text-center'>
                                    <SvgIcon
                                        Icon={Play}
                                        size='17vw'
                                        divergentHeight='112px'
                                    />
                                </div>
                            )}
                            <CardRibbon module={module} />
                        </div>
                    </div>
                </div>
            </Card>
        </div>
  );
}
