
import ActionTypes from 'shared/utilities/action-types';

const INITIAL_STATE = { };

class ModuleFeedbackReducer {
  static reduce(state = INITIAL_STATE, action) {
    const reference = action.payload?.reference;
    switch (action.type) {
      case ActionTypes.SEND_MODULE_FEEDBACK_REQUEST:
        return {
          ...state,
          loading: true,
          reference: null,
        };

      case ActionTypes.SEND_MODULE_FEEDBACK_SUCCESS:
      case ActionTypes.SEND_MODULE_FEEDBACK_FAILED:
        return {
          ...state,
          loading: false,
          reference,
        };
      default:
        return state;
    }
  }
}

export default ModuleFeedbackReducer.reduce;
