
/**
 * Functional version of useGetComponent hook, to get the current component depending on url
 *
 * @returns current component as string
 *
 * @category shared
 * @subcategory helpers
 * @exports getComponentByUrl
 */
export default function getComponentByUrl() {
  const url = window.location.pathname;
  switch (true) {
    case url.includes('achievements'):
      return 'achievements';
    case url.includes('elearning') && url.split('/').length === 3:
      return 'modules';
    case url === '/':
      return 'categories';
    case url.includes('account'):
      return 'profile';
    default:
      return '';
  }
}
