import { useCategoriesRequest } from 'elearning/hooks/use-categories-request';
import { PersonalizedModule } from 'flamingo-e-learning-platform/training/types/module.type';
import {
  Title,
  Select,
  Group,
  Tabs,
  SimpleGrid,
} from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import { NoResults } from 'flamingo-e-learning-platform/components/no-results/no-results';
import { MODULE_FILTER_OPTIONS } from 'flamingo-e-learning-platform/training/types/module-filter-options';
import filterModules from '../shared/filter-modules';
import { sortMandatoryToFirst } from '../shared/sort-mandatory-module-to-first';
import { ModuleCardController } from '../module-card/module-card-controller';
import { LibraryModuleCard } from '../module-card/library-module-card/library-module-card';
import { hasOnlyOptionalModules } from '../shared/check-has-only-optional-modules';

import style from './module-hub.module.css';
import { updateModulesByLimit } from '../shared';

interface Props {
  onModuleCard: (module: PersonalizedModule) => void;
  personalizedModules: PersonalizedModule[];
  learningDaysLimit: number;
}

const ModuleHub = ({
  onModuleCard,
  personalizedModules,
  learningDaysLimit,
}: Props) => {
  const { t } = useTranslation('flamingo');
  const { data: categoriesResponse } = useCategoriesRequest(true);

  const [selectedCategory, setSelectedCategory] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const defaultCategory = { label: t('all_categories'), value: 'all' };

  const categories = categoriesResponse?.data.result || [];

  const mappedCategories = [
    defaultCategory,
    ...categories.map((category) => ({
      label: category.name,
      value: String(category.id),
    })),
  ];

  const isOnlyOptionalModules = hasOnlyOptionalModules(personalizedModules);

  const updatedModulesListWithLimit = updateModulesByLimit(
    personalizedModules,
    learningDaysLimit
  );

  const filteredModulesArrayAll = useMemo(() => {
    if (!selectedCategory) {
      return null;
    }
    const filteredModules = filterModules(
      updatedModulesListWithLimit,
      selectedCategory.value,
      MODULE_FILTER_OPTIONS.ALL_LESSONS
    );
    return sortMandatoryToFirst(filteredModules);
  }, [updatedModulesListWithLimit, selectedCategory]);

  const filteredModulesArrayMandatory = useMemo(() => {
    if (!selectedCategory) {
      return null;
    }
    const filteredModules = filterModules(
      updatedModulesListWithLimit,
      selectedCategory.value,
      MODULE_FILTER_OPTIONS.MANDATORY
    );
    return sortMandatoryToFirst(filteredModules);
  }, [updatedModulesListWithLimit, selectedCategory]);

  const filteredModulesArrayOverdue = useMemo(() => {
    if (!selectedCategory) {
      return null;
    }
    const filteredModules = filterModules(
      updatedModulesListWithLimit,
      selectedCategory.value,
      MODULE_FILTER_OPTIONS.OVERDUE
    );
    return sortMandatoryToFirst(filteredModules);
  }, [updatedModulesListWithLimit, selectedCategory]);

  return (
    <div className={style.moduleHub}>
      <div className={style.divider}>
        <p className={style.dividerText}>
          <span>{t('divider')}</span>
        </p>
      </div>
      <div className={style.librarySection}>
        <div className={style.categoryDropdown}>
          <Select
            style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
            label={
              <Title order={3} size="h3" className={style.lessons}>
                {t('library')}
              </Title>
            }
            disabled={!mappedCategories}
            onChange={(selected) => {
              if (selected) {
                setSelectedCategory({ label: selected, value: selected });
              }
            }}
            placeholder={t('select_category')}
            name="modules-category"
            data-testid="modules-category"
            data={mappedCategories}
            value={selectedCategory ? selectedCategory.value : ''}
            aria-label={t('library')}
          />
        </div>
      </div>
      {selectedCategory && (
        <Tabs defaultValue={MODULE_FILTER_OPTIONS.ALL_LESSONS}>
          {!isOnlyOptionalModules && (
            <Group justify="center">
              <Tabs.List mb="md" aria-label={t('module_status_tabs')}>
                <Tabs.Tab value={MODULE_FILTER_OPTIONS.ALL_LESSONS}>
                  {t('all-lessons')}
                </Tabs.Tab>
                <Tabs.Tab value={MODULE_FILTER_OPTIONS.MANDATORY}>
                  {t('mandatory')}
                </Tabs.Tab>
                <Tabs.Tab value={MODULE_FILTER_OPTIONS.OVERDUE}>
                  {t('overdue')}
                </Tabs.Tab>
              </Tabs.List>
            </Group>
          )}

          <span id="filtered-module-content">
            {filteredModulesArrayAll?.length ? (
              <>
                <Tabs.Panel
                  value={MODULE_FILTER_OPTIONS.ALL_LESSONS}
                  className={style.content}
                >
                  <SimpleGrid
                    cols={{ base: 1, sm: 1, md: 2, lg: 3 }}
                    spacing="lg"
                  >
                    {filteredModulesArrayAll.map((mod) => (
                      <ModuleCardController
                        key={mod.id}
                        {...mod}
                        thumbnailUrl={undefined}
                        onAction={onModuleCard}
                        ModuleVariant={LibraryModuleCard}
                        buttonSize="xs"
                        isOnlyOptionalModules={hasOnlyOptionalModules(
                          personalizedModules
                        )}
                      />
                    ))}
                  </SimpleGrid>
                </Tabs.Panel>
                <Tabs.Panel
                  value={MODULE_FILTER_OPTIONS.MANDATORY}
                  className={style.content}
                >
                  {filteredModulesArrayMandatory &&
                  filteredModulesArrayMandatory.length > 0 ? (
                    <SimpleGrid
                      cols={{ base: 1, sm: 1, md: 2, lg: 3 }}
                      spacing="lg"
                    >
                      {filteredModulesArrayMandatory.map((mod) => (
                        <ModuleCardController
                          key={mod.id}
                          {...mod}
                          thumbnailUrl={undefined}
                          onAction={onModuleCard}
                          ModuleVariant={LibraryModuleCard}
                          buttonSize="xs"
                          isOnlyOptionalModules={hasOnlyOptionalModules(
                            personalizedModules
                          )}
                        />
                      ))}
                    </SimpleGrid>
                  ) : (
                    <NoResults />
                  )}
                </Tabs.Panel>
                <Tabs.Panel
                  value={MODULE_FILTER_OPTIONS.OVERDUE}
                  className={style.content}
                >
                  {filteredModulesArrayOverdue &&
                  filteredModulesArrayOverdue.length > 0 ? (
                    <SimpleGrid
                      cols={{ base: 1, sm: 1, md: 2, lg: 3 }}
                      spacing="lg"
                    >
                      {filteredModulesArrayOverdue.map((mod) => (
                        <ModuleCardController
                          key={mod.id}
                          {...mod}
                          thumbnailUrl={undefined}
                          onAction={onModuleCard}
                          ModuleVariant={LibraryModuleCard}
                          buttonSize="xs"
                          isOnlyOptionalModules={hasOnlyOptionalModules(
                            personalizedModules
                          )}
                        />
                      ))}
                    </SimpleGrid>
                  ) : (
                    <NoResults />
                  )}
                </Tabs.Panel>
              </>
            ) : (
              <NoResults />
            )}
          </span>
        </Tabs>
      )}
    </div>
  );
};

export default ModuleHub;
