
import React from 'react';

// Components
import Categories from 'shared/svgs/tour-svgs/categories';
import Quickstart from 'shared/svgs/tour-svgs/quick-start';
import Welcome from 'shared/svgs/tour-svgs/welcome';
import Badges from 'shared/svgs/tour-svgs/badges';
import Level from 'shared/svgs/tour-svgs/level';
import LevelDisabled from 'shared/svgs/tour-svgs/level-disabled';
import Rank from 'shared/svgs/tour-svgs/rank';
import VideoModule from 'shared/svgs/tour-svgs/video-module';
import AllModules from 'shared/svgs/tour-svgs/all-modules';
import Filter from 'shared/svgs/tour-svgs/filter';
import LevelProgress from 'shared/svgs/tour-svgs/level-progress';
import LevelProgressDisabled from 'shared/svgs/tour-svgs/level-progress-disabled';
import Activities from 'shared/svgs/tour-svgs/activities';
import AchievementBadges from 'shared/svgs/tour-svgs/achievement-badges';

export const TOURS = {
  MainpageTour: 'MainpageTour',
  ModulepageTour: 'ModulepageTour',
  AchievementTour: 'AchievementTour',
  MainpageTourMobile: 'MainpageTourMobile',
  ModulepageTourMobile: 'ModulepageTourMobile',
  AchievementTourMobile: 'AchievementTourMobile',
};

export const STEP_TYPES = {
  CENTER: 'CENTER',
  STEP: 'STEP',
  FINISH: 'FINISH',
  MOBILE: 'MOBILE',
  START: 'START',
};

export const MainpageTour = {
  id: 'MainpageTour',
  steps: [
    {
      id: 'main_tour_start',
      title: 'tourTranslations:Category:start:title',
      description: 'tourTranslations:Category:start:msg',
      type: STEP_TYPES.START,
      scroll: false,
      style: {
        borderRadius: false,
      },
    },
    {
      id: 'main_tour_welcome',
      title: 'tourTranslations:Category:welcome:title',
      description: 'tourTranslations:Category:welcome:msg',
      type: STEP_TYPES.STEP,
      scroll: true,
      style: {
        borderRadius: true,
      },
    },
    {
      id: 'main_tour_category',
      title: 'tourTranslations:Category:category-overview:title',
      description: 'tourTranslations:Category:category-overview:msg',
      type: STEP_TYPES.STEP,
      scroll: true,
      style: {
        borderRadius: true,
      },
    },
    {
      id: 'main_tour_quickstart',
      title: 'tourTranslations:Category:quickstart:title',
      description: 'tourTranslations:Category:quickstart:msg',
      type: STEP_TYPES.STEP,
      scroll: true,
      style: {
        borderRadius: true,
      },
    },
    {
      id: 'main_tour_level',
      title: {
        level: 'tourTranslations:Category:level:title:level',
        default: 'tourTranslations:Category:level:title:default',
      },
      description: {
        level: 'tourTranslations:Category:level:msg:level',
        default: 'tourTranslations:Category:level:msg:default',
      },
      type: STEP_TYPES.STEP,
      scroll: true,
      style: {
        borderRadius: true,
      },
    },
    {
      id: 'main_tour_bages',
      title: 'tourTranslations:Category:badges:title',
      description: 'tourTranslations:Category:badges:msg',
      type: STEP_TYPES.STEP,
      scroll: true,
      style: {
        borderRadius: true,
      },
      condition: {
        every: ['badges'],
      },
    },
    {
      id: 'main_tour_certificate',
      title: 'tourTranslations:Category:certificate:title',
      description: 'tourTranslations:Category:certificate:msg',
      type: STEP_TYPES.STEP,
      scroll: true,
      style: {
        borderRadius: true,
      },
      condition: {
        every: ['certificate'],
      },
    },
    {
      id: 'main_tour_colleagues',
      title: 'tourTranslations:Category:colleagues:title',
      description: 'tourTranslations:Category:colleagues:msg',
      type: STEP_TYPES.STEP,
      scroll: true,
      style: {
        borderRadius: true,
      },
      condition: {
        every: ['colleagues'],
      },
    },
    {
      id: 'main_tour_hints',
      title: 'tourTranslations:Category:hints:title',
      description: 'tourTranslations:Category:hints:msg',
      type: STEP_TYPES.STEP,
      scroll: true,
      style: {
        borderRadius: true,
      },
      condition: {
        every: ['hints'],
      },
    },
    {
      id: 'main_tour_navigation',
      title: 'tourTranslations:Category:navigation:title',
      description: 'tourTranslations:Category:navigation:msg',
      type: STEP_TYPES.STEP,
      scroll: false,
      style: {
        borderRadius: false,
      },
    },
    {
      id: 'main_tour_training',
      title: 'tourTranslations:Category:training:title',
      description: 'tourTranslations:Category:training:msg',
      type: STEP_TYPES.STEP,
      scroll: false,
      style: {
        borderRadius: true,
      },
      condition: {
        every: ['achievements'],
      },
    },
    {
      id: 'main_tour_achievements',
      title: 'tourTranslations:Category:achievements:title',
      description: 'tourTranslations:Category:achievements:msg',
      type: STEP_TYPES.STEP,
      scroll: false,
      style: {
        borderRadius: true,
      },
      condition: {
        every: ['achievements'],
      },
    },
    {
      id: 'main_tour_downloads',
      title: 'tourTranslations:Category:downloads:title',
      description: 'tourTranslations:Category:downloads:msg',
      type: STEP_TYPES.STEP,
      scroll: false,
      style: {
        borderRadius: true,
      },
      condition: {
        every: ['material_area'],
      },
    },
    {
      id: 'main_tour_feedback',
      title: 'tourTranslations:Category:feedback:title',
      description: 'tourTranslations:Category:feedback:msg',
      type: STEP_TYPES.STEP,
      scroll: false,
      style: {
        borderRadius: true,
      },
      condition: {
        every: ['feedback'],
      },
    },
    {
      id: 'main_tour_external',
      title: 'tourTranslations:Category:external:title',
      description: 'tourTranslations:Category:external:msg',
      type: STEP_TYPES.STEP,
      scroll: true,
      style: {
        borderRadius: true,
      },
      condition: {
        every: ['info_widgets'],
      },
    },
    {
      id: 'main_tour_finish',
      title: 'tourTranslations:Category:finish:title',
      description: 'tourTranslations:Category:finish:msg',
      type: STEP_TYPES.FINISH,
      scroll: false,
    },
  ],
};

export const MainpageTourMobile = {
  id: 'MainpageTourMobile',
  steps: [
    {
      lang: 'welcome',
      image: <Welcome />,
      title: 'tourTranslations:Category:welcome:title',
      description: 'tourTranslations:Category:welcome:msg',
    },
    {
      lang: 'quickstart',
      image: <Quickstart />,
      title: 'tourTranslations:Category:quickstart:title',
      description: 'tourTranslations:Category:quickstart:msg',
    },
    {
      lang: 'level',
      image: {
        level: <Level />,
        default: <LevelDisabled />,
      },
      title: {
        level: 'tourTranslations:Category:level:title:level',
        default: 'tourTranslations:Category:level:title:default',
      },
      description: {
        level: 'tourTranslations:Category:level:msg:level',
        default: 'tourTranslations:Category:level:msg:default',
      },
    },
    {
      lang: 'badges',
      image: <Badges />,
      title: 'tourTranslations:Category:badges:title',
      description: 'tourTranslations:Category:badges:msg',
      condition: {
        every: ['badges'],
      },
    },
    {
      lang: 'colleagues',
      image: <Rank />,
      title: 'tourTranslations:Category:colleagues:title',
      description: 'tourTranslations:Category:colleagues:msg',
      condition: {
        every: ['colleagues'],
      },
    },
    {
      lang: 'category-overview',
      image: <Categories />,
      title: 'tourTranslations:Category:category-overview:title',
      description: 'tourTranslations:Category:category-overview:msg',
    },
  ],
};

export const ModulepageTour = {
  id: 'ModulepageTour',
  steps: [
    {
      id: 'module_tour_video',
      title: 'tourTranslations:Module:video:title',
      description: 'tourTranslations:Module:video:msg',
      type: STEP_TYPES.STEP,
      scroll: true,
      style: {
        borderRadius: true,
      },
    },
    {
      id: 'module_tour_progress',
      title: 'tourTranslations:Module:progress:title',
      description: 'tourTranslations:Module:progress:msg',
      type: STEP_TYPES.STEP,
      scroll: true,
      style: {
        borderRadius: true,
      },
    },
    {
      id: 'module_tour_modules',
      title: 'tourTranslations:Module:modules:title',
      description: 'tourTranslations:Module:modules:msg',
      type: STEP_TYPES.STEP,
      scroll: true,
      style: {
        borderRadius: true,
      },
    },
    {
      id: 'module_tour_filter',
      title: 'tourTranslations:Module:filter:title',
      description: 'tourTranslations:Module:filter:msg',
      type: STEP_TYPES.STEP,
      scroll: true,
      style: {
        borderRadius: true,
      },
    },
    {
      id: 'module_tour_finish',
      title: 'tourTranslations:Module:finish:title',
      description: 'tourTranslations:Module:finish:msg',
      type: STEP_TYPES.FINISH,
      scroll: false,
    },
  ],
};

export const ModulepageTourMobile = {
  id: 'ModulepageTourMobile',
  steps: [
    {
      lang: 'video',
      image: <VideoModule />,
      title: 'tourTranslations:Module:video:title',
      description: 'tourTranslations:Module:video:msg',
    },
    {
      lang: 'modules',
      image: <AllModules />,
      title: 'tourTranslations:Module:modules:title',
      description: 'tourTranslations:Module:modules:msg',
    },
    {
      lang: 'filter',
      image: <Filter />,
      title: 'tourTranslations:Module:filter:title',
      description: 'tourTranslations:Module:filter:msg',
    },
  ],
};

export const AchievementTour = {
  id: 'AchievementTour',
  steps: [
    {
      id: 'achievement_tour_level',
      title: {
        level: 'tourTranslations:Achievements:level:title:level',
        default: 'tourTranslations:Achievements:level:title:default',
      },
      description: {
        level: 'tourTranslations:Achievements:level:msg:level',
        default: 'tourTranslations:Achievements:level:msg:default',
      },
      type: STEP_TYPES.STEP,
      scroll: true,
      style: {
        borderRadius: true,
      },
    },
    {
      id: 'achievement_tour_spider',
      title: 'tourTranslations:Achievements:spider:title',
      description: 'tourTranslations:Achievements:spider:msg',
      type: STEP_TYPES.STEP,
      scroll: true,
      style: {
        borderRadius: true,
      },
    },
    {
      id: 'achievement_tour_development',
      title: 'tourTranslations:Achievements:development:title',
      description: 'tourTranslations:Achievements:development:msg',
      type: STEP_TYPES.STEP,
      scroll: true,
      style: {
        borderRadius: true,
      },
      condition: {
        every: ['level'],
      },
    },
    {
      id: 'achievement_tour_activities',
      title: 'tourTranslations:Achievements:activities:title',
      description: 'tourTranslations:Achievements:activities:msg',
      type: STEP_TYPES.STEP,
      scroll: true,
      style: {
        borderRadius: true,
      },
    },
    {
      id: 'achievement_tour_badges',
      title: 'tourTranslations:Achievements:badges:title',
      description: 'tourTranslations:Achievements:badges:msg',
      type: STEP_TYPES.STEP,
      scroll: true,
      style: {
        borderRadius: true,
      },
      condition: {
        every: ['badges'],
      },
    },
    {
      id: 'achievement_tour_finish',
      title: 'tourTranslations:Achievements:finish:title',
      description: 'tourTranslations:Achievements:finish:msg',
      type: STEP_TYPES.FINISH,
      scroll: false,
    },
  ],
};

export const AchievementTourMobile = {
  id: 'AchievementTourMobile',
  steps: [
    {
      lang: 'video',
      image: {
        level: <LevelProgress />,
        default: <LevelProgressDisabled />,
      },
      title: {
        level: 'tourTranslations:Achievements:level:title:level',
        default: 'tourTranslations:Achievements:level:title:default',
      },
      description: {
        level: 'tourTranslations:Achievements:level:msg:level',
        default: 'tourTranslations:Achievements:level:msg:default',
      },
    },
    {
      lang: 'activities',
      image: <Activities />,
      title: 'tourTranslations:Achievements:activities:title',
      description: 'tourTranslations:Achievements:activities:msg',
    },
    {
      lang: 'badges',
      image: <AchievementBadges />,
      title: 'tourTranslations:Achievements:badges:title',
      description: 'tourTranslations:Achievements:badges:msg',
    },
  ],
};
