// Packages
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components
import CustomButton from 'shared/components/custom-button';
import SvgIcon from 'shared/svgs/helper/svg-icon';
import { Steps, ChevronRight } from 'shared/svgs';
import { dispatchSetPlayerData } from 'elearning/services';
import { useHistory } from 'react-router';
import ResponsivePicture from 'shared/components/responsive-picture';
import { MODULE_OPENED_FROM, getModuleKey } from 'elearning/helpers';
import Pill from 'elearning/components/pill-component';
import { Title, Text } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import styles from "./activity-section.module.css";

/**
 * renders the "module-completed" activity in the activity section on the gamification-page
 *
 * @category gamification
 * @subcategory components
 * @exports ActivityModuleSection
 * @component
 */
export default function ActivityModuleSection(props) {
  const { t, i18n } = useTranslation('translations');

  const { isLast, activity } = props;

  const { module, category } = activity;

  const { name, stage, image } = module || {};
  const { category_name: categoryKey } = category || {};

  const history = useHistory();
  const dispatch = useDispatch();

  const title = t('Module completed');
  let typeText = 'Basics';
  let color = 'pill-orange';

  switch (stage) {
    case 0:
      break;
    case 1:
      typeText = 'Extension';
      color = 'pill-blue';
      break;
    case 2:
      typeText = 'Expert';
      color = 'pill-purple';
      break;
    default:
      break;
  }

  const LR = `${i18n.language === 'ar' ? 'r' : 'l'}`;
  const RL = `${i18n.language === 'ar' ? 'l' : 'r'}`;

  return (
        <div className={`activity d-flex flex-column py-3 border-top ${isLast ? 'border-bottom mb-3' : ''}`}>
            <div className='mb-3 d-flex flex-row justify-content-between'>
                <Title order={3} size="h5">{title}</Title>
                <Pill
                    module={module}
                    hideText
                    showPlus
                />
            </div>

            <div className={`${styles.activityBadgeContent} d-flex d-row`}>
                {/* Image */}
                <div className='activity-badge'>
                    <ResponsivePicture
                        className='activity-badge-image'
                        src={image}
                        alt=""
                        fit='cover'
                        defaultHeight={100}
                    />
                </div>
                <span className='mx-2' />
                {/* Info */}
                <div className='d-flex flex-column w-100'>
                    <div className='d-flex flex-row'>
                        <SvgIcon
                            Icon={Steps}
                            size='15px'
                            className={`my-auto m${RL}-2 stroke-${color}`}
                            flipx={i18n.language === 'ar'}
                        />
                        <Text span className={`my-auto u-textMedium text-${color}`}>
                            {t(typeText)}
                        </Text>
                    </div>
                    <Title className='mb-3' order={4} size="h5">{name}</Title>
                    {/* Restart button link */}
                    <CustomButton
                        label={(
                            <span className='d-flex'>
                                <span className='my-auto'>{t('Restart')}</span>
                                <SvgIcon
                                    Icon={ChevronRight}
                                    size='10px'
                                    className={`mt-2 m${LR}-2`}
                                    color='$secondaryColor'
                                    flipx={i18n.language === 'ar'}
                                />
                            </span>
                        )}
                        className={`p-0 m${LR}-auto`}
                        variant='link'
                        onClick={() => {
                          dispatch(dispatchSetPlayerData({ isContinue: false }));
                          history.push(`/elearning/${categoryKey}/${getModuleKey(module)}`, {
                            openedFrom: MODULE_OPENED_FROM.ACHIEVEMENT_PROGRESS_CARD,
                          });
                        }}
                        ariaLabel={t('Restart')}
                    />
                </div>
            </div>
        </div>
  );
}
