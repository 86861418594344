
import ActionTypes from 'shared/utilities/action-types';

const INITIAL_STATE = {};

class SettingsReducer {
  static reduce(state = INITIAL_STATE, action) {
    const { setting } = action;
    switch (action.type) {
      case ActionTypes.SET_SETTING:
        return {
          ...state,
          ...setting,
        };

      case ActionTypes.AUTH_LOGOUT_FAILED:
      case ActionTypes.AUTH_LOGOUT_SUCCESS:
      case ActionTypes.CLEAR_STORE:
        return {};
      default:
        return state;
    }
  }
}

export default SettingsReducer.reduce;
