import { AUTH_MODE } from 'shared/modules/global-settings/hooks/use-global-settings.hooks';
import { UseGlobalSettingsContext } from 'shared/modules/global-settings/state/global-settings-context.provider';

const useHasAuthMode = (): {
  hasAuthMode: (requestedMethod: AUTH_MODE) => boolean;
  hasSamlSso: boolean;
  hasGoogleSso: boolean;
  hasMicrosoftSso: boolean;
} => {
  const { globalSettings } = UseGlobalSettingsContext();
  const hasAuthMode = (requestedMethod: AUTH_MODE): boolean =>
    Boolean(globalSettings.auth_methods?.methods?.includes(requestedMethod));

  return {
    hasAuthMode,
    hasSamlSso: hasAuthMode(AUTH_MODE.SAML),
    hasGoogleSso: hasAuthMode(AUTH_MODE.GOOGLE),
    hasMicrosoftSso: hasAuthMode(AUTH_MODE.MICROSOFT),
  };
};

export default useHasAuthMode;
