
import ActionTypes from 'shared/utilities/action-types';

/**
 * Dispatch delete action type to modules reducer
 *
 * @returns {function} Function to dispatch
 *
 * @category elearning
 * @subcategory services
 * @exports dispatchSetPlayerData
 */
export default function dispatchSetPlayerData() {
  return (dispatch) => {
    dispatch({ type: ActionTypes.DELETE_PLAYER_DATA, payload: {} });
  };
}
