
import getStatusOfModule from 'elearning/helpers/get/get-status-of-module';

/**
 * Returns an array of all modules including a status in text form
 *
 * @param {*} categories
 * @returns modules
 *
 * @category elearning
 * @subcategory helpers
 * @exports getAllModulesFromCategories
 */
export default function getAllModulesFromCategories(categories) {
  const allModules = [];
  if (!categories) return allModules;
  const allCategories = [...categories];
  allCategories.forEach((cat) => {
    if (!cat.modules) return;
    cat.modules.forEach((mod) => {
      const currentMod = mod;
      currentMod.statusOfModule = getStatusOfModule(mod);
      allModules.push(currentMod);
    });
  });
  return allModules;
}
