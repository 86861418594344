
import { daysBetweenDates } from 'shared/helpers';
import { WELCOME_MESSAGES } from 'shared/utilities/welcome-message-states';
import { AuthProps } from 'types';
import { GamificationSettings } from 'types/gamification-settings';

const MAX_LOGIN_SINCE_MODULE = 3;
const MAX_LOGIN_SINCE_BADGE = 3;

/**
 * Returns inactivity message for welcome card
 *
 * @param {*} user
 * @param {*} gamificationSettings
 * @returns WelcomeStateKey
 *
 * @category elearning
 * @subcategory helpers
 * @exports getInactivityMessage
 */
export default function getInactivityMessage(
  user: AuthProps['user'],
  gamificationSettings: GamificationSettings
) {
  if (!user || !user?.game) return null;
  const { game } = user;
  const { activities } = game;
  const badgesActivated = gamificationSettings && gamificationSettings.badges;
  const levelActivated = gamificationSettings && gamificationSettings.level;
  const messages: string[] = [];

  const isAllAvailableModulesFinished = game.progress.completed === game.progress.total;

  if (activities.loginsSinceBadge === MAX_LOGIN_SINCE_BADGE && badgesActivated) {
    messages.push(WELCOME_MESSAGES.INACTIVE_BADGE);
  }

  if (
    activities.loginsSinceModule === MAX_LOGIN_SINCE_MODULE &&
    levelActivated &&
    !isAllAvailableModulesFinished
  ) {
    messages.push(WELCOME_MESSAGES.INACTIVE_MODULE);
  }

  if (activities.lastLogin) {
    const diff = daysBetweenDates(new Date(activities.lastLogin), new Date());
    if (diff >= 21) {
      messages.push(WELCOME_MESSAGES.INACTIVE_LOGIN);
    }
  }
  if (!messages.length) return null;

  return messages[Math.floor(Math.random() * messages.length)];
}
