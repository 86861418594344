
import Form from "react-bootstrap/Form";
import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// Components
import CustomButton from "../components/custom-button";

// Scss
import "./modal-alert.scss";

const MySwal = withReactContent(Swal);

export const ALERT_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
  INFO: "info",
  WARNING: "warning",
  QUESTION: "question",
  /**
   * No icon will be displayed
   */
  NONE: "none",
};

/**
 * Shows notification on top right.
 *
 * @param  {ALERT_TYPE} type alert type
 * @param  {string} msg message
 * @param  {number} [time] time
 * @returns null
 *
 * @category shared
 * @subcategory utilities
 * @exports showToast
 */
export function showToast(type, msg, time = 1800) {
  const popup = `${type}-alert-border`;
  const container = "sosafe-ModalAlert-alertContainer";
  if (!Swal.isVisible()) {
    Swal.fire({
      position: "top-end",
      icon: type,
      title: msg,
      showConfirmButton: false,
      timer: time,
      toast: true,
      customClass: { popup, container },
      role: "alert",
      iconColor: type === ALERT_TYPE.SUCCESS ? "#000" : null,
    });
  }
}

/**
 * @typedef {Object} showToastWithCallbackOptions
 * @property {ALERT_TYPE} type alert type
 * @property {string} msg message
 * @property {string} [confirmButtonText] confirmButtonText
 * @property {string} [confirmButtonClass] confirmButtonClass
 * @property {number} [time] time
 */

/**
 * Shows notification on top right.
 *
 * @param  {showToastWithCallbackOptions} options options
 * @returns {Object} Swal.fire-Object
 *
 * @category shared
 * @subcategory utilities
 * @exports showToastWithCallback
 */
export function showToastWithCallback(options) {
  const popup = `${options.type}-alert-border`;
  const container = "sosafe-ModalAlert-alertContainerCallback";
  return Swal.fire({
    position: "top-end",
    icon: options.type,
    title: options.msg,
    showConfirmButton: true,
    confirmButtonText: options.confirmButtonText || "Confirm",
    confirmButtonClass: options.confirmButtonClass || "btn btn-info",
    timer: options.time || 4500,
    toast: true,
    customClass: { popup, container },
  });
}

/**
 * @typedef {Object} showDecisionAlertOptions
 * @property {?} type icon
 * @property {?} title title
 * @property {?} msg
 * @property {Array} buttons Array with Objects as Values. Objects: {label, callback, style, variant, isCancelButton}; style, variant: not required. (variant='dark' often used.); isCancelButton: not required, default to false. If true, callback will be set by this function.
 * @property {function} [dismissCallback] function gets called if user clicks on background
 * @property {string} customAlertClass
 * @property {boolean} [showCloseButton]
 * @property {function} [closeButtonAriaLabel]
 */

/**
 * User needs to choose a option Sweetalert
 *
 * @param {showDecisionAlertOptions} options
 * @returns null
 *
 * @category shared
 * @subcategory utilities
 * @exports showDecisionAlert
 */
export function showDecisionAlert(options) {
  const allowEnterKey = options.allowEnterKey ? options.allowEnterKey : false;
  const preConfirm =
    options.preConfirm && typeof options.preConfirm === "function" ? options.preConfirm : null;

  const { buttons, customAlertClass } = options;
  const footer = getButtonFooter(buttons, allowEnterKey);

  let dismissCallback = () => {};
  if (options.dismissCallback && typeof options.dismissCallback === "function") {
    dismissCallback = options.dismissCallback;
  }

  let didDestroy = () => {};
  if (options.didDestroy && typeof options.didDestroy === "function") {
    didDestroy = options.didDestroy;
  }

  const html = options.msg;
  MySwal.fire({
    icon: options.type,
    title: options.title,
    html,
    buttonsStyling: false,
    showConfirmButton: false,
    footer,
    willClose: dismissCallback,
    allowEnterKey,
    showCloseButton: options.showCloseButton !== null ? options.showCloseButton : false,
    closeButtonAriaLabel: options.closeButtonAriaLabel !== null ? options.closeButtonAriaLabel : "",
    customClass: {
      closeButton: "custom-close-button",
      footer: "custom-footer",
      title: "custom-font",
      content: "custom-font",
      icon: "custom-icon",
      container: customAlertClass,
    },
    preConfirm,
    focusConfirm: false,
    didDestroy,
  });
}

function getButtonFooter(buttons, allowEnterKey) {
  let footer = [];
  const autofocus = buttons.length === 1 && allowEnterKey;

  buttons.forEach((btnConfig, idx) => {
    let button = null;
    const style = btnConfig.style || {};
    const variant = btnConfig.variant || "secondary";

    if (
      btnConfig.isCancelButton ||
      !btnConfig.callback ||
      typeof btnConfig.callback !== "function"
    ) {
      button = (
        <CustomButton
          autoFocus={autofocus}
          style={{
            ...style,
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            padding: "5px",
          }}
          variant={variant}
          key={`swalFooter-Button-${idx}`}
          onClick={() => {
            Swal.close();
          }}
          disabled={btnConfig.disabled}
          label={btnConfig.label}
        />
      );
    } else {
      const { callback } = btnConfig;
      button = (
        <CustomButton
          autoFocus={autofocus}
          style={{
            ...style,
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            padding: "5px",
          }}
          variant={variant}
          key={`swalFooter-Button-${idx}`}
          onClick={() => {
            callback();
            Swal.close();
          }}
          disabled={btnConfig.disabled}
          label={btnConfig.label}
          ariaLabel={btnConfig.ariaLabel}
        />
      );
    }
    footer.push(button);
  });
  footer = <div className="mb-3 d-flex">{footer}</div>;
  return footer;
}
