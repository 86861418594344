import { Button } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import { IconTexture } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react/icons';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SsoFlow } from './sso-form-v2';

interface SamlSsoButtonProps {
  type: SsoFlow;
  onClick: () => void;
}

const SamlSsoButton: React.FC<SamlSsoButtonProps> = ({
  type,
  onClick,
}): ReactElement => {
  const { t } = useTranslation('translations');

  return (
    <Button
      size="sm"
      justify="space-between"
      leftSection={<IconTexture size={14} />}
      rightSection={<span />}
      variant="secondary"
      onClick={onClick}
      aria-label={
        type === 'LOGIN' ? t('Login with SAML') : t('Registration with SAML')
      }
    >
      {type === 'LOGIN' ? t('Login with SAML') : t('Registration with SAML')}
    </Button>
  );
};

export default SamlSsoButton;
