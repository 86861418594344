import { CHART_CONFIG } from './chart-config';
import { toFixedFloat } from '../transform';

const { MIDDLE } = CHART_CONFIG;

interface ChartUtils {
  polarToX: (angle: number, distance: number) => number;
  polarToY: (angle: number, distance: number) => number;
  calculateDashArray: (radius: number, progress: number, isComplete: boolean) => string;
  pathDefinition: (points: [number, number][]) => string;
}

export const chartUtils: ChartUtils = {
  polarToX: (angle: number, distance: number): number =>
    MIDDLE + Math.cos(angle - Math.PI / 2) * distance,

  polarToY: (angle: number, distance: number): number =>
    MIDDLE + Math.sin(angle - Math.PI / 2) * distance,

  calculateDashArray: (radius: number, progress: number, isComplete: boolean): string => {
    const circumference = Math.PI * radius * 2;
    return isComplete ? `${circumference} 0` :
      `${toFixedFloat(circumference * progress)} ${toFixedFloat(circumference)}`;
  },

  pathDefinition: (points: [number, number][]): string => {
    let d = `M${points[0][0]},${points[0][1]}`;
    for (let i = 1; i < points.length; i++) {
      d += `L${points[i][0]},${points[i][1]}`;
    }
    return `${d}z`;
  }
};
