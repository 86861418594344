
/**
 * Checks if browser is checkIsSafari
 *
 * @export checkIsSafari
 * @returns bool
 */

export default function checkIsSafari() {
  const ua = window.navigator.userAgent;
  return /^((?!chrome|android).)*safari/i.test(ua);
}
