
// Packages
import React, { useState, FC } from "react";
import color from "color";
import { Tooltip } from "@sosafe-platform-engineering/fe-lib-ui-react";
import { useTranslation } from "react-i18next";
import SvgHandlingWrapper from "shared/components/svg-handling-wrapper";
import { toFixedFloat, checkIsIE } from "shared/helpers";
import { getContrastColor } from "shared/helpers/a11y";
import { CHART_CONFIG, chartUtils } from "shared/helpers/chart";
import { ChartProps, ChartColumn } from "types/charts-types";
import { isAllModulesPassed } from '../utils/module-helpers';
import "./experience-cake-chart.scss";

const ExperienceCakeChart: FC<ChartProps> = ({
  data = [],
  className = "",
  loading = null
}) => {
  const { t } = useTranslation("translations");
  const [ref] = useState<React.RefObject<HTMLDivElement>>(React.createRef());

  const { SIZE, MIDDLE, CAKE } = CHART_CONFIG;
  const maxCircleRadius = CAKE.MAX_RADIUS_MULTIPLIER * SIZE;
  const circleWidth = CAKE.CIRCLE.WIDTH_MULTIPLIER * SIZE;
  const circleMargin = CAKE.CIRCLE.MARGIN_MULTIPLIER * circleWidth;
  const labelWidth = SIZE * CAKE.LABEL.WIDTH_MULTIPLIER;
  const labelHeight = SIZE * CAKE.LABEL.HEIGHT_MULTIPLIER;

  const columns: ChartColumn[] = data.map((d, i) => {
    const allModulesPassed = isAllModulesPassed(d.modules);
    const currentProgress = allModulesPassed ? 1 : d.progress;
    const currentAngle = Math.PI * 2 * (currentProgress === 1 ? 0.99 : currentProgress);
    const currentRadius = maxCircleRadius - i * (circleWidth + circleMargin);
    return {
      ...d,
      progress: currentProgress,
      angle: currentAngle,
      radius: toFixedFloat(currentRadius),
      x: toFixedFloat(chartUtils.polarToX(currentAngle, currentRadius)),
      y: toFixedFloat(chartUtils.polarToY(currentAngle, currentRadius)),
    };
  });

  const circle = (col: ChartColumn, i: number): JSX.Element => (
    <circle
      strokeWidth={circleWidth}
      opacity="0.2"
      cx={MIDDLE}
      cy={MIDDLE}
      r={col.radius}
      className="stroke-light fill-none"
      key={i.toString()}
    />
  );

  const indicator = (col: ChartColumn, i: number): JSX.Element => {
    const allModulesPassed = isAllModulesPassed(col.modules);
    const dashArray = chartUtils.calculateDashArray(col.radius ?? 0, col.progress, allModulesPassed);

    return (
      <circle
        cx={MIDDLE}
        cy={MIDDLE}
        stroke={color(col.color).fade(0.6)}
        strokeWidth={circleWidth}
        r={col.radius}
        strokeLinecap="round"
        strokeDasharray={dashArray}
        className="fill-none cakechart-indicator-animation"
        transform={`rotate(-90) translate(${-SIZE}, 0)`}
        key={i.toString()}
      />
    );
  };

  const dots = (col: ChartColumn, idx: number): JSX.Element => {
    const allModulesPassed = isAllModulesPassed(col.modules);
    return (
      <Tooltip content={col.name} key={idx.toString()}>
        <g
          className="cakechart-dots-animation"
          aria-label={`${col.name} - ${t("completed", {
            percentage: allModulesPassed ? 100 : Math.floor(100 * col.progress),
          })}`}
        >
          <circle fill={col.color} cx={col.x} cy={col.y} r={circleWidth / 2} />
          <text
            textAnchor="middle"
            x={col.x}
            y={col.y}
            dy={circleWidth * 0.22}
            fontSize={circleWidth * 0.65}
            className="cursor-default"
            fill={getContrastColor(col.color)}
          >
            {idx + 1}
          </text>
        </g>
      </Tooltip>
    );
  };

  const label = (col: ChartColumn, i: number): JSX.Element => {
    const labelCircleRadius = 1.3 * maxCircleRadius;
    const allModulesPassed = isAllModulesPassed(col.modules);
    const topRight = col.angle < 0.5 * Math.PI;
    const bottomRight = 0.5 * Math.PI <= col.angle && col.angle < Math.PI;
    const bottomLeft = Math.PI <= col.angle && col.angle < 1.5 * Math.PI;
    const topLeft = 1.5 * Math.PI <= col.angle;

    let labelDiffAngle = 0;
    if (topRight) labelDiffAngle = 0.4;
    if (bottomRight) labelDiffAngle = -0.4;
    if (bottomLeft) labelDiffAngle = 0.4;
    if (topLeft) labelDiffAngle = -0.4;

    const labelPos: [number, number] = [
      chartUtils.polarToX(col.angle + labelDiffAngle, labelCircleRadius),
      chartUtils.polarToY(col.angle, labelCircleRadius),
    ];

    let polyMiddlePoint: [number, number] = [0, 0];
    if (topRight) polyMiddlePoint = [labelPos[0] - labelWidth * 0.7, labelPos[1]];
    if (bottomRight) polyMiddlePoint = [labelPos[0] - labelWidth * 0.7, labelPos[1]];
    if (bottomLeft) polyMiddlePoint = [labelPos[0] + labelWidth * 0.7, labelPos[1]];
    if (topLeft) polyMiddlePoint = [labelPos[0] + labelWidth * 0.7, labelPos[1]];

    return (
      <g className="cakechart-labels-animation" key={i.toString()}>
        <polyline
          stroke={col.color}
          strokeWidth=".6"
          points={`${col.x} ${col.y} ${polyMiddlePoint[0]} ${polyMiddlePoint[1]} ${labelPos[0]} ${labelPos[1]}`}
          fill="none"
        />
        <use
          href="#cakechart-label"
          x={labelPos[0] - labelWidth / 2}
          y={labelPos[1] - labelHeight / 2}
        />
        <text
          textAnchor="middle"
          x={labelPos[0]}
          y={labelPos[1]}
          fontSize={labelHeight * 0.5}
          dy={labelHeight * 0.15}
          className="cursor-default fill-dark"
        >
          {allModulesPassed ? "100%" : `${Math.floor(100 * col.progress)}%`}
        </text>
      </g>
    );
  };

  const groups: JSX.Element[] = [];
  groups.push(<g key="group-circles">{columns.map((col, i) => circle(col, i))}</g>);
  groups.push(<g key="group-indicator">{columns.map((col, i) => indicator(col, i))}</g>);
  groups.push(<g key="group-labels">{columns.map((col, i) => label(col, i))}</g>);
  groups.push(<g key="group-dots">{columns.map((col, i) => dots(col, i))}</g>);

  return (
    <SvgHandlingWrapper className={className} propRef={ref} loading={loading}>
      <svg
        height="100%"
        viewBox={`0 0 ${SIZE} ${SIZE}`}
        preserveAspectRatio="xMidYMid meet"
        className={`cakechart ${checkIsIE() ? "is-ie" : ""}`}
        aria-label={t("chart_progress_aria_label")}
        role="img"
      >
        <defs>
          <filter
            x="-1"
            y="-1"
            width="3"
            height="3"
            filterUnits="objectBoundingBox"
            id="cakechart-labelShadow"
          >
            <feOffset dx="1" dy="1" in="SourceAlpha" result="cakechart-labelOffset" />
            <feGaussianBlur
              stdDeviation="1.5"
              in="cakechart-labelOffset"
              result="cakechart-labelBlur"
            />
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"
              type="matrix"
              in="cakechart-labelBlur"
              result="cakechart-labelColorMatrix"
            />
            <feBlend in="SourceGraphic" in2="cakechart-labelColorMatrix" mode="normal" />
          </filter>
          <rect
            id="cakechart-label"
            filter="url(#cakechart-labelShadow)"
            fill="white"
            height={labelHeight}
            width={labelWidth}
            rx="1"
          />
        </defs>
        <g>{groups}</g>
      </svg>
    </SvgHandlingWrapper>
  );
};

export default ExperienceCakeChart;
