
// Packages
import React, { useState } from 'react';
import soSafeAxios from 'state/axios';
import { Form } from 'react-bootstrap';
import { Card } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Utilities
import { URL_AUTH_API } from 'shared/helpers';
import useMetaLinks from 'shared/hooks/use-meta-links';
import { UseReferenceHandler } from 'shared/utilities/use-reference-handler';

// Components
import AuthImage from 'authentication/components/auth-image';
import CustomButton from 'shared/components/custom-button';
import Helmet from 'shared/components/helmet';
import AuthHeader from 'authentication/components/auth-header';
import PasswordControl from 'shared/components/password-control';
import Validators from 'shared/utilities/validators';

// Scss
import 'scss/base.scss';
import { useSoSafeConnect } from 'shared/modules/sosafe-connect';

export default function SetPasswordPage() {
  const { token } = useParams();
  const { t, i18n } = useTranslation(['translations', 'helmetTranslations', 'placeholderTranslations']);
  const [loading, setLoading] = useState(false);
  const { endPoints } = useSoSafeConnect();
  const checkReference = UseReferenceHandler();

  const { support } = useMetaLinks();

  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  const setNewPassword = () => {
    setLoading(true);
    endPoints.put.resetPassword({ password: password1, code: token }).then((response) => {
      checkReference(response.data);
      setLoading(false);
      setPassword1('');
      setPassword2('');
    }).catch((error) => {
      setPassword1('');
      setPassword2('');
      if (error.response) {
        checkReference(error.response.data);
        setLoading(false);
      } else {
        checkReference({ title: error.name, status: error.statusCode, reference: '40700745-2789-4F88-8412-1885DDC724E4' });
        setLoading(false);
      }
    });
  };

  return (
    <React.Fragment>
      <Helmet title={t('helmetTranslations:Restore password')} />

      <AuthHeader />

      <div className='m-auto auth-card-width'>
        <Card shadow="sm" className='overflow-hidden'>
          <div className='d-flex flex-row'>
            <AuthImage
              flipx={(i18n.language === 'ar')}
            />
            <div className='base-card-right py-3 px-3 flex-grow-1'>
              <h2 className='mb-2 h1 text-center'>{t('Set new password')}</h2>
              <hr />
              <Form>
                <Helmet title={t('helmetTranslations:Restore password')} />
                <PasswordControl
                  name='password_1'
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                  secondPassword={password2}
                  showHint
                  newPassword
                />
                <PasswordControl
                  hideIndicator
                  name='password_2'
                  formLabel={t('Repeat Password')}
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  firstPassword={password1}
                  newPassword
                />
                <CustomButton
                  onClick={setNewPassword}
                  disabled={!formIsValid(password1, password2)}
                  label={loading ? t('Setting new password...') : t('Confirm')}
                  className='w-100 mb-3'
                  variant='secondary'
                />
              </Form>

              <p className='text-center'>
                {t('Remembered your password?')}
                <Link
                  className='text-nowrap ml-2'
                  to='/'
                >
                  {t('Go to login')}.
                </Link>
              </p>

              {support && (
                <React.Fragment>
                  <hr />
                  <p className='text-center mb-1'>
                    <a
                      href={support}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {t('Go to support')}
                    </a>
                  </p>
                </React.Fragment>
              )}
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
}

function formIsValid(password1, password2) {
  const validations = [
    Validators.required(password2).valid,
    Validators.matches(password1, password2).valid,
    Validators.password(password1).valid,
  ];
  return validations.every((item) => item);
}
