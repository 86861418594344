
// Utilities
import { tourMobileCheck } from 'shared/helpers';
import ActionTypes from 'shared/utilities/action-types';
import { store } from 'state/store/store';

function showOptIn() {
  return (dispatch) => {
    dispatch({ type: ActionTypes.OPTIN_CHANGED, optin: true });
  };
}

export function closeOptIn() {
  return (dispatch) => {
    dispatch({ type: ActionTypes.OPTIN_CHANGED, optin: false });
  };
}

export function closeWelcome() {
  return (dispatch) => {
    dispatch({ type: ActionTypes.WELCOME_CHANGED, welcome: false });
  };
}

function showWelcome() {
  return (dispatch) => {
    dispatch({ type: ActionTypes.WELCOME_CHANGED, welcome: true });
  };
}

export function validateOnboarding(width, simulationOptIn, tourStart) {
  const state = store.getState();

  const { tour } = state.tour;
  const { user } = state.auth;
  const { welcome, firstLogin } = state.welcome;
  const { optin: isOptinOpen } = state.optin;

  if (!user) return () => { };

  const { opt_in: optIn } = user;

  if (tour) {
    const newId = tourMobileCheck({ id: tour, width });
    if (newId !== tour) {
      return tourStart(newId);
    }
  }

  if (simulationOptIn && (optIn === undefined || optIn === null)) {
    if (!isOptinOpen) {
      return showOptIn();
    }
  } else if (firstLogin && !isOptinOpen) {
    if (!welcome) {
      return showWelcome();
    }
  }
  return () => {};
}
