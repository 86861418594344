import { checkIsIE } from 'shared/helpers/check';
import { KEYS } from 'shared/utilities/constants';
import keyboardFocusCycle, { getKeyboardFocusableElements } from './keyboard-focus-cycle';

/**
 * Keyboard-Accesibility helper / callback for a mutationObserver
 * Can only be used as an observers-callback-function!
 * Makes sure all elements are keyboard accessible, even in IE11
 * adds a bunch of eventListeners to all necessary objects and tabbable functionality and more!
 *
 * @param {Array} mutationList An array of MutationRecord objects, default parameter from MutationObserver
 *
 * @category shared
 * @subcategory helpers
 * @exports keyboardA11y
 */
export default function keyboardA11y(mutationList) {
  const isIE = checkIsIE();

  const getNextAriaOption = (element, backwards = false) => {
    const parentElement = element.parentElement;
    const allOptionsArray = Array.from(parentElement.querySelectorAll('[role="option"]'));
    if (backwards) allOptionsArray.reverse();

    let nextElement = element;
    allOptionsArray.forEach((currentOption, index) => {
      if (currentOption === element) {
        nextElement = allOptionsArray[(index + 1) % allOptionsArray.length];
      }
    });
    return nextElement;
  };

  const keyDownEvent = (event) => {
    const element = event.target || null;
    if (event.which === KEYS.SPACE || event.which === KEYS.ENTER) {
      if (typeof element?.click === 'function') {
        element.click();
        return true;
      }
    }
    if (element?.getAttribute('role') === 'option') {
      if ([KEYS.ARROWUP, KEYS.ARROWRIGHT, KEYS.ARROWDOWN, KEYS.ARROWLEFT].includes(event.which)) {
        event.preventDefault();
        const nextElement = getNextAriaOption(element, (event.which === KEYS.ARROWUP || event.which === KEYS.ARROWLEFT));
        return nextElement?.focus() || true;
      }
    }
    return true;
  };

  const advancedFocus = (props) => {
    const { newElements, activeElement } = props;
    const focusElement = newElements.find((elem) => elem.getAttribute('aria-selected') === 'true') || newElements[0];
    if (!(focusElement instanceof Element)) return;

    const focusPositionHandler = (e, index, arr) => {
      if (e.which === KEYS.TAB) {
        e.preventDefault();
        if (e.shiftKey) {
          if (index > 0) {
            arr[index - 1].focus();
          } else if (typeof activeElement?.click === 'function') {
            activeElement.focus();
            activeElement.click();
          }
          return;
        }
        if (index < arr.length - 1) {
          arr[index + 1].focus();
        } else if (typeof activeElement?.click === 'function') {
          activeElement.focus();
          activeElement.click();
        }
      }

      if (e.which === KEYS.HOME) {
        e.preventDefault();
        arr[0].focus();
      } else if (e.which === KEYS.END) {
        e.preventDefault();
        arr[arr.length - 1].focus();
      }

      if (e.which === KEYS.SPACE || e.which === KEYS.ENTER) {
        if (typeof activeElement?.click === 'function') {
          // to move the focus to the original element, after React re-renders the page.
          // this is a bit hacky, but hey, it works in IE...
          setTimeout(() => {
            activeElement.focus();
          }, 50);
        }
      }
    };

    const escapeHandler = (e) => {
      if ([KEYS.ESCAPE, KEYS.BACKSPACE].includes(e.which)) {
        if (typeof activeElement?.click === 'function') {
          activeElement.focus();
          activeElement.click();
        }
      }
    };

    focusElement.focus();
    newElements.forEach((element, index, arr) => {
      element.addEventListener('keydown', (e) => { focusPositionHandler(e, index, arr); });
      element.addEventListener('keyup', escapeHandler);
    });
  };

  const addListboxPopupEvents = (haspopupElem) => {
    haspopupElem.addEventListener('keydown', (e) => {
      if ([KEYS.ARROWUP, KEYS.ARROWDOWN].includes(e.which)) {
        if (typeof haspopupElem?.click === 'function') {
          e.preventDefault();
          haspopupElem.click();
        }
      }
    });
  };

  // node and element helpers, to discern if there are any actions necessary
  const isVisible = (element) => ((element?.getBoundingClientRect()?.width || 0) + (element?.getBoundingClientRect()?.height || 0) > 0);
  const isTabbable = (node) => { if (node instanceof Element) return getKeyboardFocusableElements(node).length; return false; };
  const addedTabbableNodes = (nodelist) => Array.from(nodelist).find((node) => isTabbable(node));
  const customFocusElementChildren = (node) => { if (node instanceof Element) return node.querySelectorAll('[role="button"],[role="option"]'); return []; };
  const hasNavOrListbox = (node) => { if (node instanceof Element) return node.querySelectorAll('[role="listbox"],nav').length; return false; };
  const getDialogElements = (node) => {
    if (node instanceof Element && node.getAttribute('role') === 'dialog') return [node];
    if (node instanceof Element) return node.querySelectorAll('[role="dialog"]');
    return [];
  };
  const hasListboxPopup = (node) => { if (node instanceof Element) return node.querySelectorAll('[aria-haspopup="listbox"]'); return []; };

  mutationList.forEach((mutation) => {
    const targetElement = mutation.target || null;
    if (!targetElement || !mutation.addedNodes.length) return;

    if (
      addedTabbableNodes(mutation.addedNodes)
            || isTabbable(targetElement)
            || hasNavOrListbox(targetElement)
    ) {
      // set svg-focusable attribute to false in IE - svgs have an implicit focus in IE that they shouldn't have!
      if (isIE) {
        const svgs = targetElement.querySelectorAll('svg:not([focusable])');
        svgs.forEach((svg) => {
          svg.setAttribute('focusable', 'false');
        });
      }

      // make button and option Nodes tabbable and keyboard-clickable, if they are in scope
      const customFocusElements = customFocusElementChildren(targetElement);
      let newElements = [];
      customFocusElements.forEach((focusElement) => {
        if (focusElement.getAttribute('aria-disabled') !== 'true' && isVisible(focusElement)) {
          focusElement.tabIndex = 0;
          focusElement.onkeydown = keyDownEvent;
          if (isIE && focusElement.tagName === 'svg') focusElement.removeAttribute('focusable');
          newElements.push(focusElement);
        }
      });

      // add arrow-events to buttons that have a listbox-popup (dropdown-menus)
      mutation.addedNodes.forEach((node) => {
        hasListboxPopup(node).forEach((haspopupElem) => {
          addListboxPopupEvents(haspopupElem);
        });
      });

      // this part manages the advanced-focus of modals and dropdowns, should have a pretty
      // restrictive condition to ignore wrong elements (should not be used on <body> or div.app, eg.)
      if (isVisible(targetElement) && hasNavOrListbox(targetElement) && document.activeElement !== document.body && !targetElement.classList.contains('app')) {
        newElements = newElements.filter((element) => {
          const addedFocusElements = [];
          mutation.addedNodes.forEach((node) => {
            customFocusElementChildren(node).forEach((elem) => addedFocusElements.push(elem));
          });
          return (addedFocusElements.includes(element) && isVisible(element));
        });
        // jump into first interactive element of newly created modal, if any exist and
        // add functionality to return to origin on leaving active parent element
        if (newElements.length) {
          advancedFocus({ newElements, activeElement: document.activeElement });
        }
      }
    }
    // new dialog added? get it and create keyboardFocusCycle inside
    const addedDialogElements = [];
    mutation.addedNodes.forEach((node) => {
      getDialogElements(node).forEach((elem) => addedDialogElements.push(elem));
    });
    if (addedDialogElements.length) {
      addedDialogElements.forEach((dialogElement) => {
        if (isVisible(dialogElement) && dialogElement.getAttribute('aria-disabled') !== 'true') {
          keyboardFocusCycle(dialogElement);
        }
      });
    }
  });
}
