
/**
 * Checks if browser is IE
 *
 * @export checkIsIE
 * @returns bool
 */
export default function checkIsIE() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const msie11 = ua.indexOf('Trident/');
  const isIE = msie > 0 || msie11 > 0;
  return isIE;
}
