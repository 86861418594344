
import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ErrorCollapsible from 'shared/components/error-collapsible';
import { getUrlParameter } from 'shared/helpers/get';
import GlobalSettingsContext from 'shared/modules/global-settings/state/global-settings-context.provider';
import { ALERT_TYPE } from 'shared/utilities/modal-alert';
import { LOGIN_METHOD } from 'types';
import { useModalContext } from 'modal-context/modal-context.tsx';
import { Button, Text, Title } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { UseLoginRequest } from './use-login-request';
import { useRegisterUser } from './use-register-user';
import { UseShowDecisionAlert } from '../../flamingo-e-learning-platform/utilities/modal-alert/use-show-decision-alert'

const CLIENT_ID = '7a8bd249-14a5-4311-86fa-0aac18aa6698';
const SCOPE = 'openid profile user.read';
const REDIRECT_URI = window.location.origin;

function getToken(code, codeChallenge) {
  const postObj = {
    code,
    client_id: CLIENT_ID,
    scope: SCOPE,
    redirect_uri: REDIRECT_URI,
    grant_type: 'authorization_code',
    code_verifier: codeChallenge,
  };

  const formData = new FormData();
  for (const key in postObj) {
    if (postObj[key]) {
      formData.append(key, postObj[key]);
    }
  }

  return axios
    .post(
      'https://login.microsoftonline.com/common/oauth2/v2.0/token',
      formData,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
}

export default function useMsSso() {
  const { login } = UseLoginRequest();
  const { t, i18n } = useTranslation('messageTranslations');
  const history = useHistory();
  const { register } = useRegisterUser();
  const { close } = useModalContext();
  const { show } = UseShowDecisionAlert();

  // Error handling
  useEffect(() => {
    const errorHashString = getUrlParameter(window.location.hash, 'error');
    const errorSubcodeHashString = getUrlParameter(window.location.hash, 'error_subcode');

    if (errorHashString) {
      if (errorSubcodeHashString && errorSubcodeHashString === 'cancel') {
        sessionStorage.removeItem('MSAL_STATE');
        history.push('/');
      }
    }
  }, []);

  useEffect(() => {
    const stateHashString = getUrlParameter(window.location.hash, 'state');
    const code = getUrlParameter(window.location.hash, 'code');
    let state = null;
    try {
      state = JSON.parse(decodeURIComponent(stateHashString));
    } catch {
      state = null;
    }

    if (code && state && state.codeChallenge) {
      window.location.hash = '';

      getToken(code, state.codeChallenge)
        .then(({ data }) => {
          if (state.type === 'REGISTER') {
            register({ sso: data.access_token, method: LOGIN_METHOD.MICROSOFT });
          } else {
            login({ sso: data.access_token, method: LOGIN_METHOD.MICROSOFT });
          }
        })
        .catch((error) => {
          show({
              title:  <Title size="h2">{t('Error')}</Title>,
              content: <div>
                          <Text>
                            {t('messageTranslations:An error has occured')}
                          </Text>
                          <ErrorCollapsible
                            error={error}
                            response={error?.response}
                          />
                       </div>,
              footer: <>
                        <Button variant='primary' aria-label={t('Close')} onClick = {() => close()}>{t('Close')}</Button>
                      </>,
              type: ALERT_TYPE.ERROR,
          })
          sessionStorage.removeItem('MSAL_STATE');
        });
    }
  }, []);

  const { globalSettings } = useContext(GlobalSettingsContext);
  const { domain: brandingDomain } = globalSettings;

  return {
    signIn: (type) => signIn(type, brandingDomain),
  };
}

function signIn(type, brandingDomain) {
  sessionStorage.setItem('MSAL_STATE', 'SIGNIN');
  const codeChallenge = Array(128).fill(42).map(() => Math.random().toString(36).charAt(2))
    .join('');
  const state = {
    codeChallenge,
    type,
  };

  const urlQueryParams = [
    'response_type=code',
    `redirect_uri=${encodeURIComponent(REDIRECT_URI)}`,
    'response_mode=fragment',
    `scope=${encodeURIComponent(SCOPE)}`,
    `client_id=${CLIENT_ID}`,
    `code_challenge=${codeChallenge}`,
    'code_challenge_method=plain',
    `state=${encodeURIComponent(JSON.stringify(state))}`,
  ];

  const ssoDomain = getUrlParameter(window.location.search, 'domain');
  if (ssoDomain) {
    urlQueryParams.push(`domain_hint=${ssoDomain}`);
  } else if (brandingDomain) {
    urlQueryParams.push(`domain_hint=${brandingDomain}`);
  }

  window.location.replace(`https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?${urlQueryParams.join('&')}`);
}
