
import i18n from 'lang/i18n';
import ErrorCollapsible from 'shared/components/error-collapsible';
import ActionTypes from 'shared/utilities/action-types';
import { ALERT_TYPE, showToast } from 'shared/utilities/modal-alert';
import React from 'react';
import { ModalBody } from 'flamingo-e-learning-platform/utilities/modal-alert/modal-body';
import { Button } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import { ERROR_CODES, SUCCESS_CODES } from './use-reference-handler';

export function getReferenceObjById(id) {
  const error = ERROR_CODES.find((c) => c.id === id);
  const success = SUCCESS_CODES.find((c) => c.id === id);
  return error || success;
}

export function getMessageByReference(reference) {
  const error = ERROR_CODES.find((c) => c.reference === reference);
  const success = SUCCESS_CODES.find((c) => c.reference === reference);
  return error?.message || success?.message;
}

export function isReferenceIdMatch(reference, id) {
  const error = ERROR_CODES.find((c) => c.id === id);
  const success = SUCCESS_CODES.find((c) => c.id === id);
  if (error) {
    return reference === error.reference;
  }
  if (success) {
    return reference === success.reference;
  }
  return false;
}

export function isReferenceUpdate(props, nextProps, referenceKey) {
  let refNew = null;
  let refOld = null;
  if (referenceKey) {
    refNew = nextProps[referenceKey];
    refOld = props[referenceKey];
  } else {
    refNew = nextProps.reference;
    refOld = props.reference;
  }
  if (refNew && refNew !== refOld) {
    return true;
  }
  return false;
}

export function getIdByReference(reference) {
  const error = ERROR_CODES.filter((c) => c.reference === reference);
  const success = SUCCESS_CODES.filter((c) => c.reference === reference);

  if (error && error.length > 0) {
    if (error.length > 1) {
      console.warn(`[Reference handling] Multiple action types for reference ${reference}`);
    }
    return error[0].id;
  }
  if (success && success.length > 0) {
    if (success.length > 1) {
      console.warn(`[Reference handling] Multiple action types for reference ${reference}`);
    }
    return success[0].id;
  }
  return null;
}

export function getReferenceById(id) {
  const error = ERROR_CODES.filter((c) => c.id === id);
  const success = SUCCESS_CODES.filter((c) => c.id === id);
  return error || success;
}

export function checkReference(data, dispatch, modal) {
  const { reference, status } = data;
  const translationClass = i18n.language === 'ar' ? 'arab' : '';

  if (!dispatch) {
    dispatch = () => { };
  }

  let alertOptions = {
    set: false,
    type: 'TOAST',
    alert_type: ALERT_TYPE.ERROR,
    message: i18n.t('messageTranslations:An error has occured'),
    title: '',
    callback: () => { },
  };

  if (reference) {
    const payload = data;
    switch (reference) {
      case '2CFF5FC6-BA93-48CA-B87C-4CFAC86F9D5C': // Access Level not allowed
        dispatch({
          type: ActionTypes.AUTH_ROLE_ACCESS,
          payload,
        });
        return {};
      case '79917939-D48D-488A-B2CB-96F650C9FB12': // Access Customer not allowed
        dispatch({
          type: ActionTypes.AUTH_CUSTOMER_ACCESS,
          payload,
        });
        return {};
      case '8595105A-E431-4D49-843D-C6AA3930FFBB': // Api Token is temporary invalid
        dispatch({
          type: ActionTypes.AUTH_TEMPORARY_INVALID,
          payload,
        });
        return {};
      case '250532AD-B877-47DB-B1E2-8CFED586BCBD': // Api Token is missing
        dispatch({
          type: ActionTypes.AUTH_TOKEN_MISSING,
          payload,
        });
        return {};
      case '94E1AFCD-CDCD-47AF-8C84-33CD16A3B8E0': // ELEARNING_NOT_ACTIVE
        dispatch({
          type: ActionTypes.AUTH_ELEARNING_NOT_ACTIVE,
          payload,
        });
        break;
      case 'E3A48F0F-87CC-4995-AE41-338EFD5A6B67': // Api Token is invalid
        dispatch({
          type: ActionTypes.AUTH_TOKEN_INVALID,
          payload,
        });
        break;
      default: {
        const error = ERROR_CODES.find((c) => c.reference === reference);
        const success = SUCCESS_CODES.find((c) => c.reference === reference);
        if (error || success) {
          if (error) {
            alertOptions = {
              set: true,
              type: error.type || 'TOAST',
              alert_type: ALERT_TYPE.ERROR,
              message: i18n.t(error.message),
              title: typeof error.title === 'string' ? i18n.t(error.title) : error.title || '',
              callback: error.callback || null,
            };
          } else if (success) {
            alertOptions = {
              set: true,
              type: success.type || 'TOAST',
              alert_type: ALERT_TYPE.SUCCESS,
              message: i18n.t(success.message),
              title:
                typeof success.title === 'string' ? i18n.t(success.title) : success.title || '',
              callback: success.callback || null,
            };
          }
        }
        break;
      }
    }
  } else if (status) {
    // Handle HTTP Status Codes; no reference
    let message = i18n.t('messageTranslations:An error has occured');
    switch (status) {
      case 403: // Random Error
        message = i18n.t('messageTranslations:Random error (403)');
        break;
      case 404: // Not Found
        message = i18n.t('messageTranslations:Not found (404)');
        break;
      case 408: // Timeout
        message = i18n.t('messageTranslations:Timeout (408)');
        break;
      case 409: // Conflict
        message = i18n.t('messageTranslations:Conflict (409)');
        break;
      case 429: // Too Many Requests
        message = i18n.t('messageTranslations:Too many requests (429)');
        break;
      case 500: // Server Error
        message = i18n.t('messageTranslations:Server error (500)');
        break;
      default:
        message = i18n.t('messageTranslations:An error has occured');
        break;
    }
    alertOptions = {
      set: true,
      type: 'TOAST',
      alert_type: ALERT_TYPE.ERROR,
      message,
      title: '',
      callback: null,
    };
  }

  if (alertOptions.message && alertOptions.message !== '') {
    if (alertOptions.type && alertOptions.type === 'MODAL') {
      let msg = alertOptions.message;
      if (alertOptions.type === ALERT_TYPE.ERROR) {
        msg = (
          <div>
            {alertOptions.message}
            <ErrorCollapsible error={data} />
          </div>
        );
      }

      if (modal === undefined) {
        throw new Error('modal is not defined');
      }

      modal.fire({
        html: <ModalBody
          content={msg}
          title={alertOptions.title}
          footer={
            <Button variant='primary'
              onClick={() => {
                alertOptions.callback?.();
                modal.close();
              }}
              aria-label={i18n.t('Close')}
            >{i18n.t('Close')}
            </Button>
          }
          type={alertOptions.alert_type}
        />,
        onClose: modal.close,
      });

    } else {
      showToast(alertOptions.alert_type, alertOptions.message, 2400);
    }
  }

  return data;
}
