
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Filter() {
  const { t, i18n } = useTranslation('tourTranslations');
  const textPosX = i18n.language === 'da' ? '3' : '19';

  return (
        <svg
            width='208'
            height='162'
            viewBox='0 0 208 162'
            direction='ltr'
        >
            <defs>
                <rect
                    id='filter-b'
                    width='192'
                    height='146'
                    x='0'
                    y='0'
                    rx='4'
                />
                <filter
                    id='filter-a'
                    width='114.6%'
                    height='119.2%'
                    x='-7.3%'
                    y='-6.8%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dy='4'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='4'
                    />
                    <feComposite
                        in='shadowBlurOuter1'
                        in2='SourceAlpha'
                        operator='out'
                        result='shadowBlurOuter1'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        values='0 0 0 0 0 0 0 0 0 0  0 0 0 0 0  0 0 0 0.2 0'
                    />
                </filter>
            </defs>
            <g
                fill='none'
                fillRule='evenodd'
                transform='translate(8 4)'
            >
                <use
                    fill='#000'
                    filter='url(#filter-a)'
                    href='#filter-b'
                />
                <rect
                    width='191'
                    height='145'
                    x='.5'
                    y='.5'
                    fill='#FFF'
                    stroke='#494949'
                    strokeLinejoin='square'
                    rx='4'
                />
                <path
                    fill='#494949'
                    fillRule='nonzero'
                    d='M160.621628,9.72785837 C160.63832,9.74278492 160.654433,9.75834678 160.669932,9.77450956 L167.75127,17.159432 C168.105149,17.5284812 168.09285,18.1145296 167.723801,18.4684078 L167.689974,18.4984476 C167.664484,18.5344045 167.636389,18.5690898 167.605689,18.6022437 L160.553533,26.2180396 C160.225665,26.5721122 159.672843,26.5933552 159.318771,26.2654874 C159.302353,26.2502848 159.286525,26.2344572 159.271323,26.2180396 C158.909559,25.827362 158.909559,25.2240271 159.271323,24.8333494 L165.772,17.8131133 L159.410486,11.1829715 C159.041401,10.7982795 159.030804,10.1942534 159.386168,9.7968517 C159.708279,9.43663681 160.261413,9.40574744 160.621628,9.72785837 Z'
                    transform='rotate(90 163.504 18.002)'
                />
                <text
                    fill='#494949'
                    fontSize='14'
                >
                    <tspan
                        x={textPosX}
                        y='23'
                    >{t('SvgText:Filter1')}</tspan>
                </text>
                <text
                    fill='#494949'
                    fontSize='14'
                >
                    <tspan
                        x={textPosX}
                        y='54'
                    >{t('SvgText:Filter2')}</tspan>
                </text>
                <text
                    fill='#494949'
                    fontSize='14'
                >
                    <tspan
                        x={textPosX}
                        y='80'
                    >{t('SvgText:Filter3')}</tspan>
                </text>
                <text
                    fill='#494949'
                    fontSize='14'
                >
                    <tspan
                        x={textPosX}
                        y='106'
                    >{t('SvgText:Filter4')}</tspan>
                </text>
                <text
                    fill='#494949'
                    fontSize='14'
                >
                    <tspan
                        x={textPosX}
                        y='132'
                    >{t('SvgText:Filter5')}</tspan>
                </text>
            </g>
        </svg>
  );
}
