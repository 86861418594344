
// Packages
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

// Components
import ResponsivePicture from 'shared/components/responsive-picture';

// Scss
import './auth-image.scss';
import GlobalSettingsContext from 'shared/modules/global-settings/state/global-settings-context.provider';

/**
 * Component to display responsive auth image
 *
 * @category authentication
 * @subcategory components
 * @exports AuthImage
 * @component
 */
export default function AuthImage(props) {
  const { flipx } = props;
  const { globalSettings } = useContext(GlobalSettingsContext);

  return (
    <div className="base-card-left d-none d-md-flex auth-image position-relative">
      <ResponsivePicture
        src={`${window.elearningRuntimeConfig.RESOURCE_URL}resource/${
          globalSettings.customerToken || 'default'
        }/img/assets/login_desk.png`}
        className="position-absolute desk"
        alt=""
        position="right bottom"
        fit="cover"
        flipx={flipx}
      />
    </div>
  );
}

AuthImage.propTypes = {
  flipx: PropTypes.bool,
};

AuthImage.defaultProps = {
  flipx: false,
};
