
import { checkHasRight } from 'shared/helpers';
import { RIGHTS } from 'shared/utilities/rights';

/**
  * Determines whether a user is allowed to see the cheat menu
  *
  * @param {*} user user object
  * @returns {*} boolean
  *
  * @category shared
  * @subcategory helpers
  * @exports checkCheatsActive
  */

export default function checkCheatsActive(user) {
  if (!user) return false;
  const isDemoAccount = user?.game?.demo || false;
  const isSalesAccount = user?.game?.sales || false;
  const isCheatMenu = checkHasRight(RIGHTS.FE_CHEATMENU, user);
  return isDemoAccount || isSalesAccount || isCheatMenu;
}
