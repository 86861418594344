// Packages
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

// Components
import ResponsivePicture from 'shared/components/responsive-picture';
import PlaceholderReplacement from 'shared/components/placeholder-replacement';
import { Title } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import styles from "./activity-section.module.css";
/**
 * renders the achievement activity in the activity section on the gamification-page
 *
 * @category gamification
 * @subcategory components
 * @exports ActivityBadgeSection
 * @component
 */
export default function ActivityBadgeSection(props) {
  const { t } = useTranslation('translations');
  const { isLast, activity } = props;
  const history = useHistory();
  const { badge } = activity;
  const {
    name,
    description_won: description,
    image,
    id,
  } = badge;

  const title = t('Badge received');

  return (
        <div className={`activity d-flex flex-column py-3 border-top ${isLast ? 'border-bottom mb-3' : ''}`}>
            <div className='mb-3 d-flex flex-row'>
                <Title order={3} size="h5">{title}</Title>
            </div>

            <div className={`${styles.activityBadgeContent} d-flex flex-row position-relative`}>
                {/* Image */}
                <div className='activity-badge'>
                    <ResponsivePicture
                        className='activity-badge-image'
                        src={image}
                        alt=""
                        fit='contain'
                        defaultHeight={100}
                    />

                </div>
                <span className='mx-2' />
                {/* Info */}
                <div className='d-flex flex-column w-100'>
                    <Title className='mb-3' order={4} size="h5">{name}</Title>
                    <span>
                        <PlaceholderReplacement
                            stringInput={description}
                            history={history}
                        />
                    </span>
                </div>
            </div>
        </div>
  );
}
