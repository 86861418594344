import React from 'react';
import PropTypes from 'prop-types';
import { getContrastColor } from "shared/helpers/a11y"

export default function ExperienceLegendBubble(props) {
  const {
    size, color, number, className,
  } = props;

  return (
        <svg
            width={`${size}px`}
            height={`${size}px`}
            viewBox={`0 0 ${size} ${size}`}
            preserveAspectRatio='xMidYMid meet'
            className={className}
        >
            <g>
                <circle
                    cx={size * 0.5}
                    cy={size * 0.5}
                    r={size * 0.45}
                    fill={color}
                />
            </g>
            <g>
                <text
                    textAnchor='middle'
                    x={size * 0.5}
                    y={size * 0.5}
                    fontSize={size * 0.6}
                    strokeWidth={size * 0.02}
                    dy={size * 0.2}
                    fill={getContrastColor(color)}
                >
                    {number + 1}
                </text>
            </g>
        </svg>
  );
}

ExperienceLegendBubble.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  number: PropTypes.number,
  className: PropTypes.string,
};
ExperienceLegendBubble.defaultProps = {
  size: '',
  color: '',
  number: 0,
  className: '',
};
