import { useState, useEffect } from 'react';

/**
 * In iOS, fullscreen is only supported for <video> elements (not iframe in our usage).
 */
function checkIsFullScreenAvailable(checkElem: Element | null): boolean {
  if (!checkElem) {
    return false;
  }

  return !!(
    checkElem.requestFullscreen ||
    checkElem.mozRequestFullScreen ||
    checkElem.webkitRequestFullscreen ||
    checkElem.msRequestFullscreen
  );
}

function checkIsFullScreen(): boolean {
  return !![
    document.fullscreenElement,
    document.mozFullScreenElement,
    document.webkitFullscreenElement,
    document.msFullscreenElement,
    document.fullscreen,
    document.mozFullScreen,
    document.webkitIsFullScreene,
    document.fullScreenMode,
  ].find((obj) => !!obj);
}

declare global {
  interface Document {
    mozFullScreenElement?: Element;
    webkitFullscreenElement?: Element;
    msFullscreenElement?: Element;
    mozFullScreen?: boolean;
    webkitIsFullScreene?: boolean;
    fullScreenMode?: boolean;
    mozCancelFullScreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
  }
  interface Element {
    mozRequestFullScreen?: () => Promise<void>;
    webkitRequestFullscreen?: () => Promise<void>;
    msRequestFullscreen?: () => Promise<void>;
  }
}

export default function useFullscreen(): {
  isFullscreen: boolean;
  isFullScreenAvailable: boolean;
  toggleFullscreen: (toggleElem: Element) => void;
  setElement: (element: HTMLDivElement | null) => void;
} {
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const isFullScreenAvailable = checkIsFullScreenAvailable(element);

  const [isFullscreen, setIsFullscreen] = useState(() => checkIsFullScreen());

  const [triggerRerender, setTriggerRerender] = useState<boolean>(false);
  const handleFullscreenChange = () => setTriggerRerender((prev) => !prev);

  useEffect(() => {
    document.addEventListener(
      'webkitfullscreenchange',
      handleFullscreenChange,
      false
    );
    document.addEventListener(
      'mozfullscreenchange',
      handleFullscreenChange,
      false
    );
    document.addEventListener(
      'msfullscreenchange',
      handleFullscreenChange,
      false
    );
    document.addEventListener(
      'MSFullscreenChange',
      handleFullscreenChange,
      false
    ); // IE11
    document.addEventListener(
      'fullscreenchange',
      handleFullscreenChange,
      false
    );
    return () => {
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullscreenChange
      );
      document.removeEventListener(
        'mozfullscreenchange',
        handleFullscreenChange
      );
      document.removeEventListener(
        'msfullscreenchange',
        handleFullscreenChange
      );
      document.removeEventListener(
        'MSFullscreenChange',
        handleFullscreenChange
      );
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const toggleFullscreen = (toggleElem: Element) => {
    const available = checkIsFullScreenAvailable(toggleElem);
    if (!available) return;

    const isActive = checkIsFullScreen();
    setIsFullscreen((prev) => !prev);

    if (isActive) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else if (toggleElem.requestFullscreen) {
      toggleElem.requestFullscreen();
    } else if (toggleElem.mozRequestFullScreen) {
      toggleElem.mozRequestFullScreen();
    } else if (toggleElem.webkitRequestFullscreen) {
      toggleElem.webkitRequestFullscreen();
    } else if (toggleElem.msRequestFullscreen) {
      toggleElem.msRequestFullscreen();
    }
  };

  return {
    isFullscreen,
    isFullScreenAvailable,
    toggleFullscreen,
    setElement,
  };
}
