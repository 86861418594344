
import PasswordValidator from 'password-validator';

const passwordValidator = new PasswordValidator();
passwordValidator
  .is()
  .min(12)
  .is()
  .max(512)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols();

type Primary = string | number | boolean | undefined | null;

export default class Validators {
  private static readonly EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  static required(valueToCheck: boolean | string | number | Date | unknown) {
    if (valueToCheck) {
      let isValid = false;
      switch (typeof valueToCheck) {
        case 'boolean':
          isValid = valueToCheck;
          break;
        case 'string':
          isValid = !!valueToCheck.length;
          break;
        case 'number': {
          isValid = !Number.isNaN(valueToCheck);
          break;
        }
        case 'object':
          isValid = valueToCheck instanceof Date;
          break;
        default:
          isValid = true;
          // eslint-disable-next-line no-console
          console.error(`validateRequired: missing case for [${typeof valueToCheck}]. Defaults to valid.`);
      }
      return {
        valid: isValid,
        data: {
          message: 'Value is required.',
        },
      };
    }
    return {
      valid: false,
      data: null,
    };
  }

  static email(valueToCheck: string) {
    return {
      valid: this.EMAIL_REGEX.test(valueToCheck),
      data: {
        message: 'Not a valid email address.',
      },
    };
  }

  static matches(valueToCheck1: Primary, valueToCheck2: Primary) {
    return {
      valid: valueToCheck1 === valueToCheck2,
      data: null,
    };
  }

  static password(valueToCheck) {
    const failedRules = passwordValidator.validate(valueToCheck, { list: true });
    return {
      valid: Array.isArray(failedRules) ? failedRules.length === 0 : failedRules,
      data: failedRules,
    };
  }
}
