
// Packages
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import uniqueId from "lodash/uniqueId";

// Utilities
import Loading from "shared/components/loading";
import { validateDidYouKnowHints } from "elearning/helpers";

// Hooks
import usePlatformSettings from "shared/hooks/use-platform-settings";

// Components
import SvgIcon from "shared/svgs/helper/svg-icon";
import { Idea } from "shared/svgs";

import { useSoSafeAnalytics } from "shared/hooks/use-sosafe-analytics";
import { ANALYTICS_TYPE, queryClient, queryKey } from "shared/modules/sosafe-connect";
import { useDidYouKnowHintRequest } from "elearning/hooks/use-did-you-know-hint-request";
import useUser from "shared/hooks/use-user";
import styles from "./did-you-know-card.module.css";

function DidYouKnowCard() {
  const { t, i18n } = useTranslation("translations");
  const { sendSoSafeAnalytics } = useSoSafeAnalytics();

  const { user } = useUser({});
  const { settings: platformSettings } = usePlatformSettings();
  const { badges } = platformSettings;

  const userHasSeen = !badges || !user?.game || user?.game.hint;

  const ariaLabelId = uniqueId("dyk_");

  const { data: hintResponse, loading } = useDidYouKnowHintRequest({
    enabled: !validateDidYouKnowHints(didyouknowHints, i18n.language),
  });
  const didyouknowHints = hintResponse?.data.result || [];

  const newHint = () => {
    if (didyouknowHints?.length) {
      const index = Math.floor(Math.random() * didyouknowHints.length);
      return didyouknowHints[index];
    }
    return "";
  };

  const [hint, setHint] = useState(newHint());

  const uncoverHints = () => {
    queryClient.setQueryData([queryKey.AUTH], ({ data: authResponse }) => {
      const newResponse = {
        ...authResponse,
        result: {
          ...authResponse.result,
          game: {
            ...authResponse.result?.game,
            hint: true,
          },
        },
      };
      return { data: { ...newResponse }, status: 200 };
    });
  };

  useEffect(() => {
    setHint(newHint());
  }, [didyouknowHints]);

  const body = loading ? (
    <div className="d-flex justify-content-center">
      <Loading size="medium" isLabel />
    </div>
  ) : (
    <div>
      <p className="mb-0">{hint?.content || ""}</p>
    </div>
  );

  if (!loading && !hint) return null;

  return (
    <Card
      className={`did-you-know-card shadow hover-shadow p-3 ${userHasSeen ? "visible" : `${styles.secondaryBg} cursor-help`
        }`}
      onClick={() => {
        if (!userHasSeen) uncoverHints();
        sendSoSafeAnalytics({ type: ANALYTICS_TYPE.HINT_VIEWED });
        setHint(newHint());
      }}
      role="button"
      aria-labelledby={ariaLabelId}
    >
      {userHasSeen ? (
        <div className="row">
          <div className="col-12" id={ariaLabelId}>
            <h2 className="mb-2 h4">{t("Did you know?")}</h2>
            {body}
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12" id={ariaLabelId}>
            <h2 className="h4 color-white">{t("Did you know?")}</h2>
            <div className="d-flex justify-content-end">
              <SvgIcon Icon={Idea} className="stroke-white" size="42" setStroke={false} />
            </div>
          </div>
        </div>
      )}
    </Card>
  );
}

function areEqual() {
  return true;
}

export default React.memo(DidYouKnowCard, areEqual);
