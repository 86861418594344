
// Packages
import React, { useEffect, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';

// Services
import { dispatchSetPlayerData } from 'elearning/services';

// Hooks
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import usePlatformSettings from 'shared/hooks/use-platform-settings';

// Utilities
import { TOURS } from 'shared/utilities/tour-configs';
import getModuleKey from 'elearning/helpers/get/get-module-key';
import { checkModuleUnlocked } from 'elearning/helpers/check';
import { ProductAnalytics } from 'shared/modules/product-analytics';

// Components
import CollapsibleTopicItem from 'elearning/components/collapsible-topic-item';
import CustomButton from 'shared/components/custom-button';
import CustomDropdown from 'shared/components/custom-dropdown';
import Divider from 'elearning/components/divider';
import Helmet from 'shared/components/helmet';
import ModuleCard from 'elearning/components/module-card';
import TourMobileStepWrapper from 'shared/components/onboarding/tour-mobile-step-wrapper';
import TourStepWrapper from 'shared/components/onboarding/tour-step-wrapper';
import { getIdByReference } from 'shared/utilities/reference-handling';
import {
  getCategoryByKeyAndVersion,
  getModuleForQuickStartCard,
  getTopics,
  MODULE_OPENED_FROM,
  TRACKED_EVENTS,
} from 'elearning/helpers';
import { showToast, ALERT_TYPE } from 'shared/utilities/modal-alert';

// Scss
import './module-page.scss';
import { useSoSafeAnalytics } from 'shared/hooks/use-sosafe-analytics';
import { ANALYTICS_TYPE } from 'shared/modules/sosafe-connect';
import { useCategoriesRequest } from 'elearning/hooks/use-categories-request';

const historyState = {
  openedFrom: MODULE_OPENED_FROM.MODULE_CARD,
};

function scrollTop(delay) {
  setTimeout(() => {
    scroll.scrollToTop({
      containerId: 'scrollContainer',
      duration: 200,
      delay: delay || 10,
      smooth: true,
    });
  }, 0);
}

const useEffectOnlyOnce = (func) => useEffect(func, []);

export default function ModulePage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation(['translations', 'messageTranslations', 'helmetTranslations']);
  const { categoryKey } = useParams();
  const location = useLocation();
  const { reference, categories } = useSelector((state) => state.modules, shallowEqual);
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { sendSoSafeAnalytics } = useSoSafeAnalytics();
  const { refetch: refetchCategories } = useCategoriesRequest();

  const [currentFilter, setcurrentFilter] = useState('none');
  const category = getCategoryByKeyAndVersion(categories, categoryKey, location.state?.version);

  const { settings } = usePlatformSettings();
  const { step_activation } = settings || {};

  useEffectOnlyOnce(() => {
    if (!category) {
      refetchCategories();
    } else if (!category.modules || !category.modules.length) {
      showToast(ALERT_TYPE.ERROR, t('messageTranslations:An error has occured'));
      history.push('/elearning');
      return;
    }

    sendSoSafeAnalytics({ type: ANALYTICS_TYPE.PAGE_VIEWED });
  });

  useEffect(() => {
    switch (getIdByReference(reference)) {
      case 'CATEGORIES_FETCH_SUCCESS':
      case 'CATEGORIES_FETCH_SUCCESS_2':
        if (!category || !category.modules || !category.modules.length) {
          showToast(ALERT_TYPE.ERROR, t('messageTranslations:An error has occured'));
          history.push('/elearning');
        }
        break;
      default:
        break;
    }
  }, [reference, category, history, t]);

  useEffect(() => {
    if (category) {
      ProductAnalytics.getInstance().trackEvent(TRACKED_EVENTS.SINGLE_CATEGORY_VIEW_OPENED, {
        categoryId: category.uuid,
        categoryTitle: category.name,
        categoryLanguage: category.language,
        categoryGroup: category.group,
        categoryInterfaceDisplay: category.version,
      });
    }
  }, [category?.uuid]);

  if (!category) return null;

  const { modules } = category;
  if (!modules || !modules.length) return null;

  const filterModules = (list) => {
    const cond1 = (m) => m.statusOfModule && m.statusOfModule.includes(currentFilter);
    const cond2 = (m) =>
      m.statusOfModule && m.statusOfModule.includes('PAUSED') && currentFilter === 'UNTREATED';
    return list.filter((m) => cond1(m) || cond2(m));
  };

  /* Topics */
  const topics = getTopics(modules, step_activation, user);

  let filteredTopics = topics;
  if (currentFilter !== 'none') {
    filteredTopics = filteredTopics.map((topic) => ({
      ...topic,
      modules: filterModules(topic.modules),
    }));
    filteredTopics = filteredTopics.filter((topic) => topic.modules && topic.modules.length);
  }

  const filteredTopicsWithoutMaster = filteredTopics.filter((topic) => topic.topic_id !== 'master');
  /* Mastercard */
  const masterTopic = filteredTopics.find((topic) => topic.topic_id === 'master');
  const masterModule =
    masterTopic && masterTopic.modules && masterTopic.modules.length
      ? masterTopic.modules[0]
      : null;
  const masterCard = masterModule ? (
    <ModuleCard
      key={masterModule.id}
      handleButton={(action, moduleKey) =>
        handleButton(action, moduleKey, categoryKey, dispatch, history)
      }
      module={masterModule}
    />
  ) : null;
  let masterTotal = 0;
  if (masterModule) {
    if (checkModuleUnlocked(user, masterModule)) {
      masterTotal += 1;
    }
  }

  // for v3
  let completedModules = 0;
  let totalModules = 0;
  filteredTopics.forEach((topic) => {
    completedModules += topic.completed;
    if (topic.modules && topic.modules.length) {
      topic.modules.forEach((module) => {
        if (checkModuleUnlocked(user, module)) {
          totalModules += 1;
        }
      });
    }
  });

  const dropdownOptions = [
    { key: 'none', value: t('All modules') },
    { key: 'PASSED', value: t('Passed') },
    { key: 'UNTREATED', value: t('Open') },
    { key: 'EXPIRE_SOON', value: t('Due') },
    { key: 'EXPIRED', value: t('Overdue') },
  ];
  const currentDropdownFilter = dropdownOptions.find((opt) => opt.key === currentFilter);

  const messageNoneFiltered = () => {
    switch (currentFilter) {
      case 'EXPIRED':
        return <p className="h1 mb-2">{t('You currently have no overdue modules.')}</p>;
      case 'EXPIRE_SOON':
        return <p className="h1 mb-2">{t('You currently have no due modules.')}</p>;
      case 'UNTREATED':
        return <p className="h1 mb-2">{t('You have no more open modules.')}</p>;
      case 'PASSED':
        return (
          <p className="h1 mb-2 text-center">
            {t('Unfortunately you have not yet passed any modules.')}
            <br />
            {t('It is best to get started right away.')}
          </p>
        );
      default:
        return '';
    }
  };
  const nextModule = getModuleForQuickStartCard(categories, user, step_activation);

  const LR = `${i18n.language === 'ar' ? 'r' : 'l'}`;

  let showCompletedSummary = false;
  if (modules?.[0]?.version === 3) {
    showCompletedSummary = !!totalModules;
  } else {
    showCompletedSummary = !!masterTopic && masterTotal;
  }

  return (
    <React.Fragment>
      <Helmet title={category.name} />
      <TourStepWrapper id="module_tour_finish" tour={TOURS.ModulepageTour} />
      <div className="ModulePage d-flex flex-column">
        <div className="mb-3 d-flex justify-content-between">
          <CustomButton label={t('Back')} onClick={() => history.push('/')} />
          <TourStepWrapper id="module_tour_filter" tour={TOURS.ModulepageTour}>
            <div>
              <CustomDropdown
                dropdownOptions={dropdownOptions}
                currentSelection={currentDropdownFilter}
                handleClick={(e, key) => setcurrentFilter(key)}
                styling="outline-secondary"
                className="select-module-page"
              />
            </div>
          </TourStepWrapper>
        </div>
        <h1 className="mb24">{category.name}</h1>
        {filteredTopics.length === 0 && (
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            {messageNoneFiltered()}
            {currentFilter === 'PASSED' && nextModule && (
              <CustomButton
                className="mt-5"
                label={t('Go to first module')}
                onClick={() => {
                  dispatch(dispatchSetPlayerData({ isContinue: true }));
                  history.push(
                    `${nextModule.category_key}/${getModuleKey(nextModule)}`,
                    historyState
                  );
                }}
              />
            )}
          </div>
        )}

        {showCompletedSummary && (
          <div className="d-flex flex-row" data-testid="passed-module-label">
            <TourStepWrapper
              id="module_tour_progress"
              tour={TOURS.ModulepageTour}
              className="d-flex flex-row mb-3 w-100"
              disabled={modules?.[0]?.version !== 3}
            >
              <div className="my-auto">
                <span className="d-inline mb-3 h3">
                  {modules?.[0]?.version === 3 ? t('Passed modules') : t('Entry videos')}:
                </span>
              </div>
              <div className={`m${LR}-2 my-auto`}>
                <span className="d-inline text-primary h3 mb-2">
                  {modules?.[0]?.version === 3
                    ? `${completedModules}/${totalModules}`
                    : `${masterTopic?.completed || 0}/${masterTotal}`}
                </span>
              </div>
            </TourStepWrapper>
          </div>
        )}

        {!!masterCard && (
          <React.Fragment>
            <TourStepWrapper id="module_tour_video" tour={TOURS.ModulepageTour}>
              {masterCard}
            </TourStepWrapper>
          </React.Fragment>
        )}

        {!!filteredTopicsWithoutMaster.length && !!masterCard && <hr className="my-4 mx-4" />}

        <div className="d-flex flex-column">
          {!!filteredTopicsWithoutMaster.length &&
            filteredTopicsWithoutMaster.map((topic, idx) => (
              <CollapsibleTopicItem
                topic={topic}
                last={filteredTopicsWithoutMaster.length - 1 === idx}
                first={idx === 0}
                key={topic.topic_id}
              />
            ))}
        </div>
        <div className="d-md-none mt-auto mx-4">
          <Divider
            label={t('To the top')}
            direction="up"
            className="my-0"
            onClick={() => scrollTop()}
          />
        </div>
      </div>
      <TourMobileStepWrapper tour={TOURS.ModulepageTourMobile} />
    </React.Fragment>
  );
}

function handleButton(action, moduleKey, categoryKey, dispatch, history) {
  historyState.action = action;
  const isContinue = action === 'CONTINUE';
  dispatch(dispatchSetPlayerData({ isContinue }));

  history.push(`/elearning/${categoryKey}/${moduleKey}`, historyState);
}
