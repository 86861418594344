import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Footer from 'shared/components/footer';
import NewFooter from 'shared/components/footer/new-footer.component';
import AuthHeader from './auth-header.component';

type AppPageContentType = {
  isNewLayout?: boolean;
  isFooterHidden?: boolean;
  children: JSX.Element | JSX.Element[];
};

const AuthPageContent: React.FC<AppPageContentType> = ({
  isNewLayout,
  isFooterHidden,
  children,
}) => {
  const { i18n } = useTranslation('translations');

  if (isNewLayout) {
    return (
      <div
        key="scrollContainer"
        id="scrollContainer"
        className={clsx(
          {
            arab: i18n.language === 'ar',
          },
          'app-page-content',
          'p-0'
        )}
      >
        <AuthHeader />
        <div className="app-page-content-scroll-container">{children}</div>
        <NewFooter isFooterHidden={isFooterHidden} />
      </div>
    );
  }
  return (
    <div
      key="scrollContainer"
      id="scrollContainer"
      className={clsx(
        {
          arab: i18n.language === 'ar',
        },
        'pt-4',
        'app-page-content'
      )}
    >
      <div className="app-page-content-scroll-container px-md-2">
        {children}
      </div>

      <Footer />
    </div>
  );
};

export default AuthPageContent;
