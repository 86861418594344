
// Packages
import React from 'react';
import uniqueId from 'lodash/uniqueId';
import { useTranslation } from 'react-i18next';

// Components
import SvgIcon from '../svgs/helper/svg-icon';
import { Tooltip } from '@sosafe-platform-engineering/fe-lib-ui-react';
import { Info } from '../svgs';

import './info-bubble.scss';

export interface InfoBubbleProps {
  /**
     * Children to be displayed inside the Infobubble-Tooltip.
     * This prop takes precedence over the 'description' prop.
     * @default undefined
     */
  children?: React.ReactNode,
  /**
     * Simple text to be displayed inside the Infobubble-Tooltip.
     * When children are present the description is ignored and only the children are displayed.
     * @default ''
     */
  description?: string,
  /**
     * The size of the Icon.
     * 'small' corresponds to 20px, 'normal' to 24px
     * @default 'normal'
     */
  size?: 'small' | 'normal'
}
/**
 * An Icon with a Tooltip.
 * If neither children nor description are provided the component does not render.
 *
 * ### Usage
 * <InfoBubble
 *      size='small'
 *      description='I am the text shown inside the Tooltip'
 * />
 *
 * or
 *
 * <InfoBubble size='small'>
 *      {children}
 * </InfoBubble>
 *
 * @param props as InfoBubbeProps
 * @returns JSX.Element
 */
export default function InfoBubble(props: InfoBubbleProps) {
  const {
    size = 'normal',
    description = '',
    children,
  } = props;
  const { t } = useTranslation('translations');
  const tooltipId = uniqueId('ttip_');
  if (!children && !description) {
    return null;
  }
  const Content = () => {
    if (children) {
      return (<div className='info-bubble-tooltip'> {children} </div>);
    }
    return (
            <div
                className='info-bubble-tooltip'
                dangerouslySetInnerHTML={{ __html: description }}
            />
    );
  };

  const svgSize = size === 'small' ? '20px' : '24px';

  return (
        <Tooltip
            content={<Content />}
            position='left'
        >
            <div
                className={`info-bubble ${size}`}
                onClick={(e) => e.stopPropagation()}
                role='button'
                aria-label={t('More Information')}
                aria-describedby={tooltipId}
            >
                <SvgIcon
                    Icon={Info}
                    size={svgSize}
                    className='my-auto mx-auto color-white'
                />
                <span
                    id={tooltipId}
                    role='tooltip'
                    className='position-absolute invisible'
                    dangerouslySetInnerHTML={{ __html: description }}
                />{/* this line is only visible for screen readers */}
            </div>
        </Tooltip>
  );
}
