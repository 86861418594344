
// Packages
import React from 'react';

// Scss
import './particles.scss';

function Particles() {
  const random = (max) => Math.floor(Math.random() * max);
  const getOpacity = () => Math.min(Math.max(Math.random(), 0.3), 1);
  const getColor = () => ['primary', 'secondary'][random(2)];
  const getRadius = () => `${['2.5', '3', '3.5', '4', '4.5'][random(5)]}`;
  const circle = (pos) => (
        <circle
            key={pos}
            className={`animate-particle delay-${random(5) + 1} duration-${random(4) + 1} fill-${getColor()}`}
            cx={pos[0]}
            cy={pos[1]}
            fillOpacity={getOpacity()}
            r={getRadius()}
        />
  );
  const ring = (pos) => (
        <circle
            key={pos}
            className={`animate-particle delay-${random(5) + 1} duration-${random(4) + 1} stroke-${getColor()}`}
            cx={pos[0]}
            cy={pos[1]}
            r={getRadius()}
            strokeWidth='2'
        />
  );
  const plus = (pos) => {
    const radius = [3, 4, 5][random(3)];
    const [x, y] = pos;
    return (
            <path
                key={pos}
                className={`animate-particle delay-${random(5) + 1} duration-${random(4) + 1} stroke-${getColor()}`}
                d={`M${x - radius},${y} L${x + radius},${y} M${x},${y - radius} L${x},${y + radius}`}
                fillOpacity={getOpacity()}
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
            />
    );
  };

  const positions = [
    [56, 28],
    [23, 16],
    [280, 109],
    [37, 141],
    [264, 35],
    [14, 97],
    [259, 155],
    [274, 78],
    [215, 22],
    [101, 9],
    [10, 51],
    [60, 166],
    [250, 64],
    [230, 6],
    [202, 164],
    [189, 0],
    [236, 104],
    [52, 65],
    [86, 164],
    [18, 149],
  ];
  const particles = [];
  for (let i = 0; i < 20; i++) {
    switch (random(3)) {
      case 0:
        particles.push(circle(positions[i]));
        break;
      case 1:
        particles.push(ring(positions[i]));
        break;
      case 2:
        particles.push(plus(positions[i]));
        break;
      default:
        break;
    }
  }

  return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='100%'
            height='100%'
            viewBox='0 0 294 182'
        >
            <g
                className='particle-wrapper'
                fill='none'
                fillRule='evenodd'
            >
                {particles}
            </g>
        </svg>
  );
}

Particles.propTypes = {

};

export default React.memo(Particles, () => true);
