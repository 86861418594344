
// Packages
import { useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// Hooks
import usePlatformSettings from "shared/hooks/use-platform-settings";

// Utils
import {
  ACHIEVEMENT_PAGE_OPENED_FROM,
  getAdHocMessage,
  getCategoryByCategoryKey,
  getInactivityMessage,
  getMainMessage,
  getModuleKey,
  getNextModuleRelease,
} from "elearning/helpers";
import { dispatchSetPlayerData } from "elearning/services";
import isEqual from "lodash/isEqual";
import getCertificateModal from "shared/components/cert-modal";
import { dateToDDMMYYYY, getDisplayName } from "shared/helpers";
import useUser from "shared/hooks/use-user";
import ActionTypes from "shared/utilities/action-types";
import { WELCOME_MESSAGES } from "shared/utilities/welcome-message-states";
import { useCategoriesRequest } from "./use-categories-request";

export default function useWelcomeMessage() {
  const { user, loading: userLoading } = useUser({});
  const { loading, data: categoriesResponse } = useCategoriesRequest(true);
  const { welcomeCompareData } = useSelector((state) => state.welcomeCard, shallowEqual);
  const categories = categoriesResponse?.data.result || [];

  const displayName = getDisplayName(user);

  const { t, i18n } = useTranslation("welcomeCardTranslations");
  const dispatch = useDispatch();
  const { settings: gamificationSettings } = usePlatformSettings();
  const history = useHistory();

  const achievementsAreActive = gamificationSettings?.achievements || false;
  const levelActivated = gamificationSettings?.level || false;
  const certificatAvailable = gamificationSettings?.certificate || false;

  const currentMessage = !user ? "default" : welcomeCompareData?.currentMessage || "default";

  useEffect(() => {
    if (!welcomeCompareData && !userLoading && !loading) {
      const inactivityMessage = getInactivityMessage(user, gamificationSettings);

      const mainMessage = getMainMessage({
        categories,
        lastSeen: user.last_seen,
        progress: user.game.progress,
        campaign: user.campaign,
      });

      if (inactivityMessage || mainMessage.message) {
        dispatch({
          type: ActionTypes.WELCOME_CARD_DATA_UPDATE,
          payload: {
            game: user?.game,
            currentMessage: inactivityMessage || mainMessage.message,
            addData: mainMessage.addData || null,
          },
        });
      }
    } else if (user?.game && !isEqual(user.game, welcomeCompareData?.game)) {
      const adHocMessage = getAdHocMessage(
        user,
        welcomeCompareData,
        gamificationSettings,
        categories
      );
      if (adHocMessage && adHocMessage.message) {
        dispatch({
          type: ActionTypes.WELCOME_CARD_DATA_UPDATE,
          payload: {
            ...welcomeCompareData,
            game: user.game,
            currentMessage: adHocMessage.message,
            addData: adHocMessage.addData || null,
          },
        });
      }
    }
  }, [user?.game, welcomeCompareData, userLoading, loading, categories.length]);

  const selectedMessage = useMemo(() => {
    switch (currentMessage) {
      case WELCOME_MESSAGES.ADHOC_CAT_PROGRESS: {
        const catKey = welcomeCompareData?.addData?.catKey;
        const modId = welcomeCompareData?.addData?.moduleKey;
        const categoryToImprove = getCategoryByCategoryKey(categories, catKey, user?.language);
        const catName = categoryToImprove?.name;
        const nextModule = categoryToImprove?.modules?.find((module) => module.id === modId);
        const modName = nextModule?.name;
        if (!catKey || !catName || !nextModule || !modName) {
          return t("welcomeCardTranslations:default:message");
        }
        return (
          <Trans i18nKey={`welcomeCardTranslations:${currentMessage}:message`}>
            You have not made much progress in category
            <Link className="text-white text-underlined" to={`/elearning/${catKey}`}>
              {{ catName }}
            </Link>
            . Start with
            <a
              className="text-white cursor-pointer text-underlined font-weight-bold"
              onClick={() => {
                const isContinue = true;
                dispatch(dispatchSetPlayerData({ isContinue }));
                history.push(`/elearning/${catKey}/${getModuleKey(nextModule)}`);
              }}
              role="button"
              aria-label={modName}
            >
              {{ modName }}
            </a>
            to increase your knowledge.
          </Trans>
        );
      }
      case WELCOME_MESSAGES.MAIN_DONE: {
        if (!certificatAvailable) {
          return t("welcomeCardTranslations:default:message");
        }
        const btnLabel = t(`welcomeCardTranslations:${currentMessage}:message2`);
        return (
          <Trans i18nKey={`welcomeCardTranslations:${currentMessage}:message`}>
            You have completed all modules successfully.
            <a
              className="text-white cursor-pointer text-underlined"
              onClick={() => getCertificateModal(i18n)}
              role="button"
              aria-label={btnLabel}
            >
              {{ btnLabel }}
            </a>
          </Trans>
        );
      }
      case WELCOME_MESSAGES.ADHOC_MODULE: {
        const newModule = user?.game?.activities?.lastModule?.name;
        const xpGained = user?.game?.activities?.lastModule?.xp;
        if (!newModule || !xpGained) {
          return t("welcomeCardTranslations:default:message");
        }
        if (levelActivated) {
          return t(`welcomeCardTranslations:${currentMessage}:message`, {
            x: newModule,
            y: xpGained,
          });
        }
        return t(`welcomeCardTranslations:${currentMessage}:messageNoLevel`, { x: newModule });
      }
      case WELCOME_MESSAGES.ADHOC_LEVEL: {
        const newLevel = user?.game?.progress?.level?.group || 1;
        return t(`welcomeCardTranslations:${currentMessage}:message`, { x: newLevel });
      }
      case WELCOME_MESSAGES.ADHOC_DUE_DATE: {
        const module = welcomeCompareData?.addData?.module;
        const catKey = welcomeCompareData?.addData?.catKey;
        if (!module || !catKey) {
          return t("welcomeCardTranslations:default:message");
        }
        return (
          <div data-testid="module-due-date-message">
            <Trans i18nKey={`welcomeCardTranslations:${currentMessage}:message`}>
              The module
              <a
                className="text-white cursor-pointer text-underlined font-weight-bold"
                onClick={() => {
                  const isContinue = true;
                  dispatch(dispatchSetPlayerData({ isContinue }));
                  history.push(`/elearning/${catKey}/${getModuleKey(module)}`);
                }}
                role="button"
                aria-label={module.name}
              >
                {{ name: module.name }}
              </a>
              was due on
              <b>{{ date: dateToDDMMYYYY(module.finished_by) }}</b>
              Finish the module now!
            </Trans>
          </div>
        );
      }
      case WELCOME_MESSAGES.ADHOC_MOD_ALMOST_DONE: {
        const almostDoneModule = welcomeCompareData?.addData?.module;
        if (!almostDoneModule) {
          return t("welcomeCardTranslations:default:message");
        }

        let catKey;
        categories.forEach((category) => {
          const matchingModule = category.modules.find(
            (module) => module.id === almostDoneModule?.id
          );
          if (matchingModule) catKey = category.category_name;
        });

        if (!catKey) {
          return t("welcomeCardTranslations:default:message");
        }

        return (
          <Trans i18nKey={`welcomeCardTranslations:${currentMessage}:message`}>
            Invest two minutes of your time into the module
            <a
              className="text-white cursor-pointer text-underlined font-weight-bold"
              onClick={() => {
                const isContinue = true;
                dispatch(dispatchSetPlayerData({ isContinue }));
                history.push(`/elearning/${catKey}/${getModuleKey(almostDoneModule)}`);
              }}
              role="button"
              aria-label={almostDoneModule.name}
            >
              {{ name: almostDoneModule.name }}
            </a>
            and complete it. Let&apos;s go, this is a quick win!
          </Trans>
        );
      }
      case WELCOME_MESSAGES.INACTIVE_MODULE:
      case WELCOME_MESSAGES.INACTIVE_BADGE:
        if (achievementsAreActive) {
          return (
            <Trans i18nKey={`welcomeCardTranslations:${currentMessage}:message`}>
              Have a look at your
              <Link
                className="text-white text-underlined"
                to={{
                  pathname: "/achievements",
                  state: { openedFrom: ACHIEVEMENT_PAGE_OPENED_FROM.START_PAGE.WELCOME_CARD },
                }}
              >
                achievements.
              </Link>
              ;
            </Trans>
          );
        }
        return t(`welcomeCardTranslations:${currentMessage}:noAchievementsMessage`);

      case WELCOME_MESSAGES.FIRST_LOGIN:
      case WELCOME_MESSAGES.MAIN_MAX_TWO_MOD:
      case WELCOME_MESSAGES.MAIN_LESS_HALF:
      case WELCOME_MESSAGES.MAIN_MORE_HALF:
      case WELCOME_MESSAGES.MAIN_THREE_MORE:
      case WELCOME_MESSAGES.INACTIVE_LOGIN:
      case WELCOME_MESSAGES.ADHOC_ALL_ESSENTIALS:
      case WELCOME_MESSAGES.ADHOC_FIRST:
      case WELCOME_MESSAGES.ADHOC_LAST:
        return (
          <Trans i18nKey={`welcomeCardTranslations:${currentMessage}:message`}>
            Have a look at your
            <Link
              className="text-white text-underlined"
              to={{
                pathname: "/achievements",
                state: { openedFrom: ACHIEVEMENT_PAGE_OPENED_FROM.START_PAGE.WELCOME_CARD },
              }}
            >
              achievements.
            </Link>
            ;
          </Trans>
        );
      case WELCOME_MESSAGES.ADHOC_BADGE: {
        const lastBadgeName = user?.game?.activities?.lastBadge?.name || "";
        if (achievementsAreActive) {
          return (
            <Trans i18nKey={`welcomeCardTranslations:${currentMessage}:message`}>
              You have received the badge <strong>{{ lastBadgeName }}</strong>. Have a look at your
              <Link
                className="text-white text-underlined"
                to={{
                  pathname: "/achievements",
                  state: { openedFrom: ACHIEVEMENT_PAGE_OPENED_FROM.START_PAGE.WELCOME_CARD },
                }}
              >
                achievements.
              </Link>
              ;
            </Trans>
          );
        }
        return t(`welcomeCardTranslations:${currentMessage}:noAchievementsMessage`, {
          lastBadgeName,
        });
      }

      case WELCOME_MESSAGES.ALL_MODULES_LOCK: {
        const { releaseDate } = getNextModuleRelease(user);
        const date = dateToDDMMYYYY(releaseDate);
        return (
          <Trans i18nKey={`welcomeCardTranslations:${currentMessage}:message`}>
            Great job! You've earned a break. On {{ date }}, new modules will become available!
          </Trans>
        );
      }

      default:
        return t("welcomeCardTranslations:default:message");
    }
  }, [currentMessage, user?.game.activities.length, loading]);

  const count = user?.game?.progress?.totalAllUnlocked || 0;
  const countDone = user?.game?.progress?.completed || 0;

  const message = useMemo(() => {
    if (!categories) {
      return {
        title: "",
        message: "",
        footer: "",
        loading: true,
      };
    }

    return {
      title: !displayName
        ? t("welcomeCardTranslations:fallbackTitle")
        : t(`welcomeCardTranslations:${currentMessage}:title`, { x: displayName }),
      message: selectedMessage,
      footer: t("welcomeCardTranslations:footer", { x: countDone, y: count }),
      loading: userLoading && loading,
    };
  }, [loading, userLoading, selectedMessage, displayName, currentMessage]);

  return message;
}
