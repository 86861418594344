import { getUrlParameter } from 'shared/helpers/get';
import {
  SESSION_STORAGE_KEY_MODULE,
  SESSION_STORAGE_KEY_SSO,
} from 'shared/utilities/constants';

export default function checkRedirect(location, history, user) {
  // Temp fix for password routing
  const passwordToken = getUrlParameter(location.search, 'password');
  if (passwordToken) {
    history.push(`/setpassword/${passwordToken}`);
    return;
  }

  // Development
  const backendParameter = getUrlParameter(location.search, 'backend');
  const branding = getUrlParameter(location.search, 'branding');
  // if branding is present on the url, the user is previewing the E-learning from the manager.
  if (backendParameter && !branding) {
    history.push('/');
    return;
  }

  if (!user) {
    // Module / Category
    if (location?.pathname?.includes('elearning') ?? false) {
      const moduleStorageState = sessionStorage.getItem(
        SESSION_STORAGE_KEY_MODULE,
      );

      /**
       * Logic here will get the information about the module the user is on,
       * if they are on the module player route.
       *
       * This information should not be collected if the user logs out manually.
       *
       * Logic to clear this 'logout' value can be found in the authentication/pages/login-page.js
       */

      if (moduleStorageState !== 'logout') {
        const urlSplit = location.pathname
          .split('/')
          .filter((str) => str !== '' && str !== 'elearning');
        const catKey = urlSplit?.[0];
        const modKey = urlSplit?.[1];

        const redirect = {};
        if (catKey) redirect.catKey = catKey;
        if (modKey) redirect.modKey = modKey;

        sessionStorage.setItem(
          SESSION_STORAGE_KEY_MODULE,
          JSON.stringify(redirect),
        );
      }
    }

    // Sso
    let sso = getUrlParameter(location.search, 'sso');
    if (sso) {
      if (location.pathname.includes('register')) {
        sso = `REGISTER_${sso.toUpperCase()}`;
        sessionStorage.setItem(SESSION_STORAGE_KEY_SSO, sso);
        history.push('/register');
      } else {
        sso = `LOGIN_${sso}`;
        sessionStorage.setItem(SESSION_STORAGE_KEY_SSO, sso);
      }
    }
  }
}
