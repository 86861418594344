// polyfill - must be first
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "./index.scss";
// Flamingo styles from react-ui
import "@sosafe-platform-engineering/fe-lib-ui-react/dist/tokens/tokens.css";
import "@sosafe-platform-engineering/fe-lib-ui-react/dist/index.css";
import "@sosafe-platform-engineering/fe-lib-ui-react/dist/normalize/normalize.css";
import "@sosafe-platform-engineering/fe-lib-ui-react/dist/colors/colors.css";
import "@sosafe-platform-engineering/fe-lib-ui-react/dist/typography/typography.css";
import "@sosafe-platform-engineering/fe-lib-ui-react/dist/suitcss/utilities.css";
import "@sosafe-platform-engineering/fe-lib-ui-react/dist/spacing/spacing.css";

import React from "react";
import { createRoot } from "react-dom/client";
import { withFFProvider } from "@sosafe-aws/fe-lib-feature-flags";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";

import * as Sentry from "@sentry/react";

import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import de from "javascript-time-ago/locale/de";

import {
  keyboardA11y,
  mutationObserver,
  focusA11yHelper,
} from "shared/helpers";

import { store, persistor } from "state/store/store";

import AuthenticationWrapper from "authentication/components/authentication-wrapper";

import { createBrowserHistory } from "history";
import { GlobalSettingsContextProvider } from "shared/modules/global-settings/state/global-settings-context.provider";
import BrandedLinkElement from "elearning/components/branded-link-element.component";

import { QueryContextProvider } from "shared/modules/sosafe-connect/query";
import { SoSafeAxiosProvider } from "shared/modules/sosafe-connect";
import {
  vera3,
  MantineProvider,
  createTheme,
  mergeMantineTheme,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import ErrorPage from "shared/pages/error/error.page";
import { ModalContextProvider } from "modal-context/modal-context";
import { ErrorBoundary } from "flamingo-e-learning-platform/utilities/error-boundary";
import { version, name } from "../package.json";


JavascriptTimeAgo.addLocale(de);
JavascriptTimeAgo.addLocale(en);

const { location } = window;
const { hostname } = location;
const environment = window.elearningRuntimeConfig.DEPLOY_ENVIRONMENT as
  | string
  | undefined;

const sentryConfig: Sentry.BrowserOptions = {
  dsn: window.elearningRuntimeConfig.SENTRY_DSN as string | undefined,
  environment,
  // SENTRY_PROJECT is set on build in CI
  release: `${process.env.SENTRY_PROJECT}@${version}${hostname.includes('localhost') ? '_local' : ''}`,
  // these two are thrown as part of a sentry-issue, that is not fixed and caused by web-crawlers. Not actual issues, so I'll add this for now, in order to avoid false positives.
  // mor information here: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/13
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'Error: Network Error',
    'Request aborted',
    'timeout of 40000ms exceeded',
  ],
  beforeSend(event: Sentry.ErrorEvent) {
    if (event?.breadcrumbs?.length) {
      const shouldOmit = event?.breadcrumbs?.some((breadcrumb) => {
        if (
          breadcrumb?.category === 'xhr' &&
          breadcrumb?.data?.status_code === 403
        ) {
          return true;
        }
        return false;
      });
      if (shouldOmit) {
        return null;
      }
    }

    return {
      ...event,
      tags: {
        ...event.tags,
        'AMZ-edge-node': window.elearningRuntimeConfig.AMZ_EDGE_NODE,
        'AMZ-edge-country': window.elearningRuntimeConfig.AMZ_EDGE_COUNTRY,
      },
    };
  },
  beforeSendTransaction(event) {
    return {
      ...event,
      tags: {
        ...event.tags,
        'AMZ-edge-node': window.elearningRuntimeConfig.AMZ_EDGE_NODE,
        'AMZ-edge-country': window.elearningRuntimeConfig.AMZ_EDGE_COUNTRY,
      },
    };
  },
  tracesSampleRate: window.elearningRuntimeConfig.SENTRY_TRACES_SAMPLE_RATE as
    | number
    | undefined,
  integrations: [Sentry.browserTracingIntegration()],
  tracePropagationTargets: [
    'localhost',
    'https://elearning.sosafe-stage.de/',
    'https://elearning.sosafe.de/',
  ],
};

if (window.elearningRuntimeConfig.SENTRY_DSN) {
  Sentry.init(sentryConfig);
}

// init A11y-helpers:
mutationObserver(document.body, keyboardA11y);
focusA11yHelper(document.body, 'focus-visible');

const history = createBrowserHistory();

const theme = createTheme({
  other: {
    convertCSSVariablesInput: {
      light: {
        '--mantine-color-placeholder': 'var(--mantine-color-dark-5)',
      },
    },
  },
  autoContrast: true,
  luminanceThreshold: 0.3,
  components: {
    Progress: {
      defaultProps: {
        color: 'teal',
        size: 'lg',
      },
    },
    Avatar: {
      defaultProps: {
        color: '--mantine-color-gray-8',
      },
    },
    Select: {
      defaultProps: {
        searchable: true,
        styles: {
          input: {
            borderColor: 'var(--mantine-color-dark-5)',
          },
        },
      },
    },
    TextInput: {
      styles: {
        input: {
          borderColor: 'var(--mantine-color-dark-5)',
        },
      },
    },
  },
  colors: {
    cyan: [
      'var(--sosafe-color-primary--10)',
      'var(--sosafe-color-primary--20)',
      'var(--sosafe-color-primary--40)',
      'var(--sosafe-color-primary--60)',
      'var(--sosafe-color-primary--80)',
      'var(--sosafe-color-primary--100)',
      'var(--sosafe-color-primary--120)',
      'var(--sosafe-color-primary--120)',
      'var(--sosafe-color-primary--120)',
      'var(--sosafe-color-primary--100)',
    ],
  },
  primaryColor: 'cyan',
});

const mergedTheme = mergeMantineTheme(vera3, theme);

const RootComponent = () => (
  <MantineProvider theme={mergedTheme}>
    <Provider store={store}>
      <QueryContextProvider>
        <SoSafeAxiosProvider>
          <ErrorBoundary
            fallbackComponent={() => <ErrorPage errorCode={404} />}
          >
            <PersistGate loading={null} persistor={persistor}>
              <GlobalSettingsContextProvider>
                <Router history={history}>
                  <BrandedLinkElement />
                  <ModalContextProvider>
                    <Switch>
                      <Route
                        key="/"
                        path="/"
                        render={() => <AuthenticationWrapper />}
                      />
                      {/* Start: Delete this once new Error page is ready */}
                      <Route
                        key="/503"
                        exact
                        path="/503"
                        render={() => <ErrorPage errorCode={503} />}
                      />
                      {/* End: Delete this once new Error page is ready */}
                    </Switch>
                  </ModalContextProvider>
                </Router>
              </GlobalSettingsContextProvider>
            </PersistGate>
          </ErrorBoundary>
        </SoSafeAxiosProvider>
      </QueryContextProvider>
    </Provider>
  </MantineProvider>
);

const RootComponentWithFF = withFFProvider(
  window.elearningRuntimeConfig.LAUNCHDARKLY_CLIENT_ID as string,
  RootComponent,
  {
    appName: name,
    appVersion: version,
    options: {
      streaming: false,
    },
  }
);

createRoot(document.getElementById('root') as Element).render(
  <RootComponentWithFF />
);
