
// Packages
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// Components
import AchievementCardHeader from 'gamification/components/achievement-card-header';
import {
  ActivityBadgeSection, ActivityLevelSection, ActivityModuleSection, ActivityRegisterSection,
} from 'gamification/components/activity-sections';
import CustomButton from 'shared/components/custom-button';
import { Point } from 'shared/svgs';
import SvgIcon from 'shared/svgs/helper/svg-icon';

// Utilities
import { getDaysFromActivities } from 'gamification/helpers';

// Scss
import 'gamification/components/activities-card.scss';
import { useGetActivities } from 'shared/hooks/use-get-activities';

export default function ActivitiesCard(props) {
  const { t } = useTranslation('translations');
  const { className } = props;
  const { data: activitiesResponse } = useGetActivities();
  const activities = activitiesResponse?.data.result || [];
  const [maxVisibleEntries, setMaxVisibleEntries] = useState(5);
  const filteredActivities = activities.slice(0, maxVisibleEntries);
  const days = getDaysFromActivities(filteredActivities).sort((a, b) => {
    if (a.day === b.day) return 0;
    if (new Date(a.isoTimestamp) > new Date(b.isoTimestamp)) return -1;
    return 1;
  });

  return (
        <Card className={`shadow ${className}`}>
            <Card.Body className='d-flex flex-column'>
                <AchievementCardHeader
                    title={t('Activities')}
                    info={t('achievement activities tooltip')}
                />
                <div className='activity-list mx-0 px-2'>
                    <div className='activity-list-border'>
                        {days && days.length && days.map((day, dayIdx) => (
                            <ActivityDay
                                date={day.day}
                                key={dayIdx.toString()}
                            >
                                {day.activities.map((act, idx) => {
                                  const isLast = idx === day.activities.length - 1;
                                  if (act.module) {
                                    return (
                                            <ActivityModuleSection
                                                activity={act}
                                                isLast={isLast}
                                                key={idx.toString()}
                                            />
                                    );
                                  }
                                  if (act.badge) {
                                    return (
                                            <ActivityBadgeSection
                                                activity={act}
                                                isLast={isLast}
                                                key={idx.toString()}
                                            />
                                    );
                                  }
                                  if (act.level) {
                                    return (
                                            <ActivityLevelSection
                                                activity={act}
                                                isLast={isLast}
                                                key={idx.toString()}
                                            />
                                    );
                                  }
                                  if (act.type === 17) {
                                    return (
                                            <ActivityRegisterSection
                                                activity={act}
                                                isLast={isLast}
                                                key={idx.toString()}
                                            />
                                    );
                                  }
                                  return null;
                                })}
                            </ActivityDay>
                        ))}
                        {activities.length > 5 && (
                            <div className='d-flex mt-auto mb-2'>
                                <CustomButton
                                    variant='link'
                                    label={activities.length > maxVisibleEntries ? t('Show more') : t('Show less')}
                                    className='mx-auto'
                                    onClick={() => setMaxVisibleEntries(activities.length > maxVisibleEntries ? maxVisibleEntries + 2 : 5)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Card.Body>
        </Card>
  );
}

ActivitiesCard.propTypes = {
  progress: PropTypes.shape({
    completed: PropTypes.number,
    total: PropTypes.number,
    xp: PropTypes.number,
    level: PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.string,
      name: PropTypes.string,
      xp: PropTypes.number,
    }),
  }),
  className: PropTypes.string,
};

ActivitiesCard.defaultProps = {
  progress: null,
  className: '',
};

function ActivityDay(props) {
  const { children, date } = props;
  return (
        <div className='d-flex flex-column pl-3 pl-md-4 pr-4 position-relative'>
            <SvgIcon
                Icon={Point}
                size='16px'
                className='activity-day-dot color-primary'
                setStroke={false}
            />
            <span className='mt-1 mb-3'>
                {date} {/* TODO: locale */}
            </span>
            {children}
        </div>
  );
}
