
// Packages
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { animateScroll as scroll } from 'react-scroll';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Hooks
import useUser from 'shared/hooks/use-user';
import usePlatformSettings from 'shared/hooks/use-platform-settings';

// Utilities
import { MODULE_STATES } from 'shared/utilities/module-states';
import { getStatusOfModule, TRACKED_EVENTS } from 'elearning/helpers';
import { checkCheatsActive } from 'shared/helpers';
import { getIdByReference } from 'shared/utilities/reference-handling';
import { SESSION_STORAGE_KEY_MODULE } from 'shared/utilities/constants';

// Components
import CategoryCard from 'elearning/components/category-card';
import CustomButton from 'shared/components/custom-button';
import DidYouKnowCard from 'elearning/components/did-you-know-card';
import Divider from 'elearning/components/divider';
import ExternContentCard from 'elearning/components/extern-content-card';
import LevelCard from 'elearning/components/level-card';
import QuickStartCard from 'elearning/components/quick-start-card';
import WelcomeCard from 'elearning/components/welcome-card';
import Helmet from 'shared/components/helmet';
import TourStepWrapper from 'shared/components/onboarding/tour-step-wrapper';
import { TOURS } from 'shared/utilities/tour-configs';
import Loading from 'shared/components/loading';
import TourMobileStepWrapper from 'shared/components/onboarding/tour-mobile-step-wrapper';
import DebugSettings from 'test/components/debug-settings';
import { ProductAnalytics } from 'shared/modules/product-analytics';
import { useSoSafeAnalytics } from 'shared/hooks/use-sosafe-analytics';
import { ANALYTICS_TYPE } from 'shared/modules/sosafe-connect';
import { useCategoriesRequest } from 'elearning/hooks/use-categories-request';

const useEffectOnlyOnce = (func) => useEffect(func, []);

export default function CategoryPage() {
  const history = useHistory();
  const { t } = useTranslation([
    'translations',
    'messageTranslations',
    'helmetTranslations',
  ]);
  const { user } = useUser({});
  const { sendSoSafeAnalytics } = useSoSafeAnalytics();
  const { refetch: refetchCategories, data: categoriesResponse } =
    useCategoriesRequest(true);

  useEffect(() => {
    ProductAnalytics.getInstance().trackEvent(TRACKED_EVENTS.START_PAGE_VIEWED);

    const selectedModule = sessionStorage.getItem(SESSION_STORAGE_KEY_MODULE);

    /**
     * Check if a module information has been saved with the "SESSION_STORAGE_KEY_MODULE" key
     * If so, redirect to the player page for that module
     *
     * Logic can be find in the checkRedirect function in shared/helpers/redirect/check-redirect
     *
     * This is a temporary bug fix, refactoring of the router-controllers should handle this.
     *  */

    if (selectedModule) {
      const { catKey, modKey } = JSON.parse(selectedModule);

      if (catKey && modKey) {
        history.push(`/elearning/${catKey}/${modKey}`);
      }
      sessionStorage.removeItem(SESSION_STORAGE_KEY_MODULE);
    }
  }, []);

  const { loading, reference } = useSelector(
    (state) => state.modules,
    shallowEqual
  );

  const categories = categoriesResponse?.data.result || [];

  const cheatsActive = checkCheatsActive(user);

  const { settings: platformSettings } = usePlatformSettings();
  const { colleagues: showColleagues, hints: showHints } =
    platformSettings || {};

  useEffectOnlyOnce(() => {
    sendSoSafeAnalytics({ type: ANALYTICS_TYPE.PAGE_VIEWED });
  });

  const trainingRef = useRef(null);
  const scrollToTraining = () => {
    if (trainingRef && trainingRef.current) {
      scroll.scrollTo(getCoords(trainingRef.current).top - 75, {
        containerId: 'scrollContainer',
        duration: 200,
        delay: 10,
        smooth: true,
      });
    }
  };

  let statistics = null;
  if (categories && categories.length) {
    statistics = { score: 0, passed: 0, modulesAmount: 0 };
    categories.forEach((category) => {
      const { modules } = category;
      if (!modules) return;
      modules.forEach((mod) => {
        if (mod.module_name && mod.module_name !== '') {
          statistics.modulesAmount += 1;
        }
        statistics.score += mod.score;
        statistics.passed += getStatusOfModule(mod).includes(
          MODULE_STATES.PASSED,
        )
          ? 1
          : 0;
      });
    });
    statistics.score /= statistics.modulesAmount;
  }

  const errorFrame = (message) => (
    <div className="d-flex flex-column m-auto py-5">
      <span className="font-weight-normal mx-auto mb-4 span">{message}</span>
      <CustomButton
        label={t('Refresh')}
        onClick={() => refetchCategories()}
        className="mx-auto"
        disabled={loading}
      />
    </div>
  );
  let categoriesContent = null;
  switch (getIdByReference(reference)) {
    case 'NO_MODULES_UNLOCKED_FOR_LANG':
      categoriesContent = errorFrame(
        t(
          'messageTranslations:The e-learning you opened does not support the language you selected.'
        )
      );
      break;
    case 'NO_MODULES_ACTIVE_FOR_LANG':
      categoriesContent = errorFrame(
        t(
          'messageTranslations:The e-learning you have opened has no active modules with the language you selected.'
        )
      );
      break;
    case 'NO_MODULES_ACTIVE':
      categoriesContent = errorFrame(
        t(
          'messageTranslations:The e-learning you have opened has no active modules.'
        )
      );
      break;
    default:
      if (categories && categories.length) {
        categoriesContent = (
          <div className="row">
            {categories.map((cat, idx) =>
              idx === 0 ? (
                <TourStepWrapper
                  key={cat.id}
                  id="main_tour_category"
                  tour={TOURS.MainpageTour}
                  className={`col-12 ${cat.master ? 'col-xxl-12' : 'col-xxl-6'} mb-4`}
                  marginX={4}
                >
                  <CategoryCard category={cat} className="h-100" />
                </TourStepWrapper>
              ) : (
                <div
                  className={`col-12 ${cat.master ? 'col-xxl-12' : 'col-xxl-6'} mb-4`}
                  key={cat.id}
                >
                  <CategoryCard category={cat} className="h-100" />
                </div>
              )
            )}
          </div>
        );
      } else if (loading) {
        categoriesContent = (
          <div className="d-flex justify-content-center">
            <Loading />
          </div>
        );
      } else {
        categoriesContent = errorFrame(
          t(
            'messageTranslations:Connection to modules failed - Please reload the page.'
          )
        );
      }
      break;
  }

  const className = 'category-page d-flex flex-grow-1 flex-column';

  return (
    <React.Fragment>
      <Helmet title={t('helmetTranslations:Category overview')} />
      <TourStepWrapper id="main_tour_start" tour={TOURS.MainpageTour} />
      <TourStepWrapper id="main_tour_finish" tour={TOURS.MainpageTour} />
      <div className={className} data-for="ieFixToolTip" data-tip="">
        <div className="d-grid grid-col-1 grid-lg-col-3x1 grid-xxl-col-4x1">
          <div className="grid-row-1 grid-row-span-1 grid-col-1 grid-col-span-1">
            <div className="row">
              <TourStepWrapper
                marginX={4}
                className="col-12 col-md-6 flex-grow-1 mb-4"
                id="main_tour_welcome"
                tour={TOURS.MainpageTour}
              >
                <WelcomeCard />
              </TourStepWrapper>

              <TourStepWrapper
                marginX={4}
                className="col-12 col-md-6 flex-grow-1 mb-4"
                id="main_tour_quickstart"
                tour={TOURS.MainpageTour}
              >
                <QuickStartCard />
              </TourStepWrapper>
            </div>
            <Divider
              label={t('To the training')}
              direction="down"
              className="mb-4 mt-0"
              onClick={scrollToTraining}
            />
          </div>

          <div className="grid-row-2 grid-row-span-1 grid-col-1 grid-col-span-1 grid-lg-row-1 grid-lg-row-span-2 grid-lg-col-2">
            <div className="row">
              <div className="d-grid grid-col-1 grid-md-col-1x2 grid-lg-col-1 w-100">
                {!!user?.customer?.info_widgets?.length && (
                  <TourStepWrapper
                    marginX={4}
                    className="mb-4 grid-row-1 grid-row-span-1 grid-col-1 grid-col-span-1 grid-md-col-2 grid-lg-col-1"
                    id="main_tour_external"
                    tour={TOURS.MainpageTour}
                  >
                    <ExternContentCard />
                  </TourStepWrapper>
                )}

                <div
                  className={`grid-row-span-1 grid-col-1 grid-col-span-1 grid-md-row-1 grid-md-row-span-2 grid-lg-row-span-1 ${
                    user?.customer?.info_widgets?.length
                      ? 'grid-row-2 grid-lg-row-2'
                      : 'grid-row-1 grid-lg-row-1'
                  } ${showHints || showColleagues ? '' : ''}`}
                >
                  <div className="row">
                    {cheatsActive && <DebugSettings className="col-12 mb-4" />}
                    <LevelCard className="col-12 mb-4" />
                  </div>
                </div>

                <div
                  className={`grid-row-span-1 grid-col-1 grid-col-span-1 grid-md-col-2 grid-lg-row-span-1 grid-lg-col-1 ${
                    user?.customer?.info_widgets?.length
                      ? 'grid-row-3 grid-md-row-2 grid-md-row-span-1 grid-lg-row-3'
                      : 'grid-row-2 grid-md-row-1 grid-md-row-span-2 grid-lg-row-2'
                  }`}
                >
                  <div className="row">
                    {showHints && (
                      <TourStepWrapper
                        marginX={4}
                        id="main_tour_hints"
                        tour={TOURS.MainpageTour}
                        className="col-12 mb-4"
                      >
                        <DidYouKnowCard key="dykc" className="my-first-step" />
                      </TourStepWrapper>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Divider
              label={t('To the top')}
              direction="up"
              className="mb-4 mt-0 d-lg-none"
              onClick={() => scrollTop()}
            />
          </div>

          <div className="grid-row-3 grid-row-span-1 grid-col-1 grid-col-span-1 grid-lg-row-2">
            <span name="trainingComp" ref={trainingRef} />
            {categoriesContent}
            <Divider
              label={t('To the top')}
              direction="up"
              className="mb-4 mt-0 d-lg-none"
              onClick={() => scrollTop()}
            />
          </div>
        </div>
      </div>
      <TourMobileStepWrapper tour={TOURS.MainpageTourMobile} />
    </React.Fragment>
  );
}

function scrollTop(delay) {
  setTimeout(() => {
    scroll.scrollToTop({
      containerId: 'scrollContainer',
      duration: 200,
      delay: delay || 10,
      smooth: true,
    });
  }, 0);
}

function getCoords(elem) {
  // crossbrowser version
  const box = elem.getBoundingClientRect();

  const { body } = document;
  const docEl = document.documentElement;

  const scrolltop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollleft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = box.top + scrolltop - clientTop;
  const left = box.left + scrollleft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
}
