
import ActionTypes from 'shared/utilities/action-types';

/**
 * Dispatch function to clear reference of auth reducer
 *
 * @returns {function} Functio to dispatch
 *
 * @category authentication
 * @subcategory services
 * @exports dispatchClearAuthRef
 */
export default function dispatchClearAuthRef() {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_AUTH_REF });
  };
}
