// Packages
import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "@sosafe-platform-engineering/fe-lib-ui-react";

// Components
import AchievementCardHeader from "gamification/components/achievement-card-header";
import ExperienceSpiderChart from "gamification/components/experience-spider-chart";
import ExperienceLegendBubble from "./experience-legend-bubble";
import ExperienceCakeChart from "./experience-cake-chart";

// Scss
import "./experience-card.scss";
import useUser from "shared/hooks/use-user";
import { useMemo } from "react";

const COLORS = [
  "#D77B01",
  "#1C9B80",
  "#005547",
  "#018090",
  "#7682EF",
  "#5F5E86",
  "#E16E3A",
];

export const ExperienceCard = () => {
  const { t } = useTranslation("translations");
  const { user, loading } = useUser({});

  const data = useMemo(
    () =>
      user?.game.progress.spider.map((gameProgress, i) => {
        return {
          ...gameProgress,
          color: COLORS[i % COLORS.length],
          progress: Math.floor(gameProgress.progress) / 100,
          value: gameProgress.progress,
          name: gameProgress.name || gameProgress.category_name,
          modules: gameProgress.modules || [],
        };
      }) || [],
    [user?.game.progress.spider]
  );

  return (
    <Card className="w-100 h-100">
      <Card.Body className="d-flex flex-column">
        <AchievementCardHeader
          title={t("Experience")}
          info={t("achievement experience tooltip")}
        />

        <div className="experience-chart flex-grow-1 d-flex flex-column">
          {!loading && !data?.length && (
            <span className="u-textMedium">{t("Loading failed")}</span>
          )}

          {data.length > 3 && (
            <ExperienceSpiderChart
              className="h-100 d-flex flex-column flex-grow-1"
              data={data}
              loading={loading}
            />
          )}

          {data.length < 4 && (
            <ExperienceCakeChart
              className="h-100 d-flex flex-column flex-grow-1"
              data={data}
              loading={loading}
            />
          )}
        </div>

        <div className="px-0 px-md-4">
          <ul className="list-unstyled m-0">
            {data.map((c, i) => (
              <li className="mt-1 d-flex" key={i.toString()}>
                <div className="my-auto d-flex">
                  <ExperienceLegendBubble
                    size="21"
                    color={c.color}
                    className="my-auto"
                    number={i}
                  />
                </div>
                <span className="mx-1" />
                <span className="my-auto u-textMedium text-nowrap">
                  {c.name}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ExperienceCard;
