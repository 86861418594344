
import ActionTypes from 'shared/utilities/action-types';

/**
 * Dispatch data to playerpage
 *
 * @param {object} data
 * @param {bool} data.isContinue Continue or restart module
 * @returns {function} Function to dispatch
 *
 * @category elearning
 * @subcategory services
 * @exports dispatchSetPlayerData
 */
export default function dispatchSetPlayerData(data) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_PLAYER_DATA, payload: data });
  };
}
