
// Packages
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// Components
import Loading from 'shared/components/loading';

// Utilities
import { getNextModuleRelease } from 'elearning/helpers';
import { dateToDDMMYYYY } from 'shared/helpers';

// Hooks
import useWelcomeMessage from 'elearning/hooks/use-welcome-message';

// Scss
import './welcome-card.scss';
import useUser from 'shared/hooks/use-user';

function WelcomeCard(props) {
  const { className } = props;
  const { t } = useTranslation();
  const {
    title, message, footer, loading,
  } = useWelcomeMessage();
  const { user } = useUser({});

  const { infoNotAllModulesAvailable, releaseDate } = getNextModuleRelease(user);

  if (loading) {
    return (
            <Card
                className={`welcome-card shadow hover-shadow overflow-hidden ${className}`}
                as='section'
            >
                <Card.Body className='d-flex'>
                    <div className='align-self-center mx-auto'>
                        <div className='pulse-loader'>
                            <Loading size='medium' />
                        </div>
                    </div>
                </Card.Body>
            </Card>
    );
  }

  return (
        <Card
            className={`welcome-card shadow hover-shadow overflow-hidden ${className}`}
            as='section'
        >
            <Card.Body className='d-flex flex-column'>
                <p className='h3 mb-2'>{title}</p>
                <span className='mb-1'>{message}</span>
                <div className='d-flex flex-column mt-auto'>
                    <span className='u-textMedium'>{footer}</span>
                    {infoNotAllModulesAvailable && <span className='u-textMedium'>{t('footerNextRelease', { x: dateToDDMMYYYY(releaseDate) })}</span>}
                </div>
            </Card.Body>
        </Card>
  );
}

WelcomeCard.propTypes = {
  className: PropTypes.string,
};

WelcomeCard.defaultProps = {
  className: '',
};

export default React.memo(WelcomeCard);
