
// Packages
import PropTypes from 'prop-types';
import React, {
  useRef, useEffect, useState, useContext,
} from 'react';
import { animateScroll as scroll, Events as ScrollEvents } from 'react-scroll';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';

// Hooks
import useWindowSize from 'shared/hooks/use-window-size';

// Utilities
import { TOURS, STEP_TYPES } from 'shared/utilities/tour-configs';
import { tourGetConfig, widthInViewport } from 'shared/helpers';
import { checkIsIE } from 'shared/helpers/check';

// Components
import CustomButton from 'shared/components/custom-button';
import SvgIcon from 'shared/svgs/helper/svg-icon';
import { Close } from 'shared/svgs';
import ResponsivePicture from '../responsive-picture';

// Scss
import './tour-step-wrapper.scss';
import GlobalSettingsContext from 'shared/modules/global-settings/state/global-settings-context.provider';
import { useTourWithAnalytics } from 'shared/hooks/use-tour/use-tour-with-analytics';

export default function TourStepWrapper(props) {
  const wrapper = useRef(null);
  const tooltip = useRef(null);
  const [updateTooltipRef, setUpdateTooltipRef] = useState(null);
  const { tourCancel, tourNextStep, tourPrevStep } = useTourWithAnalytics();
  const { step } = useSelector((state) => state.tour, shallowEqual);
  const { globalSettings } = useContext(GlobalSettingsContext);

  const { t, i18n } = useTranslation(['translations', 'tourTranslations']);
  const arab = i18n.language === 'ar' ? 'arab' : '';
  const RL = i18n.language === 'ar' ? 'l' : 'r';
  const LR = i18n.language === 'ar' ? 'r' : 'l';

  const {
    className,
    children,
    id,
    tour,
    disabled,
    marginX,
    marginY,
  } = props;
  const [show, setShow] = useState(false);
  const debounceTime = 30;
  // Update on window size change. Debounced with 30 ms;
  const { width: windowWidth, height: windowHeight } = useWindowSize(debounceTime);

  const dispatch = useDispatch();

  const scrollContainerId = 'scrollContainer';

  // unmount
  useEffect(() => () => {
    ScrollEvents.scrollEvent.remove('end');
    enableOverflow(scrollContainerId);
  }, []);

  let overlay = null;

  const config = tourGetConfig(tour);
  const stepIndex = config?.steps?.findIndex((s) => s.id === id);
  const {
    title,
    description,
    type,
    scroll: isScroll,
    style,
  } = stepIndex < 0 ? {} : config?.steps?.[stepIndex];

  let {margin} = props;
  if (margin === null || margin === undefined) {
    margin = (type === STEP_TYPES.CENTER || type === STEP_TYPES.FINISH || type === STEP_TYPES.START) ? 0 : 16;
  }
  const marginObj = {
    mt: marginY || margin,
    mb: marginY || margin,
    ml: marginX || margin,
    mr: marginX || margin,
  };
  const {
    mt,
    mb,
    ml,
    mr,
  } = marginObj;

  const offset = 18;

  if (show) {
    const {
      bottom,
      left,
      right,
      top,

      arrowCenterX,
      arrowCenterY,
      arrowRotation,

      tooltipTop,
      tooltipLeft,
    } = calcPositions(wrapper, tooltip, windowWidth, windowHeight, type, offset, margin, scrollContainerId);

    const nextButtonText = () => {
      switch (type) {
        case STEP_TYPES.STEP:
        case STEP_TYPES.CENTER:
        case STEP_TYPES.START:
          return t('Next');
        case STEP_TYPES.FINISH:
          return t('Close');
        default:
          return '';
      }
    };
    const borderRadius = 12;
    overlay = (
            <div
                className='w-100 h-100 position-fixed tour-overlay'
                role='dialog'
            >
                <svg
                    version='1'
                    xmlns='http://www.w3.org/2000/svg'
                    width='100%'
                    height='100%'
                >
                    <g
                        opacity='.5'
                        id='overlay'
                    >
                        <path
                            d={`M${0},${0} L${windowWidth},${0} ${windowWidth},${top - mt} 0,${top - mt} 0,0`}
                            stroke='blue'
                            strokeWidth='0'
                        />
                        <path
                            d={`M${0},${0} L${left - ml},${0} ${left - ml},${windowHeight} 0,${windowHeight} 0,0`}
                            stroke='blue'
                            strokeWidth='0'
                        />
                        <path
                            d={`M${right + mr},${0} L${windowWidth},${0} ${windowWidth},${windowHeight} ${right + mr},${windowHeight} ${right + mr},${0}`}
                            stroke='blue'
                            strokeWidth='0'
                        />
                        <path
                            d={`M${0},${bottom + mb} L${windowWidth},${bottom + mb} ${windowWidth},${windowHeight} ${0},${windowHeight} ${0},${bottom + mb}`}
                            stroke='blue'
                            strokeWidth='0'
                        />
                        {style && style.borderRadius && (
                            <g>
                                <path
                                    d={`M${left - ml} ${top - mt + borderRadius} C ${left - ml} ${top - mt}, ${left - ml} ${top - mt}, ${left - ml + borderRadius} ${top - mt} L${left - ml} ${top - mt}`}
                                    stroke='blue'
                                    strokeWidth='0'
                                    fill='black'
                                />
                                <path
                                    d={`M${right + mr} ${top - mt + borderRadius} C ${right + mr} ${top - mt}, ${right + mr} ${top - mt}, ${right + mr - borderRadius} ${top - mt} L${right + mr} ${top - mt}`}
                                    stroke='blue'
                                    strokeWidth='0'
                                    fill='black'
                                />
                                <path
                                    d={`M${left - ml} ${bottom + mb - borderRadius} C ${left - ml} ${bottom + mb}, ${left - ml} ${bottom + mb}, ${left - ml + borderRadius} ${bottom + mb} L${left - ml} ${bottom + mb}`}
                                    stroke='blue'
                                    strokeWidth='0'
                                    fill='black'
                                />
                                <path
                                    d={`M${right + mr} ${bottom + mb - borderRadius} C ${right + mr} ${bottom + mb}, ${right + mr} ${bottom + mb}, ${right + mr - borderRadius} ${bottom + mb} L${right + mr} ${bottom + mb}`}
                                    stroke='blue'
                                    strokeWidth='0'
                                    fill='black'
                                />
                            </g>
                        )}
                    </g>
                    {(type !== STEP_TYPES.CENTER && type !== STEP_TYPES.FINISH && type !== STEP_TYPES.START) && (
                        <g
                            transform={`translate(${arrowCenterX}, ${arrowCenterY})`}
                            opacity='.9'
                        >
                            <path
                                d='M-5,-20 L5,0 -5,20'
                                transform={`rotate(${arrowRotation})`}
                                className='stroke-primary'
                                strokeWidth='4'
                                strokeLinejoin='round'
                                troke-linecap='round'
                                fill='none'
                            />
                        </g>
                    )}
                </svg>
                <div
                    className={`card shadow tour-tooltip ${tooltipTop && tooltipLeft ? 'show' : 'hide'} ${arab}`}
                    style={{ top: `${tooltipTop}px`, left: `${tooltipLeft}px` }}
                    ref={tooltip}
                >
                    <div className='d-flex flex-row justify-content-between'>
                        <h4 className='mb-2 h3'>{t(title)}</h4>
                        <CustomButton
                            variant='link'
                            className='pt-0 pr-0 pl-0 pb-2'
                            label={
                                <SvgIcon
                                    Icon={Close}
                                    className='mb-1'
                                    color='black'
                                    size='16px'
                                />
                            }
                            ariaLabel={t('Close')}
                            onClick={() => dispatch(tourCancel({ skipAnalytics: false }))}
                        />
                    </div>
                    {type === STEP_TYPES.START && (
                        <div className='position-relative'>
                            <ResponsivePicture
                                src={`${window.elearningRuntimeConfig.RESOURCE_URL}resource/${globalSettings.customerToken || 'default'}/img/assets/tour_training_start.png`}
                                alt='Tour start'
                                className='w-100 tour-image'
                                defaultHeight={200}
                            />
                        </div>
                    )}
                    {(type === STEP_TYPES.FINISH && tour === TOURS.MainpageTour) && (
                        <div className='position-relative'>
                            <ResponsivePicture
                                src={`${window.elearningRuntimeConfig.RESOURCE_URL}resource/${globalSettings.customerToken || 'default'}/img/assets/tour_training_end.png`}
                                alt='Tour end'
                                className='w-100 tour-image'
                                defaultHeight={200}
                            />
                        </div>
                    )}
                    <p>{t(description)}</p>
                    <div className='d-flex flex-row justify-content-between'>
                        {!!stepIndex && (
                            <CustomButton
                                label={t('Back')}
                                className={`py-0 m${RL}-auto`}
                                variant='outline-secondary'
                                onClick={() => dispatch(tourPrevStep())}
                            />
                        )}
                        {stepIndex >= 0 && (
                            <span className={stepIndex > 0 ? 'text-muted mx-4' : 'text-muted'}>{stepIndex + 1} / {config.steps.length}</span>
                        )}
                        <CustomButton
                            label={nextButtonText()}
                            className={`py-0 m${LR}-auto`}
                            onClick={() => dispatch(tourNextStep({}))}
                            focus
                        />
                    </div>
                </div>
            </div>
    );
  }

  const showTour = () => {
    if (isScroll) {
      enableOverflow(scrollContainerId);
      scrollTo(
        wrapper,
        offset,
        scrollContainerId,
      );
    } else {
      setShow(true);
      setTimeout(() => {
        setUpdateTooltipRef(!updateTooltipRef);
        disableOverflow(scrollContainerId);
      }, 1);
    }
  };

  useEffect(() => {
    if (!disabled && widthInViewport(windowWidth, props.viewPorts)) {
      if (step === id && !show) {
        setTimeout(() => {
          showTour();
        }, 1);

        if (isScroll) {
          ScrollEvents.scrollEvent.register('end', () => {
            setShow(true);
            setTimeout(() => {
              setUpdateTooltipRef(!updateTooltipRef);
              disableOverflow(scrollContainerId);
            }, 1);
          });
        }
      } else if (step !== id && show) {
        if (config?.steps?.length === (stepIndex + 1)) {
          setShow(false);
          ScrollEvents.scrollEvent.register('end', () => {
            setTimeout(() => {
              enableOverflow(scrollContainerId);
            }, 1);
          });
          scroll.scrollTo(0, {
            containerId: scrollContainerId,
            duration: 500,
            delay: 10,
            smooth: 'easeOutQuad',
            offset: 50,
          });
        } else {
          setShow(false);
          enableOverflow(scrollContainerId);
        }
      }
    }
  }, [step, windowWidth]);

  if (disabled) return <div className={className}>{children}</div>;

  switch (type) {
    case STEP_TYPES.STEP:
      return (
                <React.Fragment>
                    <div
                        id={id}
                        ref={wrapper}
                        className={className}
                    >
                        {children}
                    </div>
                    <AsPortal>
                        {overlay}
                    </AsPortal>
                </React.Fragment>
      );
    case STEP_TYPES.START:
    case STEP_TYPES.CENTER:
    case STEP_TYPES.FINISH:
      return (
                <AsPortal>
                    {overlay}
                </AsPortal>
      );
    default:
      return (
                <div
                    className={className}
                    id={id}
                >
                    {children}
                </div>
      );
  }
}

TourStepWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  tour: PropTypes.string.isRequired,
  margin: PropTypes.number,
  marginX: PropTypes.number,
  marginY: PropTypes.number,
};

TourStepWrapper.defaultProps = {
  margin: null,
  marginX: null,
  marginY: null,
};

function scrollTo(wrapper, offset, containerId) {
  if (!wrapper?.current) return;
  const rect = wrapper?.current?.getBoundingClientRect();
  const scrollcontainer = document.getElementById(containerId);
  const rectScroll = scrollcontainer.getBoundingClientRect();

  // TODO duration dynamic

  const destPos = Math.abs(rect.top + (scrollcontainer.scrollTop - rectScroll.top) - offset);

  scroll.scrollTo(destPos, {
    containerId,
    duration: 500,
    delay: 10,
    smooth: 'easeOutQuad',
    offset: 50,
  });
}

const getSpaceLeft = (width, tooltipWidth, margin, arrowCenterX, left, windowWidth) => {
  if (width > tooltipWidth
        || ((left + width / 2) - (tooltipWidth / 2 + margin) > 0
        && (left + width / 2) + (tooltipWidth / 2 + margin) < windowWidth)) {
    return arrowCenterX - tooltipWidth / 2;
  }
  if (left + tooltipWidth + margin < windowWidth) {
    return left;
  }
  return left + width - (tooltipWidth + margin);
};

function calcPositions(wrapper, tooltip, windowWidth, windowHeight, type, offset, margin, scrollContainerId) {
  const centerPoint = {
    bottom: windowHeight * 0.5,
    height: 0,
    left: windowWidth * 0.5,
    right: windowWidth * 0.5,
    top: windowHeight * 0.5,
    width: 0,
  };

  const rect = wrapper.current ? wrapper.current.getBoundingClientRect() : centerPoint;

  const {
    bottom,
    height,
    left,
    right,
    top,
    width,
  } = rect;

  let tooltipTop = 0;
  let tooltipLeft = 0;
  let arrowCenterX = 0;
  let arrowCenterY = 0;
  let arrowRotation = 0;

  const tooltipRect = tooltip && tooltip.current ? tooltip.current.getBoundingClientRect() : null;

  if (tooltipRect) {
    const {
      height: tooltipHeight,
      width: tooltipWidth,
    } = tooltipRect;

    const headerHeight = document.getElementById(scrollContainerId)?.getBoundingClientRect().top || 0;

    const maxHeight = height < windowHeight - headerHeight ? height : windowHeight - headerHeight;

    const outerSpace = offset + margin * 2;

    const leftSpace = left - outerSpace;
    const rightSpace = windowWidth - (right + outerSpace);
    const topSpace = top - outerSpace;
    const bottomSpace = windowHeight - (bottom + outerSpace);

    let position = null;

    if (type === STEP_TYPES.CENTER || type === STEP_TYPES.FINISH || type === STEP_TYPES.START) {
      position = 'center';
    } else if (leftSpace > tooltipWidth && height > tooltipHeight) {
      position = 'left';
    } else if (rightSpace > tooltipWidth && height > tooltipHeight) {
      position = 'right';
    } else if (topSpace > tooltipHeight && windowWidth > tooltipWidth) {
      position = 'top';
    } else if (bottomSpace > tooltipHeight && windowWidth > tooltipWidth) {
      position = 'bottom';
    } else {
      position = 'center';
    }

    // Arrow position - Allways centered to wrapper
    switch (position) {
      case 'left':
        arrowCenterX = left - offset - margin;
        arrowCenterY = top + maxHeight * 0.5;
        arrowRotation = 0;
        break;
      case 'right':
        arrowCenterX = right + offset + margin;
        arrowCenterY = top + maxHeight * 0.5;
        arrowRotation = 180;
        break;
      case 'bottom':
        arrowCenterX = left + width * 0.5;
        arrowCenterY = bottom + offset + margin;
        arrowRotation = 270;
        break;
      case 'top':
        arrowCenterX = left + width * 0.5;
        arrowCenterY = top - offset - margin;
        arrowRotation = 90;
        break;
      default:
        break;
    }

    switch (position) {
      case 'center':
        tooltipTop = windowHeight * 0.5 - tooltipHeight * 0.5;
        tooltipLeft = windowWidth * 0.5 - tooltipWidth * 0.5;
        break;
      case 'left':
        tooltipTop = arrowCenterY - tooltipHeight * 0.5;
        tooltipLeft = arrowCenterX - tooltipWidth - offset;
        break;
      case 'right':
        tooltipTop = arrowCenterY - tooltipHeight * 0.5;
        tooltipLeft = arrowCenterX + offset;
        break;
      case 'top':
        tooltipTop = arrowCenterY - tooltipHeight - offset;
        tooltipLeft = getSpaceLeft(width, tooltipWidth, margin, arrowCenterX, left, windowWidth);
        break;
      case 'bottom': {
        tooltipTop = arrowCenterY + offset;
        tooltipLeft = getSpaceLeft(width, tooltipWidth, margin, arrowCenterX, left, windowWidth);
        break;
      }
      default:
        break;
    }
  }
  return {
    bottom,
    left,
    right,
    top,

    arrowCenterX,
    arrowCenterY,
    arrowRotation,

    tooltipTop,
    tooltipLeft,
  };
}

function disableOverflow(id) {
  if (checkIsIE()) {
    const elem = document.getElementById(id);
    elem.style.overflowX = 'hidden';
    elem.style.overflowY = 'hidden';
  }
}

function enableOverflow(id) {
  if (checkIsIE()) {
    const elem = document.getElementById(id);
    elem.style.overflowX = 'hidden';
    elem.style.overflowY = 'auto';
  }
}

function isDomElement(obj) {
  try {
    /* Using W3 DOM2 (works for FF, Opera and Chrome) */
    return obj instanceof HTMLElement;
  } catch (error) {
    /* Browsers not supporting W3 DOM2 don't have HTMLElement and
        an exception is thrown and we end up here. Testing some
        properties that all elements have (works on IE7) */
    return (typeof obj === 'object') && (obj.nodeType === 1) && (typeof obj.style === 'object') && (typeof obj.ownerDocument === 'object');
  }
}

function AsPortal({ children }) {
  if (!isDomElement(document.body)) return null;
  return ReactDOM.createPortal(
    children,
    document.body,
  );
}
