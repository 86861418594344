import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  Center,
  Flex,
  Stack,
  Title,
} from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import AuthCardImage from './auth-card-image.component';

interface AuthCardProps {
  title: string;
  children?: React.ReactNode;
}

const AuthCard: React.FC<AuthCardProps> = ({ title, children }) => {
  const { i18n } = useTranslation('translations');
  return (
    <Center h="100%">
      <Card shadow="sm" maw={800} p={0} w="100%" mb="xl" mx="md">
        <Flex justify={{ base: 'center', md: 'none' }}>
          <AuthCardImage
            flex={{ base: 'none', md: '52%' }}
            flipx={i18n.language === 'ar'}
          />

          <Stack
            p="xl"
            pb="lg"
            maw={380}
            flex={{ base: 'none', md: '48%' }}
            gap="lg"
          >
            <Title order={2} ta="center">
              {title}
            </Title>
            <Box>{children}</Box>
          </Stack>
        </Flex>
      </Card>
    </Center>
  );
};

export default AuthCard;
