
import { MODULE_STATES } from 'shared/utilities/module-states';

/**
 * Get module status
 *
 * @param {*} module the module
 * @returns {Array} array with single MODULE_STATES string
 *
 * @category elearning
 * @subcategory helpers
 * @exports getStatusOfModule
 */

export default function getStatusOfModule(module) {
  const status: Array<MODULE_STATES> = [];

  if (!module) {
    return [];
  }

  switch (module.status) {
    case 0:
      status.push(MODULE_STATES.UNTREATED);
      break;
    case 1:
      status.push(MODULE_STATES.PAUSED);
      break;
    case 2:
      status.push(MODULE_STATES.FAILED);
      break;
    case 3:
      status.push(MODULE_STATES.PASSED);
      break;
    default:
      status.push(MODULE_STATES.UNTREATED);
  }

  if (module.score !== null && module.score !== undefined && module.score < 75) {
    status.push(MODULE_STATES.FAILED);
  }

  if (module.status !== 3) {
    const { finished_by } = module;
    if (finished_by) {
      const now = Date.now();
      const timeFinishedBy = Date.parse(finished_by);
      const dt = timeFinishedBy - now;
      if (dt <= 0) {
        status.push(MODULE_STATES.EXPIRED);
      } else {
        const dtDays = Math.floor(dt / 86400000);
        if (dtDays <= 7) {
          status.push(MODULE_STATES.EXPIRE_SOON);
        }
      }
    }
  }
  return status;
}
