
import { applyMiddleware, createStore } from 'redux';
// import logger from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ReducersProps } from 'types';
import reducers from '../reducers';

const blacklistTransform = createTransform((inboundState: any) => ({
  ...inboundState,
  loading: false,
  reference: null,
}));

const persistConfig = {
  key: 'root',
  storage,
  transforms: [blacklistTransform],
  blacklist: ['debug', 'branding', 'welcomeCard', 'rewards', 'survey', 'optin', 'welcome'],
};

const middleware = applyMiddleware(thunk, promise);

const persistedReducer = persistReducer<ReducersProps>(persistConfig, reducers);
const store = createStore(persistedReducer, middleware);
const persistor = persistStore(store);

export { store, persistor };
