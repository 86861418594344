
// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './shake-container.scss';

export default function ShakeContainer(props) {
  const { className, children, disabled } = props;

  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    let interval = null;
    let timer = null;

    if (disabled) return () => {};

    interval = setInterval(() => {
      setIsAnimated(true);
      timer = setTimeout(() => {
        setIsAnimated(false);
      }, 2400);
    }, 9000);

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, []);

  return (
        <div className={`shake-container ${className} ${(isAnimated && !disabled) ? 'animated' : ''}`}>
            {children}
        </div>
  );
}

ShakeContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
};

ShakeContainer.defaultProps = {
  className: '',
  disabled: false,
};
