
import { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "@sosafe-platform-engineering/fe-lib-ui-react";
import { Redirect } from "react-router-dom";

import "./downloads.page.css";
import { RootState } from "state/reducers";
import Collapse from "shared/components/collapse";
import { useHistory } from "react-router";
import useInitTranslations from "lang/use-init-translations.hook";
import Loading from "shared/components/loading";
import usePlatformSettings from "shared/hooks/use-platform-settings";
import DownloadRow from "./components/download-row/download-row.component";
import { DownloadsDataType } from "./downloads.types";
import sendGetDownloads from "./services/send-get-downloads.service";
import translations from "./lang";

/** This page will display a list of downloadable materials, ordered by category */
export default function DownloadsPage() {
  const history = useHistory();
  const { translationsReady } = useInitTranslations(translations, "downloads");
  const { t } = useTranslation();
  const { apikey } = useSelector(
    (state: RootState) => state.auth?.user ?? {},
    shallowEqual
  );
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DownloadsDataType>([]);

  const { settings } = usePlatformSettings();
  const { material_area } = settings;

  useEffect(() => {
    if (material_area && apikey) {
      sendGetDownloads({
        apikey,
        setLoading,
        setData,
      });
    }
  }, [material_area, apikey]);

  if (!material_area) return <Redirect to="/" />;

  if (!translationsReady || loading)
    return (
      <div className="sosafe-DownloadsPage-loading u-flex u-flexJustifyCenter u-flexAlignItemsCenter">
        <Loading />
      </div>
    );

  return (
    <div className="sosafe-DownloadsPage">
      <Button ariaLabel={t("Back")} onClick={history.goBack}>
        <Trans i18nKey={"Back"} />
      </Button>
      <div>
        {data.map((category) => (
          <section
            className="sosafe-DownloadsPage-category"
            key={`category_${category.id}`}
          >
            <Collapse
              className="sosafe-DownloadsPage-categoryTitle"
              Title={() => <h2>{category.title}</h2>}
            >
              <div>
                <DownloadRow downloads={category.materials} />
                {category.categories?.map((subCategory) => (
                  <Collapse
                    key={`subcategory_${subCategory.id}`}
                    className="sosafe-DownloadsPage-categorySubtitle"
                    Title={() => <h3>{subCategory.title}</h3>}
                  >
                    <div>
                      <DownloadRow downloads={subCategory.materials} />
                    </div>
                  </Collapse>
                ))}
              </div>
            </Collapse>
          </section>
        ))}
      </div>
    </div>
  );
}
