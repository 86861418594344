// Packages
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { withRouter, useHistory } from 'react-router';
import * as Sentry from '@sentry/react';
// Hooks
import useAuthentication from 'authentication/hooks/use-authentication';

// Components
import ActionTypes from 'shared/utilities/action-types';
import RedirectPage from 'authentication/pages/redirect-page';
import UpdatingPlayerPage from 'authentication/pages/updating-player-page';
import { checkUser, getUrlParameter } from 'shared/helpers';

import { UseReferenceHandler } from 'shared/utilities/use-reference-handler';
import { RouterController } from 'router-controller/router-controller';
import { useFFClient } from '@sosafe-aws/fe-lib-feature-flags';

function AuthenticationWrapper() {
  const history = useHistory();
  const dispatch = useDispatch();
  const checkReference = UseReferenceHandler();

  const {
    signOut,
    signIn,
    signUp,
    isSignInProgress,
    isSignOutProgress,
    samlLoading,
  } = useAuthentication();

  const { user } = useSelector((state) => state.auth, shallowEqual);

  const [userInvalid, setUserInvalid] = useState(user && !checkUser(user));
  const ffClient = useFFClient();
  const [ffClientInitialized, setFfClientInitialized] = useState(false);

  useEffect(() => {
    if (user && user.uuid && ffClient && !ffClientInitialized) {
      const customerIdentifyObject = user.customer?.uuid
        ? {
            customer: {
              key: user.customer?.uuid,
              industry: user.customer?.salesforce_data?.account.industry,
            },
          }
        : {};

      if (!ffClientInitialized) {
        ffClient.identify({
          kind: 'multi',
          user: {
            key: user.uuid,
            language: user.language,
          },
          ...customerIdentifyObject,
        });
        setFfClientInitialized(true);
      }
    }
  }, [user, ffClient, ffClientInitialized]);

  // Set the customerId tag for Sentry
  useEffect(() => {
    if (user && user.customer) {
      Sentry.setTag('customerId', user.customer.uuid);
    }
  }, [user]);

  const [loading, setLoading] = useState();

  useEffect(() => {
    const errorHashString = getUrlParameter(window.location.hash, 'error');
    const errorSubcodeHashString = getUrlParameter(
      window.location.hash,
      'error_subcode'
    );

    if (errorHashString && errorSubcodeHashString !== 'cancel') {
      checkReference({ reference: errorHashString });
      history.push(
        '',
        document.title,
        window.location.pathname + window.location.search
      );
    }
  }, []);

  useEffect(() => {
    if (!user && isSignOutProgress) {
      history.push('/');
    }

    if (user && !checkUser(user)) {
      setUserInvalid(true);
      dispatch((disp) => disp({ type: ActionTypes.CLEAR_STORE }));
      setLoading(true);
      history.push('/');
    } else {
      setUserInvalid(false);
    }
  }, [user, isSignOutProgress]);

  if (loading || userInvalid) {
    return <UpdatingPlayerPage />;
  }

  if (isSignInProgress || isSignOutProgress || samlLoading) {
    return (
      <RedirectPage
        isSignInProgress={isSignInProgress}
        isSignOutProgress={isSignOutProgress}
        samlLoading={samlLoading}
      />
    );
  }

  return (
    <React.Fragment>
      <RouterController {...{ signIn, signOut, signUp }} /> :
    </React.Fragment>
  );
}

export default withRouter(AuthenticationWrapper);

AuthenticationWrapper.propTypes = {
  location: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
};
