
import ActionTypes from 'shared/utilities/action-types';

const INITIAL_STATE = {};

class GameReducer {
  static reduce(state = INITIAL_STATE, action) {
    let reference;
    let result;
    const payload = action.payload;
    if (payload) {
      reference = payload.reference;
      result = payload.result;
    }
    switch (action.type) {
      case ActionTypes.GET_BADGES_REQUEST:
        return { ...state, loadingBadges: true, reference: null };
      case ActionTypes.GET_BADGES_SUCCESS:
        return {
          ...state, loadingBadges: false, reference, badges: result,
        };
      case ActionTypes.GET_BADGES_FAILED:
        return { ...state, loadingBadges: false, reference };
      case ActionTypes.GET_ACTIVITIES_REQUEST:
        return { ...state, loading: true, reference: null };
      case ActionTypes.GET_ACTIVITIES_SUCCESS:
        return {
          ...state, loading: false, reference, activities: result,
        };
      case ActionTypes.GET_ACTIVITIES_FAILED:
        return { ...state, loading: false, reference };
      case ActionTypes.AUTH_LOGOUT_FAILED:
      case ActionTypes.AUTH_LOGOUT_SUCCESS:
      case ActionTypes.CLEAR_STORE:
        return {};
      default:
        return state;
    }
  }
}

export default GameReducer.reduce;
