
import useGoogleIdentityServices from 'authentication/hooks/use-google-identity-services';
import { Button } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { FaGoogle, FaMicrosoft } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { AUTH_MODE } from 'shared/modules/global-settings/hooks/use-global-settings.hooks';
import { UseGlobalSettingsContext } from 'shared/modules/global-settings/state/global-settings-context.provider';
import { Azure } from 'shared/svgs';
import { parseCodeLanguageToISO6391 } from 'shared/utilities/languages';
import './sso.scss';

export default function SsoForm(props) {
  const { type = 'LOGIN' } = props;
  const history = useHistory();
  const [loadGoogle, setLoadGoogle] = useState(false);

  const { t, i18n } = useTranslation('translations');
  const { globalSettings } = UseGlobalSettingsContext();

  const { init } = useGoogleIdentityServices();

  const hasAuthMode = (requestedMethod) => {
    const availableMethods = globalSettings?.auth_methods?.methods;
    if (!availableMethods?.length) return false;
    return availableMethods?.includes(requestedMethod);
  };

  useEffect(() => {
    const doInit = async () => {
      try {
        await init();
      } catch (error) {
        console.error('Failed to init Google Identity Services');
      }
    };
    if (loadGoogle && hasAuthMode(AUTH_MODE.GOOGLE)) {
      doInit();
    }
  }, [globalSettings, i18n.language, loadGoogle]);

  const onSaml = () => {
    if (globalSettings.domain) {
      if (type === 'LOGIN') {
        props.doSignIn('SAML', {
          domain: globalSettings.domain,
        });
      } else {
        props.doSignUp('SAML', {
          domain: globalSettings.domain,
        });
      }
    } else {
      history.push('/saml');
    }
  };

  return (
    <div className="loginPage__authMethods">
      {hasAuthMode(AUTH_MODE.MICROSOFT) && (
        <Button
          justify="space-between"
          variant="secondary"
          leftSection={<FaMicrosoft size="1.125rem" />}
          rightSection={<span />}
          onClick={() =>
            type === 'LOGIN' ? props.doSignIn('MICROSOFT') : props.doSignUp('MICROSOFT')
          }
          aria-label={
            type === 'LOGIN' ? t('Login with Microsoft') : t('Registration with Microsoft')
          }
        >
          <span className="mx-auto">
            {type === 'LOGIN' ? t('Login with Microsoft') : t('Registration with Microsoft')}
          </span>
        </Button>
      )}
      {hasAuthMode(AUTH_MODE.SAML) && (
        <Button
          justify="space-between"
          leftSection={<Azure size="1.25rem" />}
          rightSection={<span />}
          variant="secondary"
          onClick={onSaml}
          aria-label={type === 'LOGIN' ? t('Login with SAML') : t('Registration with SAML')}
        >
          <span className="mx-auto">
            {type === 'LOGIN' ? t('Login with SAML') : t('Registration with SAML')}
          </span>
        </Button>
      )}
      {hasAuthMode(AUTH_MODE.GOOGLE) && (
        <div className="loginPage__googleButtonArea">
          <div id="g_id_signin" className="loginPage__googleSigninArea" />
          {!loadGoogle && (
            <React.Fragment>
              <Button
                className="loginPage__googleButton"
                justify="space-between"
                variant="secondary"
                leftSection={<FaGoogle size="1.125rem" />}
                rightSection={<span />}
                onClick={() => setLoadGoogle(true)}
              >
                <span className="mx-auto">{t('Enable Google Login')}</span>
              </Button>
              <div className="loginPage__ssoGooglePrivacy">
                <small>
                  <Trans
                    i18nKey={'translations:Google privacy note'}
                    components={{
                      1: (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://policies.google.com/privacy?hl=${parseCodeLanguageToISO6391(
                            i18n?.language ?? 'en'
                          )}`}
                        >
                          Google's privacy policy
                        </a>
                      ),
                    }}
                  >
                    By clicking on the Google SSO login you enable data processing by the provider.
                    For more information about the specific data processing please refer to{' '}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://policies.google.com/privacy?hl=${parseCodeLanguageToISO6391(
                        i18n?.language ?? 'en'
                      )}`}
                    >
                      Google's privacy policy
                    </a>
                    .
                  </Trans>
                </small>
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
}

SsoForm.propTypes = {
  type: PropTypes.string,
  doSignIn: PropTypes.func,
  doSignUp: PropTypes.func,
};

SsoForm.defaultProps = {
  type: 'LOGIN',
  doSignIn: () => { },
  doSignUp: () => { },
};
