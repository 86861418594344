
import ActionTypes from 'shared/utilities/action-types';

/**
 * Dispatch function to save key value pair into preferences reducer.
 *
 * @param {string} key Pref key
 * @param {string} value Pref value
 * @returns {function} Function to dispatch
 *
 * @category shared
 * @subcategory services
 * @exports dispatchSetPreference
 */
export default function dispatchSetPreference(key, value) {
  return (dispatch) => dispatch({ type: ActionTypes.SET_PREFERENCES, data: { [key]: value } });
}
