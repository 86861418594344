
// Packages
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

// Hooks
import useUser from 'shared/hooks/use-user';
import usePlatformSettings from 'shared/hooks/use-platform-settings';

// Services
import { ProductAnalytics } from 'shared/modules/product-analytics';

// Components
import ExperienceCard from 'gamification/components/experience-card';
import LevelCard from 'gamification/components/level-card';
import ActivitiesCard from 'gamification/components/activities-card';
import ProgressCard from 'gamification/components/progress-card';
import Helmet from 'shared/components/helmet';
import TourStepWrapper from 'shared/components/onboarding/tour-step-wrapper';
import { TOURS } from 'shared/utilities/tour-configs';
import TourMobileStepWrapper from 'shared/components/onboarding/tour-mobile-step-wrapper';
import { ACHIEVEMENT_PAGE_OPENED_FROM, TRACKED_EVENTS } from 'elearning/helpers';
import { useSoSafeAnalytics } from 'shared/hooks/use-sosafe-analytics';
import { ANALYTICS_TYPE } from 'shared/modules/sosafe-connect';
import BadgesCard from 'gamification/components/badge-card';

export default function AchievementsPage() {
  const { t } = useTranslation(['translations', 'helmetTranslations']);
  const history = useHistory();
  const { sendSoSafeAnalytics } = useSoSafeAnalytics();

  const { settings: platformSettings } = usePlatformSettings();
  const {
    level: showProgress,
    badges: showBadges,
  } = platformSettings || {};

  const { user, loading } = useUser({});

  if (!user?.game && !loading) {
    history.push('/');
  }

  useEffect(() => {
    sendSoSafeAnalytics({ type: ANALYTICS_TYPE.PAGE_VIEWED });

    ProductAnalytics.getInstance().trackEvent(TRACKED_EVENTS.ACHIEVEMENT_PAGE_OPENED, {
      source: ((history.location || {}).state || {}).openedFrom || ACHIEVEMENT_PAGE_OPENED_FROM.DIRECT_ACCESS,
    });
  }, []);

  return (
        <React.Fragment>
            <Helmet title={t('helmetTranslations:Achievements')} />
            <TourStepWrapper
                id='achievement_tour_finish'
                tour={TOURS.AchievementTour}
            />
            <div className='achievements-page row mx-0'>
                <div className={`col-12 ${showBadges ? 'col-xxl-4' : 'col-xxl-5'}`}>
                    <div className='row h-100 align-content-start'>
                        <div className='col-12 col-md-6 col-xxl-12'>
                            <div className='row'>
                                <TourStepWrapper
                                    id='achievement_tour_level'
                                    tour={TOURS.AchievementTour}
                                    className='col-12 mb-4'
                                >
                                    <LevelCard
                                        progress={user?.game.progress}
                                        className='w-100'
                                    />
                                </TourStepWrapper>

                                {showProgress && (
                                    <TourStepWrapper
                                        id='achievement_tour_development'
                                        tour={TOURS.AchievementTour}
                                        className='col-12 mb-4'
                                    >
                                        <ProgressCard
                                            className='w-100'
                                        />
                                    </TourStepWrapper>
                                )}
                            </div>
                        </div>
                        <TourStepWrapper
                            id='achievement_tour_spider'
                            tour={TOURS.AchievementTour}
                            className='col-12 col-md-6 col-xxl-12 mb-4'
                        >
                            <ExperienceCard/>
                        </TourStepWrapper>
                    </div>
                </div>
                <div className={`col-12 ${showBadges ? 'col-xxl-8' : 'col-xxl-7'}`}>
                    <div className='row h-100'>
                        <TourStepWrapper
                            id='achievement_tour_activities'
                            tour={TOURS.AchievementTour}
                            className={`col-12 activity-wrapper mb-4 ${showBadges ? 'col-lg-6' : ''}`}
                        >
                            <ActivitiesCard
                                className='h-100 w-100'
                            />
                        </TourStepWrapper>

                        {showBadges && (
                            <TourStepWrapper
                                id='achievement_tour_badges'
                                tour={TOURS.AchievementTour}
                                className='col-12 col-lg-6 mb-4'
                            >
                                <BadgesCard className='w-100 h-100'/>
                            </TourStepWrapper>
                        )}
                    </div>
                </div>
            </div>
            <TourMobileStepWrapper tour={TOURS.AchievementTourMobile} />
        </React.Fragment>
  );
}
