
/**
 * Download the respective document in the language provided
 *
 * @param {string} document document
 * @param {Event} e click event to prevent
 * @param {boolean} showInBrowser set true to show file in new browser tab
 * @param {string} lang i18n style language
 * @param {string} customer_id id of the customer
 * @returns null
 *
 * @category shared
 * @subcategory helpers
 * @exports downloadDocument
 */
export default function getDocumentUrl(document, showInBrowser, lang, customer_id) {
  return `${window.elearningRuntimeConfig.API_URL}document/${customer_id ? `${customer_id}/` : ''}download?document=${document}&language=${lang}&download=${!showInBrowser}`;
}
