
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { escapeRegExp } from 'shared/helpers';

export function getSVG(svg, params = []) {
  const result = svg.replace(new RegExp(escapeRegExp('<path'), 'g'), `<path ${params.join(' ')}`);
  return result;
}

export function svgToBase64(svg) {
  return `data:image/svg+xml;base64,${btoa(svg)}`;
}

export function getBackgroundSVG(ToConvert, params = []) {
  return `url(${componentToBase64(ToConvert, params)})`;
}

export function componentToBase64(ToConvert, params = []) {
  let toString = ReactDOMServer.renderToStaticMarkup(<ToConvert />);
  toString = toString.replace(new RegExp(escapeRegExp('<svg'), 'g'), '<svg xmlns="http://www.w3.org/2000/svg"');
  let result = toString;
  if (params.length > 0) {
    result = getSVG(toString, params);
  }
  return svgToBase64(result);
}
