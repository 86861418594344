import React, { ReactElement, useEffect } from 'react';
import {
  Group,
  Stack,
  Text,
  Divider,
} from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import { Link, RouteProps, useHistory } from 'react-router-dom';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from 'state/reducers';
import ActionTypes from 'shared/utilities/action-types';
import { SESSION_STORAGE_KEY_MODULE } from 'shared/utilities/constants';
import Helmet from 'shared/components/helmet';
import {
  SsoFlow,
  SsoFormV2,
} from 'authentication/components/sso-form/sso-form-v2';
import { UseRequestActivateAccount } from 'authentication/hooks/use-request-activate-account';
import SignInForm from 'authentication/components/singin-form/signin-form';
import { AuthFunction } from 'authentication/types/auth-funtion.type';
import AuthCardFooter from 'authentication/components/auth-card-footer.component';
import AuthCard from 'authentication/components/auth-card.component';
import useHasAuthMode from 'authentication/components/sso-form/use-has-auth-mode.hook';

interface LoginPageProps {
  doSignIn: AuthFunction;
  doSignUp: AuthFunction;
  location: RouteProps['location'] & {
    state?: {
      mail?: string;
    };
  };
}

export default function NewLoginPage(props: LoginPageProps): ReactElement {
  const { t } = useTranslation(['translations', 'helmetTranslations']);
  const { activationSuccess } = useSelector(
    (state: RootState) => state.auth,
    shallowEqual
  );
  const { hasSamlSso, hasMicrosoftSso, hasGoogleSso } = useHasAuthMode();
  const isSsoFormVisible = hasSamlSso || hasMicrosoftSso || hasGoogleSso;
  const dispatch = useDispatch();
  const history = useHistory();

  UseRequestActivateAccount();

  useEffect(() => {
    const isModuleKeySessionStorageLogout = sessionStorage.getItem(
      SESSION_STORAGE_KEY_MODULE
    );

    if (isModuleKeySessionStorageLogout === 'logout') {
      /**
       * Item must be removed to clear the session storage to allow for it to be used again.
       * Timeout is required since the pages are rendered prematurely and pages rendered multiple times,
       * leading to the session item to be removed before the checkRedirect function is called.
       *
       * This change should be considered a temporary fix until authentication and routes can be refactored
       */
      setTimeout(() => {
        sessionStorage.removeItem(SESSION_STORAGE_KEY_MODULE);
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (activationSuccess) {
      dispatch({ type: ActionTypes.CLEAN_UP_ACTIVATION_CODE });
      history.push('/');
    }
  }, [activationSuccess, dispatch, history]);

  return (
    <React.Fragment>
      <Helmet title={t('helmetTranslations:Login')} />

      <AuthCard title={t('translations:Sign in now')}>
        <Stack gap="lg">
          {isSsoFormVisible && (
            <>
              <SsoFormV2 flowType={SsoFlow.LOGIN} doSignIn={props.doSignIn} />
              <Divider
                label={<Text c="gray.5">{t('translations:OR')}</Text>}
                labelPosition="center"
              />
            </>
          )}
          <Stack gap="lg">
            <SignInForm
              doSignIn={props.doSignIn}
              memoizedEmail={props.location?.state?.mail}
            />
            <Group justify="center">
              <Text size="sm">
                {t("translations:Don't have an account?")}{' '}
                <Link className="text-nowrap" to="/register">
                  {t('translations:Go to registration')}
                </Link>
              </Text>
            </Group>
          </Stack>
        </Stack>
        <AuthCardFooter mt={70} />
      </AuthCard>
    </React.Fragment>
  );
}
