import {
  Flex,
  FlexProps,
  useMantineTheme,
} from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import React, { useContext } from 'react';
import ResponsivePicture from 'shared/components/responsive-picture';
import GlobalSettingsContext from 'shared/modules/global-settings/state/global-settings-context.provider';

interface AuthImageProps extends FlexProps {
  flipx: boolean;
}

const AuthCardImage: React.FC<AuthImageProps> = ({ flipx, ...flexProps }) => {
  const { globalSettings } = useContext(GlobalSettingsContext);
  const theme = useMantineTheme();
  return (
    <Flex
      bg={theme.colors.teal[1]}
      align="end"
      display={{ base: 'none', md: 'flex' }}
      {...flexProps}
    >
      <ResponsivePicture
        src={`${window.elearningRuntimeConfig.RESOURCE_URL}resource/${globalSettings.customerToken || 'default'}/img/assets/login_desk.png`}
        alt=""
        fit="cover"
        flipx={flipx}
      />
    </Flex>
  );
};

export default AuthCardImage;
