
// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { checkIsIE } from 'shared/helpers';

// Scss
import './star.scss';

/**
 * Functional component to display star svg. Notifies parent on mouse enter and on mouse leave. Gets filled on enter or on prop-change
 *
 * @category shared
 * @subcategory components
 * @exports Star
 * @component
 */
export default function Star(props) {
  const {
    className, active, onHover, onClick, selected,
  } = props;

  return (
        <div
            className={`cursor-pointer ${className}`}
            onMouseEnter={onHover}
            onClick={onClick}
            role='option'
            aria-selected={selected}
        >
            <svg
                xmlns='http://www.w3.org/2000/svg'
                version='1.1'
                width='34'
                height='34'
                viewBox='0 0 24 24'
                className={`${active ? 'fill-primary' : 'fill-white'} ${!checkIsIE() ? 'animate-star' : ''} stroke-dark`}
            >
                <path
                    d='M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z'
                />
            </svg>
        </div>
  );
}

Star.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  onHover: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

Star.defaultProps = {
  className: '',
  active: false,
  selected: false,
};
