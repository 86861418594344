
// Packages
import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Hooks
import useMobileCheck from 'shared/hooks/use-mobile-check';

// Services
import { dispatchSetPreference } from 'shared/services';

// Utilities
import { checkIsIE, getComponentByUrl } from 'shared/helpers';
import { TOURS } from 'shared/utilities/tour-configs';

// Components
import CustomButton from 'shared/components/custom-button';
import SvgIcon from 'shared/svgs/helper/svg-icon';
import { Tour, ChevronUp } from 'shared/svgs';

// Scss
import './tour-hint.scss';
import { useTourWithAnalytics } from './use-tour/use-tour-with-analytics';

/**
 * Displays floating tooltip unter tour-header button
 *
 * @export
 * @returns TourHint JSX
 */
export default function useTourHint(isMobile = false) {
  const { t, i18n } = useTranslation('translations');
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { welcome } = user?.game || {};
  const { tourStart } = useTourWithAnalytics();
  const dispatch = useDispatch();

  const component = getComponentByUrl();
  let type = null;
  let pageName = null;
  switch (component) {
    case 'achievements':
      type = TOURS.AchievementTour;
      pageName = t('Achievement page');
      break;
    case 'modules':
      type = TOURS.ModulepageTour;
      pageName = t('Modules page');
      break;
    case 'categories':
      type = TOURS.MainpageTour;
      pageName = t('Training page');
      break;
    default:
      break;
  }

  const onStart = () => {
    dispatch(tourStart({ id: type }));
    dispatch(dispatchSetPreference(`${type}_hidden`, true));
  };

  const allHidden = useSelector((state) => state.userPreferences).hidden;
  const typeHidden = useSelector((state) => state.userPreferences)[`${type}_hidden`];

  const hidden = allHidden || typeHidden;

  const onCancel = () => dispatch(dispatchSetPreference('hidden', true));

  const isTourHintHidden = !type || hidden || (welcome && welcome[component]);

  const { isMobile: screenIsMobile } = useMobileCheck();

  const arab = `${i18n.language === 'ar' ? 'arab' : ''}`;

  let tourHint;
  if (isTourHintHidden) {
    tourHint = null;
  } else {
    let classN = '';
    if (isMobile) classN = 'isMobile';
    if (!isMobile && !checkIsIE()) classN = 'animate';

    tourHint = (
            <React.Fragment>
                {screenIsMobile && <div className='position-fixed overlay-tour-hint' />}
                <div className={`TourHint w-100 card shadow px-2 py-2 ${classN} ${arab}`}>
                    {!isMobile && (
                        <div className='hint-arrow'>
                            <SvgIcon
                                Icon={ChevronUp}
                                className='color-primary'
                            />
                        </div>
                    )}
                    <div className='d-flex flex-row mb-2 justify-content-between'>
                        <strong className='my-auto'>{t('New here')}?</strong>
                        <div className='col-2 justify-content-end d-flex p-0'>
                            <SvgIcon
                                Icon={Tour}
                                size='26'
                                className='my-auto stroke-icon-gray'
                            />
                        </div>
                    </div>
                    <div className='u-textMedium mb-2'>
                        {t('Start the tour of the {{ pageName }} now!', { pageName })}
                    </div>
                    <div className='d-flex flex-row justify-content-between'>
                        <CustomButton
                            variant='link'
                            className='px-0 py-0'
                            label={t('Later')}
                            onClick={onCancel}
                        />
                        <CustomButton
                            className='py-0'
                            label={t('Start')}
                            onClick={onStart}
                        />
                    </div>
                </div>
            </React.Fragment>
    );
  }

  return {
    tourHint,
    isTourHintHidden,
  };
}
