
/**
 * Checks if tour step is enabled on given viewports and window width
 *
 * @param {number} windowWidth width of the window
 * @param {Array} viewports array of enabled viewport keys
 * @returns {boolean} if tour step is enabled
 *
 * @category shared
 * @subcategory helpers
 * @exports widthInViewport
 */
export default function widthInViewport(windowWidth, viewports) {
  if (!windowWidth) return false;
  if (!viewports || !viewports.length) return true;
  const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  };

  let windowBreakpoint = 'xs';
  if (windowWidth < breakpoints.sm) {
    windowBreakpoint = 'xs';
  } else if (windowWidth < breakpoints.md) {
    windowBreakpoint = 'sm';
  } else if (windowWidth < breakpoints.lg) {
    windowBreakpoint = 'md';
  } else if (windowWidth < breakpoints.xl) {
    windowBreakpoint = 'lg';
  } else {
    windowBreakpoint = 'xl';
  }
  return viewports.includes(windowBreakpoint);
}
