
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUrlParameter, URL_BRANDING_API } from 'shared/helpers';
import soSafeAxios from 'state/axios';
import setContextInCookie from '../../../helpers/storage/set-context-cookie';

type GetSettingsRequestParameters = { token: string } | { domain: string };

export enum AUTH_MODE {
  DEFAULT,
  MICROSOFT,
  GOOGLE,
  SAML,
}

export type GlobalSettings = {
  copyright: boolean;
  customerToken: string;
  domain: string;
  footer_logo: string;
  imprint: string;
  logo: string;
  favicon: string;
  privacy: string;
  saml_auto_login: boolean;
  support: string;
  tab_title_prefix: string;
  auth_methods?: {
    methods: AUTH_MODE[];
  };
  branding_version_id?: string;
  hrmDashboard: boolean;
};

export const defaultGlobalSettings: GlobalSettings = {
  copyright: true,
  customerToken: '',
  domain: '',
  footer_logo: '',
  imprint: '',
  logo: '',
  privacy: '',
  saml_auto_login: false,
  support: '',
  favicon: '/fav.png', // This points to public/fav.png and constitutes the fallback Icon, in case our customers don't provide one for custom branding
  tab_title_prefix: '',
  auth_methods: undefined,
  hrmDashboard: false,
};

type useGlobalSettingsResult = {
  globalSettings: GlobalSettings;
  loading: boolean;
  setLocalToken: (token: string) => void;
  removeLocalToken: () => void;
};

export default function useGlobalSettings(): useGlobalSettingsResult {
  const { t } = useTranslation('translations');
  const [loading, setLoading] = useState(false);
  const [globalSettings, setGlobalSettings] = useState<GlobalSettings>({
    ...defaultGlobalSettings,
    tab_title_prefix: t`SoSafe E-Learning`,
  });

  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));

  const fetchBrandCustomization = useCallback(() => {
    let requestParameters: GetSettingsRequestParameters;
    const localToken = localStorage.getItem('token');
    const urlToken = getUrlParameter(window.location.search, 'branding');

    const isManagerPreview = !!urlToken;
    if (isManagerPreview) {
      requestParameters = { token: urlToken };
    } else if (localToken) {
      requestParameters = { token: localToken };
    } else {
      requestParameters = { domain: window.location.hostname };
    }

    setLoading(true);
    soSafeAxios
      .get(URL_BRANDING_API(), { params: requestParameters })
      .then(({ data: responseData }) => {
        setLoading(false);
        const { result } = responseData;
        if (!result) {
          console.error('[PLATFORM-SETTINGS] No result from GET');
        } else {
          const { customer } = result;
          if (customer) {
            setGlobalSettings((currentGlobalSettings) => ({
              ...currentGlobalSettings,
              copyright: customer.copyright,
              customerToken: customer.token,
              domain: customer.domain,
              favicon: customer.favicon,
              footer_logo: customer.footer_logo,
              imprint: customer.imprint,
              logo: customer.logo,
              privacy: customer.privacy,
              saml_auto_login: customer.saml_auto_login,
              support: customer.support,
              tab_title_prefix: customer.tab_title_prefix || globalSettings.tab_title_prefix,
              auth_methods: customer.auth_methods,
              branding_version_id: result.brand?.version_id,
              hrmDashboard: customer.components?.reporting?.hrm_dashboard?.enabled || false,
            }));
            setContextInCookie(customer.contextToken);
          } else {
            setGlobalSettings(defaultGlobalSettings);
          }
        }
      })
      .catch(() => {
        console.error('[PLATFORM-SETTINGS] GET failed');
        setLoading(false);
      });
  }, [globalSettings.tab_title_prefix, globalSettings.customerToken]);

  const setLocalToken = (newToken: string) => {
    localStorage.setItem('token', newToken);
    setGlobalSettings((oldState) => ({
      ...oldState,
      customerToken: newToken,
    }));
    setToken(newToken);
  };

  const removeLocalToken = () => {
    localStorage.removeItem('token');
    setGlobalSettings({
      ...defaultGlobalSettings,
    });
    setToken(null);
  };

  useEffect(() => {
    fetchBrandCustomization();
  }, [token]);

  return {
    loading,
    globalSettings,
    setLocalToken,
    removeLocalToken,
  };
}
