
import getAllModulesFromCategories from './get-all-modules-from-categories';
import getTopics from './get-topics';
import { MODULE_STATES } from 'shared/utilities/module-states';
import { checkModuleUnlocked } from '../check';

/**
 * Get module for quickstart card
 *
 * @param {*} categories
 * @param {*} user
 * @param {*} step_activation
 * @param {*} preventRandomModule
 * @returns module
 *
 * @category elearning
 * @subcategory helpers
 * @exports getModuleForQuickStartCard
 */
export default function getModuleForQuickStartCard(categories, user, step_activation, preventRandomModule = false) {
  const modulesByCategories = getTopics(getAllModulesFromCategories(categories), step_activation);
  const unlockedModulesByCategory = modulesByCategories.map((cat) => {
    cat.modules = cat.modules.filter((module) => checkModuleUnlocked(user, module));
    return cat;
  });

  const countUnlocked = user?.game?.progress.total || 0;
  const countDone = user?.game?.progress.completed || 0;

  // Return random completed module if all modules already done
  if (countUnlocked === countDone && countDone) {
    if (preventRandomModule) {
      return null;
    }
    const randomCategory = unlockedModulesByCategory[Math.floor(Math.random() * unlockedModulesByCategory.length)];
    return randomCategory?.modules[Math.floor(Math.random() * randomCategory.modules.length)] || null;
  }

  // Check for started modules
  let startedModule = null;
  unlockedModulesByCategory.forEach((cat) => {
    cat.modules.forEach((mod) => {
      if (mod.statusOfModule.includes(MODULE_STATES.PAUSED)) {
        if (!startedModule) startedModule = mod;
        if (startedModule && mod.progress > startedModule.progress) {
          startedModule = mod;
        }
      }
    });
  });
  if (startedModule) return startedModule;

  // Check for next due date
  let dueModule = null;
  unlockedModulesByCategory.forEach((cat) => {
    cat.modules.forEach((mod) => {
      if (mod.finished_by && mod.active && !mod.statusOfModule.includes(MODULE_STATES.PASSED)) {
        if (!dueModule) dueModule = mod;
        if (dueModule && new Date(dueModule.finished_by) > new Date(mod.finished_by)) {
          dueModule = mod;
        }
      }
    });
  });
  if (dueModule) return dueModule;

  // Select next module;
  let nextMasterModule = null;
  let nextBasicModule = null;
  let nextExtendModule = null;
  let nextExpertModule = null;

  unlockedModulesByCategory.forEach((cat) => {
    cat.modules.forEach((mod) => {
      if (mod.master && mod.statusOfModule.includes(MODULE_STATES.UNTREATED)) {
        if (!nextMasterModule) nextMasterModule = mod;
      } else if (mod.active && mod.statusOfModule.includes(MODULE_STATES.UNTREATED)) {
        if (mod.stage === 0 && !nextBasicModule) nextBasicModule = mod;
        if (mod.stage === 1 && !nextExtendModule) nextExtendModule = mod;
        if (mod.stage === 2 && !nextExpertModule) nextExpertModule = mod;
      }
    });
  });
  if (nextMasterModule) return nextMasterModule;
  if (nextBasicModule) return nextBasicModule;
  if (nextExtendModule) return nextExtendModule;
  if (nextExpertModule) return nextExpertModule;

  return null;
}
