import React from 'react';
import LoginPage from 'authentication/pages/login.page';
import NewLoginPage from 'authentication/pages/new-login.page';
import RegisterConfPage from 'authentication/pages/register-conf-page';
import RegisterGetDataPage from 'authentication/pages/register-get-data-page';
import SamlPage from 'authentication/pages/saml-page';
import SetPasswordPage from 'authentication/pages/set-password-page';
import RequestPasswordPage from 'authentication/pages/request-password-page';
import AppPageContent from 'shared/components/app-page-content';
import { RouterWithAuthorizationMethod } from 'router-controller/router-with-authorization';
import { AuthContextProps } from 'auth-context/auth-context';
import ErrorPage from 'shared/pages/error/error.page';
import AuthPageContent from 'authentication/components/auth-page-content.component';

export const name = 'AuthRouteController';

export const NewWrapper = ({ children }: { children: JSX.Element }) => (
  <AuthPageContent isFooterHidden isNewLayout>
    {children}
  </AuthPageContent>
);

export const OldWrapper = ({ children }: { children: JSX.Element }) => (
  <AppPageContent>{children}</AppPageContent>
);

export const routes = [
  {
    path: '/',
    render: (p: any) => (
      <RouterWithAuthorizationMethod
        Component={
          process.env.FEATURE_FLAG_NEW_LOGIN_PAGE === 'true' ||
          window.elearningRuntimeConfig.FEATURE_FLAG_NEW_LOGIN_PAGE === 'true'
            ? (auth: AuthContextProps) => (
                <NewWrapper>
                  <NewLoginPage
                    location={p.location}
                    doSignUp={auth.signUp}
                    doSignIn={auth.signIn}
                  />
                </NewWrapper>
              )
            : (auth: AuthContextProps) => (
                <OldWrapper>
                  <LoginPage
                    location={p.location}
                    doSignUp={auth.signUp}
                    doSignIn={auth.signIn}
                  />
                </OldWrapper>
              )
        }
      />
    ),
  },
  {
    path: '/register',
    render: () => (
      <RouterWithAuthorizationMethod
        Component={(auth: AuthContextProps) => (
          <OldWrapper>
            <RegisterGetDataPage doSignUp={auth.signUp} />
          </OldWrapper>
        )}
      />
    ),
  },
  {
    path: '/registration',
    render: () => (
      <RouterWithAuthorizationMethod
        Component={(auth: AuthContextProps) => (
          <OldWrapper>
            <RegisterGetDataPage doSignUp={auth.signUp} />
          </OldWrapper>
        )}
      />
    ),
  },
  {
    path: '/register/conf',
    render: () => (
      <OldWrapper>
        <RegisterConfPage />
      </OldWrapper>
    ),
  },
  {
    path: '/password',
    render: (p) => (
      <OldWrapper>
        <RequestPasswordPage location={p.location} />
      </OldWrapper>
    ),
  },
  {
    path: '/setpassword/:token',
    render: () => (
      <OldWrapper>
        <SetPasswordPage />
      </OldWrapper>
    ),
  },
  {
    path: '/saml',
    render: () => (
      <OldWrapper>
        <SamlPage />
      </OldWrapper>
    ),
  },
  {
    path: '/503',
    render: () => (
      <OldWrapper>
        <ErrorPage errorCode={503} />
      </OldWrapper>
    ),
  },
];

export const defaultRoute = '/';

export const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <React.Fragment>{children}</React.Fragment>
);

export const UseIsPathAllowed = () => {
  const apiKey = localStorage.getItem('apikey');
  return {
    allowed: !apiKey,
    loading: false,
  };
};
