
import ActionTypes from 'shared/utilities/action-types';

class OptinReducer {
  static reduce(state = {}, action) {
    switch (action.type) {
      case ActionTypes.OPTIN_CHANGED:
        return {
          ...state,
          optin: action.optin,
        };

      case ActionTypes.AUTH_LOGIN_FAILED:
      case ActionTypes.AUTH_LOGIN_REQUEST:
      case ActionTypes.AUTH_LOGIN_SUCCESS:
      case ActionTypes.AUTH_LOGOUT_FAILED:
      case ActionTypes.AUTH_LOGOUT_REQUEST:
      case ActionTypes.AUTH_LOGOUT_SUCCESS:
      case ActionTypes.CLEAR_STORE:
        return {};

      default:
        return state;
    }
  }
}

export default OptinReducer.reduce;
