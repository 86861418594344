
// Packages
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Utilities
import { showToast, ALERT_TYPE } from "shared/utilities/modal-alert";

// Components
import AppPageContent from "shared/components/app-page-content";
import ResponsivePicture from "shared/components/responsive-picture";
import Loading from "shared/components/loading";

// Scss
import "app.scss";
import GlobalSettingsContext from "shared/modules/global-settings/state/global-settings-context.provider";

export default function RedirectPage(props) {
  const { globalSettings } = useContext(GlobalSettingsContext);
  const { t } = useTranslation(["translations", "messageTranslations"]);

  const { isSignInProgress, samlLoading } = props;

  // remove loading after timeout
  useEffect(() => {
    let timer2 = null;
    const timer = setTimeout(() => {
      showToast(ALERT_TYPE.ERROR, `${t("messageTranslations:An error has occured")} (Timeout)`);
      timer2 = setTimeout(() => {
        sessionStorage.removeItem("MSAL_STATE");
        sessionStorage.removeItem("MSAL_STATE");
        window.location.reload();
      }, 600);
    }, 60000); // 1 minute, due to slow login & registration response, temporary solution until theses requests are optimized
    return () => {
      if (timer) clearTimeout(timer);
      if (timer2) clearTimeout(timer2);
    };
  }, []);

  const label = isSignInProgress || samlLoading ? t("Logging in...") : t("Sign out ...");
  const classNames = "container base m-auto d-flex flex-column justify-content-center";
  return (
    <div className="app-content h-100">
      <div className="app-page">
        <AppPageContent center>
          <div className={classNames}>
            <div className="row justify-content-center">
              <div className="col-12 col-sm-9 col-md-7 col-lg-5 col-xl-4">
                <div className="card shadow">
                  <div className="d-flex py-4 flex-column">
                    <p className="mx-auto text-center">{label}</p>
                    <div className="mx-auto fs-color-primary text-center">
                      <Loading className="mx-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AppPageContent>
      </div>
    </div>
  );
}
