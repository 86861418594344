
// Packages
import React from "react";
import { Card } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { replacePlaceholdersWithUserData } from "elearning/helpers";

export default function ExternContentCard() {
  const { i18n } = useTranslation("translations");
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const customer = user?.customer;
  if (customer?.info_widgets) {
    const currLang = i18n.language;
    const currWidget = customer?.info_widgets.find((widget) => widget.language === currLang);
    // if (!currWidget) {
    //  currWidget = customer?.info_widgets[0];
    // }

    if (currWidget) {
      const content = replacePlaceholdersWithUserData(currWidget.content, user);
      return (
        <Card className="tour_extern shadow hover-shadow p-3">
          <div className="d-flex flex-column" dangerouslySetInnerHTML={{ __html: content }} />
        </Card>
      );
    }
    return null;
  }
  return null;
}
