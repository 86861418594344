
// Packages
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

// Utilities
import ActionTypes from 'shared/utilities/action-types';
import { getUrlParameter } from 'shared/helpers/get';
import { ALERT_TYPE, showToast } from 'shared/utilities/modal-alert';
import { URL_AUTH_API } from 'shared/helpers';
import Axios from 'axios';
import { UseReferenceHandler } from 'shared/utilities/use-reference-handler';
import { UseGlobalSettingsContext } from 'shared/modules/global-settings/state/global-settings-context.provider';
import { queryClient, queryKey, useSoSafeConnect } from 'shared/modules/sosafe-connect';
import useUser from 'shared/hooks/use-user';
import {addMethodToResponse} from "./use-login-request/utils"

export default function useSAML() {
  const dispatch = useDispatch();
  const { t } = useTranslation('messageTranslations');
  const history = useHistory();
  const { globalSettings, setLocalToken } = UseGlobalSettingsContext();
  const [samlLoading, setSamlLoading] = useState(false);
  const { user } = useUser({ enabled: false })

  const location = useLocation();

  const { endPoints, setApiKey } = useSoSafeConnect();
  const checkReference = UseReferenceHandler();

  const fetchAuthorization = useCallback(async (manuallyApiKey) => {
    try {
      const response = await endPoints.fetch.authorization({ manuallyApiKey });
      setSamlLoading(false);
      setApiKey(response.data.result.apikey);
      setLocalToken(response.data.result.customer.token);
      history.replace('/');
      queryClient.setQueryData([queryKey.AUTH], response);
      // store the user data in global storge.
      const responseWithMethod = addMethodToResponse(response);
      dispatch({
        payload: responseWithMethod,
        type: ActionTypes.AUTH_LOGIN_SUCCESS,
      });
    } catch (error) {
      setSamlLoading(false);
      const payload = { ...(error?.response?.data || {}) };
      delete payload.reference;
      dispatch({ payload, type: ActionTypes.AUTH_LOGIN_FAILED });
    }
  }, [endPoints.fetch, history, setApiKey, setLocalToken, t]);

  useEffect(() => {
    const apikeyFromUrl = getUrlParameter(location.search, 'saml');
    if (apikeyFromUrl) {
      setSamlLoading(true);
      fetchAuthorization(apikeyFromUrl);
    }
  }, []);

  const doSamlLogin = (domain) => {
    setSamlLoading(true);
    const data = { domain };
    const instance = Axios.create();
    instance
      .post(`${URL_AUTH_API()}/saml/login`, { ...data, area: 1 })
      .then((response) => {
        const { data: respData } = response;
        const { id } = respData || {};
        if (!id) {
          showToast(ALERT_TYPE.ERROR, `${t('messageTranslations:An error has occured')} (No ID)`);
          setSamlLoading(false);
          return;
        }
        window.location.replace(`${URL_AUTH_API()}/saml/login/${id}`);
      })
      .catch((error) => {
        setSamlLoading(false);
        if (error.response) {
          dispatch({
            payload: checkReference(error.response.data, dispatch),
            type: ActionTypes.AUTH_LOGIN_FAILED,
          });
        } else {
          dispatch({
            payload: checkReference(
              {
                title: error.name,
                status: error.statusCode,
                reference: '40700745-2789-4F88-8412-1885DDC724E4',
              },
              dispatch
            ),
            type: ActionTypes.AUTH_LOGIN_FAILED,
          });
        }
      });
  };

  useEffect(() => {
    const isSaml =
      getUrlParameter(location.search, 'sso') === 'SAML' ||
      getUrlParameter(location.search, 'sso') === 'saml' ||
      getUrlParameter(location.search, 'SSO') === 'saml' ||
      getUrlParameter(location.search, 'SSO') === 'SAML';

    const queryDomain =
      getUrlParameter(location.search, 'domain') || getUrlParameter(location.search, 'DOMAIN');
    const domain = queryDomain && queryDomain.split('/')[0];
    if (isSaml && domain && !user) {
      doSamlLogin(domain);
    }
  }, []);

  const { saml_auto_login, domain } = globalSettings;
  useEffect(() => {
    if (!user && !samlLoading && saml_auto_login && !sessionStorage.getItem('SAML_AUTO_LOGIN')) {
      sessionStorage.setItem('SAML_AUTO_LOGIN', 'true');
      doSamlLogin(domain || window.location?.hostname);
    }
    // eslint-disable-next-line
  }, [saml_auto_login]);

  return {
    samlLoading,
    signIn: doSamlLogin,
  };
}
