
/**
 * get module_name and removes lang and version
 *
 * @param {object} module Learning module
 * @returns {*} return value description
 *
 * @category elearning
 * @subcategory helpers
 * @exports getModuleKey
 */
export default function getModuleKey(module) {
  const name = module?.module_name;
  if (!name) return null;
  const arr = name.split('/');
  return arr?.[arr.length - 1];
}
