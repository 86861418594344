
// Packages
import React from 'react';
import PropTypes from 'prop-types';

// Components
import SvgIcon from 'shared/svgs/helper/svg-icon';
import { Steps, Shield } from 'shared/svgs';

// Hooks
import { useTranslation } from 'react-i18next';
import usePlatformSettings from 'shared/hooks/use-platform-settings';

// Scss
import './pill-component.scss';

export default function Pill({
  module, className, hideText, showPlus,
}) {
  const { t, i18n } = useTranslation('translations');
  const { settings } = usePlatformSettings();

  const showXP = settings && settings.level;

  let typeClass = 'basic';
  let typeText = 'Basics';

  if (!module) return null;

  const { stage, xp } = module;

  switch (stage) {
    case 0:
      break;
    case 1:
      typeClass = 'extension';
      typeText = 'Extension';
      break;
    case 2:
      typeClass = 'expert';
      typeText = 'Expert';
      break;
    default:
      break;
  }

  return (
        <div className={`pill u-textXsmall font-weight-bold ${typeClass} ${className}`}>
            {!hideText && (
                <div className='mx-1 d-flex flex-row align-items-center h-100'>
                    <SvgIcon
                        Icon={Steps}
                        size='14px'
                        className='my-auto pill-icon'
                        flipx={(i18n.language === 'ar')}
                    />
                    <span className='mx-1'>{t(typeText)}</span>
                </div>
            )}
            {!hideText && xp && showXP && (<div className='pill-divider' />)}
            {xp && showXP && (
                <div className='mx-1 d-flex flex-row align-items-center h-100'>
                    <SvgIcon
                        Icon={Shield}
                        size='14px'
                        className='pill-icon'
                    />
                    <span className='mx-1'>{(showPlus ? '+' : '') + xp}</span>
                    <span>{t('XP')}</span>
                </div>
            )}
        </div>
  );
}

Pill.propTypes = {
  module: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  hideText: PropTypes.bool,
  showPlus: PropTypes.bool,
};

Pill.defaultProps = {
  hideText: false,
  showPlus: false,
  className: '',
};
