// Packages
import PropTypes from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getDocumentUrl } from 'shared/helpers/get';
import { DOCUMENTS } from 'shared/utilities/documents';
import useMetaLinks from 'shared/hooks/use-meta-links';

export default function OptInLabel({ registerData, registerType, termsVersion }) {
  const { t, i18n } = useTranslation('translations');
  const { privacy } = useMetaLinks();

  let docTermsOfUse;
  if (registerData && registerData.elearning_anonym) {
    if (registerType === 'CODE') {
      docTermsOfUse = DOCUMENTS.TermsOfUseAnonymeCode;
    } else {
      docTermsOfUse = DOCUMENTS.TermsOfUseAnonyme;
    }
  } else {
    docTermsOfUse = DOCUMENTS.TermsOfUse;
  }

  return (
        <React.Fragment>
            <p className={`${termsVersion === 0 ? 'mb-0' : 'mb-3'} u-textMedium`}>
                {termsVersion === 0 ? (
                    <Trans i18nKey='optInTranslations:checkLabel'>
                        I confirm the processing of my data and that I have read
                        <a
                            aria-label={t('Terms of use')}
                            target='_blank'
                            rel='noopener noreferrer'
                            href={getDocumentUrl(docTermsOfUse, true, i18n.language)}
                        >
                            the Terms of use
                        </a>
                        <br />
                        and
                        <a
                            aria-label={t('Data privacy declaration')}
                            target='_blank'
                            rel='noopener noreferrer'
                            href={privacy}
                        >
                            privacy policy
                        </a>
                        .
                    </Trans>
                ) : (
                    <Trans i18nKey='optInTranslations:checkLabelNewDpa'>
                        I have taken note of the
                        <a
                            aria-label={t('Data privacy declaration')}
                            target='_blank'
                            rel='noopener noreferrer'
                            href={privacy}
                        >
                            privacy policy
                        </a>
                        .
                    </Trans>
                )}
            </p>

        </React.Fragment>
  );
}

OptInLabel.propTypes = {
  registerData: PropTypes.shape({
    elearning_anonym: PropTypes.bool,
  }).isRequired,
  registerType: PropTypes.string.isRequired,
  termsVersion: PropTypes.number.isRequired,
};
