export const DEMO_CUSTOMER_ID = 27;
export const VERSION = '1.0.0';

export const PREVIEW_BRANDING_LOCALSTORAGE_KEY = 'prev_branding';
export const DEV_LOCALSTORAGE_KEY = 'dev_data';

export const SESSION_STORAGE_KEY = 'REDIRECT_PARAM';
export const SESSION_STORAGE_KEY_SSO = 'REDIRECT_PARAM_SSO';
export const SESSION_STORAGE_KEY_MODULE = 'REDIRECT_PARAM_MODULE';
export type TSessionStorageKeyModule = {
  categoryKey: string;
  moduleKey: string;
};

// keyevent.which
export const KEYS = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  ALT: 18,
  ESCAPE: 27,
  SPACE: 32,
  END: 35,
  HOME: 36,
  ARROWLEFT: 37,
  ARROWUP: 38,
  ARROWRIGHT: 39,
  ARROWDOWN: 40,
};

export const enum RoutingOptions {
  LOGGED = 'logged',
  FLAMINGO = 'flamingo',
  AUTH = 'authentication',
}

export enum ICON_COLOR {
  DANGER = 'var(--mantine-color-red-filled)',
}
