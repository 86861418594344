
// Packages
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Card } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UseRequestActivateAccount } from 'authentication/hooks/use-request-activate-account';

import ActionTypes from 'shared/utilities/action-types';
import { SESSION_STORAGE_KEY_MODULE } from 'shared/utilities/constants';

// Components
import AuthImage from 'authentication/components/auth-image';
import Helmet from 'shared/components/helmet';
import AuthHeader from 'authentication/components/auth-header';
import SignInForm from 'authentication/components/signin-form';
import SsoForm from 'authentication/components/sso-form';
import useMetaLinks from 'shared/hooks/use-meta-links';

// Scss
import 'scss/base.scss';

export default function LoginPage(props) {
  const { t, i18n } = useTranslation(['translations', 'helmetTranslations']);
  const { activationSuccess } = useSelector(
    (state) => state.auth,
    shallowEqual
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { support, privacy } = useMetaLinks();

  UseRequestActivateAccount();

  useEffect(() => {
    const isModuleKeySessionStorageLogout = sessionStorage.getItem(
      SESSION_STORAGE_KEY_MODULE
    );

    if (isModuleKeySessionStorageLogout === 'logout') {
      /**
       * Item must be removed to clear the session storage to allow for it to be used again.
       * Timeout is required since the pages are rendered prematurely and pages rendered multiple times,
       * leading to the session item to be removed before the checkRedirect function is called.
       *
       * This change should be considered a temporary fix until authentication and routes can be refactored
       */
      setTimeout(() => {
        sessionStorage.removeItem(SESSION_STORAGE_KEY_MODULE);
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (activationSuccess) {
      dispatch({ type: ActionTypes.CLEAN_UP_ACTIVATION_CODE });
      history.push('/');
    }
  }, [activationSuccess]);

  return (
    <React.Fragment>
      <Helmet title={t('helmetTranslations:Login')} />
      <AuthHeader />
      <main className="m-auto auth-card-width">
        <div>
          <Card shadow="sm" className="auth-card">
            <div className="d-flex flex-row">
              <AuthImage flipx={i18n.language === 'ar'} />
              <div className="base-card-right flex-grow-1">
                <h2 className="mb-2 h1 text-center register-login-title-weight">
                  {t('Sign in now')}
                </h2>
                <hr />
                <SignInForm
                  doSignIn={props.doSignIn}
                  data={props.location.state}
                />
                <SsoForm type="LOGIN" doSignIn={props.doSignIn} />
                <p className="mb-3 text-center">
                  {`${t("Don't have an account?")} `}
                  <Link className="text-nowrap" to="/register">
                    {t('Go to registration')}.
                  </Link>
                </p>
                {privacy && (
                  <p className="mb-3 text-center cookies-policy">
                    {`${t('cookies_information')} `}
                    <a
                      href={privacy}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-nowrap cookies-policy"
                    >
                      {t('cookies_link')}.
                    </a>
                  </p>
                )}
                <p className="text-center">
                  <Link to="/password">{t('Forgot your password?')}</Link>
                </p>

                {support && (
                  <React.Fragment>
                    <hr />
                    <p className="text-center mb-1">
                      <a
                        href={support}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('Go to support')}
                      </a>
                    </p>
                  </React.Fragment>
                )}
              </div>
            </div>
          </Card>
        </div>
      </main>
    </React.Fragment>
  );
}

LoginPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.shape({}),
  }).isRequired,
  doSignIn: PropTypes.func.isRequired,
  doSignUp: PropTypes.func.isRequired,
};