
// Packages
import Form from 'react-bootstrap/Form';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// Hooks
import useForm from 'shared/hooks/use-form';
import useUser from 'shared/hooks/use-user';

// Utilities
import { getNameAndDerivativeNameFromCode, parseCodeLanguageToLanguageType, sortLanguages, parseCodeLanguageToISO6391 } from 'shared/utilities/languages';
import { getIdByReference } from 'shared/utilities/reference-handling';

// Components
import PasswordControl from 'shared/components/password-control';
import Validators from 'shared/utilities/validators';
import PageContentHeader from 'shared/components/page-content-header';
import CustomButton from 'shared/components/custom-button';
import Helmet from 'shared/components/helmet';

// Scss
import './account-page.scss';
import { useAccount } from 'account/hooks/use-account';
import { useSoSafeAnalytics } from 'shared/hooks/use-sosafe-analytics';
import { ANALYTICS_TYPE } from 'shared/modules/sosafe-connect';

export default function AccountPage() {
  const { t, i18n } = useTranslation([
    'translations',
    'languagesTranslations',
    'helmetTranslations',
    'placeholderTranslations',
  ]);
  const { save, changePassword } = useAccount();

  const { user, reference, loading } = useUser({});

  const customerLanguage = useMemo(
    () => {
      if (loading) return [];
      const languagesCode = user?.customer?.languages.languages ?? [];
      const languages = parseCodeLanguageToLanguageType(languagesCode);
      return sortLanguages(languages);
    },
    [loading, user?.customer?.languages.languages],
  );

  const { user: userData, setUser } = useUserForm(user, reference);
  const { passwordData, setPasswordData } = usePassword(reference);
  const { sendSoSafeAnalytics } = useSoSafeAnalytics();

  const { password1, password2, oldpassword } = passwordData;

  useEffect(() => {
    sendSoSafeAnalytics({ type: ANALYTICS_TYPE.PAGE_VIEWED });
  }, []);

  useEffect(() => {
    if (!loading && i18n.language !== user?.language) {
      i18n.changeLanguage(user.language);
      window.document.documentElement.lang = user.language;
    }
  }, [i18n, user, loading]);

  const LangOptions = useCallback(() => {
    if (!customerLanguage.length) {
      return (
        <option
          key='en'
          value='en'
        >
          {t('languagesTranslations:en')}
        </option>
      );
    }

    return (
      <React.Fragment>
        {customerLanguage.map(({ key, value }) => (
          <option
            key={key}
            value={key}
          >
            {value}
          </option>
        ))}
      </React.Fragment>
    );
  }, [customerLanguage.length]);

  const {
    gender, email, firstname, lastname, elearning_code
  } = userData;
  const language = parseCodeLanguageToISO6391(userData.language)

  const showPassword = !userData.use_sso;

  const savePassword = () => {
    const data = {
      newpassword: password1,
      oldpassword,
    };
    changePassword(data);
  };

  const saveUser = () => {
    const data = {
      firstname: userData.firstname,
      lastname: userData.lastname,
      language: userData.language,
      email: userData.email,
      gender: userData.gender === '' ? null : parseInt(userData.gender, 10),
    };
    save(data);
  };

  const dataHasChanged = user.language !== language
    || user.gender !== (gender === '' ? null : gender)
    || user.email !== email
    || user.firstname !== firstname
    || user.lastname !== lastname;

  return (
    <React.Fragment>
      <Helmet title={t('helmetTranslations:Account management')} />
      <div className='AccountPage'>
        <PageContentHeader
          title={t('Personal information')}
          subtitle={t('You can view and edit your account settings here.')}
        />
        <div className='row mb-4'>
          <div className='col-12 col-md-8 mx-auto'>
            <Card className='shadow p-3'>
              <h4 className='mb-2 h3'>{t('My Data')}</h4>
              <Form.Group>
                <Form.Label
                  className='text-muted'
                  htmlFor='gender'
                >
                  {t('Salutation')}
                </Form.Label>
                <Form.Control
                  value={gender === null ? '' : gender}
                  as='select'
                  className='custom-select'
                  id='gender'
                  name='gender'
                  onChange={(e) => setUser({ gender: e.target.value })}
                  autoComplete="honorific-prefix"
                >
                  <option value=''>{t('------')}</option>
                  <option value={0}>{t('Mr.')}</option>
                  <option value={1}>{t('Mrs./Ms.')}</option>
                  <option value={2}>{t('Divers')}</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label
                  className='text-muted'
                  htmlFor='firstname'
                >
                  {t('Firstname')}
                </Form.Label>
                <Form.Control
                  type='text'
                  id='firstname'
                  className=''
                  placeholder={t('placeholderTranslations:Firstname')}
                  value={firstname ?? ''}
                  onChange={(e) => setUser({ firstname: e.target.value })}
                  disabled={!elearning_code}
                  autoComplete='given-name'
                />
              </Form.Group>
              <Form.Group>
                <Form.Label
                  className='text-muted'
                  htmlFor='lastname'
                >
                  {t('Lastname')}
                </Form.Label>
                <Form.Control
                  type='text'
                  id='lastname'
                  className=''
                  placeholder={t('placeholderTranslations:Lastname')}
                  value={lastname ?? ''}
                  onChange={(e) => setUser({ lastname: e.target.value })}
                  disabled={!elearning_code}
                  autoComplete="family-name"
                />
              </Form.Group>

              {email && (
                <Form.Group>
                  <Form.Label
                    className='text-muted'
                    htmlFor='email'
                  >
                    {t('Email')}
                  </Form.Label>
                  <Form.Control
                    type='text'
                    id='email'
                    className=''
                    placeholder={t('placeholderTranslations:Email')}
                    value={email}
                    onChange={(e) => setUser({ email: e.target.value })}
                    disabled
                    autoComplete="email"
                  />
                </Form.Group>
              )}
              <Form.Group>
                <Form.Label
                  className='text-muted'
                  htmlFor='language'
                >
                  {t('Language')}
                </Form.Label>
                <Form.Control
                  as='select'
                  defaultValue={language}
                  className='custom-select'
                  id='language'
                  name='language'
                  onChange={(e) => {
                    const { languageName, derivativeLanguage } = getNameAndDerivativeNameFromCode(e.target.value);
                    setUser({ language: derivativeLanguage ?? languageName });
                  }}
                  autoComplete='language'
                >
                  <LangOptions />
                </Form.Control>
                {user?.game?.certificate && (
                  <span className='certificate-warning-text'>
                    {t('user_profile_certificate_warning')}
                  </span>
                )}
              </Form.Group>
              <div className='d-flex d-flex justify-content-end'>
                <CustomButton
                  disabled={loading || !dataHasChanged}
                  onClick={saveUser}
                  label={loading ? t('Saving...') : t('Save')}
                />
              </div>
            </Card>
          </div>
          {email && showPassword && (
            <div className='col-12 col-md-8 mx-auto mt-4'>
              <Card className='shadow p-3'>
                <h4 className='mb-2 h3'>{t('Password')}</h4>
                <PasswordControl
                  formLabel={t('Old Password')}
                  key='oldpassword'
                  name='oldpassword'
                  value={oldpassword}
                  onChange={(e) => setPasswordData({ oldpassword: e.target.value })}
                  hideIndicator
                />
                <PasswordControl
                  key='password1'
                  name='password1'
                  value={password1}
                  onChange={(e) => setPasswordData({ password1: e.target.value })}
                  secondPassword={password2}
                  newPassword
                />
                <PasswordControl
                  key='password2'
                  hideIndicator
                  name='password2'
                  formLabel={t('Repeat Password')}
                  value={password2}
                  onChange={(e) => setPasswordData({ password2: e.target.value })}
                  firstPassword={password1}
                  newPassword
                />
                <div className='d-flex justify-content-end'>
                  <CustomButton
                    disabled={!isPasswordValid(oldpassword, password1, password2) || loading}
                    onClick={savePassword}
                    label={loading ? t('Saving...') : t('Change password')}
                  />
                </div>
              </Card>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

function isPasswordValid(oldpassword, newPassword1, newPassword2) {
  const validations = [
    Validators.required(oldpassword).valid,
    Validators.required(newPassword1).valid,
    Validators.required(newPassword2).valid,
    Validators.password(newPassword1).valid,
    Validators.matches(newPassword1, newPassword2).valid,
  ];
  return validations.every((item) => item);
}

/*
 * Handles user data. Changes data on CHANGE_ACCOUNT_SUCCESS or on setUser
 *
 * @param {*} propUser
 * @param {*} reference
 * @returns user object
 */
function useUserForm(propUser, reference) {
  const [user, setUser] = useForm(propUser);
  const [prevReference, setPrevReference] = useState(reference);

  useEffect(() => {
    if (prevReference !== reference) {
      if (getIdByReference(reference) === 'CHANGE_ACCOUNT_SUCCESS') {
        setUser({
          isEdit: false,
          ...propUser,
        });
      }
      setPrevReference(reference);
    }
  }, [setPrevReference, propUser, reference, prevReference]);

  return {
    user,
    setUser: (data) => setUser({
      ...user,
      ...data,
    }),
  };
}

/*
 * Handles passwords (old, new1 and new2)
 *
 * @param {*} propUser
 * @param {*} reference
 * @returns passwordData object
 */
function usePassword(reference) {
  const [passwordData, setPasswordData] = useForm({
    password1: '',
    password2: '',
    oldpassword: '',
  });

  const [prevReference, setPrevReference] = useState(reference);

  useEffect(() => {
    if (prevReference !== reference) {
      if (getIdByReference(reference) === 'CHANGE_PASSWORD_SUCCESS') {
        setPasswordData({
          password1: '',
          password2: '',
          oldpassword: '',
        });
      }
      setPrevReference(reference);
    }
  }, [setPrevReference, reference, setPasswordData, prevReference]);

  return {
    passwordData,
    setPasswordData: (data) => setPasswordData({
      ...passwordData,
      ...data,
    }),
  };
}
