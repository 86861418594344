
// Packages
import React, { useState, useEffect } from 'react';
import i18n from 'lang/i18n';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Form } from 'react-bootstrap';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import useMetaLinks from 'shared/hooks/use-meta-links';
import { Trans, useTranslation } from 'react-i18next';

// Services
import { closeOptIn } from 'shared/services/onboarding.service';

// Utilities
import { DOCUMENTS } from 'shared/utilities/documents';
import { getDocumentUrl } from 'shared/helpers/get';
import { isReferenceIdMatch } from 'shared/utilities/reference-handling';
import ActionTypes from 'shared/utilities/action-types';

// Components
import CustomButton from 'shared/components/custom-button';

// Scss
import './optin-modal.scss';
import { useSoSafeConnect } from 'shared/modules/sosafe-connect';

export default function OptinModal() {
  const { optin: isOptin } = useSelector((state) => state.optin, shallowEqual);
  const { t } = useTranslation(['translations', 'optInTranslations', 'messageTranslations']);
  const { privacy } = useMetaLinks();
  const { endPoints } = useSoSafeConnect();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [optinChecked, setOptinChecked] = useState(false);

  const dispatch = useDispatch();

  const [currState, setCurrState] = useState('INIT');

  useEffect(() => {
    if (isOptin) {
      setTimeout(() => {
        setShow(true);
      }, 100);
    } else {
      setShow(false);
    }
  }, [isOptin]);

  const sendOptInRequest = () => {
    setLoading(true);

    endPoints.post.optIn({ opt_in: optinChecked })
      .then((response) => {
        setLoading(false);
        setCurrState('SUCCESS');
        const { data } = response;

        if (isReferenceIdMatch(data.reference, 'OPTIN_SEND_SUCCESS')) {
          dispatch({ payload: { opt_in: optinChecked }, type: ActionTypes.OPTIN_SEND_SUCCESS });
        }
      })
      .catch(() => {
        setLoading(false);
        setCurrState('FAILED');
        return { isError: true };
      });
  };

  const arab = `${i18n.language === 'ar' ? 'arab' : ''}`;

  if (isOptin) {
    return (
            <div
                className={`w-100 h-100 position-fixed optin-overlay ${arab}`}
                role='dialog'
            >
                <SwitchTransition>
                    <CSSTransition
                        key={currState}
                        addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
                        classNames='fade'
                    >
                        <React.Fragment>
                            {currState === 'INIT' && (
                                <div className={`card shadow optin-tooltip ${show ? 'show' : 'hide'}`}>
                                    <div className='d-flex flex-row'>
                                        <h3 className='mb-2 h2'>{t('optInTranslations:title')}</h3>
                                    </div>

                                    <div className='d-flex flex-column'>
                                        <p className='mb-1'>{t('optInTranslations:message_1')}</p>
                                        <ul className='pl-3 border-0 mb-1'>
                                            <li className='u-textMedium my-2'>{t('optInTranslations:bullet_1')}</li>
                                            <li className='u-textMedium my-2'>{t('optInTranslations:bullet_2')}</li>
                                            <li className='u-textMedium my-2'>{t('optInTranslations:bullet_3')}</li>
                                        </ul>
                                        <p>{t('optInTranslations:message_2')}</p>

                                        <span className='mx-auto'>
                                            <Form.Group style={{ marginLeft: 10 }}>
                                                <Form.Check
                                                    type='checkbox'
                                                    label={(
                                                        <span className='u-textMedium optInLabel'>
                                                            <Trans i18nKey='optInTranslations:checkLabel'>
                                                                I confirm the processing of my data and that I have read
                                                                <a
                                                                    aria-label={t('Terms of use')}
                                                                    target='_blank'
                                                                    rel='noopener noreferrer'
                                                                    href={getDocumentUrl(DOCUMENTS.TermsOfUse, true, i18n.language)}
                                                                >
                                                                    the Terms of use
                                                                </a>
                                                                and
                                                                <a
                                                                    aria-label={t('Data privacy declaration')}
                                                                    target='_blank'
                                                                    rel='noopener noreferrer'
                                                                    href={privacy}
                                                                >
                                                                    privacy policy
                                                                </a>
                                                                .
                                                            </Trans>
                                                        </span>
                                                    )}
                                                    checked={optinChecked}
                                                    onChange={() => setOptinChecked(!optinChecked)}
                                                    name='optin'
                                                    id='optin'
                                                />
                                            </Form.Group>
                                        </span>
                                    </div>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <CustomButton
                                            label={loading ? t('Loading ...') : t('Save')}
                                            className='ml-auto py-1'
                                            onClick={sendOptInRequest}
                                            focus
                                            disabled={loading}
                                        />
                                    </div>
                                </div>
                            )}
                            {currState === 'SUCCESS' && (
                                <div className={`card shadow optin-tooltip ${show ? 'show' : 'hide'}`}>
                                    <div className='d-flex flex-row'>
                                        <h3 className='mb-2 h2'>{t('Thank you!')}</h3>
                                    </div>

                                    <div className='d-flex flex-column'>
                                        <p>{t('Your details have been saved.')}</p>
                                    </div>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <CustomButton
                                            label={t('Close')}
                                            className='ml-auto py-1'
                                            onClick={() => dispatch(closeOptIn())}
                                            focus
                                            disabled={loading}
                                        />
                                    </div>
                                </div>
                            )}
                            {currState === 'FAILED' && (
                                <div className={`card shadow optin-tooltip ${show ? 'show' : 'hide'}`}>
                                    <div className='d-flex flex-row'>
                                        <h3 className='mb-2 h2'>{t('Error')}</h3>
                                    </div>

                                    <div className='d-flex flex-column'>
                                        <p>{t('messageTranslations:An error has occured')}</p>
                                    </div>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <CustomButton
                                            label={t('Close')}
                                            className='ml-auto py-1'
                                            onClick={() => window.location.reload()}
                                            focus
                                            disabled={loading}
                                        />
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    </CSSTransition>
                </SwitchTransition>
            </div>
    );
  }
  return null;
}
