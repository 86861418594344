
export const DOCUMENTS = {
  InstructionsO365: 0,
  InstructionsGsuite: 1,
  InstructionsPabO365: 2,
  InstructionsPabExchange: 3,
  DpaContract: 4,
  GeneralTermsAndConditions: 5,
  SeflserviceCommunicationGuide: 6,
  SelfserviceProcessGuide: 7,
  TermsOfUse: 8,
  TermsOfUseAnonyme: 9,
  // DataPrivacyDeclaration: 10,
  TermsOfUseAnonymeCode: 12,
};
