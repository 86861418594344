
import React from 'react';

export default function useMobileCheck() {
  const [width, setWidth] = React.useState(window?.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [window]);

  const isMobile = width && width < 768;

  return {
    isMobile,
  };
}
