import ActionTypes from 'shared/utilities/action-types';

const INITIAL_STATE = { rewards: [] };

class RewardReducer {
  static reduce(state = INITIAL_STATE, action) {
    const { newRewards, type } = action || {};
    if (type === ActionTypes.CLEAR_STORE) {
      return INITIAL_STATE;
    }
    if (!newRewards) return state;
    if (type !== ActionTypes.REWARD_SUCCESS) return state;


    return {
      ...state,
      rewards: newRewards,
    };
  }
}

export default RewardReducer.reduce;
