import React from 'react';
import PropTypes from 'prop-types';
import InfoBubble from 'shared/components/info-bubble';

export default function AchievementCardHeader(props) {
  const {
    className,
    title,
    titleJsx,
    info,
  } = props;

  return (
        <div className={`d-flex flex-row justify-content-between mb-3 ${className}`}>
            {title && <h4 className='h3'>{title}</h4>}
            {titleJsx}
            <InfoBubble
                size='small'
                description={info}
            />
        </div>
  );
}

AchievementCardHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  info: PropTypes.string,
  titleJsx: PropTypes.element,
};

AchievementCardHeader.defaultProps = {
  className: '',
  title: '',
  info: '',
  titleJsx: null,
};
