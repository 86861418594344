
import { combineReducers } from 'redux';

import { AppState, AuthReducerProps, ReducersProps } from 'types';
import AuthReducer from './auth-reducer';
import DebugReducer from './debug-reducer';
import GameReducer from './game-reducer';
import HintsReducer from './hints-reducer';
import ModuleFeedbackReducer from './module-feedback-reducer';
import ModulesReducer from './modules-reducer';
import OptinReducer from './optin-reducer';
import RewardReducer from './reward-reducer';
import SettingsReducer from './settings-reducer';
import TourReducer from './tour-reducer';
import UserPreferencesReducer from './user-preferences-reducer';
import WelcomeCardReducer from './welcome-card-reducer';
import WelcomeReducer from './welcome-reducer';

const rootReducer = combineReducers<ReducersProps>({
  auth: AuthReducer as AuthReducerProps, // infer type until the AuthReducer is converted to typescript
  debug: DebugReducer,
  game: GameReducer,
  hints: HintsReducer,
  moduleFeedback: ModuleFeedbackReducer,
  modules: ModulesReducer,
  rewards: RewardReducer,
  settings: SettingsReducer,
  tour: TourReducer,
  welcomeCard: WelcomeCardReducer,
  optin: OptinReducer,
  welcome: WelcomeReducer,
  userPreferences: UserPreferencesReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer> & AppState;
