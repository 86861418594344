import { getSSORedirect, removeSSORedirect } from 'authentication/helpers';
import { getDataFromSSOProfile } from 'authentication/helpers/get';
import useMsSso from 'authentication/hooks/use-ms-sso';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useUser from 'shared/hooks/use-user';
import { useSoSafeConnect } from 'shared/modules/sosafe-connect';
import ActionTypes from 'shared/utilities/action-types';
import { ALERT_TYPE } from 'shared/utilities/modal-alert';
import { getIdByReference } from 'shared/utilities/reference-handling';
import { SESSION_STORAGE_KEY_MODULE } from 'shared/utilities/constants';
import '../../scss/buttons.scss';
import { useModalContext } from 'modal-context/modal-context.tsx';
import {
  Button,
  Text,
  Title,
} from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import { UseLoginRequest } from './use-login-request';
import { useLogoutRequest } from './use-logout-request';
import useSAML from './use-saml';
import { useSendRegisterAccountRequest } from './use-send-register-request';
import { UseShowDecisionAlert } from '../../flamingo-e-learning-platform/utilities/modal-alert/use-show-decision-alert';

const useEffectOnlyOnce = (func) => useEffect(func, []);

export default function useAuthentication() {
  const history = useHistory();
  const { t, i18n } = useTranslation(['translations', 'messageTranslations']);
  const { login } = UseLoginRequest();
  const { logout: logoutRequest } = useLogoutRequest();
  const { apiKey } = useSoSafeConnect();
  const { mutate: registerUser } = useSendRegisterAccountRequest();
  const { close } = useModalContext();
  const { show } = UseShowDecisionAlert();

  const logout = () => {
    /**
     * This is a fix for an issue where if users logout from a modules page,
     * they will get redirected to that same module page after logging back in.
     * See checkRedirect function in src/shared/helpers/redirect/check-redirect.js
     */
    sessionStorage.setItem(SESSION_STORAGE_KEY_MODULE, 'logout');
    logoutRequest();
  };

  const { user, reference, register_data, redirectToRegister } = useSelector(
    (state) => state.auth,
    shallowEqual,
  );

  const { refreshUser } = useUser({ enabled: false });

  const { signIn: doSignInMsSso } = useMsSso();

  const { samlLoading, signIn: doSamlLogin } = useSAML();

  const dispatch = useDispatch();

  // Check if user area 1 to prevent localhost changes
  if (user && user.area !== 1) {
    dispatch({ type: ActionTypes.AUTH_LOGOUT_SUCCESS });
  }

  const signIn = (type, data) => {
    switch (type) {
      case 'EMAIL':
      case 'CODE':
        login(data);
        break;
      case 'MICROSOFT':
        doSignInMsSso('LOGIN');
        break;
      case 'SAML':
        doSamlLogin(data.domain);
        break;
      default:
        break;
    }
  };

  const signUp = (type, data) => {
    switch (type) {
      case 'EMAIL':
      case 'CODE':
        login(data);
        break;
      case 'MICROSOFT':
        doSignInMsSso('REGISTER');
        break;
      case 'SAML':
        doSamlLogin(data.domain);
        break;
      default:
        break;
    }
  };

  const showLogoutModal = (logoutMethod) => {
    show({
      title: <Title size="h2">{t('Do you really want to sign out?')}</Title>,
      content: <Text>{t('Come back soon!')}</Text>,
      footer: (
        <>
          <Button
            variant="secondary"
            aria-label={t('Cancel')}
            onClick={() => close()}
          >
            {t('Cancel')}
          </Button>
          <Button
            variant="primary"
            aria-label={t('Logout')}
            onClick={() => logoutMethod()}
          >
            {t('Logout')}
          </Button>
        </>
      ),
      type: ALERT_TYPE.QUESTION,
    });
  };

  const isSignInProgress = sessionStorage.getItem('MSAL_STATE') === 'SIGNIN';
  const isSignOutProgress =
    sessionStorage.getItem('MSAL_STATE') === 'SIGNOUT' ||
    history.location.pathname.includes('logout');

  useEffectOnlyOnce(() => {
    if (apiKey && user && !isSignInProgress && !isSignOutProgress) {
      refreshUser();
    }

    const ssoRedirect = getSSORedirect();
    if (ssoRedirect) {
      removeSSORedirect();
      if (ssoRedirect.includes('MICROSOFT')) {
        setTimeout(() => {
          if (ssoRedirect.includes('LOGIN')) {
            signIn('MICROSOFT');
          } else {
            signUp('MICROSOFT');
          }
        }, 500);
      } else if (ssoRedirect.includes('GOOGLE')) {
        setTimeout(() => {
          if (ssoRedirect.includes('LOGIN')) {
            signIn('GOOGLE');
          } else {
            signUp('GOOGLE');
          }
        }, 500);
      }
    }
    if (isSignOutProgress) {
      logout();
    }
  });

  useEffect(() => {
    const handleClose = () => {
      close();
      window.location.href = `${window.elearningRuntimeConfig.ELEARNING_HOSTS}/`;
    };
    switch (getIdByReference(reference)) {
      case 'AUTH_TEMPORARY_INVALID':
      case 'AUTH_ROLE_ACCESS':
      case 'AUTH_CUSTOMER_ACCESS':
      case 'API_TOKEN_MISSING':
      case 'API_TOKEN_INVALID':
      case 'API_TOKEN_INVALID_2':
      case 'ELEARNING_NOT_ACTIVE':
        if (apiKey) {
          setTimeout(() => {
            logout();
          }, 500);
        }
        break;
      case 'REGISTRATION_ADDRESS_NOT_FOUND': {
        show({
          title: <Title size="h2">{t('Error')}</Title>,
          content: (
            <>
              <Text>
                {t('messageTranslations:This address is not in our database.')}
              </Text>
              <Text>
                {t(
                  'messageTranslations:Please contact the person in charge to integrate your address into the system.'
                )}
              </Text>
            </>
          ),
          footer: (
            <>
              <Button
                variant="primary"
                aria-label={t('Close')}
                onClick={() => handleClose()}
              >
                {t('Close')}
              </Button>
            </>
          ),
          type: ALERT_TYPE.ERROR,
        });
        break;
      }
      // Remove activation and itwasntme token on failure
      case 'VALIDATION_FAILED':
      case 'SEND_ACTIVATION_SUCCESS':
        history.push('/');
        break;
      case 'ITWASNTME_FAILED':
      case 'ITWASNTME_BAD_REQUEST':
        break;

      case 'REGISTRATION_DATA_SUCCESS_EMAIL': {
        // Same as sso
        if (
          history.location.pathname !== '/register' &&
          history.location.pathname !== '/registration' &&
          history.location.pathname !== '/register/conf'
        ) {
          if (register_data) {
            if (register_data.sso) {
              if (register_data.skip_optin) {
                const data = getDataFromSSOProfile(register_data, i18n);
                const registerUserData = {
                  ...register_data,
                  ...data,
                };
                registerUser(registerUserData);
              } else if (redirectToRegister) {
                history.push('/register/conf');
              }
            }
          }
        }
        break;
      }
      default:
        break;
    }
  }, [reference]);

  return {
    signOut: () => showLogoutModal(logout),
    // TODO: Add type to signIn and signUp from src/authentication/types/auth-funtion.type.ts
    signIn: (type, data) => signIn(type, data),
    signUp: (type, data) => signUp(type, data),
    isSignInProgress,
    isSignOutProgress,
    samlLoading,
  };
}

useAuthentication.propTypes = {
  location: PropTypes.shape().isRequired,
};
