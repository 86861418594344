import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

export default function CustomSelectWidget(props) {
  const { t } = useTranslation('translations');
  return (
        <select
            className='form-control'
            defaultValue='CHOOSE'
            onChange={(event) => {
              const selected = event.target.value;
              const found = props.options.enumOptions.find((option) => option.label === selected);
              props.onChange(found.value);
            }}
        >
            <option value='CHOOSE'>{t('Choose...')}</option>
            {
                props.options.enumOptions.map((option, index) => (
                    <option
                        key={index}
                        value={option.label}
                    >{option.label}</option>
                ))
            }
        </select>
  );
}

CustomSelectWidget.propTypes = {
  options: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};
