
/**
 * Get module from categories by id
 *
 * @param {*} categories
 * @param {*} moduleId
 * @returns module
 *
 * @category elearning
 * @subcategory helpers
 * @exports getModuleFromCategories
 */
export default function getModuleFromCategories(categories, moduleId) {
  if (!categories || !categories.length) return null;

  let moduleData = null;
  categories.find((category) => {
    if (category.modules && category.modules.length) {
      const tempModule = category.modules.find((mod) => mod.id === moduleId);
      if (tempModule) {
        moduleData = tempModule;
        return true;
      }
    }
    return false;
  });
  return moduleData;
}
