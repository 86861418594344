
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Helmet as ReactHelmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import GlobalSettingsContext from 'shared/modules/global-settings/state/global-settings-context.provider';

export default function Helmet(props) {
  const { t } = useTranslation('helmetTranslations');
  const { globalSettings } = useContext(GlobalSettingsContext);
  const { tab_title_prefix: titlePrefix, favicon } = globalSettings;
  const { title } = props;
  return (
        <React.Fragment>
            <ReactHelmet>
                <title>
                    {`${title} - ${titlePrefix || t('helmetTranslations:SoSafe E-Learning')}`}
                </title>
                {favicon && (
                    <link
                        rel='icon'
                        type='image/png'
                        href={favicon}
                        sizes='16x16'
                    />
                )}
            </ReactHelmet>
            <h1 className='sosafe-InvisibleHeading position-absolute'>{title}</h1>{/* This heading is only visible for screen readers */}
        </React.Fragment>
  );
}

Helmet.propTypes = {
  title: PropTypes.string.isRequired,
};
