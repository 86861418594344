const isCertificateActivated =
  window.elearningRuntimeConfig.CERTIFICATE_ACTIVE === 'true';
const isAwarenessAssessment =
  window.elearningRuntimeConfig.AWARENESS_ASSESSMENT_ACTIVE === 'true';
const isPartnerFeatureActivated =
  window.elearningRuntimeConfig.PARTNER_FEATURE_ACTIVE === 'true';

// Check if there is a limit for learning path
const learningPathLimitFlag =
  window.elearningRuntimeConfig.LEARNING_PATH_LIMIT_DAYS;
const learningPathLimit =
  Number.isNaN(Number(learningPathLimitFlag)) ||
  !learningPathLimitFlag ||
  typeof learningPathLimitFlag !== 'string'
    ? 0
    : parseInt(learningPathLimitFlag, 10);

// Check if there is a customers ids array for learning path
const learningPathCustomersArrayFlag =
  window.elearningRuntimeConfig.LEARNING_PATH_LIMIT_CUSTOMER_IDS;
const learningPathCustomersArray =
  learningPathCustomersArrayFlag &&
  typeof learningPathCustomersArrayFlag === 'string'
    ? learningPathCustomersArrayFlag
        .split(',')
        .map((str) => parseInt(str.trim(), 10))
    : [];

const isLearningPathLimited =
  window.elearningRuntimeConfig.LEARNING_PATH_LIMITED === 'true';

const limitedDisplayModules = 5;

const isNewIframeEnabledV7 =
  window.elearningRuntimeConfig.ENABLE_NEW_IFRAME_FOR_V7 === 'true';

const isNewIframeEnabledV6 =
  window.elearningRuntimeConfig.ENABLE_NEW_IFRAME_FOR_V6 === 'true';

export default {
  isCertificateActivated,
  isAwarenessAssessment,
  isPartnerFeatureActivated,
  learningPathLimit,
  learningPathCustomersArray,
  isLearningPathLimited,
  limitedDisplayModules,
  isNewIframeEnabledV7,
  isNewIframeEnabledV6,
};
