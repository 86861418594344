
// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import SvgIcon from 'shared/svgs/helper/svg-icon';
import { ErrorShield } from 'shared/svgs';
import Loading from './loading';

// Scss
import './svg-handling-wrapper.scss';

/**
 * Wrapper around svg to handle loading and error on loading.
 * Fades svg after onLoad event. Displays error message on fail.
 * Must have only one child. Child must be 'svg'
 *
 * @category shared
 * @subcategory components
 * @exports SvgHandlingWrapper
 * @component
 */
export default function SvgHandlingWrapper(props) {
  const {
    children, className, propRef, loading: propLoading,
  } = props;

  const { t } = useTranslation('translations');

  const [loading, setLoading] = useState(true);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const [svgElem, setSvgElem] = useState(null);
  const [loadingTimeout, setLoadingTimeout] = useState(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoadingFailed(true);
      setLoading(false);
    }, 12000000);
    setLoadingTimeout(timeout);
    return () => {
      if (loadingTimeout) clearTimeout(loadingTimeout);
    };
  }, []);

  useEffect(() => {
    if (propRef && propRef.current && !svgElem) {
      const svg = propRef.current.querySelector('svg');
      setSvgElem(svg);
      if (svg) {
        svg.addEventListener('load', () => {
          setLoading(false);
          setLoadingFailed(false);
          if (loadingTimeout) clearTimeout(loadingTimeout);
        });
      }
    }
    return () => {
      if (svgElem) {
        svgElem.removeEventListener('load');
      }
    };
  }, [propRef, propLoading]);

  useEffect(() => {
    if (propLoading === false) {
      setTimeout(() => {
        if (loadingTimeout) clearTimeout(loadingTimeout);
        setLoading(false);
      }, 100);
    }
  }, [propLoading]);

  return (
        <React.Fragment>
            {loadingFailed && (
                <div className='w-100 h-100 position-absolute bg-white d-flex z-index-1 svg-handler-error'>
                    <div className='align-self-center mx-auto n-mt-5 d-flex'>
                        <SvgIcon
                            Icon={ErrorShield}
                            size='54px'
                        />
                        <span className='align-self-center ml-2'>{t('Loading failed')}</span>
                    </div>
                </div>
            )}
            {((loading || propLoading) && !loadingFailed) && (
                <div className='w-100 h-100 position-absolute bg-white d-flex z-index-1 svg-handler-loading'>
                    <div className='align-self-center mx-auto n-mt-5'>
                        <Loading size='medium' />
                    </div>
                </div>
            )}
            <div
                className={`position-relative ${className}`}
                ref={propRef}
            >
                <div className={`flex-grow-1 h-100 d-flex flex-column animate-opacity ${(loading && !loadingFailed) ? 'opacity-0' : 'opacity-1'}`}>
                    {children}
                </div>
            </div>
        </React.Fragment>
  );
}

SvgHandlingWrapper.propTypes = {
  propRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

SvgHandlingWrapper.defaultProps = {
  className: '',
  loading: null,
};
