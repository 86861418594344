
// Packages
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { validateOnboarding } from 'shared/services/onboarding.service';
import usePlatformSettings from './use-platform-settings';
import { useTourWithAnalytics } from './use-tour/use-tour-with-analytics';
import useWindowSize from './use-window-size';

// Services
// Hooks
// Utilities
// Components
// Scss

/**
 * Hook to react to state changes and call onboarding functions
 *
 * @export
 * @returns empty Object
 */
export default function useOnboarding() {
  const dispatch = useDispatch();
  const { tourStart } = useTourWithAnalytics();
  const { optin } = useSelector((state) => state.optin, shallowEqual);
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { firstLogin } = useSelector((state) => state.welcome, shallowEqual);

  const debounceTime = 60;
  const { width } = useWindowSize(debounceTime);

  const { settings } = usePlatformSettings();
  const { simulation_opt_in: simulationOptIn } = settings || { };

  useEffect(() => {
    if (user && !user.last_seen) {
      dispatch({ type: 'SET_FIRST_LOGIN', payload: true });
    }
  }, [user?.last_seen]);

  useEffect(() => {
    dispatch(validateOnboarding(width, simulationOptIn, tourStart));
  }, [optin, user, width]);

  if (firstLogin) dispatch(validateOnboarding(width, simulationOptIn, tourStart));

  return {

  };
}
