
import ActionTypes from 'shared/utilities/action-types';

const INITIAL_STATE = {};

class UserPreferencesReducer {
  static reduce(state = INITIAL_STATE, action) {
    switch (action.type) {
      case ActionTypes.SET_PREFERENCES:
        return {
          ...state,
          ...action.data,
        };
      case ActionTypes.REMOVE_PREFERENCES: {
        const newState = state;
        delete newState[action.key];
        return {
          state: newState,
        };
      }
      case ActionTypes.AUTH_LOGIN_FAILED:
      case ActionTypes.AUTH_LOGIN_REQUEST:
      case ActionTypes.AUTH_LOGIN_SUCCESS:
      case ActionTypes.AUTH_LOGOUT_FAILED:
      case ActionTypes.AUTH_LOGOUT_REQUEST:
      case ActionTypes.AUTH_LOGOUT_SUCCESS:
      case ActionTypes.CLEAR_STORE:
        return INITIAL_STATE;

      default:
        return state;
    }
  }
}

export default UserPreferencesReducer.reduce;
