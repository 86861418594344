
// Packages
import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

// Hooks
import useCertificate from 'shared/hooks/use-certificate';
import usePlatformSettings from 'shared/hooks/use-platform-settings';

// Components
import Badge from 'elearning/components/badge';
import SafeScoreIndicator from 'elearning/components/safe-score-indicator';
import AnimatedNumber from 'shared/components/animated-number';
import getCertificateModal from 'shared/components/cert-modal';
import CustomButton from 'shared/components/custom-button';
import InfoBubble from 'shared/components/info-bubble';
import TourStepWrapper from 'shared/components/onboarding/tour-step-wrapper';
import Progressbar from 'shared/components/progress-bar';
import ResponsivePicture from 'shared/components/responsive-picture';
import { ChevronLeft, ChevronRight } from 'shared/svgs';
import SvgIcon from 'shared/svgs/helper/svg-icon';

// Utilities
import { ACHIEVEMENT_PAGE_OPENED_FROM, BADGE_VIEWS, getNextModuleRelease } from 'elearning/helpers';
import { dateToDDMMYYYY } from 'shared/helpers';
import { TOURS } from 'shared/utilities/tour-configs';

// Scss
import { Tooltip } from '@sosafe-platform-engineering/fe-lib-ui-react';
import Loading from 'shared/components/loading';
import { calculateProgress } from 'shared/components/progress-bar/progressbar.utils';
import useUser from 'shared/hooks/use-user';
import './level-card.scss';

function LevelCard(props) {
  const { t, i18n } = useTranslation('translations');
  const history = useHistory();
  const { user, loading } = useUser({ enabled: false });
  const {
    enabled: certificateEnabled,
    loading: loadingCertificate,
    mandatory: mandatoryModuleCount,
  } = useCertificate();
  const { infoNotAllModulesAvailable, releaseDate } = getNextModuleRelease(user);
  const { settings: gamificationSettings } = usePlatformSettings();

  const { className } = props;

  if (loading) {
    return (
      <Card className="level-card shadow hover-shadow overflow-hidden p-3">
        <Loading />
      </Card>
    );
  }

  if (!user?.game?.progress || !gamificationSettings) return null;

  const {
    level: showLevel,
    badges: showBadges,
    achievements: showAchievementPage,
    certificate: showCertificate,
  } = gamificationSettings;

  const { completed, total, xp: userXp, level } = user.game.progress;

  const { image: levelImage, name: levelName, xp_max: nextXp, xp } = level || {};

  const { lastBadges: badges } = user.game.activities || {};

  const { percentageXp, currentLevelXp, maxLevelXp } = calculateProgress({
    minXp: xp,
    maxXp: nextXp,
    currentXp: userXp,
  });

  const LR = `${i18n.language === 'ar' ? 'r' : 'l'}`;
  let levelSection = null;

  const certificateTooltip = () => {
    if (certificateEnabled) return null;
    if (mandatoryModuleCount > 0) {
      return {
        description: t('Complete all mandatory modules first!'),
        position: 'bottom',
      };
    }
    return {
      description: t('Complete at least one module!'),
      position: 'bottom',
    };
  };

  if (showLevel) {
    levelSection = (
      <React.Fragment>
        {showAchievementPage && (
          <div className="d-flex justify-content-end neg-margin-bottom">
            <CustomButton
              className="text-light p-0 font-weight-bold"
              data-testid="level-card-details-button"
              variant="link"
              label={
                <div className="d-flex">
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <small className="d-flex">{t('Details')}</small>
                    <SvgIcon
                      size="9"
                      Icon={i18n.language === 'ar' ? ChevronLeft : ChevronRight}
                      className={`m${LR}-1`}
                    />
                  </div>
                </div>
              }
              onClick={() =>
                history.push({
                  pathname: '/achievements',
                  state: {
                    openedFrom: ACHIEVEMENT_PAGE_OPENED_FROM.START_PAGE.LEVEL_DETAILS,
                  },
                })
              }
              ariaLabel={t('Details')}
            />
          </div>
        )}
        <div className="level-shield flex-shrink-0 mx-auto">
          <ResponsivePicture
            className="h-100 w-100"
            src={levelImage}
            alt={t('level badge')}
            fit="contain"
            srcset={{
              xs: { height: 120 },
              md: { height: 200 },
            }}
          />
        </div>
        <div className="d-flex flex-column flex-grow-1">
          <div className="d-flex align-items-center mb-2 justify-content-between">
            <h3 className="h4">{levelName}</h3>
            <InfoBubble size="small" description={t('category level tooltip')} />
          </div>
          <Tooltip content={t('Required XP points increase exponentially')}>
            <div>
              <Progressbar
                progress={percentageXp}
                animate
                className={`w-100 mb-1 delay ${i18n.language === 'ar' ? 'rotate180' : ''}`}
                delay={0.25}
                maxValue={maxLevelXp}
                aria-labelledby="progress-description"
              />
            </div>
          </Tooltip>
          <span id="progress-description" className="mb-1">
            <AnimatedNumber className="" start={0} end={currentLevelXp} duration={1} delay={0.25} />
            <span>
              {' '}
              / {maxLevelXp} {t('Experience points')}
            </span>
          </span>
          {infoNotAllModulesAvailable && (
            <span className="u-textMedium mt-2">
              {t('footerNextRelease', { x: dateToDDMMYYYY(releaseDate) })}
            </span>
          )}
        </div>
      </React.Fragment>
    );
  } else {
    levelSection = (
      <React.Fragment>
        <div className="d-flex justify-content-between mb-1">
          <h2 className="h4 my-auto">{t('Overview of results')}</h2>
          {showAchievementPage && (
            <CustomButton
              className="text-light p-0 font-weight-bold mt-0 mb-auto flex-grow-0"
              variant="link"
              label={
                <div className="d-flex">
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <small className="d-flex">{t('Details')}</small>
                    <SvgIcon
                      size="9"
                      Icon={i18n.language === 'ar' ? ChevronLeft : ChevronRight}
                      className={`m${LR}-1`}
                    />
                  </div>
                </div>
              }
              onClick={() =>
                history.push({
                  pathname: '/achievements',
                  state: {
                    openedFrom: ACHIEVEMENT_PAGE_OPENED_FROM.START_PAGE.OVERVIEW_OF_RESULTS,
                  },
                })
              }
              ariaLabel={t('Details')}
            />
          )}
        </div>
        <div className="d-flex flex-column align-items-center">
          <div className="level-shield">
            <SafeScoreIndicator percent={total ? Math.ceil(((completed || 0) / total) * 100) : 0} />
          </div>
          <p className="h4 mb-2">{t('Completed modules')}</p>
          <span className="h3">
            <span className="color-primary">{completed || 0}</span>
            <span> / {total || 0}</span>
          </span>
          {infoNotAllModulesAvailable && (
            <span className="u-textMedium mt-2">
              {t('footerNextRelease', { x: dateToDDMMYYYY(releaseDate) })}
            </span>
          )}
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className={className}>
      <Card className="level-card shadow hover-shadow overflow-hidden p-3">
        <div>
          <div className="row">
            <div className="col-12">
              <TourStepWrapper id="main_tour_level" tour={TOURS.MainpageTour}>
                {levelSection}
              </TourStepWrapper>
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-12">
              {showBadges && <hr className="custom-hr" />}
              {showBadges && (
                <TourStepWrapper id="main_tour_bages" tour={TOURS.MainpageTour}>
                  <div className="d-flex justify-content-between mb-3">
                    <h3 className="h4">{t('Badges')}</h3>
                    {showAchievementPage && (
                      <div className="d-flex justify-content-end">
                        <CustomButton
                          className="text-light p-0 font-weight-bold"
                          data-testid="badge-card-details-button"
                          variant="link"
                          label={
                            <div className="d-flex">
                              <div className="d-flex flex-row align-items-center justify-content-between">
                                <small className="d-flex">{t('Details')}</small>
                                <SvgIcon
                                  size="9"
                                  Icon={i18n.language === 'ar' ? ChevronLeft : ChevronRight}
                                  className={`m${LR}-1`}
                                />
                              </div>
                            </div>
                          }
                          onClick={() =>
                            history.push({
                              pathname: '/achievements',
                              state: {
                                openedFrom: ACHIEVEMENT_PAGE_OPENED_FROM.START_PAGE.BADGE_DETAILS,
                              },
                            })
                          }
                          ariaLabel={t('Details')}
                        />
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-row justify-content-around">
                    {badges.map((badge) => (
                      <Badge
                        key={badge.id}
                        badge={badge}
                        fromView={BADGE_VIEWS.START_PAGE.LEVEL_CARD}
                      />
                    ))}
                  </div>
                </TourStepWrapper>
              )}
            </div>
          </div>
        </div>

        {showCertificate && (
          <React.Fragment>
            <hr className="custom-hr" />
            <TourStepWrapper
              id="main_tour_certificate"
              tour={TOURS.MainpageTour}
              className="certificate-wrapper"
            >
              <CustomButton
                className={`my-1 py-1 flex-grow-1 ${
                  !certificateEnabled || loadingCertificate ? 'cursor-default' : ''
                }`}
                label={t('Issue certificate')}
                disabled={!certificateEnabled}
                onClick={() => getCertificateModal(i18n)}
                tooltip={certificateTooltip()}
              />
            </TourStepWrapper>
          </React.Fragment>
        )}
      </Card>
    </div>
  );
}

export default React.memo(LevelCard);
