
// Packages
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import SvgHandlingWrapper from 'shared/components/svg-handling-wrapper';

// Hooks
import useProgressDataPoints from 'gamification/hooks/use-progress-data-points';

// Utilities
import { toFixedFloat, checkIsIE } from 'shared/helpers';

// Scss
import './progress-chart.scss';
import { Tooltip } from '@sosafe-platform-engineering/fe-lib-ui-react';

export default function ProgressChart(props) {
  const {
    activities, className, game, loading,
  } = props;
  const { t } = useTranslation('translations');

  const [ref] = useState(React.createRef());

  // Scales
  const chartHeight = 90;
  const chartWidth = 160;

  const minNumberPoints = 6;

  const { dataPoints } = useProgressDataPoints(activities, game?.progress, minNumberPoints);

  const ttpPosition = (dat) => {
    if (dat.position.relativeX < 0.4) return 'right';
    if (dat.position.relativeX > 0.6) return 'left';
    if (dat.position.relativeY > 0.8) return 'top';
    return 'bottom';
  };

  const RegisteredTooltip = () => (
        <div className='d-flex flex-column max-width-300px'>
            {t('You have registered on the platform')}
        </div>
  );
  const RewardsTooltip = (dat) => (
        <div className='d-flex flex-column max-width-300px'>
            {dat.level && (
                <span>{t('New level reached')}: <strong>{dat.level.name}</strong></span>
            )}
            {!!dat.xp && dat.module && (
                <span>{t('Experience points reached')}: <strong>{dat.xp}</strong></span>
            )}
            {dat.badges && !!dat.badges.length && dat.badges.map((badge) => (badge ? (
                <span
                    key={badge.id}
                    className='text-wrap'
                >{t('Received a badge')}: <strong>{badge.name}</strong></span>
            ) : null))}
        </div>
  );

  const getInnerTooltip = (dat) => {
    if (dat.type === 17) {
      return RegisteredTooltip;
    }
    return () => RewardsTooltip(dat);
  };

  // Points
  const pointSvg = useCallback((dat, idx) => {
    const { relativeX, relativeY } = dat.position;

    const circleDef = dat.level ? '#progresschart-circle-level' : '#progresschart-circle';
    const circleClass = dat.level ? 'fill-secondary stroke-gray-100' : 'fill-primary stroke-gray-100';

    return (
            <Tooltip
                content={getInnerTooltip(dat)()}
                position={ttpPosition(dat)}
                key={idx}
            >
                <g
                    className='cursor-help'
                    transform={`translate(${toFixedFloat(chartWidth * relativeX)} ${toFixedFloat(chartHeight * relativeY)})`}
                >
                    <use
                        href={circleDef}
                        className={circleClass}
                        filter='url(#progresschart-shadow)'
                    />
                    {dat.badges && !!dat.badges.length && (
                        <use
                            x='-3.5'
                            y='-10'
                            className='fill-light stroke-gray-100'
                            href='#progresschart-shield'
                            filter='url(#progresschart-shadow)'
                            height='7'
                            width='7'
                        />
                    )}
                </g>
            </Tooltip>
    );
  }, [chartWidth, chartHeight]);

  const line = () => {
    let path = '';
    if (!dataPoints || !dataPoints.length) return null;
    const firstPoint = dataPoints[0];

    path += `${toFixedFloat(firstPoint.position.relativeX * chartWidth, 2)} ${toFixedFloat(firstPoint.position.relativeY * chartHeight, 2)} `;
    if (dataPoints.length > 1) {
      for (let i = 1; i < dataPoints.length; i++) {
        path += `L ${toFixedFloat(dataPoints[i].position.relativeX * chartWidth, 2)} ${toFixedFloat(dataPoints[i].position.relativeY * chartHeight, 2)} `;
      }
    }
    const points = dataPoints.map((p) => [toFixedFloat(p.position.relativeX * chartWidth, 2), toFixedFloat(p.position.relativeY * chartHeight, 2)]);

    const curve = getBezierCurve(points, chartHeight);
    const linePath = `M ${points[0][0]},${points[0][1]} ${curve}`;
    const fillPath = `M 0,${chartHeight} ${curve} L${points[points.length - 1][0]},${chartHeight} Z`;
    return (
            <g className='progresschart-mainline'>
                <path
                    d={fillPath}
                    strokeWidth='0'
                    opacity='0.1'
                    className='stroke-primary fill-primary progresschart-mainline-fill'
                />
                <path
                    d={linePath}
                    className='stroke-primary progresschart-mainline-line'
                    strokeWidth='1'
                    fill='none'
                />
            </g>
    );
  };

  const pointLine = (point, idx) => {
    if (idx === (dataPoints.length - 1)) return null;
    if (point.position.relativeX >= 0.99) return null;
    if (point.position.relativeX <= 0.01) return null;

    const path = `M ${toFixedFloat(point.position.relativeX * chartWidth)} ${chartHeight} L ${toFixedFloat(point.position.relativeX * chartWidth)} ${toFixedFloat(point.position.relativeY * chartHeight)}`;

    return (
            <path
                key={idx}
                d={path}
                strokeWidth='.4'
                fill='none'
                opacity='.9'
                className='stroke-primary'
            />
    );
  };

  // put together
  const groups = [];
  groups.push(<g key='group-line'>{line()}</g>);
  groups.push(
        <g
            key='group-pointlines'
            className='progresschart-pointlines'
        >
            {dataPoints.map((point, i) => pointLine(point, i))}
        </g>,
  );
  groups.push(
        <g
            key='group-circles'
            className='progresschart-points'
        >
            {dataPoints.map((point, i) => pointSvg(point, i))}
        </g>,
  );

  const cssPoints = dataPoints.length < minNumberPoints ? minNumberPoints : dataPoints.length;

  const svg = (
        <svg
            viewBox={`0 0 ${chartWidth} ${chartHeight}`}
            preserveAspectRatio='xMidYMid meet'
            className={`progresschart ${checkIsIE() ? 'is-ie' : ''}`}
            style={{ '--points': cssPoints }}
        >
            <defs>
                <filter
                    x='-1'
                    y='-1'
                    width='3'
                    height='3'
                    filterUnits='objectBoundingBox'
                    id='progresschart-shadow'
                >
                    <feOffset
                        dx='1'
                        dy='1'
                        in='SourceAlpha'
                        result='progresschart-center-shadowOffset'
                    />
                    <feGaussianBlur
                        stdDeviation='1.5'
                        in='progresschart-center-shadowOffset'
                        result='progresschart-center-shadowBlur'
                    />
                    <feColorMatrix
                        values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0'
                        type='matrix'
                        in='progresschart-center-shadowBlur'
                        result='progresschart-center-shadowColorMatrix'
                    />
                    <feBlend
                        in='SourceGraphic'
                        in2='progresschart-center-shadowColorMatrix'
                        mode='normal'
                    />
                </filter>

                <line
                    x1='0'
                    y1='0'
                    x2={chartWidth}
                    y2='0'
                    id='progresschart-horizontal-line'
                />
                <circle
                    strokeWidth='.5'
                    cx='0'
                    cy='0'
                    r='1.8'
                    id='progresschart-circle'
                />
                <circle
                    strokeWidth='.5'
                    cx='0'
                    cy='0'
                    r='2.1'
                    id='progresschart-circle-level'
                />
            </defs>
            <symbol
                id='progresschart-shield'
                viewBox='0 0 130 160'
            >
                <path
                    strokeWidth='15'
                    d='M65 11L9 36v44c0 14 6 33 24 49 21 19 32 20 32 20s11-1 32-20c17-16 23-35 23-49V36L65 11z'
                />
            </symbol>
            <g className='progresschart-text'>
                <text
                    x='2'
                    y='1'
                    textAnchor='start'
                    fontSize={chartHeight * 0.05}
                    dy={chartHeight * 0.05}
                    className='fill-dark'
                >
                    {t('Experience points')}
                </text>
                <text
                    x={chartWidth - 1}
                    y={chartHeight}
                    textAnchor='end'
                    fontSize={chartHeight * 0.05}
                    dy={chartHeight * 0.055}
                    className='fill-dark'
                >
                    {t('Time')}
                </text>

                <g
                    className='stroke-light progresschart-horizontals'
                    opacity='0.4'
                    strokeWidth='0.3'
                >
                    <use
                        x='0'
                        y={10 + (chartHeight - 10) * 0}
                        href='#progresschart-horizontal-line'
                    />
                    <use
                        x='0'
                        y={10 + (chartHeight - 10) * 0.25}
                        href='#progresschart-horizontal-line'
                    />
                    <use
                        x='0'
                        y={10 + (chartHeight - 10) * 0.5}
                        href='#progresschart-horizontal-line'
                    />
                    <use
                        x='0'
                        y={10 + (chartHeight - 10) * 0.75}
                        href='#progresschart-horizontal-line'
                    />
                </g>
                <polyline
                    id='axis'
                    className='stroke-dark progresschart-graphline'
                    strokeWidth='0.5'
                    opacity='0.8'
                    fill='none'
                    points={`0 0 0 ${chartHeight} ${chartWidth} ${chartHeight} `}
                />

                {groups}

            </g>
        </svg>
  );

  return (
        <React.Fragment>
            <SvgHandlingWrapper
                className={`${className} progresschart-container mb-4`}
                propRef={ref}
                loading={loading}
            >
                {svg}
            </SvgHandlingWrapper>
        </React.Fragment>
  );
}

ProgressChart.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      color: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
};
ProgressChart.defaultProps = {
  activities: null,
  className: '',
};

function getBezierCurve(points, innerChartHeight) {
  const smoothing = 0.00;

  const calcLine = (pointA, pointB) => {
    const lengthX = pointB[0] - pointA[0];
    const lengthY = pointB[1] - pointA[1];
    return {
      length: Math.sqrt((lengthX * lengthX) + lengthY * lengthY),
      angle: Math.atan2(lengthY, lengthX),
    };
  };

  const controlPoint = (smooth) => (current, previous, next, reverse) => {
    const p = previous || current;
    const n = next || current;
    const l = calcLine(p, n);

    const angle = l.angle + (reverse ? Math.PI : 0);
    const length = l.length * smooth;
    const x = current[0] + Math.cos(angle) * length;
    const y = current[1] + Math.sin(angle) * length;
    return [x, y];
  };

  const bezierCommand = (calcControlPoint) => (point, i, a) => {
    const cps = calcControlPoint(a[i - 1], a[i - 2], point);
    const cpe = calcControlPoint(point, a[i - 1], a[i + 1], true);
    return `C ${Math.abs(cps[0])},${Math.abs(cps[1])} ${Math.abs(cpe[0])},${Math.abs(cpe[1])} ${Math.abs(point[0])},${Math.abs(point[1])}`;
  };

  const svgPath = (command) => {
    const parts = points.map((p, idx) => {
      if (idx === 0) return `L ${p[0]},${p[1]} L ${p[0]},${p[1]}`;
      return command(p, idx, points);
    });

    return parts.join(' ');
  };

  const bezierCommandCalc = bezierCommand(controlPoint(smoothing));
  const p = svgPath(bezierCommandCalc, innerChartHeight);

  return p;
}
