
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function LevelProgressDisabled() {
  const { t } = useTranslation('tourTranslations');

  return (
        <svg
            width='313'
            height='179'
            viewBox='0 0 313 179'
        >
            <defs>
                <rect
                    id='prefix__level_card_tour_mobile-b'
                    width='299'
                    height='164.191'
                    x='0'
                    y='0'
                    rx='3.457'
                />
                <filter
                    id='prefix__level_card_tour_mobile-a'
                    width='107.7%'
                    height='114%'
                    x='-3.8%'
                    y='-5.8%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dy='2'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='3.5'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
                    />
                </filter>
                <path
                    id='prefix__level_card_tour_mobile-d'
                    d='M36.02 0L0 16.057v28.247c0 9.142 3.906 21.372 15.24 31.84 13.354 12.346 20.78 13.23 20.78 13.23s7.422-.885 20.787-13.232c11.322-10.467 15.23-22.697 15.23-31.839V16.057L36.02 0z'
                />
                <filter
                    id='prefix__level_card_tour_mobile-c'
                    width='126.4%'
                    height='121.3%'
                    x='-11.8%'
                    y='-9.5%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dx='1'
                        dy='1'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='3'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'
                    />
                </filter>
                <path
                    id='prefix__level_card_tour_mobile-e'
                    d='M0 0h72.036v49.375H0z'
                />
                <path
                    id='prefix__level_card_tour_mobile-g'
                    d='M36.02 0L0 16.057v28.247c0 9.142 3.906 21.372 15.24 31.84 13.354 12.346 20.78 13.23 20.78 13.23s7.422-.885 20.787-13.232c11.322-10.467 15.23-22.697 15.23-31.839V16.057L36.02 0z'
                />
                <text
                    id='prefix__level_card_tour_mobile-j'
                    fill='#000'
                    fontSize='16.25'
                    fontWeight='normal'
                    letterSpacing='-.625'
                >
                    <tspan
                        x='20'
                        y='55.625'
                    >58 %</tspan>
                </text>
                <filter
                    id='prefix__level_card_tour_mobile-i'
                    width='112.5%'
                    height='126.7%'
                    x='-6.2%'
                    y='-6.7%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dy='1'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='.5'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
                    />
                </filter>
            </defs>
            <g
                fill='none'
                fillRule='evenodd'
            >
                <g transform='translate(7 5)'>
                    <use
                        fill='#000'
                        filter='url(#prefix__level_card_tour_mobile-a)'
                        href='#prefix__level_card_tour_mobile-b'
                    />
                    <use
                        fill='#FFF'
                        href='#prefix__level_card_tour_mobile-b'
                    />
                </g>
                <g transform='translate(112 105)'>
                    <rect
                        width='99'
                        height='20'
                        fill='#E9EDEF'
                        rx='4'
                    />
                    <text
                        fill='#8E8E8E'
                        fontSize='10'
                    >
                        <tspan
                            x='6'
                            y='13'
                        >{t('SvgText:LevelProgressMobile1')}</tspan>
                    </text>
                </g>
                <path
                    stroke='#E9EDF0'
                    d='M112.219 96.239h183.202'
                />
                <text
                    fill='#535353'
                    fontSize='8'
                    transform='translate(7 5)'
                >
                    <tspan
                        x='105'
                        y='82'
                    >{t('SvgText:LevelProgressMobile2')}</tspan>
                </text>
                <path
                    className='fill-secondary'
                    fillRule='nonzero'
                    d='M286.124 15.491a9.503 9.503 0 0 0-9.506 9.506 9.503 9.503 0 0 0 9.506 9.506 9.503 9.503 0 0 0 9.506-9.506 9.503 9.503 0 0 0-9.506-9.506zm.95 14.259h-1.9v-5.704h1.9v5.704zm0-7.605h-1.9v-1.9h1.9v1.9z'
                />
                <text
                    fill='#535353'
                    fontSize='15.555'
                    fontWeight='bold'
                    transform='translate(7 5)'
                >
                    <tspan
                        x='10.491'
                        y='24.491'
                    >{t('SvgText:LevelProgressMobile1')}</tspan>
                </text>
                <g transform='translate(24 55)'>
                    <g fillRule='nonzero'>
                        <use
                            fill='#000'
                            filter='url(#prefix__level_card_tour_mobile-c)'
                            href='#prefix__level_card_tour_mobile-d'
                        />
                        <use
                            fill='#FFF'
                            href='#prefix__level_card_tour_mobile-d'
                        />
                    </g>
                    <g transform='translate(0 40.313)'>
                        <mask
                            id='prefix__level_card_tour_mobile-f'
                            fill='#fff'
                        >
                            <use href='#prefix__level_card_tour_mobile-e' />
                        </mask>
                        <path
                            className='fill-primary'
                            fillRule='nonzero'
                            d='M36.02-40L0-23.943V4.304c0 9.142 3.906 21.372 15.24 31.84 13.354 12.346 20.78 13.23 20.78 13.23s7.422-.885 20.787-13.232c11.322-10.467 15.23-22.697 15.23-31.839v-28.247L36.02-40z'
                            mask='url(#prefix__level_card_tour_mobile-f)'
                        />
                        <path
                            fill='#000'
                            fillRule='nonzero'
                            d='M36.02-40.313l-.001 89.376s7.422-.886 20.787-13.232c11.322-10.467 15.23-22.697 15.23-31.84v-28.246L36.02-40.313z'
                            mask='url(#prefix__level_card_tour_mobile-f)'
                            opacity='.1'
                        />
                    </g>
                    <mask
                        id='prefix__level_card_tour_mobile-h'
                        fill='#fff'
                    >
                        <use href='#prefix__level_card_tour_mobile-g' />
                    </mask>
                    <path
                        className='stroke-primary'
                        strokeWidth='3.575'
                        d='M36.02 1.957l34.229 15.26v27.087c0 8.777-3.801 20.491-14.656 30.526-11.456 10.583-18.314 12.46-19.575 12.73-1.261-.27-8.12-2.148-19.565-12.73C5.586 64.795 1.788 53.08 1.788 44.304h0V17.217L36.02 1.957z'
                        mask='url(#prefix__level_card_tour_mobile-h)'
                    />
                    <path
                        stroke='#000'
                        strokeOpacity='.2'
                        strokeWidth='3.575'
                        d='M36.02 1.957l34.229 15.26v27.087c0 8.777-3.801 20.491-14.656 30.526-11.456 10.583-18.314 12.46-19.575 12.73-1.261-.27-8.12-2.148-19.565-12.73C5.586 64.795 1.788 53.08 1.788 44.304h0V17.217L36.02 1.957z'
                        mask='url(#prefix__level_card_tour_mobile-h)'
                    />
                    <g
                        fill='#000'
                        mask='url(#prefix__level_card_tour_mobile-h)'
                    >
                        <use
                            filter='url(#prefix__level_card_tour_mobile-i)'
                            href='#prefix__level_card_tour_mobile-j'
                        />
                        <use href='#prefix__level_card_tour_mobile-j' />
                    </g>
                </g>
            </g>
        </svg>

  );
}
