
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Rank() {
  const { t } = useTranslation('tourTranslations');

  return (
        <svg
            width='313'
            height='130'
            viewBox='0 0 313 130'
            direction='ltr'
        >
            <defs>
                <rect
                    id='rang-b'
                    width='299'
                    height='115.195'
                    x='0'
                    y='0'
                    rx='3.388'
                />
                <filter
                    id='rang-a'
                    width='108%'
                    height='120.8%'
                    x='-4%'
                    y='-7.8%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dy='3'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='3.5'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0'
                    />
                </filter>
            </defs>
            <g
                fill='none'
                fillRule='evenodd'
                transform='translate(7 4)'
            >
                <use
                    fill='#000'
                    filter='url(#rang-a)'
                    href='#rang-b'
                />
                <use
                    fill='#FFF'
                    href='#rang-b'
                />
                <path
                    fill='#C7D2DA'
                    d='M12.7053824,83.0084986 L150.770538,83.0084986 L150.770538,88.0906516 C150.770538,89.9618486 149.253633,91.4787535 147.382436,91.4787535 L16.0934844,91.4787535 C14.2222874,91.4787535 12.7053824,89.9618486 12.7053824,88.0906516 L12.7053824,83.0084986 L12.7053824,83.0084986 Z'
                    opacity='.4'
                />
                <path
                    className='fill-primary'
                    d='M16.0934844,74.5382436 L282.906516,74.5382436 C284.777713,74.5382436 286.294618,76.0551486 286.294618,77.9263456 L286.294618,79.6203966 C286.294618,81.4915937 284.777713,83.0084986 282.906516,83.0084986 L12.7053824,83.0084986 L12.7053824,83.0084986 L12.7053824,77.9263456 C12.7053824,76.0551486 14.2222874,74.5382436 16.0934844,74.5382436 Z'
                />
                <text
                    fill='#393939'
                    fontSize='15.246'
                    fontWeight='bold'
                >
                    <tspan
                        x='12.705'
                        y='26.705'
                    >{t('SvgText:Rank1')}</tspan>
                </text>
                <text fontSize='11.858'>
                    <tspan
                        x='12.705'
                        y='49.116'
                        fill='#393939'
                    >{t('SvgText:Rank2')} {t('SvgText:Rank3')} {t('SvgText:Rank4')}</tspan>
                </text>
                <text
                    fill='#ACB0B4'
                    fontSize='8.47'
                >
                    <tspan
                        x='99.102'
                        y='101.173'
                    >{t('SvgText:Rank5')}</tspan>
                </text>
                <text
                    fill='#ACB0B4'
                    fontSize='8.47'
                >
                    <tspan
                        x='272.742'
                        y='72.374'
                    >{t('SvgText:Rank6')}</tspan>
                </text>
            </g>
        </svg>

  );
}
