import ActionTypes from 'shared/utilities/action-types';

const INITIAL_STATE = {};

class HintReducer {
  static reduce(state = INITIAL_STATE, action) {
    let reference;
    let result;
    const payload = action.payload;
    if (payload) {
      reference = payload.reference;
      result = payload.result;
    }
    switch (action.type) {
      case ActionTypes.DIDYOUKNOWHINT_REQUEST:
        return {
          ...state,
          loading: true,
          didyouknowHints: null,
          reference: null,
        };

      case ActionTypes.DIDYOUKNOWHINT_SUCCESS:
        return {
          ...state,
          loading: false,
          didyouknowHints: result,
          reference,
        };

      case ActionTypes.DIDYOUKNOWHINT_FAILED:
        return {
          ...state,
          loading: false,
          didyouknowHints: null,
          reference,
        };

      case ActionTypes.AUTH_LOGOUT_FAILED:
      case ActionTypes.AUTH_LOGOUT_SUCCESS:
      case ActionTypes.CLEAR_STORE:
        return {};
      default:
        return state;
    }
  }
}

export default HintReducer.reduce;
