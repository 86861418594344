
// Packages
import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import clsx from "clsx";

// Hooks
import { useTranslation } from "react-i18next";

// Utilities
import {
  getModuleKey,
  getStatusOfModule,
  MODULE_OPENED_FROM,
} from "elearning/helpers";
import { checkModuleUnlocked } from "elearning/helpers/check";
import { MODULE_STATES } from "shared/utilities/module-states";

// Components
import InfoBubble from "shared/components/info-bubble";
import SvgIcon from "shared/svgs/helper/svg-icon";
import CardRibbon from "elearning/components/card-ribbon";
import ResponsivePicture from "shared/components/responsive-picture";
import { Attention, Checkmark, Time, Lock } from "shared/svgs";

// Actions
import { dispatchSetPlayerData } from "elearning/services";

// Scss
import "./category-card.scss";
import { Tooltip } from "@sosafe-platform-engineering/fe-lib-ui-react";

import { kebabCase } from "lodash";
import useUser from "shared/hooks/use-user";
import CustomButton from "shared/components/custom-button";
import { CustomButtonVariantType } from "shared/components/custom-button/types";

function CategoryCard(props) {
  const { category, className } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("translations");
  const { user } = useUser({});

  const {
    image,
    name,
    description,
    modules,
    version,
    category_name: categoryKey,
    description_short,
  } = category;

  let durationLeft = 0;
  let progressMade = false;

  let allModulesLocked = true;

  if (modules && modules.length) {
    modules.forEach((mod) => {
      if (mod.progress) progressMade = true;
      let modDuration =
        mod.duration - (mod.progress / 100) * mod.duration || mod.duration;
      if (mod.master) {
        modDuration = mod.duration;
      }
      const moduleUnlocked = checkModuleUnlocked(user, mod);
      if (mod.status !== 3 && moduleUnlocked)
        durationLeft += Math.ceil(modDuration);
      if (moduleUnlocked) allModulesLocked = false;
    });
  }

  let textDuration = "";
  if (!progressMade) {
    textDuration = t("approx. x minute", { count: durationLeft });
  } else {
    textDuration = t("approx. x minute left", { count: durationLeft });
  }

  const isModuleUnTreated = (modul) =>
    modul.status === 0 || modul.status === null;
  const isUntreated =
    !modules || !modules.find((modul) => !isModuleUnTreated(modul));
  let notPassedAmount = 0;
  let allPassed = true;
  const statusOfModules: Array<MODULE_STATES> = [];
  let numberOfUnlockedModules = 0;

  const historyState = {
    openedFrom: MODULE_OPENED_FROM.CATEGORY_CARD,
  };

  if (modules) {
    modules.forEach((module) => {
      if (module.module_name) {
        if (checkModuleUnlocked(user, module)) {
          if (module.status !== 3) {
            statusOfModules.push(...getStatusOfModule(module));
            notPassedAmount += 1;
            allPassed = false;
          }
          numberOfUnlockedModules += 1;
        }
      }
    });
  }

  let rightButtonLabel = t("Continue");
  if (isUntreated) {
    rightButtonLabel = t("Start");
  } else if (allPassed) {
    rightButtonLabel = t("Restart");
  }

  const onClickStart = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!modules || !modules.length) return;
    if (isUntreated || allPassed) {
      // start first module
      let firstModuleKey = null;
      const firstModule = modules.find(
        (module) => module.unlocked && new Date() > new Date(module.unlocked)
      );
      if (firstModule) {
        if (firstModule.module_name) {
          firstModuleKey = getModuleKey(firstModule);
        } else if (modules.length > 1) {
          firstModuleKey = getModuleKey(modules[1]);
        } else {
          return;
        }
        dispatch(dispatchSetPlayerData({ isContinue: false }));
        history.push(
          `/elearning/${categoryKey}/${firstModuleKey}`,
          historyState
        );
      } else {
        history.push(`/elearning/${categoryKey}`);
      }
    } else {
      const pausedModule = modules.find(
        (module) => module.module_name && module.status === 1
      );
      if (pausedModule) {
        const pausedModuleKey = getModuleKey(pausedModule);
        dispatch(dispatchSetPlayerData({ isContinue: true }));
        history.push(
          `/elearning/${categoryKey}/${pausedModuleKey}`,
          historyState
        );
        return;
      }
      const untreatedModule = modules
        .filter((module) => checkModuleUnlocked(user, module))
        .find(
          (module) =>
            module.module_name &&
            (module.status === 0 ||
              module.status === null ||
              module.status === 2)
        );
      if (untreatedModule) {
        const untreatedModuleKey = getModuleKey(untreatedModule);
        dispatch(dispatchSetPlayerData({ isContinue: true }));
        history.push(
          `/elearning/${categoryKey}/${untreatedModuleKey}`,
          historyState
        );
        return;
      }
      console.error(
        "[CategoryCard] Kein Modul mit status null, 0 oder 1 gefunden."
      );
    }
  };

  const LR = `${i18n.language === "ar" ? "r" : "l"}`;

  const goToModulePage = () => {
    history.push(`/elearning/${categoryKey}`, { version });
  };

  return (
    <div
      tabIndex={0}
      role="button"
      className={`overflow-hidden card shadow hover-shadow cursor-pointer ${className || ""}`}
      onClick={goToModulePage}
      data-testid={`${kebabCase(name)}-category-card`}
    >
      <div className="row h-100">
        <div
          className={clsx(
            {
              "col-xxl-8": category.master,
              "pl-md-3": i18n.language !== "ar",
              "pr-md-3": i18n.language === "ar",
            },
            "col-12 col-md-6 flex-grow-1 order-2"
          )}
        >
          <div
            className={`h-100 py-3 px-3 p${LR}-md-0 d-flex flex-column category-min-height`}
          >
            <div className="row">
              <div className="col-12 d-flex flex-row justify-content-between">
                <div className="col-10 p-0">
                  <h2 className="mb-2 h1 n-mt-1">{name}</h2>
                </div>
                <div className="col-2 justify-content-end d-flex p-0">
                  <div>
                    <InfoBubble size="small" description={description} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p className="u-textMedium">{description_short}</p>
              </div>
            </div>
            <div className="row mt-auto text-muted">
              {!allPassed && (
                <div
                  className={`col-12 d-flex flex-row ${category.master ? "justify-content-xxl-end" : ""}`}
                >
                  <SvgIcon
                    Icon={Time}
                    size="15px"
                    className="my-auto stroke-icon-gray"
                  />
                  <small className={`my-auto m${LR}-1`}>{textDuration}</small>
                </div>
              )}
            </div>
            <div className="row mt-1">
              <div
                className={`col-12 ${category.master ? "col-xxl-5 mr-xxl-0 ml-xxl-auto" : ""} d-flex flex-row justify-content-between`}
              >
                <CustomButton
                  variant={CustomButtonVariantType.SECONDARY_OUTLINE}
                  label={t("To overview")}
                  className="flex-basis-50"
                  onClick={goToModulePage}
                />
                <span className="mx-1" />
                <CustomButton
                  className="flex-basis-50"
                  disabled={
                    !modules || modules.length === 0 || allModulesLocked
                  }
                  label={rightButtonLabel}
                  onClick={(e) => onClickStart(e)}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={clsx(
            {
              "col-xxl-4": category.master,
              "pr-md-0": i18n.language !== "ar",
              "pl-md-0": i18n.language === "ar",
            },
            "col-12 col-md-6 d-flex card-header-down-sm-height order-1"
          )}
        >
          <ResponsivePicture
            className="h-100 w-100"
            src={image}
            alt={name}
            fit="cover"
            grayscale={allModulesLocked}
            defaultWidth={730}
            srcset={{
              xs: { width: 730 },
              md: { height: 230 },
              lg: { width: 570 },
              xxl: { height: 250 },
            }}
          />
          {allModulesLocked && (
            <div className="locked-container">
              <Tooltip content={t("These modules are not available yet.")}>
                <div className="cursor-help">
                  <SvgIcon Icon={Lock} size="large" />
                </div>
              </Tooltip>
            </div>
          )}
          {statusOfModules.flat().includes(MODULE_STATES.EXPIRED) && (
            <CardRibbon
              text="Module overdue!"
              icon={Attention}
              className="overdue mb-5"
            />
          )}
          {!allModulesLocked && (
            <CardRibbon
              text={
                allPassed
                  ? "Completed"
                  : `${numberOfUnlockedModules - notPassedAmount} / ${numberOfUnlockedModules} ${t("Modules")}`
              }
              icon={allPassed ? Checkmark : null}
              className={allPassed ? "passed" : "info"}
            />
          )}
        </div>
      </div>
    </div>
  );
}

CategoryCard.propTypes = {
  category: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    modules: PropTypes.arrayOf(PropTypes.any),
    id: PropTypes.number,
    master: PropTypes.bool,
    version: PropTypes.number,
  }).isRequired,
  className: PropTypes.string,
};

CategoryCard.defaultProps = {
  className: "",
};

export default React.memo(CategoryCard);
