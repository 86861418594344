import React from 'react';
import {
  Text,
  Group,
  Anchor,
  Stack,
  MantineStyleProps,
} from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import useMetaLinks from 'shared/hooks/use-meta-links';
import { useTranslation } from 'react-i18next';

const AuthCardFooter: React.FC<MantineStyleProps> = (props) => {
  const { t } = useTranslation('translations');
  const { accessibility, privacy, imprint, support } = useMetaLinks();
  const useOfCookies = `${privacy}#use-of-cookies`;
  return (
    <Stack align="center" {...props} gap={0}>
      {support && (
        <Anchor size="xs" href={support} target="_blank" underline="hover">
          {t('Go to support')}
        </Anchor>
      )}
      <Group pt="xl" justify="center">
        {privacy && (
          <Anchor size="xs" target="_blank" href={privacy} underline="hover">
            {t('Privacy Policy')}
          </Anchor>
        )}
        {privacy && (
          <Anchor
            size="xs"
            target="_blank"
            href={useOfCookies}
            underline="hover"
          >
            {t('Use Of Cookies')}
          </Anchor>
        )}
        {accessibility && (
          <Anchor
            size="xs"
            target="_blank"
            href={accessibility}
            underline="hover"
          >
            {t('Accessibility')}
          </Anchor>
        )}
        {imprint && (
          <Anchor size="xs" target="_blank" href={imprint} underline="hover">
            {t('Imprint')}
          </Anchor>
        )}
      </Group>
      <Text size="xs" c="dimmed" pt="sm">
        © {new Date().getFullYear()} SoSafe SE
      </Text>
    </Stack>
  );
};

export default AuthCardFooter;
