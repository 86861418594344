
// Packages
import React, { useCallback, useState, memo } from 'react';
import ReactTimeAgo from 'react-time-ago';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

// Components
import SvgIcon from 'shared/svgs/helper/svg-icon';

// Hooks
import useNotifications from 'shared/hooks/use-notifications';

// Utils
import {
  Messages, Time, ChevronRight, Close,
} from 'shared/svgs';

// Scss
import './notification.scss';
import { Tooltip } from '@sosafe-platform-engineering/fe-lib-ui-react';
import { PanelToggle } from '../panel-toggle';

function Notification() {
  const { t, i18n } = useTranslation('translations');
  const [showNotificationOptions, setShowNotificationOptions] = useState(false);
  const {
    notifications,
    sendReadNotification,
  } = useNotifications();

  const unreadNotifications = notifications ? notifications.filter((noti) => !noti.read) : [];
  const unread = !!unreadNotifications.length;
  const isScroll = notifications.length > 3;

  const history = useHistory();

  const markAsRead = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    sendReadNotification(id);
  };

  const openUrl = (e, noti) => {
    e.stopPropagation();
    e.preventDefault();
    if (!noti.read) {
      sendReadNotification(noti.id);
    }
    window.open(noti.url);
  };

  const openRoute = (e, noti) => {
    e.stopPropagation();
    e.preventDefault();
    if (!noti.read) {
      sendReadNotification(noti.id);
    }
    history.push(`/${noti.route}`);
  };

  const ToolTipContent = useCallback(() => (
        <nav
            className='notification-menu'
            role='listbox'
        >
            <div className='px-2'>
                <h6 className='mb-2 u-paragraph u-textBold'>{t('Notifications')}</h6>
            </div>
            <div className={isScroll ? 'scrollable' : ''}>
                {notifications.length ? notifications.map((noti, idx) => {
                  let onClick = () => { };
                  if (noti.url) {
                    onClick = (e) => openUrl(e, noti);
                  } else if (noti.route) {
                    onClick = (e) => openRoute(e, noti);
                  }

                  const itemClassArr = [];
                  itemClassArr.push(`type-${noti.type}`);
                  if (!noti.read) itemClassArr.push('unread');
                  if (noti.url || noti.route) itemClassArr.push('cursor-pointer');

                  return (
                        <React.Fragment key={`fragment_${idx}`}>
                            {idx === 0 && (
                                <hr
                                    className='my-1 mx-0'
                                    key={`hr_${noti.id}`}
                                />
                            )}
                            <div
                                className={`item px-2 ${itemClassArr.join(' ')}`}
                                key={noti.id}
                                onClick={onClick}
                                role='option'
                                aria-selected='false'
                            >
                                <div className='d-flex justify-content-between px-2 py-1'>
                                    <div className='flex-grow-1'>
                                        <div className='font-weight-bold'>
                                            {noti.title}
                                        </div>
                                        <span>
                                            {noti.content}
                                        </span>
                                        <div className='d-flex text-muted'>
                                            <SvgIcon
                                                Icon={Time}
                                                size='12'
                                                className='my-auto mr-2 stroke-icon-gray'
                                            />
                                            <ReactTimeAgo
                                                date={new Date(noti.created)}
                                                locale={i18n.language}
                                                timeStyle='twitter'
                                            />
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column justify-content-between'>
                                        {!noti.read && (
                                            <Tooltip content={t('Mark as read')}>
                                                <div
                                                    className='ml-auto d-flex flex-row cursor-pointer'
                                                    onClick={(e) => markAsRead(e, noti.id)}
                                                    role='button'
                                                >
                                                    <SvgIcon
                                                        Icon={Close}
                                                        size='14'
                                                        className='align-self-center stroke-icon-gray mt-1'
                                                    />
                                                </div>
                                            </Tooltip>
                                        )}
                                        {(noti.url || noti.route) && (
                                            <div className='d-flex flex-row cursor-pointer align-self-end mt-auto'>
                                                <span className='align-self-center mr-2 text-muted'>
                                                    {t('Start')}
                                                </span>
                                                <SvgIcon
                                                    Icon={ChevronRight}
                                                    size='10'
                                                    className='align-self-center stroke-icon-gray'
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {idx !== notifications.length - 1 && (
                                <hr
                                    className='my-1 mx-0'
                                    key={`hr_bottom_${noti.id}`}
                                />
                            )}
                        </React.Fragment>
                  );
                }) : (
                    <div className='px-2'>
                        <span>{t('No new notifications')}</span>
                    </div>
                )}
            </div>
        </nav>
  ), [showNotificationOptions, notifications.length]);

  return (
        <div className='position-relative'>
            <PanelToggle
                showContent={showNotificationOptions}
                content={<ToolTipContent/>}
                onMouseLeave={() => setShowNotificationOptions(false) }
            >
                <Tooltip
                    content={t('Notifications')}
                >
                    <div
                        className={`align-self-center my-auto position-relative ${unread ? ' notification-bubble' : ''}`}
                        role='button'
                        aria-label={t('Notifications')}
                        onClick={() => setShowNotificationOptions(true)}
                    >
                        <SvgIcon
                            Icon={Messages}
                            size='25'
                            className='my-auto mx-0 mx-sm-4 cursor-pointer stroke-icon-gray'
                        />
                    </div>
                </Tooltip>
            </ PanelToggle>
        </div>
  );
}

export default memo(Notification);
