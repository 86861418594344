import { getDefaultLanguage } from 'lang/i18n';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import storage from 'redux-persist/lib/storage';
import { Select } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import {
  getLanguageISO6391Name,
  sortLanguages,
  LANGUAGE_MANAGER_ISO,
  nonLatinLanguages,
  getLanguageName,
} from 'shared/utilities/languages';
import { IconCheck } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react/icons';

const LANGUAGE_MANAGER_KEY = LANGUAGE_MANAGER_ISO.map(({ key }) => key);

export default function LangSwitch() {
  const { t, i18n } = useTranslation('translations');
  const [languageKey, setLanguageKey] = useState('');

  useEffect(() => {
    const setLanguage = async () => {
      const lang = await storage.getItem('language');
      setLanguageKey(lang);
    };
    setLanguage();
  }, []);

  const currLang = useMemo(() => {
    const currentLanguage = LANGUAGE_MANAGER_KEY.find(
      (lang) => lang === languageKey
    );
    if (!currentLanguage) {
      const defaultLanguage = LANGUAGE_MANAGER_KEY.find(
        (lang) => lang === getDefaultLanguage()
      );
      if (!defaultLanguage) {
        return { key: 'en', value: 'English' };
      }
      return {
        key: defaultLanguage,
        value: getLanguageISO6391Name(defaultLanguage),
      };
    }
    return {
      key: currentLanguage,
      value: getLanguageISO6391Name(currentLanguage),
    };
  }, [languageKey]);

  const sortedLang = useMemo(() => sortLanguages(LANGUAGE_MANAGER_ISO), []);

  const handleClick = (selectedValue) => {
    if (!selectedValue) {
      return;
    }

    const selectedLang = sortedLang.find((lang) => lang.key === selectedValue);
    if (!selectedLang) {
      return;
    }

    localStorage.setItem('language', selectedValue);
    i18n.changeLanguage(selectedValue);
    setLanguageKey(selectedValue);
    window.document.documentElement.lang = selectedValue;
  };

  return (
    <Select
      label={t('Language')}
      aria-label={t('Language')}
      onChange={handleClick}
      data={sortedLang.map((avLang) => ({
        value: avLang.key,
        label: avLang.value,
        code: avLang.key,
      }))}
      value={currLang.key}
      renderOption={(item) => (
        <div
          lang={item.option.code}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 'calc(0.5rem * var(--mantine-scale))', // Ensure --mantine-scale is defined in your CSS
          }}
          aria-label={
            nonLatinLanguages.has(item.option.code)
              ? getLanguageName(item.option.code)
              : undefined
          }
        >
          {item.checked ? (
            <IconCheck
              style={{
                width: '1.2em',
                minWidth: '1.5em',
                height: '1em',
              }}
            />
          ) : null}
          <span lang={item.option.value}>{item.option.label}</span>
        </div>
      )}
    />
  );
}
