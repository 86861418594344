
import { MutableRefObject } from 'react';
import escapeRegExp from 'shared/helpers/check/escape-reg-exp';

const replaceConstants = (stringifyHtml: string, apiKey: string, moduleId: string) => {
  const urlLms = window.elearningRuntimeConfig.LMS_URL as string;
  if (!urlLms) throw new Error('Could not start scorm because URL DOMAIN is undefined');

  return stringifyHtml.replace(new RegExp(escapeRegExp('$CODE$'), 'g'), apiKey)
    .replace(new RegExp(escapeRegExp('$MODULE$'), 'g'), `${moduleId}`)
    .replace(new RegExp(escapeRegExp('$LMS_DOMAIN$'), 'g'), urlLms);
};

const scormStart = async (apiKey: string, moduleId: string, iframe: MutableRefObject<HTMLIFrameElement>) => {
  const loc = window.location.origin;
  try {
    const htmlTemplate = await fetch(`${loc}/wrapper/index.html`);
    const stringifyHtml = await htmlTemplate.text();
    const stringifyHtmlWithConstants = replaceConstants(stringifyHtml, apiKey, moduleId);

    if (!iframe || !iframe.current) throw new Error('Could not start scorm because iFrame is undefined');

    const doc = iframe.current.contentDocument;
    if (!doc) throw new Error('Could not start scorm because iframe content document is undefined');

    doc.open();
    doc.write(stringifyHtmlWithConstants);
    doc.close();
  } catch (error: any) {
    console.error(error);
    throw new Error(error);
  }
};

export default scormStart;
