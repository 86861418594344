
// Packages
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

// Components
import AchievementCardHeader from 'gamification/components/achievement-card-header';
import CustomButton from 'shared/components/custom-button';
import ProgressChart from 'gamification/components/progress-chart';
import Loading from 'shared/components/loading';

// Hooks
import usePlatformSettings from 'shared/hooks/use-platform-settings';
import useUser from 'shared/hooks/use-user';
import { useGetActivities } from 'shared/hooks/use-get-activities';
import { useCategoriesRequest } from 'elearning/hooks/use-categories-request';

// Utilities
import { MODULE_OPENED_FROM, getModuleForQuickStartCard, getModuleKey } from 'elearning/helpers';

// Scss
import './progress-card.scss';

function ProgressCard(props) {
  const { t } = useTranslation('translations');
  const { className } = props;
  const history = useHistory();

  const { settings } = usePlatformSettings();
  const { step_activation } = settings || {};

  const { data: categoriesResponse, isLoading: isCategoryLoading } = useCategoriesRequest();
  const categories = useMemo(() => categoriesResponse?.data?.result || [], [categoriesResponse?.data]);

  const { data: activitiesResponse, isLoading: isActivitiesLoading } = useGetActivities();
  const activities = useMemo(() => activitiesResponse?.data?.result || [], [activitiesResponse?.data]);

  const { user, loading: isUserLoading } = useUser({});

  const progressIsLoading = isCategoryLoading || isActivitiesLoading || isUserLoading;

  if (progressIsLoading) {
    return <div className='d-flex justify-content-around'><Loading /></div>;
  }

  const newModule = getModuleForQuickStartCard(categories, user, step_activation);

  return (
        <Card className={`shadow ${className}`}>
            <Card.Body>
                <AchievementCardHeader
                    title={t('Progress')}
                    info={t('achievement progress tooltip')}
                />
                <div className='direction-ltr'>
                    <ProgressChart
                        activities={activities}
                        game={user.game}
                        loading={progressIsLoading}
                    />
                    {(!progressIsLoading && activities && activities.length < 2 && newModule) && (
                        <div className='progress-no-data-hint shadow text-center card px-2 py-2'>
                            <p className='text-muted mb-0'>
                                {t('You have not collected any experience points yet')}
                            </p>
                            <p className='text-muted'>
                                {t('Finish your first module now!')}
                            </p>
                            <CustomButton
                                className='px-2 py-1 align-self-center'
                                label={t('Start')}
                                onClick={() => history.push(`/elearning/${newModule.category_key}/${getModuleKey(newModule)}`, {
                                  openedFrom: MODULE_OPENED_FROM.ACHIEVEMENT_PROGRESS_CARD,
                                })}
                            />
                        </div>
                    )}
                </div>
            </Card.Body>
        </Card>
  );
}

export default React.memo(ProgressCard, () => true);

ProgressCard.propTypes = {
  progress: PropTypes.shape({
    completed: PropTypes.number,
    total: PropTypes.number,
    xp: PropTypes.number,
    level: PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.string,
      name: PropTypes.string,
      xp: PropTypes.number,
    }),
  }),
  className: PropTypes.string,
};

ProgressCard.defaultProps = {
  progress: null,
  className: '',
};
