
import ActionTypes from 'shared/utilities/action-types';

const INITIAL_STATE = { settings: null };

class DebugReducer {
  static reduce(state = INITIAL_STATE, action) {
    switch (action.type) {
      case ActionTypes.SETTINGS_CHANGED:
        return {
          ...state,
          settings: {
            ...state.settings,
            ...action.payload.changes,
          },
        };
      case ActionTypes.ACTIVATE_DEBUG:
        return {
          ...state,
          activatedDebug: true,
        };
      case ActionTypes.DEACTIVATE_DEBUG:
        return {
          ...state,
          activatedDebug: false,
        };

      case ActionTypes.AUTH_LOGIN_FAILED:
      case ActionTypes.AUTH_LOGIN_REQUEST:
      case ActionTypes.AUTH_LOGIN_SUCCESS:
      case ActionTypes.AUTH_LOGOUT_FAILED:
      case ActionTypes.AUTH_LOGOUT_REQUEST:
      case ActionTypes.AUTH_LOGOUT_SUCCESS:
      case ActionTypes.CLEAR_STORE:
        return {};

      default:
        return state;
    }
  }
}

export default DebugReducer.reduce;
