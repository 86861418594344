
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function LevelProgress() {
  const { t } = useTranslation('tourTranslations');

  return (
        <svg
            width='313'
            height='179'
            viewBox='0 0 313 179'
            direction='ltr'
        >
            <defs>
                <rect
                    id='prefix__level-b'
                    width='299'
                    height='164.191'
                    x='0'
                    y='0'
                    rx='3.457'
                />
                <filter
                    id='prefix__level-a'
                    width='107.7%'
                    height='114%'
                    x='-3.8%'
                    y='-5.8%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dy='2'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='3.5'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
                    />
                </filter>
                <filter
                    id='prefix__level-c'
                    width='143.2%'
                    height='135.2%'
                    x='-21.6%'
                    y='-17.6%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dx='1'
                        dy='1'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='3.5'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        result='shadowMatrixOuter1'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0'
                    />
                    <feMerge>
                        <feMergeNode in='shadowMatrixOuter1' />
                        <feMergeNode in='SourceGraphic' />
                    </feMerge>
                </filter>
                <path
                    id='prefix__level-d'
                    d='M36.675 0L0 16.349v28.76c0 9.31 3.977 21.762 15.517 32.419C29.114 90.098 36.674 91 36.674 91s7.557-.901 21.164-13.472C69.367 66.87 73.346 54.418 73.346 45.11V16.349L36.675 0z'
                />
            </defs>
            <g
                fill='none'
                fillRule='evenodd'
            >
                <g transform='translate(7 5)'>
                    <use
                        fill='#000'
                        filter='url(#prefix__level-a)'
                        href='#prefix__level-b'
                    />
                    <use
                        fill='#FFF'
                        href='#prefix__level-b'
                    />
                </g>
                <path
                    className='fill-secondary'
                    fillRule='nonzero'
                    d='M286.124 15.491a9.503 9.503 0 0 0-9.506 9.506 9.503 9.503 0 0 0 9.506 9.506 9.503 9.503 0 0 0 9.506-9.506 9.503 9.503 0 0 0-9.506-9.506zm.95 14.259h-1.9v-5.704h1.9v5.704zm0-7.605h-1.9v-1.9h1.9v1.9z'
                />
                <g transform='translate(112.219 76.961)'>
                    <rect
                        width='183.202'
                        height='8.393'
                        fill='#C7D2DA'
                        opacity='.4'
                        rx='2.098'
                    />
                    <rect
                        width='62.161'
                        height='8.393'
                        className='fill-primary'
                        rx='2.098'
                    />
                </g>
                <text
                    fill='#414141'
                    fontSize='10.37'
                    transform='translate(112.219 76.568)'
                >
                    <tspan
                        x='0'
                        y='21.44'
                    >{t('SvgText:LevelProgress1')}</tspan>
                </text>
                <text
                    fill='#535353'
                    fontSize='12.098'
                    fontWeight='bold'
                    transform='translate(7 5)'
                >
                    <tspan
                        x='105.219'
                        y='63.714'
                    >{t('SvgText:LevelProgress2')}</tspan>
                </text>
                <text
                    fill='#494949'
                    fontSize='8'
                    transform='translate(7 5)'
                >
                    <tspan
                        x='105'
                        y='113'
                    >{t('SvgText:LevelProgress3')}</tspan>
                </text>
                <g transform='translate(112 125)'>
                    <rect
                        width='99'
                        height='20'
                        fill='#E9EDEF'
                        rx='4'
                    />
                    <text
                        fill='#8E8E8E'
                        fontSize='10'
                    >
                        <tspan
                            x='6'
                            y='13'
                        >{t('SvgText:LevelProgress4')}</tspan>
                    </text>
                </g>
                <g
                    filter='url(#prefix__level-c)'
                    transform='translate(26 54)'
                >
                    <mask
                        id='prefix__level-e'
                        fill='#fff'
                    >
                        <use href='#prefix__level-d' />
                    </mask>
                    <use
                        className='fill-primary'
                        fillRule='nonzero'
                        href='#prefix__level-d'
                    />
                    <path
                        stroke='peru'
                        strokeWidth='3.64'
                        d='M36.675 1.993L71.526 17.53v27.58c0 8.936-3.87 20.864-14.923 31.08-11.663 10.776-18.646 12.687-19.93 12.962-1.284-.275-8.267-2.187-19.921-12.961C5.688 65.973 1.82 54.045 1.82 45.11h0V17.53L36.675 1.993z'
                        mask='url(#prefix__level-e)'
                    />
                    <path
                        fill='#000'
                        fillRule='nonzero'
                        d='M36.675 0l-.001 91s7.557-.901 21.164-13.472C69.367 66.87 73.346 54.418 73.346 45.11V16.349L36.675 0z'
                        mask='url(#prefix__level-e)'
                        opacity='.1'
                    />
                </g>
                <text
                    fill='#494949'
                    fontSize='15.555'
                    fontWeight='bold'
                    transform='translate(7 5)'
                >
                    <tspan
                        x='10.491'
                        y='24.491'
                    >{t('SvgText:LevelProgress5')}</tspan>
                </text>
                <path
                    stroke='#E9EDF0'
                    d='M112.219 105.239h183.202'
                />
            </g>
        </svg>
  );
}
