
// Packages
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, Provider } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Hooks
import useCertificate from 'shared/hooks/use-certificate';

// Components
import CustomButton from 'shared/components/custom-button';
import { Shine } from 'shared/svgs';

// Utilities
import { store } from 'state/store/store';
import ResponsivePicture from 'shared/components/responsive-picture';
import SvgFromUrlAsBackgroundDiv from 'shared/components/svg-from-url-as-background-div';
import { URL_RESOURCE_API, checkIsIE } from 'shared/helpers';

// Scss
import 'shared/components/cert-modal.scss';
import { useSoSafeAnalytics } from 'shared/hooks/use-sosafe-analytics';
import {
  ANALYTICS_TYPE,
  QueryContextProvider,
  SoSafeAxiosProvider,
} from 'shared/modules/sosafe-connect';

const MySwal = withReactContent(Swal);

/**
 * opens the certificate Sweetalert
 *
 * @returns null
 *
 * @category shared
 * @subcategory components
 * @exports getCertificateModal
 */
export default function getCertificateModal(i18n) {
  let arab = '';
  if (i18n && i18n.language === 'ar') {
    arab = 'arab';
  }

  MySwal.fire({
    html: (
      <Provider store={store}>
        <QueryContextProvider>
          <SoSafeAxiosProvider>
            <CertModal />
          </SoSafeAxiosProvider>
        </QueryContextProvider>
      </Provider>
    ),
    showConfirmButton: false,
    customClass: {
      closeButton: 'badge-modal-close',
      popup: `overflow-hidden ${arab}`,
    },
    showCloseButton: true,
    closeButtonAriaLabel: i18n.t('Close'),
    focusConfirm: false,
  });
}

function CertModal() {
  const { t } = useTranslation('translations');
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { loading, download } = useCertificate();
  const { sendSoSafeAnalytics } = useSoSafeAnalytics();
  const customerToken = user && user?.customer ? user?.customer.token : '';

  return (
    <div>
      <div className="my-3 mx-auto certmodal-badge">
        <div className={`certmodal-badge-shine ${checkIsIE() ? 'is-ie' : ''}`}>
          <SvgFromUrlAsBackgroundDiv url="" fallback={Shine} className="w-100 h-100" />
        </div>
        <ResponsivePicture
          className="z-index-1 certmodal-badge-image"
          src={`${URL_RESOURCE_API()}/${customerToken}/img/assets/certificate.png`}
          alt={t('certificate image')}
          fit="contain"
          defaultHeight={400}
        />
      </div>
      <p>{t('Here you can download your highly deserved certificate!')}</p>
      <CustomButton
        disabled={loading}
        label={loading ? t('Loading ...') : t('Download now')}
        onClick={() => {
          download();
          sendSoSafeAnalytics({ type: ANALYTICS_TYPE.CERT_DOWNLOADED });
        }}
      />
    </div>
  );
}
