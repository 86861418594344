
// Packages
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

// Scss
import "./loading.scss";

export default function Loading(props) {
  const { className, size, isLabel } = props;
  const { t } = useTranslation("translations");

  let loaderSize = 0;
  switch (size) {
    case "small":
      loaderSize = "32px";
      break;
    case "medium":
      loaderSize = "64px";
      break;
    case "large":
      loaderSize = "96px";
      break;
    default:
      break;
  }

  return (
    <div
      role="alert"
      aria-label={t("Loading ...")}
      className={`loading-wrapper ${size} ${isLabel ? "use-label" : ""
        } position-relative ${className}`}
    >
      <svg
        width={loaderSize}
        height={loaderSize}
        viewBox="0 0 570.48 550"
        version="1.1"
        className="loading-inner-svg loading-shield"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
      >
        <g>
          <path
            id="e73yq7qgc5fe3"
            d="M284.91 72.72L124.27 145.4v127.86c0 41.39 17.42 96.75 68 144.13 59.55 55.89 92.67 59.89 92.67 59.89s33.1-4 92.7-59.89c50.5-47.38 67.93-102.74 67.93-144.13V145.4z"
            className="fill-primary"
          />
          <path
            id="e73yq7qgc5fe4"
            d="M284.91 477.28s33.1-4 92.7-59.89c50.5-47.38 67.93-102.74 67.93-144.13V145.4L284.91 72.72z"
            opacity=".1"
            className="fill-white"
          />
        </g>
      </svg>
      <svg
        width={loaderSize}
        height={loaderSize}
        viewBox="0 0 570.48 550"
        version="1.1"
        className="loading-inner-svg loading-stroke"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
      >
        <path
          d="M284.94 29.339L88.57 116.6v156.28c0 50.58 21.29 118.24 83.07 176.15 72.79 68.31 113.27 73.21 113.27 73.21s40.46-4.9 113.31-73.21c61.71-57.91 83-125.57 83-176.15V116.6z"
          fill="none"
          strokeWidth="8"
          strokeLinecap="round"
          strokeMiterlimit="10"
          className="stroke-primary"
        />
      </svg>
      {isLabel && <span className="loading-label">{t("Loading ...")}</span>}
    </div>
  );
}

Loading.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  isLabel: PropTypes.bool,
};

Loading.defaultProps = {
  className: "",
  size: "medium",
  isLabel: false,
};
