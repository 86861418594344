
export const WELCOME_MESSAGES = {
  FIRST_LOGIN: 'FIRST_LOGIN',
  INACTIVE_BADGE: 'INACTIVE_BADGE',
  INACTIVE_LOGIN: 'INACTIVE_LOGIN',
  INACTIVE_MODULE: 'INACTIVE_MODULE',
  MAIN_DONE: 'MAIN_DONE',
  MAIN_MAX_TWO_MOD: 'MAIN_MAX_TWO_MOD',
  MAIN_LESS_HALF: 'MAIN_LESS_HALF',
  MAIN_MORE_HALF: 'MAIN_MORE_HALF',
  MAIN_THREE_MORE: 'MAIN_THREE_MORE',
  ADHOC_ALL_ESSENTIALS: 'ADHOC_ALL_ESSENTIALS',
  ADHOC_BADGE: 'ADHOC_BADGE',
  ADHOC_FIRST: 'ADHOC_FIRST',
  ADHOC_LAST: 'ADHOC_LAST',
  ADHOC_MODULE: 'ADHOC_MODULE',
  ADHOC_DUE_DATE: 'ADHOC_DUE_DATE',
  ADHOC_MOD_ALMOST_DONE: 'ADHOC_MOD_ALMOST_DONE',
  ADHOC_CAT_PROGRESS: 'ADHOC_CAT_PROGRESS',
  ADHOC_LEVEL: 'ADHOC_LEVEL',
  ALL_MODULES_LOCK: 'ALL_MODULES_LOCK',
};
