// Packages
import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Title } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";

// Components
import ResponsivePicture from 'shared/components/responsive-picture';
import { getDisplayName } from 'shared/helpers';
import styles from "./activity-section.module.css";

/**
 * renders the first activity in the activity section on the gamification-page
 *
 * @category gamification
 * @subcategory components
 * @exports ActivityRegisterSection
 * @component
 */
export default function ActivityRegisterSection({ isLast, activity }) {
  const { t } = useTranslation('translations');
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { image } = activity;

  const displayName = getDisplayName(user);
  const title = displayName ? t('Welcome {{name}}!', { name: displayName }) : t('Welcome!');

  return (
        <div className={`activity d-flex flex-column py-3 border-top ${isLast ? 'border-bottom mb-3' : ''}`}>
            <div className='mb-3 d-flex flex-row'>
                <Title order={3} size="h5">{title}</Title>
            </div>

            <div className={`${styles.activityBadgeContent} d-flex d-row position-relative`}>
                {/* Image */}
                <div className='activity-badge'>
                    <ResponsivePicture
                        className='activity-badge-image'
                        src={image}
                        alt=""
                        fit='contain'
                        defaultHeight={100}
                    />
                </div>
                <span className='mx-2' />
                {/* Info */}
                <div className='d-flex flex-column w-100'>
                    <Title className='mb-3' order={4} size="h5"> {t('First step')}</Title>
                    <span>
                        {t('You have registered on the platform and have taken the first step towards becoming a cyber security expert.')}
                    </span>
                </div>
            </div>
        </div>
  );
}
