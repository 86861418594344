
// Packages
import Axios from 'axios';
import Form from 'react-bootstrap/Form';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Utilities
import ActionTypes from 'shared/utilities/action-types';
import { URL_AUTH_API } from 'shared/helpers';
import useMetaLinks from 'shared/hooks/use-meta-links';
import { UseReferenceHandler } from 'shared/utilities/use-reference-handler';
import { isEmail } from 'authentication/helpers';
import { showToast, ALERT_TYPE } from 'shared/utilities/modal-alert';

// Components
import AuthImage from 'authentication/components/auth-image';
import CustomButton from 'shared/components/custom-button';
import CustomFormGroup from 'shared/components/custom-form-group';
import Helmet from 'shared/components/helmet';
import AuthHeader from 'authentication/components/auth-header';

// Scss
import 'scss/base.scss';

export default function SamlPage() {
  const { t, i18n } = useTranslation([
    'translations',
    'messageTranslations',
    'helmetTranslations',
    'placeholderTranslations',
  ]);

  const [email, setEmail] = useState(sessionStorage.getItem('sessionExpiredSso') ?? '');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const checkReference = UseReferenceHandler();

  const { support } = useMetaLinks();

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();
    const data = { email };

    const instance = Axios.create();
    instance
      .post(`${URL_AUTH_API()}/saml/login`, { ...data, area: 1 })
      .then((response) => {
        setLoading(false);
        const { data: respData } = response;
        const { id } = respData || {};
        if (!id) {
          showToast(
            ALERT_TYPE.ERROR,
            `${t('messageTranslations:An error has occured')} (Missing ID)`
          );
          return;
        }
        window.location.replace(`${URL_AUTH_API()}/saml/login/${id}`);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          dispatch({
            payload: checkReference(error.response.data, dispatch),
            type: ActionTypes.AUTH_LOGIN_FAILED,
          });
        } else {
          dispatch({
            payload: checkReference(
              {
                title: error.name,
                status: error.statusCode,
                reference: '40700745-2789-4F88-8412-1885DDC724E4',
              },
              dispatch
            ),
            type: ActionTypes.AUTH_LOGIN_FAILED,
          });
        }
      });
  };

  return (
    <React.Fragment>
      <Helmet title={t('helmetTranslations:Login')} />

      <AuthHeader />

      <div className="m-auto auth-card-width">
        <Card shadow="sm" className="shadow overflow-hidden">
          <div className="d-flex flex-row">
            <AuthImage flipx={i18n.language === 'ar'} />
            <div className="base-card-right py-3 px-3 flex-grow-1">
              <h2 className="mb-2 h1 text-center register-login-title-weight">{t('Login')}</h2>
              <hr />
              <Form onSubmit={onSubmit} className="u-flex u-flexCol">
                <CustomFormGroup
                  id="input"
                  label={t('Business email address')}
                  isTrimOnBlur
                  placeholder={t('placeholderTranslations:Email')}
                  value={email}
                  handleOnChange={(value) => setEmail(value.input)}
                  valid={isEmail(email)}
                />
                <CustomButton
                  onClick={() => { }}
                  disabled={!isEmail(email) || loading}
                  label={loading ? t('Fetching data...') : t('Next')}
                  className="u-flexGrow1 mb-2"
                  isSubmitButton
                />
              </Form>
              <p className="text-center">
                {t('Alternative login')}?
                <Link className="text-nowrap ml-2" to="/">
                  {t('Back')}.
                </Link>
              </p>

              {support && (
                <React.Fragment>
                  <hr />
                  <p className="text-center mb-1">
                    <a href={support} target="_blank" rel="noopener noreferrer">
                      {t('Go to support')}
                    </a>
                  </p>
                </React.Fragment>
              )}
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
}
