
import { ProductAnalytics } from "shared/modules/product-analytics";
import { TRACKED_EVENTS } from "elearning/helpers";

// Packages
import { saveAs } from "file-saver";
import { useSelector, shallowEqual } from "react-redux";
import { useState } from "react";

// Utilities
import { UseReferenceHandler } from "shared/utilities/use-reference-handler";
import { useSoSafeConnect } from "shared/modules/sosafe-connect";

export default function useCertificate() {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const checkReference = UseReferenceHandler();
  const { customer = {}, game } = user || {};
  const canDownloadCertificate = game?.canDownloadCertificate || false;
  const mandatoryModuleCount = game?.progress?.mandatory || 0;
  const { endPoints, axiosInstance } = useSoSafeConnect();
  const fallbackUserName =
    user?.firstname && user?.lastname ? `${user?.firstname}_${user?.lastname}` : "";
  const fallbackFileName = `SoSafe_Certificate_${fallbackUserName}`;

  const [loading, setLoading] = useState(false);

  const downloadCertificate = () => {
    if (!canDownloadCertificate) return;
    setLoading(true);

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error &&
          error.request &&
          error.request.responseType === "blob" &&
          error.response.data instanceof Blob &&
          error.response.data.type &&
          error.response.data.type.toLowerCase().indexOf("json") !== -1
        ) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              const rejectError = error;
              rejectError.response.data = JSON.parse(reader.result);
              resolve(Promise.reject(rejectError));
            };
            reader.onerror = () => {
              reject(error);
            };
            reader.readAsText(error.response.data);
          });
        }
        return Promise.reject(error);
      }
    );

    endPoints.fetch
      .getCertificate({ customerId: customer.id }, { responseType: "blob" })
      .then((response) => {
        setLoading(false);
        const header = response.headers["content-disposition"];
        const parts = header?.split(";");
        const filename = parts?.[1]?.split("=")?.[1]?.replace(/['"]+/g, "") || fallbackFileName;
        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(blob, filename);
        ProductAnalytics.getInstance().trackEvent(TRACKED_EVENTS.CERTIFICATE_DOWNLOAD);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          checkReference(error.response.data);
        } else {
          checkReference({
            title: error.name,
            status: error.statusCode,
            reference: "15D7A8A9-DAFA-44B4-8B24-708E6BC0123F",
          });
        }
      });
  };

  return {
    loading,
    enabled: canDownloadCertificate,
    mandatory: mandatoryModuleCount,
    download: downloadCertificate,
  };
}
