
import { getLessonStatusBy } from 'elearning/pages/player-page/utils';
import { ModuleProps, LessonStatus, ScormModuleProgressProps, CMIElement } from '../../../types';
import { checkIsActivePresenter } from '../check';

enum ScormAction {
  INIT = 'INIT',
  GETDATA = 'GETDATA',
  SETDATA = 'SETDATA',
  FINISH = 'FINISH',
}

interface ScormCallbackActionProps {
  type: ScormAction;
  cmiElement: CMIElement;
  moduleData: ModuleProps;
  isFullScreenAvailable: boolean;
  redirectBack: () => void;
  finishLoading: () => void;
  handleModuleCompletion: (props: LessonStatus.PASSED | LessonStatus.FAILED) => void;
  sendUpdateModuleProgressRequest: (props: ScormModuleProgressProps) => void;
}

/**
  LMS_URL set by env variables without v1
 */
const lmsUrlWihtoutApiVerstion = () => {
  const lmsUrl = window.elearningRuntimeConfig.LMS_URL;
  if (typeof lmsUrl === 'string') {
    return (window.elearningRuntimeConfig.LMS_URL as string)?.replace('/v1', '');
  }
  throw new Error('Please make sure to set LMS_URL on your env variables');
};

/**
 * receives user progress data from scorm modules
 * and update the platform accordantly to data received
 */
export default function scormCallbackAction({
  type,
  cmiElement,
  moduleData,
  sendUpdateModuleProgressRequest,
  finishLoading,
  handleModuleCompletion,
  redirectBack,
  isFullScreenAvailable = true,
}: ScormCallbackActionProps) {
  const isActivePresenter = checkIsActivePresenter(moduleData?.scorm_entry_point);

  switch (type) {
    case ScormAction.INIT:
      if (moduleData.isCustom) {
        finishLoading();
      }
      break;

    case ScormAction.GETDATA:
      break;

    case ScormAction.SETDATA: {
      if (cmiElement === 'cmi.core.exit' && isFullScreenAvailable && isActivePresenter) {
        const elFrame = document.getElementById('el_frame');
        const iFrame = elFrame?.ownerDocument?.getElementsByTagName('iframe')[0]?.contentWindow;
        if (iFrame) {
          const lmsUrl = lmsUrlWihtoutApiVerstion();
          if (lmsUrl) {
            iFrame.postMessage({ type: 'ADD_FULLSCREEN_TOGGLE' }, lmsUrl);
          }
        }
      }

      const lmsScore = window.API?.LMSGetValue(CMIElement.SCORE);
      const score = lmsScore ? parseInt(window.API?.LMSGetValue(CMIElement.SCORE), 10) : 0;
      const suspendData = window.API?.LMSGetValue(CMIElement.SUSPEND_DATA);
      const lessonStatus = getLessonStatusBy({
        lessonStatus: window.API?.LMSGetValue(CMIElement.LESSON_STATUS),
      });

      sendUpdateModuleProgressRequest({
        module: moduleData?.id,
        version: 2,
        score,
        suspend_data: suspendData,
        lesson_status: getLessonStatusBy({ lessonStatus }),
      });

      if (suspendData !== '') {
        finishLoading();
      }

      if (lessonStatus === 'failed' || lessonStatus === 'passed') {
        handleModuleCompletion(lessonStatus);
      }
      break;
    }

    case ScormAction.FINISH:
      redirectBack();
      break;

    default:
      break;
  }
}
