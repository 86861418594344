import { BadgeModal } from 'flamingo-e-learning-platform/components/badge-modal/badge-modal';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { BadgeProps } from 'types';
import { getModuleKey, TRACKED_EVENTS } from 'elearning/helpers';

import { ProductAnalytics } from 'shared/modules/product-analytics';
import Helmet from 'shared/components/helmet';
import { useTranslation } from 'react-i18next';
import { useModalContext } from 'modal-context/modal-context';
import useUser from 'shared/hooks/use-user';
import {
  SESSION_STORAGE_KEY_MODULE,
  TSessionStorageKeyModule,
} from 'shared/utilities/constants';
import { LearningPath } from './components/learning-path/learning-path';
import { LevelCard } from './components/level-card/level-card';
import ModuleHub from './components/module-hub/module-hub';
import { SophieFacts } from './components/sophie-facts/sophie-facts';
import { useLastBadges } from './hooks/use-last-badges';
import { PersonalizedModule } from './types/module.type';
import { UsePersonalizedModule } from './hooks/use-personalized-module';
import { MobileView } from './components/mobile-view/mobile-view';
import { WelcomeCard } from './components/welcome-card/welcome-card';
import { limitedContentDays } from './components/shared';
import { hasOnlyOptionalModules } from './components/shared/check-has-only-optional-modules';
import { FLAGS } from './components/shared';

import style from './training.page.module.css';

/**
 * @category flamingo
 * @exports FlamingoTrainingPage
 * @component
 */
export default function FlamingoTrainingPage() {
  const [curBadge, setCurBadge] = useState<BadgeProps | null>(null);
  const history = useHistory();
  const { t } = useTranslation('helmetTranslations');
  const modal = useModalContext();

  const { user } = useUser({});
  const customerId = user?.customer?.id || 0;
  const campaignLimitedDays = user?.campaign?.module_unlock_days || 0;

  const learningDaysLimit = limitedContentDays(customerId, campaignLimitedDays);

  const handleBadgeClick = (badge) => {
    setCurBadge(badge);
    modal.fire({
      html: <BadgeModal onClose={() => setCurBadge(null)} badge={badge} />,
      onClose: async () => {
        modal.close();
      },
    });
  };

  const badgeComponents = useLastBadges({
    onClick: handleBadgeClick,
    maxNumberOfBadges: 3,
  });

  const onModuleCard = (module: PersonalizedModule) => {
    if (FLAGS.isNewIframeEnabledV7 && module.version === 7) {
      history.push(`/elearning/${module.category}/${getModuleKey(module)}/v7`);
    } else if (FLAGS.isNewIframeEnabledV6 && module.version === 6) {
      history.push(`/elearning/${module.category}/${getModuleKey(module)}/v6`);
    } else {
      history.push(`/elearning/${module.category}/${getModuleKey(module)}`);
    }
  };

  const { personalizedModules } = UsePersonalizedModule();
  const hasOnlyOptionals = hasOnlyOptionalModules(personalizedModules);

  const mandatoryModules = useMemo(
    () => personalizedModules.filter((mod) => mod.mandatory),
    [personalizedModules]
  );

  useEffect(() => {
    ProductAnalytics.getInstance().trackEvent(TRACKED_EVENTS.START_PAGE_VIEWED);

    /**
     * Check if a module information has been saved with the "SESSION_STORAGE_KEY_MODULE" key
     * If so, redirect to the player page for that module
     *
     * Logic can be find in the checkRedirect function in shared/helpers/redirect/check-redirect
     *
     * This is a temporary bug fix, refactoring of the router-controllers should handle this.
     *  */

    const selectedModule = sessionStorage.getItem(SESSION_STORAGE_KEY_MODULE);
    if (selectedModule) {
      const { categoryKey, moduleKey } = JSON.parse(
        selectedModule
      ) as TSessionStorageKeyModule;
      if (categoryKey && moduleKey) {
        history.push(`/elearning/${categoryKey}/${moduleKey}`);
      }

      sessionStorage.removeItem(SESSION_STORAGE_KEY_MODULE);
    }
  }, []);

  return (
    <div className={style.container}>
      <Helmet title={t('training')} />
      <div className={style.widgets}>
        <WelcomeCard />

        <LevelCard
          badges={badgeComponents}
          OnLearnMore={() => history.push('/achievements')}
        />
        <SophieFacts />
      </div>
      <div className={style.trainingModules}>
        <LearningPath
          onModuleCard={onModuleCard}
          modules={hasOnlyOptionals ? personalizedModules : mandatoryModules}
          learningDaysLimit={learningDaysLimit}
        />
        <ModuleHub
          onModuleCard={onModuleCard}
          personalizedModules={personalizedModules}
          learningDaysLimit={learningDaysLimit}
        />
      </div>
      <div className={style.mobileView}>
        <MobileView
          onModuleCard={onModuleCard}
          mandatoryModules={
            hasOnlyOptionals ? personalizedModules : mandatoryModules
          }
          learningDaysLimit={learningDaysLimit}
        />
      </div>
    </div>
  );
}
