
// Packages
import PropTypes from 'prop-types';
import React from 'react';
import { ChevronDown, ChevronUp } from 'shared/svgs';
import SvgIcon from 'shared/svgs/helper/svg-icon';

// Hooks
import { useTranslation } from 'react-i18next';

// Scss
import './divider.scss';

/**
 * Component to render hline. Adds chevron and onClick on mobile.
 *
 * @category elearning
 * @subcategory components
 * @exports Divider
 * @component
 */
function Divider(props) {
  const { label, direction, className } = props;
  const { i18n } = useTranslation('translations');

  const LR = `${i18n.language === 'ar' ? 'r' : 'l'}`;
  const RL = `${i18n.language === 'ar' ? 'l' : 'r'}`;

  return (
        <React.Fragment>
            {/* Desktop */}
            <hr className={`d-none d-md-block ${className}`} />
            {/* Mobile */}
            <div
                className={`d-md-none d-flex flex-row cursor-pointer align-items-center ${className}`}
                onClick={props.onClick}
                role='button'
                aria-label={label}
            >
                <hr className={`w-100 m${RL}-2`} />
                <div className='w-100 text-muted cursor-pointer d-flex flex-row justify-content-center'>
                    <span className='u-textMedium mb-1 text-nowrap'>{label}</span>
                    <SvgIcon
                        Icon={direction === 'up' ? ChevronUp : ChevronDown}
                        className={`m${LR}-2 align-self-center fill-icon-gray`}
                        size='14'
                    />
                </div>
                <hr className={`w-100 m${LR}-2`} />
            </div>
        </React.Fragment>
  );
}

Divider.propTypes = {
  label: PropTypes.string,
  direction: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Divider.defaultProps = {
  label: '',
  direction: '',
  className: '',
  onClick: () => {},
};

export default React.memo(Divider);
