
export default function replacePlaceholdersWithUserData(content, user) {
  const placeholders = [
    {
      key: '$FIRSTNAME$',
      value: user.firstname,
    },
    {
      key: '$LASTNAME$',
      value: user.lastname,
    },
  ];

  let result = content;
  placeholders.forEach((ph) => {
    result = content.replace(ph.key, ph.value);
  });

  return result;
}
