
/**
 * Validate user. Returns false if user is invalid
 *
 * @param {*} parameterName parameter description
 * @returns {*} return value description
 *
 * @category shared
 * @subcategory helpers
 * @exports checkUser
 */
export default function checkUser(user) {
  if (!user) return false;
  const {
    apikey,
    area,
    campaign,
    customer,
    game,
  } = user;

  if (!apikey) return false;
  if (!area || area !== 1) return false;
  if (!campaign) return false;
  if (!customer) return false;

  if (!game) return false;
  const {
    activities,
    comparison,
    progress,
    welcome,
  } = game;

  if (!activities || !comparison || !progress || !welcome) return false;
  return true;
}
