
import useCheats from 'test/hooks/use-cheats';
import { GamificationSettings } from 'types/gamification-settings';
import useUser from './use-user';

/**
 * Takes redux state; Evaluates gamification settings;
 *
 * @export
 * @param {*} state
 */
export default function usePlatformSettings() {
  const { user } = useUser({ enabled: false });
  const { isDebug, cheats } = useCheats();
  if (!user) return {};

  const { campaign } = user || {};

  const { level: currentLevel, xp: currentXp } = user?.game?.progress || {};

  const {
    achievements,
    material_area,
    badges,
    certificate,
    colleagues,
    feedback,
    hints,
    level,
    simulation_opt_in,
    step_activation,
    notifications,
    show_faq,
    show_glossary,
  } = campaign || {};

  const settings: GamificationSettings = {
    achievements,
    material_area,
    badges,
    certificate,
    colleagues,
    feedback,
    hints,
    level,
    simulation_opt_in,
    step_activation,
    notifications,
    show_faq,
    show_glossary,
    gameProgress: {
      currentLevel,
      currentXp,
    },
  };

  if (isDebug) {
    return { settings, ...cheats };
  }

  return { settings };
}
