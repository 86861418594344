
// Packages
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Utilities
import { checkCheatsActive } from 'shared/helpers';
import ActionTypes from 'shared/utilities/action-types';

let debouncePressTimeout = null;
let resetTimeout = null;
let pressCount = 0;

export default function useCheats() {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { settings, activatedDebug } = useSelector((state) => state.debug, shallowEqual);

  const activateCheats = checkCheatsActive(user);

  const checkIsDebugAllowed = () => {
    if (activateCheats) return true;
    if (window.elearningRuntimeConfig.DEPLOY_ENVIRONMENT === 'production') return false;
    return true;
  };

  const onKey = () => {
    if (debouncePressTimeout) {
      clearTimeout(debouncePressTimeout);
    }
    debouncePressTimeout = setTimeout(() => {
      pressCount++;
      if (pressCount === 4) {
        dispatch({
          type: ActionTypes.ACTIVATE_DEBUG,
        });
      }
    }, 10);

    if (resetTimeout) {
      clearTimeout(resetTimeout);
    }
    resetTimeout = setTimeout(() => {
      pressCount = 0;
    }, 500);
  };

  useEffect(() => {
    if (activateCheats && !activatedDebug) dispatch({ type: ActionTypes.ACTIVATE_DEBUG });
    if (checkIsDebugAllowed()) {
      if (document.getElementById('version-number')) {
        document.getElementById('version-number').addEventListener('click', onKey);
      }
    }
    return () => {
      if (document.getElementById('version-number')) {
        document.getElementById('version-number').removeEventListener('click', onKey);
      }
    };
  }, []);

  return {
    isDebug: checkIsDebugAllowed() && activatedDebug,
    cheat: (changes) => {
      dispatch({
        type: ActionTypes.SETTINGS_CHANGED,
        payload: {
          changes,
        },
      });
    },
    cheats: settings,
    closeDebug: () => {
      dispatch({
        type: ActionTypes.DEACTIVATE_DEBUG,
      });
    },
  };
}
