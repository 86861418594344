// Packages
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Services
import { dispatchDeletePlayerData } from 'elearning/services';
import { ProductAnalytics } from 'shared/modules/product-analytics';

// Hooks
import useDemoPopup from 'elearning/hooks/use-demo-popup';
import useFullscreen from 'elearning/hooks/use-fullscreen';
import usePlatformSettings from 'shared/hooks/use-platform-settings';

// Utilities
import {
  checkIsActivePresenter,
  checkShowModuleFeedback,
  getCategoryByCategoryKey,
  getTopics,
  MODULE_OPENED_FROM,
  MODULE_START_TYPE,
  scormGetJSON,
  scormCallbackAction,
  scormStart,
  TRACKED_EVENTS,
} from 'elearning/helpers';
import getModuleByKeyFromCategories from 'elearning/helpers/get/get-module-by-key-from-categories';
import ActionTypes from 'shared/utilities/action-types';
import { ALERT_TYPE } from 'shared/utilities/modal-alert';
import { UseReferenceHandler } from 'shared/utilities/use-reference-handler';
import { useModalContext } from 'modal-context/modal-context.tsx';
import {
  Button,
  ActionIcon,
  Tooltip,
  Text,
  Title,
} from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import { UseShowDecisionAlert } from '../../../flamingo-e-learning-platform/utilities/modal-alert/use-show-decision-alert';
import {
  IconWindowMaximize,
  IconWindowMinimize,
  IconX,
} from '@sosafe-platform-engineering/fe-lib-ui-mantine-react/icons';
// Components
import NextModulePreview from 'elearning/components/next-module-preview';
import Helmet from 'shared/components/helmet';
import Loading from 'shared/components/loading';
import OrientationChange from 'shared/components/orientation-change';

// Scss
import { Card } from '@sosafe-platform-engineering/fe-lib-ui-react';
import { useMutation } from '@tanstack/react-query';
import { useCategoriesRequest } from 'elearning/hooks/use-categories-request';
import useUser from 'shared/hooks/use-user';
import { useNotificationSystemContext } from 'shared/modules/notification-system';
import {
  queryClient,
  queryKey,
  useSoSafeConnect,
} from 'shared/modules/sosafe-connect';
import { MODULE_STATES } from 'shared/utilities/module-states';
import './player-page.scss';
import { ReferenceAction } from 'shared/modules/sosafe-connect/handle-reference';
import { useClearLoginData } from 'authentication/hooks/use-clear-login-data';
import { useIsPersonalizedLearning } from 'flamingo-e-learning-platform/hooks/use-is-personalized-learning';
import { NextPersonalizedModulePreview } from 'flamingo-e-learning-platform/components/next-module-preview';
import { FLAGS } from 'flamingo-e-learning-platform/training/components/shared';
import {
  createNewProgressFromModuleFinished,
  getLessonStatusBy,
} from './utils';

const MySwal = withReactContent(Swal);

let timer;
let demoDelayTimer;

/**
 * renders the scorm-player-page - navbar and footer will vanish on
 * mobile-landscape, to guarantee enough space for the player
 *
 * @category elearning
 * @subcategory pages
 * @exports PlayerPage
 * @component
 */
export default function PlayerPage() {
  const iFrameRef = useRef(null);
  const { t, i18n } = useTranslation(['translations', 'helmetTranslations']);
  const { moduleKey, categoryKey } = useParams();
  const { settings } = usePlatformSettings();
  const { step_activation } = settings || {};
  const history = useHistory();
  const match = useRouteMatch();
  const clearLoginData = useClearLoginData();
  const { setNotifications, setNotificationCallback, clearNotificationQueue } =
    useNotificationSystemContext();
  const { refetch: refetchCategories } = useCategoriesRequest(false);
  const { user, loading: loadingUser } = useUser({
    enabled: false,
    id: 'playerPage',
  });
  const { show } = UseShowDecisionAlert();
  const { close } = useModalContext();
  const checkReference = UseReferenceHandler();

  const { categories, player_data, loadingPlayerPage } = useSelector(
    (state) => state.modules,
    shallowEqual
  );

  const { language } = user || [];

  const dispatch = useDispatch();
  const isPersonalizedLearning = useIsPersonalizedLearning();
  const [showHint, setShowHint] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showNextModulePreview, setShowNextModulePreview] = useState(false);
  const [userClosedNextModulePreview, setUserClosedNextModulePreview] =
    useState(false);
  const [currentCategory, setCurrentCategory] = useState(
    getCategoryByCategoryKey(categories, categoryKey, language)
  );
  const [topics, setTopics] = useState(
    currentCategory
      ? getTopics(currentCategory.modules, step_activation, user)
      : []
  );
  // Delete this fallback with the refactoring of modules to be shown based on category id, not category NAME
  const currentDefaultCategory = getCategoryByCategoryKey(
    categories,
    categoryKey,
    'en'
  );
  // Delete this fallback with the refactoring of modules to be shown based on category id, not category NAME
  const topicsDefault = currentDefaultCategory
    ? getTopics(currentDefaultCategory.modules, step_activation, user)
    : [];
  const [activeModuleData, setActiveModuleData] = useState(
    getInitialModuleData(
      topics,
      topicsDefault,
      getModuleByKeyFromCategories(categories, moduleKey, categoryKey, language)
        ?.id
    )
  );

  const lastLessonStatus = useRef('incomplete');
  const { endPoints } = useSoSafeConnect();

  const updateElearningMutation = useMutation(
    (data) => endPoints.post.updateElearning(data),
    {
      onSuccess: (response, data) => {
        const newLevel = response.data?.result.achievements?.level;
        const nextLevel = response.data?.result.achievements?.nextLevel;
        const newXp = response.data?.result.achievements?.xp;
        if ((newLevel && nextLevel) || !!newXp) {
          queryClient.setQueryData(
            [queryKey.AUTH],
            ({ data: authResponse }) => {
              const oldProgress = authResponse.result?.game.progress;

              const newProgress = createNewProgressFromModuleFinished({
                progress: oldProgress,
                newLevel,
                nextLevel,
                newXp,
              });

              const newAuthResponse = {
                ...authResponse,
                result: {
                  ...authResponse.result,
                  game: {
                    ...authResponse.result?.game,
                    progress: {
                      ...oldProgress,
                      ...newProgress,
                    },
                  },
                },
              };
              return { data: { ...newAuthResponse }, status: 200 };
            }
          );
          queryClient.invalidateQueries([queryKey.AUTH]);
        }

        const { result } = checkReference(response.data);

        const newActiveModuleData = {
          ...activeModuleData,
          progress: result.progress,
          slide: result.slide,
          point: result.point,
          score: result.score,
          suspend_data: result.suspend_data,
          status: result.status,
          lesson_status: getLessonStatusBy({
            lessonStatus: data.lesson_status,
          }),
        };

        dispatch({
          type: ActionTypes.MODULE_UPDATE_PROGRESS,
          payload: { module: newActiveModuleData },
        });
        setActiveModuleData(newActiveModuleData);
        setIsLoading(false);
      },
      onError: (error) => {
        if (
          error?.response?.data?.reference &&
          ReferenceAction.shouldLogout(error.response.data.reference)
        ) {
          checkReference(error.response.data, dispatch);
          clearLoginData(true);
        }
        setIsLoading(false);
      },
    }
  );

  useEffect(() => {
    const nextCategory = getCategoryByCategoryKey(
      categories,
      categoryKey,
      user?.language
    );
    if (!isEqual(currentCategory, nextCategory)) {
      setCurrentCategory(nextCategory);
      setTopics(
        nextCategory
          ? getTopics(nextCategory.modules, step_activation, user)
          : []
      );
    }
  }, [categories?.length, categoryKey]);

  useEffect(() => {
    const historyState = (history.location || {}).state || {};
    const isRestartAction =
      (((history.location || {}).state || {}).action || '') === 'RESTART';

    let startType = MODULE_START_TYPE.STARTED;
    if (
      isRestartAction ||
      activeModuleData?.statusOfModule?.includes?.(MODULE_STATES.PASSED)
    ) {
      startType = MODULE_START_TYPE.RESTARTED;
    } else if (
      activeModuleData?.statusOfModule?.includes?.(MODULE_STATES.PAUSED)
    ) {
      startType = MODULE_START_TYPE.CONTINUED;
    }

    if (activeModuleData?.uuid) {
      ProductAnalytics.getInstance().trackEvent(TRACKED_EVENTS.MODULE_OPENED, {
        startType,
        openedFrom: historyState.openedFrom || MODULE_OPENED_FROM.DIRECT_ACCESS,
        moduleUuid: activeModuleData.uuid,
        moduleName: activeModuleData.name,
        moduleGroup: activeModuleData.group,
        moduleLanguage: activeModuleData.language,
        moduleVersion: activeModuleData.version,
      });
    }
  }, [activeModuleData?.uuid]);

  useEffect(() => {
    const moduleIdInt = getModuleByKeyFromCategories(
      categories,
      moduleKey,
      categoryKey,
      language
    )?.id;

    if (moduleIdInt !== activeModuleData?.id) {
      setActiveModuleData(
        getInitialModuleData(topics, topicsDefault, moduleIdInt)
      );
    } else if (!activeModuleData?.id) {
      refetchCategories();
    }
  }, [moduleKey, topics]);

  const wrapperRef = useRef(null);
  const { isFullscreen, isFullScreenAvailable, toggleFullscreen, setElement } =
    useFullscreen();

  const sendUpdateModuleProgressRequest = useCallback(
    debounce((data) => updateElearningMutation.mutate(data), 4000, {
      maxWait: 10000,
    }),
    [activeModuleData?.uuid]
  );

  const handleModuleCompletion = useCallback(
    (lessonStatus) => {
      if (lastLessonStatus.current !== lessonStatus) {
        lastLessonStatus.current = lessonStatus;
        setIsLoading(true);

        // This function will be called by scormGetRequestsFromSCO when the lesson_status changes from incomplete to passed/failed
        ProductAnalytics.getInstance().trackEvent(
          TRACKED_EVENTS.MODULE_COMPLETION_STATUS,
          {
            passed: lessonStatus === 'passed',
            moduleUuid: activeModuleData.uuid,
            moduleName: activeModuleData.name,
            moduleGroup: activeModuleData.group,
            moduleLanguage: activeModuleData.language,
            deadline: activeModuleData.finished_by,
            hasDeadline: !!activeModuleData.finished_by,
            mandatory: activeModuleData.mandatory,
            moduleVersion: activeModuleData.version,
          }
        );

        // invalidate elearning query to ensure fetching latest category information
        queryClient.invalidateQueries([queryKey.ELEARNING]);

        // If the module is completed then we can flush the debounce function
        // So we don't need to wait the 4sec debounce call
        sendUpdateModuleProgressRequest.flush();
      }
    },
    [activeModuleData?.uuid]
  );

  const redirectBack = useCallback(() => {
    if (match.url === history.location.pathname) {
      if (!history.location?.state?.openedFrom) return history.push('/');

      const { openedFrom } = history.location.state;

      switch (openedFrom) {
        case MODULE_OPENED_FROM.CATEGORY_CARD:
        case MODULE_OPENED_FROM.DIRECT_ACCESS:
        case MODULE_OPENED_FROM.QUICKSTART_CARD:
          history.push('/');
          break;
        case MODULE_OPENED_FROM.NEXT_MODULE_POP_UP:
        case MODULE_OPENED_FROM.MODULE_CARD:
          history.push(`/elearning/${categoryKey}`);
          break;
        case MODULE_OPENED_FROM.ACHIEVEMENT_PROGRESS_CARD:
          history.push('/achievements');
          break;
        default:
          history.push('/');
      }
    }
    queryClient.invalidateQueries([queryKey.ELEARNING]);
    sendUpdateModuleProgressRequest.flush();
  }, [history.location.state, match.url]);

  useBeforeunload(() => {
    sendUpdateModuleProgressRequest.flush();
  });

  useEffect(() => {
    setElement(wrapperRef?.current);
  }, [wrapperRef]);

  const redirectHome = () => {
    close();
    redirectBack();
  };

  const showNoModuleAlert = () => {
    if (!MySwal.isVisible()) {
      show({
        title: <Title size="h2">{t('Loading failed')}</Title>,
        content: <Text>{t('This module is not yet available.')}</Text>,
        footer: (
          <>
            <Button
              variant="primary"
              aria-label={t('Back to the home page')}
              onClick={() => redirectHome()}
            >
              {t('Back to the home page')}
            </Button>
          </>
        ),
        onClose: redirectBack,
        type: ALERT_TYPE.ERROR,
      });
    }
  };

  useEffect(() => {
    setShowNextModulePreview(false);
    const { group, scorm_entry_point, lesson_status } = activeModuleData;

    const moduleCompleted = lesson_status === 'passed';

    const moduleData = { moduleCompleted, group, scorm_entry_point };

    const showModuleFeedback = checkShowModuleFeedback({
      moduleData,
      moduleFeedback: user?.game?.moduleFeedback,
      moduleFeedbackGroups: user?.game?.moduleFeedbackGroups,
    });

    if (moduleCompleted) {
      if (showModuleFeedback && !isPersonalizedLearning) {
        const feedbackModal = {
          type: 'feedbackModal',
          moduleGroup: group,
          displayOrder: 0,
        };
        setNotifications(feedbackModal);
      }

      setNotificationCallback(() => {
        // This will set NextModulePreview only after all notifications are displayed
        setShowNextModulePreview(true);
        // Deactivate until legal requirements are met to use chameleon
        // if (isPersonalizedLearning) {
        //   window.chmlnRuntime.triggerEventById(activeModuleData.group);
        // }
      });
    }
  }, [
    activeModuleData.group,
    activeModuleData.scorm_entry_point,
    activeModuleData.lesson_status,
  ]);

  const { show: showDemoPopup, isDemo } = useDemoPopup();

  const isSCORMToBeStopped =
    (FLAGS.isNewIframeEnabledV7 &&
      !isEmpty(activeModuleData) &&
      activeModuleData?.version === 7) ||
    (FLAGS.isNewIframeEnabledV6 &&
      !isEmpty(activeModuleData) &&
      activeModuleData?.version === 6);

  const doStart = async () => {
    if (isEmpty(activeModuleData) || isSCORMToBeStopped) {
      return;
    }

    const theJSON = scormGetJSON({
      playerData: player_data,
      user,
      moduleData: activeModuleData,
    });
    try {
      window.API = new window.SCORMLMS(theJSON);
    } catch (error) {
      console.error('window.SCORMLMS is not a constructor');
    }
    if (window.API) {
      window.API.setCallBack((type, elem, val) =>
        scormCallbackAction({
          type,
          elem,
          val,
          sendUpdateModuleProgressRequest,
          finishLoading: () => setIsLoading(false),
          handleModuleCompletion,
          moduleData: activeModuleData,
          redirectBack,
          isFullScreenAvailable,
        })
      );
    }
    setIsLoading(true);
    try {
      await scormStart(user.apikey, activeModuleData.id, iFrameRef);
    } catch (e) {
      show({
        title: <Title size="h2">{t('Loading failed')}</Title>,
        footer: (
          <>
            <Button
              variant="primary"
              aria-label={t('Reload page')}
              onClick={() => window.location.reload()}
            >
              {t('Reload page')}
            </Button>
          </>
        ),
        type: ALERT_TYPE.ERROR,
      });
    }

    dispatch(dispatchDeletePlayerData());
    setShowNextModulePreview(false);

    setShowHint(true);
    timer = setTimeout(() => {
      setShowHint(false);
    }, 9000);

    demoDelayTimer = null;
    if (isDemo) {
      demoDelayTimer = setTimeout(() => {
        showDemoPopup();
      }, 2000);
    }
  };

  useEffect(() => {
    lastLessonStatus.current = 'incomplete';
    const fullScreenEvent = (event) => {
      const lmsUrl = window.elearningRuntimeConfig.LMS_URL;
      if (typeof lmsUrl === 'string') {
        if (
          lmsUrl.includes(event.origin) &&
          event.data.type === 'TOGGLE_FULLSCREEN'
        ) {
          toggleFullscreen(wrapperRef?.current);
        }
      }
    };

    if (iFrameRef.current) {
      if (activeModuleData.id && !loadingUser) {
        if (!activeModuleData.active) {
          showNoModuleAlert();
        } else {
          doStart();
          window.addEventListener('message', fullScreenEvent);
        }
      }
    }

    return () => {
      window.removeEventListener('message', fullScreenEvent);
    };
  }, [activeModuleData.id, loadingUser]);

  useEffect(
    () => () => {
      clearTimeout(timer);
      clearTimeout(demoDelayTimer);
      // this dispatch is used, to fill the WelcomeCard with up to date information
      dispatch({
        type: ActionTypes.LEAVE_PLAYER_PAGE,
        payload: activeModuleData,
      });
      clearNotificationQueue();
    },
    []
  );

  const onQuit = () => {
    if (isFullscreen) toggleFullscreen(wrapperRef?.current);

    sendUpdateModuleProgressRequest.flush();
    setTimeout(() => {
      redirectBack();
    }, 100);
  };

  const isActivePresenter = checkIsActivePresenter(
    activeModuleData?.scorm_entry_point
  );
  const iframeWrapperClassNames = ['iframe-wrapper', 'shadow', 'w-100'];
  const isModuleVersion6 = activeModuleData.version === 6;
  const isModuleVersion7 = activeModuleData.version === 7;

  if (FLAGS.isNewIframeEnabledV6 && isModuleVersion6) {
    history.push(`/elearning/${categoryKey}/${moduleKey}/v6`);
  }
  if (FLAGS.isNewIframeEnabledV7 && isModuleVersion7) {
    history.push(`/elearning/${categoryKey}/${moduleKey}/v7`);
  }

  const buttons = (customClass) => (
    <div className={`d-flex buttons-wrapper direction-ltr ${customClass}`}>
      <Tooltip label={!isMobile ? t('Close') : null}>
        <ActionIcon
          variant="transparent"
          aria-label={t('Close')}
          color="black"
          bg="white"
          mr={isMobile ? 'xs' : 'md'}
          size={isMobile ? 'sm' : undefined}
          onClick={() => onQuit()}
        >
          <IconX height={16} width={16} />
        </ActionIcon>
      </Tooltip>
      {isFullScreenAvailable && (
        <Tooltip
          disabled={isMobile}
          label={
            isFullscreen
              ? t('Deactivate full screen')
              : t('Activate full screen')
          }
        >
          <ActionIcon
            variant="transparent"
            color="black"
            bg="white"
            size={isMobile ? 'sm' : undefined}
            aria-label={
              isFullscreen
                ? t('Deactivate full screen')
                : t('Activate full screen')
            }
            onClick={() => toggleFullscreen(wrapperRef?.current)}
          >
            {isFullscreen ? (
              <IconWindowMinimize strokeWidth={1} height={24} width={24} />
            ) : (
              <IconWindowMaximize strokeWidth={1} height={24} width={24} />
            )}
          </ActionIcon>
        </Tooltip>
      )}
    </div>
  );

  const content = (
    <div
      className={iframeWrapperClassNames.join(' ')}
      id="wrapper"
      ref={wrapperRef}
    >
      {isMobile && !isFullscreen && !isLandscape && showHint && (
        <div className="landscape-hint-wrapper">
          <p className="fs-80 mb-2 text-center">
            {t('Please rotate your device for a better view')}
          </p>
          <div className="landscape-phone" />
        </div>
      )}

      <iframe
        id="el_frame"
        data-testid="elearning-module-iframe"
        className="player-iframe"
        ref={iFrameRef}
      />
      {showNextModulePreview &&
        !userClosedNextModulePreview &&
        !isPersonalizedLearning &&
        !isModuleVersion6 && (
          <NextModulePreview
            closeNextModulePreview={() => setUserClosedNextModulePreview(true)}
          />
        )}
      {showNextModulePreview &&
        !userClosedNextModulePreview &&
        isPersonalizedLearning &&
        !isModuleVersion6 && (
          <NextPersonalizedModulePreview
            closeNextModulePreview={() => setUserClosedNextModulePreview(true)}
          />
        )}
      {isLoading && (
        <div className="scorm-loader">
          <Card>
            <Loading />
            {/* 
            The new loading text is only assigned to the 
            aria-label because it may breaks the UI otherwise 
            */}
            <p aria-label={t('lesson_content_loading')}>
              {t('translations:Loading ...')}
            </p>
          </Card>
        </div>
      )}

      {
        buttons(
          'buttons-default'
        ) /* isMobile is not working as expected, so we're using css-classes instead */
      }
    </div>
  );

  const divClassesArray = ['content-wrapper', 'm-auto'];
  divClassesArray.push(isActivePresenter ? 'active-presenter' : 'storyline');
  if (activeModuleData.version === 5) divClassesArray.push('version-five');
  if (isModuleVersion6) divClassesArray.push('version6');
  if (isFullscreen) divClassesArray.push('content-wrapper-fullscreen');
  if (isMobile && isLandscape && !isFullscreen)
    divClassesArray.push('mobile-landscape');
  const divClasses = divClassesArray.join(' ');

  return (
    <React.Fragment>
      <Helmet
        title={`${t('helmetTranslations:Module')} ${activeModuleData.name || ''}`}
      />
      <OrientationChange
        updateParent={(orientation) => setIsLandscape(orientation.isLandscape)}
      />
      <div className={divClasses}>
        {loadingPlayerPage ? (
          <React.Fragment>
            <Loading className="mx-auto" />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {content}
            {/* buttons('buttons-mobile') isMobile is not working as expected, so we're using css-classes instead */}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}

function getInitialModuleData(topics, topicsDefault, moduleIdInt) {
  // Delete this fallback with the refactoring of modules to be shown based on category id, not category NAME
  const moduleData =
    topics
      ?.flatMap((topic) => topic.modules)
      ?.filter((module) => module.id === moduleIdInt)[0] ||
    topicsDefault
      ?.flatMap((topic) => topic.modules)
      ?.filter((module) => module.id === moduleIdInt)[0] ||
    {};
  return moduleData;
}
