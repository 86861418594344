export enum CMIElement {
  EXIT = 'cmi.core.exit',
  SCORE = 'cmi.core.score.raw',
  SUSPEND_DATA = 'cmi.suspend_data',
  LESSON_STATUS = 'cmi.core.lesson_status',
}

type LMSGetValueReturnType<T> = T extends CMIElement.LESSON_STATUS
  ? LessonStatus
  : string;

/** for full documentation and types: https://scorm.com/scorm-explained/technical-scorm/run-time/ */
export interface APISignature {
  // Begins a communication session with the LMS.
  LMSInitialize: () => boolean;
  // Ends a communication session with the LMS.
  LMSFinish: () => boolean;
  // Retrieves a value from the LMS.
  LMSGetValue: <T extends CMIElement>(element: T) => LMSGetValueReturnType<T>;
  // Saves a value to the LMS.
  LMSSetValue: (element: CMIElement, value: string) => string;
  // Indicates to the LMS that all data should be persisted (not required).
  LMSCommit: () => boolean;
}

export enum LessonStatus {
  PASSED = 'passed',
  COMPLETE = 'completed',
  FAILED = 'failed',
  PAUSED = 'paused',
  INCOMPLETE = 'incomplete',
  BROWSED = 'browsed',
  NOT_ATTEMPTED = 'not attempted',
}

export interface ScormModuleProgressProps {
  module?: number;
  version: number;
  score: number;
  suspend_data: string;
  lesson_status: LessonStatus;
}
