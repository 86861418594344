
// Packages
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-bootstrap';

// Hooks
import useWindowSize from 'shared/hooks/use-window-size';

// Utilities
import { tourGetConfig } from 'shared/helpers';

// Components
import CustomButton from 'shared/components/custom-button';
import SvgIcon from 'shared/svgs/helper/svg-icon';
import { Close, ChevronButtonLeft, ChevronButtonRight } from 'shared/svgs';

// Scss
import './tour-mobile-step-wrapper.scss';
import i18n from 'lang/i18n';
import { useTourWithAnalytics } from 'shared/hooks/use-tour/use-tour-with-analytics';

export default function TourMobileStepWrapper(props) {
  const [updateTooltipRef, setUpdateTooltipRef] = useState(null);
  const { tour: activeTour } = useSelector((state) => state.tour, shallowEqual);
  const { t } = useTranslation(['translations', 'tourTranslations']);
  const { tourCancel, tourDone } = useTourWithAnalytics();
  const {
    tour,
  } = props;
  const [show, setShow] = useState(false);
  const debounceTime = 30;
  // Update on window size change. Debounced with 30 ms;
  const { width: windowWidth, height: windowHeight } = useWindowSize(debounceTime);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!show && activeTour === tour) {
      setShow(true);
      setTimeout(() => {
        setUpdateTooltipRef(!updateTooltipRef);
      }, 1);
    } else if (show && activeTour !== tour) {
      setShow(false);
    }
  }, [activeTour]);

  const config = tourGetConfig(tour);
  const { steps } = config;
  const [stepNumber, setStepNumber] = useState(0);

  if (activeTour !== tour) return null;

  return (
        <div className='w-100 h-100 position-fixed welcome-overlay'>
            <div className={`card shadow tour-mobile-tooltip h-100 d-flex flex-column position-relative ${show ? 'show' : 'hide'}`}>
                <div
                    className='welcome-close cursor-pointer'
                    onClick={() => dispatch(tourCancel({ skipAnalytics: false }))}
                    role='button'
                    aria-label={t('Cancel')}
                >
                    <SvgIcon
                        Icon={Close}
                        className='mb-1 stroke-icon-gray'
                        size='24px'
                    />
                </div>

                <div className='d-flex flex-column justify-content-between mt-2 h-100'>

                    <Carousel
                        activeIndex={stepNumber}
                        controls={false}
                        indicators={false}
                        touch
                        slide={i18n.language !== 'ar'}
                    >
                        {steps.map((step) => (
                            <Carousel.Item key={step.title}>
                                <div className='d-flex'>
                                    <h5 className='mb-2 h4 text-left mobile-tour-title'>{t(step.title)}</h5>
                                </div>
                                <hr />
                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                    <div
                                        className='d-flex justify-content-center w-100 h-100'
                                    >
                                        {step.image}
                                    </div>
                                </div>
                                <hr />
                                <div className='text-container-tour'>
                                    <p className={`mb-0 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}>{t(step.description)}</p>
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>

                    <div className='d-flex justify-content-between mt-3'>
                        <div className='navigation-container'>
                            {!!stepNumber && (
                                <SvgIcon
                                    Icon={ChevronButtonLeft}
                                    className='cursor-pointer color-secondary'
                                    size='40px'
                                    onClick={() => setStepNumber(stepNumber - 1)}
                                    flipx={i18n.language === 'ar'}
                                    role='button'
                                    aria-label={t('Back')}
                                />
                            )}
                        </div>
                        <div className='d-flex flex-column justify-content-center'>
                            <span className='text-muted mx-4'>{stepNumber + 1} / {steps.length}</span>
                        </div>
                        <div className='navigation-container'>
                            {(stepNumber < steps.length - 1) && (
                                <SvgIcon
                                    Icon={ChevronButtonRight}
                                    className='cursor-pointer color-secondary'
                                    size='40px'
                                    onClick={() => setStepNumber(stepNumber + 1)}
                                    flipx={i18n.language === 'ar'}
                                    role='button'
                                    aria-label={t('Next')}
                                />
                            )}
                        </div>
                        {(stepNumber === steps.length - 1) && (
                            <CustomButton
                                className='continue-btn py-1'
                                label={t('Let\'s start!')}
                                onClick={() => dispatch(tourDone())}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
  );
}

TourMobileStepWrapper.propTypes = {
  tour: PropTypes.string.isRequired,
};
