
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function VideoModule() {
  const { t } = useTranslation('tourTranslations');

  return (
        <svg
            width='314'
            height='216'
            viewBox='0 0 314 216'
            direction='ltr'
        >
            <defs>
                <rect
                    id='prefix__videomodul-b'
                    width='298.158'
                    height='202'
                    x='0'
                    y='0'
                    rx='3.369'
                />
                <filter
                    id='prefix__videomodul-a'
                    width='107.7%'
                    height='111.4%'
                    x='-3.9%'
                    y='-4.7%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dy='2'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='3.5'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
                    />
                </filter>
                <path
                    id='prefix__videomodul-c'
                    d='M3.369 0h291.42a3.369 3.369 0 0 1 3.369 3.369v148.237H0V3.369A3.369 3.369 0 0 1 3.369 0z'
                />
                <path
                    id='prefix__videomodul-e'
                    d='M0 0h324.629v151.954H0z'
                />
                <path
                    id='prefix__videomodul-g'
                    d='M.069.052h111.082v54.425H.069z'
                />
                <path
                    id='prefix__videomodul-i'
                    d='M31.271 67.447L.035 60.091V8.116L31.271.086z'
                />
                <path
                    id='prefix__videomodul-k'
                    d='M.155.035h48.418v26.419H.155z'
                />
                <path
                    id='prefix__videomodul-m'
                    d='M.138.138h83.298v98.77H.138z'
                />
            </defs>
            <g
                fill='none'
                fillRule='evenodd'
            >
                <g transform='translate(7.842 5)'>
                    <use
                        fill='#000'
                        filter='url(#prefix__videomodul-a)'
                        xlinkHref='#prefix__videomodul-b'
                    />
                    <use
                        fill='#FFF'
                        xlinkHref='#prefix__videomodul-b'
                    />
                    <mask
                        id='prefix__videomodul-d'
                        fill='#fff'
                    >
                        <use xlinkHref='#prefix__videomodul-c'/>
                    </mask>
                    <use
                        fill='#FFF'
                        xlinkHref='#prefix__videomodul-c'
                    />
                    <g mask='url(#prefix__videomodul-d)'>
                        <g transform='translate(-14)'>
                            <mask
                                id='prefix__videomodul-f'
                                fill='#fff'
                            >
                                <use xlinkHref='#prefix__videomodul-e'/>
                            </mask>
                            <g mask='url(#prefix__videomodul-f)'>
                                <g transform='translate(-562.23 -1.727)'>
                                    <path
                                        fill='#99CCD3'
                                        fillRule='nonzero'
                                        d='M562.229 1.727h324.629v107.749H562.229z'
                                    />
                                    <path
                                        fill='#E5CDB3'
                                        fillRule='nonzero'
                                        d='M1372.972 189.113L.138 195.33l272.084-38.593 58.278-9.704 56.084-9.359 41.667-6.959 1.865-.311 2.089-.345 29.355-4.904h.017l3.678-.605 27.921-4.662 4.127-.691.587-.086 21.947-3.661 9.066-1.519 3.229-.535 6.51-1.088 17.785-2.97h356.694l12.865 2.365 8.081 1.468 1.157.225.57.103 30.011 5.491.569.121 2.677.484 28.56 5.232.207.034.07.017 28.266 5.181.104.017 1.796.328 37.954 6.959 47.52 8.703 47.485 8.72z'
                                    />
                                    <path
                                        fill='#C7B299'
                                        fillRule='nonzero'
                                        d='M997.818 124.861l-536.258.294 31.616-5.267 472.629-.898z'
                                        opacity='.2'
                                    />
                                    <path
                                        fill='#C7B299'
                                        fillRule='nonzero'
                                        d='M999.2 109.838l-536.259.121 31.617-2.141 472.628-.362z'
                                    />
                                    <path
                                        fill='#C7B299'
                                        fillRule='nonzero'
                                        d='M1066.008 137.363l-679.424.311 43.532-7.27h597.938zm125.776 19.598l-919.562-.224 58.278-9.704 783.028-.967z'
                                        opacity='.2'
                                    />
                                    <path
                                        fill='#998675'
                                        fillRule='nonzero'
                                        d='M330.361 146.86l546.153-.484-547.465-1.001zm695.897-16.784l-598.007.639 1.865-.311 2.089-.345h593.949zm-.691 7.598l-598.007.638 1.865-.31 2.089-.346h593.95zm-27.679-12.796l-536.311.277 3.678-.605 532.356.277.207.034zm-28.837-5.283l-471.748-.398.587-.086h468.484zm-33.257-6.096l-406.891.432 3.229-.535h403.092z'
                                        opacity='.36'
                                    />
                                    <path
                                        fill='#D9EEFD'
                                        fillRule='nonzero'
                                        d='M608.057 8.168h111.082v54.425H608.057z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M642.195 29.13v-1.347h-10.706v1.347h-2.452v31.669h15.61V29.13zm30.114-1.347v-.88h-.449v-.898h-2.227v.898h-.449v.88h-.449v33.465h4.023V27.783z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M621.008 53.218h7.58v7.584h-7.58z'
                                    />
                                    <path
                                        fill='#CCC'
                                        fillRule='nonzero'
                                        d='M635.27 40.734v-1.347h-6.682v1.347h-1.779v4.455h-3.125v15.61h13.382V40.734z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M690.147 38.938V37.16h-1.33v-2.228h-10.274v2.228h-1.33v1.778h-3.574V60.35h20.082V38.938z'
                                    />
                                    <path
                                        fill='#CCC'
                                        fillRule='nonzero'
                                        d='M671.86 50.542h13.382V61.25H671.86z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M713.354 39.387v1.779h-3.125v20.082h7.131V39.387z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M709.331 26.005v-.898h-1.33v-.449h-1.347v-.432h-2.676v.432h-1.33v-.432h-1.347v-.898h-1.329v.898h-2.245v.881h-.432v.898h-.898V60.35h13.832V26.005z'
                                    />
                                    <g
                                        opacity='.4'
                                        transform='translate(607.988 8.116)'
                                    >
                                        <mask
                                            id='prefix__videomodul-h'
                                            fill='#fff'
                                        >
                                            <use xlinkHref='#prefix__videomodul-g'/>
                                        </mask>
                                        <g
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            mask='url(#prefix__videomodul-h)'
                                        >
                                            <path d='M51.547-17.053l10.587 6.112-42.22 73.127-10.587-6.112zM63.04-9.454l2.766 1.597-42.22 73.128-2.766-1.597zm6.564 2.546l1.152.665-42.22 73.127-1.152-.665zM22.367-31.54l6.953 4.015-42.22 73.127-6.953-4.014zm8.125 4.675l2.706 1.562-42.22 73.127-2.706-1.562zm67.83 6.867l16.06 9.273-48.428 83.881-16.06-9.272zM115.911-9.84l2.677 1.546-48.43 83.881-2.676-1.545z'/>
                                        </g>
                                    </g>
                                    <path
                                        fill='#C7B299'
                                        fillRule='nonzero'
                                        d='M608.955 8.168h2.228v54.425h-2.228z'
                                    />
                                    <path
                                        fill='#EAD7C2'
                                        fillRule='nonzero'
                                        d='M719.139 8.15v2.228H608.056V8.15zm.017 52.2v2.227H608.073V60.35z'
                                    />
                                    <path
                                        fill='#EAD7C2'
                                        fillRule='nonzero'
                                        d='M716.911 8.168h2.228v54.425h-2.228zm-108.854 0h2.228v54.425h-2.228z'
                                    />
                                    <path
                                        fill='#D9EEFD'
                                        fillRule='nonzero'
                                        d='M723.386 8.168h47.296v54.425h-47.296z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M729.706 29.13v-1.347h13.935v1.347h3.195v31.669h-20.324V29.13z'
                                    />
                                    <path
                                        fill='#CCC'
                                        fillRule='nonzero'
                                        d='M757.283 60.799h-9.877v-7.584h9.877z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M738.703 40.734v-1.347h8.72v1.347h2.314v4.455h4.075v15.61h-17.423V40.734z'
                                    />
                                    <path
                                        fill='#D9EEFD'
                                        fillRule='nonzero'
                                        d='M799.674 68.828l-31.237-7.356V9.497l31.237-8.029z'
                                    />
                                    <g
                                        opacity='.4'
                                        transform='translate(768.403 1.381)'
                                    >
                                        <mask
                                            id='prefix__videomodul-j'
                                            fill='#fff'
                                        >
                                            <use xlinkHref='#prefix__videomodul-i'/>
                                        </mask>
                                        <g
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            mask='url(#prefix__videomodul-j)'
                                        >
                                            <path d='M53.532-3.854l10.587 6.112-42.22 73.127-10.587-6.112zM25.45-24.805l6.953 4.014-42.22 73.127-6.953-4.014zm8.107 4.675l2.707 1.562-42.22 73.127-2.707-1.563z'/>
                                        </g>
                                    </g>
                                    <path
                                        fill='#C7B299'
                                        fillRule='nonzero'
                                        d='M768.437 61.697v-2.228l33.465 7.581v2.227z'
                                    />
                                    <path
                                        fill='#C7B299'
                                        fillRule='nonzero'
                                        d='M769.335 8.168h2.228v54.425h-2.228z'
                                    />
                                    <path
                                        fill='#EAD7C2'
                                        fillRule='nonzero'
                                        d='M768.437 10.395V8.168L801.902.121v2.245zm0 52.182V60.35l33.465 7.58v2.245z'
                                    />
                                    <path
                                        fill='#EAD7C2'
                                        fillRule='nonzero'
                                        d='M801.902 70.175l-2.228-.898V1.019l2.228-.898zM768.437 8.168h2.228v54.425h-2.228z'
                                    />
                                    <path
                                        fill='#C7B299'
                                        fillRule='nonzero'
                                        d='M724.284 8.168h2.228v54.425h-2.228z'
                                    />
                                    <path
                                        fill='#EAD7C2'
                                        fillRule='nonzero'
                                        d='M770.665 8.168v2.228h-47.29V8.168zm0 52.199v2.228h-47.289v-2.228z'
                                    />
                                    <path
                                        fill='#EAD7C2'
                                        fillRule='nonzero'
                                        d='M768.437 8.168h2.228v54.425h-2.228zm-45.051 0h2.228v54.425h-2.228z'
                                    />
                                    <path
                                        fill='#B3B3B3'
                                        fillRule='nonzero'
                                        d='M562.056 68.949h22.897v50.297h-22.897z'
                                    />
                                    <path
                                        fill='#999'
                                        fillRule='nonzero'
                                        d='M563.645 71.349h19.719v14.886h-19.719zm0 15.558h19.719v14.886h-19.719zm0 15.593h19.719v14.886h-19.719z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M569.913 74.44h6.942v3.687h-6.942zm0 14.936h6.942v3.687h-6.942zm.035 15.386h6.942v3.687h-6.942z'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M563.749 47.434h5.992v21.843h-5.992z'
                                    />
                                    <path
                                        fill='#02A896'
                                        fillRule='nonzero'
                                        d='M565.026 48.573h3.436v11.364h-3.436z'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M577.269 47.434h5.992v21.843h-5.992z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M580.274 67.55a.898.898 0 1 0 0-1.795.898.898 0 0 0 0 1.795'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M580.274 67.326a.673.673 0 1 0 0-1.347.673.673 0 0 0 0 1.347'
                                    />
                                    <path
                                        fill='#F0F3BD'
                                        fillRule='nonzero'
                                        d='M578.564 48.573H582v11.364h-3.436z'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M570.552 47.434h5.992v21.843h-5.992z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M573.557 67.55a.898.898 0 1 0-.898-.898.88.88 0 0 0 .898.898'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M573.557 67.326a.656.656 0 0 0 .656-.674.673.673 0 1 0-1.33 0 .656.656 0 0 0 .674.674'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M571.83 48.573h3.436v11.364h-3.436z'
                                    />
                                    <path
                                        fill='#C7B299'
                                        fillRule='nonzero'
                                        d='M591.964 25.107a10.45 10.45 0 0 1-.967 4.455 10.723 10.723 0 1 1 .967-4.455z'
                                    />
                                    <circle
                                        cx='581.269'
                                        cy='25.108'
                                        r='9.722'
                                        fill='#998675'
                                        fillRule='nonzero'
                                        transform='rotate(-75.9 581.27 25.108)'
                                    />
                                    <circle
                                        cx='581.258'
                                        cy='25.107'
                                        r='9.307'
                                        fill='#FFF'
                                        fillRule='nonzero'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M581.88 25.107a.622.622 0 1 1-1.244 0 .622.622 0 0 1 1.243 0z'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M581.361 25.383a.276.276 0 0 1-.276-.276v-6.614a.276.276 0 1 1 .553 0v6.614a.276.276 0 0 1-.277.276z'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M581.361 25.383h-5.11a.276.276 0 0 1-.277-.276c0-.156.12-.284.276-.294h5.111c.156.01.277.138.277.294a.276.276 0 0 1-.277.276zm0-8.133a.12.12 0 0 1-.12-.103v-.743a.12.12 0 0 1 .241 0v.743a.12.12 0 0 1-.12.103zm-3.988 1.019a.104.104 0 0 1-.104-.052l-.363-.639a.12.12 0 1 1 .19-.12l.363.638a.12.12 0 0 1-.035.156l-.051.017zm-2.97 2.884h-.052l-.64-.38c-.05-.017-.068-.087-.034-.156a.12.12 0 0 1 .156-.034l.639.362a.12.12 0 0 1 .034.156.12.12 0 0 1-.103.052zm-1.105 3.954h-.743a.104.104 0 0 1-.104-.104.12.12 0 0 1 .104-.12h.743a.12.12 0 0 1 .103.12.104.104 0 0 1-.103.104zm.379 4.369a.104.104 0 0 1-.103-.052.12.12 0 0 1 .034-.156l.64-.362a.12.12 0 0 1 .12.19l-.639.362-.052.018zm3.143 3.229h-.052a.104.104 0 0 1-.052-.156l.363-.639a.12.12 0 0 1 .155-.034.104.104 0 0 1 .052.155l-.362.605c-.035.034-.07.069-.104.069zm4.334 1.208a.12.12 0 0 1-.12-.103v-.743a.12.12 0 0 1 .241 0v.743a.12.12 0 0 1-.12.103zm4.369-1.105a.086.086 0 0 1-.104-.069l-.362-.621a.12.12 0 1 1 .19-.121l.362.639a.12.12 0 0 1-.034.155l-.052.017zm3.212-3.16h-.052l-.64-.38c-.05-.017-.068-.086-.034-.155a.12.12 0 0 1 .156-.035l.639.363a.12.12 0 0 1 .034.155.12.12 0 0 1-.103.052zm1.225-4.334h-.742a.104.104 0 0 1-.103-.103.12.12 0 0 1 .103-.121h.743a.12.12 0 0 1 .103.12.104.104 0 0 1-.103.104zm-1.743-3.989a.104.104 0 0 1-.104-.051.12.12 0 0 1 .035-.156l.638-.362a.12.12 0 1 1 .121.19l-.639.362-.051.017zm-2.884-2.952h-.052a.104.104 0 0 1-.052-.156l.363-.639a.12.12 0 0 1 .207.121l-.362.64-.104.034z'
                                    />
                                    <path
                                        fill='#CCC'
                                        fillRule='nonzero'
                                        d='M797.481 74.216h42.875v44.866h-42.875z'
                                    />
                                    <path
                                        fill='#B3B3B3'
                                        fillRule='nonzero'
                                        d='M798.897 76.357h40.043v13.279h-40.043zm0 13.866h40.043v13.28h-40.043zm0 13.883h40.043v13.28h-40.043z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M815.819 79.102h6.182v3.289h-6.182zm0 13.331h6.182v3.289h-6.182zm0 13.727h6.182v3.289h-6.182z'
                                    />
                                    <path
                                        fill='gray'
                                        fillRule='nonzero'
                                        d='M830.773 63.268h-23.708a.276.276 0 0 0-.277.276v10.499a.276.276 0 0 0 .277.293h23.708a.276.276 0 0 0 .276-.293V63.544a.276.276 0 0 0-.276-.276'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M810.967 66.1h15.921v6.387h-15.921z'
                                    />
                                    <path
                                        fill='#1A1A1A'
                                        fillRule='nonzero'
                                        d='M811.502 71.522h14.677v1.003h-14.677z'
                                    />
                                    <path
                                        fill='#F0F3BD'
                                        fillRule='nonzero'
                                        d='M809.085 65.875h-.5a.104.104 0 0 1-.104-.103v-.276a.104.104 0 0 1 .103-.087h.5c.07 0 .105.035.105.087v.276a.086.086 0 0 1-.104.103'
                                    />
                                    <path
                                        fill='#00C3A0'
                                        fillRule='nonzero'
                                        d='M809.171 64.563h-1.226a.104.104 0 0 1-.103-.104v-.276a.104.104 0 0 1 .103-.103h1.226c.057 0 .104.046.104.103v.276a.104.104 0 0 1-.104.104'
                                    />
                                    <path
                                        fill='#666'
                                        fillRule='nonzero'
                                        d='M825.437 55.929H812.4l-.224 7.339h13.486z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M813.436 54.911l-.276 8.357h11.517l-.276-8.357zm12.053 16.922h-13.141l-1.761 4.886h16.663z'
                                    />
                                    <path
                                        fill='#23394C'
                                        fillRule='nonzero'
                                        d='M812.953 72.696l-.12.363h12.07l-.174-.363zm-.173 1.001l-.12.363h12.397l-.156-.363zm-.328 1.02l-.12.362h12.898l-.173-.362z'
                                        opacity='.23'
                                    />
                                    <path
                                        fill='#05668E'
                                        fillRule='nonzero'
                                        d='M853.998 43.1h13.658a3.194 3.194 0 0 1 3.177 3.177v24.485a3.194 3.194 0 0 1-3.177 3.177h-.328a3.4 3.4 0 0 1 .035.415 2.158 2.158 0 0 1-2.159 2.158h-8.754a2.158 2.158 0 0 1-2.159-2.158c-.006-.14.005-.279.035-.415h-.328a3.194 3.194 0 0 1-3.178-3.177V46.277a3.194 3.194 0 0 1 3.178-3.177'
                                        opacity='.1'
                                    />
                                    <path
                                        fill='#05668E'
                                        fillRule='nonzero'
                                        d='M850.993 69.519h19.668a1.33 1.33 0 0 1 0 2.66h-19.668a1.33 1.33 0 0 1 0-2.66m0-21.049h19.668a1.33 1.33 0 0 1 0 2.659h-19.668a1.33 1.33 0 0 1 0-2.66m0 5.768h19.668a1.33 1.33 0 0 1 0 2.66h-19.668a1.33 1.33 0 0 1 0-2.66'
                                        opacity='.2'
                                    />
                                    <path
                                        fill='#B3B3B3'
                                        fillRule='nonzero'
                                        d='M847.298 75.217h27.075v43.868h-27.075z'
                                    />
                                    <path
                                        fill='#CCC'
                                        fillRule='nonzero'
                                        d='M871.472 80.45v2.261a1.813 1.813 0 0 0-1.813-1.813h-17.682a1.83 1.83 0 0 0-1.813 1.813V80.45a1.83 1.83 0 0 1 1.813-1.813h17.682a1.83 1.83 0 0 1 1.813 1.813'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M871.472 82.711v13.745a1.813 1.813 0 0 1-1.813 1.813h-17.682a1.83 1.83 0 0 1-1.813-1.813V82.711a1.83 1.83 0 0 1 1.813-1.813h17.682c1.002 0 1.813.812 1.813 1.813'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M855.155 84.732h2.366v7.447h-2.366z'
                                    />
                                    <path
                                        fill='#018090'
                                        fillRule='nonzero'
                                        d='M855.155 85.75h2.366v1.247h-2.366z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M864.134 84.732h2.366v7.447h-2.366z'
                                    />
                                    <path
                                        fill='#00C3A0'
                                        fillRule='nonzero'
                                        d='M864.134 85.75h2.366v1.247h-2.366z'
                                    />
                                    <g opacity='.5'>
                                        <path
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            d='M864.134 43.1h3.47v32.109h-3.47zm-4.3 0h2.038v32.109h-2.038z'
                                            opacity='.62'
                                        />
                                    </g>
                                    <path d='M673.414 20.98h37.211v68.144h-37.211z'/>
                                    <path
                                        fill='#01544B'
                                        fillRule='nonzero'
                                        d='M751.498 90.033l.034 2.244.035 2.245c0 .76.017 1.502.017 2.262s-.034 1.485-.086 2.245h-.017a48.94 48.94 0 0 1-.069-4.507l.034-2.245.035-2.244h.017zm2.4-13.953l.052 11.466v5.75c-.017 1.9-.035 3.816-.086 5.733h-.018c-.069-1.917-.069-3.833-.086-5.733v-5.75l.069-11.465h.07zm2.228 22.914l-.07-8.823c-.017-1.468-.034-2.936-.017-4.403.017-1.468.035-2.936.087-4.404h.017c.052 1.468.069 2.936.086 4.404.017 1.467-.017 2.935-.017 4.403l-.07 8.823h-.016z'
                                    />
                                    <path
                                        fill='#35B9AB'
                                        fillRule='nonzero'
                                        d='M751.48 90.24s-.138-15.834-17.733-17.613c0 0 .259 17.25 17.734 17.613m4.645-8.807s12.864-.12 14.297-14.539c0 0-14.004.207-14.297 14.54m-15.023-17.838s.103 11.656 12.899 12.95c0 0-.173-12.69-12.9-12.95'
                                    />
                                    <path
                                        fill='#027E71'
                                        fillRule='nonzero'
                                        d='M734.748 73.128l2.142 2.09 2.123 2.106 4.214 4.248 4.161 4.3 2.055 2.175c.69.725 1.364 1.45 2.038 2.193l-2.142-2.09-2.123-2.106-4.196-4.248-4.162-4.3-2.072-2.175c-.673-.725-1.364-1.45-2.038-2.193m6.545-9.532a141.076 141.076 0 0 1 3.298 3.126l3.246 3.194c1.07 1.07 2.141 2.141 3.195 3.229a286.312 286.312 0 0 1 3.125 3.298 186.28 186.28 0 0 1-3.298-3.125c-1.088-1.054-2.158-2.124-3.229-3.195-1.07-1.07-2.141-2.14-3.195-3.229a283.382 283.382 0 0 1-3.142-3.298m14.833 18.044l1.76-1.864 1.762-1.865c1.174-1.243 2.383-2.47 3.557-3.713l3.592-3.66 1.813-1.83 1.813-1.814-1.744 1.882-1.779 1.865-3.557 3.695-3.591 3.678-1.796 1.814-1.83 1.813'
                                    />
                                    <path
                                        fill='#818487'
                                        fillRule='nonzero'
                                        d='M741.155 98.062h25.694l-6.096 21.187h-13.486z'
                                    />
                                    <path
                                        fill='#CCC'
                                        fillRule='nonzero'
                                        d='M616.345 119.94h-2.175l-.95-40.095h4.075z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M724.077 130.836h2.107l-7.356-51.285h-3.954zm-120.941 0h-2.107l7.339-51.285h3.971z'
                                    />
                                    <path
                                        fill='#CCC'
                                        fillRule='nonzero'
                                        d='M710.09 119.94h2.176l.95-40.095h-4.075z'
                                    />
                                    <path
                                        fill='#DDBF9E'
                                        fillRule='nonzero'
                                        d='M604.172 69.553l-15.99 8.669h150.849l-17.164-8.669z'
                                    />
                                    <path
                                        fill='#F1E5D8'
                                        fillRule='nonzero'
                                        d='M588.182 78.239h150.849v2.262H588.182z'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M688.679 64.494h-50.145a1.105 1.105 0 0 1-1.105-1.122v-4.18h52.355v4.18c0 .613-.492 1.113-1.105 1.122'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M689.784 59.28H637.43V29.716a1.02 1.02 0 0 1 1.019-1.036h50.317a1.019 1.019 0 0 1 1.02 1.036V59.28zm-17.647 9.842h-3.057v-4.628h-10.74v4.628h-3.246c-.859 0-1.555.695-1.555 1.554h20.134a1.537 1.537 0 0 0-1.536-1.554'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M664.66 61.904a1.088 1.088 0 1 1-2.176.034 1.088 1.088 0 0 1 2.176-.034'
                                    />
                                    <path
                                        fill='#CCE6E9'
                                        fillRule='nonzero'
                                        d='M639.397 30.771h48.418V57.19h-48.418z'
                                    />
                                    <g
                                        opacity='.6'
                                        transform='translate(639.242 30.736)'
                                    >
                                        <mask
                                            id='prefix__videomodul-l'
                                            fill='#fff'
                                        >
                                            <use xlinkHref='#prefix__videomodul-k'/>
                                        </mask>
                                        <g
                                            fillRule='nonzero'
                                            mask='url(#prefix__videomodul-l)'
                                        >
                                            <path
                                                fill='#02A896'
                                                d='M50.507 5.577c-8.961 11.742-14.4 13.383-17.733 12.588-3.834-.932-4.075-4.904-9.325-6.63-5.68-1.882-8.582 1.692-15.16.673-2.712-.432-6.718-1.727-11.242-6.095.058 8.023.104 16.053.138 24.088h53.858c-.173-8.196-.351-16.404-.536-24.624z'
                                            />
                                            <path
                                                fill='#02A896'
                                                d='M50.093 10.309c-10.309 9.445-15.8 10.24-18.942 8.927-1.952-.812-3.212-2.504-6.631-3.246-3.42-.743-6.372.241-8.927.811-3.454.777-8.634 1.002-15.835-1.761v15.092h50.076l.259-19.823z'
                                            />
                                            <path
                                                fill='#FFF'
                                                d='M50.093 10.309c-10.309 9.445-15.8 10.24-18.942 8.927-1.952-.812-3.212-2.504-6.631-3.246-3.42-.743-6.372.241-8.927.811-3.454.777-8.634 1.002-15.835-1.761v15.092h50.076l.259-19.823z'
                                                opacity='.3'
                                            />
                                            <path
                                                fill='#02A896'
                                                d='M48.884 11.932c-2.003 2.452-6.147 6.82-12.864 9.342-2.487.932-9.704 3.643-17.043.95-1.969-.726-2.09-1.158-3.92-1.9-5.56-2.245-11.534-1.157-16.646.811v8.997h50.404l.07-18.2z'
                                            />
                                            <path
                                                fill='#000'
                                                d='M48.884 11.932c-2.003 2.452-6.147 6.82-12.864 9.342-2.487.932-9.704 3.643-17.043.95-1.969-.726-2.09-1.158-3.92-1.9-5.56-2.245-11.534-1.157-16.646.811v8.997h50.404l.07-18.2z'
                                                opacity='.1'
                                            />
                                        </g>
                                    </g>
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M656.25 30.788l-16.853 17.146v-9.566l7.287-7.58zm5.561 0l-22.414 23.138v-4.282l18.563-18.856z'
                                        opacity='.45'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M639.207 74.924h48.798l-3.678-3.299h-41.442z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M641.262 74.319l2.331-2.089h40.026l2.332 2.089z'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M663.693 74.665v-2.78h-.19v2.78m-2.331.017l.328-2.797-.19-.018-.328 2.798m-2.331.034l.656-2.797-.19-.035-.656 2.78m-2.331.052l.967-2.78-.173-.069-.967 2.797m-2.348.069l1.295-2.797-.173-.086-1.295 2.797m-2.366.086l1.623-2.78-.155-.103-1.623 2.797m-2.366.104l1.951-2.798-.155-.103-1.951 2.78m-2.366.121l2.262-2.798-.138-.121-2.279 2.798m-2.383.121l2.607-2.78-.138-.139-2.607 2.798m22.361-2.728l.328 2.797.19-.017-.328-2.798m2.003.035l.656 2.797.173-.052-.639-2.78m2.02.052l.967 2.78.173-.052-.967-2.797m2.02.069l1.295 2.797.173-.086-1.295-2.797m2.037.103l1.624 2.78.155-.086-1.623-2.797m2.037.103l1.952 2.798.155-.121-1.951-2.78m2.055.103l2.262 2.798.155-.121-2.279-2.798m2.055.139l2.607 2.78.138-.121-2.607-2.798'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M641.832 73.37h43.756v-.19h-43.756'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M639.207 74.924h48.781v1h-48.781z'
                                    />
                                    <path
                                        fill='#1A1A1A'
                                        d='M645.406 132.614a2.09 2.09 0 1 0 0-4.179 2.09 2.09 0 0 0 0 4.18zm21.706 0a2.09 2.09 0 1 0 0-4.179 2.09 2.09 0 0 0 0 4.18z'
                                    />
                                    <path
                                        fill='#B3B3B3'
                                        fillRule='nonzero'
                                        d='M644.733 110.909h3.695v14.303h-3.695z'
                                    />
                                    <path
                                        fill='#333'
                                        d='M644.923 123.428h3.315a.829.829 0 0 1 .83.846v1.502l16.87.985a2.314 2.314 0 0 1 2.175 2.313h-43.065a2.314 2.314 0 0 1 2.176-2.313l16.87-.985v-1.502c0-.46.368-.837.829-.846z'
                                    />
                                    <path
                                        fill='#999'
                                        d='M658.547 99.858a4.023 4.023 0 0 1-4.023 4.023h-3.488v6.82a.932.932 0 0 1-.95.933h-.518v3.73a.846.846 0 0 1-.829.829h-4.317a.846.846 0 0 1-.829-.829v-3.73h-.518a.932.932 0 0 1-.95-.932v-6.82h-3.47a4.023 4.023 0 0 1-4.023-4.024h23.915z'
                                    />
                                    <path
                                        fill='#1A1A1A'
                                        fillRule='nonzero'
                                        d='M624.461 130.525a2.09 2.09 0 1 0 4.179 0h-4.179z'
                                    />
                                    <path
                                        fill='#000'
                                        fillRule='nonzero'
                                        d='M626.55 128.435a2.09 2.09 0 0 0-2.089 2.09h4.179a2.09 2.09 0 0 0-2.09-2.09zm18.856 0a2.09 2.09 0 0 0-2.089 2.09h4.179a2.107 2.107 0 0 0-2.09-2.09zm21.706 0a2.09 2.09 0 0 0-2.09 2.09h4.18a2.09 2.09 0 0 0-2.09-2.09z'
                                    />
                                    <path
                                        fill='gray'
                                        fillRule='nonzero'
                                        d='M642.143 103.881h1.468v7.753h-.587a.88.88 0 0 1-.881-.88v-6.873z'
                                    />
                                    <path
                                        fill='gray'
                                        fillRule='nonzero'
                                        d='M643.61 111.634h1.468v4.542h-.587a.88.88 0 0 1-.88-.881v-3.66z'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M614.55 60.505a2.815 2.815 0 0 1 2.659-3.764h14.66s9.497 0 14.763 20.98c0 0 3.264 18.234 16.819 17.613h5.888a2.47 2.47 0 0 1 2.47 2.469 2.47 2.47 0 0 1-2.47 2.487h-35.26a13.745 13.745 0 0 1-13.659-12.174l-1.156-10.05a23.156 23.156 0 0 0-.467-2.659c-.639-2.745-2.21-9.152-4.247-14.902z'
                                    />
                                    <path
                                        fill='#1A1A1A'
                                        fillRule='nonzero'
                                        d='M614.55 60.505l13.4-.345a12.553 12.553 0 0 1 11.551 6.769c1.07 2.055 2.124 4.714 3.056 8.133 0 0-16.956-12.882-24.45-2.487l-3.557-12.07zm8.08 33.637s7.961 4.663 26.368-.846c0 0 3.04 4.144 9.255 4.576h7.046a5.56 5.56 0 0 1 3.971 1.658l.656.673-35.847.07s-7.46.155-11.448-6.13z'
                                        opacity='.5'
                                    />
                                    <path
                                        fill='#F79470'
                                        fillRule='nonzero'
                                        d='M739.549 103.57s6.182-6.458 7.2-6.026c1.02.432.294 2.918.294 2.918l-6.614 4.265-.88-1.157z'
                                    />
                                    <path
                                        fill='#F79470'
                                        fillRule='nonzero'
                                        d='M730.19 103.329l6.751.38s5.94-2.435 8.064-2.642c2.487-.242 3.126.897 1.796 3.073-.639 1.036-2.694 2.97-3.799 3.989-.466.414.156 1.364-.466 1.347l-1.036.052c-1.606.051-2.711-1.416-4.213-2.003l-9.722.293s-1.312-2.348 1.123-5.25'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M738.91 104.83c-.69 0-.846-.759-.933-1.225-.086-.5-.241-1.312.64-1.485l.863-.156.034.242.397 2.124.277.276-1.07.19-.208.035zm-.5-1.311c.137.742.275.915.638.846l.414-.07-.345-1.83-.414.087c-.363.069-.432.259-.294.967z'
                                    />
                                    <path
                                        fill='#B2B5B8'
                                        fillRule='nonzero'
                                        d='M751.55 102.534l-.518-2.78a.207.207 0 0 0-.242-.155l-2.487.466a.207.207 0 0 0-.172.242l.518 2.78c.033.11.145.178.259.155l2.486-.466a.19.19 0 0 0 .156-.242'
                                    />
                                    <path
                                        fill='#DEDEE0'
                                        fillRule='nonzero'
                                        d='M749.65 100.928v-.086a.19.19 0 0 1 .138-.224l.553-.104c.1-.01.19.057.207.155v.087a.173.173 0 0 1-.155.207l-.536.104a.173.173 0 0 1-.207-.139m.207 1.122v-.086a.173.173 0 0 1 .139-.207l.552-.103a.173.173 0 0 1 .207.138v.086a.207.207 0 0 1-.138.225l-.552.086a.155.155 0 0 1-.208-.138'
                                    />
                                    <path
                                        fill='#02A896'
                                        fillRule='nonzero'
                                        d='M741.086 100.911l-.018-.052 7.374-1.416.794 4.248-7.339 1.399-.034-.035z'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M738.996 103.38c-.224-1.174-.293-2.072.88-2.296l1.054-.207h.138v.051l.777 4.145.035.034-1.226.242c-1.174.224-1.45-.777-1.675-1.951'
                                    />
                                    <path
                                        fill='#DEDEE0'
                                        fillRule='nonzero'
                                        d='M748.442 101.723l-.432.293c-.052.052-.07.035-.07-.034 0-.07-.051-.087-.12-.07l-.88.156c.068.07.103.155.172.207a.155.155 0 0 0 .242-.052c0-.034.017-.034.051-.034.035 0 .294-.052.311.207.018.26.018.035 0 .052-.017.017-.293.086-.31.052-.018-.035-.07-.052-.104-.052a.259.259 0 0 1-.363-.138.345.345 0 0 0-.466-.173l-.138.035c-.104.017-.19.017-.207.138a.19.19 0 0 1-.156.12c-.224.035-.38-.086-.362-.276a.242.242 0 0 1 .172-.241.259.259 0 0 1 .311.069.207.207 0 0 0 .328-.087l.104-.293a.12.12 0 0 1 .138-.104l.207-.034a.259.259 0 0 0 .173-.104.207.207 0 0 1 .293-.052.224.224 0 0 1-.08.392.207.207 0 0 1-.161-.03c-.138-.137-.277-.016-.397-.016a1.33 1.33 0 0 0-.121.276c.017.034.051.017.069.017l1.174-.207c.104-.017.052-.07.052-.12 0-.053-.018-.087.069-.07l.449.12c.017.018.052.018.052.053'
                                    />
                                    <path
                                        fill='#F79470'
                                        fillRule='nonzero'
                                        d='M748.51 104.088s-.604-1.226-2.054-2.607c-.88-.846-1.52-1.468-2.47-1.019-.085.133-.15.279-.19.432a.57.57 0 0 0 .052.587c.328.449.328 1.088 2.936 3.954 0 0-1.399-2.486-2.987-3.816-.518-.449-1.244-.863-1.693-.88a.639.639 0 0 0-.587.448c-.052.104-.069.087-.034.242.259 1.278 2.504 3.903 2.504 3.903s-3.8-5.837-3.903-2.504c-.017.518.518.811.708 1.26.984 2.47 3.989 4.093 3.989 4.093s-2.728-3.316-4.438-4.317c-.69-.415-1.278.207-1.157.811.397 2.124 3.35 4.8 3.35 4.8s3.419.104 4.075-1.156c.656-1.26 3.091-2.47 1.9-4.23'
                                    />
                                    <path
                                        fill='#4A263D'
                                        fillRule='nonzero'
                                        d='M743.848 101.62l2.383 2.59s-1.744-1.227-2.383-2.59m-2.038.724l3.109 3.661s-2.521-2.28-3.108-3.66m-1.174 1.605l2.762 2.78s-2.158-1.398-2.762-2.78m-1.399 1.07l2.573 2.712s-1.554-1.157-2.435-2.47l-.138-.241'
                                    />
                                    <path
                                        fill='#4A263D'
                                        fillRule='nonzero'
                                        d='M739.86 105.832a3.384 3.384 0 0 1-.57-.725c-.328-.5-.26-2.037 1.347-1.157l.155.138a2.314 2.314 0 0 0-.88-.276c-.398-.017-.691.276-.64.57.087.587.536 1.312.536 1.312l.052.138zm4.455-3.574a4.956 4.956 0 0 1-.553-.725c-.31-.501.225-2.245 2.124-.64l-.725-.379s-1.174-.5-1.313.38c-.103.587.415 1.226.415 1.226l.052.138z'
                                    />
                                    <path
                                        fill='#4A263D'
                                        fillRule='nonzero'
                                        d='M742.104 102.776a5.25 5.25 0 0 1-.518-.725c-.31-.501-.224-2.608 2.4-.294l-1.122-.673a1.675 1.675 0 0 0-.621-.19c-.363-.035-.743.103-.691.414.11.468.279.92.5 1.347l.052.121z'
                                    />
                                    <path
                                        fill='#02A896'
                                        fillRule='nonzero'
                                        d='M691.666 75.252s15.54 6.855 28.82 21.808c7.856 2.85 12.277 2.176 11.828 2.78-.45.605-.726 13.124-.726 13.124l-13.157-4.835s-24.106-15.575-27.628-21.308c-3.523-5.733.863-11.57.863-11.57z'
                                    />
                                    <path
                                        fill='#044D6B'
                                        fillRule='nonzero'
                                        d='M675.072 242.055l-3.108.225s-13.054-90.533-11.69-107.076c9.341-4.196 15.575 8.15 15.575 8.15l-.587 50.18-.19 48.521zm8.409-.328l3.143.536s9.808-96.111 7.891-112.584c-9.462-3.782-14.97 12.363-14.97 12.363l2.071 50.11 1.865 49.575z'
                                    />
                                    <path
                                        fill='#044D6B'
                                        fillRule='nonzero'
                                        d='M687.59 114.69l6.925 14.989s.19 13.9-18.666 13.676c-18.856-.225-16.162-9.031-15.955-11.017.207-1.986 5.715-16.56 5.715-16.56s17.907-4.403 21.982-1.087z'
                                    />
                                    <path
                                        fill='#000'
                                        fillRule='nonzero'
                                        d='M691.666 75.252s15.54 6.855 28.82 21.808c7.856 2.85 12.277 2.176 11.828 2.78-.45.605-.726 13.124-.726 13.124l-13.157-4.835s-24.106-15.575-27.628-21.308c-3.523-5.733.863-11.57.863-11.57z'
                                        opacity='.1'
                                    />
                                    <path
                                        fill='#F0F3BD'
                                        fillRule='nonzero'
                                        d='M690.25 120.579c-4.99 2.624-6.026 7.477-25.124 1.053-3.54-18.165-3.04-42.754-3.04-42.754s11.691-9.618 32.084-1.71c-.725 22.43-3.92 43.41-3.92 43.41'
                                    />
                                    <path
                                        fill='#02A896'
                                        fillRule='nonzero'
                                        d='M671.377 136.016s-12.122-.933-14.367.19c2.711-7.408 6.545-15.472 6.545-15.472l-4.23-25.383 1.104-8.53c.864-2.608.052-5.06 2.245-6.752 2.9-2.227 4.628-5.422 5.025-5.422.673 0 1.951 31.617 1.951 31.617l1.727 29.752zm26.695-1.071c-.673 0-8.53.656-8.53.656l-1.675-14.02s-2.676-43.981-1.312-46.45c7.39.69 7.615 2.037 7.615 2.037l-.242 10.309s3.195 4.576 3.108 8.547c-.086 3.972-3.367 6.717-3.367 6.717l-3.419 17.838 7.822 14.366z'
                                    />
                                    <path
                                        fill='#000'
                                        fillRule='nonzero'
                                        d='M692.409 102.62c-1.675.38-3.35.674-5.06.916a84.85 84.85 0 0 1-5.094.587c-1.711.138-3.429.19-5.145.155-.861-.01-1.72-.074-2.573-.19a11.207 11.207 0 0 1-2.504-.57c.827.198 1.671.32 2.521.363.846.052 1.71.052 2.556.052.846 0 1.71-.035 2.555-.086l2.556-.173c1.692-.138 3.401-.31 5.094-.484l5.094-.57z'
                                        opacity='.1'
                                    />
                                    <path
                                        fill='#701212'
                                        fillRule='nonzero'
                                        d='M693.168 68.742s-6.527-11.794 2.331-20.928c-16.3-3.99-26.47-4.49-26.47-4.49s4.765 18.684-2.763 22.828c4.835 5.11 13.745 10.982 26.902 2.59'
                                    />
                                    <path
                                        fill='#F79470'
                                        fillRule='nonzero'
                                        d='M690.475 75.89L679.13 89.723l-9.843-15.904s5.94-1.105 6.182-4.35c.242-3.247-.725-12.036-.725-12.036l8.668 2.296s-.172 5.733.052 9.825c.224 4.093 4.196 6.372 7.01 6.338'
                                    />
                                    <path
                                        fill='#000'
                                        fillRule='nonzero'
                                        d='M674.761 59.486s1.399-.984 9.29.536c-.414 1.398-.777 4.11-.622 6.769-2.814 1.26-5.162 2.037-7.873 1.191-.19-3.85-.795-8.496-.795-8.496'
                                        opacity='.1'
                                    />
                                    <path
                                        fill='#F79470'
                                        fillRule='nonzero'
                                        d='M690.734 49.437c.224 5.335-2.815 14.694-10.775 17.457-9.912-4.23-9.774-17.733-9.774-17.733s.881-9.998 2.815-11.19c1.934-1.191 16.335-1.053 16.525-1.036.19.017 1.209 12.502 1.209 12.502'
                                    />
                                    <path
                                        fill='#F79470'
                                        fillRule='nonzero'
                                        d='M671.394 55.947c-3.022-1.278-4.662-2.867-3.66-4.697 1-1.83 2.745.155 2.745.155l.915 4.542z'
                                    />
                                    <path
                                        fill='#851F1A'
                                        fillRule='nonzero'
                                        d='M691.286 49.299c-4.42-2.09-4.058-7.84-3.488-9.998-2.866 5.266-12.225 6.613-17.25 4.645-2.901 1.312-.345 4.334-.086 5.715.259 1.382-.208 6.77-4.3 7.943.518-4.127-.967-7.77-2.918-9.065s.362-8.91 2.124-9.584c1.761-.673 3.695-1.882 4.593-4.49.898-2.607 8.513-7.2 13.486-4.04 7.27-.69 11.224.674 11.19 7.2 4.869 2.815 4.575 5.475 3.988 8.376-2.867 1.692-4.317 6.389-4.317 6.389l-3.885-.156.863-2.935z'
                                    />
                                    <path
                                        fill='#4A263D'
                                        fillRule='nonzero'
                                        d='M676.643 58.381c.333-.197.704-.32 1.088-.362.392-.054.791-.03 1.174.069.38.103 2.038.449 2.02.846-.396.069-.604.104-2.14-.363a6.493 6.493 0 0 0-1.037-.172h-1.105m-1.519-8.582a1.198 1.198 0 1 0 2.383.259 1.209 1.209 0 0 0-1.07-1.33 1.191 1.191 0 0 0-1.313 1.07m10.239.536a1.209 1.209 0 1 0 2.404.256 1.209 1.209 0 0 0-2.404-.256'
                                    />
                                    <path
                                        stroke='#59081C'
                                        strokeWidth='.713'
                                        d='M682.722 50.352c-.225 2.09 1.122 3.937 2.987 4.144 1.865.207 3.574-1.33 3.799-3.402.224-2.072-1.105-3.937-2.97-4.144-1.865-.207-3.592 1.33-3.816 3.402zm-10.775-1.174c-.225 2.09 1.105 3.937 2.987 4.144 1.882.207 3.574-1.33 3.799-3.402.224-2.072-1.105-3.937-2.988-4.144-1.882-.207-3.574 1.33-3.798 3.402zm10.775 1.174c-.726-1.658-2.539-2.262-3.99-.432'
                                    />
                                    <path
                                        fill='#4A263D'
                                        fillRule='nonzero'
                                        d='M688.472 51.094a9.808 9.808 0 0 0-.795-.863 2.625 2.625 0 0 0-.88-.535 2.003 2.003 0 0 0-1.02-.087c-.383.068-.763.154-1.139.26.283-.314.63-.561 1.019-.726a2.055 2.055 0 0 1 1.33-.017c.426.146.795.424 1.053.794.24.349.39.753.432 1.174m-10.585-1.813a7.96 7.96 0 0 0-1.14-.241 2.59 2.59 0 0 0-1.036.034 2.28 2.28 0 0 0-.88.518c-.282.272-.548.56-.795.864a2.7 2.7 0 0 1 .432-1.175 1.9 1.9 0 0 1 1.07-.777c.44-.116.905-.092 1.33.07.39.151.74.394 1.019.707'
                                    />
                                    <path
                                        fill='#F79470'
                                        fillRule='nonzero'
                                        d='M689.249 56.88c3.315-.778 4.938-2.66 4.783-4.387-.242-2.728-3.61-.259-3.61-.259l-1.173 4.645z'
                                    />
                                    <path
                                        fill='#4A263D'
                                        fillRule='nonzero'
                                        d='M680.977 49.834c-.055.361-.049.729.018 1.088.067.335.178.66.328.967.161.313.346.613.552.898.21.276.435.542.674.794l.155.173-.103.19c-.166.228-.35.442-.553.638-.196.19-.417.353-.656.484a2.573 2.573 0 0 1-.777.259 1.83 1.83 0 0 1-.812-.035c.257-.03.508-.1.743-.207.223-.089.427-.218.604-.38.193-.138.367-.3.518-.483.155-.183.294-.38.415-.587l.051.362a4.559 4.559 0 0 1-.742-.88 3.833 3.833 0 0 1-.535-1.019 3.022 3.022 0 0 1-.19-1.157c.013-.388.12-.767.31-1.105'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M679.7 89.1c2.383-9.531 9.566-17.25 11.707-16.11 2.141 1.14 4.8 8.633 4.8 8.633S687.92 78.135 679.7 89.1'
                                    />
                                    <path
                                        fill='#F79470'
                                        fillRule='nonzero'
                                        d='M665.679 115.33s8.823 1.001 10.723.241c1.899-.76 7.615-1.33 8.858-1.727 1.243-.397 2.866-2.365 3.557-2.452.69-.086.38 3.506-3.212 4.887 0 0-.276.518.69.622.968.103 4.076 1.606 5.578.432 1.503-1.175 2.418-.83 2.47-.501.051.328.431 2.055-.139 2.52-.57.467-.863 1.14-2.003 2.142-1.14 1.001-.828 1.433-3.298 1.209-2.469-.225-5.905-.622-6.924-1.399-.328-.242-3.643-2.711-5.854-2.348-4.558.777-8.979 1.33-9.704 1.623'
                                    />
                                    <path
                                        fill='#4A263D'
                                        fillRule='nonzero'
                                        d='M694.239 118.49c-.57.103-1.157.19-1.744.258-.587.07-1.157.121-1.744.173-.587.052-1.174.07-1.761.07a14.867 14.867 0 0 1-1.762-.07l1.744-.069 1.762-.12 1.744-.122 1.76-.12m-1.087 1.76c-.496.112-.997.199-1.502.26-.518.069-1.019.103-1.52.138-.5.034-1.019.052-1.537.052a11.258 11.258 0 0 1-1.52-.121c.519 0 1.02 0 1.52-.035l1.52-.069 3.04-.224zm-1.692 1.78a14.97 14.97 0 0 1-1.52.103c-.518.034-1.018.017-1.536.017l-1.52-.103a15.178 15.178 0 0 1-1.502-.26l1.52.104 1.519.07 3.039.068z'
                                    />
                                    <path
                                        fill='#02A896'
                                        fillRule='nonzero'
                                        d='M669.046 113.827l1.14 8.634s-16.215-2.348-17.01-4.662c-.534-1.537.743-17.285 3.506-27.231 1.865-6.752 4.87-10.792 5.25-11.621 1.036-2.28 6.337-3.678 6.32 3.074-.018 6.751-6.269 29.371-6.269 29.371l7.063 2.435z'
                                    />
                                    <path
                                        fill='#000'
                                        fillRule='nonzero'
                                        d='M669.046 113.827l1.14 8.634s-16.215-2.348-17.01-4.662c-.534-1.537.743-17.285 3.506-27.231 1.865-6.752 4.87-10.792 5.25-11.621 1.036-2.28 6.337-3.678 6.32 3.074-.018 6.751-6.269 29.371-6.269 29.371l7.063 2.435z'
                                        opacity='.1'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M678.128 88.876c.501 1.036-6.25-13.59-8.927-15.282-5.06 1.002-7.65 8.288-7.65 8.288s12.122-2.33 16.577 6.994'
                                    />
                                    <path
                                        fill='#35B9AB'
                                        fillRule='nonzero'
                                        d='M612.581 48.556s-3.868 9.428-3.264 20.255l2.193-1.951s.363-13.158 1.071-18.304'
                                    />
                                    <path
                                        fill='#35B9AB'
                                        fillRule='nonzero'
                                        d='M606.917 48.349s-1.9 10.015.864 20.514l1.76-2.366s-2.296-12.95-2.624-18.148'
                                    />
                                    <path
                                        fill='#027E71'
                                        fillRule='nonzero'
                                        d='M606.917 48.349s-.017.069-.034.19c-.087 4.68.069 9.428.725 14.073.224 1.588.5 3.194 1.019 4.731h.034c.02 0 .035-.015.035-.034-1.002-2.901-1.26-6.061-1.485-9.083a117.8 117.8 0 0 1-.276-8.185V48.66c0-.121-.018-.225-.018-.328m5.664.224l-.052.121c-.829 4.783-1.588 9.583-2.106 14.401a29.165 29.165 0 0 0-.242 3.885h.052c-.07-2.676.414-5.404.777-8.046.362-2.642.829-5.681 1.277-8.513l.208-1.174a6.9 6.9 0 0 1 .086-.674'
                                    />
                                    <path
                                        fill='#ACB0B4'
                                        fillRule='nonzero'
                                        d='M611.787 72.575h-5.232l-.795-7.511h7.115z'
                                    />
                                    <path
                                        fill='#ABABAE'
                                        d='M708.744 60.574l-.812-.086.276-.863c.018-.173.484-.139.467.051l.069.898z'
                                    />
                                    <path
                                        fill='#59595B'
                                        d='M708.916 61.213l-1.226-.12.087-1.02c0-.034.034-.069.069-.103a.086.086 0 0 1 .103-.035l.933.087c.04.003.077.022.103.052.02.037.031.078.035.12l-.104 1.02z'
                                    />
                                    <path
                                        fill='#383839'
                                        d='M706.775 70.348l.967-9.929 1.243.121-.967 9.911z'
                                    />
                                    <path
                                        fill='#404041'
                                        d='M707.379 70.4l.933-9.929.673.069-.967 9.911z'
                                    />
                                    <path
                                        fill='#ABABAE'
                                        d='M708.968 60.592l.017-.156-1.364-.121-.017-.017-.086.035-.035.086-.259 2.642.138.017.121-.017.069-.622-.121-.172.173-1.693.034-.069.087-.034z'
                                    />
                                    <path
                                        fill='#E4E6E7'
                                        fillRule='nonzero'
                                        d='M702.388 61.44l.963-.267 2.939 10.596-.964.268z'
                                    />
                                    <path
                                        fill='#CFD0D2'
                                        fillRule='nonzero'
                                        d='M702.724 61.35l.964-.268 2.938 10.596-.963.267z'
                                    />
                                    <path
                                        fill='#43525A'
                                        d='M703.304 61.748h.07l.051.19h-.052l-.259.07-.5.138.639 2.313h-.052c-.035-.017-.035 0-.19-.448-.156-.45-.656-1.865-.69-2.055-.19-1.123-.277-2.02-.519-3.281-.017-.086.38-.207.414-.121l1.088 3.194z'
                                    />
                                    <path
                                        fill='#666'
                                        d='M705.618 59.78l-.19 8.772-.863-.017.207-8.772.432-.173z'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        d='M705.618 59.78l-.19 8.772-.431-.017.207-9.031z'
                                    />
                                    <path
                                        fill='#E5CDB3'
                                        d='M704.772 59.763l.466-2.09.38 2.09c-.034.086-.086.207-.155.207-.07 0-.07-.31-.26-.328-.19-.017-.137.328-.276.328-.138 0-.155-.207-.155-.207z'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        d='M705.1 58.312l.138-.639.121.639z'
                                    />
                                    <path
                                        fill='#44535B'
                                        d='M707.241 60.384l-1.554 8.634-.846-.138 1.554-8.651.449-.086z'
                                    />
                                    <path
                                        fill='#37454E'
                                        d='M707.241 60.384l-1.554 8.634-.414-.069 1.588-8.893z'
                                    />
                                    <path
                                        fill='#E5CDB3'
                                        d='M706.395 60.229l.777-1.986.07 2.141c-.052.07-.122.19-.19.173-.07-.017-.018-.328-.208-.363-.19-.034-.19.294-.328.277-.138-.018-.12-.242-.12-.242z'
                                    />
                                    <path
                                        fill='#414141'
                                        d='M706.948 58.848l.242-.605v.656z'
                                    />
                                    <path
                                        fill='#ACB0B4'
                                        d='M702.476 63.682h6.25l-.051 8.651-6.165.018z'
                                    />
                                    <path
                                        fill='#818487'
                                        fillRule='nonzero'
                                        d='M703.183 64.373h1v6.772h-1zm1.106 0h1v6.772h-1zm1.105 0h1v6.772h-1zm1.105 0h1v6.772h-1zm1.105 0h1v6.772h-1z'
                                    />
                                    <path
                                        fill='#02A896'
                                        fillRule='nonzero'
                                        d='M717.222 73.093h.483a2.176 2.176 0 1 0 0-4.351h-.483v.777h.483a1.399 1.399 0 1 1 0 2.797h-.483v.777zm-2.936-6.078c1.744 0 3.143.173 3.16.363 0 .207-1.416.38-3.16.38-1.744 0-3.16-.173-3.16-.38 0-.19 1.416-.363 3.16-.363z'
                                    />
                                    <path
                                        fill='#02A896'
                                        fillRule='nonzero'
                                        d='M711.161 67.447c.225.172 1.537.31 3.125.31h.311c1.606-.017 2.85-.19 2.85-.38v7.236c0 .207-1.417.362-3.16.362-1.745 0-3.16-.155-3.16-.362v-7.235c0 .017.017.034.034.069z'
                                    />
                                    <path
                                        fill='#000'
                                        fillRule='nonzero'
                                        d='M714.286 67.015c1.744 0 3.143.173 3.16.363 0 .207-1.416.38-3.16.38-1.744 0-3.16-.173-3.16-.38 0-.19 1.416-.363 3.16-.363z'
                                        opacity='.1'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M775.931 95.645l1.952-10.413.38-2.417 1.95.673 13.193 1.882-2.521 9.705c-1.227.35-2.48.604-3.747.76-2.521.241-5.18.362-7.719.172l-3.488-.362z'
                                    />
                                    <path
                                        fill='#23394C'
                                        fillRule='nonzero'
                                        d='M789.797 91.725a.207.207 0 0 0-.138-.294l-10.654-1.778a.19.19 0 0 0-.225.155.19.19 0 0 0 .173.225l10.637 1.795a.224.224 0 0 0 .207-.103z'
                                        opacity='.43'
                                    />
                                    <path
                                        fill='#23394C'
                                        fillRule='nonzero'
                                        d='M787 92.45a.19.19 0 0 0-.138-.276l-8.237-1.382a.19.19 0 0 0-.224.173.19.19 0 0 0 .155.225l8.237 1.364a.173.173 0 0 0 .207-.104zm1.951 1.278a.207.207 0 0 0-.138-.294l-10.326-1.726a.207.207 0 0 0-.242.155.224.224 0 0 0 .173.242l10.326 1.726a.224.224 0 0 0 .207-.103zm2.694-6.285a.19.19 0 0 0-.156-.242l-3.09-.518a.19.19 0 0 0-.225.155.207.207 0 0 0 .155.242l3.091.518a.19.19 0 0 0 .225-.155zm-10.102-.139a.207.207 0 0 0-.155-.259l-1.83-.362a.224.224 0 0 0-.242.172.19.19 0 0 0 .172.225l1.83.363a.224.224 0 0 0 .225-.139zm-.259.846a.207.207 0 0 0-.155-.241l-1.83-.363a.207.207 0 0 0-.242.156.19.19 0 0 0 .155.224l1.83.363a.207.207 0 0 0 .242-.139zm10.154.156a.207.207 0 0 0-.156-.26l-3.09-.517a.19.19 0 0 0-.225.173.19.19 0 0 0 .155.224l3.091.518a.207.207 0 0 0 .225-.138z'
                                        opacity='.23'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M781.491 93.037l-.569-1.191-1.192-.95-.725.518 1.865 1.951z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M779.005 91.414l.725-.518-2.055.086-1.502.518-.345 2.055 2.037-2.141.708.345.035-.259.207.069z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M780.3 93.434l.484 1.019-1.796 2.055-.777-.812z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M778.211 95.696l-1.295.087v-.898l1.346-2.159 2.038.708z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M778.262 92.726l-.397-1.312-1.277 1.986.328 1.485z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M777.865 91.414l.708.345.104-.759.328.414 1.226.052.311 1.243.949.328-1.105.587-.086-.19-2.038-.708z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M781.491 93.037l.156 1.278-1.192.535.329-.397-.398-.829z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M780.455 94.85l.329-.397-.484-1.019-2.038-.708-.397-1.312.708.345.035-.259.967.363.19.38-.38.535.777-.121.363.605.448.069.38.69-.431.622z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M777.865 91.414l-1.277 1.986.328 1.485v.898l-.311-.138-.777-2.09z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M777.865 91.414l-2.037 2.141.103-.691z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M776.605 95.645l2.383.863 1.018.363-2.503.189z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M778.988 96.508l-2.072-.725 1.295-.087zm0 0l1.018.363 1.278-2.401-.829.38z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M778.988 96.508l1.467-1.658.829-.38-.138.726-.621.708-.881.846z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M775.828 93.555l.552 2.97.898.173z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M775.828 93.555l-.587 1.192 1.139 1.778zm6.665 5.716l1.848-.76 1.416-1.364-.881-.656-2.953 2.193z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M784.876 96.491l.881.656-.329-2.072-.967-1.468-3.298-.069 3.592 1.778-.501.778h.449l-.103.224z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M781.75 98.114l-1.571.621-3.419-1.554 1.209-.898z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M777.969 96.283l-.225-1.329 1.416-.121 3.54 1.088-.95 2.193z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M782.7 95.921l2.055-.605-3.281-1.018-2.314.535zm2.055-.605l-.501.778 1.226.017-.604.38v1.26l-1.934.501-.449 1.019-1.019-1.054.276-.103.95-2.193z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M782.493 99.271l-2.02.328-.933-1.157.639.293 1.295-.518z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M779.54 98.442l.639.293 1.571-.621.95-2.193 2.055-.605-.501.778h.449l-.501 1.053-.604.242-.881-.311.259.777-.915.449-.069.483-1.053.466-1.036-.345z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M784.755 95.316l-3.281-1.018-2.314.535-1.416.121.173-.328 3.246-1.088z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M784.755 95.316l-3.592-1.778 1.123.017z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M777.917 94.626l-1.157 2.555-.483 1.105-.536-2.538z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M776.76 97.181l.984-2.227.225 1.329zm0 0l-.483 1.105 3.919.967-.656-.811z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M776.76 97.181l2.78 1.261.656.811-1.156-.034-1.175-.535-1.416-.795z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M781.163 93.538l-4.662.967-.19.95z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M781.163 93.538l-1.934-.432-2.728 1.399zm10.793 3.902l1.208-.604.915-1.122-.587-.57-1.934 1.796z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M793.492 95.144l.587.57-.241-1.762-.674-1.243-2.193-.104 2.418 1.555-.311.638.294.018-.07.172z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M791.455 96.456l-1.036.501-2.314-1.364.794-.725z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M788.899 94.868l-.172-1.123.949-.086 2.366.967-.587 1.83z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M792.042 94.626l1.347-.466-2.193-.916-1.52.415z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M793.389 94.16l-.311.638.812.035-.398.311.018 1.053-1.278.397-.276.846-.691-.898.19-.086.587-1.83z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M791.956 97.44l-1.33.242-.656-.984.449.259.846-.415z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M789.97 96.698l.449.259 1.036-.501.587-1.83 1.347-.466-.311.638.294.018-.311.863-.397.19-.588-.276.173.656-.604.38-.035.397-.69.38-.691-.311z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M793.389 94.16l-2.193-.916-1.52.415-.949.086.12-.276 2.124-.864z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M793.389 94.16l-2.418-1.555.76.035z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M788.847 93.469l-.742 2.124-.294.915-.397-2.141z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M788.105 95.593l.622-1.848.172 1.123zm0 0l-.294.915 2.625.881-.466-.691z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M788.105 95.593l1.865 1.105.466.691-.777-.052-.794-.466-.95-.674z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M790.971 92.605l-3.073.743-.121.794z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M790.971 92.605l-1.277-.397-1.796 1.14zm-13.745 1.779l-.587-1.191-1.191-.967-.708.518 1.865 1.968z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M774.74 92.744l.708-.518-2.038.103-1.519.518-.346 2.055 2.038-2.158.708.362.052-.276.207.069z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M776.035 94.781l.483 1.019-1.795 2.055-.777-.812z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M773.946 97.043l-1.313.069.018-.898 1.346-2.141 2.038.708z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M773.997 94.073l-.414-1.329-1.261 2.003.329 1.467z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M773.583 92.744l.708.362.121-.76.328.398 1.209.069.328 1.226.949.345-1.105.57-.086-.173-2.038-.708z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M777.226 94.384l.156 1.278-1.209.518.345-.38-.397-.846z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M776.173 96.18l.345-.38-.483-1.019-2.038-.708-.414-1.329.708.362.052-.276.967.363.19.397-.38.535.777-.121.362.587.449.069.38.691-.431.622z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M773.583 92.744l-1.261 2.003.329 1.467-.018.898-.293-.121-.795-2.089z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M773.583 92.744l-2.038 2.158.121-.708z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M772.34 96.991l2.383.864 1.018.345-2.503.207z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M774.723 97.855l-2.09-.743 1.313-.069zm0 0l1.018.345 1.278-2.383-.846.363z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M774.723 97.855l1.45-1.675.846-.363-.138.725-.622.691-.88.846z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M771.545 94.902l.553 2.953.898.172z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M771.545 94.902l-.569 1.174 1.122 1.779zm17.216.432l-.587-1.192-1.191-.949-.708.518 1.864 1.951z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M786.275 93.711l.708-.518-2.038.086-1.52.518-.345 2.055 2.038-2.141.708.345.051-.276.208.086z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M787.57 95.731l.483 1.019-1.796 2.055-.777-.812z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M785.48 97.993l-1.295.086v-.915l1.347-2.141 2.038.708z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M785.532 95.023l-.414-1.312-1.261 1.985.328 1.468z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M785.118 93.711l.708.345.12-.76.329.415 1.208.051.328 1.244.95.328-1.105.587-.086-.19-2.038-.708z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M788.761 95.334l.155 1.278-1.208.518.345-.38-.397-.829z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M787.708 97.13l.345-.38-.483-1.019-2.038-.708-.414-1.312.708.345.051-.276.967.362.19.397-.38.536.777-.121.363.604.449.069.38.674-.432.621z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M785.118 93.711l-1.261 1.985.328 1.468v.915l-.311-.138-.794-2.089z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M785.118 93.711l-2.038 2.141.121-.691z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M783.874 97.941l2.383.864 1.019.362-2.504.19z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M786.257 98.805l-2.072-.726 1.295-.086zm0 0l1.019.362 1.278-2.4-.846.363z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M786.257 98.805l1.451-1.675.846-.363-.138.725-.622.708-.881.829z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M783.08 95.852l.57 2.97.881.172z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M783.08 95.852l-.57 1.174 1.14 1.796z'
                                    />
                                    <path
                                        fill='#ACB0B4'
                                        fillRule='nonzero'
                                        d='M790.989 119.249l2.901-21.083.138-.846h-22.759l.138.846 2.901 21.083z'
                                    />
                                    <path
                                        fill='#818487'
                                        fillRule='nonzero'
                                        d='M780.024 116.4a.656.656 0 0 1-.622.708.639.639 0 0 1-.639-.673l-.431-16.3a.656.656 0 0 1 .621-.709.639.639 0 0 1 .64.674l.43 16.3zm-3.35-.069a.69.69 0 0 1-.587.76.673.673 0 0 1-.674-.64l-1.191-16.265a.673.673 0 0 1 .57-.743.673.673 0 0 1 .69.64l1.192 16.248zm5.992-20.652h-12.45v1.641h24.865v-1.641zm2.607 20.721c-.02.366.256.68.622.708a.639.639 0 0 0 .639-.673l.431-16.3a.656.656 0 0 0-.621-.709.639.639 0 0 0-.64.674l-.43 16.3zm3.35-.069a.69.69 0 0 0 .587.76.656.656 0 0 0 .673-.64l1.192-16.265a.673.673 0 0 0-.57-.743.673.673 0 0 0-.69.64l-1.192 16.248zm-5.94-16.922h-.07a.673.673 0 0 0-.638.708v16.3a.673.673 0 0 0 .639.691h.069a.673.673 0 0 0 .639-.69v-16.301a.673.673 0 0 0-.64-.708z'
                                    />
                                    <g transform='translate(780.49 45.759)'>
                                        <mask
                                            id='prefix__videomodul-n'
                                            fill='#fff'
                                        >
                                            <use xlinkHref='#prefix__videomodul-m'/>
                                        </mask>
                                        <g
                                            fillRule='nonzero'
                                            mask='url(#prefix__videomodul-n)'
                                        >
                                            <path
                                                fill='#044D6B'
                                                d='M21.981 135.584c-.276.397 34.363 5.353 40.683-.276.241-5.716.431-15.886.76-27.093.258-8.46.587-17.509 1.087-25.694.777-12.57.898-24.727 2.763-27.904-3.298-6.596-30.442-14.574-48.176-1.934-.69 13.883 1.64 25.02 1.64 25.02.69 1.866 1.347 14.074 1.71 26.817.414 14.54.397 29.786-.467 31.064'
                                            />
                                            <path
                                                fill='#F79470'
                                                d='M52.631 48.815s-4.835-1.243-4.127-14.85c-4.938-3.609-10.101-.725-10.101-.725s2.9 10.93-5.664 14.436c-.103 7.425 8.358 7.01 8.358 7.01s7.2.829 11.534-5.87'
                                            />
                                            <path
                                                fill='#000'
                                                d='M48.504 34.483c-4.938-3.609-10.101-.725-10.101-.725s1.468 4.956-.604 9.255a50.352 50.352 0 0 0 10.705-2.417 55.1 55.1 0 0 1 0-6.113'
                                                opacity='.1'
                                            />
                                            <path
                                                fill='#044D6B'
                                                d='M18.683 52.821s-8.029 9.705-10.43 43.445c-.742 10.482-.932 23.294-.19 38.852 3.195-2.141 7.944-1.191 11.173 1.002.2.141.391.297.57.466.19-5.733 1.053-21.636 2.158-36.745 1.019-13.814 2.228-26.955 3.316-31.065 2.33-8.806-6.597-15.955-6.597-15.955m47.451 0s8.03 9.705 10.413 43.445c.742 10.482.95 23.294.19 38.852-3.195-2.141-7.943-1.191-11.155 1.002a5.082 5.082 0 0 0-.57.466c-.207-5.733-1.053-21.636-2.158-36.745-1.02-13.814-2.228-26.955-3.333-31.065-2.331-8.806 6.613-15.955 6.613-15.955'
                                            />
                                            <path
                                                fill='#CCC'
                                                d='M46.864 57.777l-3.436 6.182-6.044.138-1.934-7.701-1.088-2.331 14.142.69z'
                                            />
                                            <path
                                                fill='#02A896'
                                                d='M38.196 51.319h6.199l1.502 4.127-3.436 3.436s-3.575-.552-3.99-.552c-.413 0-2.468-3.437-2.468-3.437l2.193-3.574z'
                                            />
                                            <path
                                                fill='#02A896'
                                                d='M42.461 58.882l.967 5.077-3.713 6.596-2.331-6.458 1.364-6.32z'
                                            />
                                            <path
                                                fill='#FFF'
                                                d='M38.196 51.319l-2.746 5.077-3.332-9.342 2.175-1.105s.743 3.436 3.903 5.37m6.199-.553s.535 6.182 2.469 7.011c1.778-2.47 7.149-10.24 7.149-10.24l-3.022-1.243s-3.575 4.472-6.596 4.472'
                                            />
                                            <path
                                                fill='#F79470'
                                                d='M50.766 23.207s2.003-5.3.467-9.911c-1.537-4.61-14.47-3.989-16.577-1.571-2.107 2.417-.933 22.724-.017 25.158.915 2.435 9.497 5.56 12.795 1.865 2.918-2.555 4.99-7.65 3.332-15.54'
                                            />
                                            <path
                                                fill='#4A263D'
                                                d='M49.04 24.589s1.053-7.58.328-10.533c-7.27 1.606-17.579-1.088-18.58-3.022-1.002-1.934-1.934-4.455-.604-7.598 2.227 1.762 16.37 4.334 21.204 3.937 4.3-.466 2.694 6.2 2.694 6.2s3.764 3.66 3.056 6.785c-2.521 2.573-4.161 8.41-4.161 8.41l-3.937-4.18zm-9.964-4.162a.207.207 0 0 0-.086.087.742.742 0 0 1-.07.12l-.103.242c-.069.173-.138.346-.19.501-.051.156-.224.674-.328 1.019a27.41 27.41 0 0 0-.535 2.055c-.155.69-.31 1.364-.432 2.055-.12.69-.259 1.398-.362 2.089l-.035.242.225.051.811.19.829.173.829.173.829.138-.777-.328-.795-.294-.794-.276-.794-.276.19.31c.138-.69.293-1.38.431-2.072.139-.69.277-1.38.398-2.072.12-.69.259-1.381.362-2.072l.173-1.036.086-.518.052-.259a.414.414 0 0 1 .034-.138c.018-.035.018-.086.052-.104m.38 12.882c.498.114 1.01.16 1.52.138a7.46 7.46 0 0 0 1.52-.19c.5-.128.98-.32 1.432-.57a3.28 3.28 0 0 0 1.14-1.036 8.22 8.22 0 0 1-1.347.587 12.53 12.53 0 0 1-1.381.415c-.466.12-.933.207-1.416.31-.484.104-.967.208-1.468.346'
                                            />
                                            <path
                                                fill='#4A263D'
                                                d='M42.133 21.222l.051-.035.07-.052.138-.103.293-.173c.206-.098.42-.18.64-.242a3.713 3.713 0 0 1 1.38-.155l.363.052.173.034.172.052c.118.024.234.059.346.104l.345.138.328.173.328.172.622.432-.553-.518-.31-.225a3.246 3.246 0 0 0-.311-.207l-.346-.207-.345-.155-.173-.07-.19-.069a2.435 2.435 0 0 0-.38-.103 4.092 4.092 0 0 0-1.588-.052 4.42 4.42 0 0 0-.794.19l-.38.155-.19.104-.087.052-.103.052.5.656zm-4.87-1.088l-.259-.138-.242-.087a2.815 2.815 0 0 0-.518-.12 3.09 3.09 0 0 0-1.934.276c-.155.069-.276.172-.414.242-.134.088-.26.186-.38.293l.415-.207c.147-.048.297-.089.449-.121.286-.08.584-.109.88-.086.285.018.565.082.829.19l.38.19.173.12.138.104.483-.656z'
                                            />
                                            <path
                                                fill='#3D1C3B'
                                                d='M45.293 23.415a.967.967 0 0 1-.864 1.036.967.967 0 0 1-1.019-.88.932.932 0 0 1 .864-1.02.95.95 0 0 1 1.019.864m-8.306-.277c.034.518-.31.985-.794 1.02a.932.932 0 0 1-.967-.881c-.035-.518.31-.985.794-1.02a.933.933 0 0 1 .967.881'
                                            />
                                            <path
                                                fill='#3D1C3B'
                                                d='M34.587 22.655l.31-.086.311-.104c.2-.073.41-.114.622-.12a1.9 1.9 0 0 1 .622.05c.206.062.403.15.587.26a1.278 1.278 0 0 0-.536-.414 1.537 1.537 0 0 0-.69-.104 2.176 2.176 0 0 0-.656.173 1.64 1.64 0 0 0-.294.172l-.276.173m8.253.587c.202-.182.445-.313.709-.38.256-.08.526-.11.794-.086.273.024.543.065.811.12l.415.104.414.121a2.953 2.953 0 0 0-.397-.224l-.414-.19-.415-.121-.431-.052c-.29-.024-.58.023-.846.138a1.28 1.28 0 0 0-.64.57'
                                            />
                                            <path
                                                fill='#F79470'
                                                d='M50.404 30.719s5.577-.898 3.643-8.254c-2.227-2.66-4.507 2.003-4.507 2.003l.864 6.25z'
                                            />
                                            <path
                                                fill='#08243B'
                                                d='M37.246 137.363c.017-.052.017-.121.034-.173v-.552l.035-.743.052-1.468.069-2.935.103-5.888c.07-3.914.127-7.834.173-11.76l.19-11.759c.07-3.925.144-7.845.225-11.759.103-3.92.207-7.84.414-11.759.052-.967.104-1.951.173-2.918.051-.5.086-.984.138-1.468.038-.477.113-.95.224-1.416a1.11 1.11 0 0 1 .087-.31l.19-.311.345-.657.69-1.295 1.382-2.59c1.865-3.453 3.73-6.89 5.716-10.274a120.805 120.805 0 0 1 3.09-4.99 46.086 46.086 0 0 1 1.71-2.383c.293-.38.604-.76.932-1.123.328-.362.328-.345.501-.5.088-.086.18-.166.276-.242l.277-.207h-.173c.538.313 1.037.69 1.485 1.122.214.226.41.469.587.725.183.255.35.52.5.795.279.564.482 1.162.605 1.778.126.613.167 1.24.121 1.865l.07-.104-8.065 5.681-.052.035v.086l.985 5.353.034-.103a66.016 66.016 0 0 0-2.953 1.9 36.98 36.98 0 0 0-2.797 2.123 24.157 24.157 0 0 0-2.538 2.435 10.36 10.36 0 0 0-1.07 1.398 6.596 6.596 0 0 0-.76 1.572 5.957 5.957 0 0 1 .777-1.554c.323-.49.687-.952 1.087-1.382a21.015 21.015 0 0 1 2.556-2.4c.9-.74 1.84-1.432 2.815-2.072a53.353 53.353 0 0 1 2.97-1.865l.051-.034v-.052l-.932-5.37-.052.12 8.133-5.612.052-.034v-.07a7.339 7.339 0 0 0-.121-1.95 6.976 6.976 0 0 0-1.122-2.711 6.613 6.613 0 0 0-.622-.76 7.511 7.511 0 0 0-1.554-1.209l-.104-.052-.086.052-.31.242a1.82 1.82 0 0 0-.277.242c-.191.165-.37.344-.535.535-.336.357-.653.732-.95 1.122a55.905 55.905 0 0 0-1.727 2.4 100.405 100.405 0 0 0-3.108 5.008c-2.003 3.385-3.885 6.82-5.75 10.274l-1.399 2.59-.69 1.295-.346.64-.19.327a2.763 2.763 0 0 0-.138.398c-.094.49-.163.986-.207 1.485a37.09 37.09 0 0 0-.138 1.467c-.07.985-.121 1.952-.173 2.936-.19 3.92-.293 7.84-.38 11.759-.086 3.92-.155 7.857-.19 11.776l-.103 11.76-.052 5.888v5.87l-.035 5.889-.034 2.935-.035 1.468v1.295c-.017.052-.017.121-.034.173'
                                            />
                                            <path
                                                fill='#08243B'
                                                d='M37.384 73.715a29.89 29.89 0 0 0-1.606-3.557 34.587 34.587 0 0 0-2.003-3.367l-.57-.812-.604-.777-.294-.38-.328-.38-.31-.38-.346-.362-.673-.708-.363-.328-.362-.328-.363-.346-.38-.293-.777-.605.052.156c.144-.54.323-1.071.535-1.589.202-.513.433-1.015.69-1.502.277-.5.57-.967.881-1.433.312-.458.658-.891 1.036-1.295l.156-.156-.19-.12-5.75-4.007.052.277c.38-.5.796-.974 1.243-1.416.461-.43.952-.829 1.468-1.192a9.152 9.152 0 0 1 1.657-.915l.432-.173.466-.138.225-.069.224-.052.466-.086-.19-.07c.275.438.506.9.691 1.382.207.501.38 1.002.57 1.503a90 90 0 0 1 1.019 3.021 360.338 360.338 0 0 1 1.9 6.13c.62 2.055 1.19 4.093 1.778 6.148l1.761 6.164c-.5-2.072-1.053-4.144-1.589-6.216-.535-2.072-1.122-4.127-1.692-6.182-.57-2.055-1.174-4.11-1.813-6.147a104.246 104.246 0 0 0-1.019-3.057 23.665 23.665 0 0 0-.57-1.502 7.857 7.857 0 0 0-.725-1.485l-.069-.086h-.103l-.501.086-.242.052-.242.069-.466.138-.466.19c-.604.256-1.182.568-1.727.933-.536.376-1.044.791-1.52 1.243a11.88 11.88 0 0 0-1.312 1.485l-.103.172.155.104 5.785 3.972v-.277c-.391.42-.75.871-1.07 1.347-.329.484-.622.967-.899 1.468a12.519 12.519 0 0 0-.69 1.554 14.085 14.085 0 0 0-.536 1.64v.104l.087.052.777.587c.12.104.259.19.38.31l.362.312.363.328.362.328.691.69a27.369 27.369 0 0 1 2.452 3.005 33.17 33.17 0 0 1 2.072 3.315c.311.57.622 1.14.898 1.727.276.587.553 1.174.812 1.779M18.614 54.272c1.848 8.806 3.108 20.807 4.127 34.017m43.083-34.225c-.07.018-.104.07-.156.121l-.138.156a4.196 4.196 0 0 0-.242.345c-.155.242-.293.484-.431.725-.138.242-.536.967-.777 1.468a48.498 48.498 0 0 0-1.33 3.074 45.483 45.483 0 0 0-1.951 6.389c-.5 2.18-.812 4.398-.933 6.63a27.8 27.8 0 0 0 .397 6.666 34.224 34.224 0 0 1-.086-6.648c.104-1.105.225-2.193.397-3.298.173-1.106.38-2.176.622-3.247a62.836 62.836 0 0 1 1.848-6.389 67.89 67.89 0 0 1 1.208-3.09c.225-.501.45-1.02.708-1.503.26-.483.242-.5.38-.742l.207-.363.121-.155c.052-.052.104-.121.156-.139'
                                            />
                                        </g>
                                    </g>
                                    <path
                                        fill='#DDBF9E'
                                        fillRule='nonzero'
                                        d='M732.625 135.394l-26.731 14.505h252.037l-28.664-14.505z'
                                    />
                                    <path
                                        fill='#F1E5D8'
                                        fillRule='nonzero'
                                        d='M705.894 149.899H957.93v3.78H705.894z'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M840.995 103.018h-60.989a1.26 1.26 0 0 0-1.26 1.26l.846 40.389a1.26 1.26 0 0 0 1.26 1.243h59.297a1.26 1.26 0 0 0 1.26-1.243l.847-40.389a1.26 1.26 0 0 0-1.26-1.26'
                                    />
                                    <circle
                                        cx='810.501'
                                        cy='124.464'
                                        r='3.799'
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M875.893 141.973c0 1.399-5.405 2.539-12.087 2.539-6.683 0-12.088-1.14-12.088-2.539 0-1.398 5.405-2.52 12.088-2.52 6.682 0 12.087 1.122 12.087 2.52z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M876.204 141.749c0 1.174-5.474 2.124-12.243 2.124s-12.243-.95-12.243-2.124c0-1.174 5.491-2.124 12.243-2.124 6.752 0 12.243.95 12.243 2.124z'
                                    />
                                    <path
                                        fill='#F0F0F0'
                                        fillRule='nonzero'
                                        d='M871.351 136.137c-.811 1.001-1.07 2.037-.742 2.314a10.136 10.136 0 0 0 1.416-2.21.673.673 0 0 0-.674-.104z'
                                        style={{ MixBlendMode: 'multiply' }}
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M876.204 141.352c0 1.174-5.474 2.123-12.243 2.123s-12.243-.95-12.243-2.123c0-1.175 5.491-2.124 12.243-2.124 6.752 0 12.243.95 12.243 2.124z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M854.533 130.352c-.38 11.708 7.718 12.398 9.428 12.398 2.003 0 9.48-1.122 9.428-12.519-.708.121-1.968.208-2.625.311-5.318.933-11.034.45-16.231-.19z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M871.68 130.421c-.07 1.26-1.624 7.978-4.127 9.756-4.973 3.506-10.326-.38-10.326-.38 2.4 2.711 5.715 2.953 6.734 2.953 2.003 0 9.48-1.122 9.428-12.519a28.61 28.61 0 0 1-1.71.19z'
                                        opacity='.6'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M873.389 130.214c0 .984-4.23 1.796-9.428 1.796s-9.428-.812-9.428-1.796 4.23-1.813 9.428-1.813c5.197 0 9.428.812 9.428 1.813z'
                                    />
                                    <path
                                        fill='#F0F0F0'
                                        fillRule='nonzero'
                                        d='M863.736 128.85c-3.401.052-7.183.552-8.91 1.675.57.88 4.335 1.485 9.135 1.485 4.438 0 8.167-.587 9.152-1.382a.328.328 0 0 0 .069-.207c-.035-1.001-4.213-1.64-9.446-1.571z'
                                    />
                                    <path
                                        fill='#42210B'
                                        fillRule='nonzero'
                                        d='M855.068 130.646c.88.777 4.524 1.364 8.893 1.364 4.369 0 8.012-.587 8.91-1.364-.898-.795-4.541-1.382-8.91-1.382s-8.012.587-8.893 1.382z'
                                    />
                                    <path
                                        fill='#301506'
                                        fillRule='nonzero'
                                        d='M872.871 130.646c-.673-.587-2.832-1.054-5.698-1.26 5.266.604-4.749 2.917-12.036 1.312.985.76 4.559 1.312 8.824 1.312s8.012-.587 8.91-1.364z'
                                    />
                                    <path
                                        fill='#603813'
                                        fillRule='nonzero'
                                        d='M861.578 130.525c0 .224-1.105.397-2.452.397s-2.452-.173-2.452-.397c0-.225 1.088-.415 2.452-.415 1.364 0 2.452.173 2.452.415zm1.209-.656c0 .086-.415.155-.933.155s-.915-.069-.915-.155c0-.087.415-.156.915-.156.501 0 .933.07.933.156z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M863.961 132.2c-4.593 0-9.463-.674-9.463-1.917 0-1.243 4.87-1.9 9.463-1.9 4.593 0 9.445.657 9.445 1.9 0 1.243-4.87 1.917-9.445 1.917zm0-3.488c-5.888 0-9.135.932-9.135 1.571 0 .639 3.247 1.589 9.135 1.589s9.117-.933 9.117-1.589c0-.656-3.246-1.571-9.117-1.571z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M863.667 121.062l-.103.087-.121.086c-.044.048-.085.1-.12.155a2.366 2.366 0 0 0-.26.432c-.076.185-.14.376-.19.57 0 .103-.017.207-.017.293a.328.328 0 0 0 .017.139v.086-.035l.035.121v.087c.034.017.034.034.051.069l.139.103.207.121.397.173c.198.08.389.179.57.293h.086l.052.035.104.069.034.034h.035l.069.07.138.138c.183.172.34.37.466.587.143.244.237.514.276.794.028.13.04.264.035.397l-.052.397c-.04.224-.116.44-.224.64a3.54 3.54 0 0 1-.64.914c-.21.217-.435.419-.673.605-.341.274-.699.528-1.07.76l-.415.259a.725.725 0 0 1 .017-.121 3.35 3.35 0 0 1 .104-.38c.15-.407.323-.805.518-1.192l.363-.725a4.61 4.61 0 0 0 .259-.725.95.95 0 0 0 0-.5c-.018-.035-.035-.053-.035-.087l-.052-.086a.915.915 0 0 0-.172-.208l-.052-.052h-.07l-.155-.138-.328-.207a4.472 4.472 0 0 1-.5-.31 2.4 2.4 0 0 1-.536-.501 1.658 1.658 0 0 1-.345-.726 1.26 1.26 0 0 1-.052-.38v-.051c0-.018.017-.018.017-.035v-.173h.018v-.12c.022-.104.046-.208.069-.311l.051-.139c.078-.157.17-.308.277-.448.297-.41.722-.708 1.208-.847l.225-.034h.31l.035.017zm1.744-1.64a.07.07 0 0 0-.017.052v.103a1.399 1.399 0 0 0 .121.536c.017.017.017.034.017.069 0 .034.018.051.035.051h.034l.121.104a.259.259 0 0 1 .087.104l.051.069h.018v.034h.017v.035l.052.138c.05.144.067.297.052.449-.004.13-.027.258-.07.38a1.57 1.57 0 0 1-.31.535l-.294.294-.138.103a.138.138 0 0 0-.017-.052c0-.034-.018-.069-.018-.12a1.485 1.485 0 0 1-.034-.415v-.5a.777.777 0 0 0-.017-.208.345.345 0 0 0-.035-.155h-.017v-.07h-.017c-.018-.017 0 .035 0 .035h.017v-.034a.898.898 0 0 1-.225-.38 1.33 1.33 0 0 1-.034-.26c0-.034.017-.051.017-.086a.15.15 0 0 1 .017-.069.725.725 0 0 1 .104-.31.95.95 0 0 1 .31-.363.224.224 0 0 1 .139-.052c.017-.017.034 0 .034-.017z'
                                        opacity='.2'
                                    />
                                    <path
                                        fill='#F0F0F0'
                                        fillRule='nonzero'
                                        d='M872.63 131.63c-.743.915-.07 2.158.535 1.83.102-.634.166-1.274.19-1.916-.173-.07-.432-.277-.726.086z'
                                        opacity='.5'
                                        style={{ MixBlendMode: 'multiply' }}
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M872.06 138.951a4.386 4.386 0 0 1-1.037-.276c-.276-.12.19-1.312.501-1.174.222.09.454.153.69.19a2.538 2.538 0 0 0 2.798-2.193 2.504 2.504 0 0 0-2.193-2.78h-.173c-.345-.173.018-1.295.329-1.26 2.054.327 3.54 2.14 3.298 4.213a3.799 3.799 0 0 1-4.214 3.28z'
                                    />
                                    <path
                                        fill='#F0F0F0'
                                        fillRule='nonzero'
                                        d='M872.215 138.243c2.486.588 5.007-4.817.708-5.508a2.504 2.504 0 0 1-.708 4.956 3.143 3.143 0 0 1-.69-.19c-.312-.138-.778 1.053-.502 1.174a4.38 4.38 0 0 0 1.036.276h.501c-.518-.086-1.036-.604-.345-.708z'
                                        opacity='.5'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M762.532 144.891l-3.333.777-1.364.933-27.662-2.279 2.918-2.47z'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M760.097 126.709l.518.017 1.917 18.165-6.786-.552z'
                                    />
                                    <path
                                        fill='#999'
                                        fillRule='nonzero'
                                        d='M730.5 145.116l26.696 2.193c.3.03.568-.185.605-.484l2.797-19.875a.466.466 0 0 0-.432-.535l-25.4-2.09a.553.553 0 0 0-.587.433l-4.093 19.77a.466.466 0 0 0 .415.588z'
                                    />
                                    <path
                                        fill='#127891'
                                        fillRule='nonzero'
                                        d='M757.352 126.743a.5.5 0 0 0 .466.536.483.483 0 0 0 .535-.45.5.5 0 0 0-.449-.535.518.518 0 0 0-.552.45z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M758.215 123.946c.656.052 1.122.777 1.053 1.658a2.227 2.227 0 0 1-.207.725l-.898-.07a.984.984 0 0 0 .328-.707c.035-.484-.172-.829-.328-.829-.155 0-.432.276-.466.76-.035.483.224.777.38.967.141.124.157.34.034.483a.449.449 0 0 1-.414.07c-.535-.173-.846-.795-.777-1.59.069-.794.639-1.519 1.295-1.467z'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M752.189 126.312a.535.535 0 0 0 .449.552.5.5 0 0 0 .535-.466.483.483 0 0 0-.45-.535.5.5 0 0 0-.534.449z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M753.052 123.514c.656.052 1.105.795 1.036 1.675a1.86 1.86 0 0 1-.19.708l-.898-.069a.932.932 0 0 0 .311-.708c.052-.483-.155-.811-.328-.829-.173-.017-.415.277-.466.76.012.36.153.703.397.967a.328.328 0 0 1 .017.484.432.432 0 0 1-.414.069c-.536-.156-.83-.795-.76-1.589.069-.794.639-1.52 1.295-1.468z'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M747.026 125.897a.483.483 0 0 0 .449.535.5.5 0 0 0 .535-.466.518.518 0 0 0-.45-.535.5.5 0 0 0-.534.466z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M747.872 123.083c.656.069 1.122.794 1.053 1.675a3.194 3.194 0 0 1-.19.725l-.898-.087c.194-.184.306-.44.311-.708.035-.483-.173-.811-.328-.828-.156-.018-.432.276-.466.76-.035.483.224.794.38.966h.017c.136.13.144.345.017.484a.414.414 0 0 1-.414.069c-.536-.155-.846-.794-.777-1.571.069-.777.639-1.537 1.295-1.485z'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M741.845 125.465a.5.5 0 0 0 .467.536c.267.011.5-.184.535-.45a.5.5 0 0 0-.466-.534.483.483 0 0 0-.536.448z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M742.709 122.668c.656.052 1.122.777 1.036 1.658a1.865 1.865 0 0 1-.19.725l-.898-.069c.2-.19.318-.45.328-.725.035-.484-.173-.812-.345-.83-.173-.016-.415.277-.467.778.017.353.157.69.398.95a.328.328 0 0 1 .034.483.483.483 0 0 1-.432.087c-.518-.173-.828-.812-.76-1.59.07-.776.64-1.519 1.296-1.467z'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M736.682 125.034a.483.483 0 0 0 .45.535.5.5 0 1 0 .086-.984.483.483 0 0 0-.536.449z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M737.546 122.236c.656.052 1.105.778 1.036 1.658a1.865 1.865 0 0 1-.19.725l-.898-.069a.967.967 0 0 0 .31-.708c.053-.5-.172-.828-.327-.828-.156 0-.432.276-.467.76-.034.483.225.776.398.966a.31.31 0 0 1 .017.466.449.449 0 0 1-.415.087c-.535-.173-.828-.794-.777-1.589.052-.794.657-1.52 1.313-1.468z'
                                    />
                                    <path
                                        fill='#35B9AB'
                                        fillRule='nonzero'
                                        d='M755.573 111.185s5.94 14.522 5.025 31.185l-3.385-3.021s-.535-20.22-1.64-28.164'
                                    />
                                    <path
                                        fill='#35B9AB'
                                        fillRule='nonzero'
                                        d='M764.276 110.892s2.918 15.402-1.313 31.547l-2.71-3.643s3.522-19.927 4.023-27.904'
                                    />
                                    <path
                                        fill='#027E71'
                                        fillRule='nonzero'
                                        d='M764.276 110.892c.029.09.046.182.052.276.138 7.2-.104 14.522-1.106 21.653-.345 2.452-.777 4.922-1.588 7.27 0 .02-.016.035-.035.035-.034 0-.051-.018-.034-.052 1.502-4.455 1.934-9.307 2.262-13.987.31-4.179.432-8.392.432-12.588 0-.708 0-1.399-.018-2.107.018-.172.035-.345.035-.5m-8.703.293l.086.207c1.26 7.34 2.435 14.73 3.23 22.137.207 1.986.431 3.99.38 5.975 0 .034-.018.052-.035.052a.052.052 0 0 1-.052-.052c.104-4.11-.622-8.306-1.192-12.364a419.805 419.805 0 0 0-1.985-13.088 55.668 55.668 0 0 0-.294-1.796c-.103-.587-.086-.725-.138-1.07'
                                    />
                                    <path
                                        fill='#ACB0B4'
                                        fillRule='nonzero'
                                        d='M756.782 148.155h8.046l1.244-11.569h-10.948z'
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <text
                    fill='#494949'
                    fontFamily='Roboto-Bold, Roboto'
                    fontSize='18'
                    fontWeight='bold'
                    transform='translate(7 5)'
                >
                    <tspan
                        x='13.476'
                        y='183.713'
                    >{t('SvgText:VideoModule1')}</tspan>
                </text>
                <g transform='translate(124.073 50.482)'>
                    <circle
                        cx='33.69'
                        cy='33.69'
                        r='19.372'
                        fill='#FFF'
                    />
                    <path
                        fill='#05668E'
                        fillRule='nonzero'
                        stroke='#FFF'
                        strokeWidth='3.369'
                        d='M33.69 0C15.112 0 0 15.114 0 33.69c0 18.577 15.112 33.69 33.69 33.69 18.578 0 33.69-15.113 33.69-33.69C67.38 15.114 52.268 0 33.69 0zm13.393 34.87L27.431 47.504a1.398 1.398 0 0 1-1.431.052 1.402 1.402 0 0 1-.732-1.232V21.056a1.402 1.402 0 0 1 2.163-1.18L47.083 32.51a1.404 1.404 0 0 1 0 2.36z'
                    />
                </g>
            </g>
        </svg>
  );
}
