import React from 'react';
import { useModalContext } from 'modal-context/modal-context';
import { ModalAlertConfig } from '../../utilities/modal-alert/shared';
import { ModalBody } from '../../utilities/modal-alert/modal-body';

const UseShowDecisionAlert = (): {
  show: (props: ModalAlertConfig) => void;
  close: () => void;
} => {
  const modal = useModalContext();

  const show = (props: ModalAlertConfig): void => {
    modal.fire({
      html: React.createElement(ModalBody, props),
      onClose: async () => {
        await props.onClose?.();
        modal.close();
      },
    });
  };

  return { show, close: modal.close };
};

export default UseShowDecisionAlert;
