import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import {
  Button,
  Text,
  Title,
} from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';

import { ALERT_TYPE } from 'flamingo-e-learning-platform/utilities/modal-alert/shared';
import { UseShowDecisionAlert } from 'flamingo-e-learning-platform/utilities/modal-alert/use-show-decision-alert';

import { useModalContext } from 'modal-context/modal-context';
import { dispatchClearAuthRef } from 'authentication/services';
import { useActivateAccount } from 'authentication/hooks/use-activate-account';

interface SignInAlertsHookProps {
  input: string;
  showLoginNotActivated: boolean;
  showLoginNotExist: boolean;
  showAccountNotExist: boolean;
  resetShowAccountNotExist: () => void;
}

export const useSignInAlertsHook = ({
  input,
  showLoginNotActivated,
  showLoginNotExist,
  showAccountNotExist,
  resetShowAccountNotExist,
}: SignInAlertsHookProps): void => {
  const { t } = useTranslation([
    'translations',
    'messageTranslations',
    'placeholderTranslations',
  ]);

  const { mutate: activateAccount } = useActivateAccount();
  const history = useHistory();
  const { close } = useModalContext();
  const { show } = UseShowDecisionAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleAccountNotExist = (redirect?: string) => {
      close();
      resetShowAccountNotExist();
      dispatch(dispatchClearAuthRef());

      if (redirect) {
        history.push(redirect);
      }
    };
    if (showAccountNotExist) {
      show({
        title: <Title size="h2">{t('translations:Error')}</Title>,
        content: (
          <Text>
            {t(
              'translations:Unfortunately this did not work out. Are you already registered?'
            )}
            .
          </Text>
        ),
        footer: (
          <>
            <Button
              variant="secondary"
              aria-label={t('translations:Close')}
              onClick={() => handleAccountNotExist()}
            >
              {t('translations:Close')}
            </Button>
            <Button
              variant="primary"
              aria-label={t('translations:Go to registration')}
              onClick={() => handleAccountNotExist('/register')}
            >
              {t('translations:Go to registration')}
            </Button>
          </>
        ),
        type: ALERT_TYPE.ERROR,
      });
    }
  }, [showAccountNotExist]);

  useEffect(() => {
    if (showLoginNotActivated) {
      show({
        title: (
          <Title size="h2">
            {t('messageTranslations:An error has occured')}
          </Title>
        ),
        content: (
          <Text>
            {t(
              'messageTranslations:Account not activated! Please open the link sent to your email address to activate your account.'
            )}
          </Text>
        ),
        footer: (
          <>
            <Button
              variant="primary"
              aria-label={t('translations:Send again')}
              onClick={() => activateAccount({ email: input })}
            >
              {t('translations:Send again')}
            </Button>
            <Button
              variant="secondary"
              aria-label={t('translations:Close')}
              onClick={close}
            >
              {t('translations:Close')}
            </Button>
          </>
        ),
        type: ALERT_TYPE.ERROR,
      });
    }
  }, [showLoginNotActivated, history, t, dispatch, input]);

  useEffect(() => {
    if (showLoginNotExist) {
      show({
        title: <Title size="h2">{t('translations:Error')}</Title>,
        content: (
          <Text>
            {t(
              'messageTranslations:Account not found, link expired or already activated! Try to login.'
            )}
            .
          </Text>
        ),
        footer: (
          <Button
            variant="secondary"
            aria-label={t('translations:Close')}
            onClick={() => {
              close();
              history.push('/');
            }}
          >
            {t('translations:Close')}
          </Button>
        ),
        type: ALERT_TYPE.ERROR,
      });
    }
  }, [showAccountNotExist]);
};

export default useSignInAlertsHook;
