
// Packages
import React, { useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Components
import usePlatformSettings from 'shared/hooks/use-platform-settings';
import { BadgeModal } from 'flamingo-e-learning-platform/components/badge-modal/badge-modal';
import { useLevelProgress } from 'shared/modules/level-progress';
import useUser from 'shared/hooks/use-user';
import isPLuser from 'shared/helpers/personalizedLearning/is-pl-user';
import RewardNewBadgeModal from './reward-new-badge-modal';
import { NewLevelReward } from './new-level-reward';

// Hooks
import { ModuleFeedback } from './feedback';

// Helpers

// Scss
import './reward-modal.scss';

const MySwal = withReactContent(Swal);

export default function RewardModal(props) {
  const { notificationToShow } = props;
  const { user } = useUser({ enabled: false });
  const { settings } = usePlatformSettings();
  const { prevLevel, currentLevel, xp } = useLevelProgress();
  const [openBadge, setOpenBadge] = useState(notificationToShow.badges?.[0]);
  const showPersonalizedLearning = isPLuser(user);

  const onNext = () => {
    MySwal.clickConfirm();
  };

  const moduleFeedbackType = useMemo(
    () => (Math.random() > 0.5 ? 'recommendation' : 'relevance'),
    [notificationToShow.type]
  );

  // Personalized Learning Badge Earned Modal
  if (settings?.badges && notificationToShow.type === 'badgeModal' && showPersonalizedLearning) {
    return (
      <BadgeModal
        // Add received date to badge as backend doesnt return the property at all when a badge is earned, only when it is retrieved again, added fallback until backend implements.
        badge={{
          ...openBadge,
          received: openBadge?.received || new Date().toISOString(),
        }}
        onClose={() => {
          setOpenBadge(null);
          MySwal.clickConfirm();
        }}
      />
    );
  }

  return (
    <React.Fragment>
      {/* Feedback modal - classic only */}
      {notificationToShow.type === 'feedbackModal' && (
        <React.Fragment>
          {/* This is for only classic. */}
          {notificationToShow?.moduleGroup && (
            <ModuleFeedback
              type={moduleFeedbackType}
              moduleId={notificationToShow?.moduleGroup}
              initialAnswer={-1}
              onSendFeedback={onNext}
              onSkip={onNext}
            />
          )}
        </React.Fragment>
      )}

      {/* Level up / XP earned modal for PL and Classic */}
      {settings?.level &&
        (notificationToShow.type === 'xpModal' || notificationToShow.type === 'levelModal') && (
          <NewLevelReward
            type={notificationToShow.type === 'xpModal' ? 'xp' : 'levelUp'}
            currentLevel={notificationToShow.type === 'xpModal' ? currentLevel : prevLevel}
            nextLevel={notificationToShow.type === 'xpModal' ? null : currentLevel}
            currentXp={xp}
            receivedXp={notificationToShow.xp}
            onNext={onNext}
          />
        )}

      {/* Earn badge modal in Classic */}
      {settings?.badges && notificationToShow.type === 'badgeModal' && (
        <RewardNewBadgeModal badges={notificationToShow.badges} onNext={onNext} />
      )}
    </React.Fragment>
  );
}
