
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Activities() {
  const { t } = useTranslation('tourTranslations');

  return (
        <svg
            width='313'
            height='179'
            viewBox='0 0 313 179'
            direction='ltr'
        >
            <defs>
                <rect
                    id='prefix__aktivitaeten-b'
                    width='299'
                    height='164.191'
                    x='0'
                    y='0'
                    rx='3.457'
                />
                <filter
                    id='prefix__aktivitaeten-a'
                    width='107.7%'
                    height='114%'
                    x='-3.8%'
                    y='-5.8%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dy='2'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='3.5'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
                    />
                </filter>
                <path
                    id='prefix__aktivitaeten-c'
                    d='M2.88 0L.242 1.158v2.038c0 .66.286 1.541 1.116 2.296.978.891 1.522.955 1.522.955s.543-.064 1.522-.955c.829-.755 1.115-1.637 1.115-2.296V1.158L2.88 0z'
                />
                <filter id='prefix__aktivitaeten-d'>
                    <feColorMatrix
                        in='SourceGraphic'
                        values='0 0 0 0 0.972549 0 0 0 0 0.705882 0 0 0 0 0.529412 0 0 0 1.000000 0'
                    />
                </filter>
                <rect
                    id='prefix__aktivitaeten-e'
                    width='80'
                    height='46'
                    x='0'
                    y='0'
                    rx='4'
                />
                <filter
                    id='prefix__aktivitaeten-f'
                    width='117.5%'
                    height='130.4%'
                    x='-8.8%'
                    y='-10.9%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dy='2'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='2'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
                    />
                </filter>
                <path
                    id='prefix__aktivitaeten-h'
                    d='M0 0h80v47.407H0z'
                />
                <path
                    id='prefix__aktivitaeten-j'
                    d='M.059.03H54.77v26.805H.059z'
                />
                <path
                    id='prefix__aktivitaeten-l'
                    d='M.037.03h22.63v26.805H.037z'
                />
                <path
                    id='prefix__aktivitaeten-n'
                    d='M.037.007H14.83v9.338H.037z'
                />
                <path
                    id='prefix__aktivitaeten-p'
                    d='M.059.059h23.896V13.5H.059z'
                />
                <path
                    id='prefix__aktivitaeten-r'
                    d='M.067.03h10.741v5.86H.067z'
                />
                <path
                    id='prefix__aktivitaeten-t'
                    d='M.067.067h10.741v8.041H.067z'
                />
            </defs>
            <g
                fill='none'
                fillRule='evenodd'
                transform='translate(7 5)'
            >
                <use
                    fill='#000'
                    filter='url(#prefix__aktivitaeten-a)'
                    xlinkHref='#prefix__aktivitaeten-b'
                />
                <use
                    fill='#FFF'
                    xlinkHref='#prefix__aktivitaeten-b'
                />
                <g transform='translate(246 75)'>
                    <rect
                        width='40'
                        height='11.905'
                        fill='#F6AF7E'
                        rx='5.952'
                    />
                    <text
                        fill='#FFF'
                        fontFamily='Roboto-Bold, Roboto'
                        fontSize='5.714'
                        fontWeight='bold'
                        transform='translate(4.762 2.381)'
                    >
                        <tspan
                            x='9.524'
                            y='5.476'
                        >{t('SvgText:Activities1')}</tspan>
                    </text>
                    <path d='M6.905 2.381l-2.143.941v1.656c0 .535.232 1.252.907 1.866.794.723 1.236.775 1.236.775s.441-.052 1.236-.775c.674-.614.907-1.33.907-1.866V3.322l-2.143-.941z'/>
                    <use
                        fill='#FFF'
                        fillRule='nonzero'
                        transform='translate(5.5 2.857)'
                        xlinkHref='#prefix__aktivitaeten-c'
                    />
                </g>
                <text
                    fill='#F9BC8E'
                    fontFamily='Roboto-Medium, Roboto'
                    fontSize='10'
                    fontWeight='400'
                    transform='translate(30 73)'
                >
                    <tspan
                        x='101'
                        y='31'
                    >{t('SvgText:Activities2')}</tspan>
                </text>
                <text
                    fill='#393939'
                    fontFamily='Roboto-Regular, Roboto'
                    fontSize='10'
                    transform='translate(30 73)'
                >
                    <tspan
                        x='198'
                        y='67'
                    >{t('SvgText:Activities3')} &gt;</tspan>
                </text>
                <g
                    filter='url(#prefix__aktivitaeten-d)'
                    transform='translate(30 73)'
                >
                    <g
                        fill='#F8B487'
                        fillRule='nonzero'
                    >
                        <path d='M94.823 28.2h-1.43a.376.376 0 1 0 0 .753h.522l-1.805 1.805a.376.376 0 1 0 .532.532l1.805-1.805v.521a.376.376 0 1 0 .753 0v-1.43a.377.377 0 0 0-.377-.376z'/>
                        <path d='M95.688 25h-1.844a.319.319 0 0 0-.313.325v1.592H92a.319.319 0 0 0-.312.325v1.592h-1.532a.319.319 0 0 0-.312.324v1.592h-1.531a.319.319 0 0 0-.313.325c0 .18.14.325.313.325h1.843a.307.307 0 0 0 .221-.095.331.331 0 0 0 .092-.23v-1.592H92a.307.307 0 0 0 .221-.095.331.331 0 0 0 .092-.23v-1.592h1.53a.307.307 0 0 0 .222-.095.331.331 0 0 0 .091-.23V25.65h1.531a.319.319 0 0 0 .313-.325c0-.18-.14-.325-.312-.325z'/>
                    </g>
                </g>
                <g transform='translate(30 96)'>
                    <mask
                        id='prefix__aktivitaeten-g'
                        fill='#fff'
                    >
                        <use xlinkHref='#prefix__aktivitaeten-e'/>
                    </mask>
                    <use
                        fill='#000'
                        filter='url(#prefix__aktivitaeten-f)'
                        xlinkHref='#prefix__aktivitaeten-e'
                    />
                    <use
                        fill='#D8D8D8'
                        xlinkHref='#prefix__aktivitaeten-e'
                    />
                    <g mask='url(#prefix__aktivitaeten-g)'>
                        <g transform='translate(0 -1)'>
                            <mask
                                id='prefix__aktivitaeten-i'
                                fill='#fff'
                            >
                                <use xlinkHref='#prefix__aktivitaeten-h'/>
                            </mask>
                            <g mask='url(#prefix__aktivitaeten-i)'>
                                <g transform='translate(-10.667 -1.407)'>
                                    <path
                                        fill='#99CCD3'
                                        fillRule='nonzero'
                                        d='M.03 1.407h101.281v47.407H.03z'
                                    />
                                    <path
                                        fill='#D9EEFD'
                                        fillRule='nonzero'
                                        d='M14.578.03h54.711v26.805H14.578z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M31.385 10.356v-.66h-5.274v.66h-1.207v15.6h7.689v-15.6zm14.83-.66v-.437H46v-.444h-1.104v.444h-.215v.437h-.222v16.482h1.978V9.696z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M20.948 22.222h3.733v3.736h-3.733z'
                                    />
                                    <path
                                        fill='#CCC'
                                        fillRule='nonzero'
                                        d='M27.978 16.067v-.66h-3.297v.66h-.874v2.2h-1.54v7.689h6.592v-9.889z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M55.007 15.193v-.882h-.659v-1.096h-5.052v1.096h-.659v.882h-1.763v10.54h9.889v-10.54z'
                                    />
                                    <path
                                        fill='#CCC'
                                        fillRule='nonzero'
                                        d='M46 20.904h6.593v5.273H46z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M66.43 15.407v.882h-1.541v9.889h3.518V15.407z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M64.452 8.815v-.437h-.659v-.222h-.66v-.215h-1.318v.215h-.659v-.215h-.66v-.445h-.659v.445h-1.096v.437h-.222v.437h-.438v16.918h6.808V8.815z'
                                    />
                                    <g
                                        opacity='.4'
                                        transform='translate(14.519)'
                                    >
                                        <mask
                                            id='prefix__aktivitaeten-k'
                                            fill='#fff'
                                        >
                                            <use xlinkHref='#prefix__aktivitaeten-j'/>
                                        </mask>
                                        <g
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            mask='url(#prefix__aktivitaeten-k)'
                                        >
                                            <path d='M25.409-8.397l5.215 3.01L9.83 30.63l-5.215-3.01zm5.663 3.747l1.36.786L11.639 32.15l-1.36-.785zm3.227 1.25l.866.5L14.37 33.114l-.866-.5zM11.037-15.53l3.426 1.977-20.794 36.016-3.426-1.978zm3.999 2.308l1.335.77-20.794 36.016-1.334-.77zM48.45-9.845l7.91 4.567-23.853 41.313-7.909-4.567zm8.853 4.972l1.327.763-23.852 41.503-1.327-.763z'/>
                                        </g>
                                    </g>
                                    <path
                                        fill='#C7B299'
                                        fillRule='nonzero'
                                        d='M15.015.03h1.096v26.805h-1.096z'
                                    />
                                    <path
                                        fill='#EAD7C2'
                                        fillRule='nonzero'
                                        d='M69.282 25.741v1.096h-54.71v-1.096z'
                                    />
                                    <path
                                        fill='#EAD7C2'
                                        fillRule='nonzero'
                                        d='M68.185.03h1.096v26.805h-1.096zm-53.607 0h1.096v26.805h-1.096z'
                                    />
                                    <path
                                        fill='#D9EEFD'
                                        fillRule='nonzero'
                                        d='M71.378.03h23.289v26.805H71.378z'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M74.489 10.356v-.66h6.859v.66h1.578v15.6H72.911v-15.6z'
                                    />
                                    <path
                                        fill='#CCC'
                                        fillRule='nonzero'
                                        d='M88.074 25.956h-4.86V22.22h4.86z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M78.919 16.067v-.66h4.288v.66h1.149v2.2h2v7.689h-8.578v-9.889z'
                                    />
                                    <g
                                        opacity='.4'
                                        transform='translate(71.778)'
                                    >
                                        <mask
                                            id='prefix__aktivitaeten-m'
                                            fill='#fff'
                                        >
                                            <use xlinkHref='#prefix__aktivitaeten-l'/>
                                        </mask>
                                        <g
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            mask='url(#prefix__aktivitaeten-m)'
                                        >
                                            <path d='M25.385-8.393l5.215 3.01L9.806 30.634l-5.215-3.01zm5.664 3.74l1.36.785-20.794 36.015-1.36-.785zm3.226 1.255l.866.5-20.794 36.016-.866-.5zM11.021-15.527l3.426 1.978-20.793 36.015-3.426-1.978zm3.991 2.308l1.335.77-20.793 36.016-1.335-.77z'/>
                                        </g>
                                    </g>
                                    <path
                                        fill='#C7B299'
                                        fillRule='nonzero'
                                        d='M71.815.03h1.096v26.805h-1.096z'
                                    />
                                    <path
                                        fill='#EAD7C2'
                                        fillRule='nonzero'
                                        d='M94.667 25.733v1.096h-23.29v-1.096z'
                                    />
                                    <path
                                        fill='#EAD7C2'
                                        fillRule='nonzero'
                                        d='M71.378.03h1.096v26.805h-1.096z'
                                    />
                                    <path
                                        fill='#CCC'
                                        fillRule='nonzero'
                                        d='M73.889 30.644h22.459v23.507H73.889z'
                                    />
                                    <path
                                        fill='#B3B3B3'
                                        fillRule='nonzero'
                                        d='M74.63 31.763h20.978v6.958H74.63zm0 7.274h20.978v6.958H74.63zm0 7.267h20.978v6.958H74.63z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M83.496 33.207h3.244v1.723h-3.244zm0 6.978h3.244v1.724h-3.244zm0 7.2h3.244v1.723h-3.244z'
                                    />
                                    <path
                                        fill='gray'
                                        fillRule='nonzero'
                                        d='M91.326 24.904H78.91a.148.148 0 0 0-.148.148v5.504c0 .081.066.148.148.148h12.415a.148.148 0 0 0 .148-.148v-5.504a.148.148 0 0 0-.148-.148'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M80.948 26.385h8.341v3.347h-8.341z'
                                    />
                                    <path
                                        fill='#1A1A1A'
                                        fillRule='nonzero'
                                        d='M81.23 29.23h7.689v1H81.23z'
                                    />
                                    <path
                                        fill='#F0F3BD'
                                        fillRule='nonzero'
                                        d='M79.97 26.274h-.266a.052.052 0 0 1-.052-.052v-.14c0-.03.023-.052.052-.052h.266c.029 0 .052.023.052.051v.141a.052.052 0 0 1-.052.052'
                                    />
                                    <path
                                        fill='#00C3A0'
                                        fillRule='nonzero'
                                        d='M80.015 25.585h-.645a.052.052 0 0 1-.051-.052v-.148c0-.028.023-.052.051-.052h.645c.028 0 .052.024.052.052v.148a.052.052 0 0 1-.052.052'
                                    />
                                    <path
                                        fill='#666'
                                        fillRule='nonzero'
                                        d='M88.533 21.067h-6.829l-.119 3.837h7.067z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M82.244 20.526l-.14 4.378h6.029l-.14-4.378zm6.319 8.867h-6.882l-.925 2.563h8.725z'
                                    />
                                    <path
                                        fill='#23394C'
                                        fillRule='nonzero'
                                        d='M81.993 29.845l-.06.192h6.319l-.09-.192zm-.089.525l-.06.2h6.497l-.09-.2zm-.178.534l-.06.192h6.764l-.09-.192z'
                                        opacity='.23'
                                    />
                                    <path
                                        fill='#CCC'
                                        fillRule='nonzero'
                                        d='M17.763 55.674h-1.067l-.474-19.748h2.015z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M70.83 61.044h1.037l-3.623-25.259h-1.948zm-59.571 0h-1.037l3.622-25.259h1.949z'
                                    />
                                    <path
                                        fill='#CCC'
                                        fillRule='nonzero'
                                        d='M63.941 55.674h1.066l.474-19.748h-2.014z'
                                    />
                                    <path
                                        fill='#DDBF9E'
                                        fillRule='nonzero'
                                        d='M11.77 30.859l-7.874 4.274h74.297l-8.452-4.274z'
                                    />
                                    <path
                                        fill='#F1E5D8'
                                        fillRule='nonzero'
                                        d='M3.896 35.133h74.296v1.115H3.896z'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M29.919 33.504h24.029l-1.807-1.623H31.726z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M30.926 33.207l1.148-1.029h19.719l1.14 1.029z'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M41.981 33.378v-1.371h-.1v1.371m-1.148.007l.163-1.378L40.8 32l-.156 1.37m-1.148.023l.319-1.378-.096-.022-.319 1.377m-1.148.023l.474-1.371-.089-.029-.474 1.37m-1.156.037l.637-1.378-.088-.037-.637 1.378m-1.156.037l.8-1.37-.082-.045-.8 1.371m-1.162.051l.955-1.377-.074-.052-.963 1.378m-1.163.051l1.119-1.37-.074-.059-1.119 1.37m-1.17.067l1.274-1.378-.067-.067-1.281 1.378m11.014-1.341l.156 1.378.096-.015-.163-1.37m.993.015l.318 1.378.089-.023-.318-1.377m.992.029l.482 1.371.089-.03-.482-1.37m1 .029l.637 1.378.082-.037-.637-1.378m1 .045l.8 1.37.081-.044-.8-1.371m1.008.045l.955 1.377.074-.051-.955-1.378m1.007.059l1.119 1.37.074-.059-1.119-1.37m1.008.059l1.281 1.378.067-.067-1.274-1.378'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M31.207 32.744h21.556v-.1H31.207'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M29.919 33.504h24.03v1h-24.03z'
                                    />
                                    <path
                                        stroke='#333'
                                        strokeWidth='.5'
                                        d='M24.363 33.881c-.037-.2-.052-.348.14-.385l.178-.03h.023l.133.69h0l-.2.037c-.193.044-.237-.119-.274-.312z'
                                    />
                                    <path
                                        fill='#B3B3B3'
                                        fillRule='nonzero'
                                        d='M28.519 33.563l-.163-.867a.067.067 0 0 0-.082-.052l-.77.149a.06.06 0 0 0-.052.074l.163.866c.01.032.04.054.074.052l.778-.148a.081.081 0 0 0 .052-.074'
                                    />
                                    <path
                                        fill='#F2F2F2'
                                        fillRule='nonzero'
                                        d='M27.919 33.06v-.03a.06.06 0 0 1 .044-.067l.17-.03a.044.044 0 0 1 .06.045v.03a.06.06 0 0 1-.045.066l-.17.03a.06.06 0 0 1-.067-.045m.074.348v-.022a.052.052 0 0 1 .045-.066l.163-.038a.06.06 0 0 1 .066.052v.023a.06.06 0 0 1-.044.066l-.17.03a.06.06 0 0 1-.067-.045'
                                    />
                                    <path
                                        className='fill-primary'
                                        fillRule='nonzero'
                                        d='M25.259 33.052l-.007-.015 2.296-.437.245 1.319-2.282.437-.007-.008z'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M24.607 33.822c-.066-.363-.088-.644.274-.71l.334-.068h.037v.015l.244 1.297h.008l-.378.074c-.37.066-.452-.237-.526-.608'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M27.548 33.304l-.133.096c-.015.015-.022.007-.03-.007-.007-.015-.007-.037-.037-.03l-.267.052.052.066c.015.015.067.008.074-.014.008-.023.008-.015.015-.015.008 0 .097-.015.104.067v.014c-.007.015-.096.023-.104.015a.056.056 0 0 0-.03-.015.074.074 0 0 1-.11-.044.111.111 0 0 0-.149-.052h-.044c-.03.007-.052.007-.067.044-.015.038-.022.03-.044.038-.022.007-.119-.023-.111-.082a.081.081 0 0 1 .052-.081.096.096 0 0 1 .096.022.067.067 0 0 0 .104-.022.415.415 0 0 0 .03-.09.044.044 0 0 1 .044-.036h.066a.089.089 0 0 0 .052-.037.06.06 0 0 1 .089-.015.067.067 0 1 1-.074.11c-.037-.036-.082 0-.126 0l-.03.082c0 .015.008.008.015.008l.37-.067c.03 0 .015-.022.015-.037s-.007-.03.023-.022l.14.044c.008 0 .015 0 .015.008'
                                    />
                                    <path
                                        fill='gray'
                                        fillRule='nonzero'
                                        d='M13.748 33.415h7.281v1.041h-7.281z'
                                    />
                                    <path
                                        fill='#666'
                                        fillRule='nonzero'
                                        d='M21.037 33.415h-7.289l1.393-.519h7.281z'
                                    />
                                    <path
                                        stroke='gray'
                                        strokeWidth='.5'
                                        d='M14.881 35.067a2.674 2.674 0 0 0 0 .348c.008 1.096.015 1.652.156 1.933.496 1.022 2.733 1.17 2.926.74a.2.2 0 0 0-.007-.162c-.156-.496-1.512-.852-2.037-.26a.919.919 0 0 0-.17.867c.192.63 1.073.904 1.258.719.052-.052.052-.17.015-.252-.163-.326-1.059-.34-1.622.052a1.504 1.504 0 0 0-.348.348'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='14.444'
                                        y='33.815'
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        rx='.03'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='15.481'
                                        y='33.815'
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        rx='.03'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='20.407'
                                        y='33.904'
                                        fill='#F0F3BD'
                                        fillRule='nonzero'
                                        rx='.015'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='19.889'
                                        y='33.904'
                                        fill='#F0F3BD'
                                        fillRule='nonzero'
                                        rx='.015'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='14.489'
                                        y='33.844'
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        rx='.03'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M15.259 33.963l-.007.763-.163.289h-.437l-.037-.074-.126-.215.007-.763z'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M14.985 35.126h-.252v-.111h.252zm.074-.585h-.37l.007-.311h.363z'
                                    />
                                    <path
                                        fill='#E6E6E6'
                                        fillRule='nonzero'
                                        d='M14.119 39.77l.392.46-.489.43H14l-.378-.445c-.007-.008-.007-.015 0-.015l.497-.43z'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M14.051 39.827l.755-.656.656.755-.755.656z'
                                    />
                                    <path
                                        fill='#CCC'
                                        fillRule='nonzero'
                                        d='M13.973 40.374l.756-.655.655.756-.756.655z'
                                    />
                                    <path
                                        fill='#999'
                                        fillRule='nonzero'
                                        d='M14.034 40.348l.755-.655.655.756-.756.655z'
                                    />
                                    <path
                                        fill='#CCC'
                                        fillRule='nonzero'
                                        d='M13.821 40.121l.755-.656.656.755-.755.656z'
                                    />
                                    <path
                                        fill='#999'
                                        fillRule='nonzero'
                                        d='M13.882 40.095l.755-.656.656.755-.755.656z'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M14.022 39.741l.585-.511.334-.067.289.333-.03.074-.081.245-.593.511z'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M14.973 39.165l.755-.656.656.755-.755.656z'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M14.379 39.698l.754-.656.656.754-.754.656z'
                                    />
                                    <path
                                        fill='#999'
                                        fillRule='nonzero'
                                        d='M21.037 34.459v-1.044l1.385-.519v1.045z'
                                    />
                                    <path
                                        fill='#35B9AB'
                                        fillRule='nonzero'
                                        d='M16.578 18.556s-2.134 5.214-1.808 11.207l1.215-1.089s.2-7.274.593-10.118'
                                    />
                                    <path
                                        fill='#35B9AB'
                                        fillRule='nonzero'
                                        d='M13.452 18.444s-1.052 5.541.474 11.341l.97-1.31s-1.259-7.164-1.444-10.03'
                                    />
                                    <path
                                        fill='#027E71'
                                        fillRule='nonzero'
                                        d='M13.452 18.444l-.022.104c-.052 2.585.037 5.215.4 7.778.118.881.281 1.77.57 2.615 0 .007.007.007.015.007a.015.015 0 0 0 .015-.015c-.549-1.6-.697-3.348-.815-5.022a61.441 61.441 0 0 1-.156-4.526v-.763c0-.059-.007-.118-.007-.178m3.126.112l-.03.066c-.452 2.645-.874 5.297-1.155 7.963-.082.704-.156 1.43-.141 2.14 0 .016.007.016.015.016.007 0 .014 0 .014-.015-.037-1.474.23-2.985.43-4.445.2-1.459.452-3.133.711-4.696.037-.222.074-.437.104-.652.03-.214.037-.259.052-.377'
                                    />
                                    <path
                                        fill='#ACB0B4'
                                        fillRule='nonzero'
                                        d='M16.148 31.837h-2.896l-.445-4.156h3.934z'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M72.015 21.155H56.778a.311.311 0 0 0-.311.312v10.096a.31.31 0 0 0 .31.311h15.238a.312.312 0 0 0 .318-.311V21.467a.311.311 0 0 0-.318-.312'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M56.622 31.874l-2.378 2.311h20.104l-2.289-2.311z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M55.674 33.289l1.215-1.163H71.8l1.148 1.104z'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M54.244 34.185h20.104v1H54.244z'
                                    />
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M60.333 33.748h-.007l.363-.874h-.911l-.4.748h-.008l.393-.74h-.882l-.437.696h-.007l.43-.69h-.897l-.481.66h-.008l.475-.652h-.897l-.548.66h-.015l.548-.652h-1.007v-.015h1.015l.333-.393H56.23v-.052h1.163l.392-.466h.015l-.385.459h.852l.34-.467h.008l-.334.46h.852l.29-.467h.014l-.289.46h.845l.251-.467h.008l-.245.459h.86l.192-.467h.015l-.193.46h.852l.148-.467h.008l-.14.459h.851l.096-.467h.015l-.096.467h.852l.052-.467h.014l-.052.511h.852v-.466h.015v.466h.852l-.044-.466h.014l.045.466h.852l-.097-.466h.015l.096.466h.852l-.14-.459h.014l.141.467h.852l-.193-.46h.015l.193.467h.86L68.43 32h.007l.252.467h.852l-.297-.46h.015l.297.467h.851l-.34-.46h.007l.348.467h.852l-.385-.459h.007l.393.467h1.244v.015h-1.237l.334.392h.948v.015h-.934l.615.74h-.007l-.63-.747h-.889l.541.74h-.015l-.548-.748h-.896l.437.69h-.015l-.437-.697h-.896l.363.689h-.015l-.363-.697h-.896l.31.741h-.007l-.318-.748h-.897l.252.793h-.014l-.252-.8h-.897l.17.8h-.014l-.17-.8h-.897l.082.748h-.015l-.082-.748h-.896v.652h-.015v-.652h-.896l-.067.652h-.014l.066-.652h-.896l-.104.503h-.015l.104-.503h-.896l-.208.659H61.4l.2-.652h-.896l-.37.874zm10.386-.896h.896l-.334-.393h-.851l.289.393zm-.912 0h.897l-.29-.393h-.851l.244.393zm-.91 0h.896l-.245-.393h-.86l.208.393zm-.904 0h.888l-.207-.393h-.852l.17.393zm-.912 0h.897l-.17-.393h-.852l.125.393zm-.91 0h.896l-.126-.393h-.852l.081.393zm-.912 0h.897l-.082-.393h-.86l.045.393zm-.91 0h.895l-.037-.393h-.859v.393zm-.912 0h.896v-.393h-.852l-.044.393zm-.911 0h.896l.045-.393h-.852l-.09.393zm-.904 0h.897l.081-.393h-.86l-.118.393zm-.91 0h.895l.119-.393h-.852l-.163.393zm-.927 0h.911l.163-.393H60l-.215.393zm-.896 0h.881l.215-.393h-.844l-.252.393zm-.911 0h.896l.252-.393h-.86l-.288.393zm-.911 0h.896l.289-.393H57.4l-.333.393z'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M62.437 33.37l-.185.667h4.155l-.148-.667z'
                                    />
                                    <path
                                        fill='#E9EDF0'
                                        fillRule='nonzero'
                                        d='M57 21.859h14.793v9.338H57z'
                                    />
                                    <g transform='translate(56.963 21.852)'>
                                        <mask
                                            id='prefix__aktivitaeten-o'
                                            fill='#fff'
                                        >
                                            <use xlinkHref='#prefix__aktivitaeten-n'/>
                                        </mask>
                                        <g
                                            fillRule='nonzero'
                                            mask='url(#prefix__aktivitaeten-o)'
                                        >
                                            <g opacity='.6'>
                                                <path
                                                    className='fill-primary'
                                                    d='M15.407 1.904C12.674 5.489 11.015 5.993 10 5.748c-1.17-.281-1.244-1.496-2.852-2.022-1.726-.57-2.615.511-4.622.2C1.696 3.8.48 3.407-.896 2.074c.005 2.445.017 4.894.037 7.348h16.43c-.05-2.503-.104-5.01-.164-7.518z'
                                                />
                                                <path
                                                    className='fill-primary'
                                                    d='M15.281 3.348c-3.14 2.882-4.814 3.126-5.777 2.726-.593-.244-.978-.763-2.023-.985-1.044-.222-1.94.067-2.725.244-1.052.237-2.63.304-4.83-.533v4.6H15.2c.03-2.015.057-4.032.081-6.052z'
                                                />
                                                <path
                                                    fill='#FFF'
                                                    d='M15.281 3.348c-3.14 2.882-4.814 3.126-5.777 2.726-.593-.244-.978-.763-2.023-.985-1.044-.222-1.94.067-2.725.244-1.052.237-2.63.304-4.83-.533v4.6H15.2c.03-2.015.057-4.032.081-6.052z'
                                                    opacity='.3'
                                                />
                                                <path
                                                    className='fill-primary'
                                                    d='M14.911 3.844a9.311 9.311 0 0 1-3.918 2.852c-.763.282-2.963 1.111-5.208.29-.6-.223-.637-.356-1.192-.579-1.697-.681-3.519-.348-5.082.245V9.4H14.89l.022-5.556z'
                                                />
                                                <path
                                                    fill='#000'
                                                    d='M14.911 3.844a9.311 9.311 0 0 1-3.918 2.852c-.763.282-2.963 1.111-5.208.29-.6-.223-.637-.356-1.192-.579-1.697-.681-3.519-.348-5.082.245V9.4H14.89l.022-5.556z'
                                                    opacity='.1'
                                                />
                                            </g>
                                            <path
                                                fill='#FFF'
                                                d='M6.233-8.285l2.097 1.21-12.73 22.05-2.097-1.211zM8.677-6.88l.866.5-12.73 22.048-.865-.5z'
                                                opacity='.4'
                                            />
                                        </g>
                                    </g>
                                    <path
                                        fill='#4D4D4D'
                                        fillRule='nonzero'
                                        d='M53.393 28.4H28.696a.548.548 0 0 1-.548-.548V25.8h25.793v2.052a.548.548 0 0 1-.548.548'
                                    />
                                    <path
                                        fill='#333'
                                        fillRule='nonzero'
                                        d='M53.94 25.8H28.149V11.23a.497.497 0 0 1 .496-.504H53.43a.51.51 0 0 1 .51.511V25.8zm-8.703 4.852h-1.504V28.4h-5.281v2.252h-1.593a.763.763 0 0 0-.763.755h9.897a.756.756 0 0 0-.756-.755'
                                    />
                                    <path
                                        fill='#F1F1F1'
                                        fillRule='nonzero'
                                        d='M41.563 27.096a.533.533 0 1 1-.526-.533.526.526 0 0 1 .526.533'
                                    />
                                    <path
                                        fill='#E9EDF0'
                                        fillRule='nonzero'
                                        d='M29.096 11.541h23.896v13.441H29.096z'
                                    />
                                    <g transform='translate(29.037 11.481)'>
                                        <mask
                                            id='prefix__aktivitaeten-q'
                                            fill='#fff'
                                        >
                                            <use xlinkHref='#prefix__aktivitaeten-p'/>
                                        </mask>
                                        <g
                                            fillRule='nonzero'
                                            mask='url(#prefix__aktivitaeten-q)'
                                        >
                                            <g opacity='.6'>
                                                <path
                                                    className='fill-primary'
                                                    d='M24.889 1.489c-4.422 5.792-7.096 6.6-8.74 6.207-1.897-.459-2.008-2.415-4.6-3.266-2.793-.926-4.23.83-7.468.333-1.333-.207-3.31-.852-5.533-3.007l.067 11.874h26.54L24.89 1.489z'
                                                />
                                                <path
                                                    className='fill-primary'
                                                    d='M24.689 3.83c-5.082 4.644-7.793 5.037-9.34 4.4-.956-.4-1.579-1.237-3.268-1.6-1.807-.393-3.133.118-4.4.4-1.703.377-4.251.489-7.8-.867v7.43h24.675l.133-9.763z'
                                                />
                                                <path
                                                    fill='#FFF'
                                                    d='M24.689 3.83c-5.082 4.644-7.793 5.037-9.34 4.4-.956-.4-1.579-1.237-3.268-1.6-1.807-.393-3.133.118-4.4.4-1.703.377-4.251.489-7.8-.867v7.43h24.675l.133-9.763z'
                                                    opacity='.3'
                                                />
                                                <path
                                                    className='fill-primary'
                                                    d='M24.089 4.63c-.985 1.2-3.037 3.355-6.34 4.6-1.223.459-4.786 1.792-8.4.466-.971-.355-1.03-.57-1.934-.933-2.74-1.111-5.674-.57-8.2.4v4.43h24.837l.037-8.963z'
                                                />
                                                <path
                                                    fill='#000'
                                                    d='M24.089 4.63c-.985 1.2-3.037 3.355-6.34 4.6-1.223.459-4.786 1.792-8.4.466-.971-.355-1.03-.57-1.934-.933-2.74-1.111-5.674-.57-8.2.4v4.43h24.837l.037-8.963z'
                                                    opacity='.1'
                                                />
                                            </g>
                                            <path
                                                fill='#FFF'
                                                d='M10.065-14.973l3.387 1.956-20.56 35.61-3.386-1.955zm3.957 2.283l1.315.76-20.56 35.61-1.315-.76z'
                                                opacity='.4'
                                            />
                                        </g>
                                    </g>
                                    <rect
                                        width='12.578'
                                        height='8.707'
                                        x='19.074'
                                        y='22.844'
                                        fill='#033347'
                                        fillRule='nonzero'
                                        rx='.415'
                                    />
                                    <rect
                                        width='12.452'
                                        height='8.585'
                                        x='19.133'
                                        y='22.904'
                                        fill='#044D6B'
                                        fillRule='nonzero'
                                        rx='.378'
                                    />
                                    <path
                                        fill='#E9EDF0'
                                        fillRule='nonzero'
                                        d='M19.993 23.178h10.741v8.041H19.993z'
                                    />
                                    <circle
                                        cx='19.548'
                                        cy='27.2'
                                        r='1'
                                        fill='#CDE0E8'
                                        fillRule='nonzero'
                                    />
                                    <circle
                                        cx='31.17'
                                        cy='27.2'
                                        r='1'
                                        fill='#033347'
                                        fillRule='nonzero'
                                    />
                                    <g
                                        opacity='.6'
                                        transform='translate(19.926 25.333)'
                                    >
                                        <mask
                                            id='prefix__aktivitaeten-s'
                                            fill='#fff'
                                        >
                                            <use xlinkHref='#prefix__aktivitaeten-r'/>
                                        </mask>
                                        <g
                                            fillRule='nonzero'
                                            mask='url(#prefix__aktivitaeten-s)'
                                        >
                                            <path
                                                className='fill-primary'
                                                d='M11.533.563C9.43 3.311 8.163 3.696 7.378 3.511c-.785-.185-.948-1.148-2.185-1.555-1.237-.408-2.008.4-3.549.163-.63-.104-1.57-.408-2.622-1.43l.03 5.637h12.607a2626.12 2626.12 0 0 1-.126-5.763z'
                                            />
                                            <path
                                                className='fill-primary'
                                                d='M11.437 1.674C9.022 3.881 7.733 4.067 7 3.763c-.452-.185-.748-.585-1.548-.763-.8-.178-1.496.06-2.096.193-.808.185-2.015.237-3.704-.408v3.526H11.37l.067-4.637z'
                                            />
                                            <path
                                                fill='#FFF'
                                                d='M11.437 1.674C9.022 3.881 7.733 4.067 7 3.763c-.452-.185-.748-.585-1.548-.763-.8-.178-1.496.06-2.096.193-.808.185-2.015.237-3.704-.408v3.526H11.37l.067-4.637z'
                                                opacity='.3'
                                            />
                                            <path
                                                className='fill-primary'
                                                d='M11.148 2.052a7.06 7.06 0 0 1-3.007 2.185c-.585.222-2.274.86-3.993.222-.46-.17-.489-.266-.918-.444-1.297-.526-2.697-.267-3.897.192v2.104h11.8l.015-4.26z'
                                            />
                                            <path
                                                fill='#000'
                                                d='M11.148 2.052a7.06 7.06 0 0 1-3.007 2.185c-.585.222-2.274.86-3.993.222-.46-.17-.489-.266-.918-.444-1.297-.526-2.697-.267-3.897.192v2.104h11.8l.015-4.26z'
                                                opacity='.1'
                                            />
                                        </g>
                                    </g>
                                    <g transform='translate(19.926 23.111)'>
                                        <mask
                                            id='prefix__aktivitaeten-u'
                                            fill='#fff'
                                        >
                                            <use xlinkHref='#prefix__aktivitaeten-t'/>
                                        </mask>
                                        <g
                                            fill='#FFF'
                                            fillRule='nonzero'
                                            mask='url(#prefix__aktivitaeten-u)'
                                            opacity='.4'
                                        >
                                            <path d='M4.663-4.598l1.232.711L-1.58 9.058l-1.231-.711zm1.437.822l.865.5L-.508 9.67l-.866-.5z'/>
                                        </g>
                                    </g>
                                    <path
                                        fill='#044D6B'
                                        fillRule='nonzero'
                                        d='M24.66 25.822v5.837a.393.393 0 0 1-.023.148.03.03 0 0 1-.007.023.378.378 0 0 1-.067.118l-.044.052a.34.34 0 0 1-.052.052l-.037.022-.045.022-.066.03h-.045l-.096.015h-2.4l-.104-.015h-.03l-.074-.03-.037-.015c0-.007-.007-.007-.014-.014l-.03-.015-.082-.082-.03-.037-.036-.066a.252.252 0 0 1-.03-.09.304.304 0 0 1-.015-.118v-5.837a.481.481 0 0 1 .482-.489h2.4a.482.482 0 0 1 .481.49z'
                                    />
                                    <path
                                        fill='#044D6B'
                                        fillRule='nonzero'
                                        d='M24.66 27.296v-.689c.026 0 .051.01.07.029.019.018.03.042.03.068v.496c0 .026-.011.05-.03.068a.102.102 0 0 1-.07.028zm-3.316-.34h-.015a.083.083 0 0 1-.085-.082v-.178c0-.045.038-.081.085-.081h.015v.348-.007zm0 .414h-.015a.083.083 0 0 1-.085-.081v-.178c0-.045.038-.081.085-.081h.015v.348-.008z'
                                    />
                                    <path
                                        fill='#D9EEFD'
                                        fillRule='nonzero'
                                        d='M24.556 25.822v5.837c0 .03-.008.052-.008.074l-.015.037v.023a.615.615 0 0 1-.052.096l-.037.037-.044.037-.022.015h-.008l-.022.015h-.007l-.045.022h-2.622l-.06-.022-.021-.015c-.008 0-.015 0-.015-.008l-.022-.014a.178.178 0 0 1-.067-.06l-.022-.03a.185.185 0 0 1-.03-.051l-.022-.06a.437.437 0 0 1-.015-.096v-5.837a.378.378 0 0 1 .378-.385h2.4a.38.38 0 0 1 .348.244.43.43 0 0 1 .03.141z'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='21.6'
                                        y='27.356'
                                        fill='#B2D9DB'
                                        fillRule='nonzero'
                                        rx='.185'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='22.644'
                                        y='27.356'
                                        fill='#F0F3BD'
                                        fillRule='nonzero'
                                        rx='.185'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='23.696'
                                        y='27.356'
                                        fill='#9BC2D2'
                                        fillRule='nonzero'
                                        rx='.185'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='21.6'
                                        y='28.333'
                                        className='fill-primary'
                                        fillRule='nonzero'
                                        rx='.185'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='22.644'
                                        y='28.333'
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        rx='.185'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='22.644'
                                        y='28.333'
                                        className='fill-primary'
                                        fillRule='nonzero'
                                        rx='.185'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='23.696'
                                        y='28.333'
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        rx='.185'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='22.644'
                                        y='28.333'
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        opacity='.5'
                                        rx='.185'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='21.6'
                                        y='31.163'
                                        fill='#018090'
                                        fillRule='nonzero'
                                        rx='.185'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='22.644'
                                        y='31.163'
                                        fill='#99CCD3'
                                        fillRule='nonzero'
                                        rx='.185'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='22.644'
                                        y='31.163'
                                        fill='#99CCD3'
                                        fillRule='nonzero'
                                        opacity='.3'
                                        rx='.185'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='23.696'
                                        y='31.163'
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        rx='.185'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='21.6'
                                        y='29.311'
                                        fill='#69A3BB'
                                        fillRule='nonzero'
                                        rx='.185'
                                    />
                                    <rect
                                        width='1'
                                        height='1'
                                        x='22.644'
                                        y='29.311'
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        rx='.185'
                                    />
                                    <circle
                                        cx='22.274'
                                        cy='30.867'
                                        r='1'
                                        fill='#FFF'
                                        fillRule='nonzero'
                                    />
                                    <circle
                                        cx='22.622'
                                        cy='30.867'
                                        r='1'
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        opacity='.6'
                                    />
                                    <circle
                                        cx='22.978'
                                        cy='30.867'
                                        r='1'
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        opacity='.6'
                                    />
                                    <circle
                                        cx='23.326'
                                        cy='30.867'
                                        r='1'
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        opacity='.6'
                                    />
                                    <circle
                                        cx='23.681'
                                        cy='30.867'
                                        r='1'
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        opacity='.6'
                                    />
                                    <path
                                        fill='#494949'
                                        fillRule='nonzero'
                                        d='M24.215 25.667h-.17c-.008 0-.008-.008-.015-.015v-.096h.222c0 .007.007.014.007.022v.044a.03.03 0 0 1-.007.022.015.015 0 0 1-.015.015l-.022.008zm-.148-.119h-.03v.092c.004 0 .007.004.007.008h.2v-.092c0-.004-.003-.008-.007-.008h-.17zm.209.024v.09a.05.05 0 0 0 .03-.05c0-.02-.01-.03-.03-.04'
                                    />
                                    <path
                                        fill='#494949'
                                        fillRule='nonzero'
                                        d='M24.067 25.566h.163v.07c0 .01 0 .01-.008.01h-.163c-.004 0-.007-.005-.007-.01v-.07h.015zm-.12.082a.06.06 0 0 0-.06 0l.03.02.03-.02zm-.042-.027l.039.02.015-.02c-.029-.032-.071-.032-.1 0v.02h.008l.038-.02zm-.001-.037a.071.071 0 0 1 .059.03h.007v-.02c-.018-.03-.045-.047-.074-.047-.028 0-.055.017-.074.047l.015.02c.018-.023.042-.034.067-.03zm-.138-.036h.02c.01 0 .01.008.01.015v.081c0 .008 0 .015-.01.015h-.02a.02.02 0 0 1-.01-.015v-.081a.02.02 0 0 1 .01-.015zm-.042.022h.02v.084a.024.024 0 0 1-.01.016h-.02c-.01 0-.01-.008-.01-.016v-.067c.01-.008.01-.017.02-.017zm-.047.024h.01l.02.02v.05a.02.02 0 0 1-.02.02h-.01c-.01 0-.01-.01-.01-.02v-.05c0-.01 0-.02.01-.02zm-.042.027h.02v.03c0 .01 0 .02-.01.02h-.01a.02.02 0 0 1-.02-.02v-.03h.02zm-1.879-.081a.052.052 0 0 0-.06.06.06.06 0 0 0 .06.067c.036 0 .066-.03.066-.067a.06.06 0 0 0-.066-.06zm0 .12a.06.06 0 0 1-.052-.06c0-.029.023-.052.052-.052a.06.06 0 0 1 .059.052.067.067 0 0 1-.06.06z'
                                    />
                                    <path
                                        fill='#494949'
                                        fillRule='nonzero'
                                        d='M21.773 25.564h-.01v.05l.03.04h.01l-.03-.03z'
                                    />
                                    <circle
                                        cx='22.141'
                                        cy='26.548'
                                        r='1'
                                        fill='#FFF'
                                        fillRule='nonzero'
                                    />
                                    <path
                                        stroke='#9BC2D2'
                                        strokeLinecap='round'
                                        strokeWidth='.5'
                                        d='M22.14 26.548l.216.222m-.202-.585v.363'
                                    />
                                    <path
                                        fill='#FFF'
                                        fillRule='nonzero'
                                        d='M23.792 25.437L21.4 29.103v-1.8l1.214-1.866zm.734.244L21.4 30.467v-.837l2.74-4.193h.038a.38.38 0 0 1 .348.244zm.029 2.126l-2.763 4.23h.548l2.215-3.393z'
                                        opacity='.4'
                                        style={{ mixBlendMode: 'screen' }}
                                    />
                                    <path
                                        fill='#044D6B'
                                        fillRule='nonzero'
                                        d='M23.333 25.585h-.71a.215.215 0 0 1-.216-.215h1.141a.215.215 0 0 1-.215.215z'
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <text
                    fill='#494949'
                    fontFamily='Roboto-Bold, Roboto'
                    fontSize='12'
                    fontWeight='bold'
                    transform='translate(30 73)'
                >
                    <tspan
                        x='0'
                        y='11'
                    >
                        {t('SvgText:Activities4')}
                    </tspan>
                </text>
                <text
                    fill='#535353'
                    fontFamily='Roboto-Bold, Roboto'
                    fontSize='10'
                    fontWeight='bold'
                    transform='translate(30 73)'
                >
                    <tspan
                        x='88'
                        y='44'
                    >
                        {t('SvgText:Activities5')}
                    </tspan>
                    <tspan
                        x='88'
                        y='55'
                    >
                        {t('SvgText:Activities6')}
                    </tspan>
                </text>
                <path
                    fill='#05668E'
                    fillRule='nonzero'
                    d='M279.124 10.491a9.503 9.503 0 0 0-9.506 9.506 9.503 9.503 0 0 0 9.506 9.506 9.503 9.503 0 0 0 9.506-9.506 9.503 9.503 0 0 0-9.506-9.506zm.95 14.259h-1.9v-5.704h1.9v5.704zm0-7.605h-1.9v-1.9h1.9v1.9z'
                />
                <text
                    fill='#535353'
                    fontFamily='Roboto-Bold, Roboto'
                    fontSize='15.555'
                    fontWeight='bold'
                >
                    <tspan
                        x='10.491'
                        y='24.491'
                    >{t('SvgText:Activities7')}</tspan>
                </text>
                <path
                    stroke='#494949'
                    d='M15.5 32.5l1 123'
                />
                <circle
                    cx='16'
                    cy='49'
                    r='6'
                    className='fill-primary'
                />
                <text
                    fill='#494949'
                    fontFamily='Roboto-Regular, Roboto'
                    fontSize='12'
                >
                    <tspan
                        x='30'
                        y='53'
                    >{t('SvgText:Activities8')}</tspan>
                </text>
                <path
                    stroke='#E9EDF0'
                    d='M30 64l255.5.5M30 150.75l255.5.5'
                />
            </g>
        </svg>
  );
}
