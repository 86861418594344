
// Packages
import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

// Services
// Hooks
// Utilities
// Components
// Scss

/**
 * Hook to calculate data points for progress chart
 *
 * @param {*} activities array from game reducer
 * @param {*} progress taken from progress user game object
 * @param {number} minNumberPoints minimum Number of points to make total width 100%
 * @returns {*} return value description
 *
 * @category gamification
 * @subcategory hooks
 * @exports useProgressDataPoints
 */
export default function useProgressDataPoints(activities, progress, minNumberPoints) {
  const [dataPoints, setDataPoints] = useState([]);

  useEffect(() => {
    if (!activities || !activities.length) return () => {};
    if (!progress || !Object.keys(progress).length) return () => {};

    const cumulativeActivityXP = activities.reduce((acc, curr) => acc + (curr?.xp ?? 0), 0);
    const xpMax = Math.max((progress.level?.xp_max ?? 0) * 1.1, cumulativeActivityXP * 1.75);

    let points = [];
    const firstElem = activities[0];
    const numberOfPoints = activities.length;

    let calculatedXp = 0;
    if (activities.length === 1) {
      points.push({
        id: firstElem.id,
        badges: firstElem.badge ? [firstElem.badge] : null,
        created: firstElem.created,
        level: firstElem.level,
        module: firstElem.module,
        xp: calculatedXp,
        position: {
          relativeX: 0.01,
          relativeY: 1 - (calculatedXp / xpMax).toFixed(2),
        },
        type: firstElem.type,
      });
    } else {
      // get points reverse
      for (let i = activities.length - 1; i >= 0; i--) {
        const dat = activities[i];
        calculatedXp += dat.xp ?? 0;
        const relativeX = 1 - (i / (numberOfPoints - 1)).toFixed(4);
        const relativeY = 1 - (calculatedXp / xpMax).toFixed(4);

        points.push({
          id: dat.id,
          badges: dat.badge ? [dat.badge] : null,
          created: dat.created,
          level: dat.level,
          module: dat.module,
          xp: calculatedXp,
          position: {
            relativeX,
            relativeY,
          },
          type: dat.type,
        });
      }
    }

    // Improve chart if below minNumberPoints
    if (points.length < minNumberPoints) {
      points = points.map((point, idx) => ({
        ...point,
        position: {
          ...point.position,
          relativeX: idx * 0.1,
        },
      }));
    }

    // merge points which are too close to each other
    const mergedDataPoints = [];
    points.forEach((point) => {
      if (!mergedDataPoints.length) {
        mergedDataPoints.push(point);
        return;
      }

      const closePoint = mergedDataPoints.find((mergedPoint) => {
        const {
          position: mergedPosition,
        } = mergedPoint;
        const {
          position: comparePosition,
        } = point;

        return Math.abs(mergedPosition.relativeX - comparePosition.relativeX) < 0.02;
      });

      if (!closePoint) {
        mergedDataPoints.push(point);
        return;
      }

      const index = mergedDataPoints.findIndex((p) => p.id === closePoint.id);
      const {
        xp: pointXp,
      } = point;

      const {
        xp: closeXp,
      } = closePoint;

      const higherPoint = pointXp > closeXp ? point : closePoint;

      const mergedPoint = {
        ...higherPoint,
        level: higherPoint.level || point.level,
        badges: [...(higherPoint.badges || []), ...(point.badges || [])],
        module: higherPoint.module || point.module,
        xp: higherPoint.xp || point.xp,
      };

      mergedDataPoints[index] = mergedPoint;
    });
    points = mergedDataPoints;
    if (!isEqual(points, dataPoints)) {
      setDataPoints(points);
    }
    return () => {};
  }, [activities, progress]);
  return {
    dataPoints,
  };
}
