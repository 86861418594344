
// Packages
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Services
import { dispatchSetPlayerData } from 'elearning/services';

// Hooks
import usePlatformSettings from 'shared/hooks/use-platform-settings';

// Components
import CustomButton from 'shared/components/custom-button';
import InfoBubble from 'shared/components/info-bubble';
import Loading from 'shared/components/loading';
import Pill from 'elearning/components/pill-component';
import SvgIcon from 'shared/svgs/helper/svg-icon';
import CardRibbon from 'elearning/components/card-ribbon';
import ResponsivePicture from 'shared/components/responsive-picture';
import { Time } from 'shared/svgs';

// Utils
import { dateToDDMMYYYY } from 'shared/helpers';
import {
  getModuleForQuickStartCard, getModuleKey, getNextModuleRelease, MODULE_OPENED_FROM,
} from 'elearning/helpers';
import { MODULE_STATES } from 'shared/utilities/module-states';

// Scss
import './quick-start-card.scss';
import { useSoSafeAnalytics } from 'shared/hooks/use-sosafe-analytics';
import { ANALYTICS_TYPE } from 'shared/modules/sosafe-connect';
import { useCategoriesRequest } from 'elearning/hooks/use-categories-request';
import useUser from 'shared/hooks/use-user';

export default function QuickStartCard(props) {
  const { className } = props;
  const { t, i18n } = useTranslation('translations');
  const { user } = useUser({});
  const dispatch = useDispatch();
  const history = useHistory();
  const { settings } = usePlatformSettings();
  const { step_activation } = settings || {};
  const { sendSoSafeAnalytics } = useSoSafeAnalytics();
  const { refetch: refetchCategories, data: categoriesResponse, loading } = useCategoriesRequest(true);
  const categories = categoriesResponse?.data.result || [];

  let cardConfig = null;

  const [module, setModule] = useState();

  useEffect(() => {
    setModule(getModuleForQuickStartCard(categories, user, step_activation));
  }, [categories]);

  if (module) {
    const { description, mandatory, statusOfModule } = module;
    if (mandatory) {
      switch (true) {
        case statusOfModule?.includes(MODULE_STATES.EXPIRED):

          break;
        case statusOfModule?.includes(MODULE_STATES.EXPIRE_SOON):

          break;
        default:
          break;
      }
    }

    cardConfig = {
      description,
      name: module.name || '',
      mandatory,
      finished_by: module.finished_by,
      image: module.image,
      catKey: module.category_key,
      modKey: getModuleKey(module),
      duration: module.duration,
      statusOfModule: module.statusOfModule,
    };
  }

  const pillLongLangs = ['ar', 'es', 'cs', 'fr', 'it', 'nl', 'pl', 'pt', 'ru', 'se'];

  const LR = `${i18n.language === 'ar' ? 'r' : 'l'}`;
  const RL = `${i18n.language === 'ar' ? 'l' : 'r'}`;

  let buttonText = t('Start');
  const ribbonText = t('Quickstart');
  let moduleAlreadyPassed = false;
  if (cardConfig && cardConfig.statusOfModule?.includes(MODULE_STATES.PAUSED)) buttonText = t('Continue');
  if (cardConfig && cardConfig.statusOfModule?.includes(MODULE_STATES.PASSED)) {
    buttonText = t('Repeat');
    moduleAlreadyPassed = true;
  }

  if (loading) {
    return (
            <Card className={`quick-start-card shadow hover-shadow overflow-hidden ${className}`}>
                <Card.Body className='d-flex'>
                    <div className='align-self-center mx-auto'>
                        <Loading size='medium' />
                    </div>
                </Card.Body>
            </Card>
    );
  }

  if (!cardConfig) {
    const { infoNotAllModulesAvailable, releaseDate } = getNextModuleRelease(user);
    return (
            <Card className={`quick-start-card shadow hover-shadow overflow-hidden ${className}`}>
                <Card.Body className='d-flex flex-column'>
                    <span>{t('No modules available')}.</span>
                    {infoNotAllModulesAvailable && <span className='u-textMedium mt-2'>{t('footerNextRelease', { x: dateToDDMMYYYY(releaseDate) })}</span>}
                    <CustomButton
                        className='ml-auto mt-auto'
                        label={t('Reload')}
                        onClick={() => refetchCategories()}
                    />
                </Card.Body>
            </Card>
    );
  }

  const historyState = {
    openedFrom: MODULE_OPENED_FROM.QUICKSTART_CARD,
  };

  return (
        <Card
            data-testid='quickstart-card'
            className={`quick-start-card shadow hover-shadow cursor-pointer overflow-hidden ${className}`}
            onClick={() => {
              sendSoSafeAnalytics({ type: ANALYTICS_TYPE.QUICKSTART_USED });
              dispatch(dispatchSetPlayerData({ isContinue: true }));
              history.push(`elearning/${cardConfig.catKey}/${cardConfig.modKey}`, historyState);
            }}
        >
            <div className='row flex-grow-1'>
                <div className='col-12 col-md-7 d-flex flex-column order-1'>
                    <div className={`h-100 py-3 px-3 p${LR}-md-0 d-flex flex-column`}>
                        <h2 className='u-paragraph u-textBold mt-3 pb-3 order-1'>
                            {cardConfig.name}
                        </h2>
                        <div className='d-flex flex-row justify-content-between order-0'>
                            <Pill
                                className={pillLongLangs.includes(i18n.language) ? 'two-lines' : ''}
                                module={module}
                            />
                            <div className='col-2 justify-content-end d-flex p-0'>
                                <div>
                                    <InfoBubble
                                        size='small'
                                        description={cardConfig.description}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-row justify-content-between align-items-end mt-auto order-2'>
                            <div className='d-flex flex-column'>
                                {cardConfig.finished_by && !moduleAlreadyPassed && (
                                    <small className='text-danger'>
                                        <strong>
                                            {dateToDDMMYYYY(cardConfig.finished_by)}
                                        </strong>
                                    </small>
                                )}
                                {cardConfig.mandatory && (
                                    <small className='text-danger'>
                                        <strong>
                                            {t('Mandatory module')}
                                        </strong>
                                    </small>
                                )}
                                <div className='text-muted d-flex flex-row mt-auto'>
                                    <SvgIcon
                                        Icon={Time}
                                        size='15px'
                                        className='my-auto stroke-icon-gray'
                                    />
                                    <small className={`my-auto m${LR}-1`}>
                                        {t('approx. x minute', { count: cardConfig.duration })}
                                    </small>
                                </div>
                            </div>
                            <CustomButton
                                label={buttonText}
                                size='sm'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  sendSoSafeAnalytics({ type: ANALYTICS_TYPE.QUICKSTART_USED });
                                  dispatch(dispatchSetPlayerData({ isContinue: true }));
                                  history.push(`elearning/${cardConfig.catKey}/${cardConfig.modKey}`, historyState);
                                }}
                                className='mt-auto'
                            />
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-5 d-flex p${RL}-md-0 image-section card-header-down-sm-height order-0`}>
                    <div className='w-100 position-relative'>
                        <div className='quickstart-label d-flex shadow'>
                            <strong className='mx-auto text-center'>{ribbonText}</strong>
                        </div>
                        <ResponsivePicture
                            className='h-100 w-100'
                            src={cardConfig.image}
                            alt={t('Quickstart image')}
                            fit='cover'
                            defaultWidth={730}
                            srcset={{
                              xs: { width: 730 },
                              md: { height: 145 },
                            }}
                        />
                        <CardRibbon
                            className='u-textSmall'
                            module={module}
                        />
                    </div>
                </div>
            </div>
        </Card>
  );
}

QuickStartCard.propTypes = {
  className: PropTypes.string,
};

QuickStartCard.defaultProps = {
  className: '',
};
