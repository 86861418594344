
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SvgIcon from 'shared/svgs/helper/svg-icon';
import { getCardRibbonConfig } from 'elearning/helpers';

import './card-ribbon.scss';

/**
 * CardRibbon is the small ribbon on the side of a Card-element that displays the current state of the card.
 * it defaults to display information about the module that was passed in,
 * but can be overwritten with a custom text, an icon and classes.
 */

export default function CardRibbon(props) {
  const { t, i18n } = useTranslation('translations');

  const RL = i18n.language === 'ar' ? 'l' : 'r';
  const {
    className, module, icon, text,
  } = props;
  let {
    cardRibbonDisplay, cardRibbonClassName, cardRibbonIcon, cardRibbonText,
  } = getCardRibbonConfig(module);
  // only display the card, if the ribbonConfig returns data, or custom text or an icon is passed in.
  const displayRibbon = (cardRibbonDisplay || icon || text);
  // prefer passed in props to module-props. reset all ribbonConfig-properties to make sure they don't mix
  if (icon || text) {
    cardRibbonDisplay = '';
    cardRibbonClassName = '';
    cardRibbonIcon = '';
    cardRibbonText = '';
  }
  const displayIcon = icon || cardRibbonIcon;
  const displayText = text || cardRibbonText;
  if (displayRibbon) {
    return (
            <div
                className='card-ribbon-wrapper'
                data-testid='card-ribbon-wrapper'
            >
                <div className={`card-ribbon shadow px-3 py-2 d-flex ${cardRibbonClassName} ${className}`}>
                    {displayIcon && (
                        <SvgIcon
                            Icon={displayIcon}
                            size='16px'
                            className={`my-auto color-white m${RL}-2`}
                            flipx={i18n.language === 'ar'}
                        />
                    )}
                    <span className={`${module && module.master ? '' : 'u-textSmall'} font-weight-bold text-white`}>{t(displayText)}</span>
                </div>
            </div>
    );
  }
  return null;
}

CardRibbon.propTypes = {
  className: PropTypes.string,
  module: PropTypes.shape({}),
  icon: PropTypes.shape({}),
  text: PropTypes.string,
};

CardRibbon.defaultProps = {
  className: '',
  module: null,
  icon: null,
  text: '',
};
