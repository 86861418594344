
export default function getDataFromSSOProfile(register_data, i18n) {
  const profileLanguage = null;
  // move this to BE
  // if (profile && profile.preferredLanguage) {
  //     const { preferredLanguage } = profile;
  //     if (preferredLanguage.includes('-')) {
  //         const arr = preferredLanguage.split('-');
  //         profileLanguage = arr[0];
  //     } else {
  //         profileLanguage = preferredLanguage;
  //     }
  //     if (profileLanguage === 'sv') profileLanguage = 'se'; // map microsoft sv to se
  //     if (profileLanguage === 'ja') profileLanguage = 'jp'; // map microsoft sv to se
  // }

  let language = null;
  const { languages: languagesObj = {}, default_language } = register_data || {};

  const { languages = [] } = languagesObj || {};
  if (profileLanguage && languages.includes(profileLanguage)) {
    language = profileLanguage;
  } else if (register_data && register_data.language && languages.includes(register_data.language)) {
    language = register_data.language;
  } else if (languages.includes(i18n.language)) {
    language = i18n.language;
  } else if (default_language && languages.includes(default_language)) {
    language = default_language;
  } else if (languages.length) {
    language = languages[0];
  } else {
    language = i18n.language;
  }

  return {
    firstname: register_data.firstname,
    lastname: register_data.lastname,
    email: register_data.address || '',
    language,
    gender: register_data.gender ?? null,
    sso: register_data.sso,
  };
}
