
// Packages
import Button from "react-bootstrap/Button";
import React, { useEffect, useRef, useMemo, useCallback } from "react";
import { Tooltip } from "@sosafe-platform-engineering/fe-lib-ui-react";
import { CustomButtonProps, CustomButtonVariantType } from "./types";
import styles from "./custom-button.module.css";

/**
 * Button component. No more info needed.
 *
 * Do not wrap this component with TooltipWrapper. Use tooltip prop instead.
 *
 * @category shared
 * @subcategory components
 * @exports CustomButton
 * @component
 */
export default function CustomButton(props: CustomButtonProps) {
  const {
    ariaLabel,
    ariaHaspopup = false,
    ariaExpanded = false,
    className = "",
    disabled = false,
    focus = false,
    id,
    isSubmitButton = false,
    label,
    size = "sm",
    tooltip,
    variant = CustomButtonVariantType.SECONDARY,
    ...rest
  } = props;

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focus && ref && ref.current) {
      ref.current.focus();
    }
  }, []);

  const aria: string = useMemo(() => {
    if (ariaLabel) {
      return ariaLabel;
    }

    if (typeof label === "string") {
      return label;
    }
    return "";
  }, [ariaLabel, label]);

  const handleOnClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!isSubmitButton && !disabled) {
      props.onClick(e);
    }
  };

  const Wrapper: React.FunctionComponent<{ children: React.ReactNode }> =
    useCallback(
      ({ children }: { children: React.ReactNode }) => {
        if (tooltip) {
          return (
            <Tooltip
              content={tooltip?.description || ""}
              position={tooltip?.position || "bottom"}
            >
              <div>{children}</div>
            </Tooltip>
          );
        }
        return <React.Fragment>{children}</React.Fragment>;
      },
      [tooltip]
    );

  const buttonColor = useMemo(() => {
    switch (variant) {
      case CustomButtonVariantType.PRIMARY:
        return styles.primary;
      case CustomButtonVariantType.SECONDARY:
        return styles.secondary;
      case CustomButtonVariantType.PRIMARY_OUTLINE:
        return styles.primaryOutline;
      case CustomButtonVariantType.SECONDARY_OUTLINE:
        return styles.secondaryOutline;
      default:
        return "";
    }
  }, [variant]);

  return (
    <Wrapper>
      <Button
        {...rest}
        aria-label={aria}
        {...(disabled ? { 'aria-disabled': disabled } : {})}
        {...(ariaExpanded ? { 'aria-expanded': ariaExpanded } : {})}
        {...(ariaHaspopup ? { 'aria-haspopup': ariaHaspopup } : {})}
        className={`${buttonColor} ${className || ""} ${
          disabled ? "cursor-default disabled" : ""
        }`}
        disabled={disabled && !tooltip}
        id={id}
        key={id}
        onClick={handleOnClick}
        ref={ref}
        size={size}
        type={isSubmitButton ? "submit" : ""}
        variant={variant}
      >
        {label || ""}
      </Button>
    </Wrapper>
  );
}
