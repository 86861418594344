
import { MODULE_STATES } from 'shared/utilities/module-states';
import getStatusOfModule from 'elearning/helpers/get/get-status-of-module';
import { WELCOME_MESSAGES } from 'shared/utilities/welcome-message-states';
import getModuleFromCategories from './get-module-from-categories';
import getModuleKey from './get-module-key';

/**
 * Object with message and optional, added Data
 *
 * @typedef {Object} getAdHocMessageObject
 * @property {string} message message string (from WELCOME_MESSAGES const)
 * @property {Object} addData addData object to store additional Data
 *
 */

/**
 *
 * Returns adhoc message for welcome card
 *
 * @param {*} user new data
 * @param {*} compareData old data to compare
 * @param {Object} gamificationSettings gamification settings Object
 * @param {Object} categories Object of all categories
 * @returns {getAdHocMessageObject} Object with message and optional, added Data
 *
 * @category elearning
 * @subcategory helpers
 * @exports getAdHocMessage
 */
export default function getAdHocMessage(user, compareData, gamificationSettings, categories) {
  if (!user || !compareData) return null;

  const badgesActivated = gamificationSettings && gamificationSettings.badges;
  const levelActivated = gamificationSettings && gamificationSettings.level;
  const achievementsActivated = gamificationSettings && gamificationSettings.achievements;
  const { game: newGame } = user;
  const { game: oldGame } = compareData;

  if (!oldGame || !newGame) return null;

  // all essentials done
  if (oldGame.progress && newGame && newGame.progress && !oldGame.progress.essentialsDone && newGame.progress.essentialsDone) {
    return { message: WELCOME_MESSAGES.ADHOC_ALL_ESSENTIALS };
  }

  // new level received
  if (levelActivated) {
    const oldLevelId = oldGame.progress && oldGame.progress.level ? oldGame.progress.level.id : null;
    const newLevelId = newGame.progress && newGame.progress.level ? newGame.progress.level.id : null;
    if (newLevelId && newLevelId !== oldLevelId) {
      return { message: WELCOME_MESSAGES.ADHOC_LEVEL };
    }
  }

  // new badge received
  if (badgesActivated) {
    const oldBadgeId = oldGame.activities && oldGame.activities.lastBadge ? oldGame.activities.lastBadge.id : null;
    const newBadgeId = newGame.activities && newGame.activities.lastBadge ? newGame.activities.lastBadge.id : null;
    if (newBadgeId && newBadgeId !== oldBadgeId) {
      return { message: WELCOME_MESSAGES.ADHOC_BADGE };
    }
  }

  // module completed
  const oldModuleId = oldGame.activities && oldGame.activities.lastModule ? oldGame.activities.lastModule.id : null;
  const newModuleId = newGame.activities && newGame.activities.lastModule ? newGame.activities.lastModule.id : null;
  if (newModuleId) {
    const newModule = getModuleFromCategories(categories, newModuleId);
    if (!newModule) return null;
    const statusOfNewModule = getStatusOfModule(newModule);
    if (newModuleId !== oldModuleId && statusOfNewModule && statusOfNewModule.includes(MODULE_STATES.PASSED)) {
      return { message: WELCOME_MESSAGES.ADHOC_MODULE };
    }
  }

  // Category is 50% behind category with the most experience
  const oldSpider = oldGame.progress && oldGame.progress.spider;
  const newSpider = newGame.progress && newGame.progress.spider;

  if (newSpider.length >= 2 && achievementsActivated) {
    let mostProgress = null;
    let leastProgress = null;
    newSpider.forEach((cat) => {
      if (!mostProgress) mostProgress = cat;
      if (!leastProgress) leastProgress = cat;
      if (cat.progress > mostProgress.progress) mostProgress = cat;
      if (cat.progress < leastProgress.progress) leastProgress = cat;
    });
    if (mostProgress.progress - leastProgress.progress >= 50) {
      const mostInOldSpider = oldSpider.find((cat) => cat.id === mostProgress.id);
      const leastInOldSpider = oldSpider.find((cat) => cat.id === leastProgress.id);
      if (mostInOldSpider && leastInOldSpider && mostInOldSpider.progress - leastInOldSpider.progress < 50) {
        const categoryToImprove = categories.find((category) => category.id === leastProgress.id);
        const nextModule = categoryToImprove.modules.find((module) => module.active && !module.statusOfModule.includes(MODULE_STATES.PASSED));
        if (nextModule) {
          return {
            message: WELCOME_MESSAGES.ADHOC_CAT_PROGRESS,
            addData: {
              catKey: categoryToImprove.category_name,
              moduleKey: getModuleKey(nextModule),
            },
          };
        }
      }
    }
  }

  return null;
}
