
import { dateToDDMMYYYY } from 'shared/helpers';

export default function getDaysFromActivities(activities) {
  if (!activities || !activities.length) return [];

  const days = [];
  activities.forEach((act) => {
    const { created } = act;
    const day = dateToDDMMYYYY(created);
    const index = days.findIndex((d) => d.day === day);
    if (index === -1) {
      days.push({
        day,
        isoTimestamp: created,
        activities: [act],
      });
    } else {
      days[index] = {
        ...days[index],
        activities: [
          ...days[index].activities,
          act,
        ],
      };
    }
  });
  return days;
}
