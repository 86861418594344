import React, { useState, FC } from "react";
import { Tooltip } from "@sosafe-platform-engineering/fe-lib-ui-react";
import { useTranslation } from "react-i18next";
import { toFixedFloat, checkIsIE } from "shared/helpers";
import { getContrastColor } from "shared/helpers/a11y";
import { CHART_CONFIG, chartUtils } from "shared/helpers/chart";
import SvgHandlingWrapper from "shared/components/svg-handling-wrapper";
import { ChartProps, ChartColumn } from "types/charts-types";
import { isAllModulesPassed } from '../utils/module-helpers';
import "./experience-spider-chart.scss";

const ExperienceSpiderChart: FC<ChartProps> = ({
  data = [],
  className = "",
  loading = null
}) => {
  const { t } = useTranslation("translations");
  const [ref] = useState<React.RefObject<HTMLDivElement>>(React.createRef());

  const { SIZE, MIDDLE, SPIDER } = CHART_CONFIG;
  const radiusInnerCircle = toFixedFloat(((1 / SPIDER.SCALES) * (SIZE * SPIDER.INNER_CIRCLE_MULTIPLIER)) / 2);
  const minSpiderChartDistance = radiusInnerCircle * SPIDER.MIN_DISTANCE_MULTIPLIER;

  const scale = (value: number): JSX.Element => (
    <circle
      cx={MIDDLE}
      cy={MIDDLE}
      fill="none"
      opacity="0.4"
      strokeWidth="0.28"
      r={toFixedFloat((value / SPIDER.SCALES) * (SPIDER.AXIS_LENGTH * 0.9))}
      className="stroke-light"
      key={value.toString()}
    />
  );

  const scales: JSX.Element[] = [];
  for (let i = SPIDER.SCALES; i > 1; i--) {
    scales.push(scale(i));
  }

  const columns: ChartColumn[] = data.map((d, i, all) => {
    const currentAngle = (Math.PI * 2 * i) / all.length;
    return {
      ...d,
      angle: currentAngle,
      x: toFixedFloat(chartUtils.polarToX(currentAngle, SPIDER.AXIS_LENGTH)),
      y: toFixedFloat(chartUtils.polarToY(currentAngle, SPIDER.AXIS_LENGTH)),
    };
  });

  const axis = (col: ChartColumn, i: number): JSX.Element => (
    <line
      x1={MIDDLE}
      y1={MIDDLE}
      x2={col.x}
      y2={col.y}
      stroke={col.color}
      strokeWidth="0.8"
      key={i.toString()}
    />
  );

  const dots = (col: ChartColumn, i: number): JSX.Element => {
    const allModulesPassed = isAllModulesPassed(col.modules);
    return (
      <Tooltip position="bottom" content={col.name} key={i.toString()}>
        <g
          aria-label={`${col.name} - ${t("completed", {
            percentage: allModulesPassed ? 100 : Math.floor(100 * col.progress),
          })}`}
        >
          <circle fill={col.color} cx={col.x} cy={col.y} r={toFixedFloat(0.025 * SIZE)} />
          <text
            textAnchor="middle"
            x={col.x}
            y={col.y}
            fill={getContrastColor(col.color)}
            stroke={getContrastColor(col.color)}
            strokeWidth={toFixedFloat(0.001 * SIZE)}
            fontSize={toFixedFloat(0.035 * SIZE)}
            dy={toFixedFloat(0.012 * SIZE)}
            className="cursor-default"
          >
            {i + 1}
          </text>
        </g>
      </Tooltip>
    );
  };

  const texts = (col: ChartColumn, i: number): JSX.Element => {
    const allModulesPassed = isAllModulesPassed(col.modules);
    return (
      <text
        textAnchor="middle"
        x={toFixedFloat(chartUtils.polarToX(col.angle, SPIDER.AXIS_LENGTH * 1.28))}
        y={toFixedFloat(chartUtils.polarToY(col.angle, SPIDER.AXIS_LENGTH * 1.18))}
        fontSize={toFixedFloat(0.04 * SIZE)}
        dy={toFixedFloat(0.025 * SIZE)}
        className="fill-dark"
        key={i.toString()}
      >
        {allModulesPassed ? '100%' : `${Math.round(col.progress * 100)}%`}
      </text>
    );
  };

  const groups: JSX.Element[] = [];
  groups.push(
    <g key="scales" className="spiderchart-scales">
      {scales}
    </g>
  );
  groups.push(
    <g key="group-axes" className="spiderchart-axes">
      {columns.map((col, i) => axis(col, i))}
    </g>
  );
  groups.push(
    <g key="group-text" className="spiderchart-textdots">
      {columns.map((col, i) => texts(col, i))}
    </g>
  );
  groups.push(
    <g key="group-dots" className="spiderchart-textdots">
      {columns.map((col, i) => dots(col, i))}
    </g>
  );
  groups.push(
    <g key="groups">
      <path
        className="spiderchart-progress"
        key="shape"
        d={chartUtils.pathDefinition(
          columns.map((col) => {
            const progress = isAllModulesPassed(col.modules) ? 1 : col.progress;
            const distance = minSpiderChartDistance + progress * SPIDER.AXIS_LENGTH * 0.8;
            return [
              toFixedFloat(chartUtils.polarToX(col.angle, distance)),
              toFixedFloat(chartUtils.polarToY(col.angle, distance))
            ];
          })
        )}
        opacity="0.4"
      />
    </g>
  );

  return (
    <SvgHandlingWrapper className={className} propRef={ref} loading={loading}>
      <svg
        height="100%"
        viewBox={`0 0 ${SIZE} ${SIZE}`}
        preserveAspectRatio="xMidYMid meet"
        className={`spiderchart ${checkIsIE() ? "is-ie" : ""}`}
        aria-label={t("chart_progress_aria_label")}
        role="img"
      >
        <defs>
          <ellipse
            id="spiderchart-center"
            cx={MIDDLE}
            cy={MIDDLE}
            rx={radiusInnerCircle}
            ry={radiusInnerCircle}
            fill="white"
            fillRule="evenodd"
          />
          <filter
            x="-1"
            y="-1"
            width="3"
            height="3"
            filterUnits="objectBoundingBox"
            id="spiderchart-center-shadow"
          >
            <feOffset dx="1" dy="1" in="SourceAlpha" result="spiderchart-center-shadowOffset" />
            <feGaussianBlur
              stdDeviation="1.5"
              in="spiderchart-center-shadowOffset"
              result="spiderchart-center-shadowBlur"
            />
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"
              type="matrix"
              in="spiderchart-center-shadowBlur"
              result="spiderchart-center-shadowColorMatrix"
            />
            <feBlend in="SourceGraphic" in2="spiderchart-center-shadowColorMatrix" mode="normal" />
          </filter>
        </defs>
        <g>{groups}</g>
        <g>
          <use href="#spiderchart-center" filter="url(#spiderchart-center-shadow)" />
        </g>
      </svg>
    </SvgHandlingWrapper>
  );
};

export default ExperienceSpiderChart;
