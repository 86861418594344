
/**
 * Hook to get the current component depending on url
 *
 * @export
 * @returns string
 */
export default function useGetComponent() {
  const url = window.location.pathname;
  switch (true) {
    case url.includes('achievements'):
      return 'achievements';
    case url.includes('elearning') && url.split('/').length === 3:
      return 'modules';
    case url === '/':
      return 'categories';
    case url.includes('account'):
      return 'profile';
    default:
      return '';
  }
}
