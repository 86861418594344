import { getSubdomain } from 'authentication/helpers';
import i18n from 'lang/i18n';
import { useDispatch } from 'react-redux';
import {
  RegisterUserProps,
  useSoSafeConnect,
} from 'shared/modules/sosafe-connect';
import ActionTypes from 'shared/utilities/action-types';
import {
  getMessageByReference,
  isReferenceIdMatch,
} from 'shared/utilities/reference-handling';
import { UseReferenceHandler } from 'shared/utilities/use-reference-handler';
import ErrorCollapsible from 'shared/components/error-collapsible';
import { useCallback } from 'react';
import { UseGlobalSettingsContext } from 'shared/modules/global-settings/state/global-settings-context.provider';
import { useModalContext } from 'modal-context/modal-context';
import { UseShowDecisionAlert } from 'flamingo-e-learning-platform/utilities/modal-alert/use-show-decision-alert';
import {
  Button,
  Text,
  Title,
} from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import { ALERT_TYPE } from 'flamingo-e-learning-platform/utilities/modal-alert/shared';
import { useActivateAccount } from './use-activate-account';

const getParamsByDataContent = (data: RegisterUserProps): RegisterUserProps => {
  const subdomain = getSubdomain();
  const defaultParam = { subdomain };

  if (data.sso) {
    return { ...defaultParam, sso: data.sso, method: data.method };
  }
  if (data.email) {
    return { ...defaultParam, email: data.email };
  }
  if (data.code) {
    return { ...defaultParam, code: data.code };
  }
  if (data.key) {
    return { ...defaultParam, key: data.key };
  }
  return data;
};

/** Hook to manage user registration
 * @return register method that handles all registrations (sso, code, email and key).
 */
export const useRegisterUser = () => {
  const { endPoints, setApiKey } = useSoSafeConnect();
  const { setLocalToken } = UseGlobalSettingsContext();
  const dispatch = useDispatch();
  const { mutate: activateAccount } = useActivateAccount();
  const { close } = useModalContext();
  const { show } = UseShowDecisionAlert();
  const checkReference = UseReferenceHandler();

  const failedToRegister = useCallback(
    (data: RegisterUserProps, error: any) => {
      // message translations
      const closeMsg = i18n.t('Close');
      const errorMsg = i18n.t('Error');
      const sendAgainMsg = i18n.t('Send again');
      const accountActivationMsg = i18n.t(
        'messageTranslations:The account already exists, but has not yet been activated! Please open the link sent to your email address to activate your account.'
      );

      if (sessionStorage.getItem('MSAL_STATE')) {
        sessionStorage.removeItem('MSAL_STATE');
        const message = error.response?.data
          ? getMessageByReference(error.response.data.reference)
          : null;
        const translatedMessage = message
          ? i18n.t(message)
          : i18n.t('messageTranslations:An error has occured');

        show({
          title: <Title size="h2">{errorMsg}</Title>,
          content: (
            <>
              <Text>{translatedMessage}</Text>
              <ErrorCollapsible error={error} response={error?.response} />
            </>
          ),
          footer: (
            <Button
              variant="primary"
              aria-label={closeMsg}
              onClick={() => close()}
            >
              {closeMsg}
            </Button>
          ),
          type: ALERT_TYPE.ERROR,
        });

        const payload = error?.response?.data || {};
        delete payload.reference;
        dispatch({ payload, type: ActionTypes.AUTH_REGISTER_DATA_FAILED });
        return;
      }

      const reference = error?.response?.data?.reference;

      if (error.response) {
        dispatch({
          payload: checkReference(error.response.data, dispatch),
          type: ActionTypes.AUTH_REGISTER_DATA_FAILED,
        });
      }

      if (
        reference &&
        (isReferenceIdMatch(
          reference,
          'REGISTRATION_LOGIN_EXISTS_NOT_ACTIVE_1'
        ) ||
          isReferenceIdMatch(
            reference,
            'REGISTRATION_LOGIN_EXISTS_NOT_ACTIVE_2'
          ))
      ) {
        const accountActivation = () => {
          if (data.email) activateAccount({ email: data.email });
          close();
        };

        show({
          title: <Title size="h2">{errorMsg}</Title>,
          content: <Text>{accountActivationMsg}</Text>,
          footer: (
            <>
              <Button
                variant="primary"
                aria-label={sendAgainMsg}
                onClick={accountActivation}
              >
                {sendAgainMsg}
              </Button>
              <Button variant="secondary" aria-label={closeMsg} onClick={close}>
                {closeMsg}
              </Button>
            </>
          ),
          type: ALERT_TYPE.ERROR,
        });
      } else {
        dispatch({
          payload: checkReference(
            {
              title: error.name,
              status: error.statusCode,
              reference: '8CCA1873-7195-4EB7-8BF4-32CCF473B353',
            },
            dispatch
          ),
          type: ActionTypes.AUTH_REGISTER_DATA_FAILED,
        });
      }
    },
    []
  );

  const register = (data: RegisterUserProps) => {
    dispatch({ type: ActionTypes.AUTH_REGISTER_DATA_REQUEST });
    const params = getParamsByDataContent(data);
    endPoints.fetch
      .getRegisterUser(params)
      .then((response) => {
        if (response.data.result) {
          setApiKey(response.data.result.apikey);
        }
        if (response?.data?.result?.customer?.token) {
          setLocalToken(response.data.result.customer.token);
        }
        setTimeout(() => {
          sessionStorage.removeItem('MSAL_STATE');
          dispatch({
            payload: checkReference(response.data, dispatch),
            type: ActionTypes.AUTH_REGISTER_DATA_SUCCESS,
          });
        }, 300);
      })
      .catch((error) => failedToRegister(data, error));
  };

  return {
    register,
  };
};
