import { DownloadDataType } from 'downloads/downloads.types';
import { PersonalizedModule } from 'flamingo-e-learning-platform/training/types/module.type';
import { ReactElement } from 'react';
import { GameProgressLevelProps } from 'types';

export enum ALERT_TYPE {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  QUESTION = 'question',
  /**
   * No icon will be displayed
   */
  NONE = 'none',
}

export interface ModalAlertConfig {
  title?: ReactElement;
  content: ReactElement;
  footer: ReactElement;
  type: ALERT_TYPE;
  onClose?: () => Promise<void>;
}

declare global {
  interface Window {
    SCORMLMS: any;
  }
}

export interface ApiError extends Error {
  response?: {
    data: {
      reference: any;
    };
  };
}

export interface MutationResponse {
  data: {
    result: {
      achievements?: {
        level?: GameProgressLevelProps;
        nextLevel?: GameProgressLevelProps;
        xp?: number;
      };
      progress: any;
    };
  };
}

export interface HistoryState {
  openedFrom?: string;
  action?: string;
}

export type CategoryDataType = CategoryType & {
  /** any possible subcategories are saved here */
  categories: CategoryDataType[] | never[];

  /** downloads available for this category */
  materials: DownloadDataType[] | never[];
};

export interface CategoryType {
  /** createdDate of this category */
  created: string;

  /** optional description */
  description: string | null;

  /** should be displayed? */
  enabled: boolean;

  /** group-id, to match same categories across languages */
  group: number | null;

  /** UID */
  id: number;

  /** iso, 2-letter language-string */
  language: string | null;

  /** points to the parent category (FK), will be null, if it's toplevel */
  parent_material_category_id: number | null;

  /** number, to be sorted by */
  sort: number | null;

  /** category title, to be displayed */
  title: string | null;

  /** modules associated with this category */
  modules: PersonalizedModule[];

  /** category name */
  category_name: string;

  /** category key */
  category_key: string;
}
