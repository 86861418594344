
import { KEYS } from 'shared/utilities/constants';

/**
 * creates a focus cycle on a specified element. Should be used on dialog boxes
 *
 * @param {Element} parentElement wrapper/parent element to create focuscycle in
 *
 * @category shared
 * @subcategory helpers
 * @exports keyboardFocusCycle
 */
export default function keyboardFocusCycle(parentElement) {
  const focusElements = getKeyboardFocusableElements(parentElement) || [];
  if (focusElements.length === 0) return;

  const first = focusElements[0];
  const last = focusElements[focusElements.length - 1];

  const goToFirst = (e) => {
    if (e.which === KEYS.TAB) {
      first.focus();
      e.preventDefault();
    }
  };

  const goToLast = (e) => {
    if (e.which === KEYS.TAB && e.shiftKey) {
      last.focus();
      e.preventDefault();
    }
  };

  first.addEventListener('keydown', goToLast);
  last.addEventListener('keydown', goToFirst);
}

/**
 * Gets keyboard-focusable elements within a specified element
 *
 * @param {HTMLElement} [element] element
 * @returns {Array}
 */
export function getKeyboardFocusableElements(element) {
  const focusElements = [...element.querySelectorAll(
    'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"]), [role="button"], [role="option"]',
  )];
  focusElements.filter((el) => !el.hasAttribute('disabled'));
  return focusElements;
}
