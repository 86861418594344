
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Welcome() {
  const { t, i18n } = useTranslation('tourTranslations');

  return (
        <svg
            width='334'
            height='145'
            viewBox='0 0 334 145'
            direction='ltr'
        >
            <defs>
                <rect
                    id='willkommenskachel-b'
                    width='320'
                    height='130.337'
                    x='0'
                    y='0'
                    rx='3.596'
                />
                <filter
                    id='willkommenskachel-a'
                    width='107.2%'
                    height='117.6%'
                    x='-3.6%'
                    y='-7.3%'
                    filterUnits='objectBoundingBox'
                >
                    <feOffset
                        dy='2'
                        in='SourceAlpha'
                        result='shadowOffsetOuter1'
                    />
                    <feGaussianBlur
                        in='shadowOffsetOuter1'
                        result='shadowBlurOuter1'
                        stdDeviation='3.5'
                    />
                    <feColorMatrix
                        in='shadowBlurOuter1'
                        values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0'
                    />
                </filter>
            </defs>
            <g
                fill='none'
                fillRule='evenodd'
                transform='translate(7 5)'
            >
                <use
                    fill='#000'
                    filter='url(#willkommenskachel-a)'
                    href='#willkommenskachel-b'
                />
                <use
                    className='fill-secondary'
                    href='#willkommenskachel-b'
                />
                <text
                    fill='#FFF'
                    fontSize='17.978'
                    fontWeight='bold'
                >
                    <tspan
                        x='13.483'
                        y='29.584'
                    >{t('SvgText:Welcome1')}</tspan>
                </text>
                <text
                    fill='#FFF'
                    fontSize='12.584'
                >
                    <tspan
                        x='13.483'
                        y='76.719'
                    >{t('SvgText:Welcome2')}</tspan>
                </text>
                {i18n.language === 'ru' && (
                    <text
                        fill='#FFF'
                        fontSize='12.584'
                    >
                        <tspan
                            x='13.483'
                            y='96.719'
                        >{t('SvgText:Welcome2a')}</tspan>
                    </text>
                )}
                <text
                    fill='#FFF'
                    fontSize='10.787'
                >
                    <tspan
                        x='13.483'
                        y={i18n.language === 'ru' ? '116.719' : '96.719'}
                    >{t('SvgText:Welcome3')}</tspan>
                </text>
            </g>
        </svg>
  );
}
